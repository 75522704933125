//GENERAL PERMISSIONS
export const close_shift = "close_shift";
export const reports = "reports";
export const sales_report = "sales_report";
export const ranking_report = "ranking_report";
export const receipts_reports = "receipts_reports";
export const statistics_report = "statistics_report";
export const cash_balance_report = "cash_balance_report";
export const settings = "settings";
export const general = "general";
export const locations = "locations";
export const billing = "billing";
export const serials = "serials";
export const taxes = "taxes";
export const exchange_rate = "exchange_rate";
export const store = "store";
export const store_actions = "store_actions";
export const employees = "employees";
export const staff = "staff";
export const volunteer = "volunteer";
export const accounts = "accounts";
export const rrhh_payment = "rrhh_payment";
export const groups = "groups";
export const users = "users";
export const support = "support";
// BILLING PERMISSIONS
export const consumptions = "consumptions";
export const staff_payment = "staff_payment";
export const receipts = "receipts";
export const billing_store = "billing_store";
export const billing_sales_history = "billing_sales_history";
//POS PERMISSIONS
export const pos_store = "pos_store";
export const pos_sales_history = "pos_sales_history";
export const cancel_sales = "cancel_sales"; //can use Billing & POS modules

export const generalPermisions = {
    close_shift,
    reports,
    sales_report,
    ranking_report,
    receipts_reports,
    statistics_report,
    cash_balance_report,
    settings,
    general,
    locations,
    billing,
    serials,
    taxes,
    exchange_rate,
    store,
    store_actions,
    employees,
    staff,
    volunteer,
    accounts,
    groups,
    users,
    support
};

export const billingPermisions = {
    consumptions,
    staff_payment,
    receipts,
    billing_store,
    billing_sales_history,
    cancel_sales 
};

export const posPermisions = {
    pos_store,
    pos_sales_history,
    cancel_sales
};

export const generalPermissionsTest = [
    {label: 'Closing Shift', value: close_shift},
    {label: 'Reports', value: reports},
    {label: 'Sales Report', value: sales_report},
    {label: 'Ranking Report', value: ranking_report},
    {label: 'Receipts Report', value: receipts_reports},
    {label: 'Statistics Report', value: statistics_report},
    {label: 'Cash Balance', value: cash_balance_report},
    {label: 'Settings', value: settings},
    {label: 'General', value: general},
    {label: 'Locations', value: locations},
    {label: 'Billing', value: billing},
    {label: 'Serials', value: serials},
    {label: 'Taxes', value: taxes},
    {label: 'Exchange rate', value: exchange_rate},
    {label: 'Store', value: store},
    {label: 'Store Actions', value: store_actions},
    {label: 'Employees', value: employees},
    {label: 'Staff', value: staff},
    {label: 'Volunteers', value: volunteer},
    {label: 'RRHH Payment', value: rrhh_payment},
    {label: 'Accounts', value: accounts},
    {label: 'Groups', value: groups},
    {label: 'Users', value: users},
    {label: 'Support', value: support}
];


export const billingPermissionsTest = [
    {label: "Consumptions", value:"consumptions"},
    {label: "Staff Payment", value:"staff_payment"},
    {label: "Receipts", value:"receipts"},
    {label: "Billing Store", value:"billing_store"},
    {label: "Billing Sales History", value:"billing_sales_history"},
    {label: "Cancel Sales", value:"cancel_sales"}
];

export const posPermissionsTest = [
    {label: "POS Store", value:"pos_store"},
    {label: "POS Sales History", value:"pos_sales_history"},
    {label: "Cancel Sales", value:"cancel_sales"}
];