import React, {useEffect} from 'react';
import {Input, InputAdornment, List, ListItem, ListItemText} from '@material-ui/core';
import NullMessage from '../../../common/components/error.pages/NullMessage'
import {pmsGuestsList} from "../../selector/customer.selector";
import {connect, useDispatch} from "react-redux";
import SearchIcon from "@material-ui/icons/SearchRounded";
import Classes from "../../../../styles";
import {searchSettingsVolunteerModal} from "../../actions/asyncAction";
import {getPMSGuest} from "../../../pos/redux/asyncActions";


const VolunteerSectionModal = ({guestListPMS, volunteerSearchValue, closeModal, handleAction}) => {
    const classes = Classes();
    const dispatch = useDispatch();
    let search;

    useEffect(() => {
            dispatch(getPMSGuest())
        }, [dispatch]
    );

    const handleVolunteerModalSearch = (e) => {
        clearTimeout(search)
        dispatch(searchSettingsVolunteerModal(e.target.value.trim()));
    };
    return (
        <div>
            <Input
                className={` general-forms- ${classes.forms.searchInput} general-colors- ${classes.colors.bgWhite}  
					                general-styles- ${classes.styles.bRadius18} general-styles- ${classes.styles.shadow1}`}
                id="volunteer-input-search" name="volunteer-input-search" placeholder="Search..."
                fullWidth
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon style={{color: "#9E9E9E"}}/>
                    </InputAdornment>
                }
                onChange={handleVolunteerModalSearch}
                value={volunteerSearchValue}
            />
            <br/>
            {
                guestListPMS.length ?
                    <List component="nav" aria-label="contacts"
                          style={{backgroundColor: "#fafafa", border: 50, maxHeight: 280, overflowY: "scroll"}}>
                        {
                            guestListPMS.map((guest, index) =>
                                <ListItem key={index} button onClick={() => {
                                    handleAction(guest);
                                    closeModal()
                                }}>
                                    <ListItemText primary={guest.fullName}/>
                                </ListItem>
                            )
                        }
                    </List>
                    : <NullMessage message="There are no guests from PMS" height={"10em"}/>
            }
        </div>

    );
};

const mapStateToProps = state => ({
    guestListPMS: pmsGuestsList(state),
    volunteerSearchValue: state.employeesAccountsSearchReducer.volunteerSearch,
});

export default connect(mapStateToProps,)(VolunteerSectionModal)