import React, {useEffect, useState} from "react";

import {Document, Page, StyleSheet, Text, View,} from "@react-pdf/renderer"

import ImagePDF from './imagePDF';

const BORDER_COLOR = "#bfbfbf"
const BORDER_STYLE = "solid"

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 80,
        paddingHorizontal: 15,
    },
    table: {
        display: "table",
        width: "auto",
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableColHeader: {
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: "#39CDC8",
        color: "#ffffff"
    },
    tableCol: {
        borderLeftWidth: 0,
        borderTopWidth: 0,
        lineHeight: 1.5
    },

    tableCellHeader: {
        margin: 5,
        fontSize: 10,
        fontWeight: 500

    },
    tableCell: {
        margin: '10px',
        fontSize: 8
    },
    image: {
        width: 70,
        heigth: 70
    },
    position: {
        position: 'absolute',
        right: 15
    },
    title: {
        fontSize: 16,
        marginBottom: 20,
        color: '#333333'
    },
    subtitle: {
        fontSize: 10,
        color: '#7a7a7a'
    },
    dateRange: {
        position: 'absolute',
        fontSize: 10,
        bottom: 15,
        left: 15,
        right: 0,
        textAlign: 'left',
        color: '#7a7a7a',
        padding: '20px 0'
    }

});


export default function PdfDocument(props) {
    const [title, setTitle] = useState("")

    useEffect(() => {
        const auxTitle = props.name.replace('-', ' ');
        setTitle(auxTitle)

    }, [])

    return (
        <Document>
            <Page style={styles.body}
                  orientation={props.numColumns < 10 ? "portrait" : "landscape"}
            >
                <View style={{marginBottom: 8}}>
                    <View>
                        <Text style={styles.title}>{title}</Text>
                        <Text style={styles.subtitle}>

                            {'From: ' + props.dateList.initDate + '  hrs: ' + props.dateList.initTime}
                            {', To: ' + props.dateList.endDate + '  hrs: ' + props.dateList.endTime}
                        </Text>

                    </View>
                    <View style={styles.position}>
                        <ImagePDF/>

                    </View>

                </View>

                {props.data.length > 0 ?

                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            {
                                Object.keys(props.data[0]).map((col, index) =>
                                    <View key={index + 1}
                                          style={[styles.tableColHeader, {width: 100 / props.numColumns + '%'}]}
                                    >
                                        <Text style={styles.tableCellHeader}>
                                            {col[0].toUpperCase() + col.toLowerCase().slice(1)}
                                        </Text>
                                    </View>
                                )
                            }


                        </View>
                        {
                            props.data.map((data, index) =>
                                <View key={index + 1}
                                      style={[styles.tableRow, {backgroundColor: (index) % 2 === 0 && '#f6f6f6'}]}>
                                    {
                                        Object.values(data).map((newdata, index) =>
                                            <View key={index + 1}

                                                  style={[styles.tableCol, {width: 100 / props.numColumns + '%',}]}
                                            >
                                                <Text style={styles.tableCell}>{newdata}</Text>
                                            </View>
                                        )
                                    }
                                </View>
                            )
                        }
                    </View>
                    : null
                }

                <Text style={styles.dateRange} fixed>
                    Report date: {props.currentDate}
                </Text>

            </Page>
        </Document>
    )
}


