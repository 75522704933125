import React from 'react';
import {connect, useDispatch} from "react-redux";

import {filterSearchMultiple, openModalView} from "../../actions/utilsActions"
// MATERIAL UI
import {makeStyles} from '@material-ui/core/styles';
import {Card, CardContent, CardHeader, IconButton, Modal} from '@material-ui/core';
// ICONS
import CloseIcon from '@material-ui/icons/CloseRounded';


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    paper: {
        maxWidth: "95vw",
        maxHeight: "95vh",
        overflowY: "auto",
        backgroundColor: theme.palette.background.paper,
        borderRadius: 10,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        '&:hover': {
            border: 'none!important'
        },
        '& .MuiCardHeader-title': {
            fontSize: "1.60rem",
            fontWeight: "400"
        }
    },
    btnFailed: {
        height: '3em',
        width: '8em',
        fontSize: '0.8em!important'

    }
}));


function ModalView({title, body, keyValue, door}) {
    const useclasses = useStyles();
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(openModalView(""));
        dispatch(filterSearchMultiple(""))
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={useclasses.modal}
            open={keyValue === door}
            onClose={handleClose}>
            <Card className={useclasses.paper} style={{outline: '0'}}>
                <CardHeader
                    action={
                        <IconButton style={{margin: "0 0 0 100px"}} aria-label="settings" onClick={handleClose}>
                            <CloseIcon/>
                        </IconButton>
                    }
                    title={title}
                />
                <CardContent>
                    {body}
                </CardContent>
            </Card>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        door: state.utilsReducer.modalViewReducer.door
    }
};

export default connect(mapStateToProps)(ModalView)