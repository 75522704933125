import {
    API_BALANCE_STATE_URL, API_CLIENT_RECEIPTS_HISTORY_URL,API_DOCUMENTS_DAILY_URL,API_DOCUMENTS_MONTHLY_URL,
    API_DOCUMENTS_WEEKLY_URL,API_DOCUMENTS_YEARLY_URL,API_RECEIPTS_BY_BALANCE_DASHBOARD_URL,
    API_SALES_TOTALS_BY_ORIGIN_URL,API_SALES_TOTALS_BY_TYPE_URL, CANCEL_ACCOUNT_CONSUMPTION_URL,
    CONSUMPTION_URL,SET_CONSUMPTION_STATUS_URL} from "./apiUrlsConstants";

import {
    baseDeleteAPIRequest,
    baseGetAPIRequest,
    basePostAPIRequest,
    basePutAPIRequest,
    baseSupportGetList
} from '../common/utils'

export function requestBalanceStatus() {
    return baseGetAPIRequest(API_BALANCE_STATE_URL);
}

export function requestDailyTransactions() {
    return baseGetAPIRequest(API_DOCUMENTS_DAILY_URL)
}

export function requestWeeklyTransactions() {
    return baseGetAPIRequest(API_DOCUMENTS_WEEKLY_URL);
}

export function requestMonthlyTransactions() {
    return baseGetAPIRequest(API_DOCUMENTS_MONTHLY_URL);
}

export function requestYearlyTransactions() {
    return baseGetAPIRequest(API_DOCUMENTS_YEARLY_URL);
}

export function requestReceiptsByBalance() {
    return baseGetAPIRequest(API_RECEIPTS_BY_BALANCE_DASHBOARD_URL);
}

export function requestSalesTotalsByType() {
    return baseGetAPIRequest(API_SALES_TOTALS_BY_TYPE_URL);
}

export function requestSalesTotalsByOrigin() {
    return baseGetAPIRequest(API_SALES_TOTALS_BY_ORIGIN_URL);
}

export function getGuestConsumption(data) {
    return basePostAPIRequest(CONSUMPTION_URL, data)
}

export function putConsumptionStatus(consumptionId, data) {
    const url = `${SET_CONSUMPTION_STATUS_URL}/${consumptionId}/`;
    return basePutAPIRequest(url, data)
}

export function requestClientReceiptsHistory(customerId,groupId,clientId) {
    const url = `${API_CLIENT_RECEIPTS_HISTORY_URL}?customer-id=${customerId}&group-id=${groupId}&client-id=${clientId}`;
    return baseGetAPIRequest(url)
}

export function requestSupportInformation() {
    return baseSupportGetList()
}

export function cancelAccountItem(saleId) {
    const url = `${CANCEL_ACCOUNT_CONSUMPTION_URL}/${saleId}`;
    return baseDeleteAPIRequest(url)
}