/*ACTIONS TYPES FOR SETTINGS MODULE*/
/*CONFIG*/
const CONFIG = "CONFIG"
export const LOAD_CONFIG = `LOAD_${CONFIG}`;

/* AREAS */
export const LOAD_AREAS = "LOAD_AREAS";
export const ADD_AREA = "ADD_AREA";
export const REMOVE_AREA = "REMOVE_AREA"
export const EDIT_AREA = "EDIT_AREA"
export const UPDATE_AREA_SERIAL = "UPDATE_AREAL_SERIAL";
export const DISMISS_AREA = "DISMISS_AREA";
export const AREA_SELECTED = "AREA_SELECTED";

/*TAXES CHARGES*/
const TAX = "TAX";
export const LOAD_TAX = `LOAD_${TAX}`;
export const ADD_TAX=`ADD_${TAX}`;
export const REMOVE_TAX =`REMOVE_${TAX}`;
export const EDIT_TAX = `EDIT_${TAX}`;

/*CURRENCIES*/
const CURRENCY = "CURRENCY"
export const EDIT_CURRENCY =`EDIT_${CURRENCY}`;
export const LOAD_EXCHANGE_RATE = 'LOAD_EXCHANGE_RATE';
export const LOAD_EXCHANGE_RATE_FAILED = 'LOAD_EXCHANGE_RATE_FAILED';
export const UPDATE_EXCHANGE_RATE = 'UPDATE_EXCHANGE_RATE';
export const UPDATE_FAIL = 'UPDATE_FAIL';
export const CONSULTING_EXCHANGE_RATE = "CONSULTING_EXCHANGE_RATE"
export const CONSULTING_EXCHANGE_RATE_FAILED = "CONSULTING_EXCHANGE_RATE_FAILED"

/* SERIAL */
const SERIAL = "SERIAL"
export const  LOAD_SERIAL =  `LOAD_${SERIAL}`;
export const  ADD_SERIAL =  `ADD_${SERIAL}`;
export const  EDIT_SERIAL =  `EDIT_${SERIAL}`;


/* USERS */
const USER = "USER";
export const  LOAD_USERS =  'LOAD_USERS';
export const  ADD_USER =  `ADD_${USER}`;
export const  EDIT_USER =  `EDIT_${USER}`;
export const  REMOVE_USER =  `REMOVE_${USER}`;


/* GROUPS */
const GROUP = "GROUP";
export const  LOAD_GROUPS =  'GET_GROUPS';
export const  ADD_GROUP =  `ADD_${GROUP}`;
export const  EDIT_GROUP =  `EDIT_${GROUP}`;
export const  REMOVE_GROUP =  `REMOVE_${GROUP}`;

/*STORE CATEGORIES */
const CATEGORY = "CATEGORY"
export const LOAD_CATEGORY_LIST = `LOAD_${CATEGORY}_LIST`;
export const CREATE_CATEGORY = `CREATE_${CATEGORY}`;
export const UPDATE_CATEGORY = `UPDATE_${CATEGORY}`;
export const REMOVE_CATEGORY = `REMOVE_${CATEGORY}`;
export const SELECT_CATEGORY = `SELECT_${CATEGORY}`;
export const SHOW_CATEGORY_FORM = `SHOW_CATEGORY_FORM`
export const SHOW_CREATED_CATEGORY = "SHOW_CREATED_CATEGORY";
export const TOGGLE_CREATE_EDIT_CATEGORY = "TOGGLE_CREATE_EDIT_CATEGORY";

/* STORE PRODUCTS */
const PRODUCT = "PRODUCT"
export const LOAD_PRODUCT_LIST = `LOAD_${PRODUCT}_LIST`;
export const CREATE_PRODUCT = `CREATE_${PRODUCT}`;
export const UPDATE_PRODUCT = `UPDATE_${PRODUCT}`;
export const REMOVE_PRODUCT = `REMOVE_${PRODUCT}`;
export const FILTER_VISIBLE_PRODUCTS = "FILTER_VISIBLE_PRODUCTS";
export const PRODUCT_SELECTED = `${PRODUCT}_SELECTED`;
export const LOAD_OUTLET_LIST = 'LOAD_OUTLET_LIST';
export const UPDATE_TOTAL_PRODUCTS = 'UPDATE_TOTAL_PRODUCTS';
export const UPDATE_PRODUCT_CATEGORY = 'UPDATE_PRODUCT_CATEGORY';
export const UPDATE_ALL_PRODUCTS = 'UPDATE_ALL_PRODUCTS';
export const PUT_ALL_PRODUCTS = 'PUT_ALL_PRODUCTS';
export const CHANGE_PREVIEW_APP = "CHANGE_PREVIEW_APP"
export const UPDATE_PREVIEW_CATEGORY = "UPDATE_PREVIEW_CATEGORY";
export const DROP_PRODUCT_LIST = "DROP_PRODUCT_LIST";
export const ACTIVE_ALERT_DRAG_DROP = "ACTIVE_ALERT_DRAG_DROP"

export const GET_CREATED_PRODUCT = "GET_CREATED_PRODUCT"

/* Company */
export const LOAD_COMPANY = "LOAD_COMPANY";

/* Country */
export const LOAD_COUNTRY = "LOAD_COUNTRY";

/* Connection */
export const ADD_CONNECTION = "ADD_CONNECTION";
export const LOAD_CONNECTION = "LOAD_CONNECTION";

/* Staff and Customer */

export const LOAD_CUSTOMERS = 'LOAD_CUSTOMER';
export const LIST_CUSTOMERS = 'LIST_CUSTOMER';
export const ADD_CUSTOMER  = 'ADD_CUSTOMER';
export const EDIT_CUSTOMER  = 'EDIT_CUSTOMER';
export const REMOVE_CUSTOMER  = 'REMOVE_CUSTOMER';
export const FILL_CUSTOMER  = 'FILL_CUSTOMER';
export const SET_CUSTOMER_TYPE = 'SET_CUSTOMER_TYPE';
export const EMPTY_CUSTOMER = "EMPTY_CUSTOMER";


export const LOAD_COMMON_OPTION = "LOAD_COMMON";
export const LOAD_ACCOUNTS = "LOAD_ACCOUNTS";
export const SEARCH_SETTINGS_STAFF = "SEARCH_SETTINGS_STAFF";
export const SEARCH_SETTINGS_USER = "SEARCH_SETTINGS_USER";
export const SEARCH_SETTINGS_GROUPS = "SEARCH_SETTINGS_GROUPS";
export const SEARCH_SETTINGS_VOLUNTEERS = "SEARCH_SETTINGS_VOLUNTEERS";
export const SEARCH_SETTINGS_VOLUNTEER = "SEARCH_SETTINGS_VOLUNTEER";

export const LOAD_LOCATIONS = "LOAD_LOCATIONS";
export const LOAD_GENERAL = "LOAD_GENERAL";
export const LOAD_BILLING = "LOAD_BILLING";

export const HANDLE_SWITCH_CHARGE = "TOGGLE_SWITCH_CHARGE"
export const HANDLE_INFO_CHARGE = "HANDLE_INFO_CHARGE"
export const GET_TAXES_BY_OUTLETS = "GET_TAXES_BY_OUTLETS"
export const ACTIVE_SWITCH_STORE = "ACTIVE_SWITCH_STORE"
export const TOGGLE_SELECT_OUTLET = "TOGGLE_SELECT_OUTLET"
export const GET_EVENT_TYPE_PRODUCT = " GET_EVENT_TYPE_PRODUCT"
export const GET_CUSTOMIZED_CHARGES = "GET_CUSTOMIZED_CHARGES"