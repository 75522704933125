import React from 'react';
import {store_actions} from "../../permissionsConstants";
// COMPONENTS
import Product from './Product';
import DynamicDialog from "../../../common/components/modals/DynamicDialog"
import NullMessage from "../../../common/components/error.pages/NullMessage"
import LinearProgress from '@material-ui/core/LinearProgress';

// MATERIAL UI
import {Box, Grid} from '@material-ui/core';

let ProductList = ({productList, onDragStart, editProduct , categories, removeProduct, createdProduct, 
    permissionsList, openModal,selectedProduct, animation, dropProductList, currentDragProduct, currentCategory}) => {
        
    const permissions = Object.values(permissionsList);
    const canManageSettingsStore = permissions.includes(store_actions) || permissions.includes("superuser");
    const dropProduct = productList.length > 0  && (dropProductList !== undefined && dropProductList.length > 0) ? productList.filter(prod => prod.id == currentDragProduct) : {}
    const existDropPoduct = dropProduct.length > 0 ? true : false
    const productIds = productList.map(prod => prod.id);
    const newProductId = Math.max(...productIds);
    const auxCreatedProduct = productList.length > 0 ? productList.filter(prod =>  prod.id == newProductId) : {}
    const currentProductList = productList.length > 0 ? productList.filter(prod =>  existDropPoduct ? prod.id !== currentDragProduct : prod.id !== newProductId): []
    const auxProductList = productList.length > 0 ? (existDropPoduct ? [dropProduct[0],...currentProductList] : (createdProduct == "CREATE" ? [auxCreatedProduct[0],...currentProductList] : productList)) : []
                   
    return (
        <Box pt={2} style={{height: '60vh', overflowY: "auto", overflowX: "hidden" }} pr={3.5} >
            <Grid container spacing={3} >
                {
                    auxProductList.length > 0 ? 
                        (createdProduct == "PRE-CREATE" ?
                            <Box  width={"100%"} mt={17} pl={10} pr={10}>
                                <LinearProgress color="primary"/> 
                            </Box>
                            : auxProductList
                            .map((product, index) => (
                                <Grid key={index + 1} item xs={6} sm={4} md={4} lg={4} >
                                    <Product
                                        product={product}
                                        editProduct={editProduct}
                                        onDragStart={onDragStart}
                                        categories={categories}
                                        createdProduct={createdProduct}
                                        productList={productList}
                                        openModal={openModal}
                                        selectedProduct={selectedProduct}
                                        animation={animation}
                                        dropProductList={dropProductList}
                                        canManage={canManageSettingsStore}
                                    />
                                </Grid>
                            ))
                    ) : 
                    <Grid  item xs={12} sm={12} md={12} lg={12}>
                        <NullMessage message="There are no products" height={"21em"} />
                    </Grid>
                }
                <DynamicDialog
                title={"Are you sure?"}
                keyValue="product"
                msg={"Do you want to delete this product?"}
                type={"delete"}
                handleFunction={removeProduct} />
            </Grid>
        </Box>
    )
};

export default ProductList