import React, {useEffect, useState} from 'react';
// CSS
import Classes from '../../../../styles'
// COMPONENTS
import InfoList from '../utils/InfoList';
// MATERIAL UI
import {
    activeSwitchStore,
    getCreatedProduct,
    getTaxesByOtulets,
    selectCategory,
    toggleSelectOutlet
} from '../../actions/store/sync';

import {
    Box,
    Button,
    FormControl,
    FormGroup,
    Grid,
    Hidden,
    InputLabel,
    MenuItem,
    TextField,
    Typography
} from '@material-ui/core';

import {connect, useDispatch} from 'react-redux';
import {Field, reduxForm,} from 'redux-form';
import {submitProductsForm, validate} from "../../forms/submit/product.submit";
import {handleViewPaymentPanel} from "../../../common/actions/actions"

import {
    renderSelectField,
    renderSelectFilter,
    renderSwitch,
    renderSwitchExemption,
    renderTextField,
    SwitchGroupStore
} from "../../../common/components/utils/ReduxFromMaterialUI";

import {taxesByOutletsSelector, taxesByProducts} from "../../selector/billing.selector";
import {getCategories, getOutlets, getTaxes} from "../../actions/store/async";
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import UnchekIcon from '@material-ui/icons/RadioButtonUnchecked';

let FormProduct = ({
                       handleSubmit, array: {push}, submitFailed, customizedCharges, pristine, reset, submitting,
                       outletList, taxesList, taxesByOutlets, activedSwitchStore, activedOutlet, selectedCategoryName,
                       categoryId, eventTypeProduct, categoryList, initialValues
                   }) => {
    const classes = Classes();
    const dispatch = useDispatch();
    const [selectedOutlet, setSelectedOutlet] = useState(0)
    const taxList = taxesList.filter(opt => opt.type === "TAX");
    const taxId = taxList[0] ? taxList[0].value : "1";
    const handleCancel = () => {
        reset();
        window.history.back()
    };

    const handleSelectOutlet = (event) => {
        if (event > 0) {
            let outlet = event
            setSelectedOutlet(outlet)
            const excises = outletList[outlet - 1].excises;
            dispatch(getTaxesByOtulets(excises));
            dispatch(activeSwitchStore(false));
            dispatch(toggleSelectOutlet(true));
        } else {
            setSelectedOutlet(0)
            dispatch(toggleSelectOutlet(false));
            dispatch(activeSwitchStore(true));
        }
    };

    useEffect(() => {
        dispatch(handleViewPaymentPanel(false))
        dispatch(getOutlets());
        dispatch(getTaxes());
        dispatch(getCategories());
        dispatch(getCreatedProduct(""))
        if (eventTypeProduct === "EDIT") {
            dispatch(toggleSelectOutlet(false));
        }

        if (!selectedCategoryName) {
            handleCancel()
        }
    }, []);

    const addSubmit = () => {
        if (!submitFailed) {
            if (!activedOutlet) {
                push('taxes', taxId)
                push('selectOutlet', customizedCharges)
            }
            if (activedOutlet) {
                if (customizedCharges !== null) {
                    push('taxesByOutlets', taxId)
                    push('taxesByOutlets', customizedCharges)
                    push('selectOutlet', customizedCharges)
                } else {
                    push('taxesByOutlets', taxId)
                    push('selectOutlet', 1)
                }
            }
        }
        selectCategory(categoryId, selectedCategoryName)
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container item spacing={3}>
                <Grid item xs={12}>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={2}>
                            <InfoList title={"Description"}
                                      description={"The new created product will appear on the product list of RUMIpos once have been categorized"}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={5}>
                            <FormControl
                                className={`general-forms- ${classes.forms.formMargin} ${classes.forms.fullFormWidth}`}>
                                <Field
                                    id="standard-input"
                                    label="Name *"
                                    name="name"
                                    component={renderTextField}
                                    className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} ${classes.forms.fullFormWidth}`}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                            <FormControl
                                className={`general-forms- ${classes.forms.formMargin} ${classes.forms.fullFormWidth}`}>
                                <Field
                                    id="standard-input"
                                    label="Receipt name"
                                    name="receiptName"
                                    component={renderTextField}
                                    className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} ${classes.forms.fullFormWidth}`}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                            <FormControl
                                className={`general-forms- ${classes.forms.formMargin} ${classes.forms.fullFormWidth}`}>
                                <Field
                                    id="standard-input"
                                    label="Description"
                                    name="description"
                                    component={renderTextField}
                                    className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} ${classes.forms.fullFormWidth}`}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                            <Hidden smDown>
                                <FormControl
                                    className={`general-forms- ${classes.forms.formMargin} ${classes.forms.fullFormWidth}`}>
                                    <TextField className={`general-forms- ${classes.forms.textFieldBox} `}
                                               id="standard-required"
                                               label="Category"
                                               value={selectedCategoryName || ""}
                                               disabled
                                               InputLabelProps={{
                                                   shrink: true
                                               }}
                                    />
                                </FormControl>
                            </Hidden>
                            <Hidden mdUp>
                                <FormControl
                                    className={`general-forms- ${classes.forms.formMargin} ${classes.forms.fullFormWidth}`}>
                                    <Field className={`${classes.forms.select}`}
                                           component={renderSelectField}
                                           label="Category *"
                                           name="category">
                                        <MenuItem value={0} disabled>
                                            <em>Select a Category...</em>
                                        </MenuItem>
                                        {categoryList.map(category =>
                                            <MenuItem aria-label="None" className={classes.forms.option}
                                                      value={category.id}
                                                      key={category.id}>{category.name}</MenuItem>
                                        )}
                                    </Field>
                                </FormControl>
                            </Hidden>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={2}>
                            <InfoList title={"Outlets"}
                                      description={"This set up come from settings/taxes & charges by outlet, but you can do some taxes adjustments only for this product."}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={5}>
                            <FormControl
                                className={`general-forms- ${classes.forms.formMargin} ${classes.forms.fullFormWidth}`}>
                                <Field className={`${classes.forms.select}`}
                                       component={renderSelectFilter}
                                       label="Outlets *"
                                       name="outlet"
                                       inputProps={{
                                           "handleSelectOutlet": handleSelectOutlet,
                                           "selectedValue": selectedOutlet
                                       }}>
                                    <MenuItem value={0} disabled>
                                        <em>Select a Outlet...</em>
                                    </MenuItem>
                                    {outletList.map(outlet =>
                                        <MenuItem aria-label="None" className={classes.forms.option} value={outlet.id}
                                                  key={outlet.id}>{outlet.name}</MenuItem>
                                    )}
                                </Field>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={2}>
                            <InfoList title={"Visibility"}
                                      description={"Will define in wich app will appear this new created product."}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <FormControl className={` general-forms- ${classes.forms.formWidth}`}>
                                <InputLabel shrink component="legend" color="secondary">App</InputLabel>
                                <br/>
                                <FormGroup>
                                    <Field
                                        name="displayBilling"
                                        label="Rumi Billing"
                                        component={renderSwitch}
                                        value={false}
                                    />
                                    <br/>
                                    <Field
                                        name="displayPos"
                                        label="Rumi POS"
                                        component={renderSwitch}
                                        value={false}
                                    />
                                    <br/>
                                    <Field
                                        name="variablePrice"
                                        label="Variable Price"
                                        component={renderSwitch}
                                        value={false}
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={2}>
                            <InfoList title={"Prices"}
                                      description={"This prices will be shown for your costumers to paid in."}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={10}>
                            <FormControl className={`general-forms- ${classes.forms.formMargin} `}
                                         style={{width: 200, marginRight: "2em"}}>
                                <Field
                                    id="standard-input"
                                    label="Price *"
                                    name="price"
                                    type="number"
                                    component={renderTextField}
                                    className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} `}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                            <FormControl style={{width: 200}}>
                                <Field
                                    id="standard-input"
                                    label="Special price"
                                    name="specialPrice"
                                    type="number"
                                    component={renderTextField}
                                    className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} `}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={2}>
                            <InfoList title={"Taxes "}
                                      description={"Your product will be affected by the addition of taxes on price."}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={10}>
                            <Box display="flex" flexWrap="nowrap" flexDirection="row">
                                {
                                    false &&
                                    <Box mt={2} mr={8}>
                                        <Typography variant="body1" color="textSecondary"
                                                    gutterBottom>outlet or customized</Typography>
                                    </Box>
                                }
                                <Box mr={6}>
                                    <InputLabel shrink component="legend" color="secondary">Tax</InputLabel>
                                    <Box mt={1}>
                                        {
                                            activedSwitchStore ?
                                                <UnchekIcon color="primary"/> :
                                                <CheckIcon color="primary"/>
                                        }
                                    </Box>
                                </Box>
                                <Box mr={4}>
                                    <InputLabel shrink component="legend" color="secondary">Exemption</InputLabel>
                                    <Box mt={1}>
                                        <Field
                                            name="applyTaxDiscount"
                                            label=""
                                            component={renderSwitchExemption}
                                            value={true}
                                            activedSwitchStore={activedSwitchStore}
                                        />
                                    </Box>
                                </Box>
                                <Box>
                                    <InputLabel shrink component="legend" color="secondary">Charge</InputLabel>
                                    <Box mt={1}>
                                        <SwitchGroupStore name="taxes" options={taxesList}
                                                          taxesByOutlets={taxesByOutlets}
                                                          inputProps={{
                                                              "activedOutlet": activedOutlet,
                                                              "activedSwitchStore": activedSwitchStore,
                                                              "eventTypeProduct": eventTypeProduct,
                                                              "customizedCharges": customizedCharges
                                                          }}/>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={2}/>
                <Grid item xs={12} lg={10}>
                    <Button type="submit" variant="contained" color="primary"
                            style={{margin: '0 0.8em 0 0', width: '100px'}}
                            disabled={pristine || submitting}
                            onClick={() => addSubmit()}
                    >Save</Button>
                    <Button type="button" variant="outlined" color="primary"
                            style={{margin: '0 0.8em 0 0', width: '100px'}}
                            onClick={handleCancel}
                    >Cancel</Button>
                </Grid>
            </Grid>
        </form>
    );
};

FormProduct = reduxForm({
    form: 'product',
    onSubmit: submitProductsForm,
    validate
})(FormProduct);

FormProduct = connect(
    state => ({
        outletList: Object.values(state.storeReducer.outletList),
        taxesList: taxesByProducts(state.billingSettingReducer.taxesList),
        categoryList: Object.values(state.storeReducer.categoryList),
        initialValues: {category: state.storeReducer.currentCategory, "applyTaxDiscount": true},
        selectedCategoryName: state.storeReducer.selectedCategoryName,
        categoryId: state.storeReducer.currentCategory,
        taxesByOutlets: taxesByOutletsSelector(state.storeReducer.taxesByOutlets),
        activedSwitchStore: state.storeReducer.activedSwitchStore,
        activedOutlet: state.storeReducer.selectedOutlet,
        eventTypeProduct: state.storeReducer.eventTypeProduct,
        customizedCharges: state.storeReducer.customizedCharges
    })
)(FormProduct);

export default FormProduct