import {GUEST, STAFF} from "../../pos/posConstants";

const getCurrentCustomer = state => state.client
const getCustomerType = state => state.paymentTypeSelected.paymentCustomerType

export const getCustomerIdentifier = state => {
    let identifier = null;
    let group = null;
    let reservationId= null;
    const customerType = getCustomerType(state);
    const isGuest = customerType === GUEST;
    const isStaff = customerType === STAFF;
    if (isGuest || isStaff) {
        const customer = getCurrentCustomer(state);
        identifier = isGuest ? customer.customerId : customer.id;
        //  customerID  from PMS  - id From system
        group = customer.groupId
        reservationId = customer.reservationId ? customer.reservationId : null;
    }
    return {identifier, customerType, group,reservationId}
};