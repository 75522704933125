const filterReport = (filterCriteria, filterValue, inputReport) => {
    if (!filterValue) return inputReport;
    let found = false;
    return inputReport.filter(obj => {
        for (let key of filterCriteria) {
            if (obj.hasOwnProperty(key)) {
                found = (obj[key] || '').toString().toLowerCase().includes(filterValue.toLowerCase());
                if (found) break
            }
        }
        return found
    })
};

const searchReport = (searchCriteria, searchValue, inputReport) => {
    if (!searchValue) return inputReport;
    let found = false;
    return inputReport.filter(obj => {
        for (let key of searchCriteria) {
            if (obj.hasOwnProperty(key)) {
                found = (obj[key] || '').toString().toLowerCase().includes(searchValue.toLowerCase());
                if (found) break
            }
        }
        return found
    })
};

const handleDataSearchedAndFiltered = (filterCriteriaKeys, searchCriteriaKeys) => (report, filterValue, searchValue) => {
    let filteredReport = filterReport(filterCriteriaKeys, filterValue, report);
    return searchReport(searchCriteriaKeys, searchValue, filteredReport);
};

const salesReportSearchCriteria = ['user', 'type', 'customer', 'name', 'product'];
const salesReportFilterCriteria = ['outlet'];

const RankingReportSearchCriteria = ['product'];
const rankingReportFilterCriteria = ['outlet'];

const ticketsReportSearchCriteria = ['serial', 'documentNumber', 'name', 'type', 'relatedInvoice'];
const ticketsReportFilterCriteria = ['location'];

const invoicesReportSearchCriteria = ['serial', 'documentNumber', 'name', 'type', 'relatedInvoice'];
const invoicesReportFilterCriteria = ['location'];

const creditNotesReportSearchCriteria = ['serial', 'documentNumber', 'name', 'relatedReceipt', 'relatedDate', 'relatedReceiptType'];
const creditNotesReportFilterCriteria = ['location'];


export const handleSalesReportFilterAndSearch = handleDataSearchedAndFiltered(salesReportFilterCriteria, salesReportSearchCriteria);
export const handleRankingReportFilterAndSearch = handleDataSearchedAndFiltered(RankingReportSearchCriteria, rankingReportFilterCriteria);
export const handleTicketsReportFilterAndSearch = handleDataSearchedAndFiltered(ticketsReportSearchCriteria, ticketsReportFilterCriteria);
export const handleInvoicesReportFilterAndSearch = handleDataSearchedAndFiltered(invoicesReportSearchCriteria, invoicesReportFilterCriteria);
export const handleCreditNotesReportFilterAndSearch = handleDataSearchedAndFiltered(creditNotesReportSearchCriteria, creditNotesReportFilterCriteria);
