import React from "react";
// CSS
import Classes from '../../../../styles'
import SearchMultiple from '../../../common/components/utils/SearchMultiple'
// MATERIAL UI
import {Box, Button, Typography, Grid} from '@material-ui/core';
import {storePreviewSelector, getPreviewCategorySelected} from "../../selector/store.selector";
import {connect} from "react-redux";
import {handleProductsSearch,
} from "../../../common/selectors/utilsSelector";
import ProductPreview from "./ProductPreview"
import NullMessage from '../../../common/components/error.pages/NullMessage'
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    gridContainer:{
        paddingLeft: 15,
        paddingTop: 0,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingTop: 20
        },
    }
}));

const ProductListPreview = ({categories, filteredProducts, category}) => {
    const classes = Classes();
    const useclasses = useStyles()

    return (
        !category ? <p>Loading...</p> :
            <Grid container item spacing={3} >
                <Grid item xs={12}>
                    <Box className={useclasses.gridContainer}>
                        <Typography variant="h6" color="textSecondary">
                            Products
                        </Typography>
                    </Box>
                </Grid>
                <Grid xs={12} item style={{height: '65vh', overflowY: 'auto'}} className="scroll">
                    <Grid container item >
                    {
                        filteredProducts.length ? filteredProducts.map((prod, index) => (
                        <Grid item xs={6} sm={4} md={4} lg={3} key={index + 1} style={{padding:15}} >
                            <ProductPreview product={prod} categories={categories}/>
                        </Grid>
                        )) :
                        <Grid item xs={12} >
                            <NullMessage message="There are no products" height={"21em"} />
                        </Grid>
                    }
                    </Grid>
                </Grid>
            </Grid>
           
    )
};

const mapStateToProps = state => {
    const {categoryList: categories} = storePreviewSelector(state)
    let {productList: products} = storePreviewSelector(state)
    const category = getPreviewCategorySelected(state)
    const searchValue = state.utilsReducer.search
    const productsFilterByCategory = products.filter(prod => prod.category === category)
    const filteredProducts = !searchValue ? productsFilterByCategory : handleProductsSearch(searchValue,products)
    return {
        categories,
        filteredProducts,
        category
    }
};

export default connect(mapStateToProps)(ProductListPreview)
