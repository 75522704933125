import React from "react";
import {connect} from "react-redux"

import XLSX from "xlsx";
import {PDFDownloadLink} from "@react-pdf/renderer";
// CSS
import Classes from '../../../../styles'
import {IconButton} from "@material-ui/core";

// COMPONENTS
import PdfDocument from "./PdfDocument"
// ICONS
import PdfIcon from "../../../../assets/icons/pdf-file.svg"
import ExcelIcon from "../../../../assets/icons/excel-file.svg"
import PdfIconDisabled from "../../../../assets/icons/pdf-file-gray.svg"
import ExcelIconDisabled from "../../../../assets/icons/excel-file-gray.svg"


function ExportButtons(props) {
    const classes = Classes();

    const exportExcel = (obj, name) => {
        var data = obj;
        var nameDocument = name;
        const header = [
            {"name": "John", "city": "Seattle"},
            {"name": "Mike", "city": "Los Angeles"},
            {"name": "Zach", "city": "New York"}
        ];
        var ws = XLSX.utils.json_to_sheet(data);
        var wh = XLSX.utils.json_to_sheet(header);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, nameDocument);
        XLSX.writeFile(wb, `${nameDocument}.xlsx`);
    };

    if (props.report.length > 0) {
        return (
            <div>
                <IconButton onClick={exportExcel.bind(this, props.report, props.name)}>
                    <img src={ExcelIcon} style={{width: '32px', height: '32px'}} alt=""/>
                </IconButton>
                <PDFDownloadLink
                    className={`menu- ${classes.nav.link}`}
                    document={<PdfDocument
                        data={props.report ? props.report : []}
                        name={props.name}
                        numColumns={props.report.length > 0 ? Object.keys(props.report[0]).length : []}
                        dateList={props.dateList}
                        currentDate={props.currentDate}
                    />}
                    fileName={props.name}>
                    {({blob, url, loading, error}) => (loading ? "Loading document..." :
                            <IconButton>
                                <img src={PdfIcon} style={{width: '32px', height: '32px'}} alt=""/>
                            </IconButton>
                    )}
                </PDFDownloadLink>
            </div>
        );
    } else {
        return (
            <div>
                <IconButton disabled>
                    <img src={ExcelIconDisabled} style={{width: '32px', height: '32px'}} alt=""/>
                </IconButton>
                <IconButton disabled>
                    <img src={PdfIconDisabled} style={{width: '32px', height: '32px'}} alt=""/>
                </IconButton>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        dateList: state.utilsReducer.getDateRange,
        currentDate: state.utilsReducer.currentDate
    }
};

export default connect(mapStateToProps)(ExportButtons)