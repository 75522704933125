import {
    GET_ALERT,
    GET_REQUEST,
    GET_RESPONSE
} from '../actions/actionTypes'

const initialState = {
    status: 'close',
    msg:'',
    open: false,
    request: null,
    response: {}
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_ALERT:
            return {
                ...state,
                status: action.status,
                open: action.open,
                msg: action.msg
            }
        case GET_REQUEST:
            return {
                ...state,
                request: action.status
            }
        case GET_RESPONSE:
            return {
                ...state,
                response: action.response
            }
        default:
            return state;
    }
}