import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    basePanel:{
      '& .basePanelSummary':{

      }
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
        '&.expanded':{
            flexBasis: 'auto',
        }
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));