import React, {Fragment,} from 'react';

// COMPONENTS
import Product from "./Product";
//  MATERIAL UI
import {Box, Grid, Hidden} from '@material-ui/core';
import NullMessage from "../../../../common/components/error.pages/NullMessage"

const ProductList = ({products, addProductCart, addVariableProduct,editVariableProduct}) => {

    const handleProductSelection = (product, variablePrice = false, newPrice = null) => {
        if (variablePrice) {
            addProductCart(product);
            addVariableProduct(product, newPrice);
        } else {
            addProductCart(product);
        }
    }

    const handleEditVariableProduct = (product, variablePrice = null, newPrice) =>{
        if(variablePrice){
            editVariableProduct(product,newPrice)
        }
    }

    return (
        <Fragment>
            <Hidden smDown>
                <Box style={{height: '72vh', overflowY: "auto"}}>
                    {products.length ?
                        <Grid container style={{width: "100%"}}>
                            {products.map(function (product, index) {
                                return (
                                    <Grid item xs={4} key={index + 1} style={{padding: 5}}>
                                        <Product {...product} addProduct={handleProductSelection} editVariablePrice={handleEditVariableProduct}/>
                                    </Grid>
                                )
                            })}
                        </Grid>
                        : <NullMessage message="There are no products" height={"21em"}/>
                    }
                </Box>
            </Hidden>
            <Hidden mdUp>
                {products.length ?
                    <Grid item container>
                        {products.map(function (product, index) {
                            return (
                                <Grid item xs={4} sm={3} key={index + 1} style={{padding: 5}}>
                                    <Product {...product} addProduct={handleProductSelection} editVariablePrice={handleEditVariableProduct}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                    : <NullMessage message="There are no products" height={"21em"}/>
                }
            </Hidden>
        </Fragment>
    )
};

export default ProductList;