import React from "react";
import {useSelector} from "react-redux";
import ClientListView from "./ClientListView";
import RoomListView from "./RoomListView";
import {showClientList} from "../../selector/utils.selector"


const RoomGuestList = () => {
    const showClients = useSelector(showClientList);
    return (
        <div>
            {
                showClients ?
                    <ClientListView/> : <RoomListView/>
            }
        </div>
    )
};
export default RoomGuestList