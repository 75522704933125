import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import {Badge, Box, Button, Grid, Typography} from '@material-ui/core/';
import Classes from "../../../../styles";
import {sendCodeForReauthorization} from "../../../common/redux/asyncActions";
import {useDispatch} from "react-redux";

const PmsAuthorizationReceiver = () => {
    const location = useLocation();
    const classes = Classes();
    const dispatch = useDispatch();
    const params = new URLSearchParams(location.search)

    useEffect(() => {
        if (params.get("code")) {
            dispatch(sendCodeForReauthorization(params.get("code")));
        }
    }, []);

    return (
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column"
             className={`${classes.landingBase}`}>
            <Typography variant="h6">You can close this tab now</Typography>
        </Box>
    );
}

export default PmsAuthorizationReceiver;