import {LOAD_COMPANY, LOAD_GENERAL} from "../actions/actionsTypes";

const companyReducer = (state= {}, action) => {
    switch (action.type) {
        case LOAD_GENERAL:
        case LOAD_COMPANY:
            return action.company.reduce((obj, company) => {
                return company
            }, {})
        default:
            return state
    }
}

export default companyReducer;

