import React from 'react';
import {Link, Typography} from "@material-ui/core";
import Classes from "../../../../styles";
import {connect} from "react-redux";

const CloudbedsAuthorizeButton = ({authorizationUrl}) => {
    const classes = Classes();

    return (
        <Typography variant="h6" className={classes.bold}>
            <Link type="button" variant="contained" color="primary" href={authorizationUrl} target="_blank">Reconnect
                With CLoudbeds</Link>
        </Typography>
    );
};

const mapStateToProps = (state) => {
    return {
        authorizationUrl: state.auth.userData.authorizationUrl,
    }
};
export default connect(mapStateToProps)(CloudbedsAuthorizeButton)