import {handleSupportFilter, handleSupportSearch} from "../../common/selectors/utilsSelector";

export const supportQuestionsList = state => {
    const searchValue = state.utilsReducer.search;
    const filterValue = state.supportReducer.filterValue;
    let filtered = state.supportReducer.supportQuestions;
    if(filterValue){
        filtered = handleSupportFilter(filterValue, state.supportReducer.supportQuestions)
    }
    if(searchValue){
        filtered = handleSupportSearch(searchValue, state.supportReducer.supportQuestions)
    }
    return filtered
};