import React from "react";
// MATERIAL UI
import {Box, Button, Typography} from "@material-ui/core";
import Classes from "../../../../styles";

const Location = ({location, handleRedirectToApp, activeLoader}) => {
    const classes = Classes();

    return (
        <Button variant="outlined" color="secondary" onClick={() => {
            activeLoader()
            handleRedirectToApp(location.id)
        }}
                className={`${classes.buttons.roundPillButton} filter`}>
            <Box lineHeight={2}>
                <Typography variant="body1"
                            className={classes.buttons.roundPillText}>
                    {location.name}
                </Typography>
            </Box>
        </Button>
    )
}

export default Location
