import {LOAD_BALANCE, SUCCESS_OPEN_BALANCE} from "../actions/actionTypes";

const initialState = {
    openBalance: true,
    primaryOpenData: {},
    secondaryOpenData: {},
    isFetch: false
};
const balanceReducer = (state = initialState, action) => {

    switch (action.type) {
        case LOAD_BALANCE:
            return {
                ...state,
                openBalance: action.openBalance,
                primaryOpenData: action.primaryOpenData,
                secondaryOpenData: action.secondaryOpenData,
                isFetch: true
            };
        case SUCCESS_OPEN_BALANCE:
            return {...state, openBalance: true};
        default :
            return state;
    }
};

export default balanceReducer