import React from 'react';
// CSS
import Classes from '../../../../styles/'
// COMPONENTS MATERIAL UI
import {Box, Button} from "@material-ui/core";
import AccountIcon from '../../../../assets/icons/payment.png'
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCartOutlined';

const Client = ({client, setClient, enable}) => {
    const classes = Classes();
    const clientFullNameToLowercase = !client.fullName ? "" : client.fullName.toLowerCase();
    const possibleStatus = {
        "checked_in":"Checked-in",
        "not_confirmed":"Not-confirm",
        "confirmed":"Confirmed",
        "canceled":"Cancelled",
        "checked_out":"Checked-out",
        "no_show":"No-Show",
    }
    return (
        <Button variant="outlined"
                disabled={!enable}
                className={`${classes.styles.shadow1} ${classes.styles.bRadius10} `}
                style={{
                    width: "100%", marginBottom: "1.5em", height: "3em", color: '#5BE576',
                    borderColor: !client.documentNumber ? 'rgb(245 188 0 / 59%)' : '#5BE576',
                    backgroundColor: client.debt > 200 ? 'rgba(255,122,122,0.20)' : 'transparent'
                }}
                onClick={setClient}>
            {
                client.debt ?
                    <div>
                        <img src={AccountIcon} style={{width: 20, height: 20}} alt='Account debt'/> <span style={{color:"#707070"}}>{client.debt.toFixed(2)}</span>
                    </div>
                    : null
            }
            <Box flexGrow={1} mr={'0'} color="grey.700"
                 fontSize={'0.9em'} lineHeight={'1.3em'} style={{textTransform: 'capitalize'}}>
                {possibleStatus[client.status]} - {clientFullNameToLowercase}
            </Box>
            {
                client.customerType === "STAFF" ?
                    <Box display="flex" flexDirection="column" flexShrink={1} style={{wordWrap: 'break-Word'}}>
                        {client.active === false ?
                            <RemoveShoppingCartIcon style={{fontSize: 18, marginLeft: 12, color: "#4A4A49"}}/> : null}
                        <Box m={0} fontSize={'0.7em'} justifyContent="center" color="text.hint"
                             className={classes.typography.textCapitalize}>
                            {
                                client.pmsGuestId === null ? "STAFF" : "VOLUNTEER"
                            }
                        </Box>
                    </Box>
                    :
                    <Box display="flex" flexDirection="column" flexShrink={1} style={{wordWrap: 'break-Word'}}>
                        <Box m={0} fontSize={'0.7em'} color="text.hint" justifyContent="center"
                        >{client.roomName}</Box>
                    </Box>
            }
        </Button>

    )
};

export default Client