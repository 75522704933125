import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Button, MenuItem, Select, TextField, Typography} from "@material-ui/core";
import {englishTexts, spanishTexts} from "./landingLanguaje";
import LogoSoloColores from "../../../../assets/images/Main/Main/LogoSoloColores.svg";
import landingIconBilling from "../../../../assets/landingAssets/landingIconBilling.svg"
import landingIconPos from "../../../../assets/landingAssets/landingIconPos.svg"
import ceoPicture from "../../../../assets/landingAssets/ceoPicture.png"
import landingDiagram from "../../../../assets/landingAssets/landingDiagram.svg"
import landingHighlighBackgroundImage from "../../../../assets/landingAssets/landingHighlighBackgroundImage.svg"
import testimonialBackgroundImage from "../../../../assets/landingAssets/testimonialBackgroundImage.svg"
import taxesTextBackgroundImage from "../../../../assets/landingAssets/taxesTextBackgroundImage.svg"

const useStyles = makeStyles(theme => ({
    landingBase: {},
    normal: {
        fontFamily: "Jost",
        fontWeight: "400"
    },
    semiBold: {
        fontFamily: "Jost",
        fontWeight: "600"
    },
    bold: {
        fontFamily: "Jost",
        fontWeight: "700"
    },
    extraBold: {
        fontFamily: "Jost",
        fontWeight: "900"
    },
    centerText: {
        textAlign: 'center'
    },
    buttons: {
        color: theme.palette.common.white,
        padding: "0.2em",
        textDecoration: "none",
        fontSize: "1.2em"
    },
    highlightSection: {
        backgroundColor: theme.palette.utils.linen
    },
    highlightBackground: {
        backgroundImage: `url(${landingHighlighBackgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
    },
    testimonialBackground: {
        backgroundImage: `url(${testimonialBackgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
    },
    taxesTextBackground: {
        backgroundImage: `url(${taxesTextBackgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
    },
    mainHolder: {
        paddingTop: "6em",
        paddingBottom: "6em",
    },
    header: {
        width: "100%",
        height: "6em",
        [theme.breakpoints.up('md')]: {
            width: "70%"
        }
    },
    mainHeaderText: {
        fontSize: "3em",
        paddingBottom: "1.5em",
        [theme.breakpoints.up('md')]: {
            fontSize: "4em",
            lineHeight: "1.4em"
        }
    },
    billingsRow: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
        width: "100%",
        [theme.breakpoints.up('md')]: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "60%",
        }
    },
    billingItems: {
        background: theme.palette.secondary.main,
        color: theme.palette.common.white,
        padding: "0.3em 0.3em",
        borderRadius: "12px",
        width: "250px",
        textAlign: "center",
        fontSize: "2em",
        [theme.breakpoints.up('md')]: {
            padding: "1.3em 0.3em",
        }
    },
    productsBoxes: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        [theme.breakpoints.up('md')]: {
            flexDirection: "row",
            width: "80%",
        }
    },
    productIcon: {
        marginBottom: "1.8em"
    },
    thinColumn: {
        width: "100%",
        [theme.breakpoints.up('md')]: {
            width: "50%",
        }
    },
    highlightColumn: {
        borderRadius: "20px",
        padding: "4em 4em 4em 4em",
    },
    highlightTitle: {
        paddingBottom: "1em",
    },
    highlightSubTitle: {
        paddingBottom: "2em",
    },
    testimonialHolder: {
        width: "100%"
    },
    testimonialInnerHolder: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        [theme.breakpoints.up('md')]: {
            flexDirection: "row-reverse",
            width: "80%"
        }
    },
    testimonialColumn: {
        width: "50%",
        color: theme.palette.common.white
    },
    testimonialText: {
        fontSize: "1.85em"
    },
    detailsInnerHolderEven: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        [theme.breakpoints.up('md')]: {
            flexDirection: "row-reverse",
        }
    },
    detailsInnerHolderOdd: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        [theme.breakpoints.up('md')]: {
            flexDirection: "row",
        }
    },
    detailsBoxes: {
        width: "100%",
        [theme.breakpoints.up('md')]: {
            flexDirection: "row",
            width: "50%",
        }
    },
    diagramHolder: {
        width: "95%",
        [theme.breakpoints.up('md')]: {
            flexDirection: "row",
            width: "80%",
        }
    },
    taxesText: {
        fontSize: "2.4em",
        paddingBottom: "1.5em",
        [theme.breakpoints.up('md')]: {
            fontSize: "4em",
            lineHeight: "1.4em"
        }
    },
    contactForm: {
        display: "flex",
        background: theme.palette.primary.main,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        [theme.breakpoints.up('md')]: {
            flexDirection: "row",
            width: "80%",
            borderRadius: "20px",
        }
    },
    textFields: {
        background: theme.palette.common.white,
        marginBottom: "1em",
        width: "100%"
    },
    contactButton: {
        background: theme.palette.grey['charcoal'],
        color: theme.palette.common.white
    },
    formHolder: {
        width: "100%"
    },
    formBoxes: {
        width: "100%",
        [theme.breakpoints.up('md')]: {
            width: "50%",
        }
    },
    footerHolder: {
        background: theme.palette.grey['matterhorn'],
        padding: "1em",
        color: theme.palette.common.white
    },
}));

const Landing = () => {
    localStorage.clear();
    const classes = useStyles();
    const [language, setLanguage] = React.useState('english');
    const [texts, setTexts] = React.useState(englishTexts);

    useEffect(() => {
        if (language === "english") {
            setTexts(englishTexts)
        }
        if (language === "spanish") {
            setTexts(spanishTexts)
        }
    }, [language]);

    const handleChange = (event) => {
        setLanguage(event.target.value);
    };

    return (
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column"
             className={`${classes.landingBase}`}>
            <Box display="flex" alignItems="flex-start" flexDirection="row" className={`${classes.header}`}>
                <Box flexGrow={1} m={3}>
                    <img src={LogoSoloColores} alt="Rumi Logo" height="70px" width="auto"/>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center" m={3}>
                    <Box mr={2}>
                        <Typography className={`${classes.normal}`}>{texts.areYouAclient}</Typography>
                    </Box>
                    <Box>
                        <Button variant="contained" color="secondary">
                            <Link to="/login" className={`${classes.buttons} ${classes.semiBold}`}>{texts.login}</Link>
                        </Button>
                    </Box>
                </Box>
                <Box m={3}>
                    <Select
                        value={language}
                        onChange={handleChange}>

                        <MenuItem value='english'>English</MenuItem>
                        <MenuItem value='spanish'>Spanish</MenuItem>
                    </Select>
                </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" width="50%"
                 className={`${classes.mainHolder} ${classes.bold}`}>
                <Typography className={`${classes.centerText} ${classes.mainHeaderText} ${classes.bold}`}>
                    {texts.mainPresentation}
                </Typography>
                <Button variant="contained" color="primary">
                    <Link to="#contactUs"
                          className={`${classes.buttons} ${classes.semiBold}`}>{texts.contactUsButton}</Link>
                </Button>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" m={3} width="100%"
                 className={`${classes.highlightBackground} ${classes.mainHolder}`}>
                <Typography className={`${classes.centerText} ${classes.mainHeaderText} ${classes.bold}`}>
                    {texts.billingPresentation}
                </Typography>
                <Box className={`${classes.billingsRow}`} m={1}>
                    <Box p={1}><Typography
                        className={`${classes.billingItems}`}>{texts.hostels}</Typography></Box>
                    <Box p={1}><Typography
                        className={`${classes.billingItems}`}>{texts.hotels}</Typography></Box>
                    <Box p={1}><Typography
                        className={`${classes.billingItems}`}>{texts.boutiques}</Typography></Box>
                </Box>
                <Box className={`${classes.billingsRow}`} m={1}>
                    <Box p={1}><Typography
                        className={`${classes.billingItems}`}>{texts.apartHotels}</Typography></Box>
                    <Box p={1}><Typography className={`${classes.billingItems}`}>{texts.bAndB}</Typography></Box>
                    <Box p={1}><Typography
                        className={`${classes.billingItems}`}>{texts.cabins}</Typography></Box>
                </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" width="100%"
                 className={`${classes.mainHolder}`}>
                <Typography className={`${classes.centerText}  ${classes.mainHeaderText} ${classes.bold}`}>
                    {texts.ourProducts}
                </Typography>
                <Box className={`${classes.productsBoxes}`}>
                    <Box display="flex" alignItems="flex-start" justifyContent="center" flexDirection="column" m={3}
                         className={`${classes.highlightColumn} ${classes.highlightSection} ${classes.thinColumn}`}>
                        <img src={landingIconBilling} width="100px" height="auto" className={`${classes.productIcon}`}
                             alt="icon-billing"/>
                        <Typography variant="h3" className={`${classes.highlightTitle} ${classes.bold}`}>
                            RUMI Billing
                        </Typography>
                        <Typography className={classes.highlightSubTitle}>
                            {texts.billingText}
                        </Typography>
                        <Button variant="contained" color="primary">
                            <Link to="/login"
                                  className={`${classes.buttons} ${classes.semiBold}`}>{texts.knowMore}</Link>
                        </Button>
                    </Box>
                    <Box display="flex" alignItems="flex-start" justifyContent="center" flexDirection="column" m={3}
                         className={`${classes.highlightColumn} ${classes.highlightSection} ${classes.thinColumn}`}>
                        <img src={landingIconPos} width="100px" height="auto" className={`${classes.productIcon}`}
                             alt="icon-pos"/>
                        <Typography variant="h3" className={`${classes.highlightTitle} ${classes.bold}`}>
                            RUMI POS
                        </Typography>
                        <Typography className={classes.highlightSubTitle}>
                            {texts.posText}
                        </Typography>
                        <Button variant="contained" color="primary">
                            <Link to="/login"
                                  className={`${classes.buttons} ${classes.semiBold}`}>{texts.knowMore}</Link>
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="row"
                 className={`${classes.testimonialHolder} ${classes.testimonialBackground} ${classes.mainHolder}`}>
                <Box className={`${classes.testimonialInnerHolder}`}>
                    <Box m={1} className={classes.testimonialColumn}>
                        <img src={ceoPicture} width="100%" alt="ceo-picture"/>
                    </Box>
                    <Box m={1} className={classes.testimonialColumn}>
                        <Typography className={classes.testimonialText}>
                            {texts.testimonial}
                        </Typography>
                        <br/>
                        <Typography variant="h5" className={classes.bold}>
                            Fernando Razetto, CEO de Pariwana
                        </Typography>
                        <Typography variant="h5">
                            Logo Pariwana
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" width="100%"
                 className={`${classes.mainHolder}`}>
                <Box>
                    <Typography className={`${classes.centerText} ${classes.mainHeaderText} ${classes.bold}`}>
                        {texts.howDoesItWork}
                    </Typography>
                </Box>
                <Box className={`${classes.diagramHolder}`} display="flex">
                    <img src={landingDiagram} width="100%" height="auto" alt="diagram"/>
                </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" width="100%"
                 className={`${classes.highlightSection} ${classes.mainHolder}`}>
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" width="80%">
                    <Box className={`${classes.detailsInnerHolderOdd}`}>
                        <Box className={`${classes.detailsBoxes}`}>SCREENSHOT</Box>
                        <Box className={`${classes.detailsBoxes}`}>
                            <Typography className={`${classes.mainHeaderText} ${classes.bold}`}>
                                {texts.firstScreenShotHeader}
                            </Typography>
                            <ul>
                                {Object.values(texts.firstScreenShotText).map((item, index) => <li
                                    key={index}>{item}</li>)}
                            </ul>

                        </Box>
                    </Box>
                    <Box className={`${classes.detailsInnerHolderEven}`}>
                        <Box className={`${classes.detailsBoxes}`}>SCREENSHOT</Box>
                        <Box className={`${classes.detailsBoxes}`}>
                            <Typography className={`${classes.mainHeaderText} ${classes.bold}`}>
                                {texts.secondScreenShotHeader}
                            </Typography>
                            <ul>
                                {Object.values(texts.secondScreenShotText).map((item, index) => <li
                                    key={index}>{item}</li>)}
                            </ul>

                        </Box>
                    </Box>
                    <Box className={`${classes.detailsInnerHolderOdd}`}>
                        <Box className={`${classes.detailsBoxes}`}>SCREENSHOT</Box>
                        <Box className={`${classes.detailsBoxes}`}>
                            <Typography className={`${classes.mainHeaderText} ${classes.bold}`}>
                                {texts.thirdScreenShotHeader}
                            </Typography>
                            <ul>
                                {Object.values(texts.thirdScreenShotText).map((item, index) => <li
                                    key={index}>{item}</li>)}
                            </ul>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" width="100%"
                 className={`${classes.taxesTextBackground} ${classes.bold}`}>
                <Typography className={`${classes.centerText} ${classes.taxesText} ${classes.bold}`}>
                    {texts.taxesText}
                </Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" width="100%"
                 className={`${classes.mainHolder}`}>
                <Box className={`${classes.contactForm} ${classes.mainHolder}`}>
                    <Box className={`${classes.formBoxes}`} display="flex" alignItems="center" justifyContent="center"
                         flexDirection="column" padding="1em">
                        <Typography className={`${classes.centerText} ${classes.mainHeaderText} ${classes.bold}`}>
                            {texts.letsWorkTogether}
                        </Typography>
                        <Typography variant="h5" className={classes.bold}>
                            {texts.contactText}
                        </Typography>
                    </Box>
                    <Box className={`${classes.formBoxes}`} display="flex" alignItems="center" justifyContent="center"
                         flexDirection="column" padding="1em">
                        <form noValidate autoComplete="off" className={`${classes.formHolder}`}>
                            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                                <TextField className={`${classes.textFields}`} id="name" label="Name"/>
                                <TextField className={`${classes.textFields}`} id="email" label="Email"/>
                                <TextField className={`${classes.textFields}`} id="message" label="Message"/>
                                <Button variant="contained" className={`${classes.contactButton}`}>
                                    <Link to="/login">{texts.hostels}</Link>
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </Box>
            <Box id="contactUs" display="flex" alignItems="center" justifyContent="center" flexDirection="row"
                 className={`${classes.footerHolder}`} width="100%">
                <Box width="70%" display="flex" alignItems="center" justifyContent="flex-start" flexDirection="row">
                    <Typography variant="h4">
                        {texts.copyRight}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
};

export default Landing;