import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Container,
    Divider,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControl,
    FormHelperText,
    Grid,
    Hidden,
    Link,
    MenuItem,
    Paper,
    Select,
    Typography
} from '@material-ui/core';
import Classes from "../../../styles";
import Loader from "../../common/components/utils/Loader";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchMultiple from '../../common/components/utils/SearchMultiple';
import {connect, useDispatch} from "react-redux";
import {requestSupportList, setSupportFilterValue} from "../actions/async";
import {supportQuestionsList} from "../utils/supportSelector";
import {handleViewPaymentPanel} from "../../common/actions/actions";
import ContactMailIcon from '@material-ui/icons/MailOutlineOutlined';
import NullMessage from '../../common/components/error.pages/NullMessage'
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    notification: {
        '& .title': {
            paddingTop: 12,
            fontSize: '1em',
            fontWeight: '2em',
            color: theme.palette.common.black
        },
        '& .message': {
            paddingTop: 2,
            color: theme.palette.grey['700']
        },
        '& .iconButton': {
            padding: 7
        },

    },
    divider: {
        width: '100%'
    }

}));

const SupportView = ({categories, questions, isLoading, paymentPanel}) => {
    const classes = Classes();
    const useclasses = useStyles()
    const [expanded, setExpanded] = useState(false);
    const [selectedCategoryName, setSelectedCategoryName] = useState('default');
    const [selectedCategory, setSelectedCategory] = useState(null);
    const dispatch = useDispatch();


    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const selectCategory = (id) => {
        setSelectedCategory(id)

    };

    useEffect(() => {
        if (paymentPanel) {
            dispatch(handleViewPaymentPanel(false));
        }
        dispatch(setSupportFilterValue(null));
        dispatch(requestSupportList());
    }, [isLoading]);

    if (isLoading) {
        return (
            <Container className={classes.styles.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Loader/>
                    </Grid>
                </Grid>
            </Container>
        )
    }
    return (
        <Grid container spacing={3} style={{paddingRight: 10}}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="h1" className={classes.typography.pageTitles}>Support</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box ml={2} mb={0.9} display="flex" flexWrap="wrap" alignItems="center"
                     className={useclasses.notification}>
                    <Typography component="legend" className="title"
                    >Contact us, we are here to help you:&nbsp;&nbsp;</Typography>
                    <FormHelperText className={`${classes.typography.familyPoppins} message`}>
                        Send us a mail to &nbsp;
                        <Link color="primary" href="mailto:example@support@rumifact.com" target="_blank"
                              style={{fontWeight: "bold"}}>
                            support@rumibilling.com&nbsp;.&nbsp;
                            <ContactMailIcon style={{fontSize: 20}}/>
                        </Link>
                    </FormHelperText>
                </Box>
                <Divider light className={useclasses.divider}/>
            </Grid>
            <Hidden smUp>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                    <Box mt={0.2}>
                        <Typography variant="h6" style={{marginBottom: 8}}>
                            <Box display="inline" color="text.secondary">
                                Categories
                            </Box>
                        </Typography>
                        {
                            categories.length > 0 ?
                                <FormControl fullWidth>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        onChange={(e) => {
                                            dispatch(setSupportFilterValue(e.target.value));
                                        }}>
                                        <MenuItem value={null}>All</MenuItem>
                                        {
                                            Object.values(categories).map((category, index) => (
                                                <MenuItem key={index + 1}
                                                          value={category.name}>{category.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                : <NullMessage message="There are no categories" height={"28em"}/>
                        }
                    </Box>
                </Grid>
            </Hidden>
            <Hidden xsDown>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                    <Box className={classes.containers.dividerVertical} mt={0.2}>
                        <Typography variant="h6" style={{marginBottom: 8}}>
                            <Box display="inline" color="text.secondary">
                                Categories
                            </Box>
                        </Typography>

                        {
                            categories.length > 0 ?

                                <Box style={{overflowY: 'auto', maxHeight: '70vh'}} className="scroll" pr={3}>
                                    <Button variant="contained" color="primary" fullWidth
                                            className={`${classes.buttons.categoryButton} ${classes.styles.bRadius18} 
                                        ${classes.typography.textCapitalize} ${selectedCategory === null && "selected"}`}
                                            style={{marginBottom: '0.8em'}}
                                            onClick={() => {
                                                dispatch(setSupportFilterValue(null));
                                                selectCategory(null)
                                            }}
                                    >
                                        <Box fontSize={'1.2em'} alignItems="center">All</Box>
                                    </Button>
                                    {Object.values(categories).map((value, index) => (
                                        <Button variant="contained" color="primary" fullWidth key={index + 1}
                                                className={`${classes.buttons.categoryButton} ${classes.styles.bRadius18} ${classes.typography.textCapitalize} ${selectedCategory === value.id && "selected"}`}
                                                style={{marginBottom: '0.8em'}}
                                                onClick={() => {
                                                    dispatch(setSupportFilterValue(value.name));
                                                    selectCategory(value.id)
                                                }}
                                        >
                                            <Box fontSize={'1.2em'} alignItems="center"
                                            >{value.name}</Box>
                                        </Button>
                                    ))}
                                </Box>

                                : <NullMessage message="There are no categories" height={"28em"}/>
                        }
                    </Box>
                </Grid>
            </Hidden>
            <Grid item xs={12} sm={9} md={9} lg={9}>
                <Box pl={{xs: 0, sm: 2}} mt={0.2}>
                    <Typography variant="h6" style={{marginBottom: 8}}>
                        <Box display="inline" color="text.secondary">
                            Questions
                        </Box>
                    </Typography>
                    <Box width={{xs: 300, sm: 400}}>
                        <SearchMultiple/>
                    </Box>
                    <br/>
                    <Box height={{xs: '100%', md: '60vh'}} style={{overflowY: 'auto'}} className="scroll">
                        {questions.length > 0 ?
                            <Paper className={`general-styles- ${classes.styles.shadow1}`}>
                                {
                                    Object.values(questions).map((value, index) => (

                                        <ExpansionPanel expanded={expanded === `panel${index}`} key={index + 1}
                                                        onChange={handleChange(`panel${index}`)}
                                                        style={{
                                                            boxShadow: " 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14)",
                                                            margin: 0
                                                        }}>
                                            <ExpansionPanelSummary
                                                style={{borderBottom: `0.5px solid ${expanded === `panel${index}` ? '#39CDC8' : 'rgba(0,0,0,0.14)'}`}}
                                                expandIcon={
                                                    <ExpandMoreIcon
                                                        color={expanded === `panel${index}` ? "secondary" : "action"}/>
                                                }
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                className={classes.accordion.basePanelSummary}>
                                                <Typography className={`${classes.accordion.heading} expanded`}
                                                            style={{
                                                                color: ` ${expanded === `panel${index}` ? "#0a0a0a" : "#4a4a49"}`,
                                                                width: '100%'
                                                            }}>
                                                    {value.question}
                                                </Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails
                                                style={{borderBottom: '0.5px solid rgb(231, 231, 231)'}}>
                                                <Box display="flex" flexDirection="column" width={"100%"}>
                                                    <Box display="flex" color="text.primary" mt={2} mb={2}
                                                         style={{width: "100%", color: "#666666"}}
                                                         style={{overflowY: 'auto', maxHeight: '10vh'}}
                                                         className="scroll">
                                                        <Typography variant="body2">
                                                            {value.answer}
                                                        </Typography>
                                                    </Box>
                                                    <Box display="flex" flexDirection="row" mt={2}>
                                                        <Typography variant="caption" color="textSecondary"
                                                                    style={{marginRight: 5, marginTop: 3}}>
                                                            Tags:
                                                        </Typography>
                                                        {value.categories ?
                                                            Object.values(value.categories).map((category, index) => (
                                                                <Box mr={1} key={index + 1} style={{
                                                                    marginBottom: 0,
                                                                    fontSize: 14,
                                                                    padding: "5px 10px",
                                                                    borderRadius: 18,
                                                                    backgroundColor: "#BEEFED",
                                                                    color: "#666666"
                                                                }}>
                                                                    {category}
                                                                </Box>
                                                            ))
                                                            :
                                                            <Box>"There are no categories"</Box>
                                                        }
                                                    </Box>

                                                </Box>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    ))
                                }
                            </Paper>
                            : <NullMessage message="There are no questions" height={"20em"}/>
                        }

                    </Box>
                </Box>
            </Grid>

        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        questions: supportQuestionsList(state),
        categories: state.supportReducer.supportCategories,
        isLoading: state.supportReducer.isLoading,
        paymentPanel: state.utilsReducer.showPaymentPanel
    }
};
export default connect(mapStateToProps, {})(SupportView);