import * as api_settings from "../../api.calls";
import {ADD_CUSTOMER, EDIT_CUSTOMER} from "../../actions/actionsTypes";
import {verifyToken} from "../../../common/apiCalls";


export const validate = values => {
    const errors = {}
    if (!values.name) {
        errors.name = 'Required'
    }
    if (!values.surname) {
        errors.surname = 'Required'
    }
    if (!values.document) {
        errors.document = 'Required'
    }
    if (!values.identityNumber) {
        errors.identityNumber = 'Required'
    }
    if (!values.email) {
        errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }
    if (!values.country) {
        errors.country = 'Required'
    }
    return errors
}

export const submitEmployeesForm = async (values, dispatch, {reset, ...rest},) => {
    try {
        await verifyToken();
        const userRequest = !values.id ? api_settings.postCustomer : api_settings.putCustomer
        const response = await userRequest({
            customerData: values,
        })
        if (response.status === 201) {
            dispatch({
                type: ADD_CUSTOMER,
                payload: response.data,
            })
            reset()
        }
        if (response.status === 200) {
            dispatch({
                type: EDIT_CUSTOMER,
                payload: response.data
            })
        }
        window.history.back()
    } catch (e) {
    }
}