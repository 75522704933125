import React, {useEffect} from "react";
// COMPONENTS
import SimpleTable from "../../../common/components/utils/SimpleTable";
import Filters from "../../components/reports/Filters";
import ExportButtons from "../../../common/components/utils/ExportButtons";
import ReportsParametersPanel from "../../../common/components/utils/reportsParametersPanel";
// COMPONENTS MATERIAL UI
import {Box, Container, Grid, Typography} from "@material-ui/core";
import {connect, useDispatch} from "react-redux";
import Loader from "../../../common/components/utils/Loader";
import Classes from "../../../../styles";
import {handleViewPaymentPanel} from "../../../common/actions/actions";
import {setFilterSalesReport} from '../../redux/actions';
// import {handleSalesReportFilterAndSearch} from "../../redux/selector/handleDataFiltered";
import {loadSalesReport} from "../../redux/asyncActions";
import {searchSalesReport} from "../../utils/reports.utils";
import {reportsSelector} from "../../selector/reportsSelector";
import ReportsSearchPanel from "../../../common/components/utils/reportsSearchPanel";
// import { makeStyles } from '@material-ui/core/styles';

const SalesReport = ({report, isLoading, paymentPanel}) => {
    const classes = Classes();
    const dispatch = useDispatch();


    const columns = {
        "date": "Date/Time",
        "user": "User",
        "type_of_sale": "Type",
        "client": "Customer",
        "name": "Name",
        "product": "Product",
        "outlet": "Outlet",
        "quantity": "Quantity",
        "amount": "Amount",
        "origin": "Origin",
    };

    useEffect(() => {
        if (paymentPanel) {
            dispatch(handleViewPaymentPanel(false));
        }
        dispatch(loadSalesReport());

    }, [isLoading]);

    if (isLoading) {
        return (
            <Container className={classes.styles.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Loader/>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h1" className={classes.typography.pageTitles}>
                    <Box color="text.secondary" display="inline"> Reports /</Box>
                    &nbsp;Sales
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container item>
                    <Grid item xs={12} sm={12} md={5} lg={4}>
                        <ReportsParametersPanel panelClass={"sales-report"}/>
                    </Grid>
                    <Grid item xs={12} sm={7} md={3} lg={4}>
                        <ReportsSearchPanel searcher={searchSalesReport}/>
                    </Grid>
                    <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Filters setReportFilter={setFilterSalesReport}/>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={2} md={2} lg={2}>
                        <Box display="flex" flexDirection="row-reverse">
                            <ExportButtons report={report} name={"Sales-report"}/>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <SimpleTable report={report} amountKey={8} columns={columns} indexListResponsiveTable={[0, 4, 2]}/>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    report: reportsSelector(state, "sales"),
    isLoading: state.reports.salesReport.salesAreLoading,
    paymentPanel: state.utilsReducer.showPaymentPanel
});

export default connect(mapStateToProps, {setFilterSalesReport})(SalesReport)