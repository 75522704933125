import React from "react";
import {Box, Grid} from "@material-ui/core/";
import {connect} from "react-redux"
import Classes from '../../../../styles'
import {groupSelector} from "../../selector/customer.selector";

import {Button} from "@material-ui/core";
import NullMessage from "../../../common/components/error.pages/NullMessage";
import AccountIcon from '../../../../assets/icons/payment.png'

const GroupList = ({groupMembers}) => {
    const classes = Classes();

    return (
        <Grid container>
            {
                groupMembers.length ?
                    groupMembers.map((member, index) =>
                        <Grid item xs={6} style={{paddingBottom: "1em", paddingRight: "1em"}}>
                            <Button fullWidth key={index + 1} variant="outlined" color="primary"
                                    className={`${classes.styles.shadow1} ${classes.styles.bRadius10} ${classes.typography.textCapitalize}`}
                                    style={{height: "2.5em", color: '#5BE576'}}>
                                {
                                    member.debt ?
                                        <img src={AccountIcon} style={{width: 17, height: 17}}/>
                                        : null
                                }
                                <Box flexGrow={1} mr={'0'} color="grey.700"
                                     fontSize={'0.8em'} lineHeight={'1em'}>
                                    {member.name} {member.surname}
                                </Box>

                            </Button>
                        </Grid>
                    ) : <NullMessage message={"There are no members in the group"} height={"5em"}/>
            }
        </Grid>
    )
};

const mapStateToProps = (state) => {
    return {
        groupMembers: groupSelector(state)
    }
};
export default connect(mapStateToProps)(GroupList)