import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {getPmsRoomsAndClients, toggleFutureReservations, toggleRrhhPayment} from "../../redux/asyncActions"
// COMPONENTS
import ClientFilter from "../../components/clients/ClientFilter";
import SearchMultiple from '../../../common/components/utils/SearchMultiple'
import RoomGuestList from './RoomGuestList';
import ErrorMessage from '../../../common/components/error.pages/ErrorMessage'
// MATERIAL UI
import {Box, Checkbox, Container, Grid, Typography} from '@material-ui/core';

import Loader from "../../../common/components/utils/Loader";
import Classes from "../../../../styles";
import {handleViewPaymentPanel} from "../../../common/actions/actions";
import {CLEAR_COMPANY_FLAG, CLEAR_COMPANY_INFO, SET_GROUP_PAYMENT} from "../../../rumi/actionTypes";

const Clients = ({paymentPanel, reservationsFetching, roomsFetching,includeFutureReservations}) => {
    const classes = Classes();
    const dispatch = useDispatch();
    const clientsFilter = ["all", "guest", "staff", "volunteer"];
    const errorResponse = useSelector(state => (state.errors));

    useEffect(() => {
        if (!paymentPanel) {
            dispatch(handleViewPaymentPanel(true));
        }
        if (reservationsFetching === false || roomsFetching === false) {
            dispatch(getPmsRoomsAndClients());
        }
        dispatch({type: SET_GROUP_PAYMENT, payload: false});
        dispatch({type: CLEAR_COMPANY_INFO});
        dispatch({type: CLEAR_COMPANY_FLAG});
    }, []);

    if (reservationsFetching || roomsFetching) {
        return (
            <Container className={classes.styles.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {
                            errorResponse.status !== null ? <ErrorMessage/> : <Loader/>
                        }
                    </Grid>
                </Grid>
            </Container>
        )
    }
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="h1" className={classes.typography.pageTitles}>Clients</Typography>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <SearchMultiple clients={true}/>
                    <br/>
                    <Box display="flex" flexDirection="row" justifyContent="left" alignItems="center" flexWrap="wrap">
                        <label>Include future reservations</label>
                        <Checkbox
                            checked={includeFutureReservations}
                            onChange={(e) => dispatch(toggleFutureReservations(e.target.checked))}
                            inputProps={{'aria-label': 'include future reservations'}}
                        />
                    </Box>
                </Grid>
                <Grid item lg={1}/>
                <Grid item xs={12} sm={6} md={6} lg={5}>
                    <ClientFilter filters={clientsFilter}/>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <RoomGuestList/>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        paymentPanel: state.utilsReducer.showPaymentPanel,
        reservationsFetching: state.pmsEntities.isLoading,
        roomsFetching: state.rooms.fetching,
        includeFutureReservations: state.clientCriteria.includeFutureReservations
    }
};

export default connect(mapStateToProps)(Clients)