import {
    LOAD_DOCUMENTS
} from "../actionTypes";

const initialState = {
    ticket: {},
    invoice: {}
};


const documents = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_DOCUMENTS:
        default:
            return {
                ...state,
            }
    }
};


export default documents
