import * as api_settings from "../../api.calls";

import {ADD_GROUP, EDIT_GROUP} from "../../actions/actionsTypes";
import {verifyToken} from "../../../common/apiCalls";
import {billingPermisions, generalPermisions, posPermisions} from "../../permissionsConstants";
import {POS_ORIGIN} from "../../../common/commonConstants";


export const validate = values => {
    const errors = {}
    if (!values.name) {
        errors.name = 'Required'
    }
    if (!values.origin) {
        errors.origin = 'Required'
    }
    if (!values.areas || !values.areas.length) {
        errors.areas = 'Required'
    }
    if(values.permission){
        if (!values.permission.length) {
            errors.permission = 'Required'
        }
    }
    return errors
}

const permissionList = {
    common: generalPermisions,
    billing: billingPermisions,
    pos: posPermisions
};

function validatePermission(app, values) {
    const commonPermission = permissionList.common.filter(element => values[element])
    const appOrigin =  app === POS_ORIGIN? permissionList.pos : permissionList.billing
    const appPermission = appOrigin.filter(element => values[element])
    return commonPermission.concat(appPermission)
}

function validateLocation(app, values) {
    const locations = Object.keys(values)
    return locations.filter(element => element > 0)
}

export const submitGroupForm = async (values, dispatch, {reset, ...rest},) => {
    try {
        const {name, origin, permission, areas, ...others} = values
        const permissions = permission
        const locations = areas
        
        const data ={
            id : others.id,
            name,
            origin,
            visualizations : true,
            dashboard : true,
            permissions,
            locations
        }
       
        await verifyToken();
        const groupRequest = !values.id ? api_settings.postGroup : api_settings.putGroup
        const response = await groupRequest({
            groupData: data,
        })
        if (response.status === 201) {
            dispatch({
                type: ADD_GROUP,
                payload: response.data,
            })
        }
        if (response.status === 200) {
            dispatch({
                type: EDIT_GROUP,
                payload: response.data
            })
            
            
        }
        rest.history.goBack()
    } catch (e) {
        
    }
}
