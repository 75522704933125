import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {connect, useDispatch} from 'react-redux';
import {Field, formValueSelector, getFormInitialValues, reduxForm} from 'redux-form';
import {handleViewPaymentPanel} from "../../../common/actions/actions"

import {billingPermissionsTest, generalPermissionsTest, posPermissionsTest,} from "../../permissionsConstants";
// CSS
import Classes from '../../../../styles';
// COMPONENTS
import InfoList from '../utils/InfoList';

// MATERIAL UI
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    InputLabel,
    Radio,
    Typography
} from '@material-ui/core';
import {
    radioButton,
    renderTextField,
    SwitchGroup,
    SwitchGroupPermissions,
    SwitchGroupSettings,
    SwitchPermission,
    SwitchSettings
} from "../../../common/components/utils/ReduxFromMaterialUI";
import {submitGroupForm, validate} from "../../forms/submit/group.submit";
import {areasByOrigin} from "../../selector/accounts.selector";
import {loadAccounts} from "../../actions/asyncAction";
import {makeStyles} from '@material-ui/core/styles';
import {POS_ORIGIN, RUMI_ORIGIN} from "../../../common/commonConstants";


const useStyles = makeStyles((theme) => ({
    permissions: {
        fontSize: 15,
    },
    title: {
        color: "#0a0a0a",
        marginLeft: 0
    },
    subtitle: {
        color: "#4a4a49",
        marginLeft: 40
    },
    postsubtitle: {
        color: "#707070",
        marginLeft: 80
    },
    lastPostsubtitle: {
        marginBottom: 20
    }
}))

let GroupForm = (props) => {
    const classes = Classes();
    const useclasses = useStyles()
    const dispatch = useDispatch();
    const [disabledSettings, setDisabledSettings] = useState(false)
    const [appName, setAppName] = useState("")

    const {
        handleSubmit,
        array: {removeAll},
        pristine,
        reset,
        submitting,
        app,
        locationApp,
        initialValues,
        invalid
    } = props

    const reportsPermissions = generalPermissionsTest.filter((list, index) => index > 0 && index < 7)
    const generalPermissions = generalPermissionsTest.filter((list, index) => index > 7 && index < 10)
    const billingPermissions = generalPermissionsTest.filter((list, index) => index > 9 && index < 14)
    const storePermissions = generalPermissionsTest.filter((list, index) => index > 13 && index < 16)
    const employeePermissions = generalPermissionsTest.filter((list, index) => index > 15 && index < 19)
    const accountPermissions = generalPermissionsTest.filter((list, index) => index > 18 && index < 22)
    const settingsPermission = generalPermissionsTest.filter((list, index) => index === 7)
    const supportPermission = generalPermissionsTest.filter((list, index) => index === 22)
    const closeShiftPermission = generalPermissionsTest.filter((list, index) => index === 0)
    const posStorePermissions = posPermissionsTest.filter((list, index) => index === 0)
    const salesHistoryPermissions = posPermissionsTest.filter((list, index) => index > 0)
    const billingSalesHistoryPermissions = billingPermissionsTest.filter((list, index) => index > 3)
    const consumptionPermissions = billingPermissionsTest.filter((list, index) => index < 3)
    const billingStorePermissions = billingPermissionsTest.filter((list, index) => index == 3)

    const titlePermissions = ["close_shift", "reports", "settings", "support", "pos_store", "pos_sales_history", "consumptions", "billing_store", "billing_sales_history"]
    const postsubtitlePermissions = ["locations", "serials", "taxes", "exchange_rate", "store_actions", "staff", "volunteer", "groups", "users"]
    const lastPostsubtitlePermissions = ["locations", "exchange_rate", "store_actions", "volunteer"]
    const doorsReports = ["sales_report", "ranking_report", "receipts_reports", "statistics_report", "cash_balance_report"]
    const doorsSettings = ["general", "billing", "store", "employees", "accounts", "locations", "serials", "taxes", "exchange_rate", "store_actions", "staff", "volunteer", "groups", "users"]
    const doorsGeneralSettings = ["locations"]
    const doorsBillingSettings = ["serials", "taxes", "exchange_rate"]
    const doorsStoreSettings = ["store_actions"]
    const doorsEmployeeSettings = ["staff", "volunteer"]
    const doorsAccountSettings = ["groups", "users"]
    const doorsConsumptions = ["staff_payment", "receipts"]
    const doorsSalesHistory = ["cancel_sales"]

    const handlePermissions = (list, val) => {
        let aux = list.filter(title => val === title)
        return aux[0]
    };

    const handleCancel = () => {
        props.history.goBack()
    };

    useEffect(() => {
            dispatch(handleViewPaymentPanel(false))
            dispatch(loadAccounts());
            if (initialValues === undefined) {
                setAppName("")
                setDisabledSettings(true);
            } else {
                setAppName(initialValues.origin)
                let initVal = initialValues.permission.filter(list => list == "settings")
                if (initVal[0]) {
                    setDisabledSettings(false)
                } else {
                    setDisabledSettings(true)
                }
            }
        }, [dispatch]
    );

    const handleDisabledSettings = (value) => {
        setDisabledSettings(value)
    }

    const handleCleanSwitch = (value) => {
        setAppName(value)
        removeAll('areas');
    }

    return (
        <Grid container item spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h1" className={classes.typography.pageTitles}>
                    <Box display="inline" color="text.secondary"
                    >
                        Settings /
                    </Box>
                    <Link to={`../accounts`}
                          className={`general-nav- ${classes.nav.link}`}>
                        <Box display="inline" color="text.secondary">
                            &nbsp;Accounts /
                        </Box>
                    </Link>
                    &nbsp;Group
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} sm={12} md={12} lg={2}>
                                    <InfoList title={"Description"} description={"Data from your group."}/>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} lg={6}>
                                    <FormControl
                                        className={`general-forms- ${classes.forms.formMargin} general-forms- ${classes.forms.fullFormWidth}`}>
                                        <Field
                                            id="standard-input"
                                            label="Name *"
                                            name="name"
                                            component={renderTextField}
                                            className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} `}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                    <Box>
                                        <Field
                                            label="App *"
                                            name="origin"
                                            component={radioButton}>
                                            <FormControlLabel value={RUMI_ORIGIN} control={<Radio color="secondary"
                                                                                                  onChange={() => handleCleanSwitch("Billing")}/>}
                                                              label="Rumi Billing"/>
                                            <FormControlLabel value={POS_ORIGIN} control={<Radio color="secondary"
                                                                                                 onChange={() => handleCleanSwitch("POS")}/>}
                                                              label="Rumi POS"/>
                                        </Field>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                locationApp !== undefined &&
                                appName !== "" &&
                                <Grid container item spacing={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={2}>
                                        <InfoList title={"Location"}
                                                  description={"That ones you select will appear in the very beginning of your session."}/>

                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8} lg={6}>
                                        {locationApp.length > 0 ?
                                            <Box>
                                                <SwitchGroup name="areas" outsideLabel={"Areas*"}
                                                             options={locationApp}/>
                                            </Box> :
                                            <FormControl
                                                className={`general-forms- ${classes.forms.formMargin} general-forms- ${classes.forms.fullFormWidth}`}>
                                                <InputLabel shrink component="legend" color="secondary"
                                                            style={{color: "#EA3788"}}>Areas*</InputLabel>
                                                <br/>
                                                <Box fontStyle="oblique" borderRadius={5} color="text.secondary" p={2}
                                                     style={{border: "1px solid #EA3788"}} display="flex"
                                                     alignItems="left">
                                                    <Typography variant="body1">
                                                        There is no location in app <strong>Rumi {appName}</strong>.
                                                    </Typography>
                                                </Box>

                                                <FormHelperText style={{color: "#EA3788"}}>Required</FormHelperText>
                                            </FormControl>
                                        }
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} sm={12} md={12} lg={2}>
                                    <InfoList title={"Permissions"}
                                              description={"You can give access to multiple permissions that your group will need."}/>

                                </Grid>
                                <Grid item xs={12} sm={8} md={8} lg={6}>
                                    <Grid container item spacing={3}>
                                        <Grid item xs={8}>
                                            <InputLabel shrink component="legend" color="secondary"
                                            >General</InputLabel>
                                            <Box mt={1}>
                                                {
                                                    generalPermissionsTest.map((permissions, index) =>
                                                        <Box key={index + 1}
                                                             style={handlePermissions(titlePermissions, permissions.value) && index !== 0 ? {marginTop: 20} : {}}>
                                                            <Typography variant="body1" color="textSecondary"
                                                                        gutterBottom
                                                                        className={`${useclasses.permissions}  
                                                        ${handlePermissions(titlePermissions, permissions.value) ? useclasses.title : (handlePermissions(postsubtitlePermissions, permissions.value) ? useclasses.postsubtitle : useclasses.subtitle)} 
                                                        ${handlePermissions(lastPostsubtitlePermissions, permissions.value) && useclasses.lastPostsubtitle}`}
                                                            >
                                                                - {permissions.label}
                                                            </Typography>
                                                        </Box>
                                                    )
                                                }

                                            </Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Box mt={3}>
                                                <FormGroup>
                                                    <Box mb={1.8}>
                                                        <SwitchPermission name="permission"
                                                                          options={closeShiftPermission}/>
                                                    </Box>
                                                    <SwitchGroupPermissions name="permission"
                                                                            keyValue={"reports"}
                                                                            doorsList={doorsReports}
                                                                            options={reportsPermissions}/>
                                                    <Box mt={1.3}>
                                                        <SwitchSettings name="permission"
                                                                        handleDisabledSettings={handleDisabledSettings}
                                                                        doorsList={doorsSettings}
                                                                        options={settingsPermission}/>
                                                    </Box>
                                                    <Box mt={0.5}>
                                                        <SwitchGroupSettings name="permission"
                                                                             disabledSettings={disabledSettings}
                                                                             keyValue={"general"}
                                                                             doorsList={doorsGeneralSettings}
                                                                             options={generalPermissions}
                                                                             doorsSettings={doorsSettings}
                                                                             handleDisabledSettings={handleDisabledSettings}
                                                        />
                                                    </Box>
                                                    <Box mt={1.5}>
                                                        <SwitchGroupSettings name="permission"
                                                                             disabledSettings={disabledSettings}
                                                                             keyValue={"billing"}
                                                                             doorsList={doorsBillingSettings}
                                                                             options={billingPermissions}
                                                                             doorsSettings={doorsSettings}
                                                                             handleDisabledSettings={handleDisabledSettings}
                                                        />
                                                    </Box>
                                                    <Box mt={1.5}>
                                                        <SwitchGroupSettings name="permission"
                                                                             disabledSettings={disabledSettings}
                                                                             keyValue={"store"}
                                                                             doorsList={doorsStoreSettings}
                                                                             options={storePermissions}
                                                                             doorsSettings={doorsSettings}
                                                                             handleDisabledSettings={handleDisabledSettings}
                                                        />
                                                    </Box>
                                                    <Box mt={1.5}>
                                                        <SwitchGroupSettings name="permission"
                                                                             disabledSettings={disabledSettings}
                                                                             keyValue={"employees"}
                                                                             doorsList={doorsEmployeeSettings}
                                                                             options={employeePermissions}
                                                                             doorsSettings={doorsSettings}
                                                                             handleDisabledSettings={handleDisabledSettings}
                                                        />
                                                    </Box>
                                                    <Box mt={1.5}>
                                                        <SwitchGroupSettings name="permission"
                                                                             disabledSettings={disabledSettings}
                                                                             keyValue={"accounts"}
                                                                             doorsList={doorsAccountSettings}
                                                                             options={accountPermissions}
                                                                             doorsSettings={doorsSettings}
                                                                             handleDisabledSettings={handleDisabledSettings}
                                                        />
                                                    </Box>
                                                    <Box mt={2}>
                                                        <SwitchPermission name="permission"
                                                                          options={supportPermission}/>
                                                    </Box>
                                                </FormGroup>
                                            </Box>
                                        </Grid>
                                        {
                                            app === POS_ORIGIN &&
                                            <React.Fragment>
                                                <Grid item xs={8}>
                                                    <InputLabel shrink component="legend" color="secondary"
                                                    >POS</InputLabel>
                                                    <Box mt={1}>
                                                        {
                                                            posPermissionsTest.map((permissions, index) =>
                                                                <Box key={index + 1}
                                                                     style={handlePermissions(titlePermissions, permissions.value) && index !== 0 ? {marginTop: 20} : {}}
                                                                >
                                                                    <Typography variant="body1" color="textSecondary"
                                                                                gutterBottom
                                                                                className={`${useclasses.permissions}  
                                                        ${handlePermissions(titlePermissions, permissions.value) ? useclasses.title : useclasses.subtitle}  `}
                                                                    >
                                                                        - {permissions.label}
                                                                    </Typography>
                                                                </Box>
                                                            )
                                                        }

                                                    </Box>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Box mt={3}>
                                                        <FormGroup>
                                                            <SwitchPermission name="permission"
                                                                              options={posStorePermissions}/>
                                                            <Box mt={1.5}>
                                                                <SwitchGroupPermissions name="permission"
                                                                                        keyValue={"pos_sales_history"}
                                                                                        doorsList={doorsSalesHistory}
                                                                                        options={salesHistoryPermissions}/>
                                                            </Box>
                                                        </FormGroup>
                                                    </Box>
                                                </Grid>
                                            </React.Fragment>
                                        }
                                        {
                                            app === RUMI_ORIGIN &&
                                            <React.Fragment>
                                                <Grid item xs={8}>
                                                    <InputLabel shrink component="legend" color="secondary"
                                                    >Billing</InputLabel>
                                                    <Box mt={1}>
                                                        {
                                                            billingPermissionsTest.map((permissions, index) =>
                                                                <Box key={index + 1}
                                                                     style={handlePermissions(titlePermissions, permissions.value) && index !== 0 ? {marginTop: 20} : {}}
                                                                >
                                                                    <Typography variant="body1" color="textSecondary"
                                                                                gutterBottom
                                                                                className={`${useclasses.permissions}  
                                                                            ${handlePermissions(titlePermissions, permissions.value) ? useclasses.title : useclasses.subtitle}  `}>
                                                                        - {permissions.label}
                                                                    </Typography>
                                                                </Box>
                                                            )
                                                        }
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Box mt={3}>
                                                        <FormGroup>
                                                            <SwitchGroupPermissions name="permission"
                                                                                    keyValue={"consumptions"}
                                                                                    doorsList={doorsConsumptions}
                                                                                    options={consumptionPermissions}/>
                                                            <Box mt={1.5}>
                                                                <SwitchPermission name="permission"
                                                                                  options={billingStorePermissions}/>
                                                            </Box>
                                                            <Box mt={1.5}>
                                                                <SwitchGroupPermissions name="permission"
                                                                                        keyValue={"billing_sales_history"}
                                                                                        doorsList={doorsSalesHistory}
                                                                                        options={billingSalesHistoryPermissions}/>
                                                            </Box>
                                                        </FormGroup>
                                                    </Box>
                                                </Grid>
                                            </React.Fragment>
                                        }

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container item>
                                <Grid item xs={12} lg={2}/>
                                <Grid item xs={12} lg={10}>
                                    <Button type="submit" variant="contained" color="primary"
                                            className={`${classes.buttons.saveBtn} ${!locationApp.length ? 'inactive' : 'active'}`}
                                            style={{margin: '0 0.8em 0 0', width: '100px'}}
                                            disabled={pristine || submitting || invalid}
                                    >Save</Button>
                                    <Button type="button" variant="outlined" color="primary"
                                            style={{margin: '0 0.8em 0 0', width: '100px'}}
                                            onClick={handleCancel}
                                    >Cancel</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
};

GroupForm = reduxForm({
    form: 'group',
    onSubmit: submitGroupForm,
    validate
})(GroupForm);

const formGroupSelector = formValueSelector('group');

const mapStateToProps = state => {
    const origin = formGroupSelector(state, 'origin');
    const locationApp = areasByOrigin(state, origin);

    return {
        locationApp: locationApp,
        app: origin,
        initialValues: getFormInitialValues('group')(state),
    }
};

export default connect(mapStateToProps)(GroupForm)
