import React, {useEffect, useState} from 'react';
// CSS
import Classes from '../../../styles'
// COMPONENTS
import AreaListView from '../components/general/AreaListView';
import FormGeneral from '../components/general/FormGeneral';
import LogoCompany from '../components/general/LogoCompany';
import InfoList from '../components/utils/InfoList';
// MATERIAL UI
import {Box, Container, Grid, Typography} from '@material-ui/core';
import Loader from "../../common/components/utils/Loader";
import {useDispatch} from "react-redux";
import {handleViewPaymentPanel} from "../../common/actions/actions";
import {loadBillings, loadGeneral} from "../actions/asyncAction";
import BlockAmountView from "../components/general/BlockAmountView";
import CloudbedsAuthorizeButton from "../../common/components/modals/CloudbedsAuthorizePopupButton";


const General = () => {
    const classes = Classes();
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(handleViewPaymentPanel(false));
        dispatch(loadBillings());
        dispatch(loadGeneral());
        setIsLoading(false)
    }, [dispatch]);

    if (isLoading) {
        return (
            <Container className={classes.styles.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Loader/>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h1" className={classes.typography.pageTitles}>
                    <Box display="inline" color="text.secondary">
                        Settings /
                    </Box>
                    &nbsp;General
                </Typography>
            </Grid>
            <Grid item xs={12} >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={2} md={2} lg={2} >
                        <InfoList title={"Business"} description={"Main information of the business model"}/>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8} lg={8}>
                        <LogoCompany/>
                        <FormGeneral/>
                    </Grid>
                </Grid>
                <br/>
            </Grid>
            <Grid item xs={12}>
                <Grid container  spacing={2}>
                    <Grid item xs={12} sm={2} md={2} lg={2} >
                        <InfoList title={"Account block amount"}
                                  description={"How much debt should a guest have in order to block adding items to his or her account."}/>
                    </Grid>
                    <Grid item xs={12} sm={10} md={10} lg={10}>
                        <BlockAmountView/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container  spacing={2}>
                    <Grid item xs={12} sm={2} md={2} lg={2} >
                        <InfoList title={"Location"}
                                  description={"In how many areas of your property will you use RUMI? The functional locations that are created is a 'must' to ensure an effective functioning of our system."}/>
                    </Grid>
                    <Grid item xs={12} sm={10} md={10} lg={10}>
                        <AreaListView/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <CloudbedsAuthorizeButton />
            </Grid>
        </Grid>
    )
};

export default General