import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {selectCategoryPreview} from '../../actions/store/sync';
import {filterSearchMultiple} from '../../../common/actions/utilsActions'
import {Box, Button, Hidden, List, Typography} from '@material-ui/core';
import {storePreviewSelector} from "../../selector/store.selector";
import Classes from '../../../../styles'
import NullMessage from '../../../common/components/error.pages/NullMessage'
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";


const CategoryPreview = ({
                             categories,
                             selectCategoryPreview,
                             selectedPrevCategory,
                             searchValue,
                             filterSearchMultiple
                         }) => {
    const classes = Classes();
    const [loading, setLoading] = useState(true)
    const [orderedCategories, setOrderedCategories] = useState([])

    const handleToUpperCase = () => {
        let upperCaseCategories = categories.map(category => ({
            id: category.id,
            name: (category.name[0].toUpperCase() + category.name.toLowerCase().slice(1))
        }))
        setOrderedCategories(upperCaseCategories)
    }

    useEffect(() => {
            handleToUpperCase()
            const firstCategory = categories.length > 0 ? categories.sort((asc, desc) => asc.name > desc.name ? 1 : -1).filter((category, index) => index === 0 ? category : null) : null
            const categoryId = categories.length ? firstCategory[0].id : null
            selectCategoryPreview(categoryId)
            setLoading(false)

        }, [categories]
    )

    const handleSelectedCategory = (categoryId) => {
        if (!searchValue) {
            return categoryId === selectedPrevCategory ? true : false
        } else {
            return false
        }
    }

    const stateCategory = (idCategory) => {
        filterSearchMultiple("")
        selectCategoryPreview(idCategory)

    }

    const selectCategory = (event) => {
        filterSearchMultiple("")
        selectCategoryPreview(event.target.value)
    }

    return (
        <Box>
            <Hidden smDown>
                <Box className={classes.containers.dividerVertical}>
                    <Typography variant="h6">
                        <Box color="text.secondary" mb={1}>Categories</Box>
                    </Typography>
                    <List component="nav" aria-label="main mailbox folders"
                          style={{paddingRight: '2em', height: '65vh', overflowY: 'auto'}}>
                        {
                            categories.length ?
                                orderedCategories
                                    .sort((asc, desc) => asc.name > desc.name ? 1 : -1)
                                    .map((cat, index) => (
                                        <Button key={index} variant="contained" color="primary" fullWidth
                                                className={`${classes.buttons.categoryButton} ${classes.styles.bRadius18} ${classes.typography.textCapitalize} ${handleSelectedCategory(cat.id) ? "selected" : ""}`}
                                                style={{marginBottom: '0.8em'}}
                                                onClick={() => stateCategory(cat.id)}
                                        >
                                            <Box fontSize={'1.2em'} alignItems="center"
                                            >{cat.name}</Box>
                                        </Button>
                                    )) : <NullMessage message="There are no categories" height={"20em"}/>
                        }
                    </List>
                </Box>
            </Hidden>
            <Hidden mdUp>
                <Box mb={0.6}>
                    <Typography variant="h6">
                        <Box color="text.secondary" mb={1}>Categories</Box>
                    </Typography>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            onChange={selectCategory}
                        >
                            {
                                categories.map((category, index) => (
                                    <MenuItem value={category.id} key={index + 1}>
                                        {category.name}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Hidden>
        </Box>
    )
}

const mapStateToProps = state => {
    const {categoryList: categories} = storePreviewSelector(state)

    return {
        categories,
        selectedPrevCategory: state.storeReducer.currentPrevCategory,
        searchValue: state.utilsReducer.search
    }
}

export default connect(mapStateToProps, {selectCategoryPreview, filterSearchMultiple})(CategoryPreview)
