import {
    CREATE_BALANCE_TRANSFERS,
    DELETE_BALANCE_TRANSFERS,
    LOAD_BALANCE_TRANSFERS,
    UPDATE_BALANCE_TRANSFERS
} from "../actions/actionTypes";
import {transformToObject} from "../reducerUtilities";

const initialState = {
    transfers: [],
    loaded: false
};

const balanceTransfersReducer = (state = initialState, action) => {

    switch (action.type) {
        case LOAD_BALANCE_TRANSFERS:
            return {
                ...state,
                transfers:transformToObject(action.payload),
                loaded: true
            };
        case CREATE_BALANCE_TRANSFERS:
        case UPDATE_BALANCE_TRANSFERS:
            return {
                loaded: true,
                transfers:[action.payload]
            };
        case DELETE_BALANCE_TRANSFERS:
            return {
                loaded: true,
                transfers: []
            };
        default :
            return state;
    }
};

export default balanceTransfersReducer