import {connect} from 'react-redux';
import { editProduct , removeProduct} from '../../actions/store/sync';
import ProductList from '../../components/store/ProductList';
import {productSettingList} from "../../../pos/selector/utils.selector";
import {categoriesToUpperCase, productsToUpperCase} from "../../selector/store.selector";
import {DELETE_METHOD, STATUS_RESPONSE} from "../../../common/commonConstants"
import {validateResponseApi} from "../../../common/selectors/utilsSelector"

const mapStateToProps = (state) => {
    const products = productSettingList(state)
    const capitalCaseProducts = productsToUpperCase(products)
    const categoriesToUppercase =  categoriesToUpperCase(Object.values(state.storeReducer.categoryList))
    const orderedProductList = capitalCaseProducts.sort((asc, desc) => asc.name > desc.name ? 1 : -1)
    return {
        productList: orderedProductList,
        categories : categoriesToUppercase,
        deletedProduct : state.utilsReducer.handleDynamicDialog.params,
        openModal: state.utilsReducer.handleDynamicDialog.door,
        createdProduct: state.storeReducer.createProduct,
        selectedProduct: state.messages.response.id,
        animation: validateResponseApi(state.messages.response,"delete-settings-product"),
        dropProductList: state.storeReducer.dropProductList,
        currentDragProduct: state.storeReducer.currentDragProduct,
        currentCategory: state.storeReducer.currentCategory,
        permissionsList: state.auth.permissionsList
    }
};

export default connect(mapStateToProps, {editProduct , removeProduct})(ProductList)