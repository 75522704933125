import axios from 'axios';

import {
    ADD_AREA,
    ADD_GROUP,
    ADD_TAX,
    ADD_USER,
    EDIT_GROUP,
    EDIT_TAX,
    EDIT_USER,
    LOAD_AREAS,
    LOAD_CONFIG,
    LOAD_EXCHANGE_RATE,
    LOAD_EXCHANGE_RATE_FAILED,
    REMOVE_GROUP,
    REMOVE_TAX,
    REMOVE_USER,
    UPDATE_EXCHANGE_RATE,
    LOAD_COMMON_OPTION,
    LOAD_CUSTOMERS,
    LOAD_GENERAL,
    LOAD_BILLING,
    LOAD_ACCOUNTS,
    LIST_CUSTOMERS,
    SEARCH_SETTINGS_STAFF,
    SEARCH_SETTINGS_VOLUNTEER,
    SEARCH_SETTINGS_USER,
    SEARCH_SETTINGS_GROUPS,
    SEARCH_SETTINGS_VOLUNTEERS,
    CONSULTING_EXCHANGE_RATE,
    CONSULTING_EXCHANGE_RATE_FAILED
} from "./actionsTypes";

import {
    GROUP_URL,
    GROUPS_URLS,
    USERS_URL
} from "../constants.api.urls";
import {getRequestInfo} from "../../common/utils";
import * as api_settings from "../api.calls"
import {requestBillingConfig} from "../api.calls"
import {requestError} from "../../pos/redux/actions";
import {verifyToken} from "../../common/apiCalls";


export const loadBillingConfig = () => {
    return async (dispatch) => {
        requestBillingConfig().then(
            (response) => {
                if (response.status === 200) {
                    dispatch({
                        type: LOAD_CONFIG,
                        taxes: response.data.taxes,
                        taxType: response.data.taxType,
                        currencyList: response.data.currencyList,
                        principalCurrency: response.data.principalCurrency,
                        secondaryCurrency: response.data.secondaryCurrency,
                        serial: response.data.serial,
                        // serialAreas: serialInfo.serialAreas,
                        documentType: response.data.documentType
                    })
                }
            }
        ).catch(
            (error) => {
                console.log('Error getting Billing Config')
            }
        )
    }
};

export const loadArea = () => async dispatch => {

    try {

        const response = await api_settings.getAreas();
        dispatch({
            type: LOAD_AREAS,
            payload: response.data
        })
    } catch (err) {

        alert.error("Not found response")

    }
}

export const addArea = (name, originId) => {
    return async (dispatch) => {
        const response = await axios.post("", {name, originId});
        if (response.status === 201) {
            dispatch({
                type: ADD_AREA,
                payload: response.data  /* {id, name, originId}*/
            })
        } else {
        }
    }
};


/* Taxes / Charges */
export const addTax = ({name, value, exciseType}) => {
    return async dispatch => {
        const response = await axios.post("", {name, value, exciseType});
        if (response.status === 201) {
            dispatch({
                type: ADD_TAX,
                tax: {id: response.data, name, value, exciseType}
            })
        } else {
        }
    }
};

export const editTax = (id, editedInfo) => {
    return async dispatch => {
        const response = await axios.put("", {id, ...editedInfo});
        if (response.status === 201) { //VER CUAL ES EL ESTADO
            dispatch({
                type: EDIT_TAX,
                tax: {id, ...editedInfo}
            })
        } else {
        }
    }
};

export const removeTax = (id) => {
    return async dispatch => {
        const response = await axios.delete("", id);
        if (response.status === 200) { //VER CUAL ES EL ESTADO
            dispatch({
                type: REMOVE_TAX,
                taxId: id
            })
        } else {
        }
    }
};

export const addGroup = (name, permissions, origin, dashboard, visualizations) => {
    return async dispatch => {
        const groupData = {
            name: name,
            permissions: permissions, //this must be a dictionary
            origin: origin,
            dashboard: dashboard,
            visualizations: visualizations
        };
        const accessToken = localStorage.getItem('accessToken');
        const {apiUrl, headers} = getRequestInfo(GROUPS_URLS);
        headers['Authorization'] = `Bearer ${accessToken}`;
        const response = await axios.post(apiUrl, groupData, {headers: headers});
        if (response.status === 201) {
            dispatch({
                type: ADD_GROUP,
                payload: response.data
            })
        } else {
        }
    }
};

export const retrieveGroup = (id) => {
    return async dispatch => {
        const retrieveURL = GROUP_URL + `/${id}`;
        const accessToken = localStorage.getItem('accessToken');
        const {apiUrl, headers} = getRequestInfo(retrieveURL);
        headers['Authorization'] = `Bearer ${accessToken}`;
        const response = await axios.get(apiUrl, {headers: headers});
        if (response.status === 200) {
            return response.data
        } else {
        }
    }
};

export const editGroup = (id, name, permissions, origin, dashboard, visualizations) => {
    return async dispatch => {
        const updateURL = GROUP_URL + `/${id}`;
        const groupData = {
            name: name,
            permissions: permissions, //this should be a dictionary
            origin: origin,
            dashboard: dashboard,
            visualizations: visualizations
        };
        const accessToken = localStorage.getItem('accessToken');
        const {apiUrl, headers} = getRequestInfo(updateURL);
        headers['Authorization'] = `Bearer ${accessToken}`;
        const response = await axios.post(apiUrl, groupData, {headers: headers});
        if (response.status === 200) {
             //VER CUAL ES EL ESTADO
            dispatch({
                type: EDIT_GROUP,
                payload: response.data
            })
        } else {


        }
    }
};

export const deleteGroup = (id) => {
    return async dispatch => {
        const deleteURL = GROUP_URL + `/${id}`;
        const accessToken = localStorage.getItem('accessToken');
        const {apiUrl, headers} = getRequestInfo(deleteURL);
        headers['Authorization'] = `Bearer ${accessToken}`;
        const response = await axios.delete(apiUrl, {headers: headers});
        if (response.status === 200) {
            dispatch({
                type: REMOVE_GROUP,
                payload: response.data
            })
        } else {
        }
    }
};

export const addUser = (username, email, password, first_name, last_name, group) => {
    return async dispatch => {
        const userData = {
            username: username,
            email: email,
            password: password,
            first_name: first_name,
            last_name: last_name,
            group: group
        };
        const accessToken = localStorage.getItem('accessToken');
        const {apiUrl, headers} = getRequestInfo(USERS_URL);
        headers['Authorization'] = `Bearer ${accessToken}`;
        const response = await axios.post(apiUrl, userData, {headers: headers});
        if (response.status === 201) {
            dispatch({
                type: ADD_USER,
                payload: response.data
            })
        } else {
        }
    }
};

export const retrieveUser = (id) => {
    return async () => {
        const retrieveUrl = USERS_URL + `/${id}`;
        const accessToken = localStorage.getItem('accessToken');
        const {apiUrl, headers} = getRequestInfo(retrieveUrl);
        headers['Authorization'] = `Bearer ${accessToken}`;
        const response = await axios.get(apiUrl, {headers: headers});
        if (response.status === 200) {
            return response.data
        } else {
        }
    }
};

export const editUser = (id, username, email, first_name, last_name, group) => {
    return async dispatch => {
        const userData = {
            id: id,
            username: username,
            email: email,
            first_name: first_name,
            last_name: last_name,
            group: group
        };
        const editURL = USERS_URL + `/${id}`;
        const accessToken = localStorage.getItem('accessToken');
        const {apiUrl, headers} = getRequestInfo(editURL);
        headers['Authorization'] = `Bearer ${accessToken}`;
        const response = await axios.post(apiUrl, userData, {headers: headers});
        if (response.status === 202) {
            dispatch({
                type: EDIT_USER,
                payload: response.data
            })
        } else {
        }
    }
};

export const deleteUser = (id) => {
    return async dispatch => {
        const deleteURL = USERS_URL + `/${id}`;
        const accessToken = localStorage.getItem('accessToken');
        const {apiUrl, headers} = getRequestInfo(deleteURL);
        headers['Authorization'] = `Bearer ${accessToken}`;
        const response = await axios.delete(apiUrl, {headers: headers});
        if (response.status === 200) {
            dispatch({
                type: REMOVE_USER,
                payload: response.data
            })
        } else {

        }
    }
};


/* EXCHANGE RATES */

export const loadExchangeRate = () => {
    return async dispatch => {
        try {
            await verifyToken();
            const exchangeRate = await api_settings.getExchangeRate();
            if (exchangeRate.status === 200) {
                dispatch({
                    type: LOAD_EXCHANGE_RATE,
                    payload:exchangeRate.data[0]
                })
            }else{
                dispatch({type: LOAD_EXCHANGE_RATE_FAILED})
            }
        } catch (err) {
            if(err.response){
                dispatch(requestError(err.response));
            }else{
                console.log(err)
            }
        }
    }
};

export const consultingExchangeRate = () => {
    return async dispatch => {
        try {
            await verifyToken();
            const exchangeRate = await api_settings.consultingExchangeRate();
            if (exchangeRate.status === 200) {
                dispatch({
                    type: CONSULTING_EXCHANGE_RATE,
                    exchangeRate: exchangeRate.data
                })
            }else{
                dispatch({type: CONSULTING_EXCHANGE_RATE_FAILED})
            }
        } catch (err) {
            if(err.response){
                dispatch(requestError(err.response));
            }else{
                console.log(err)
            }
        }
    }
};

export const updateExchangeRate = (id) => {
    return async dispatch => {
        const retrieveUrl = USERS_URL + `/${id}`;
        const response = await axios.get(retrieveUrl);
        if (response.status === 200) {
            dispatch({
                type: UPDATE_EXCHANGE_RATE,
                payload: response.data
            })
        } else {
            dispatch(requestError(response));
        }
    }
};

export const getCommonSelection = () => {
    return async (dispatch) => {
        try {
            await verifyToken();
            const identityType = await api_settings.requestIdentityType();
            const country = await api_settings.requestsCountries();
            const customers = await api_settings.requestCustomers();
            dispatch({
                type: LOAD_COMMON_OPTION,
                country: country.data,
                identityType: identityType.data,
                customers: customers.data
            })
        } catch (e) {
        }
    }
};

export const searchSettingsStaff = value => ({
    type: SEARCH_SETTINGS_STAFF,
    payload:value
});

export const searchSettingsVolunteer = value => ({
    type: SEARCH_SETTINGS_VOLUNTEER,
    payload:value
});

export const searchSettingsUser = value => ({
    type: SEARCH_SETTINGS_USER,
    payload:value
});
export const searchSettingsGroup = value => ({
    type: SEARCH_SETTINGS_GROUPS,
    payload:value
});
export const searchSettingsVolunteerModal = value => ({
    type: SEARCH_SETTINGS_VOLUNTEERS,
    payload:value
});

export const getCustomers = () => async dispatch => {
    try {
        await verifyToken()
        const response = await api_settings.requestCustomers()

        dispatch({
            type: LOAD_CUSTOMERS,
            customers: response.data
        })
    } catch (e) {
    }
};

export const getCustomersList = () => async dispatch => {
    try {
        await verifyToken()
        const response = await api_settings.requestCustomersList()
        dispatch({
            type: LIST_CUSTOMERS,
            customers: response.data
        })
    } catch (e) {
    }
};

export const loadGeneral = () => {
    return async dispatch => {
        try {
            await verifyToken();
            const company = await api_settings.requestCompany();
            const areas = await api_settings.getAreas();
            dispatch({
                type: LOAD_GENERAL,
                company: company.data,
                areas: areas.data
            })
        } catch (e) {
        }
    }
};

export const loadBillings = () => {
    return async dispatch => {
        try {
            await verifyToken();
            const taxes = await api_settings.getTaxes();
            const serials = await api_settings.getSerials();
            const areas = await api_settings.getAreas();
            const documentType = await api_settings.getBillingDocumentTypeAPI();
            dispatch({
                type: LOAD_BILLING,
                taxes: taxes.data,
                serials: serials.data,
                areas: areas.data,
                documentType: documentType.data
            })
        } catch (err) {
            if(err.response){
                dispatch(requestError(err.response));
            }else{
                console.log(err)
            }
        }
    }
};

export const loadAccounts = () => {
    return async dispatch => {
        try {
            await verifyToken();
            const users = await api_settings.requestUsers();
            const groups = await api_settings.requestGroups();
            const areas = await api_settings.getAreas();
            dispatch({
                type: LOAD_ACCOUNTS,
                users: users.data,
                groups: groups.data,
                areas: areas.data
            })
        } catch (e) {
        }
    }
};