import {
    handleCreditNotesSearch,
    handleInvoicesSearch,
    handleRankingSearch,
    handleSalesSearch,
    handleTicketsSearch,
    handleFilter, handleGuestFlowsSearch, handleBalanceFlowsSearch
} from "../../common/selectors/utilsSelector";

export const reportsSelector = (state, reportType) => {
    let selectedReport = [];
    let searchValue ="";
    let filterValue = "";
    if(reportType === 'balance-flows'){
        selectedReport = state.reports.balanceFlowsReport.balanceFlows;
        searchValue = state.reports.balanceFlowsReport.searchValue;
        filterValue = state.reports.balanceFlowsReport.filterValue;
        let finishSearch = null;
        if(searchValue !== ""){
            finishSearch = handleBalanceFlowsSearch(searchValue,selectedReport);
        }
        if(filterValue !== ""){
            if(finishSearch){
                finishSearch = handleFilter(filterValue,finishSearch);
            }else{
                finishSearch = handleFilter(filterValue,selectedReport);
            }

        }
        if(finishSearch){
            return finishSearch;
        }else{
            return state.reports.balanceFlowsReport.balanceFlows
        }
    }
    if(reportType === 'guest-flows'){
        selectedReport = state.reports.guestFlowsReport.guestFlows;
        searchValue = state.reports.guestFlowsReport.searchValue;
        filterValue = state.reports.guestFlowsReport.filterValue;
        let finishSearch = null;
        if(searchValue !== ""){
            finishSearch = handleGuestFlowsSearch(searchValue,selectedReport);
        }
        if(filterValue !== ""){
            if(finishSearch){
                finishSearch = handleFilter(filterValue,finishSearch);
            }else{
                finishSearch = handleFilter(filterValue,selectedReport);
            }

        }
        if(finishSearch){
            return finishSearch;
        }else{
            return state.reports.guestFlowsReport.guestFlows
        }
    }
    if(reportType === 'sales'){
        selectedReport = state.reports.salesReport.sales;
        searchValue = state.reports.salesReport.searchValue;
        filterValue = state.reports.salesReport.filterValue;
        let finishSearch = null;
        if(searchValue !== ""){
            finishSearch = handleSalesSearch(searchValue,selectedReport);
        }
        if(filterValue !== ""){
            if(finishSearch){
                finishSearch = handleFilter(filterValue,finishSearch);
            }else{
                finishSearch = handleFilter(filterValue,selectedReport);
            }

        }
        if(finishSearch){
            return finishSearch;
        }else{
            return state.reports.salesReport.sales
        }
    }
    if(reportType === 'ranking'){
        selectedReport = state.reports.salesRankingReport.ranking;
        searchValue = state.reports.salesRankingReport.searchValue;
        let finishSearch = null;
        if(searchValue !== ""){
            finishSearch = handleRankingSearch(searchValue,selectedReport);
        }
        if(finishSearch){
            return finishSearch;
        }else{
            return state.reports.salesRankingReport.ranking
        }
    }
    if(reportType === 'invoices'){
        selectedReport = state.reports.invoiceReport.invoices;
        searchValue = state.reports.invoiceReport.searchValue;
        filterValue = state.reports.invoiceReport.filterValue;
        let finishSearch = null;
        if(searchValue !== ""){
            finishSearch = handleInvoicesSearch(searchValue,selectedReport);
        }
        if(filterValue !== ""){
            if(finishSearch){
                finishSearch = handleFilter(filterValue,finishSearch);
            }else{
                finishSearch = handleFilter(filterValue,selectedReport);
            }

        }
        if(finishSearch){
            return finishSearch;
        }else{
            return state.reports.invoiceReport.invoices
        }
    }
    if(reportType === 'tickets'){
        selectedReport = state.reports.ticketReport.tickets;
        searchValue = state.reports.ticketReport.searchValue;
        filterValue = state.reports.ticketReport.filterValue;
        let finishSearch = null;
        if(searchValue !== ""){
            finishSearch = handleTicketsSearch(searchValue,selectedReport);
        }
        if(filterValue !== ""){
            if(finishSearch){
                finishSearch = handleFilter(filterValue,finishSearch);
            }else{
                finishSearch = handleFilter(filterValue,selectedReport);
            }

        }
        if(finishSearch){
            return finishSearch;
        }else{
            return state.reports.ticketReport.tickets;
        }
    }
    if(reportType === 'creditNotes'){
        selectedReport = state.reports.creditNoteReport.creditNotes;
        searchValue = state.reports.creditNoteReport.searchValue;
        filterValue = state.reports.creditNoteReport.filterValue;
        let finishSearch = null;
        if(searchValue !== ""){
            finishSearch = handleCreditNotesSearch(searchValue,selectedReport);
        }
        if(filterValue !== ""){
            if(finishSearch){
                finishSearch = handleFilter(filterValue,finishSearch);
            }else{
                finishSearch = handleFilter(filterValue,selectedReport);
            }

        }
        if(finishSearch){
            return finishSearch;
        }else{
            return state.reports.creditNoteReport.creditNotes;
        }
    }

};