import React, {Component, useState} from 'react';
import {Field} from "redux-form";
import {
    getCustomizedCharges,
    handleInfoCharge,
    handleSwitchCharge,
    toggleSelectOutlet
} from '../../../rumi-settings/actions/store/sync'
import {useDispatch} from "react-redux";

import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    InputLabel,
    RadioGroup,
    Select,
    Slider,
    Switch,
    TextareaAutosize,
    TextField
} from "@material-ui/core";


export const renderTextField = ({label, input, meta: {touched, invalid, error}, ...custom}) => (

    <TextField
        label={label}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}
    />
);

export const renderTextAreaField = ({label, input, meta: {touched, invalid, error}, ...custom}) => (
    <TextareaAutosize
        label={label}
        error={touched && invalid}
        helperText={touched && error}
        defaultValue=""
        {...input}
        {...custom}
    />
);

export const renderCheckbox = ({input, label}) => (
    <div>
        <FormControlLabel
            control={
                <Checkbox
                    checked={!!input.value}
                    onChange={input.onChange}
                />
            }
            label={label}
        />
    </div>
);

export const radioButton = ({label, input, children, meta: {touched, error}, ...rest}) => (
    <FormControl>
        <InputLabel shrink component="legend" color="secondary"
                    style={touched && error ? {color: "#EA3788"} : {}}>{label}</InputLabel>
        <br/>
        <RadioGroup {...input} {...rest}>
            {children}
        </RadioGroup>
        <FormHelperText style={{color: "#EA3788"}}>{touched && error}</FormHelperText>
    </FormControl>
);

export const renderFromHelper = ({touched, error}) => {
    if (!(touched && error)) {
        return undefined;
    } else {
        return <FormHelperText>{touched && error}</FormHelperText>
    }
};

export const renderSelectField = (
    {input, label, meta: {touched, error}, children, classNameFormControl, ...custom}
) => (<FormControl error={touched && error} className={classNameFormControl}>
        <InputLabel shrink component="legend" color="secondary">{label}</InputLabel>
        <br/>
        <Select
            {...input}
            {...custom}
            inputProps={{
                name: input.name,
                id: 'age-native-simple'
            }}
        >
            {children}
        </Select>
        <FormHelperText>{touched && error}</FormHelperText>
    </FormControl>
);

export const renderSwitch = ({input, label}) => (
    <FormControlLabel
        label={label}
        control={
            <Switch
                color="secondary"
                size="small"
                onChange={input.onChange}
                checked={!!input.value}
            />}

    />
);

export class SwitchGroup extends Component {

    field = ({input, meta, options, outsideLabel = null}) => {
        const {onChange} = input;
        const {touched, error} = meta;
        const inputValue = input.value;
        const checkboxes = options.map(({label, value}, index) => {

            const handleChange = (event) => {
                const arr = [...inputValue];
                if (event.target.checked) {
                    arr.push(value);
                } else {
                    arr.splice(arr.indexOf(value), 1);
                }
                return onChange(arr);
            };
            const checked = inputValue.includes(value);
            return (
                <FormControlLabel key={index + 1}
                                  control={
                                      <Switch key={index + 1}
                                              color="secondary"
                                              size="small"
                                              onChange={handleChange}
                                              checked={checked}
                                              value={value}
                                      />}
                                  label={label}
                />
            );
        });

        return (
            <FormControl>
                <InputLabel shrink component="legend" color="secondary"
                            style={touched && error ? {color: "#EA3788"} : {}}>{outsideLabel}</InputLabel>
                <br/>
                <FormGroup>{checkboxes}</FormGroup>
                <FormHelperText style={touched && error ? {color: "#EA3788"} : {}}>{touched && error}</FormHelperText>
            </FormControl>
        );
    };

    render() {
        return <Field {...this.props} type="checkbox" component={this.field}/>;
    }
}

export const SliderForm = ({input}) => {
    const dispatch = useDispatch();
    const marks = [
        {
            value: 0,
            label: '0',
        },
        {
            value: 13,
            label: '13',
        }
    ];

    function getSliderValue() {
        if (input.value > 0) {
            dispatch(handleSwitchCharge(false))
        } else {
            dispatch(handleSwitchCharge(true))
        }
    }

    return (
        <Box display="flex" ml={2} mt={2}>
            <Box mt={1} width={600} mr={5}>
                <Slider
                    defaultValue={input.value}
                    getAriaValueText={input.onChange}
                    aria-labelledby="discrete-slider-custom"
                    step={1}
                    valueLabelDisplay="auto"
                    marks={marks}
                    min={0}
                    max={13}
                    getAriaLabel={getSliderValue}
                    style={{color: input.value > 0 ? "#5BE576" : "#9E9E9E"}}
                />
            </Box>
            <span style={{color: "#5BE576", fontSize: 20}}> {input.value + "%"} </span>

        </Box>
    )
};

export const switchBlocked = ({input, label, inputProps}) => {
    return (
        <FormControlLabel style={{pointerEvents: "none"}}
                          label={label}
                          control={
                              <Switch
                                  disabled={inputProps.activeSwitch}
                                  color="secondary"
                                  size="small"
                                  onChange={input.onChange}
                                  checked={inputProps.activeSwitch ? false : true}
                              />}

        />
    )
};

export class SwitchGroupCharge extends Component {

    field = ({input, meta, options, inputProps}) => {
        const dispatch = useDispatch();
        const {onChange} = input;
        const {touched, error} = meta;
        const outletList = options.filter(opt => opt.code === "ACCO");
        const accoId = outletList[0].value;
        const auxInputValue = input.value.filter(data => data !== accoId);
        auxInputValue.push(accoId);

        if (input.value.length === 0 && !inputProps.activeSwitch) {
            input.value.push(accoId)
        }

        let inputValue = [...auxInputValue];

        const checkboxes = options.map(({label, value, code}, index) => {
            let arr = [...inputValue];

            const handleChange = (event) => {
                if (event.target.checked) {
                    arr.push(value);

                } else {
                    arr.splice(arr.indexOf(value), 1);

                }
                return onChange(arr);

            };

            let checked = inputValue.includes(inputProps.activeSwitch ? false : value);


            if (arr.length < 2) {
                dispatch(handleInfoCharge(true))
            } else {
                dispatch(handleInfoCharge(false))
            }

            return (
                <Box mb={0.5} key={index + 1}>
                    <FormControlLabel style={{display: code === "ACCO" ? "none" : "block"}}
                                      control={
                                          <Switch onLoad={handleChange}
                                                  key={index + 1}
                                                  color="secondary"
                                                  size="small"
                                                  onChange={handleChange}
                                                  checked={checked}
                                                  value={value}
                                                  disabled={inputProps.activeSwitch}
                                          />}
                                      label={label}
                    />
                </Box>
            );
        });

        return (
            <div>
                <FormGroup>{checkboxes}</FormGroup>
                {touched && error && <p className="error">{error}</p>}
            </div>
        );
    };

    render() {
        return <Field {...this.props} type="checkbox" component={this.field}/>;
    }
}

export class SwitchGroupStore extends Component {

    field = ({input, meta, options, inputProps, taxesByOutlets}) => {
        const dispatch = useDispatch();
        const {onChange} = input;
        const {touched, error} = meta;
        const taxList = options.filter(opt => opt.type === "TAX");
        const taxId = taxList[0] ? taxList[0].value : "1";
        let auxInputValue = taxesByOutlets.filter(data => data !== taxId);

        if (inputProps.activedOutlet) {
            input.value = [];
            input.value.push(...auxInputValue);
            dispatch(getCustomizedCharges(...auxInputValue))
        }

        let inputValue = [...input.value];

        const checkboxes = options.map(({label, value, type}, index) => {
            const handleChange = (event) => {
                input.value = [];

                if (event.target.checked) {
                    input.value.push(value);
                    dispatch(toggleSelectOutlet(false));
                    dispatch(getCustomizedCharges(null))
                } else {
                    input.value.splice(input.value.indexOf(value), 1);
                    dispatch(toggleSelectOutlet(false));
                    dispatch(getCustomizedCharges(null))

                }

                return onChange(input.value);
            };

            let checked = inputValue.includes(inputProps.activedSwitchStore ? false : value);


            return (
                <FormControlLabel style={{display: type === "TAX" ? "none" : "block"}}
                                  key={index + 1}
                                  control={
                                      <Switch key={index + 1}
                                              color="secondary"
                                              size="small"
                                              onChange={handleChange}
                                              checked={checked}
                                              value={value}
                                              disabled={inputProps.activedSwitchStore}
                                      />}
                                  label={label}
                />
            );
        });

        return (
            <div>
                <FormGroup>{checkboxes}</FormGroup>
                {touched && error && <p className="error">{error}</p>}
            </div>
        );
    };

    render() {
        return <Field {...this.props} type="checkbox" component={this.field}/>;
    }
}

export const renderSwitchExemption = ({input, label, activedSwitchStore}) => (
    <FormControlLabel
        label={label}
        control={
            <Switch
                color="secondary"
                size="small"
                onChange={input.onChange}
                checked={activedSwitchStore ? false : !!input.value}
                disabled={activedSwitchStore}
            />}
    />
);

export const renderSelectFilter = (
    {input, label, meta: {touched, error}, children, classNameFormControl, inputProps, ...custom}
) => {

    if (input.value && !inputProps.selectedValue) {
        inputProps.handleSelectOutlet(input.value)
    }

    if (input.value === "" && inputProps.selectedValue === 0) {
        inputProps.handleSelectOutlet(0)
    }

    return (
        <FormControl error={touched && error} className={classNameFormControl}>
            <InputLabel shrink component="legend" color="secondary">{label}</InputLabel>
            <br/>
            <Select
                {...input}
                {...custom}
                onClose={input.onChange}
                onChange={(e) => inputProps.handleSelectOutlet(e.target.value)}
                inputProps={{
                    name: input.name,
                    id: 'age-native-simple'
                }}
                value={inputProps.selectedValue}
            >
                {children}
            </Select>
            {
                inputProps.selectedValue === 0 &&
                <FormHelperText>{touched && error}</FormHelperText>
            }
        </FormControl>
    )
};

export class SwitchGroupPermissions extends Component {
    field = ({input, meta, options, keyValue, doorsList}) => {
        const {onChange} = input;
        const {touched, error} = meta;
        let inputValue = input.value;
        const [disabled, setDisabled] = useState(true);
        const appliedKey = inputValue !== "" ? inputValue.includes(keyValue) : false;

        const removeValues = (arr) => {
            for (let i = 0; i < doorsList.length; i++) {
                const exist = arr.includes(doorsList[i]);
                if (exist) {
                    arr.splice(arr.indexOf(doorsList[i]), 1);

                }
            }

        };

        const applyValues = (arr) => {
            for (let i = 0; i < doorsList.length; i++) {
                const exist = arr.includes(doorsList[i]);
                if (!exist) {
                    arr.push(doorsList[i]);
                }
            }
        };


        const checkboxes = options.map(({label, value}, index) => {
            let arr = [...inputValue];
            const handleChange = (event) => {
                if (event.target.checked) {
                    if (value === keyValue) {
                        setDisabled(false);
                        applyValues(arr)
                    }

                    arr.push(value);

                } else {
                    arr.splice(arr.indexOf(value), 1);
                    if (value === keyValue) {
                        setDisabled(true);
                        removeValues(arr)
                    } else {
                        if (keyValue === "reports") {
                            let flag = true;
                            for (let i = 0; i < doorsList.length; i++) {
                                const exist = arr.includes(doorsList[i]);
                                if (exist) {
                                    flag = false
                                }
                            }
                            if (flag) {
                                arr.splice(arr.indexOf("reports"), 1);
                                removeValues(arr);
                                setDisabled(true)
                            }
                        }
                    }

                }
                return onChange(arr);
            };

            const checked = inputValue.includes(value);

            return (
                <FormControlLabel key={index + 1} style={{marginBottom: 4.2}}
                                  control={
                                      <Switch
                                          color="secondary"
                                          size="small"
                                          onChange={handleChange}
                                          checked={checked}
                                          value={value}
                                          disabled={keyValue === value ? false : (appliedKey ? false : disabled)}
                                      />}
                />
            );
        });

        return (
            <div>
                <FormGroup>{checkboxes}</FormGroup>
                {touched && error && <p className="error">{error}</p>}
            </div>
        );
    };

    render() {
        return <Field {...this.props} type="checkbox" component={this.field}/>;
    }
}

export class SwitchGroupSettings extends Component {
    field = ({input, meta, options, keyValue, doorsList, disabledSettings, doorsSettings, handleDisabledSettings}) => {
        const {onChange} = input;
        const {touched, error} = meta;
        let inputValue = input.value;
        const [disabled, setDisabled] = useState(true);
        const appliedKey = inputValue !== "" ? inputValue.includes(keyValue) : false;

        const removeAllValues = (arr) => {
            for (let i = 0; i < doorsSettings.length; i++) {
                const exist = arr.includes(doorsSettings[i]);
                if (exist) {
                    arr.splice(arr.indexOf(doorsSettings[i]), 1);
                    break
                }
            }
        };

        const removeValues = (arr) => {
            for (let i = 0; i < doorsList.length; i++) {
                const exist = arr.includes(doorsList[i]);
                if (exist) {
                    arr.splice(arr.indexOf(doorsList[i]), 1);

                }
            }
        };

        const applyValues = (arr) => {
            for (let i = 0; i < doorsList.length; i++) {
                const exist = arr.includes(doorsList[i]);
                if (!exist) {
                    arr.push(doorsList[i]);
                }
            }
        };

        const checkboxes = options.map(({label, value}, index) => {
            let arr = [...inputValue];
            const handleChange = (event) => {
                if (event.target.checked) {
                    if (value === keyValue) {
                        setDisabled(false);
                        applyValues(arr)
                    }
                    arr.push(value);

                } else {
                    arr.splice(arr.indexOf(value), 1);
                    if (value === keyValue) {
                        setDisabled(true);
                        removeValues(arr)
                    }

                    let flag = true;
                    for (let i = 0; i < doorsSettings.length; i++) {
                        const exist = arr.includes(doorsSettings[i]);
                        if (exist) {
                            flag = false
                        }
                    }
                    if (flag) {
                        arr.splice(arr.indexOf("settings"), 1);
                        removeAllValues(arr);
                        handleDisabledSettings(true);
                        setDisabled(true)
                    }

                }
                return onChange(arr);
            };

            const checked = inputValue.includes(value);

            return (
                <FormControlLabel key={index + 1} style={{marginBottom: 4.2}}
                                  control={
                                      <Switch
                                          color="secondary"
                                          size="small"
                                          onChange={handleChange}
                                          checked={checked}
                                          value={value}
                                          disabled={appliedKey ? false : (keyValue === value ? disabledSettings : (disabledSettings ? true : (!checked ? true : disabled)))}
                                      />}
                />
            );
        });

        return (
            <div>
                <FormGroup>{checkboxes}</FormGroup>
                {touched && error && <p className="error">{error}</p>}
            </div>
        );
    };

    render() {
        return <Field {...this.props} type="checkbox" component={this.field}/>;
    }
}

export class SwitchSettings extends Component {
    field = ({input, meta, options, handleDisabledSettings, doorsList}) => {
        const {onChange} = input;
        const {touched, error} = meta;
        const inputValue = input.value;

        const removeValues = (arr) => {
            for (let i = 0; i < doorsList.length; i++) {
                const exist = arr.includes(doorsList[i]);
                if (exist) {
                    arr.splice(arr.indexOf(doorsList[i]), 1);
                }
            }
        };

        const applyValues = (arr) => {
            for (let i = 0; i < doorsList.length; i++) {
                const exist = arr.includes(doorsList[i]);
                if (!exist) {
                    arr.push(doorsList[i]);
                }
            }
        };

        const checkboxes = options.map(({label, value}, index) => {
            const arr = [...inputValue];
            const handleChange = (event) => {
                if (event.target.checked) {
                    arr.push(value);
                    handleDisabledSettings(false);
                    applyValues(arr)
                } else {
                    arr.splice(arr.indexOf(value), 1);
                    handleDisabledSettings(true);
                    removeValues(arr)

                }

                return onChange(arr);
            };
            const checked = inputValue.includes(value);

            return (
                <FormControlLabel key={index + 1}
                                  control={
                                      <Switch
                                          color="secondary"
                                          size="small"
                                          onChange={handleChange}
                                          checked={checked}
                                          value={value}
                                      />}
                />
            );
        });

        return (
            <div>
                <FormGroup>{checkboxes}</FormGroup>
                {touched && error && <p className="error">{error}</p>}
            </div>
        );
    };

    render() {
        return <Field {...this.props} type="checkbox" component={this.field}/>;
    }
}

export class SwitchPermission extends Component {
    field = ({input, meta, options}) => {
        const {onChange} = input;
        const {touched, error} = meta;
        const inputValue = input.value;

        const checkboxes = options.map(({label, value}, index) => {
            const handleChange = (event) => {
                const arr = [...inputValue];

                if (event.target.checked) {
                    arr.push(value);
                } else {
                    arr.splice(arr.indexOf(value), 1);
                }


                return onChange(arr);
            };
            const checked = inputValue.includes(value);

            return (

                <FormControlLabel key={index + 1}
                                  control={
                                      <Switch
                                          color="secondary"
                                          size="small"
                                          onChange={handleChange}
                                          checked={checked}
                                          value={value}
                                      />}
                />
            );
        });

        return (
            <div>
                <FormGroup>{checkboxes}</FormGroup>
                {touched && error && <p className="error">{error}</p>}
            </div>
        );
    };

    render() {
        return <Field {...this.props} type="checkbox" component={this.field}/>;
    }
}

export const switchActiveSecondCurrency = ({input, label, active, setActive}) => (
    <FormControlLabel
        label={label}
        control={
            <Switch
                color="secondary"
                size="small"
                onChange={(e) => {
                    setActive(e)
                    input.onChange(e)
                }}
                checked={active}
            />}
    />
)

export const fieldValueSecondCurrency = ({
                                             label,
                                             input,
                                             meta: {touched, invalid, error},
                                             resetValue,
                                             cancelResetValue,
                                             ...custom
                                         }) => {
    return (
        <TextField
            label={label}
            error={touched && invalid}
            helperText={touched && error}
            value={resetValue ? "" : input.value}
            onChange={(e) => {
                input.onChange(e)
                cancelResetValue()
            }}
            {...custom}
        />
    )
}

export const fieldBlockAmount = ({
                                     label,
                                     input,
                                     meta: {touched, invalid, error},
                                     resetValue,
                                     cancelResetValue,
                                     ...custom
                                 }) => {
    return (
        <TextField
            label={label}
            error={touched && invalid}
            helperText={touched && error}
            value={resetValue ? "" : input.value}
            onChange={(e) => {
                input.onChange(e)
                cancelResetValue()
            }}
            {...custom}
        />
    )
}
