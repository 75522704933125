import {verifyToken} from "../../../common/apiCalls";
import * as api_settings from "../../api.calls";
import {loadGeneral} from "../../actions/asyncAction";


export const validate = values => {
    const errors = {};
    if (!values.blockAmount ) {
        errors.blockAmount = 'Required'
    }
    if (parseFloat(values.blockAmount) > 0 ) {
        errors.blockAmount = 'Required'
    }
    return errors
};

export const submitBlockAmountForm = async (values, dispatch, {reset, ...rest}) =>  {
    try {
        await verifyToken();

        const blockAmountRequest = api_settings.postBlockAmount();

        const response = await blockAmountRequest({
            blockAmountData: values
        });

        if (response.status === 201) {
            dispatch(loadGeneral());
            reset()
        }
        if(response.status === 200) {
            dispatch(loadGeneral());
            rest.history.goBack()
        }
        rest.history.goBack()
    } catch (e) {
    }
};