export const LOAD_CONSUMPTION = "LOAD_CONSUMPTION";
export const CONSUMPTION_FETCHING = "CONSUMPTION_FETCHING";
export const SUCCESS_PAYMENT = "SUCCESS_PAYMENT";
export const LOAD_SELECTED_GUEST_CONSUMPTION = "LOAD_SELECTED_GUEST_CONSUMPTION";
/*
* CONSUMPTIONS
*/
export const  SET_CONSUMPTION_STATUS = "SET_CONSUMPTION_STATUS";
export const SHOW_CONSUMPTION_PANEL = "SHOW_CONSUMPTION_PANEL";
export const HIDE_CONSUMPTION_PANEL = "HIDE_CONSUMPTION_PANEL";

/* DOCUMENTS */
export const LOAD_DOCUMENTS = "LOAD_DOCUMENTS";
export const CLEAN_CONSUMPTION = "CLEAN_CONSUMPTION";


/*
* ACCOMMODATIONS
*/
export const ADD_STAY_QUANTITY = "ADD_STAY_QUANTITY";
export const REDUCE_STAY_QUANTITY = "REDUCE_STAY_QUANTITY";
export const SET_STAY_QUANTITY = "SET_STAY_QUANTITY";
export const SET_ACCOMMODATION_DISCOUNT = "SET_ACCOMMODATION_DISCOUNT";
/*
* ANCILLARY
*/
export const TOGGLE_OUTLET = "TOGGLE_OUTLET";
export const SET_ANCILLARY_DISCOUNT = "SET_ANCILLARY_DISCOUNT";

/*PAYMENT STATE*/
export const SET_COMPANY_FLAG = "SET_COMPANY_FLAG";
export const CLEAR_COMPANY_FLAG = "CLEAR_COMPANY_FLAG";
export const SET_RRHH_PAYMENT_FLAG = "SET_RRHH_PAYMENT_FLAG";
export const CLEAR_RRHH_PAYMENT_FLAG = "CLEAR_RRHH_PAYMENT_FLAG";
export const SET_GROUP_PAYMENT = "SET_GROUP_PAYMENT";
export const SET_COMPANY = "SET_COMPANY";
export const CLEAR_COMPANY_INFO = "CLEAR_COMPANY_INFO";
export const SET_PAYMENT_TYPE = "SET_PAYMENT_TYPE";
export const SET_PAYMENT_CURRENCY = "SET_PAYMENT_CURRENCY";
export const SET_EXCHANGE_RATE = "SET_EXCHANGE_RATE";
export const SELECT_PAYMENT_OPTIONS = "SELECT_PAYMENT_OPTIONS"

/*DASHBOARD*/
export const LOAD_DASHBOARD = 'LOAD_DASHBOARD';
export const LOAD_DASHBOARD_SALES_TOTALS_BY_TYPE = 'LOAD_DASHBOARD_SALES_TOTALS_BY_TYPE';


/* CLIENT HISTORY */
export const LOAD_HISTORY = "LOAD_HISTORY";
export const LOAD_SELECTED_DOCUMENTS = "LOAD_SELECTED_DOCUMENTS";
export const SELECT_RECEIPT = "SELECT_RECEIPT"
export const TOGGLE_RECEIPT_DETAILS = "TOGGLE_RECEIPT_DETAILS"

/* SUPPORT */
export const LOAD_SUPPORT_QUESTIONS = "LOAD_SUPPORT_QUESTIONS";
export const SET_SUPPORT_FILTER_VALUE = "SET_SUPPORT_FILTER_VALUE";