import React, {useEffect, useState} from "react";
// COMPONENTS
import ExportButtons from "../../../common/components/utils/ExportButtons"
import ReportsParametersPanel from "../../../common/components/utils/reportsParametersPanel";
import MultiTable from "./MultiTable"
// COMPONENTS MATERIAL UI
import {Box, Container, Grid, Typography} from "@material-ui/core/";
import Classes from "../../../../styles";
import {connect, useDispatch} from "react-redux";
import {handleViewPaymentPanel} from "../../../common/actions/actions";
import {searchRankingReport} from "../../utils/reports.utils";
import {reportsSelector} from "../../selector/reportsSelector";
import ReportsSearchPanel from "../../../common/components/utils/reportsSearchPanel";
import {Button} from "@material-ui/core";
import {handleFilterSearchPanel, loadRankingReport, loadSalesReport} from "../../redux/asyncActions";
import {formatedReverseDate, formatDate, formatTime} from "../../../common/components/utils/handleCurrentDate";
import {POS_ORIGIN, RUMI_ORIGIN} from "../../../common/commonConstants";
import Filters from "../../components/reports/Filters";
import Loader from "../../../common/components/utils/Loader";

const SalesRankingReport = ({report, currentDateRange, isLoading, paymentPanel}) => {
    const classes = Classes();
    const dispatch = useDispatch();
    useEffect(() => {
        if (paymentPanel) {
            dispatch(handleViewPaymentPanel(false));
        }
        dispatch(loadRankingReport());
    }, [isLoading]);


// const filterRankingReport = (origin) => {
//     const reportType = 'sales-ranking';
//     if (Object.keys(currentDateRange).length > 0) {
//         dispatch(handleFilterSearchPanel(reportType,
//             formatedReverseDate(currentDateRange.initDate),
//             formatedReverseDate(currentDateRange.endDate),
//             currentDateRange.initTime,
//             currentDateRange.endTime,
//             origin))
//     }else{
//         const today = new Date();
//         const now = new Date();
//         today.setHours(0,0,0);
//         const initDate =formatDate(today);
//         const endDate =formatDate(now);
//         const initTime =formatTime(today);
//         const endTime =formatTime(now);
//         dispatch(handleFilterSearchPanel(reportType,initDate,endDate,initTime,endTime,origin))
//     }
// };

if (isLoading) {
        return (
            <Container className={classes.styles.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Loader/>
                    </Grid>
                </Grid>
            </Container>
        )
    }
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h1" className={classes.typography.pageTitles}>
                    <Box color="text.secondary" display="inline"> Reports /</Box>
                    &nbsp;Ranking 
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container item>
                    <Grid item xs={12} sm={12} md={5} lg={4}>
                        <ReportsParametersPanel panelClass={"sales-ranking"}/>
                    </Grid>
                    <Grid item xs={12} sm={7} md={3} lg={4} >
                        <ReportsSearchPanel searcher={searchRankingReport}/>
                    </Grid>
                    <Grid item xs={6} sm={3} md={2} lg={2} />
                    <Grid item xs={6} sm={2} md={2} lg={2}>
                        <Box display="flex" flexDirection="row-reverse">
                            <ExportButtons report={report} name={"sales-ranking-report"}/>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <MultiTable report={report}/>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    report: reportsSelector(state, "ranking"),
    currentDateRange: state.utilsReducer.getDateRange,
    isLoading: state.reports.salesRankingReport.rankingIsLoading,
    paymentPanel: state.utilsReducer.showPaymentPanel
});

export default connect(mapStateToProps)(SalesRankingReport)