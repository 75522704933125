import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import {getCategories, getOutlets, getProducts, getTaxes, putAllProducts,} from '../actions/store/async';
import {
    activeAlertDragAndDrop,
    filtersVisibleProducts,
    getCreatedProduct,
    getEventTypeProduct,
    handleDropProducts
} from '../actions/store/sync';
import {store_actions} from "../permissionsConstants";
import {handleViewPaymentPanel} from "../../common/actions/actions";
// COMPONENTS
import Store from './Store'
import {Container, Grid} from '@material-ui/core/';
import Loader from "../../common/components/utils/Loader";
import Classes from "../../../styles";

const StoreView = ({
                       selectedCategoryName,
                       dropProductList,
                       handleViewPaymentPanel,
                       putAllProducts,
                       filtersVisibleProducts,
                       handleDropProducts,
                       searchValue,
                       getCreatedProduct,
                       productList,
                       getEventTypeProduct,
                       activeAlertDragAndDrop,
                       alertDragAndDrop,
                       permissionsList
                   }) => {
    const classes = Classes();
    const dispatch = useDispatch()
    const [storeIsLoading, setStoreIsLoading] = useState(true);
    const permissions = Object.values(permissionsList);
    const canManageStore = permissions.includes(store_actions) || permissions.includes("superuser");

    useEffect(() => {
        dispatch(handleViewPaymentPanel(false));
        dispatch(getCategories());
        dispatch(getProducts());
        dispatch(getOutlets());
        dispatch(getTaxes());
        setStoreIsLoading(false)
        getEventTypeProduct("")
    }, []);

    if (storeIsLoading) {
        return (
            <Container className={classes.styles.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Loader/>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    return (
        <Store
            selectedCategoryName={selectedCategoryName}
            putAllProducts={putAllProducts}
            dropProductList={dropProductList}
            filtersVisibleProducts={filtersVisibleProducts}
            handleDropProducts={handleDropProducts}
            searchValue={searchValue}
            getCreatedProduct={getCreatedProduct}
            getEventTypeProduct={getEventTypeProduct}
            activeAlertDragAndDrop={activeAlertDragAndDrop}
            alertDragAndDrop={alertDragAndDrop}
            canManage={canManageStore}
        />
    )
};

const mapStateToProps = state => {
    return {
        selectedCategoryName: state.storeReducer.selectedCategoryName,
        dropProductList: state.storeReducer.dropProductList,
        searchValue: state.utilsReducer.search,
        productList: Object.values(state.storeReducer.productList),
        alertDragAndDrop: state.storeReducer.alertDragAndDrop,
        permissionsList: state.auth.permissionsList,
    }
};


export default connect(mapStateToProps, {
    handleViewPaymentPanel,
    putAllProducts,
    filtersVisibleProducts,
    handleDropProducts,
    getCreatedProduct,
    getEventTypeProduct,
    activeAlertDragAndDrop
})(StoreView)