import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Field, reduxForm, getFormInitialValues} from "redux-form";
import {connect, useDispatch} from 'react-redux';
import {getOutletsSelector} from '../../selector/store.selector'
import {handleViewPaymentPanel} from "../../../common/actions/actions" 

// COMPONENTS
import InfoList from '../utils/InfoList';
// MATERIAL UI
import {Box, Button, FormControl, FormControlLabel, Grid, InputLabel, Radio, Typography} from '@material-ui/core';

import {
    radioButton,
    renderTextField,
    SliderForm,
    switchBlocked,
    SwitchGroupCharge
} from "../../../common/components/utils/ReduxFromMaterialUI";
import {submitTaxesForm, validate} from "../../forms/submit/taxes.submit";

import Classes from '../../../../styles';

let ChargesForm = (props) => {
    const classes = Classes();
    const dispatch = useDispatch();
    const {handleSubmit, pristine, reset, submitting, outletList, activeSwitchCharge, offInfo, initialValues} = props;

    const handleCancel = () => {
        reset();
        props.history.goBack()
    };

    useEffect(() => {
        dispatch(handleViewPaymentPanel(false))
        if(initialValues === undefined || outletList.length === 0){
            handleCancel()
        }
    });

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h1" className={classes.typography.pageTitles}>
                    <Box display="inline" color="text.secondary">
                        Settings /
                    </Box>
                    <Link to={`../billing`} className={`general-nav- ${classes.nav.link}`}>
                        <Box display="inline" color="text.secondary">
                            &nbsp;Billing /
                        </Box>
                    </Link>
                    &nbsp;Charge
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Grid container item  spacing={3}>
                                <Grid item xs={12} md={2}>
                                    <InfoList title={"Description"}
                                              description={"This taxes will be charged on your customers bill and they would see it."}/>
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    <Grid container item  >
                                        <Grid item xs={12} sm={8} lg={6}>
                                            <Box display="flex" flexDirection="column">
                                                <FormControl
                                                    className={`${classes.forms.formMargin} ${classes.forms.fullFormWidth}`}>
                                                    <Field disabled id="standard-input" label="Name *" name="name"
                                                        component={renderTextField} InputLabelProps={{shrink: true,}}
                                                        className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} `}
                                                    />
                                                </FormControl>
                                                <FormControl className={`${classes.forms.formMargin} `}>
                                                    <InputLabel shrink component="legend" color="secondary">
                                                        Value(%)
                                                    </InputLabel>
                                                    <Field
                                                        name="value"
                                                        component={SliderForm}
                                                    />
                                                </FormControl>
                                                {
                                                    false &&
                                                    <FormControl>
                                                        <Field
                                                            label="Type *"
                                                            name="type"
                                                            component={radioButton}
                                                        >
                                                            <FormControlLabel value="CHARGE"
                                                                            control={<Radio color="secondary"/>}
                                                                            label="Charge"/>
                                                        </Field>
                                                    </FormControl>
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}  md={10} >
                                            <Box fontStyle="oblique" borderRadius={5} color="text.secondary" p={2}
                                                style={{border: "1px solid #5BE576"}}>
                                                <Typography>This Charge is INCLUDED on the purchase price from all outlets,
                                                    includes ACCOMODATION</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} md={2}>
                                    <InfoList title={"Charge application by outlets"}
                                              description={""}/>
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    <Grid container item spacing={3}>
                                        <Grid item xs={6} sm={3}>
                                            <Box mr={10}>
                                                <InputLabel shrink component="legend" color="secondary">PMS</InputLabel>
                                                <Box mt={1.7}>
                                                    <Typography variant="body1" color="textSecondary"
                                                                gutterBottom>{outletList.length === 0 ? "" : outletList[0].name}</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={2}>
                                            <Box mr={15}>
                                                <InputLabel shrink component="legend"
                                                            color="secondary">Charge</InputLabel>
                                                <Box style={{cursor: 'not-allowed',filter:'opacity(0.5)'}} mt={1.7}>
                                                    <Field
                                                        name="accomodation"
                                                        label=""
                                                        component={switchBlocked}
                                                        inputProps={{'activeSwitch': activeSwitchCharge}}
                                                    />
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={7} md={5}>
                                                <InputLabel shrink component="legend"
                                                            color="secondary">i.e</InputLabel>
                                                {
                                                    activeSwitchCharge ?
                                                    <Box display="flex"
                                                            style={{backgroundColor: "#e7e7e7", fontSize: 13}} p={2}>
                                                        <Box display="flex" flexDirection="column"
                                                                alignItems="center">
                                                            <span>PMS PRICE</span><span>100 ( base price + 0%)</span></Box>&nbsp;&nbsp; +&nbsp;&nbsp;
                                                        <Box display="flex" flexDirection="column"
                                                                alignItems="center">
                                                            <span>RUMI IGV</span><span>18 %</span></Box>&nbsp;&nbsp;  =&nbsp;&nbsp;
                                                        <Box display="flex" flexDirection="column"
                                                                alignItems="center">
                                                            <span>RUMI PRICE</span><span style={{
                                                            color: "#5BE576",
                                                            fontWeight: "bold"
                                                        }}>118</span></Box>
                                                    </Box>
                                                    :
                                                    <Box display="flex"
                                                            style={{backgroundColor: "#e7e7e7", fontSize: 13}} p={2}>
                                                        <Box display="flex" flexDirection="column"
                                                                alignItems="center">
                                                            <span>PMS PRICE</span><span>100 ( base price + 8%)</span></Box>&nbsp;&nbsp; +&nbsp;&nbsp;
                                                        <Box display="flex" flexDirection="column"
                                                                alignItems="center">
                                                            <span>RUMI IGV</span><span>18 %</span></Box>&nbsp;&nbsp;  =&nbsp;&nbsp;
                                                        <Box display="flex" flexDirection="column"
                                                                alignItems="center">
                                                            <span>RUMI PRICE</span><span style={{
                                                            color: "#5BE576",
                                                            fontWeight: "bold"
                                                        }}>118</span></Box>
                                                    </Box>
                                                }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}  md={2}/>
                                <Grid item xs={12} md={10}>
                                    <Grid container item spacing={2}>
                                        <Grid item xs={6} sm={3}>
                                            <Box display="flex" flexDirection="column" mr={10}>
                                                <InputLabel shrink component="legend"
                                                            color="secondary">Rumi</InputLabel>
                                                <Box mt={1}>
                                                    {outletList.length === 0 ? "" :
                                                        outletList.map((outlet, index) => (
                                                            <Typography key={index + 1} variant="body1"
                                                                        color="textSecondary"
                                                                        gutterBottom>{outlet.name}</Typography>

                                                        ))
                                                    }
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={2}>
                                            <InputLabel shrink component="legend"
                                                        color="secondary">Charge</InputLabel>
                                            <Box mt={1}>
                                                <Box style={{cursor: 'not-allowed', filter:'opacity(0.5)'}}>
                                                    <Field
                                                        name="accomodation"
                                                        label=""
                                                        component={switchBlocked}
                                                        inputProps={{'activeSwitch': activeSwitchCharge}}
                                                    />
                                                </Box>
                                                { outletList.length === 0 ? "" :
                                                    <SwitchGroupCharge name="apply" options={outletList}
                                                                        inputProps={{'activeSwitch': activeSwitchCharge}}/>
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={7} md={5}>
                                            <InputLabel shrink component="legend"
                                                        color="secondary">i.e</InputLabel>
                                            {
                                                activeSwitchCharge || offInfo ?
                                                <Box display="flex"
                                                        style={{backgroundColor: "#e7e7e7", fontSize: 13}} p={2}>
                                                    <Box display="flex" flexDirection="column"
                                                            alignItems="center">
                                                        <span>BASE PRICE</span><span>84, 75</span></Box>&nbsp;&nbsp; +&nbsp;&nbsp;
                                                    <Box display="flex" flexDirection="column"
                                                            alignItems="center">
                                                        <span>RUMI IGV</span><span>18 %</span></Box>&nbsp;&nbsp;  =&nbsp;&nbsp;
                                                    <Box display="flex" flexDirection="column"
                                                            alignItems="center">
                                                        <span>RUMI CHARGE</span><span>0%  </span></Box>&nbsp;&nbsp; +&nbsp;&nbsp;
                                                    <Box display="flex" flexDirection="column"
                                                            alignItems="center">
                                                        <span>RUMI PRICE</span><span style={{
                                                        color: "#5BE576",
                                                        fontWeight: "bold"
                                                    }}>100</span></Box>
                                                </Box>
                                                :
                                                <Box display="flex"
                                                        style={{backgroundColor: "#e7e7e7", fontSize: 13}} p={2}>
                                                    <Box display="flex" flexDirection="column"
                                                            alignItems="center">
                                                        <span>BASE PRICE</span><span>79, 37</span></Box>&nbsp;&nbsp; +&nbsp;&nbsp;
                                                    <Box display="flex" flexDirection="column"
                                                            alignItems="center">
                                                        <span>RUMI IGV</span><span>18 %</span></Box>&nbsp;&nbsp;  =&nbsp;&nbsp;
                                                    <Box display="flex" flexDirection="column"
                                                            alignItems="center">
                                                        <span>RUMI CHARGE</span><span>8% </span></Box>&nbsp;&nbsp; +&nbsp;&nbsp;
                                                    <Box display="flex" flexDirection="column"
                                                            alignItems="center">
                                                        <span>RUMI PRICE</span><span style={{
                                                        color: "#5BE576",
                                                        fontWeight: "bold"
                                                    }}>100</span></Box>
                                                </Box>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container item  spacing={2}>
                                <Grid item xs={12} md={2} />
                                <Grid item xs={12} md={8} >
                                    <Button type="submit" variant="contained" color="primary"
                                            style={{margin: '0 0.8em 0 0', width: '100px'}}
                                            disabled={pristine || submitting}
                                    >Save</Button>
                                    <Button variant="outlined" color="primary"
                                            style={{margin: '0 0.8em 0 0', width: '100px'}}
                                            onClick={handleCancel}
                                    >Cancel</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Grid>

        </Grid>
    );
};

ChargesForm = reduxForm({
    form: 'charges',
    onSubmit: submitTaxesForm,
    validate
})(ChargesForm);

ChargesForm = connect(
    state => {
        const stateOutlets = state.storeReducer.outletList;
        return {
            initialValues: getFormInitialValues('charges')(state),
            outletList: getOutletsSelector(stateOutlets),
            activeSwitchCharge: state.storeReducer.handlerSwitchCharge,
            offInfo: state.storeReducer.infoCharge

        }
    }
)(ChargesForm);


export default ChargesForm