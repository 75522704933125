import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
        xs: {
            '& .xs-show':{
                [theme.breakpoints.down('xs')]: {
                    display: 'block'
                },
            },
            '& .xs-hide':{
                [theme.breakpoints.down('xs')]: {
                    display: 'none'
                },
            },
        },
        sm: {
            '& .sm-hide':{
                [theme.breakpoints.down('sm')]: {
                    display: 'none'
                },
            },
            '& .sm-show':{
                [theme.breakpoints.down('sm')]: {
                    display: 'block'
                },
            },
        }, 
        md:{
            '& .md-hide':{
                [theme.breakpoints.up('md')]: {
                    display: 'none'
                },
            },
            
        },
        
}));