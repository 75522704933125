import {
    FILL_CUSTOMER,
    REMOVE_AREA,
    REMOVE_CUSTOMER,
    REMOVE_GROUP,
    REMOVE_TAX,
    REMOVE_USER
} from "./actionsTypes"
import {GET_RESPONSE} from "../../common/actions/actionTypes"
import {handleAlerts} from '../../common/actions/actions'

import * as api_settings from "../api.calls";
import {verifyToken} from "../../common/apiCalls";
import {initialize} from "redux-form"
import {padLeftZeros} from "../utils";

export const editArea = id => (dispatch, getState) => {
    const area = getState().areasSettingReducer.areas[id]
    dispatch(initialize("area", area))
}

export const removeArea = (areaId) => async dispatch => {
    try {
        await verifyToken();
        const response = await api_settings.removeArea(areaId);
        setTimeout(()=> { 
            dispatch({
                type: REMOVE_AREA,
                areaId: areaId
            })
        }, 3000);
        dispatch(handleAlerts('success', 'Area removed', true));
        setTimeout(()=> { 
            dispatch(handleAlerts('close', '', false));
        }, 900);
        dispatch({
            type: GET_RESPONSE,
            response: {
                status: response.status, 
                method: response.config.method,
                id: areaId, 
                key: "delete-area"
            }
        })  
    } catch (err) {
        dispatch({
            type: GET_RESPONSE,
            response: {}
        })
        dispatch(handleAlerts('error', 'Failed remove', true));
    }
    
}

export const selectCustomer = customer => ({
    type: FILL_CUSTOMER,
    customer
})

export const emptyCustomer = () => ({type: FILL_CUSTOMER})

export const removeTaxes = (taxId) => async dispatch => {
    try {
        await api_settings.removeTaxes(taxId);
        dispatch({
            type: REMOVE_TAX,
            taxId: taxId
        })
    } catch (err) {
    }
}

export const editBlockAmount = (form) => (dispatch, getState) => {
    const blockAmount = getState().companyReducer;
    dispatch(initialize("block-amount", blockAmount))
};

export const editExchangeRate = (form) => (dispatch, getState) => {
    const rate = getState().exchangeRateReducer;
    dispatch(initialize("rate", rate))
};

export const editTaxes = (id, form) => (dispatch, getState) => {
    const taxes = getState().billingSettingReducer.taxesList[id]
    dispatch(initialize(form, taxes))
}

export const editSerial = locationId => (dispatch, getState) => {

    const state = getState();
    const location = state.areasSettingReducer.areas[locationId];
    const serialList = state.billingSettingReducer.serialList;
    let serials = location.serials;

    serials = serials.reduce((acc, elem) => {
        acc[elem.documentType] = elem.serie
        return acc
    }, {})

    const getSerialValues = (serial) => serial ? [serial.substr(1), padLeftZeros(serialList[serial].correlative)] : [null, null]
    const ticketKey = serials["TICKET"]
    const [ticket, ticketCorrelative] = getSerialValues(ticketKey);

    const creditNoteTicketKey = serials["CREDIT_NOTE_TICKET"]
    const [creditNoteTicket, creditNoteTicketCorrelative] = getSerialValues(creditNoteTicketKey);

    const invoiceKey = serials["INVOICE"]
    const [invoice, invoiceCorrelative] = getSerialValues(invoiceKey);

    const creditNoteInvoiceKey = serials["CREDIT_NOTE_INVOICE"]
    const [creditNoteInvoice, creditNoteInvoiceCorrelative] = getSerialValues(creditNoteInvoiceKey)

    const serialData = {
        location: location.id,
        ticket,
        ticketCorrelative,
        creditNoteTicket,
        creditNoteTicketCorrelative,
        invoice,
        invoiceCorrelative,
        creditNoteInvoice,
        creditNoteInvoiceCorrelative,
        isEdit: true
    }
    dispatch(initialize('serial', serialData))
}

export const editUser = id => (dispatch, getState) => {
    const user = getState().usersReducer[id]
    dispatch(initialize("user", {...user, isEdit:true}))
}

export const removeUser = (UserId) => async dispatch => {
    try {
        const response = await api_settings.removeUser(UserId);
        setTimeout(()=> { 
            dispatch({
                type: REMOVE_USER,
                userId: UserId
            })
        }, 3000);
        dispatch(handleAlerts('success', 'User removed ', true));
        setTimeout(()=> { 
            dispatch(handleAlerts('close', '', false));
        }, 900);
        dispatch({
            type: GET_RESPONSE,
            response: {
                status: response.status, 
                method: response.config.method,
                id:  UserId, 
                key: "delete-user"
            }
        })  
    } catch (err) {
        dispatch({
            type: GET_RESPONSE,
            response: {}
        })
        dispatch(handleAlerts('error', 'Failed remove', true));
    }
    
}

export const editCustomer = id => (dispatch, getState) => {
    const customer = getState().customerSettings.customers[id]
    dispatch(initialize("employees", customer))
}

export const removeCustomer = (CustomerId) => async dispatch => {
    try {
        const response = await api_settings.removeCustomer(CustomerId);
        setTimeout(()=> { 
            dispatch({
                type: REMOVE_CUSTOMER,
                customerId: CustomerId
            })
        }, 3000);
        dispatch(handleAlerts('success', 'Customer removed ', true));
        setTimeout(()=> { 
            dispatch(handleAlerts('close', '', false));
        }, 900);
        dispatch({
            type: GET_RESPONSE,
            response: {
                status: response.status, 
                method: response.config.method,
                id: CustomerId, 
                key: "delete-customer"
            }
        })  
    } catch (err) {
        dispatch({
            type: GET_RESPONSE,
            response: {}
        })
        dispatch(handleAlerts('error', 'Failed remove', true));
    }
}

export const editGroup = id => (dispatch, getState) => {
    const group = getState().groupsReducer[id]

    const locations = group.locations.map(elem => {
        elem = elem.id
        return elem
    })
    const permissions = group.permissions

    const groupData = {
        id: group.id,
        name: group.name,
        origin: group.profile.origin,
        areas: locations,
        permission: permissions
        
    }
    dispatch(initialize("group", groupData))
}

export const removeGroup = (GroupId) => async dispatch => {
    try {
        const response = await api_settings.removeGroup(GroupId);
        setTimeout(()=> { 
            dispatch({
                type: REMOVE_GROUP,
                groupId: GroupId
            })
        }, 3000);
        dispatch(handleAlerts('success', 'Group removed ', true));
        setTimeout(()=> { 
            dispatch(handleAlerts('close', '', false));
        }, 900);
        dispatch({
            type: GET_RESPONSE,
            response: {
                status: response.status, 
                method: response.config.method,
                id: GroupId, 
                key: "delete-group"
            }
        })  
    } catch (err) {
        dispatch({
            type: GET_RESPONSE,
            response: {}
        })
        dispatch(handleAlerts('error', 'Failed remove', true));
    }

}