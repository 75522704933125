import {
    CLEAN_CONSUMPTION,
    CONSUMPTION_FETCHING,
    HIDE_CONSUMPTION_PANEL,
    LOAD_CONSUMPTION,
    SET_CONSUMPTION_STATUS,
    SHOW_CONSUMPTION_PANEL,
    SUCCESS_PAYMENT
} from "../actionTypes";
import {transformToObject, transformToObjectWithDuplicateIds} from "../../common/reducerUtilities"

const initialState = {
    guestConsumptions: {},
    consumptionItems: {},
    guestsSelected: [],
    isFetch: false,
    showConsumptionsPanel: false
};

const toggleConsumptionStatus = consumption => ({...consumption, isCancelled: !consumption.isCancelled});

function toggleIsFetch(state = initialState.isFetch, action) {
    switch (action.type) {
        case LOAD_CONSUMPTION:
        case CONSUMPTION_FETCHING:
            return action.isFetch;
        case CLEAN_CONSUMPTION:
            return false
        default:
            return state
    }
}

function guestSelectedReducer(state = initialState.guestsSelected, action) {
    switch (action.type) {
        case "ADD_GUEST_SELECTED":
            return [...state, action.guest]  // todo: when add many guest at same time
        case "REMOVE_GUEST":
            return state.filter(customer => (customer !== action.guest));
        case LOAD_CONSUMPTION:
            return action.guestsSelected;
        case CLEAN_CONSUMPTION:
            return initialState.guestsSelected;
        default:
            return state
    }
}

function guestConsumptionsReducer(state = initialState.guestConsumptions, action) {
    switch (action.type) {
        case LOAD_CONSUMPTION:
            const guestConsumptions = transformToObject(action.guestConsumptions, "customerId");
            return guestConsumptions;
        case SUCCESS_PAYMENT:
        case CLEAN_CONSUMPTION:
            return initialState.guestConsumptions;
        default:
            return state;
    }
}

function consumptionItemsReducer(state = initialState.consumptionItems, action) {
    switch (action.type) {
        case LOAD_CONSUMPTION:
            const consumptionItems = transformToObjectWithDuplicateIds(action.consumptionItems);
            return consumptionItems;
        case SET_CONSUMPTION_STATUS:
            return {
                ...state,
                [action.consumptionId]: toggleConsumptionStatus(state[action.consumptionId])
            }
        case CLEAN_CONSUMPTION:
            return initialState.consumptionItems
        default:
            return state;
    }
}

function showConsumptionsPanel(state = initialState.showConsumptionsPanel, action) {
    switch (action.type) {
        case SHOW_CONSUMPTION_PANEL:
            return {...state, isHistoryLoaded: true};
        case HIDE_CONSUMPTION_PANEL:
            return {...state, isHistoryLoaded: false};
        default:
            return state;
    }
}

function guestConsumptionReducer(state = initialState, action) {

    return {
        guestConsumptions: guestConsumptionsReducer(state.guestConsumptions, action),
        guestsSelected: guestSelectedReducer(state.guestsSelected, action),
        consumptionItems: consumptionItemsReducer(state.consumptionItems, action),
        showConsumptionsPanel: showConsumptionsPanel(state.showConsumptionsPanel, action),
        isFetch: toggleIsFetch(state.isFetch, action)
    }
}

export const selectedGuest = state => state.guestsSelected;
export const consumptionByGuest = (state, customerId) => state.guestConsumptions[customerId];
export const getConsumption = (state, consumptionId) => state.consumptionItems[consumptionId];

export default guestConsumptionReducer;