import React, {useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {handleViewPaymentPanel} from "../../../common/actions/actions" 
// COMPONENTS
import InfoList from '../utils/InfoList';
// MATERIAL UI
import {Box, Button, Grid, InputAdornment, Typography} from '@material-ui/core';
// CSS
import Classes from '../../../../styles';
import {change, Field, formValueSelector, reduxForm} from "redux-form";
import {submitSerialForm, validate} from "../../forms/submit/seriesAndCorrelative.submit";
import {renderSelectField, renderTextField} from "../../../common/components/utils/ReduxFromMaterialUI";
import {getSerial} from "../../selector/billing.selector";
import {padLeftZeros} from "../../utils";
import {areasSelector} from "../../selector/areas.selector";
import {loadBillings} from "../../actions/asyncAction";
import MenuItem from '@material-ui/core/MenuItem';

let SeriesAndCorrelativesForm = (props) => {
    const classes = Classes();
    const {handleSubmit, pristine, reset, submitting, locationList, serialList, isEdit, valid} = props;
    const dispatch = useDispatch();
    const handleCancel = () => {
        props.history.goBack();
        reset()
    };

    useEffect(() => {
            dispatch(loadBillings());
            dispatch(handleViewPaymentPanel(false))
        }, [dispatch]
    );


    const handleCorrelative = (e, prefix, dest) => {
        const inputValue = e.target.value;
        if (inputValue.length === 3) {
            const fullSerial = prefix.concat(inputValue);
            const serial = serialList[fullSerial];
            const correlative = serial ? serial.correlative : 1;
            const formatCorrelative = padLeftZeros(correlative);
            dispatch(change('serial', dest, formatCorrelative))
        } else {
            const formatCorrelative = '';
            dispatch(change('serial', dest, formatCorrelative))
        }
    };
    const serialExpresion = /^[0-9A-Z]{0,3}$/g;
    const serialNormalize = (value, previousValue) => {
        const cleanSerial = value.toUpperCase();
        return cleanSerial.match(serialExpresion) ? cleanSerial : previousValue
    };
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h1" className={classes.typography.pageTitles}>
                    <Box display="inline" color="text.secondary">
                        Settings /
                    </Box>
                    <Link to={`../billing`}
                          className={`general-nav- ${classes.nav.link}`}>
                        <Box display="inline" color="text.secondary">
                            &nbsp;Billing /
                        </Box>
                    </Link>
                    &nbsp;Series & Correlatives
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={2}>
                            <InfoList title={"Description"}
                                      description={"This business area will appear when the users log on an" +
                                      " associated app to select a correspondant location."}/>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Grid container item xs={12}>
                                <Grid item xs={12}>
                                    <Grid container item xs={12}>
                                        <Field className={`${classes.forms.select}`} disabled={isEdit}
                                               classNameFormControl={`${classes.forms.formMargin} ${classes.forms.fullFormWidth}`}
                                               component={renderSelectField} label="Location *" name="location"
                                        >
                                            <MenuItem value="">
                                                <em>Select a Location...</em>
                                            </MenuItem>
                                            {locationList.map(location =>
                                                <MenuItem aria-label="None" className={classes.forms.option} value={location.id}
                                                          key={location.id}>{location.name}</MenuItem>
                                            )}
                                        </Field>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container item spacing={2}>
                                        <Grid item xs={4}>
                                            <Field id="ticket" label="Ticket" name="ticket" component={renderTextField}
                                                className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} ${classes.forms.formMargin}  ${classes.forms.fullFormWidth}`}
                                                InputLabelProps={{shrink: true,}}
                                                onChange={(e) => handleCorrelative(e, 'B', "ticketCorrelative")}
                                                InputProps={{
                                                    startAdornment: <InputAdornment
                                                        position="start">B-</InputAdornment>,
                                                    inputProps: {maxLength: 3}
                                                }}
                                                normalize={serialNormalize}
                                            />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Field id="ticketCorrelative" name="ticketCorrelative"
                                                   label="Correlative Number" type="tel" component={renderTextField}
                                                className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} ${classes.forms.formMargin} ${classes.forms.fullFormWidth}`}
                                                InputLabelProps={{shrink: true,}}
                                                inputProps={{maxLength: 8}} normalize={padLeftZeros}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container item spacing={2}>
                                        <Grid item xs={4}>
                                            <Field id="standard-input" label="Credit Note Ticket" name="creditNoteTicket"
                                                component={renderTextField}
                                                className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} ${classes.forms.formMargin}  ${classes.forms.fullFormWidth}`}
                                                InputLabelProps={{shrink: true,}}
                                                onChange={(e) => handleCorrelative(e, 'B', "creditNoteTicketCorrelative")}
                                                InputProps={{
                                                    startAdornment: <InputAdornment
                                                        position="start">B-</InputAdornment>,
                                                    inputProps: {maxLength: 3}
                                                }}
                                                normalize={serialNormalize}
                                            />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Field id="standard-input" name="creditNoteTicketCorrelative"
                                                   label="Correlative Number" type="number" component={renderTextField}
                                                   className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} ${classes.forms.formMargin} ${classes.forms.fullFormWidth}`}
                                                   InputLabelProps={{shrink: true,}} normalize={padLeftZeros}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container item spacing={2}>
                                        <Grid item xs={4}>
                                            <Field id="standard-input" label="Invoice" name="invoice"
                                                   className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} ${classes.forms.formMargin} ${classes.forms.fullFormWidth}`}
                                                   InputLabelProps={{shrink: true,}}
                                                   onChange={(e) => handleCorrelative(e, 'F', "invoiceCorrelative")}
                                                   component={renderTextField}
                                                   InputProps={{
                                                       startAdornment: <InputAdornment
                                                           position="start">F-</InputAdornment>,
                                                       inputProps: {maxLength: 3}
                                                   }}
                                                   normalize={serialNormalize}
                                            />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Field id="standard-input" name="invoiceCorrelative" label="Correlative Number"
                                                   type="number" component={renderTextField}
                                                   className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} ${classes.forms.formMargin} ${classes.forms.fullFormWidth}`}
                                                   InputLabelProps={{shrink: true,}} normalize={padLeftZeros}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container item spacing={2}>
                                        <Grid item xs={4}>
                                            <Field id="standard-input" label="Credit Note Invoice"
                                                name="creditNoteInvoice" component={renderTextField}
                                                className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} ${classes.forms.formMargin} ${classes.forms.fullFormWidth}`}
                                                InputLabelProps={{shrink: true,}}
                                                onChange={(e) => handleCorrelative(e, 'F', "creditNoteInvoiceCorrelative")}
                                                InputProps={{
                                                    startAdornment: <InputAdornment
                                                        position="start">F-</InputAdornment>,
                                                    inputProps: {maxLength: 3}
                                                }}
                                                normalize={serialNormalize}
                                            />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Field id="standard-input" name="creditNoteInvoiceCorrelative"
                                                label="Correlative Number" type="number" component={renderTextField}
                                                className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} ${classes.forms.formMargin} ${classes.forms.fullFormWidth}`}
                                                InputLabelProps={{shrink: true,}}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button type="submit" variant="contained" color="primary"
                                            style={{margin: '0 0.8em 0 0', width: '100px'}}
                                            disabled={!valid || pristine || submitting}
                                    >Save</Button>
                                    <Button type="button" variant="outlined" color="primary"
                                            style={{margin: '0 0.8em 0 0', width: '100px'}}
                                            onClick={handleCancel}
                                    >Cancel</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
};

SeriesAndCorrelativesForm = reduxForm({
    form: 'serial',
    onSubmit: submitSerialForm,
    validate
})(SeriesAndCorrelativesForm);

const selector = formValueSelector('serial');
const mapStateToProps = state => {

    const {isEdit, location} = selector(state, 'isEdit', 'location');
    return {
        locationList: areasSelector(state, location, isEdit),
        serialList: getSerial(state),
        isEdit
    }
};


export default connect(mapStateToProps)(SeriesAndCorrelativesForm)