import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  /*new*/
  familyPoppins: {
    fontFamily: 'Poppins , sans-serif!important'
  },
  /*---*/
    textCapitalize: {
      textTransform: 'capitalize!important'
    },
    
    fontBold:{
      fontWeight:'bold!important'
    },
    pageTitles:{
        fontSize:"2em",
        fontWeight:"normal",
        marginTop:"8px"
    },
    pageSubTitles:{
      fontWeight:"400",
        fontSize:"1.2rem"
    },
    dashboardBlockTitle:{
        textAlign: "left",
        fontSize: 22,
        color: '#7a7a7a'
    },
    loginErrorMessage:{
        fontSize:"0.9em",
        color:"red",
        textAlign:"center"
    }
}));