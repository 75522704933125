import Classes from "../../../../styles";
import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {handleViewPaymentPanel} from "../../../common/actions/actions";
import {Box, Button, Grid, TextField, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import InfoList from "../utils/InfoList";
import {Field, getFormInitialValues, reduxForm} from "redux-form";
import {validate} from "../../forms/submit/blockAmount.submit";
import {submitBlockAmountForm} from "../../forms/submit/blockAmount.submit";
import {fieldBlockAmount} from "../../../common/components/utils/ReduxFromMaterialUI";


let BlockAmountForm = (props) => {
    const classes = Classes();
    const [eventActive, setEventActive] = useState(false);
    const [resetValue, setResetValue] = useState(false);
    const dispatch = useDispatch();
    const {handleSubmit, pristine, reset, submitting, blockAmount, initialValues} = props;
    const handleCancel = () => {
        reset();
        props.history.goBack();
    };

    const cancelResetValue = () => {
        setResetValue(false)
    };

    useEffect(() => {
        if (initialValues !== undefined) {
            if (initialValues.blockAmount > 0) {
                setEventActive(true)
            } else {
                setEventActive(false)
            }
        }

        if (initialValues === undefined) {
            handleCancel()
        }
        dispatch(handleViewPaymentPanel(false));
    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h1" className={classes.typography.pageTitles}>
                    <Box display="inline" color="text.secondary">Settings /</Box>
                    <Link to={`../general`}
                          className={`general-nav- ${classes.nav.link}`}>
                        <Box display="inline" color="text.secondary">
                            &nbsp;General /
                        </Box>
                    </Link>
                    &nbsp;Block amount
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} sm={2}>
                                    <InfoList title={"Block Amount"} description={""}/>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Field
                                        id="block-amount"
                                        label="Block amount"
                                        name="blockAmount"
                                        type="text"
                                        component={fieldBlockAmount}
                                        resetValue={resetValue}
                                        cancelResetValue={cancelResetValue}
                                        className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} `}
                                        style={{width: '10em', marginRight: '0.15em'}}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} sm={2}/>
                                <Grid item xs={12} sm={10}>
                                    <Button type="submit" variant="contained" color="primary"
                                            style={{margin: '0 0.8em 0 0', width: '100px'}}
                                            disabled={pristine || submitting}
                                    >Save</Button>
                                    <Button variant="outlined" color="primary"
                                            style={{margin: '0 0.8em 0 0', width: '100px'}}
                                            onClick={handleCancel}
                                    >Cancel</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    )
}

BlockAmountForm = reduxForm({
    form: 'block-amount',
    onSubmit: submitBlockAmountForm,
    validate
})(BlockAmountForm);

BlockAmountForm = connect(
    state => {
        return {
            blockAmount: state.companyReducer,
            initialValues: getFormInitialValues('block-amount')(state),
        }
    }
)(BlockAmountForm);


export default BlockAmountForm