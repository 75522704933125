import React, {useState} from 'react';
// MATERIAL UI
import {
    TextField,
    Box,Grid
} from '@material-ui/core';

// STYLES
import Classes from '../../../../styles'
import {setClient, setValidateExternal} from "../../redux/actions";
import {useDispatch} from "react-redux";

const ExternalSection = () => {
    const classes = Classes();
    const dispatch = useDispatch();
    const [state, setState] = useState({
        name: "",
        documentNumber: "",
        error: false
    })

    function handleChange(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value,
        });

    }

    function handleChangeDocument(evt) {
        const value = evt.target.value;

        setState({
            ...state,
            [evt.target.name]: value,
            error: value.length === 8 || value === "" ? false : true,
        });
        
    }

    const handleValidateExternal = (value) => {  
        if(value) {
            dispatch(setValidateExternal(false))
            dispatch(setClient(state, "External"));
        }else{ 
            dispatch(setValidateExternal(true))
        }
        
    }

    const handleSaveExternal = () => {
        let errorName = state.documentNumber !== "" && state.name === "";
        let errorDocument = state.error || (state.name !== "" && state.documentNumber === "");
        handleValidateExternal(!errorName && !errorDocument)
    };

    return (
        <Grid container>
            <Grid item xs={1} />
            <Grid item xs={11}>
                <Box display="flex" alignItems="center"  justifyContent="center" flexDirection="column">
                    <form>
                        <div style={{marginBottom: '0.5em'}}>
                            <TextField size="small"
                                error={state.documentNumber !== "" && state.name === ""}
                                id="standard-basic"
                                type="text"
                                label="Name"
                                className={`${classes.forms.textField} ${classes.forms.inputExternal}`}
                                name="name"
                                helperText={(state.documentNumber !== "" && state.name === "" ) && "required field"}
                                onChange={handleChange}
                                onKeyUp={handleSaveExternal}
                            />
                        </div>
                        <TextField size="small"
                            error={state.error || (state.name !== "" && state.documentNumber === "")}
                            id="standard-basic"
                            label="Document number"
                            type="number"
                            name="documentNumber"
                            helperText={(state.error || (state.name !== "" && state.documentNumber === "") ) && "required field(8 charapters)" }
                            className={`${classes.forms.textField} ${classes.forms.inputExternal}`}
                            onChange={handleChangeDocument}
                            onKeyUp={handleSaveExternal}
                        />
                    </form>
                </Box>
            </Grid>
        </Grid>
       
    );
}
export default ExternalSection
