import React from "react";
import {connect, useDispatch} from 'react-redux';
import {handleAlerts} from '../../actions/actions'

import {makeStyles} from '@material-ui/core/styles';
import {Button, Dialog, IconButton, Snackbar, Typography} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SuccessIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import AlertIcon from '@material-ui/icons/ErrorOutlineOutlined';
import ErrorIcon from '@material-ui/icons/HighlightOffOutlined';

const useStyles = makeStyles((theme) => ({
    message: {
        top: 0,
        left: 0,
        right: 0,
        transform: 'translateY(500%)',
        '& .MuiSnackbarContent-action': {
            marginLeft: 0,
            marginRight: 0,
            paddingLeft: 0
        },
        "&.success": {
            '& .MuiSnackbarContent-root ': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main
            },

        },
        "&.warning": {
            '& .MuiSnackbarContent-root ': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.warning.main
            },
        },
        "&.error": {
            '& .MuiSnackbarContent-root ': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.error.main
            },

        },
    },
    dialog: {
        '& .MuiBackdrop-root': {
            backgroundColor: theme.palette.common['rgba-white']
        }
    },
    btnWarning: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.warning.main,
        marginRight: 0
    },
    containerTitle: {
        marginRight: 250,
        marginLeft: 10
    },
    titleStatus: {
        fontWeight: "bold",
        textTransform: "capitalize"
    },
    sizeIcon: {
        fontSize: 50
    }
}));

const Alert = ({status, message, flag}) => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const [state, setState] = React.useState({
        vertical: 'top',
        horizontal: 'center',
    });

    const {vertical, horizontal} = state;

    const handleClose = () => {
        dispatch(handleAlerts('close', 'close alert', false))
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={flag}
                className={classes.dialog}>
            {
                status !== "close" ?
                    <Snackbar className={`${classes.message} ${status}`}
                              anchorOrigin={{vertical, horizontal}}
                              autoHideDuration={status == 'success' ? 2100 : null}
                              open={flag}
                              onClose={handleClose}
                              key={vertical + horizontal}
                              action={
                                  <React.Fragment>
                                      {status === 'success' && <SuccessIcon className={classes.sizeIcon}/>}
                                      {status === 'warning' && <AlertIcon className={classes.sizeIcon}/>}
                                      {status === 'error' && <ErrorIcon className={classes.sizeIcon}/>}
                                      <span className={classes.containerTitle}>
                <Typography variant="h6" className={classes.titleStatus}>
                  {status}
                </Typography> 
                <Typography variant="subtitle2">{message}</Typography>
              </span>
                                      {
                                          status == 'warning' ?
                                              <Button className={classes.btnWarning} size="medium"
                                                      onClick={handleClose}>
                                                  OK
                                              </Button>
                                              :
                                              <IconButton size="small" aria-label="close" color="inherit"
                                                          onClick={handleClose}>
                                                  <CloseIcon fontSize="small"/>
                                              </IconButton>
                                      }
                                  </React.Fragment>
                              }/>
                    : null
            }
        </Dialog>
    );
}

const mapStateToProps = state => ({
    message: state.messages.msg,
    flag: state.messages.open,
    status: state.messages.status
})

export default connect(mapStateToProps)(Alert)
