import React from 'react';
// MATERIAL UI
import {Box, Button} from '@material-ui/core';
// CSS
import Classes from '../../../../../styles'


const Category = ({name, selected,setCategory, idCategory, filterSearchMultiple}) => {
    const classes = Classes();

    const selectCategory = () =>{
        filterSearchMultiple("")
        setCategory(idCategory)

    }

    return (
        <Button variant="contained" color="primary" fullWidth
                className={`${classes.buttons.categoryButton} ${classes.styles.bRadius18} 
                ${classes.typography.textCapitalize} ${selected ? "selected":""}`}
                style={{marginBottom: '0.8em'}}
                onClick={selectCategory}
                >
            <Box fontSize={'1.1em'} alignItems="center"
            >{name}</Box>
        </Button>

    )
};

export default Category;
