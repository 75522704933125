import React, {useEffect, useState} from 'react';
import {connect,  useDispatch} from "react-redux";
import {toggleReceiptDetails} from "../../actions/sync.js"

import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Divider, Grid, IconButton} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Classes from '../../../../styles'
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import {downloadDocumentPDF} from "../../../pos/redux/asyncActions";
import PdfIcon from "../../../../assets/icons/pdf-file.svg";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div role="tabpanel" hidden={value !== index} 
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`} {...other}>
            {value === index && (
                <Box pt={2}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index, disabled) {
    const mainProps = {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
    if (disabled) {
        mainProps["disabled"] = disabled
    }
    return mainProps
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#fafafa',
        },
        
    },
    tab: {
        '& .MuiTabs-indicator': {
            backgroundColor: '#ea3788'
        }

    },
    tabContainer : {
        "& .MuiTabs-fixed" : {
            height: 38
        },
        "& .MuiTab-root": {
            padding: 0,
            minHeight: 0
        }
    },
    arrowIcon:{
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
    }

}));

const detailColumns = {
    "quantity": "Qty.",
    "description": "Desc.",
    "amount": "Amount"
};

const ClientHistoryTabs = ({selectedDocuments, billingDetails}) => {
    const useclasses = useStyles();
    const classes = Classes();
    const dispatch = useDispatch();  
    const [value, setValue] = React.useState(0);
    const [documentsSelected, setDocumentsSelected] = useState({});


    useEffect(() => {
        if (selectedDocuments) {
            setDocumentsSelected(selectedDocuments);
        }
    }, [selectedDocuments]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        documentsSelected.length > 0 ?
        <div className={useclasses.root} >
            <ArrowBackOutlinedIcon className={`${useclasses.arrowIcon}`}  style={{float:'right',fontSize:20, margin: 10}} onClick={()=> dispatch(toggleReceiptDetails(false))}/>
            <br/>
            <Tabs value={value} onChange={handleChange} style={{marginLeft: 20}}
            className={useclasses.tabContainer} 
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example">
                {
                    Object.values(documentsSelected).map((selectDoc, index) => {
                            return selectDoc.notes.length === 0 ?
                                <Tab key={index + 1} label={selectDoc.documentType} {...a11yProps(index)} />
                                :
                                selectDoc.notes.map((note, index) =>
                                    <Tab label={note.documentType} {...a11yProps(index)}/>
                                )
                        }
                    )
                }
            </Tabs>
            {
                Object.values(documentsSelected).map((selectDoc, index) =>
                    <TabPanel key={index + 1} value={value} index={index}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                {
                                    selectDoc.notes.length === 0 ?
                                        <Box  display="flex"  style={{padding: "0 12px"}} >
                                            <Box display="flex" flexDirection="column" mr={1} flexGrow={1}>
                                                <Typography variant="subtitle2" color="textSecondary"> Correlative: </Typography>
                                            </Box>
                                            <Box display="flex" flexDirection="row" alignItems="center">
                                                <Typography variant="subtitle2" >{selectDoc.correlative}</Typography>
                                                <IconButton onClick={
                                                    (event) =>{
                                                        dispatch(downloadDocumentPDF(selectDoc.correlative))
                                                    }
                                                }>
                                                    <img src={PdfIcon} style={{width: '24px', height: '24px'}} alt=""/>
                                                </IconButton>
                                            </Box>
                                        </Box>
                                        :
                                        selectDoc.notes.map((note, index) =>
                                            <Box display="flex"  style={{padding: "0 12px"}} key={index +1}>
                                                <Box display="flex" flexDirection="column" mr={1} flexGrow={1} >
                                                    <Typography variant="subtitle2"  color="textSecondary"> Note correlative:</Typography>
                                                    <Typography variant="subtitle2"  color="textSecondary"> Document correlative:</Typography>
                                                </Box>
                                                <Box display="flex" flexDirection="row" alignItems="center">
                                                    <Typography variant="subtitle2" >{selectDoc.correlative}</Typography>
                                                    <Typography variant="subtitle2" >{note.correlative}</Typography>
                                                    <IconButton onClick={
                                                        (event) =>{
                                                            dispatch(downloadDocumentPDF(note.correlative))
                                                        }
                                                    }>
                                                        <img src={PdfIcon} style={{width: '24px', height: '24px'}} alt=""/>
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        )
                                }<br/>
                                <Divider style={{backgroundColor: "rgb(179, 236, 234)"}}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Box  style={{padding: "0 12px"}}  >
                                    <Typography variant="subtitle2" color="textSecondary" style={{marginBottom: 10}}>Items:</Typography>
                                    <TableContainer style={{maxHeight: 150}}  >
                                        <Table  stickyHeader aria-label="simple table" size="small" className={classes.tables.table}>
                                            <TableHead  >
                                                <TableRow>
                                                    {Object.values(detailColumns).map((column, index) => (
                                                        <TableCell key={index + 1} style={{border: "none"}}>
                                                            {column}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {selectDoc.details.map((column, index) => (
                                                    <TableRow key={index + 1}>
                                                        <TableCell style={{backgroundColor: "#fafafa", border: "none"}}>{column.quantity}</TableCell>
                                                        <TableCell style={{backgroundColor: "#fafafa", border: "none"}}>{column.item}</TableCell>
                                                        <TableCell style={{backgroundColor: "#fafafa", border: "none", textAlign:"right"}}>{column.amount.toFixed(2)}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <br/>
                                <Divider style={{backgroundColor: "rgb(179, 236, 234)"}}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" style={{width: 250 , float: "right" , padding: "0 12px"}}  >
                                    <Box display="flex" flexDirection="column" mr={4} flexGrow={1} >
                                        <Typography variant="subtitle2" color="textPrimary" > Subtotal</Typography>
                                        <Typography variant="subtitle2"  color="textPrimary" >Tax </Typography>
                                        <Typography variant="subtitle2"  color="textPrimary" >Service charge </Typography>
                                        <Typography variant="subtitle2"  color="textPrimary" >Discount</Typography>
                                        <Typography variant="subtitle2"  color="textPrimary" ><strong>Total</strong></Typography>
                                    </Box>
                                    <Box display="flex" flexDirection="column" >
                                        <Typography variant="subtitle2"  style={{textAlign: "right"}} >{selectDoc.subTotal.toFixed(2)}</Typography>
                                        <Typography variant="subtitle2"  style={{textAlign: "right"}} >{selectDoc.tax.toFixed(2)}</Typography>
                                        <Typography variant="subtitle2"  style={{textAlign: "right"}} >{selectDoc.serviceCharge.toFixed(2)}</Typography>
                                        <Typography variant="subtitle2"  style={{textAlign: "right"}} >{selectDoc.discount.toFixed(2)}</Typography>
                                        <Typography variant="subtitle2"  style={{textAlign: "right"}} ><strong>{selectDoc.total.toFixed(2)}</strong></Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </TabPanel>
                )
            }
        </div>
        : <Box style={billingDetails.length > 3 ? {height: "30em"} : {height: "25em"}} 
        display="flex" alignItems="center" justifyContent="center"  flexDirection="column">
            <ReceiptIcon style={{marginBottom: '0.2em', opacity: 0.2 , fontSize: 50}} />
            <Typography variant="subtitle1" style={{color: '#9e9e9e'}} >
                Select a row for detail view
            </Typography>
        </Box>
        
    );
};

const mapStateToProps = (state) => {
    return {
        selectedDocuments: state.clientHistoryReducer.selectedDocuments.selectedDocuments,
        billingDetails: state.clientHistoryReducer.clientHistory.billingDetails,
    }
};

export default connect(mapStateToProps,)(ClientHistoryTabs);