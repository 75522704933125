export const PRODUCTS_URL = 'api/pos/products';
export const CATEGORIES_URL = 'api/pos/category/';
export const GET_SALES_BY_BALANCE_URL = 'api/pos/sales_by_balance';
export const OPEN_BALANCE_URL = 'api/balance/open-balance';
export const GET_EXISTING_BALANCE_URL = 'api/pos/retrieve-open-balances';

export const GET_SALES_HISTORY_URL = 'api/pos/sale-history';
export const GET_CONSUMPTIONS_HISTORY_URL = 'api/pos/sale-history';
export const GET_BALANCE_STATUS_URL = 'api/pos/sale-balance-status';


export const CLOSE_SHIFT_URL = 'api/balance/close-balance';
export const REPORT_CASH_BALANCE = 'api/balance/balance-report';
export const GET_CLOSED_BALANCE_URL = 'api/balance/state-balance';
export const CASH_REPORT_URL = 'api/balance/balance-by-date';

export const GET_PAYMENT_TYPES_URL = ''
export const GET_GUESTS_URL = ''
export const GET_CONSUMPTIONS_URL = ''
export const GET_PAYMENTS_URL = ''
export const POST_PAYMENT_URL = ''

export const POS_PAYMENT_URL = 'api/payments/pos';
export const BILLING_PAYMENT_URL = 'api/payments/rumi';
export const ROOMS_URL = 'api/pms/rooms';
export const GUEST_URL = 'api/pms/clients';

export const BALANCE_FLOWS_REPORT_URL = 'api/balance/current-balance-status-report';
export const GUEST_FLOWS_REPORT_URL = 'api/billing/guest-flows-report';
export const SALES_REPORT_URL = 'api/pos/sales-report';
export const RANKING_REPORT_URL = 'api/pos/sales-ranking';
export const CASH_REPORT_POS = 'api/balance/SendToAdminPRS';
export const CASH_REPORT_RUMI = 'api/balance/send_to_admin_RUMI';
export const TICKETS_REPORT_URL = 'api/billing/tickets-report';
export const INVOICES_REPORT_URL = 'api/billing/invoices-report';
export const CREDIT_NOTES_REPORT_URL = 'api/billing/credit-notes-report';

export const CANCEL_SALE_URL = 'api/note/generate-credit-note/'

export const DOWNLOAD_RECEIPT_PDF = 'api/payments/retrieve-documents-pdf'

export const RESERVATIONS_ALL_TIME = 'api/pms/reservations-all-time';
export const PRODUCT_PRICES_FILE = 'api/pos/products-prices-file';
export const UPLOAD_PRODUCT_PRICES_FILE = 'api/pos/upload-products-prices-file';

export const RESERVATIONS_ALL_TIME_DETAILED_RATES = 'api/pms/reservations-all-time-detailed-rates';