import React from 'react';

// MATERIAL UI 
import {FormControl, FormHelperText, FormLabel} from '@material-ui/core/';

// CSS 
import { makeStyles } from '@material-ui/core/styles';
import Classes from '../../../../styles';


const useStyles = makeStyles((theme) =>({
    formLabel:{
        fontSize:'1em',
        fontWeight: '2em',
        color: theme.palette.common.black,
    },

}));


const InfoList = (props) => {
    const useclasses = useStyles();
    const classes = Classes();

    return (
        <FormControl fullWidth style={{paddingRight: 10}}>
            <FormLabel component="legend"  className={`${useclasses.formLabel} `} style={{paddingBottom: '0.7em'}}>
                {props.title}
            </FormLabel>
            <FormHelperText style={{color: '#707070'}} className={classes.typography.familyPoppins}>
                {props.description}
            </FormHelperText>
        </FormControl>
    )
}
export default InfoList