import React, {Fragment} from 'react';
import {useDispatch} from "react-redux";
import {handleDynamicDialog} from "../../../common/actions/actions"
import {removeConsumption} from "../../actions/async";

import PropTypes from 'prop-types';
import {makeStyles} from "@material-ui/core/styles";
import {
    Checkbox,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    Hidden,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import {buttons, main, responsive, tables} from '../../../../styles'
import Paper from '@material-ui/core/Paper';
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import NullMessage from '../../../common/components/error.pages/NullMessage'
import DynamicDialog from "../../../common/components/modals/DynamicDialog"
// import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        minWidth: '100%',
        [theme.breakpoints.up('md')]: {
            height: 320,
        },
        [theme.breakpoints.down('sm')]: {
            height: 300,
        },
    }
}))

function EnhancedTableHead(props) {
    const {classes, onSelectAllClick, numSelected, rowCount, rowsWithQuantity, alloweCheckbox, deleteAllowed} = props;
    let checked;
    let indeterminate;
    if (rowsWithQuantity.length > 0) {
        checked = rowCount > 0 && numSelected === rowCount;
        indeterminate = numSelected > 0 && numSelected < rowCount
    } else {
        checked = false;
        indeterminate = numSelected > 0 && numSelected < rowCount
    }
    return (
        <Fragment>
            <Hidden smDown>
                <TableHead>
                    <TableRow>
                        {alloweCheckbox &&
                            <TableCell padding="checkbox" align="center" className={classes.tables.tableCell}>
                                <Checkbox
                                    indeterminate={indeterminate}
                                    checked={checked}
                                    onChange={onSelectAllClick}
                                    inputProps={{'aria-label': 'select all'}}
                                />
                            </TableCell>
                        }
                        <TableCell align="center" className={classes.tables.tableCell}>Item</TableCell>
                        <TableCell align="center" className={classes.tables.tableCell}>Date</TableCell>
                        <TableCell align="center" className={classes.tables.tableCell}>User</TableCell>
                        <TableCell align="center" className={classes.tables.tableCell}>Origin</TableCell>
                        <TableCell align="right" className={classes.tables.tableCell}>Price</TableCell>
                        <TableCell align="center" className={classes.tables.tableCell}>Quantity</TableCell>
                        <TableCell align="right" className={classes.tables.tableCell}>Amount</TableCell>
                        {deleteAllowed &&
                            <TableCell align="right" className={classes.tables.tableCell}>Delete</TableCell>
                        }
                    </TableRow>
                </TableHead>
            </Hidden>
            <Hidden mdUp>
                <div>
                    <Checkbox
                        indeterminate={indeterminate}
                        checked={checked}
                        onChange={onSelectAllClick}
                        inputProps={{'aria-label': 'select all'}}
                    /> Select all items
                </div>
            </Hidden>
        </Fragment>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
};


export default function EnhancedTable(props) {
    const styles = useStyles()
    const classes = {
        main: main(),
        tables: tables(),
        buttons: buttons(),
        responsive: responsive()
    }
    const dispatch = useDispatch();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    // const [toggleAccordion, setToggleAccordion] = React.useState(false);
    // const [keyAccordion, setKeyAccordion] = React.useState(null);

    const rows = Object.values(props.data);
    let slicedRows = rows;
    const selectedrowsIds = rows.filter(item => item.quantity > 0).map(row => row.id);
    const [selected, setSelected] = React.useState(selectedrowsIds);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.id);
            rows.forEach(function (row, index) {
                props.setQuantity(row.id, 1);
            });
            setSelected(newSelecteds);
            return;
        }
        rows.forEach(function (row, index) {
            props.setQuantity(row.id, 0);
        });
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        props.setQuantity(id, newSelected.includes(id) ? 1 : 0);
        setSelected(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const cancelChargedProduct = (detail) => {
        dispatch(removeConsumption(detail.id))
    };

    // const handleToggleAccordion = (id,value) => {
    //     setKeyAccordion(id)
    //     setToggleAccordion(value)
    // }

    return (
        <div>
            <Hidden mdUp>
                {
                    props.checkboxAllowed &&
                    <EnhancedTableHead
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        rowsWithQuantity={slicedRows.filter(item => item.quantity > 0)}
                        alloweCheckbox={props.checkboxAllowed}
                        deleteAllowed={props.allowDelete}
                    />
                }
            </Hidden>
            <Paper elevation={0} style={{backgroundColor: '#fafafa'}} className={`${classes.main.shadow1} `}>
                <TableContainer className={styles.tableContainer}>
                    <Hidden smDown>
                        <Table stickyHeader aria-label="sticky table" className={`${classes.tables.table} grey-table`}>
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                                rowsWithQuantity={slicedRows.filter(item => item.quantity > 0)}
                                alloweCheckbox={props.checkboxAllowed}
                                deleteAllowed={props.allowDelete}
                            />
                            <TableBody>
                                {
                                    slicedRows.length ?
                                        slicedRows.map((row, index) => {
                                            const isItemSelected = isSelected(row.id);
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                props.checkboxAllowed ?
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        aria-checked={isItemSelected && row.quantity > 0}
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        selected={isItemSelected && row.quantity > 0}>
                                                        <TableCell padding="checkbox"
                                                                   className={`${classes.tables.cell + ' sm-noneBorder'} `}
                                                        >
                                                            <Checkbox
                                                                checked={isItemSelected && row.quantity > 0}
                                                                inputProps={{'aria-labelledby': labelId}}
                                                                onChange={(event) => handleClick(event, row.id)}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${classes.tables.cell + ' sm-noneBorder'} `}>
                                                            {row.description}
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${classes.tables.cell + ' sm-noneBorder'}`}>
                                                            {row.createdDate}
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${classes.tables.cell + ' sm-noneBorder'}`}>
                                                            {row.user}
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${classes.tables.cell + ' sm-noneBorder'}`}>
                                                            {row.origin}
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${classes.tables.cell + ' sm-noneBorder'} align-right`}>
                                                            {(row.price.total).toFixed(2)}
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${classes.tables.cell + ' sm-noneBorder'} align-right `}>
                                                            {row.quantity}
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${classes.tables.cell + ' sm-noneBorder'} align-right `}>
                                                            {(row.quantity * row.price.total).toFixed(2)}
                                                        </TableCell>
                                                    </TableRow>
                                                    : <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        className={`${classes.main.animations} ${props.selectedAncillaryResponse === row.id && props.animation + " active"}`}>
                                                        <TableCell className={`${classes.tables.cell} `}>
                                                            {row.description}
                                                        </TableCell>
                                                        <TableCell className={`${classes.tables.cell + ' sm-noneBorder'} `}>
                                                            {row.createdDate}
                                                        </TableCell>
                                                        <TableCell className={`${classes.tables.cell + ' sm-noneBorder'} `}>
                                                            {row.user}
                                                        </TableCell>
                                                        <TableCell className={`${classes.tables.cell + ' sm-noneBorder'} `}>
                                                            {row.origin}
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${classes.tables.cell + ' sm-noneBorder'} align-right`}>
                                                            {(row.price.total).toFixed(2)}</TableCell>
                                                        <TableCell
                                                            className={`${classes.tables.cell + ' sm-noneBorder'} align-right`}>
                                                            {row.quantity}
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${classes.tables.cell + ' sm-noneBorder'} align-right`}>
                                                            {(row.quantity * row.price.total).toFixed(2)}
                                                        </TableCell>
                                                        {row.paymentType && row.paymentType === "CHRG" ?
                                                            <TableCell className={`${classes.tables.cell} align-right`}>
                                                                <IconButton variant="contained"
                                                                            className={`${classes.buttons.actionButton} delete`}
                                                                            onClick={() => dispatch(handleDynamicDialog("ancillary", row))}
                                                                >
                                                                    <DeleteIcon/>
                                                                </IconButton>
                                                            </TableCell> :
                                                            <TableCell className={`${classes.tables.cell} align-right`}/>
                                                        }
                                                    </TableRow>
                                            );
                                        }) :
                                        <TableRow tabIndex={-1}>
                                            <TableCell align="center" colSpan={9}>
                                                <NullMessage message={"There are no consumptions"} height={"15em"}/>
                                            </TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </Hidden>
                    <Hidden mdUp>
                        {slicedRows.length ?
                            slicedRows.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    props.checkboxAllowed ?
                                        <ExpansionPanel key={index + 1} style={{backgroundColor: '#fafafa', margin: 0}}>
                                            <Grid container item>
                                                <Grid item xs={2} sm={2}>
                                                    <Checkbox
                                                        checked={isItemSelected && row.quantity > 0}
                                                        inputProps={{'aria-labelledby': labelId}}
                                                        onChange={(event) => handleClick(event, row.id)}
                                                    /> </Grid>
                                                <Grid item xs={10} sm={10}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={<ExpandMoreIcon/>}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header">

                                                        <Grid container item>
                                                            <Grid item xs={12} sm={4}>
                                                                <Typography variant="caption"
                                                                            style={{fontSize: 10, textAlign: 'center'}}
                                                                            display="block"
                                                                            color="textSecondary">Item</Typography>
                                                                <Typography variant="body2"
                                                                            style={{textAlign: 'center'}}
                                                                            display="block">{row.description}</Typography></Grid>
                                                            <Hidden only="xs">
                                                                <Grid item sm={3}>
                                                                    <Typography variant="caption" style={{
                                                                        fontSize: 10,
                                                                        textAlign: 'center'
                                                                    }} display="block"
                                                                                color="textSecondary">Date</Typography>
                                                                    <Typography variant="body2"
                                                                                style={{textAlign: 'center'}}
                                                                                display="block">{row.createdDate}</Typography>
                                                                </Grid>
                                                                <Grid item sm={3}>
                                                                    <Typography variant="caption" style={{
                                                                        fontSize: 10,
                                                                        textAlign: 'center'
                                                                    }} display="block"
                                                                                color="textSecondary">Origin</Typography>
                                                                    <Typography variant="body2"
                                                                                style={{textAlign: 'center'}}
                                                                                display="block">{row.origin}</Typography>
                                                                </Grid>
                                                                <Grid item sm={3}>
                                                                    <Typography variant="caption" style={{
                                                                        fontSize: 10,
                                                                        textAlign: 'center'
                                                                    }} display="block"
                                                                                color="textSecondary">User</Typography>
                                                                    <Typography variant="body2"
                                                                                style={{textAlign: 'center'}}
                                                                                display="block">{row.user}</Typography>
                                                                </Grid>
                                                            </Hidden>
                                                        </Grid>
                                                    </ExpansionPanelSummary>
                                                </Grid>
                                            </Grid>
                                            <ExpansionPanelDetails style={{
                                                borderTop: `2px solid rgba(0,0,0,0.14)`,
                                                borderBottom: `2px solid rgba(0,0,0,0.14)`
                                            }}>
                                                <Grid container spacing={2}
                                                      direction="row"
                                                      justify="space-between"
                                                      alignItems="center">
                                                    <Hidden smUp>
                                                        <Grid container item spacing={2}>
                                                            <Grid item xs={6} sm={4}
                                                                  style={{textAlign: "left", color: '#9E9E9E'}}>
                                                                Date</Grid>
                                                            <Grid item xs={6} sm={8} style={{textAlign: "right"}}>
                                                                {row.createdDate}</Grid>
                                                        </Grid>
                                                    </Hidden>
                                                    <Hidden smUp>
                                                        <Grid container item spacing={2}>
                                                            <Grid item xs={6} sm={4}
                                                                  style={{textAlign: "left", color: '#9E9E9E'}}>
                                                                Origin</Grid>
                                                            <Grid item xs={6} sm={8} style={{textAlign: "right"}}>
                                                                {row.origin}</Grid>
                                                        </Grid>
                                                    </Hidden>
                                                    <Hidden smUp>
                                                        <Grid container item spacing={2}>
                                                            <Grid item xs={6} sm={4}
                                                                  style={{textAlign: "left", color: '#9E9E9E'}}>
                                                                User</Grid>
                                                            <Grid item xs={6} sm={8} style={{textAlign: "right"}}>
                                                                {row.user}</Grid>
                                                        </Grid>
                                                    </Hidden>
                                                    <Grid container item spacing={2}>
                                                        <Grid item xs={6} sm={4}
                                                              style={{textAlign: "left", color: '#9E9E9E'}}>
                                                            Price</Grid>
                                                        <Grid item xs={6} sm={8} style={{textAlign: "right"}}>
                                                            {(row.price.total).toFixed(2)} </Grid>
                                                    </Grid>
                                                    <Grid container item spacing={2}>
                                                        <Grid item xs={6} sm={4}
                                                              style={{textAlign: "left", color: '#9E9E9E'}}>
                                                            Quantity</Grid>
                                                        <Grid item xs={6} sm={8} style={{textAlign: "right"}}>
                                                            {row.quantity}</Grid>
                                                    </Grid>
                                                    <Grid container item spacing={2}>
                                                        <Grid item xs={6} sm={4}
                                                              style={{textAlign: "left", color: '#9E9E9E'}}>
                                                            Amount</Grid>
                                                        <Grid item xs={6} sm={8} style={{textAlign: "right"}}>
                                                            {(row.quantity * row.price.total).toFixed(2)}</Grid>
                                                    </Grid>
                                                </Grid>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel> :
                                        <ExpansionPanel key={index + 1} style={{backgroundColor: '#fafafa', margin: 0}}>
                                            <ExpansionPanelSummary
                                                expandIcon={<ExpandMoreIcon/>}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header">
                                                <Grid container item>
                                                    <Grid item xs={6} sm={4}>
                                                        <Typography variant="caption"
                                                                    style={{fontSize: 10, textAlign: 'center'}}
                                                                    display="block"
                                                                    color="textSecondary">Item</Typography>
                                                        <Typography variant="body2" style={{textAlign: 'center'}}
                                                                    display="block">{row.description}</Typography></Grid>
                                                    <Grid item xs={6} sm={4}>
                                                        <Typography variant="caption"
                                                                    style={{fontSize: 10, textAlign: 'center'}}
                                                                    display="block"
                                                                    color="textSecondary">Date</Typography>
                                                        <Typography variant="body2" style={{textAlign: 'center'}}
                                                                    display="block">{row.createdDate}</Typography></Grid>
                                                    <Hidden only="xs">
                                                        <Grid item sm={4}>
                                                            <Typography variant="caption"
                                                                        style={{fontSize: 10, textAlign: 'center'}}
                                                                        display="block"
                                                                        color="textSecondary">Amount</Typography>
                                                            <Typography variant="body2" style={{textAlign: 'center'}}
                                                                        display="block"> {(row.quantity * row.price.total).toFixed(2)}</Typography>
                                                        </Grid>
                                                    </Hidden>
                                                </Grid>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails style={{
                                                borderTop: `2px solid rgba(0,0,0,0.14)`,
                                                borderBottom: `2px solid rgba(0,0,0,0.14)`
                                            }}>
                                                <Grid container spacing={2}
                                                      direction="row"
                                                      justify="space-between"
                                                      alignItems="center">
                                                    <Grid container item spacing={2}>
                                                        <Grid item xs={6} sm={4}
                                                              style={{textAlign: "left", color: '#9E9E9E'}}>
                                                            Price</Grid>
                                                        <Grid item xs={6} sm={8} style={{textAlign: "right"}}>
                                                            {(row.price.total).toFixed(2)}</Grid>
                                                    </Grid>
                                                    <Grid container item spacing={2}>
                                                        <Grid item xs={6} sm={4}
                                                              style={{textAlign: "left", color: '#9E9E9E'}}>
                                                            Quantity</Grid>
                                                        <Grid item xs={6} sm={8} style={{textAlign: "right"}}>
                                                            {row.quantity}</Grid>
                                                    </Grid>
                                                    <Hidden smUp>
                                                        <Grid container item spacing={2}>
                                                            <Grid item xs={6} sm={4}
                                                                  style={{textAlign: "left", color: '#9E9E9E'}}>
                                                                Amount</Grid>
                                                            <Grid item xs={6} sm={8} style={{textAlign: "right"}}>
                                                                {(row.quantity * row.price.total).toFixed(2)}</Grid>
                                                        </Grid>
                                                    </Hidden>
                                                    {row.paymentType && row.paymentType === "CHRG" &&
                                                        <Grid container item spacing={2}>
                                                            <Grid item xs={6} sm={4}
                                                                  style={{textAlign: "left", color: '#9E9E9E'}}>
                                                                Delete</Grid>
                                                            <Grid item xs={6} sm={8} style={{textAlign: "right"}}>
                                                                <IconButton variant="contained" style={{padding: 0}}
                                                                            className={`${classes.buttons.actionButton} delete`}
                                                                            onClick={() => dispatch(handleDynamicDialog("ancillary", row))}>
                                                                    <DeleteIcon/>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                );
                            }) :
                            <NullMessage message={"There are no consumptions"} height={"15em"}/>
                        }
                    </Hidden>
                </TableContainer>
            </Paper>
            <DynamicDialog
                title={"Are you sure?"}
                keyValue={"ancillary"}
                msg={"Are you sure that you want to delete this ancillary?"}
                type={"delete"}
                handleFunction={cancelChargedProduct}/>
        </div>
    );
}