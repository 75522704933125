import {
    LOAD_CATEGORY_LIST,
    LOAD_PRODUCT_LIST,
    SELECT_CATEGORY,
    FILTER_VISIBLE_PRODUCTS,
    PRODUCT_SELECTED,
    LOAD_OUTLET_LIST,
    UPDATE_PRODUCT_CATEGORY,
    CHANGE_PREVIEW_APP,
    CREATE_CATEGORY,
    UPDATE_CATEGORY,
    UPDATE_PREVIEW_CATEGORY, 
    SHOW_CATEGORY_FORM, 
    REMOVE_CATEGORY,
    REMOVE_PRODUCT,
    DROP_PRODUCT_LIST,
    SHOW_CREATED_CATEGORY,
    TOGGLE_CREATE_EDIT_CATEGORY,
    GET_CREATED_PRODUCT,
    HANDLE_SWITCH_CHARGE,
    HANDLE_INFO_CHARGE,
    GET_TAXES_BY_OUTLETS,
    ACTIVE_SWITCH_STORE,
    TOGGLE_SELECT_OUTLET,
    GET_EVENT_TYPE_PRODUCT,
    GET_CUSTOMIZED_CHARGES,
    ACTIVE_ALERT_DRAG_DROP
} from '../actions/actionsTypes'

import {transformToObject} from "../../common/reducerUtilities";
import {POS_ORIGIN} from "../../common/commonConstants";

const initialState = {
    showCategoryForm : false,
    categoryList: {},
    productList: {},
    productPreviewList: {},
    currentCategory: 1,
    currentPrevCategory: 1,
    previewCategorySelected: null,
    previewAppSelected: POS_ORIGIN,
    filterActive: false,
    productToEdit: {},
    outletList: [],
    selectedCategoryName: '',
    dropArrProducts: [],
    showCreatedCategory: false ,
    actionCategory: null,
    createProduct: "",
    handlerSwitchCharge: false,
    infoCharge: false,
    taxesByOutlets: [],
    activedSwitchStore: true,
    selectedOutlet: false,
    eventTypeProduct: "",
    customizedCharges: null,
    alertDragAndDrop: false,
    currentDragProduct: null
}

const categoriesSettingsReducer = (state = initialState.categoryList, action) => {
    switch (action.type) {
        case LOAD_CATEGORY_LIST:
            return transformToObject(action.categoryList)
        case CREATE_CATEGORY:
        case UPDATE_CATEGORY:
            return {...state, [action.payload.id]: action.payload};
        case REMOVE_CATEGORY:
            const {[action.categoryId]: category, ...categories} = state;
            return   categories
        default:
            return state
    }
}

const selectedCategoryReducer = (state = initialState.currentCategory, action) => {
    switch (action.type) {
        case SELECT_CATEGORY:
            return action.categoryId
        default:
            return state
    }
}

const productsSettingsReducer = (state = initialState.productList, action) => {
    switch (action.type) {
        case LOAD_PRODUCT_LIST:
            return transformToObject(action.productList)
        case  PRODUCT_SELECTED:
            return {...state, productToEdit: action.obj}
        case  FILTER_VISIBLE_PRODUCTS:
            return {...state, filterActive: action.active, filterValue: action.value}
        case UPDATE_PRODUCT_CATEGORY:
            const product =  state[action.productId]
            return {...state, [action.productId]: {...product, category: action.categoryId}  }
        case REMOVE_PRODUCT:
            const {[action.productId]: products, ...productList} = state;
            return   productList
        default:
            return state
    }
}

const productsPreviewSettingsReducer = (state = initialState.productPreviewList, action) => {
    switch (action.type) {
        case LOAD_PRODUCT_LIST:
            return transformToObject(action.productList)
        case REMOVE_PRODUCT:
            const {[action.productId]: products, ...productList} = state;
            return   productList
        default:
            return state
    }
}

const outletsSettingsReducer = (state = initialState.outletList, action) => {
    switch (action.type) {
        case LOAD_OUTLET_LIST:
            return action.outletList
        default:
            return state
    }
}

const  previewSelectedApp = (state = initialState.previewAppSelected, action) => {
    switch (action.type) {
        case CHANGE_PREVIEW_APP:
            return action.previewApp
        default:
            return state
    }
}

const previewCategorySelectedReducer = (state = initialState.previewCategorySelected, action) => {
    switch (action.type) {
        case UPDATE_PREVIEW_CATEGORY:
            return action.categoryId
        default:
            return state
    }
}

const showCategoryFormReducer = (state = initialState.showCategoryForm, action) => {
    switch (action.type) {
        case SHOW_CATEGORY_FORM:
            return action.flag
        default:
            return state
    }
}

const  selectedCategoryNameReducer = (state = initialState.categorySelected, action) => {
    switch (action.type) {
        case SELECT_CATEGORY:
            return action.name
        default:
            return state
    }
}

const  dropProductsReducer = (state = initialState.dropProductList, action) => {
    switch (action.type) {
        case DROP_PRODUCT_LIST:
            return action.dropProductList
        default:
            return state
    }
}

const  showCreatedCategoryReducer = (state = initialState.createdProduct, action) => {
    switch (action.type) {
        case SHOW_CREATED_CATEGORY:
            return action.value
        default:
            return state
    }
}

const toggleCreateEditCategoryReducer = (state = initialState.actionCategory, action) => {
    switch (action.type) {
        case TOGGLE_CREATE_EDIT_CATEGORY:
            return action.value
        default:
            return state
    }
}

const createProductReducer = (state = initialState.createProduct, action) => {
    switch (action.type) {
        case GET_CREATED_PRODUCT:
            return action.value
        default:
            return state
    }
}

const handlerSwitchChargeReducer = (state = initialState.handlerSwitchCharge, action) => {
    switch (action.type) {
        case HANDLE_SWITCH_CHARGE:
            return  action.value
            
        default:
            return state
    }
}

const infoChargeReducer = (state = initialState.infoCharge, action) => {
    switch (action.type) {
        case HANDLE_INFO_CHARGE:
            return action.value
            
        default:
            return state
    }
}

const taxesByOutletsReducer = (state = initialState.taxesByOutlets, action) => {
    switch (action.type) {
        case GET_TAXES_BY_OUTLETS:
            return action.list
        default:
            return state
    }
}

const activeSwitchReducer = (state = initialState.activeSwitchStore, action) => {
    switch (action.type) {
        case ACTIVE_SWITCH_STORE:
            return action.value
            
        default:
            return state
    }
}

const toggleSelectOutletReducer = (state = initialState.selectedOutlet, action) => {
    switch (action.type) {
        case TOGGLE_SELECT_OUTLET:
            return action.value
            
        default:
            return state
    }
}

const typeEventProductReducer = (state = initialState.eventTypeProduct, action) => {
    switch (action.type) {
        case GET_EVENT_TYPE_PRODUCT :
            return action.value
            
        default:
            return state
    }
}

const customizedChargesReducer = (state = initialState.customizedCharges, action) => {
    switch (action.type) {
        case GET_CUSTOMIZED_CHARGES :
            return action.values
            
        default:
            return state
    }
}

const alertDragAndDropReducer = (state = initialState.alertDragAndDrop, action) => {
    switch (action.type) {
        case ACTIVE_ALERT_DRAG_DROP:
            return action.value
        default:
            return state
    }
}

const currentDragProductReducer = (state = initialState.currentDragProduct, action) => {
    switch (action.type) {
        case UPDATE_PRODUCT_CATEGORY:
            return action.productId
        default:
            return state
    }
}

const storeSettings = (state = initialState, action) => {
    return {
        categoryList: categoriesSettingsReducer(state.categoryList, action),
        productList: productsSettingsReducer(state.productList, action),
        productPreviewList: productsPreviewSettingsReducer(state.productPreviewList, action),
        outletList: outletsSettingsReducer(state.outletList, action),
        currentCategory: selectedCategoryReducer(state.currentCategory, action),
        previewAppSelected: previewSelectedApp(state.previewAppSelected, action),
        previewCategorySelected: previewCategorySelectedReducer(state.previewCategorySelected, action),
        showCategoryForm: showCategoryFormReducer(state.showCategoryForm, action),
        selectedCategoryName: selectedCategoryNameReducer(state.selectedCategoryName , action),
        dropProductList: dropProductsReducer(state.dropProductList, action),
        currentPrevCategory: previewCategorySelectedReducer(state.previewCategorySelected, action),
        showCreatedCategory: showCreatedCategoryReducer(state.showCreatedCategory, action),
        actionCategory: toggleCreateEditCategoryReducer(state.actionCategory, action),
        createProduct : createProductReducer(state.createProduct, action),
        handlerSwitchCharge: handlerSwitchChargeReducer(state.handlerSwitchCharge, action),
        infoCharge: infoChargeReducer(state.infoCharge, action),
        taxesByOutlets: taxesByOutletsReducer(state.taxesByOutlets, action),
        activedSwitchStore : activeSwitchReducer(state.activedSwitchStore, action),
        selectedOutlet: toggleSelectOutletReducer(state.selectedOutlet, action),
        eventTypeProduct:  typeEventProductReducer(state.eventTypeProduct, action),
        customizedCharges: customizedChargesReducer(state.customizedCharges, action),
        alertDragAndDrop: alertDragAndDropReducer(state.alertDragAndDrop, action),
        currentDragProduct:currentDragProductReducer(state.currentDragProduct, action)
    }
}

export default storeSettings