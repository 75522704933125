import {
    LOAD_GROUPS,
    ADD_GROUP,
    EDIT_GROUP,
    REMOVE_GROUP,
    LOAD_ACCOUNTS,
} from "../actions/actionsTypes";


const groupsReducer = (state = {}, action) => {
    switch (action.type) {
        case LOAD_ACCOUNTS:
        case LOAD_GROUPS:
            return action.groups.reduce((obj, group) => {
                obj[group.id] = group;
                return obj
                // isLoading:false
            }, {})
        case ADD_GROUP:
        case EDIT_GROUP:
            return {
                ...state,
                [action.payload.id] : action.payload
            };
        case REMOVE_GROUP:
            const {[action.groupId]: group, ...groups} = state;
            return  groups;
        default:
            return  state;
    }
};

export default groupsReducer;