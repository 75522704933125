import React from 'react';
import {useDispatch} from "react-redux";
import {cleanCustomerFilter} from "../../../pos/redux/actions";
import {filterSearchMultiple} from '../../../common/actions/utilsActions'

import {makeStyles} from '@material-ui/core/styles';
import {Box, Grid} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Logo from '../../../../assets/images/min_logos/rombos/rombo_green.png'
import {SET_GROUP_PAYMENT} from "../../../rumi/actionTypes";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    logo: {
        width: "2.65em",
        height: "2.65em"
    },
    pageTitles: {
        fontSize: "1.75rem",
        fontWeight: "400"
    },
    tabsContainer:{
        marginTop: 5,
        [theme.breakpoints.down('xs')]: {
            display:"flex",
            alignItems:"center", 
            justifyContent: "center",
        },
    }
    
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        dispatch(cleanCustomerFilter());
        dispatch({type: SET_GROUP_PAYMENT, payload: false});
        dispatch(filterSearchMultiple(""))
    };

    return (
        <div>
            <Box onClick={handleClickOpen}>
                {props.btnOpen}
            </Box>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}
                    transitionDuration={500}>
                <DialogTitle id="alert-dialog-title">
                    <Grid container >
                        <Grid item xs={12} sm={5}>
                            <Box display="flex" alignItems="center" >
                                <img className={classes.logo} src={Logo} alt="logo"/>
                                <Box color="text.primary" ml={2} mb={0.7}>
                                    <Typography className={classes.pageTitles}>
                                        {props.title}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box className={classes.tabsContainer}>
                                {props.tabsView}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={1}  style={{position:'absolute',right: 10}}>
                            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <Divider/>
                <DialogContent style={{backgroundColor: '#fafafa', paddingTop: 20}}>
                    {props.body}
                </DialogContent>
            </Dialog>
        </div>
    );
}
