import {
    CLEAN_BALANCE_FLOWS_REPORT,
    CLEAN_CREDIT_NOTES_REPORT, CLEAN_GUEST_FLOWS_REPORT,
    CLEAN_INVOICES_REPORT,
    CLEAN_RANKING_REPORT,
    CLEAN_SALES_REPORT,
    CLEAN_TICKETS_REPORT, FILTER_BALANCE_FLOWS_REPORT,
    FILTER_CREDIT_NOTES_REPORT, FILTER_GUEST_FLOWS_REPORT,
    FILTER_INVOICES_REPORT,
    FILTER_RANKING_REPORT,
    FILTER_SALES_REPORT,
    FILTER_TICKETS_REPORT, LOAD_BALANCE_FLOWS_REPORT,
    LOAD_CREDIT_NOTES_REPORT, LOAD_GUEST_FLOWS_REPORT,
    LOAD_INVOICES_REPORT,
    LOAD_RANKING_REPORT,
    LOAD_SALES_REPORT,
    LOAD_TICKETS_REPORT, SEARCH_BALANCE_FLOWS_REPORT,
    SEARCH_CREDIT_NOTES_REPORT, SEARCH_GUEST_FLOWS_REPORT,
    SEARCH_INVOICES_REPORT,
    SEARCH_RANKING_REPORT,
    SEARCH_SALES_REPORT,
    SEARCH_TICKETS_REPORT,
} from "../../redux/actionTypes";

const initialState = {
    balanceFlowsReport:{
        balanceFlows:[],
        balanceFlowsAreLoading:true,
        searchValue:"",
        filterValue:""
    },
    guestFlowsReport: {
        guestFlows: [],
        guestFlowsAreLoading: true,
        searchValue:"",
        filterValue:""
    },
    salesReport: {
        sales: [],
        salesAreLoading: true,
        searchValue:"",
        filterValue:""
    },
    salesRankingReport: {
        ranking: [],
        rankingIsLoading: true,
        searchValue:"",
        filterValue:""
    },
    invoiceReport: {
        invoices: [],
        invoicesAreLoading: true,
        searchValue:"",
        filterValue:""
    },
    ticketReport: {
        tickets: [],
        ticketsAreLoading: true,
        searchValue:"",
        filterValue:""
    },
    creditNoteReport: {
        creditNotes: [],
        creditNotesAreLoading: true,
        searchValue:"",
        filterValue:""
    },
    inputValue: "",
    isLoading: true,
};

const balanceFlowsReport = (state = initialState.balanceFlowsReport, action) => {
    switch (action.type) {
        case LOAD_BALANCE_FLOWS_REPORT:
            return {...state,
                balanceFlows: action.payload,
                balanceFlowsAreLoading: false
            };
        case FILTER_BALANCE_FLOWS_REPORT:
            return {
                ...state,
                filterValue:action.payload
            };
        case SEARCH_BALANCE_FLOWS_REPORT:
            return {
                ...state,
                searchValue:action.valueInput,
            };
        case CLEAN_BALANCE_FLOWS_REPORT:
        default:
            return state
    }
};

const guestFlowsReport = (state = initialState.guestFlowsReport, action) => {
    switch (action.type) {
        case LOAD_GUEST_FLOWS_REPORT:
            return {...state,
                guestFlows: action.payload,
                guestFlowsAreLoading: false
            };
        case FILTER_GUEST_FLOWS_REPORT:
            return {
                ...state,
                filterValue:action.payload
            };
        case SEARCH_GUEST_FLOWS_REPORT:
            return {
                ...state,
                searchValue:action.valueInput,
            };
        case CLEAN_GUEST_FLOWS_REPORT:
        default:
            return state
    }
};

const salesReport = (state = initialState.salesReport, action) => {
    switch (action.type) {
        case LOAD_SALES_REPORT:
            return {...state,
                sales: action.payload,
                salesAreLoading: false
            };
        case FILTER_SALES_REPORT:
            return {
                ...state,
                filterValue:action.payload
            };
        case SEARCH_SALES_REPORT:
            return {
                ...state,
                searchValue:action.valueInput,
            };
        case CLEAN_SALES_REPORT:
        default:
            return state
    }
};

const salesRankingReport = (state = initialState.salesRankingReport, action) => {
    switch (action.type) {
        case LOAD_RANKING_REPORT:
            return {
                ...state,
                ranking: action.payload,
                rankingIsLoading: false
            };
        case FILTER_RANKING_REPORT:
            return {
                ...state,
                filterValue:action.payload
            };
        case SEARCH_RANKING_REPORT:
            return {
                ...state,
                searchValue:action.valueInput,
            };
        case CLEAN_RANKING_REPORT:
        default:
            return state
    }
};
const invoiceReport = (state = initialState.invoiceReport, action) => {
    switch (action.type) {
        case LOAD_INVOICES_REPORT:
            return {
                ...state,
                invoices: action.payload,
                invoicesAreLoading: false
            };
        case FILTER_INVOICES_REPORT:
            return {
                ...state,
                filterValue:action.payload
            };
        case SEARCH_INVOICES_REPORT:
            return {
                ...state,
                searchValue:action.valueInput,
            };
        case CLEAN_INVOICES_REPORT:
        default:
            return state
    }
};
const ticketReport = (state = initialState.ticketReport, action) => {
    switch (action.type) {
        case LOAD_TICKETS_REPORT:
            return {
                ...state,
                tickets: action.payload,
                ticketsAreLoading: false
            };
        case FILTER_TICKETS_REPORT:
            return {
                ...state,
                filterValue:action.payload
            };
        case SEARCH_TICKETS_REPORT:
            return {
                ...state,
                searchValue:action.valueInput,
            };
        case CLEAN_TICKETS_REPORT:
        default:
            return state
    }
};
const creditNoteReport = (state = initialState.creditNoteReport, action) => {
    switch (action.type) {
        case LOAD_CREDIT_NOTES_REPORT:
            return {
                ...state,
                creditNotes: action.payload,
                creditNotesAreLoading: false
            };
        case FILTER_CREDIT_NOTES_REPORT:
            return {
                ...state,
                filterValue:action.payload
            };
        case SEARCH_CREDIT_NOTES_REPORT:
            return {
                ...state,
                searchValue:action.valueInput,
            };
        case CLEAN_CREDIT_NOTES_REPORT:
        default:
            return state
    }
};

const reports = (state = initialState, action) => {
    return {
        balanceFlowsReport: balanceFlowsReport(state.balanceFlowsReport, action),
        guestFlowsReport: guestFlowsReport(state.guestFlowsReport, action),
        salesReport: salesReport(state.salesReport, action),
        salesRankingReport: salesRankingReport(state.salesRankingReport, action),
        invoiceReport: invoiceReport(state.invoiceReport, action),
        ticketReport: ticketReport(state.ticketReport, action),
        creditNoteReport: creditNoteReport(state.creditNoteReport, action),
    }
};

export default reports;