import React , {useEffect, useState, Fragment}from 'react';
import {useDispatch} from "react-redux";
import {store_actions} from "../../permissionsConstants";
// COMPONENTS
import Category from './Category';
import {List, Hidden, Box, Grid} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import DynamicDialog from "../../../common/components/modals/DynamicDialog";
import NullMessage from '../../../common/components/error.pages/NullMessage';

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {handleDynamicDialog} from "../../../common/actions/actions";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    gridContainer:{
        maxHeight:"60vh", 
        overflowY:"scroll",
        [theme.breakpoints.down('sm')]: {
            overflowY: 'hidden'
        }
    }
}));


let CategoryList = ({categoryList, selectCategory, currentCategory, showDeleteIcon, showCreatedCategory, updateProductCategory, 
                    editCategory,  removeCategory , handleShowCategoryForm, handleDropProducts, handleShowCreatedCategory,
                    toggleCreateEditCategory, searchValue, filterSearchMultiple, createdProduct, getCreatedProduct,
                    permissionsList, allProducts, selectedCategoryResponse, animation, keyDeleteResponse, dropProductList, activeAlertDragAndDrop}) => {

    const useclasses =  useStyles();                   
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const permissions = Object.values(permissionsList);
    const canManageSettingsStore = permissions.includes(store_actions) || permissions.includes("superuser");

    const [auxDropProducts, setAuxDropProducts] = useState([])
    const [auxCategoryList] = useState([])
    const [newCategoryId, setNewCategoryId] = useState(0)
    const [categorySorted, setCategorySorted] = useState({})
    const arrIDs = categoryList.map(category => category.id)
    const categoryMaxId = Math.max(...arrIDs)

    const validateNewCreatedCategory = (maxID, catName) => {
        const categories = categoryList.length > 0 ? categoryList.sort((asc, desc) => asc.name > desc.name ? 1 : -1).filter((category, index) => index === 0 ? category : null) : null
        if (showCreatedCategory) {
            setNewCategoryId(maxID)
            dispatch(selectCategory(maxID, catName))

        } else {
            setNewCategoryId(0)
            if (categories !== null) {
                if (createdProduct === "") {
                    dispatch(selectCategory(categories[0].id, categories[0].name))
                }
            }
        }
        if(categoryList.length > 0){
            setCategorySorted({id:categories[0].id, name:categories[0].name})
        }
    }

    const calcNewCreatedCategory = () => {
        const categoryName = categoryList.map(category => categoryMaxId === category.id ? category.name : null)
        validateNewCreatedCategory(categoryMaxId, categoryName)
    }

    useEffect(() => {
            setLoading(false)
            calcNewCreatedCategory()
            dispatch(handleShowCreatedCategory(false))
            if(animation == "delete" &&  keyDeleteResponse == "delete-category") {
                dispatch(selectCategory(categorySorted.id, categorySorted.name))
            }
           
        }, [categoryList, animation]
    )

    const onDragOver = (ev) => {
        ev.preventDefault()
    };

    const onDrop = (e, categoryId, categoryName) => {
        const productId = Number(e.dataTransfer.getData("productId"));
        const currentCategory = Number(e.dataTransfer.getData("categoryId"));
       
        if (currentCategory !== categoryId) {
            updateProductCategory(productId, categoryId);
            const isSameProduct = auxDropProducts.filter(element => element.productId == productId).length > 0
            if (isSameProduct) {
                for (let i = 0; i < auxDropProducts.length; i++) {
                    if (auxDropProducts[i].productId == productId) {
                        auxDropProducts[i].categoryId = categoryId;
                        break;
                    }
                }
            } else {
                auxDropProducts.push({productId, categoryId})
            }
            dispatch(handleDropProducts(auxDropProducts))
            selectCategory(categoryId, categoryName)

        } else {
            const productName = e.dataTransfer.getData("productName");
            alert(`${productName}  already exists on ${categoryName} category`)
        }

    };

    const handleEditCategory = (categoryId, categoryName) => {
        const category = {"id": categoryId, "name": categoryName};
        handleShowCategoryForm(true);
        toggleCreateEditCategory(2);
        editCategory(category);
    };

    const handleSelectedListCategory = (event) => {
        selectCategory(event.target.value[0], event.target.value[1]);
    };

    const handleSelectedCategory = (categoryId) => {
        if(!searchValue) {
            return categoryId === currentCategory  ? true : false
        }else {
            return false
        }
    };

    return (
        <Grid container item  className={useclasses.gridContainer} >
            <Grid item xs={12} sm={12} md={11} lg={11} >
                <Hidden smDown>
                    { categoryList.length > 0 ?
                        <List component="nav" aria-label="main mailbox folders" style={{paddingTop:(!newCategoryId ? 0 : 45), width:'100%'}} >
                        {
                            auxCategoryList.concat(categoryList)
                            .sort((asc, desc) => asc.name > desc.name ? 1 : -1)
                            .map((category, index) => (
                                <Category key={index + 1}
                                    newCategoryId={newCategoryId}
                                    category={category}
                                    selectedCategory={handleSelectedCategory(category.id)}
                                    showDeleteIcon={showDeleteIcon}
                                    selectCategory={selectCategory}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    edit={editCategory}
                                    remove={removeCategory}
                                    handleShowCategoryForm={handleShowCategoryForm}
                                    toggleCreateEditCategory={toggleCreateEditCategory}
                                    filterSearchMultiple={filterSearchMultiple}
                                    categoryMaxId={categoryMaxId}
                                    getCreatedProduct={getCreatedProduct}
                                    allProducts={allProducts}
                                    selectedCategoryResponse={selectedCategoryResponse}
                                    animation={animation}
                                    dropProductList={dropProductList}
                                    activeAlertDragAndDrop={activeAlertDragAndDrop}
                                    canManage={canManageSettingsStore}
                                />
                            ))
                        }
                        </List>
                        : <NullMessage message="There are no categories" height={"25em"} />
                    }
                </Hidden>
                <Hidden mdUp>
                    <Box mb={0.6}>
                        { categoryList.length > 0 ?
                            <FormControl fullWidth>
                                <Select 
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    onChange={handleSelectedListCategory}>
                                    {
                                        categoryList.map((category, index) => (
                                            <MenuItem key={index + 1} value={[category.id, category.name]}>
                                                {category.name}
                                                {
                                                    canManageSettingsStore ?  
                                                    (!category.products &&  (category.id === categoryMaxId) ?
                                                        <span  style={{color: '#39CDC8', marginLeft: 20}}
                                                        >( New )</span> : null
                                                    ): null
                                                }
                                                {
                                                    allProducts.filter(product => product.category === category.id).length === 0 ? 
                                                    ( canManageSettingsStore ?  
                                                        <IconButton style={{padding: 7, position:"absolute", right:40}}
                                                                    onClick={() => dispatch(handleDynamicDialog("category",category.id))}>
                                                            <DeleteIcon style={{color: "#EC4262"}} />
                                                        </IconButton> : 
                                                           null
                                                    ) : null
                                                }
                                                {
                                                    canManageSettingsStore ? 
                                                    <IconButton style={{padding: 7, position:"absolute", right:0}}
                                                     onClick={() => handleEditCategory(category.id, category.name)}>
                                                        <EditIcon style={{color: "#F5BC00"}} />
                                                    </IconButton>
                                                    :  null
                                                }
                                               
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            : <NullMessage message="There are no categories" height={"25em"} />
                        }
                    </Box>
                </Hidden> 
            </Grid>
            <DynamicDialog
            title={"Are you sure?"}
            keyValue="category"
            msg={"Do you want to delete this category?"}
            type={"delete"}
            handleFunction={removeCategory}/>
        </Grid>
    )
}

export default  CategoryList
