import React from 'react';
import {Link, withRouter} from "react-router-dom";
import {useDispatch} from 'react-redux';
import {cleanConsumption, cleanCustomerFilter, consumptionFetching} from "../../redux/actions";
import {setMenuItem} from "../../../common/actions/actions"
// CSS
import Classes from '../../../../styles'
// COMPONENTS
import CustomerSection from "../../components/payment_panel/CustomerSection";
import ExternalSection from '../../components/payment_panel/ExternalSection'
import CompanyForm from '../../components/payment_panel/CompanyForm'
import CompanySection from '../../components/payment_panel/CompanySection'

import ModalView from "../../../common/components/modals/ModalView"
import {openModalView} from "../../../common/actions/utilsActions"
import {CLEAR_COMPANY_FLAG, CLEAR_COMPANY_INFO, SET_COMPANY_FLAG, SET_GROUP_PAYMENT} from "../../../rumi/actionTypes";
import {loadConsumption} from "../../../rumi/actions/async";
import {selectPaymentOptions} from "../../../rumi/actions/sync"

// MATERIAL UI
import {Box, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';
import CompanyIcon from '@material-ui/icons/BusinessOutlined';
import MoreIcon from '@material-ui/icons/MoreVert';
import PeopleIcon from '@material-ui/icons/People';
import ClientIcon from '@material-ui/icons/PersonOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';

import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    container: {
        padding: '12px 7px 12px 15px',
        background: theme.palette.grey['50']
    },
    iconButton: {
        paddingRight: '8px',
        '& :hover': {
            color: theme.palette.primary.main
        },
    },
    menuItem: {
        borderRadius: 50,
        '&:hover': {
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.primary.main
            },
        },
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white + "!important"
            },
        },
    },
    menuItemActive: {
        borderRadius: 50,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
            color: theme.palette.primary.main,

        }
    },
}));

function ClientDetails({location, ...props}) {
    const classes = Classes();
    const useclasses = useStyles();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const currentPath = location.pathname
    const isConsumptionsView = currentPath.includes('consumptions')
    const clientFullNameToLowerCase = !props.client.fullName ? "" : props.client.fullName.toLowerCase();

    const open = Boolean(anchorEl);

    const menuCustomer = [
        {
            name: 'Company',
            icon: <CompanyIcon/>
        },
        // {
        //     name: 'Company group',
        //     icon: <span style={{marginRight: 15}}><CompanyIcon/>
        //         <GroupAddIcon/>
        //     </span>
        //
        // }
    ];

    const menuExternal = [
        {
            url: null,
            name: 'Company',
            icon: <CompanyIcon/>
        }
    ];

    const menu = props.customerType === "GUEST" ? menuCustomer : menuExternal;

    const removeSelectedClient = () => {
        dispatch(setMenuItem('Clients'));
        dispatch(cleanCustomerFilter());
        dispatch(props.setClient({}, ""))
        dispatch({type: SET_GROUP_PAYMENT, payload: false});
        dispatch({type: CLEAR_COMPANY_INFO});
        dispatch({type: CLEAR_COMPANY_FLAG});
        dispatch(selectPaymentOptions(""))
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCompanyPaymentChange = (value) => {
        dispatch({type: SET_COMPANY_FLAG, isCompany: value});
    };

    const handleGroupPaymentChange = (value) => {
        dispatch({type: SET_GROUP_PAYMENT, payload: value})
    };

    const toggleSelectPaymentOptions = (value) => {
        dispatch(selectPaymentOptions(value))
    }
    return (
        <Grid container className={useclasses.container}>
            <Grid item xs={11}>
                {
                    props.isCompany ? <CompanySection/> :
                        props.optionClientType === "External" ?
                            <ExternalSection/>
                            : <CustomerSection/>
                }
            </Grid>
            <Grid item xs={1}>
                <Link to={props.clientRoute}
                      className={`${classes.nav.link}`}>
                    <IconButton aria-label="settings"
                                style={{padding: '5px'}}
                                className={classes.buttons.btnCloseCardPanel}
                                onClick={removeSelectedClient}>
                        <CloseIcon/>
                    </IconButton>
                </Link>
                <Box mt={2}
                     style={isConsumptionsView && props.customerType !== "GUEST" && !props.client.debt ? {display: "none"} : {}}>
                    <IconButton
                        className={classes.buttons.btnCloseCardPanel}
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        style={{padding: '5px'}}>
                        <MoreIcon/>
                    </IconButton>
                </Box>
                {props.isCompany &&
                <IconButton
                    className={classes.buttons.btnCloseCardPanel}
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => {
                        dispatch(openModalView('Company'))
                    }}
                    style={{padding: '5px', marginTop: 10}}>
                    <EditIcon/>
                </IconButton>
                }
            </Grid>
            <Menu id="menu-appbar" anchorEl={anchorEl}
                  anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                  }}
                  open={open}
                  onClick={handleClose}
                  style={{right: '7em'}}>
                {
                    props.selectedPaymentOption !== "" &&
                    <Box display="flex" flexDirection="row" px={'0.8em'} py={'0'}
                         onClick={() => {
                             setTimeout(() => toggleSelectPaymentOptions(""), 200)
                             handleCompanyPaymentChange(false)
                             handleGroupPaymentChange(false)
                             dispatch(loadConsumption())
                             dispatch({type: CLEAR_COMPANY_INFO});
                         }}>
                        <MenuItem style={{width: "100%"}}
                                  className={`general-colors- ${classes.colors.grey7a} submenu- ${useclasses.menuItem} `}>
                            <ListItemIcon>
                                <ClientIcon/>
                            </ListItemIcon>
                            <ListItemText style={{textTransform: 'capitalize'}}
                                          primary={props.customerType === "EXTERNAL" ? "Client: External" : ("Client: " + clientFullNameToLowerCase)}/>
                        </MenuItem>
                    </Box>
                }
                { isConsumptionsView ?
                    props.customerType === "GUEST" &&  props.selectedPaymentOption !== "Group" &&//!isGroup &&
                    <Box display="flex" flexDirection="row" px={'0.8em'} py={'0'}
                    onClick={()=> {
                        setTimeout(()=> toggleSelectPaymentOptions("Group"),  200)
                        handleGroupPaymentChange(true)
                        dispatch(consumptionFetching());
                        dispatch(loadConsumption())
                    }} >
                        <MenuItem style={{width: "100%" }} className={`general-colors- ${classes.colors.grey7a} submenu- ${useclasses.menuItem} `} >
                            <ListItemIcon >
                                <PeopleIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Group" />
                        </MenuItem>
                    </Box> : null
                }
                {
                    menu.map((submenu, index) =>
                        <Box key={index + 1}>
                            <Box display="flex" flexDirection="row" px={'0.8em'} py={'0'}
                                 onClick={() => {
                                     dispatch(openModalView(submenu.name))
                                 }}>
                                <MenuItem style={{width: "100%"}}
                                          className={`general-colors- ${classes.colors.grey7a} submenu- ${useclasses.menuItem}`}
                                >
                                    <ListItemIcon>
                                        {submenu.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={submenu.name}/>
                                </MenuItem>
                            </Box>
                            <ModalView
                                title={submenu.name}
                                keyValue={submenu.name}
                                body={<CompanyForm typeCompany={submenu.name}/>}
                            />
                        </Box>
                    )
                }
            </Menu>
        </Grid>

    );
}

export default withRouter(ClientDetails);
