import React from 'react';
import errorPage403 from "../../../../assets/errorPage403.svg";
import ErrorPageDisplay from "./errorPageDisplay";

const page403 = () => {
    return (
        <ErrorPageDisplay errorName="Access forbiden" errorDisplay={errorPage403}/>
    )
};

export default page403