import * as api_settings from "../../api.calls";
import {ADD_SERIAL} from "../../actions/actionsTypes";
import {verifyToken} from "../../../common/apiCalls";
import {userDataRequest} from "../../../common/actions/asyncActions";

export const validate = values => {
    const {location, ticket, creditNoteTicket, invoice, creditNoteInvoice} = values

    const errors = {}
    if (!location) {
        errors.location = 'Required'
    }

    const documents = {ticket, creditNoteTicket, invoice, creditNoteInvoice}
    Object.entries(documents).reduce((acc, [key, value]) => {
        if(!value || value.length !== 3 ){
            acc[key] = 'Required'
        }
        return acc
    }, errors)

    return errors
}

export const submitSerialForm = async (values, dispatch, {reset, ...rest},) => {
    try {
        await verifyToken();
        const serialRequest = api_settings.postSerial;
        const response = await serialRequest({
            serialData: values,
        })
        if (response.status === 201) {
            dispatch(userDataRequest());
            reset()
        }
        rest.history.goBack()
    } catch (e) {
    }
}
