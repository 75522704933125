import * as api_settings from "../../api.calls";
import {ADD_USER, EDIT_USER} from "../../actions/actionsTypes";
import {verifyToken} from "../../../common/apiCalls";
import {initialize} from "redux-form";
import {userLookupAPI} from "../../api.calls";

export const validate = values => {
    const errors = {}
    if (!values.firstName) {
        errors.firstName = 'Required'
    }
    if (!values.lastName) {
        errors.lastName = 'Required'
    }
    if (!values.email) {
        errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }
    if (!values.username) {
        errors.username = 'Required'
    }
    if (!values.password && !values.id) {
        errors.password = 'Required'
    }
    if (!values.password2 && !values.id) {
        errors.password2 = 'Required'
    } else if (values.password2 !== values.password) {
        errors.password2 = 'Passwords do not match'
    }
    if (!values.groups) {
        errors.groups = 'Required'
    }
    return errors
}


export const asyncValidate = async (values, dispatch) => {
    if (values.email){
        try {
            await verifyToken();
            const user = await userLookupAPI(values.email)
            dispatch(
                initialize(
                    'user',
                    {...user.data, groups: user.data.groups[0]})
            )
        } catch (e) {
            // Add some event when requests fail / not found
        }

    }
}

export const submitUserForm = async (values, dispatch, {reset, ...rest},) => {
    try {
        await verifyToken();
        const userRequest = !values.id ? api_settings.postUser : api_settings.putUser
        const response = await userRequest({
            userData: values,
        })
        if (response.status === 201) {
            dispatch({
                type: ADD_USER,
                payload: response.data,
            })
            reset()
        }
        if (response.status === 200) {
            dispatch({
                type: EDIT_USER,
                payload: response.data
            })
        }
        rest.history.goBack()
    } catch (e) {
    }
}
