import React, {useEffect} from 'react';
import {connect, useDispatch} from 'react-redux'
import {calculateConsumptions} from "../../selector/cartAmount";
import {useHistory, useRouteMatch, withRouter} from 'react-router-dom';

import {
    activePaySecondCurrency,
    clearPaymentCurrencyValues,
    processPayment,
    toggleRrhhPayment
} from "../../redux/asyncActions";
import {
    cleanCart,
    cleanConsumption,
    cleanCustomerFilter,
    setClient,
    setPaymentType,
    setTransferPayment,
    togglePaymentSection
} from "../../redux/actions";
import {selectPaymentOptions} from "../../../rumi/actions/sync"


//CONTAINERS
import PaymentTypeView from "./PaymentTypeView";
import AmountDetailView from "../payment-panel/AmountDetailView"
import LoaderProgress from "../../../common/components/modals/LoaderProgress"
// MATERIAL UI
import {Box, Button, Grid, Typography} from '@material-ui/core';
// CSS
import {paymentTypeCriteria} from "../../redux/selector/payment.selector";
import {makeStyles} from '@material-ui/core/styles';
import {staff_payment} from "../../../rumi-settings/permissionsConstants";
import {CLEAR_COMPANY_FLAG, CLEAR_COMPANY_INFO, SET_GROUP_PAYMENT} from "../../../rumi/actionTypes";
import PaymentsMethodIcon from "../../../../assets/icons/methods.svg"


const useStyles = makeStyles((theme) => ({
    buttonSave: {
        '& .MuiButtonBase-root.Mui-disabled': {
            cursor: "not-allowed",
            pointerEvents: "initial"
        }
    },
    container: {
        padding: '0px 7px 5px 15px',
        height: '28em',
        [theme.breakpoints.down('md')]: {
            height: '24em'
        }
    },
    errorMessage: {
        color: theme.palette.error.main
    }
}));

const PaymentDetail = ({
                           location, consumptions, paymentCustomerType, emptyCart, paymentType, validatedExternal,
                           permissionsList, hasSerials, paymentCurrencyValues, activedPaySecondCurrency
                       }) => {
    const dispatch = useDispatch();
    const useclasses = useStyles();
    let history = useHistory();
    let {path} = useRouteMatch();
    const permissions = Object.values(permissionsList);
    const canPayStaff = permissions.includes(staff_payment) || permissions.includes("superuser");
    const redirectCB = origin => history.push(origin);
    const currentPath = location.pathname;

    const cancelTransaction = () => {
        if (currentPath.includes('consumptions')) {
            dispatch(cleanConsumption());
        } else {
            dispatch(cleanCart());
        }
    };

    const disabledSaveBtn = () => {
        const validatePaySecondCurrency = activedPaySecondCurrency ? (paymentCurrencyValues.secondaryValue > 0 ? true : false) : true
        if (paymentCustomerType || validatedExternal) {
            if (currentPath.includes("consumptions") && parseInt(consumptions.total) > 0) {
                if (validatePaySecondCurrency && paymentType && canPayStaff && hasSerials) {
                    return false
                } else {
                    return true
                }
            } else {
                if (!emptyCart) {
                    if (validatePaySecondCurrency && paymentType && hasSerials) {
                        return false
                    } else {
                        return true
                    }
                } else {
                    return true
                }

            }
        } else {
            return true
        }

    };

    const handleCancel = () => {
        dispatch(togglePaymentSection(false))
        dispatch(cleanCustomerFilter());
        dispatch(setClient({}, ""));
        dispatch(toggleRrhhPayment(false))
        cancelTransaction();
        dispatch(setPaymentType("", false));
        dispatch(setTransferPayment(""))
        dispatch({type: SET_GROUP_PAYMENT, payload: false});
        dispatch({type: CLEAR_COMPANY_INFO});
        dispatch({type: CLEAR_COMPANY_FLAG});
        dispatch(selectPaymentOptions(""))
        dispatch(activePaySecondCurrency(false))
        redirectCB(`${path}/clients`);
    };

    useEffect(() => {
        if (!paymentType) {
            dispatch(activePaySecondCurrency(false))
        }
    }, [paymentType])


    return (
        <Grid container item className={useclasses.container} spacing={1}>
            <Grid item xs={12}>
                {
                    (currentPath.includes('consumptions') && parseInt(consumptions.total) === 0) ?
                        <Box p={5}
                             display="flex"
                             alignItems="center"
                             justifyContent="center"
                             flexDirection="column">
                            <img src={PaymentsMethodIcon} style={{width: 45, height: 45, opacity: 0.2}}/>
                            <Box style={{color: '#9e9e9e'}} mt={2}>
                                <Typography variant="subtitle1">
                                    Payments method
                                </Typography>
                            </Box>
                        </Box>
                        : <PaymentTypeView/>
                }
            </Grid>
            <AmountDetailView/>
            <Grid item xs={12}>
                {!hasSerials ?
                    <span style={{color: '#EC4262'}}>
                    There are no serials configured*
                </span> : ""}
                <Box display="flex" alignItems="center" justifyContent="center"
                     className={disabledSaveBtn() ? useclasses.buttonSave : "default"}>
                    <Button variant="outlined" color="primary" style={{marginRight: '1em', width: '8em'}}
                            onClick={handleCancel}>
                        CANCEL
                    </Button>

                    <Button variant="contained" color="primary" disabled={disabledSaveBtn()} style={{width: '8em'}}
                            onClick={() => {
                                dispatch(processPayment(redirectCB, path, currentPath, paymentType, paymentCurrencyValues));
                                dispatch(cleanCustomerFilter());
                                dispatch(clearPaymentCurrencyValues());
                            }}>
                        {paymentType !== "CHRG" ? (paymentCustomerType === "STAFF" && location.pathname !== "/billing/store" && location.pathname !== "/pos/store" ?
                            "Pay Staff" : "Pay") : "Sale"}
                    </Button>
                </Box>
            </Grid>
            <LoaderProgress/>
        </Grid>

    )
};

const mapStateToProps = state => {
    return {
        paymentCustomerType: state.paymentTypeSelected.paymentCustomerType,
        paymentType: paymentTypeCriteria(state),
        emptyCart: !state.cart.productsIds.length,
        consumptions: calculateConsumptions(state),
        validatedExternal: state.clientPanel.validatedExternal,
        permissionsList: state.auth.permissionsList,
        hasSerials: state.auth.userData.locationHasSerials,
        paymentCurrencyValues: state.paymentCurrencyValues,
        activedPaySecondCurrency: state.paymentTypeSelected.activedPaySecondCurrency
    }
};

export default connect(mapStateToProps)(withRouter(PaymentDetail))





