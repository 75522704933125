import {connect} from "react-redux"
import CloseShift from "../../components/balance/CloseShift"
import {sendAndCloseShift} from "../../redux/asyncActions"

const mapStateToProps = (state) => {
    return {
    closeShift: state.closeShift,
    transfers: state.balanceTransfersReducer.transfers,
    exchangeRate: state.exchangeRateReducer.exchangeRate
}};

export default connect(mapStateToProps, {sendAndCloseShift})(CloseShift)