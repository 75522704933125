import React, {useEffect, useState} from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect , useDispatch} from "react-redux";
import {
    billing_store, pos_store
} from "../../../rumi-settings/permissionsConstants";
import {activePaySecondCurrency} from "../../redux/asyncActions";
import {getCurrentRoute, togglePaymentSection, setPaymentType, setTransferPayment} from "../../redux/actions"
// //CONTAINERS
import ClientPanel from './ClientPanel';
import CartPanel from './CartPanel';
import PaymentDetail from './PaymentDetail'
import TransferPaymentSection from '../../components/payment_panel/TransferPaymentSection'
// MATERIAL UI
import {Box, Grid, Typography, Button} from '@material-ui/core';
import Classes from "../../../../styles";
import PaymentIcon from "@material-ui/icons/MonetizationOnOutlined";
import StoreIcon from "@material-ui/icons/LocalGroceryStoreOutlined";
import ClientIcon from "@material-ui/icons/PersonOutlineRounded";
import {RUMI_LINK,POS_LINK} from "../../../common/commonConstants";
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    container: {
        padding:'0 20px 10px 20px',
        [theme.breakpoints.down('md')]: {
            maxHeight: '44em',
            overflow:'scroll'
        }
    },
    gridItem:{
        padding:'3%  0'
    },
    typography: {
        marginLeft:10
    },
    link:{
        color: theme.palette.primary.main,
        cursor:'pointer'
    }
}));

const PaymentPanel = ({location,permissionsList, chargedCartProducts, optionClientType, openTransferSection, openPaymentSection, totalProducts}) => {
    const origin = localStorage.getItem('origin')
    const dispatch = useDispatch();
    const classes = Classes();
    const useclasses = useStyles()
    const permissions = Object.values(permissionsList);
    const canSeeBillingStore = permissions.includes(billing_store) || permissions.includes("superuser");
    const canSeePosStore = permissions.includes(pos_store) || permissions.includes("superuser");

    useEffect(() => {
        dispatch(getCurrentRoute(location.pathname));
        const currentPath = location.pathname;
        dispatch(setPaymentType("", false));
        dispatch(setTransferPayment(""))
        dispatch(activePaySecondCurrency(false))
        if(currentPath.includes("consumptions")) {
            dispatch(togglePaymentSection(true))
        } else {
            dispatch(togglePaymentSection(false))
        }
        
    }, [location]);

    const enableSaleStore = () => {
        if (origin === RUMI_LINK) {
            if(location.pathname ===  "/"+ RUMI_LINK + "/consumptions"){
                return true
            }else {
                return canSeeBillingStore
            }
        } else if (origin === POS_LINK) {
            return canSeePosStore
        } else {
            return true
        }
    };

    return (
    <Grid container item className={useclasses.container}  style={ enableSaleStore() ? {}: {filter:'opacity(0.5)', cursor: 'not-allowed'}}>
        <Grid item xs={12}  className={useclasses.gridItem}  style={ enableSaleStore() ? {}: {pointerEvents: 'none'}}>
            <Box  display="flex" >
                    <ClientIcon color="primary" />
                    <Typography variant="subtitle1" color="textPrimary" className={useclasses.typography} >
                            {
                                optionClientType === "External" ? "External": "Clients"
                            }
                    </Typography>
            </Box>
            <ClientPanel/>  
        </Grid>
        {
            location.pathname !==  "/"+ RUMI_LINK + "/consumptions" &&
            <Grid item xs={12} style={{padding: '1.5% 0 0 0'}} style={ enableSaleStore() ? {}: {pointerEvents: 'none'}}>
                <Box  display="flex" >
                    <Box flexGrow={1} display="flex" flexDirection="row">
                        <StoreIcon color="primary" /> 
                        <Typography variant="subtitle1" color="textPrimary" className={useclasses.typography}>
                            Store
                        </Typography>
                    </Box>
                    {
                        (chargedCartProducts && openPaymentSection) &&
                        <a className={`${classes.nav.link} ${useclasses.link}`} onClick={()=> dispatch(togglePaymentSection(false))}> 
                            {totalProducts} unit(s) added
                        </a>
                    }
                </Box>
                {
                    !openPaymentSection &&
                    <CartPanel/>
                }
            </Grid> 
        }
        {
            openPaymentSection &&
            <Grid item xs={12}  className={useclasses.gridItem} >
                <Box  display="flex" >
                    <PaymentIcon color="primary"/>
                    <Typography variant="subtitle1" color="textPrimary" className={useclasses.typography}>
                            Payments
                    </Typography>
                </Box>
                {
                    openTransferSection ?
                    <TransferPaymentSection/>
                    :
                    <PaymentDetail/>
                } 
            </Grid> 
        }    
    </Grid>
      
    );
};

const mapStateToProps = (state) => {
    const qtyProducts =  !Object.values(state.cart.quantityById).length  ? [] : Object.values(state.cart.quantityById).reduce((total, num) =>  total + num)
   
    return {
        chargedCartProducts: state.cart.productsIds.length > 0,
        optionClientType: state.paymentTypeSelected.optionClientType,
        openTransferSection: state.paymentTypeSelected.transferPaymentSection.status,
        openPaymentSection:state.paymentTypeSelected.openPaymentSection,
        totalProducts: qtyProducts,
        permissionsList: state.auth.permissionsList,
    }
}


export default connect(mapStateToProps)(withRouter(PaymentPanel));
