import {
    handleCreditNotesSearch,
    handleSearchSettingsStaff,
    handleSearchSettingsVolunteer, handleSearchSettingsVolunteerModal
} from "../../common/selectors/utilsSelector";
import {useSelector} from "react-redux";

const getDocumentType = state => state.commonOptions.idType;
const getCountry = state => state.commonOptions.country;
const commonIsFetch = state => state.commonOptions.isFetch;
const getCustomerSelected = state => state.customerSettings.selectedCustomer;
const getCustomers = state => state.customerSettings.customers;
const getStaffSearchValue = state => state.customerSettings.searchStaff;
const getVolunteerSearchValue = state => state.customerSettings.searchVolunteer;
const getPmsGuests = state => state.pmsEntities.guest;
const getVolunteerModalSearchValue = state => state.employeesAccountsSearchReducer.volunteerSearch;

export const customerSelected = state => getCustomerSelected(state);

export const customerList = (state) => {
    let volunteer = {};
    let staff = {};
    let searchedVolunteers =  null;
    let searchedStaffs = null;
    const isFetch = commonIsFetch(state);
    if (isFetch) {
        const sourceCustomer = getCustomers(state);
        const staffSearch = getStaffSearchValue(state);
        const volunteerSearch = getVolunteerSearchValue(state);
        const list = Object.values(sourceCustomer);
        const customers = list.map(customer => ({ ...customer }));
        volunteer = listFilter(customers.filter(customer => !!customer.pmsGuestId));
        staff = listFilter(customers.filter(customer => !customer.pmsGuestId));
        if(staffSearch !== ""){
            searchedStaffs =  handleSearchSettingsStaff(staffSearch,staff);
        }

        if(volunteerSearch !== ""){
            searchedVolunteers = handleSearchSettingsVolunteer(volunteerSearch, volunteer)
        }
        // const sourceDocument = getDocumentType(state);
        // const sourceCountry = getCountry(state);
        // const customers = Object.values(sourceCustomer).map(customer => (
        //         {
        //             ...customer,
        //             countryName: customer.country ? sourceCountry[customer.country].name : null,
        //             documentName: customer.document ? sourceDocument[customer.document].name : null
        //         }
        //     )
        // );
        // volunteer = customers.filter(customer => !!customer.pmsGuestId);
        // staff = customers.filter(customer => !customer.pmsGuestId);
    }
    return {
        volunteer: searchedVolunteers? searchedVolunteers : volunteer,
        staff: searchedStaffs ? searchedStaffs : staff,
        isFetch
    }
};

export const pmsGuestsList = (state) => {
    const guests = getPmsGuests(state);
    const modalVolunteerSearchValue = getVolunteerModalSearchValue(state);
    if (!guests) return [];
    let searchedFromModal = null;
    if(modalVolunteerSearchValue !== ""){
        searchedFromModal = handleSearchSettingsVolunteerModal(modalVolunteerSearchValue, Object.values(guests))
    }
    return searchedFromModal ? Object.values(searchedFromModal) : Object.values(guests);
};

function listFilter(customers) {
    return customers.map(customer =>{
        const {id, name, surname, identityNumber} = customer
        return {id, name, surname, identityNumber}
    })
}