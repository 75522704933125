import {
    ACCOUNT,
    BANK,
    BBVA,
    BCP,
    CARD,
    CASH,
    EXTERNAL,
    MASTERCARD,
    PLIN,
    STAFF,
    VISA,
    WALLET,
    YAPE
} from "../../posConstants";

import Cash from "../../../../assets/icons/cash.png"
import Account from "../../../../assets/icons/payment.png"
import Card from "../../../../assets/icons/credit-card.svg"
import Wallet from "../../../../assets/icons/wallet.svg"
import Bank from "../../../../assets/icons/strongbox.svg"
/* Icon card list*/
import Visa from "../../../../assets/icons/visa2.png"
import Mastercard from "../../../../assets/icons/mastercard2.png"
/* Icon Bank list */
import Bbva from "../../../../assets/icons/bbva-logo.png"
import Bcp from "../../../../assets/icons/bcp-logo.png"
/* Icon wallet list*/
import Plin from "../../../../assets/icons/plin-logo.png"
import Yape from "../../../../assets/icons/yape-logo.png"
/* --- */
import {calculateCartAmount} from "../../selector/cartAmount";
import {groupSelector} from "../../selector/customer.selector";


const getSelectedClient = state => state.client;
const getCartProduct = state => state.cart.quantityById;
const getSelectedAccos = state => state.selectedConsumption.accommodation.items;
const getSelectedAncies = state => state.selectedConsumption.ancillary.items;
const getAccoDiscount = state => state.selectedConsumption.accommodation.discount;
const getAnciesDiscount = state => state.selectedConsumption.ancillary.discount;
// const getCompany = state => state.selectedConsumption.isCompany;
const getPaymentType = state => state.paymentTypeSelected.paymentType;
const getSaleCustomerType = state => state.paymentTypeSelected.paymentCustomerType;
const getRoute = state => state.clientPanel.currentRoute;

export const preparePaymentRequest = (state, currentPath, paymentCurrencyValues) => {
    const groupPayment = state.paymentState.groupPayment;
    const allMethods = [CASH, VISA, MASTERCARD, YAPE, PLIN, BBVA, BCP]
    let customer; //let customer = getSelectedClient(state);
    if (groupPayment) {
        const groupGuests = groupSelector(state);
        customer = groupGuests
    } else {
        const selectedCustomer = getSelectedClient(state);
        if (currentPath.includes('consumptions')) {
            customer = [selectedCustomer]
        } else {
            customer = selectedCustomer
        }
    }

    const selectedPaymentCurrencyValues = paymentCurrencyValues;
    const companyInfo = state.paymentState.company;
    const isCompany = state.paymentState.isCompany;

    const methodSelected = paymentTypeCriteria(state);
    const allowMethod = (customer.reservationId || customer.customerType === STAFF) ? [ACCOUNT, ...allMethods] : allMethods;
    const paymentMethod = allowMethod.indexOf(methodSelected) === -1 ? CASH : methodSelected;
    const isPayment = paymentMethod !== ACCOUNT;
    let data = {};

    if (currentPath.includes('consumptions')) {
        const selectedAccos = getSelectedAccos(state);
        const selectedAncies = getSelectedAncies(state);
        const accosDiscount = getAccoDiscount(state);
        const anciesDiscount = getAnciesDiscount(state);
        // const isCompany = getCompany(state);

        const selectedAncillaries = Object.values(selectedAncies).filter(function (ancie) {
            return ancie.selected === true || ancie.quantity > 0
        });

        const selectedAccommodations = Object.values(selectedAccos).filter(function (acco) {
            return acco.selected === true || acco.quantity > 0
        });

        const anciesTotal = Object.values(selectedAncillaries).reduce(function (acc, item) {
            return acc + item.price.total
        }, 0);

        const countryCode = localStorage.getItem('country')
        const taxValue = localStorage.getItem('taxValue')
        const paymentSecondaryCurrency = state.paymentTypeSelected.activedPaySecondCurrency
        let accosTotal
        if(countryCode==='CL' && paymentSecondaryCurrency){
            accosTotal = Object.values(selectedAccommodations).reduce(function (acc, item) {
                return acc + (item.price.subtotal * taxValue) + item.price.subtotal + item.price.services
            }, 0);
        }else{
            accosTotal = Object.values(selectedAccommodations).reduce(function (acc, item) {
                return acc + item.price.total
            }, 0);
        }

        let accommodations = [];
        selectedAccommodations.forEach(function (acco) {
            accommodations.push(acco.id);
        });

        let items = [];
        selectedAncillaries.forEach(function (anci) {
            items.push(anci.code)
        });
        let discount = {};
        if (accosDiscount > 0) {
            discount['acco'] = accosDiscount
        }
        if (anciesDiscount > 0) {
            discount['ancillary'] = anciesDiscount
        }

        //TODO: fix company variable
        data = {
            'customer': customer,
            'company': isCompany, //This should be obtained correctly
            'prePay': false,
            'paymentType': paymentMethod,
            "items": items,
            "accommodations": accommodations,
            "discount": discount,
            'amountPaid': anciesTotal + accosTotal,
            'primaryCurrencyValue': selectedPaymentCurrencyValues.primaryValue,
            'secondaryCurrencyValue': selectedPaymentCurrencyValues.secondaryValue,
            'devolutionValue': selectedPaymentCurrencyValues.devolution,
            'companyInfo': companyInfo,
        }

    } else {
        const products = getCartProduct(state);
        const amountPaid = calculateCartAmount(state);
        data = {
            'products': products,
            'reservationId': customer.reservationId,
            'isPayment': isPayment,
            'paymentType': paymentMethod,
            'customer': customer,
            'customerType': customer.customerType,
            'amountPaid': amountPaid,
            'primaryCurrencyValue': selectedPaymentCurrencyValues.primaryValue,
            'secondaryCurrencyValue': selectedPaymentCurrencyValues.secondaryValue,
            'devolutionValue': selectedPaymentCurrencyValues.devolution,
            'companyInfo': companyInfo,
            'company': isCompany
        }
    }
    const variableProducts = state.variablePriceProducts;
    data['variableProducts'] = variableProducts;
    const isRrhhPayment = state.paymentState.isRrhhPayment
    data['isRrhhPayment'] = isRrhhPayment;
    return data;
};

const isEnable = customerType => Boolean(customerType);
const accountEnable = customerType => customerType !== EXTERNAL && Boolean(customerType);

export const getWallet = () => ([
    {id: 1, name: 'Yape', code: YAPE, icon: Yape, type: WALLET},
    {id: 2, name: 'plin', code: PLIN, icon: Plin, type: WALLET},
])

export const getBank = () => ([
    {id: 1, name: 'BCP', code: BCP, icon: Bcp, type: BANK},
    {id: 2, name: 'BBVA', code: BBVA, icon: Bbva, type: BANK},
])

export const getCard = () => ([
    {id: 1, name: 'Visa', code: VISA, icon: Visa, type: CARD},
    {id: 2, name: 'Mastercard', code: MASTERCARD, icon: Mastercard, type: CARD},
])

export const getPaymentTypeConfig = () => ([
    {id: 1, name: 'Cash', code: CASH, enable: isEnable, icon: Cash, isGroup: false},
    {id: 2, name: 'Account', code: ACCOUNT, enable: accountEnable, icon: Account, isGroup: false},
    {id: 3, name: 'Card', code: CARD, enable: isEnable, icon: Card, isGroup: true},
    {id: 4, name: 'Wallet', code: WALLET, enable: isEnable, icon: Wallet, isGroup: true},
    {id: 5, name: 'Bank', code: BANK, enable: isEnable, icon: Bank, isGroup: true}
])

export const getSelectedPaymentList = (paymentType) => {
    if (paymentType === CARD) {
        return getCard()
    }
    if (paymentType === WALLET) {
        return getWallet()
    }
    if (paymentType === BANK) {
        return getBank()
    }
    if (!paymentType) {
        return []
    }

}

/* SELECCIONARSE POR DEFAULT TIPOS DE PAGO (reload)*/
export const paymentTypeCriteria = (state) => {
    const route = getRoute(state);
    const paymentTypeSelected = getPaymentType(state);
    const customerType = getSaleCustomerType(state);
    const paymentTypeBlocked = state.client.blocked

    return paymentTypeSelected;
};