import {
    LOAD_BALANCE_STATUS,
    LOAD_SALES_BALANCE,
    LOAD_SALES_HISTORY,
    LOAD_FAILED_SALES, LOAD_CONSUMPTIONS_HISTORY
} from "../../redux/actionTypes";

const initialState = {
    salesByBalance: [],
    salesHistory: [],
    balanceStatus: [],
    failedSales: [],
    isBalanceFetch: false,
    isSalesHistoryFetch:false
}

export default function (state = initialState, action) {

    switch (action.type) {
        case LOAD_SALES_BALANCE:
            return {
                ...state,
                salesByBalance: action.payload,
            }

        case LOAD_CONSUMPTIONS_HISTORY:
            return {
                ...state,
                consumptionsHistory: action.value,
                isConsumptionsHistoryFetch: true
            }

        case LOAD_SALES_HISTORY:
            return {
                ...state,
                salesHistory: action.value,
                isSalesHistoryFetch: true
            }


        case LOAD_FAILED_SALES:
            return {
                ...state,
                failedSales: action.value
            }

        case LOAD_BALANCE_STATUS:
            return {
                ...state,
                balanceStatus: action.value,
                isBalanceFetch: true
            }

        default :
            return state

    }
}