import React, {useEffect, useState} from "react";
import {Box, Input, InputAdornment, Typography} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/SearchRounded";
import Classes from "../../../../styles";
import {connect, useDispatch} from "react-redux";
import {makeStyles} from '@material-ui/core/styles';
import {cleanCustomerFilter, setFilterClient} from "../../../pos/redux/actions";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import {filterSearchMultiple} from "../../actions/utilsActions";

const useStyles = makeStyles((theme) => ({
    marginGrid: {
        marginTop: 12,
        width: '100%',
        paddingRight: '0.5em',
        [theme.breakpoints.up('md')]: {
            paddingLeft: 20
        }
    }
}));

const ReportsSearchPanel = (props) => {
    const classes = Classes();
    const useclasses = useStyles();
    const dispatch = useDispatch();
    const [activeIcon, setActiveIcon] = useState(false);
    const [filtered, setFiltered] = useState(false);
    const [criterialMarket, setCriterialMarket] = useState("");

    const handleEventIcon = (value) => {
        setTimeout(() => setActiveIcon(value), 100);
    }

    useEffect(() => {
        if (props.filterCriteria) {
            setCriterialMarket(props.filterCriteria);
            setFiltered(true);
        } else {
            setCriterialMarket("");
            setFiltered(false);
        }
    }, [props.filterCriteria]);

    const handleFilter = () => {
        dispatch(cleanCustomerFilter());
        dispatch(setFilterClient(""));
    }

    let search;
    return (
        <Box className={useclasses.marginGrid}>
            <Input style={{backgroundColor: '#ffffff'}}
                   fullWidth
                   className={`${classes.forms.searchInput} ${classes.styles.bRadius18} ${classes.styles.shadow1}`}
                   id="input-with-icon-adornment"
                   placeholder="Search"
                   startAdornment={
                       <InputAdornment position="start">
                           <SearchIcon style={activeIcon ? {color: "#5BE576"} : {color: "#9E9E9E"}}/>
                       </InputAdornment>
                   }
                   endAdornment={
                       filtered &&
                       <InputAdornment position="end" onClick={() => {
                           handleFilter()
                       }} style={{cursor: 'pointer'}}>
                           <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center"
                                style={{
                                    backgroundColor: '#adadad',
                                    borderRadius: '1em',
                                    width: '100%',
                                    padding: '0.1em 0.7em',
                                    marginRight: '0.2em'
                                }}
                           >
                               <CancelOutlinedIcon style={{marginRight: '5px', color: '#ffffff'}}/>
                               <Typography variant="subtitle2" style={{color: '#ffffff'}}
                               >{criterialMarket}</Typography>
                           </Box>
                       </InputAdornment>
                   }
                   onChange={(e) => {
                       const value = e.target.value.trim();
                       clearTimeout(search);
                       search = setTimeout(() => dispatch(props.searcher(value)), 500);
                   }}
                   onFocus={() => handleEventIcon(true)}
                   onBlur={() => handleEventIcon(false)}
            />
        </Box>
    )

};

export default connect(state => ({
    filterCriteria: state.clientCriteria.criteria
}), {filterSearchMultiple})(ReportsSearchPanel)
