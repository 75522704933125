import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
// MATERIAL UI
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@material-ui/core/';
// CSS
import Classes from '../../../../styles'
import {validateNumericalInput} from "../../utils/formatConsumptions";
import {connect} from "react-redux";

const AncillaryTable = (props) => {
    const classes = Classes();
    const rows = props.data;
    const columnsLenght = Object.keys(props.columns).length + 1;

    const validateAncieDiscount = (event, subTotal, tax, service) => {
        const total = subTotal + tax + service;
        let value = Number(event.target.value);
        if(value >= total) {
            value = total
        }
        const discount = validateNumericalInput(value);
        props.setDiscount(discount);
    };

    return (
        <Paper elevation={0} className={`${classes.styles.shadow1}`} style={{minHeight:280}}>
            <TableContainer component={Paper} className={`${classes.styles.shadow1}`} style={{maxHeight: 350}}>
                <Table stickyHeader aria-label="sticky table" size="small"
                    className={`${classes.tables.table} white-table`}>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            {Object.values(props.columns).map((column, index) => (
                                <TableCell
                                    key={index + 1}
                                    align={index == 0 ? "center": "right"}
                                    className={classes.tables.tableCell}>
                                    {column.name}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody style={{overflowY: "auto", maxHeight: "213px"}}>
                        {Object.values(rows).map( (outlet, index) => (
                                <TableRow key={index}>
                                    <TableCell className={classes.tables.tableCell}>
                                        <Checkbox
                                            checked={outlet.selected}
                                            onClick={() => {props.toggleOutlet(outlet.code)}}/>
                                    </TableCell>
                                    <TableCell align="center" className={classes.tables.tableCell}>{outlet.description}</TableCell>
                                    <TableCell align="right" className={classes.tables.tableCell}>{ outlet.price.total.toFixed(2) }</TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer>
                <Table aria-label="spanning table" size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={columnsLenght - 1} align="right"
                                       className={`${classes.tables.td} ${classes.tables.tableCell} ${classes.colors.black} ${classes.typography.fontBold}`}>Subtotal
                            </TableCell>
                            <TableCell align="right"
                                       className={`${classes.tables.td} ${classes.tables.tableCell}`}>{props.subTotal.toFixed(2)}
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tables.rowHighlight}>
                            <TableCell colSpan={columnsLenght-1} align="right"
                                       className={`${classes.tables.td} ${classes.tables.tableCell} ${classes.colors.black} ${classes.typography.fontBold}`}>
                                Taxes
                            </TableCell>
                            <TableCell align="right"
                                       className={`${classes.tables.td} ${classes.tables.tableCell}`}>
                                    {props.taxes.toFixed(2)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={columnsLenght-1} align="right"
                                       className={`${classes.tables.td} ${classes.tables.tableCell} ${classes.colors.black} ${classes.typography.fontBold}`}>
                                Charges
                            </TableCell>
                            <TableCell align="right"
                                       className={`${classes.tables.td} ${classes.tables.tableCell}`}>{props.services.toFixed(2)}</TableCell>
                        </TableRow>
                        <TableRow className={classes.tables.rowHighlight}>
                            <TableCell colSpan={columnsLenght - 1} align="right"
                                       className={`${classes.tables.td} ${classes.tables.tableCell} ${classes.colors.black} ${classes.typography.fontBold}`}>
                                Discount</TableCell>
                            <TableCell align="right"
                                       className={`${classes.tables.td} ${classes.tables.tableCell} `}>
                                <TextField style={{width: '6em'}} type="number" name="discount" id="ancyDiscount"
                                           value={props.discount}
                                           inputProps={{min: 0,step:1}}
                                           className={`general-forms- ${classes.forms.textField}`}
                                           onChange={event => validateAncieDiscount(event, props.subTotal, props.taxes, props.services)}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={columnsLenght-1} align="right"
                                       className={`${classes.tables.td} ${classes.tables.tableCell} ${classes.colors.black} ${classes.typography.fontBold}`}>
                                    Total</TableCell>
                            <TableCell align="right"><h3>{props.total.toFixed(2)}</h3></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

const mapStateToProps = (state) => {
    return {
        clientType:state.client.customerType
    }
};
export default connect(mapStateToProps,)(AncillaryTable)