import {CLEAR_CURRENCY_VALUES, SET_CURRENCY_VALUES} from "../redux/actionTypes";

const initialState = {
    primaryValue:0,
    secondaryValue:0,
    devolution:0,
    insufficientPayment:false,
    excessPayment:false

};

const paymentCurrencyValues = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENCY_VALUES:
            return {...action.payload};//payload {primaryValue,secondaryValue, devolution, insufficientPayment, excessPayment}
        case CLEAR_CURRENCY_VALUES:
        default:
            return initialState;
    }
};

export default paymentCurrencyValues