import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
// COMPONENTS MATERIAL UI
import {IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
// CSS
import {buttons, main, tables} from '../../../../styles';
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import {handleDynamicDialog} from "../../../common/actions/actions"
import NullMessage from '../../../common/components/error.pages/NullMessage'
import {DEFAULT} from "../../../common/commonConstants"
import DefaultIcon from '@material-ui/icons/HttpsOutlined';
import DetailsIcon from '@material-ui/icons/VisibilityOutlined';

export default function AccountsTable(props) {
    const classes = {
        main: main(),
        tables: tables(),
        buttons: buttons()
    }
    const dispatch = useDispatch();
    // const [page, setPage] = React.useState(0);
    // const [open, setOpen] = React.useState(false);
    // const [modalId, setModalId] = React.useState(null);
    // const [modalOrigin, setModalOrigin] = React.useState(null);
    // const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const rows = props.entries || [];
    const columns = props.columns || [];
    const edit = props.edit || false;
    // const remove = props.remove || false;
    //
    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(+event.target.value);
    //     setPage(0);
    // };

    // const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    //
    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // };
    //
    // const handleClickOpen = (id, origin) => {
    //     setOpen(true);
    //     setModalId(id);
    //     setModalOrigin(origin);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    return (
        <Paper className={`general-styles- ${classes.main.shadow1}`}>
            <TableContainer style={{maxHeight: "25em"}}>
                <Table stickyHeader size="small" aria-label="sticky table"
                       className={`general-tables- ${classes.tables.table} white-table`}>
                    <TableHead>
                        <TableRow>
                            {columns.map(function (column, index) {
                                if (index === 0 || index === 4) {
                                    return (
                                        <TableCell key={index + 1} align="center"></TableCell>
                                    )
                                }
                                return (
                                    <TableCell key={index + 1} align="center">{column}</TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows.length ?
                                rows.map((row, index) => (
                                        <TableRow
                                            style={row.id === DEFAULT ? row.id === props.userId ? {} : {display: 'none'} : {}}
                                            key={index + 1} hover role="checkbox" tabIndex={-1}
                                            className={`${classes.main.animations} ${props.selectedItem === row.id && props.animation + " active"}`}>
                                            {
                                                Object.values(row).map((value, index) => {
                                                    if (index === 0 || index === 4) {
                                                        return (
                                                            <TableCell key={index + 1} align="center"></TableCell>
                                                        )
                                                    }
                                                    return (
                                                        <TableCell key={index + 1} align="center">{value}</TableCell>
                                                    )
                                                })
                                            }
                                            <TableCell align="center">
                                                {
                                                    (edit && props.canManage ?
                                                        <Fragment>
                                                            {
                                                                row.isStaff ?
                                                                    <Fragment>
                                                                        {
                                                                            props.userId === DEFAULT && row.id === DEFAULT ?
                                                                                <Link to={props.link}>
                                                                                    <IconButton variant="contained"
                                                                                                className={`${classes.buttons.actionButton}`}
                                                                                                onClick={() => {
                                                                                                    props.edit(row.id)
                                                                                                }}>
                                                                                        <DetailsIcon color="secondary"/>
                                                                                    </IconButton>
                                                                                </Link>
                                                                                :
                                                                                <IconButton variant="contained"
                                                                                            disabled
                                                                                            className={`${classes.buttons.actionButton} edit`}
                                                                                >
                                                                                    <EditOutlinedIcon/>
                                                                                </IconButton>
                                                                        }
                                                                    </Fragment>
                                                                    :
                                                                    <Link to={props.link}>
                                                                        <IconButton variant="contained"
                                                                                    className={`${classes.buttons.actionButton} edit`}
                                                                                    onClick={() => {
                                                                                        props.edit(row.id)
                                                                                    }}>
                                                                            <EditOutlinedIcon/>
                                                                        </IconButton>
                                                                    </Link>
                                                            }
                                                        </Fragment>
                                                        :
                                                        <IconButton variant="contained"
                                                                    disabled
                                                                    className={`${classes.buttons.actionButton} `}
                                                        >
                                                            <EditOutlinedIcon/>
                                                        </IconButton>)
                                                }
                                                {
                                                    props.userId === DEFAULT && row.id === DEFAULT ?
                                                        <IconButton variant="contained" style={{pointerEvents: 'none'}}
                                                                    className={`${classes.buttons.actionButton}`}>
                                                            <DefaultIcon color="secondary"/>
                                                        </IconButton>
                                                        :
                                                        (!row.isStaff && props.canManage ?
                                                            <IconButton variant="contained"
                                                                        className={`${classes.buttons.actionButton} delete`}
                                                                        onClick={() => dispatch(handleDynamicDialog(props.entity, row.id))}
                                                            >
                                                                <DeleteIcon/>
                                                            </IconButton> :
                                                            <IconButton variant="contained" disabled
                                                                        className={`${classes.buttons.actionButton} `}
                                                            >
                                                                <DeleteIcon/>
                                                            </IconButton>)
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                ) :
                                <TableRow>
                                    <TableCell colSpan={9} align="center">
                                        <NullMessage message={"There are no" + props.entity} height={"10em"}/>
                                    </TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

        </Paper>
    );
}