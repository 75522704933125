import {
    SELECT_CATEGORY,
    PRODUCT_SELECTED,
    UPDATE_PRODUCT_CATEGORY,
    CHANGE_PREVIEW_APP,
    UPDATE_PREVIEW_CATEGORY,
    SHOW_CATEGORY_FORM, REMOVE_CATEGORY,
    REMOVE_PRODUCT,
    DROP_PRODUCT_LIST, 
    ACTIVE_ALERT_DRAG_DROP,
    SHOW_CREATED_CATEGORY,
    TOGGLE_CREATE_EDIT_CATEGORY,
    GET_CREATED_PRODUCT,
    HANDLE_SWITCH_CHARGE,
    HANDLE_INFO_CHARGE,
    GET_TAXES_BY_OUTLETS,
    ACTIVE_SWITCH_STORE, 
    TOGGLE_SELECT_OUTLET,
    GET_EVENT_TYPE_PRODUCT,
    GET_CUSTOMIZED_CHARGES,
} from "../actionsTypes"
import {GET_RESPONSE} from "../../../common/actions/actionTypes"
import {handleAlerts, openLoaderProgress } from '../../../common/actions/actions'
import {initialize} from "redux-form";
import {verifyToken} from "../../../common/apiCalls";
import * as api_settings from "../../api.calls";


export const selectCategory = (categoryId, name) => {
    return {
        type: SELECT_CATEGORY,
        categoryId,
        name
    }
}
export const selectCategoryPreview = (categoryId) => {
    return {
        type: UPDATE_PREVIEW_CATEGORY,
        categoryId
    }
}

export const filtersVisibleProducts = app => ({
    type: CHANGE_PREVIEW_APP,
    previewApp: app
})

export const getProductSelected = (obj) => {
    return {
        type: PRODUCT_SELECTED,
        obj
    }
}

export const updateProductCategory = (productId, categoryId) => {
    return {
        type: UPDATE_PRODUCT_CATEGORY,
        productId,
        categoryId
    }
}

export const editCategory = ({id, name}) => {
    return initialize("categories", {id, name})
}

export const removeCategory = (categoryId) => async dispatch => {
    try {
        await verifyToken();
        const response = await api_settings.removeCategory(categoryId);
        setTimeout(()=> { 
            dispatch({
                type: REMOVE_CATEGORY,
                categoryId: categoryId
            })
        }, 3000);
        dispatch(handleAlerts('success', 'Category removed ', true));
        setTimeout(()=> { 
            dispatch(handleAlerts('close', '', false));
        }, 900);
        dispatch({
            type: GET_RESPONSE,
            response: {
                status: response.status, 
                method: response.config.method,
                id: categoryId, 
                key: "delete-category"
            }
        })  
    } catch (err) {
        dispatch({
            type: GET_RESPONSE,
            response: {}
        })
        dispatch(handleAlerts('error', 'Failed remove', true));
    }
}

export const handleShowCategoryForm = (flag) => {
    return {
        type: SHOW_CATEGORY_FORM,
        flag
    }
}

export const editProduct = id => (dispatch, getState) => {
    const product = getState().storeReducer.productList[id]
    console.log(product);
    dispatch(initialize("product", product))
}

export const removeProduct = (productId) => async dispatch => {
    try {
        await verifyToken();
        const response = await api_settings.removeProduct(productId) 
        setTimeout(()=> { 
            dispatch({
                type: REMOVE_PRODUCT,
                productId
            })
        }, 3000);
        dispatch(handleAlerts('success', 'Product removed ', true));
        setTimeout(()=> { 
            dispatch(handleAlerts('close', '', false));
        }, 900);
        dispatch({
            type: GET_RESPONSE,
            response: {
                status: response.status, 
                method: response.config.method,
                id: productId, 
                key: "delete-settings-product"
            }
        }) 
        dispatch({
            type: GET_CREATED_PRODUCT,
            value: ""
        }) 
    } catch (err) {
        dispatch({
            type: GET_RESPONSE,
            response: {}
        })
        dispatch(handleAlerts('error', 'Failed remove', true));
    }
}

export const handleDropProducts = (dropProductList) => {
    return {
        type: DROP_PRODUCT_LIST,
        dropProductList
    }
}

export const activeAlertDragAndDrop = (value) => {
    return {
        type: ACTIVE_ALERT_DRAG_DROP,
        value
    }
}

export const handleShowCreatedCategory = (value) => {
    return {
        type: SHOW_CREATED_CATEGORY,
        value
    }
}

export const toggleCreateEditCategory = (value) => {
    return {
        type: TOGGLE_CREATE_EDIT_CATEGORY,
        value
    }
}

export const getCreatedProduct = (value) => {
    return {
        type: GET_CREATED_PRODUCT,
        value
    }
}

export const handleSwitchCharge = (value) => {
    return {
        type: HANDLE_SWITCH_CHARGE,
        value
    }
}

export const handleInfoCharge = (value) => {
    return {
        type: HANDLE_INFO_CHARGE,
        value
    }
}

export const getTaxesByOtulets = (list) => {
    return {
        type: GET_TAXES_BY_OUTLETS,
        list
    }
}

export const activeSwitchStore = (value) => {
    return {
        type: ACTIVE_SWITCH_STORE,
        value
    }
}

export const toggleSelectOutlet = (value) => {
    return {
        type: TOGGLE_SELECT_OUTLET,
        value
    }
}

export const getEventTypeProduct = (value) => {
    return {
        type: GET_EVENT_TYPE_PRODUCT,
        value
    }
}

export const getCustomizedCharges = (values) => {
    return {
        type: GET_CUSTOMIZED_CHARGES,
        values
    }
}

