import React, {useEffect} from 'react';
import {connect , useDispatch} from "react-redux";
import {getSelectedPaymentList} from "../../redux/selector/payment.selector"

import {setPaymentType, setTransferPayment, toggleTransferPaymentSection} from "../../redux/actions"
//CONTAINERS

// MATERIAL UI
import {makeStyles} from '@material-ui/core/styles';
import {Button, Box,Grid, IconButton,Typography} from '@material-ui/core';
import Classes from "../../../../styles";
import CloseIcon from '@material-ui/icons/CloseOutlined';

const useStyles = makeStyles((theme) => ({
    container:{
        padding: '12px 7px 12px 15px',
        background: theme.palette.grey['50']
    },
    pLTypePayment: {
        paddingLeft: '1em!important'
    },
    defaultShadowBtn: {
        boxShadow: "0px 0px 0px -0px rgba(0,0,0,0.0)",
    },
    shadowBtn: {
        boxShadow: ' 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
    },
    button: {
        height:'3.5em'
    },
    gridTypePayment:{
        padding:'5px 0'
    },
    sectionCard:{
        paddingTop: 10,
        height: '23em',
        [theme.breakpoints.only('sm')]: {
            height: '20em'
        }
       
    }
}));

const TransferPaymentSection = ({transferPaymentList, selectedTransferPaymentKey}) => {
    const origin = localStorage.getItem('origin')
    const [activeBtn, setActiveBtn] = React.useState(false);
    const [selectedPayment, setSelectPayment] = React.useState("");
    const dispatch = useDispatch();
    const classes = Classes();
    const useclasses = useStyles();
    const transferName = selectedTransferPaymentKey.charAt(0).toUpperCase() + selectedTransferPaymentKey.slice(1).toLowerCase();

    return (
        <Grid container item className={useclasses.container}>
            <Grid item xs={11}>
                <Typography variant="subtitle1" color="textSecondary">
                        {transferName} 
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <IconButton aria-label="settings"
                            style={{padding: '5px'}}
                            className={classes.buttons.btnCloseCardPanel}
                            onClick={()=> {
                                setSelectPayment("")
                                setActiveBtn(false)
                               dispatch(toggleTransferPaymentSection(false, null))
                }}>
                    <CloseIcon/>
                </IconButton>
               
            </Grid>
            <Grid item xs={12} className={useclasses.sectionCard}>
                <Grid container item spacing={1}>
                    <Grid item xs={12} style={{ maxHeight: '20em', overflow:'scroll'}}>
                        <Grid container item >
                            {
                                transferPaymentList ?
                                transferPaymentList.map((payType, index) => 
                                <Grid item xs={4} key={index + 1} className={useclasses.gridTypePayment}>
                                    <Button  className={`${useclasses.defaultShadowBtn} ${useclasses.button}`}
                                    variant={selectedPayment === payType.code ? "outlined" : "text"}
                                    color={selectedPayment === payType.code ? "secondary" : "default"}
                                    style={selectedPayment === payType.code ? {boxShadow: "1px 1px 1px #39CDC8, 0 0 10px #39CDC8, 0 0 1px #39CDC8"} : {}}
                                    onClick={() => {
                                        setSelectPayment(payType.code)
                                        dispatch(setTransferPayment(payType))
                                        setActiveBtn(true)
                                    }}>
                                        <img src={payType.icon}
                                        style={{
                                            color: '#7a7a7a',
                                            maxWidth: '3.5em',
                                            maxHeight: '2.5em',
                                        }}/>
                                    </Button> 
                                </Grid> )
                                : null
                            } 
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <Button variant="contained" color="primary" disabled={!activeBtn} 
                            onClick={()=> {
                                dispatch(setPaymentType(selectedPayment, true))
                                dispatch(toggleTransferPaymentSection(false, selectedTransferPaymentKey))
                            }}>save</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        transferPaymentList: getSelectedPaymentList(state.paymentTypeSelected.transferPaymentSection.key),
        selectedTransferPaymentKey: state.paymentTypeSelected.transferPaymentSection.key
    }
}

export default connect(mapStateToProps)(TransferPaymentSection);
