import * as fromPmsEntities from '../../reducers/reservations'
import * as fromRooms from "../../reducers/rooms";
import {createSelector} from "reselect"
import {customersSelector} from "../../selector/customer.selector";

const getClient = (state, id) => fromPmsEntities.getClient(state.pmsEntities, id);
const getReservation = (state, id) => fromPmsEntities.getReservation(state.pmsEntities, id);
const getClientCriteria = (state) => state.clientCriteria;
const getRoomsReservation = (state) => fromPmsEntities.getRoomsReservation(state.pmsEntities);
const getRoom = (state, id) => fromRooms.getRoom(state.rooms, id);

function _filterClient(reservations, criteria = null) {
    if (!criteria) return reservations;
    return reservations.filter(criteria);
}

export const filterClient = (state) => {
    const {criteria, value, futureReservations} = getClientCriteria(state);
    let finalReservations
    switch (criteria) {
        case "room":
            const roomReservation = _filterClient(
                getRoomsReservation(state),
                roomReservation => roomReservation.roomId.split('-')[0] === value
            );
            finalReservations = getClientList(roomReservation, state);
            break;
        case "staff":
            finalReservations = getCustomers(state);
            break;
        case "volunteer":
            finalReservations = getVolunteers(state);
            break;
        case 'guest':
            finalReservations = customersSelector(state);
            break;
        case 'all':
            const customers = customersSelector(state);
            const concatenated = customers.concat(getCustomers(state)).concat(getVolunteers(state));
            finalReservations = concatenated;
            break;
        default:
            finalReservations = []
            break;
    }

    let filtered_reservations = []
    if (!futureReservations) {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1;
        var yyyy = today.getFullYear();
        if(dd <10){dd='0'+dd};
        if(mm<10){mm='0'+mm};
        var today_date=yyyy+'-'+mm+'-'+dd;
        filtered_reservations = finalReservations.filter(reservation => {
            if(reservation["checkInDate"]){
                return (reservation["checkInDate"] === today_date || reservation["status"] === 'checked_in' )
            }
        })
    }
    if (filtered_reservations.length > 0) {
        return filtered_reservations
    } else {
        return finalReservations
    }
};

function getClientList(reservations, state) {
    if (!reservations) return [];
    let existingBeds = [];
    return reservations.reduce((clients, roomReservation) => {
        const reservation = getReservation(state, roomReservation.reservationId);
        const room = getRoom(state, roomReservation.roomId.split('-')[0]);
        return clients.concat(reservation.customers.map(customerId => {
            const client = getClient(state, customerId);
            if (client.variations) {
                if (client.roomName === room.number) {
                    if (existingBeds.includes(client.roomNumber)) {
                        const variationsReds = client.variations.filter(variation => !existingBeds.includes(variation.roomNumber));
                        let alternateVariation = []
                        if (variationsReds.length === 0) {
                            alternateVariation = client.variations[0]
                        } else {
                            alternateVariation = variationsReds[0]
                        }
                        const processedClient = Object.assign(
                            {},
                            alternateVariation,
                            room,
                            {reservationId: roomReservation.reservationId, groupId: reservation.groupId});
                        if (!existingBeds.includes(alternateVariation.roomNumber)) {
                            existingBeds.push(variationsReds[0].roomNumber);
                        }
                        return processedClient
                    } else {
                        const processedClient = Object.assign(
                            {},
                            client,
                            room,
                            {reservationId: roomReservation.reservationId, groupId: reservation.groupId});
                        existingBeds.push(client.roomNumber);
                        return processedClient
                    }
                } else {
                    const selectedVariation = client.variations.filter(variation => {
                        return variation.roomName === room.number;
                    });
                    const clientVariation = Object.assign({},
                        selectedVariation[0],
                        room,
                        {reservationId: roomReservation.reservationId, groupId: reservation.groupId})
                    return clientVariation;
                }
            } else {
                const normalClient = Object.assign({},
                    client,
                    room,
                    {reservationId: roomReservation.reservationId, groupId: reservation.groupId});
                return normalClient
            }
        }))
    }, [])
}


export function getCustomers(state) {
    const customers = state.customerSettings.customers;
    const auxCustomers = Object.values(customers).filter((volunteer) =>
        volunteer.pmsGuestId === null
    );
    return auxCustomers
}

export function getVolunteers(state) {
    const volunteers = state.customerSettings.customers;
    const auxVolunteers = Object.values(volunteers).filter((volunteer) =>
        volunteer.pmsGuestId !== null
    );

    return auxVolunteers
}

export const getRooms = createSelector(
    [
        state => state.rooms.allIds,
        state => state.rooms.byId,
    ],
    (roomsIds, rooms) => {
        return roomsIds.map(roomId => rooms[roomId])
    }
);


