import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    table:{
        '&.white-table':{
            '& .MuiTableCell-stickyHeader': {
                backgroundColor: '#ffffff!important'
            },
        },
        '&.grey-table':{
            backgroundColor: theme.palette.grey[50],
            '& .MuiTableCell-stickyHeader': {
                backgroundColor:theme.palette.grey[50]
            }
        }
    },
    greyTable:{// borrar -->verificar en tablas antes de borrar
        backgroundColor: theme.palette.grey[50],
        '& .MuiTableCell-stickyHeader': {
            backgroundColor:theme.palette.grey[50]
        }
    },
    tableCell:{
        fontSize:'0.9em!important',
        '&.leftSide':{
            textAlign:"left"
        },
        '&.rightSide':{
            textAlign:"right"
        },
        '&.center':{
            textAlign:"center"
        }
    },
    rowHighlight:{
        backgroundColor: theme.palette.grey[50],
    },
    td:{
        borderBottom: 'none!important'

    },
    bottomSection:{
        marginBottom: '0.8em'
    },
    btnView:{
        fontSize: '0.8em!important',
        lineHeight: '1em',
        padding:"2px 14px",
        width: "1em"
    },
    maxHeightTable: {
        maxHeight: 440,
    },
    hiddenColumn:{
        display:"none !important"
    },
    rowInput:{
        width:"100px"
    },
    cell:{
        '&.align-right':{
            textAlign: 'right'
        },
        '&.align-left':{
            textAlign: 'left'
        },
        '&.align-center':{
            textAlign: 'center'
        },
        '&.sm-noneBorder':{
            [theme.breakpoints.down('sm')]: {
                borderBottom: 'none!important',
            },
        },
        '& .sm-cell-right':{
            [theme.breakpoints.down('sm')]: {
                textAlign: 'right',
                paddingRight: 20
            },
        },
        '& .cell-tittle':{
            color: theme.palette.grey['600']
        },
        '& .cell-accordion-tittle':{
            paddingTop: 5, 
            color: theme.palette.text.primary, 
            fontWeight:500,
            '&.center-tittle-acco':{
                textAlign:'center'
            },
            '&.right-tittle-acco':{
                textAlign:'right'
            }
        },
        '& .cell-accordion-icon':{
            lineHeight:3, 
            textAlign:'right', 
            paddingRight: 5,
           
        }
       
    }
}));