import React from "react";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";

// COMPONENTS MATERIAL UI
import {IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
// CSS
import {buttons, main, tables} from '../../../../styles';
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import {handleDynamicDialog} from "../../../common/actions/actions"
import NullMessage from '../../../common/components/error.pages/NullMessage'


export default function TableConfig(props) {
    const classes = {
        main: main(),
        tables: tables(),
        buttons: buttons()
    };

    const dispatch = useDispatch();
    const [page, setPage] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [modalId, setModalId] = React.useState(null);
    const [modalOrigin, setModalOrigin] = React.useState(null);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const rows = props.entries || [];
    const columns = props.columns || [];
    const edit = props.edit || false;
    const remove = props.remove || false;

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleClickOpen = (id, origin) => {
        setOpen(true);
        setModalId(id);
        setModalOrigin(origin);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Paper className={`general-styles- ${classes.main.shadow1}`}>
            <TableContainer style={{maxHeight: "25em"}}>
                <Table stickyHeader size="small" aria-label="sticky table"
                       className={`general-tables- ${classes.tables.table} white-table`}>
                    <TableHead>
                        <TableRow>
                            {columns.map(function (column, index) {
                                if (index === 0) {
                                    return (
                                        <TableCell key={index + 1} align="center"
                                                   className={classes.tables.hiddenColumn}>{column}</TableCell>
                                    )
                                }
                                return (
                                    <TableCell key={index + 1} align="center">{column}</TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows.length ?
                                rows.map((row, index) => (
                                        <TableRow key={index + 1} hover role="checkbox" tabIndex={-1}
                                                  className={`${classes.main.animations} ${props.selectedItem === row.id && props.animation + " active"}`}>
                                            {
                                                Object.values(row).map(function (value, index) {
                                                    if (index === 0) {
                                                        return (
                                                            <TableCell key={index} align="center"
                                                                       className={classes.tables.hiddenColumn}>{value}</TableCell>
                                                        )
                                                    }
                                                    return (
                                                        <TableCell key={index} align="center">{value}</TableCell>
                                                    )
                                                })
                                            }
                                            <TableCell align="center">
                                                {edit &&
                                                    (props.canManage ?
                                                        (row.id === null ?
                                                            <IconButton variant="contained" disabled
                                                                        className={`${classes.buttons.actionButton} edit`}>
                                                                <EditOutlinedIcon/>
                                                            </IconButton>
                                                            :
                                                            <Link to={props.link}>
                                                                <IconButton variant="contained"
                                                                            className={`${classes.buttons.actionButton} edit`}
                                                                            onClick={() => {
                                                                                props.edit(row.id)
                                                                            }}>
                                                                    <EditOutlinedIcon/>
                                                                </IconButton>
                                                            </Link>)
                                                        :
                                                        <IconButton variant="contained"
                                                                    disabled
                                                                    className={`${classes.buttons.actionButton} `}
                                                        >
                                                            <EditOutlinedIcon/>
                                                        </IconButton>)
                                                }
                                                {remove &&
                                                    (props.canManage ?
                                                        <IconButton variant="contained"
                                                                    className={`${classes.buttons.actionButton} delete`}
                                                                    onClick={() => dispatch(handleDynamicDialog(props.entity, row.id))}
                                                        >
                                                            <DeleteIcon color="error"/>
                                                        </IconButton> :
                                                        <IconButton variant="contained"
                                                                    disabled
                                                                    className={`${classes.buttons.actionButton} `}
                                                        >
                                                            <DeleteIcon/>
                                                        </IconButton>)
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                ) :
                                <TableRow>
                                    <TableCell colSpan={9} align="center">
                                        {props.entity === "volunteer" ?
                                            <NullMessage message={"There are no volunteers"} height={"10em"}/>
                                            :
                                            <NullMessage message={"There are no " + props.entity} height={"10em"}/>
                                        }
                                    </TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}