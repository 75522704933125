import React, {useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import {useRouteMatch} from "react-router-dom";

import {cleanCustomerFilter} from "../../../pos/redux/actions";
import {filterSearchMultiple} from '../../actions/utilsActions';
import {userDataRequest} from "../../actions/asyncActions";
import {close_shift} from "../../../rumi-settings/permissionsConstants";

import {makeStyles} from "@material-ui/core/styles";
import {Avatar, IconButton, Tooltip} from '@material-ui/core';
import CurrentUserProfile from "../profile/currentUserProfile";
import SubMenu from './SubMenu'
import CloseShiftView from "../../../pos/containers/balance/CloseShiftView"
// DEVELOP BRANCH REQUIRE: import BalanceTransfer from "../modals/BalanceTransfer";
import AccountIcon from '@material-ui/icons/AccountCircleOutlined';
import ExitIcon from '@material-ui/icons/ExitToAppOutlined';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
// DEVELOP BRANCH REQUIRE: import LastPageIcon from '@material-ui/icons/LastPage';

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize: '1em',
        fontWeight: "410",
    }
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const useStyles = makeStyles((theme) => ({
    avatar: {
        textTransform: 'capitalize',
        backgroundColor: theme.palette.grey['main']
    },
    iconBtn: {
        padding: "14px"
    }
}));

const UserItem = ({username, color, permissionsList}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    let {path} = useRouteMatch();
    const permissions = Object.values(permissionsList);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const submenuUser = [
        {
            url: 'modal',
            name: 'User profile',
            icon: <AccountIcon/>,
            body: <CurrentUserProfile/>,
            enableButton: true
        },
        // { DEVELOP BRANCH REQUIRE:
        //     url: 'modal',
        //     name: 'Transfer',
        //     icon: <LastPageIcon />,
        //     body: <BalanceTransfer/>,
        //     enableButton:true
        // },
        {
            url: 'modal',
            name: 'Close shift',
            icon: <SaveAltIcon/>,
            body: <CloseShiftView/>,
            enableButton: permissions.includes(close_shift) || permissions.includes("superuser"),
        },
        {
            url: `${path}/logout`,
            name: 'Log out',
            icon: <ExitIcon/>,
            enableButton: true
        }
    ];

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const cleanEvents = () => {
        dispatch(cleanCustomerFilter())
        dispatch(filterSearchMultiple(""))
    };

    useEffect(() => {
        if ((typeof username === undefined || username === "") && (typeof color === undefined || color === "")) {
            dispatch(userDataRequest());
        }
    }, [username, color, dispatch]);

    return (
        <React.Fragment>
            <BootstrapTooltip title={username !== undefined ? username : "user"} placement="right">
                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                    className={classes.iconBtn}>
                    <Avatar className={classes.avatar} style={color ? {backgroundColor: color} : {}}>
                        {username ? username.charAt(0) : ""}
                    </Avatar>
                </IconButton>
            </BootstrapTooltip>
            <SubMenu
                position={{left: '6.2em'}}
                subItems={submenuUser}
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
                cleanEvents={cleanEvents}
            />
        </React.Fragment>
    )
};

const mapStateToProps = (state) => ({
    username: state.auth.userData.username,
    color: state.auth.userData.color,
    permissionsList: state.auth.permissionsList,
});

export default connect(mapStateToProps,)(UserItem)
