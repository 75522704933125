import {makeStyles} from '@material-ui/core/styles';


export default makeStyles((theme) => ({
    /* new Clases Custom */
    btnCloseCardPanel: {
        '& span > svg': {
            fontSize: '1.2rem'
        }
    },
    btnTitlePaymentPanel: {
        '& :hover': {
            color: theme.palette.primary.main
        },
        color: theme.palette.grey[600],
    },
    categoryButton: {
        fontSize:"0.8em",
        '&.selected': {
            background: theme.palette.secondary.main
        }
    },
    clientButton: {
        width: '33.33%'
    },
    boxButton: {
        borderWidth: "1px",
        borderColor: "rgba(57, 205, 200, 0.5)",
        borderStyle: "solid",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        height:'8em',
        '&:hover':{
            backgroundColor:"rgba(57, 205, 200, 0.04)",
        },
        '&.roomBox': {
            width: '98%',
            height:'8em'
        },
        '&.productBox': {
            width: '98%',
            height: "9.4em",
            padding:"1em"
        },
        '&.settings':{
            width: '98%',
            height:'3em'
        }
    },
    boxInfo:{
        '& > p.mainText': {
            fontSize:"2em",
            lineHeight:"1.4em",
            color:theme.palette.secondary.main,
            textTransform: "capitalize !important",
            '&.products':{
                fontSize:"1em",
                lineHeight:"1em",
                textTransform: "capitalize !important",
            },
            '&.settings':{
                fontSize:"1em",
                marginTop: "0em",
                lineHeight:"1em",
                textTransform: "capitalize !important",
            },
        },
        '& > p.extraData':{
            marginTop: "0.5em",
            fontSize:"0.9em",
            lineHeight:"1em",
            fontWeight:"bold",
            color:theme.palette.grey[600],
            textTransform: "capitalize !important",
        },
        '& > p.subText': {
            fontSize:"0.8em",
            color:theme.palette.grey[400],
            textTransform: "capitalize !important",
        }
    },
    roundPillButton: {
        margin: '0.5em',
        padding: '0.8em',
        borderRadius: '20px',
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        '&.filter': {
            margin: '0 0.5em 0 0',
            padding: '3px 12px',
            boxShadow: '0px 1px 0px -1px rgba(0,0,0,0.2), 0px 1px 5px 0px rgba(0,0,0,0.14)!important',
            '&:hover': {
                backgroundColor: 'white',
                color: theme.palette.secondary.main,
                borderColor:theme.palette.secondary.main
            },
        },
        '&:hover': {
            color: theme.palette.secondary.main
        },
    },
    roundPillButtonPrimary: {
        margin: '0.5em',
        padding: '0.8em',
        borderRadius: '20px',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&.filter': {
            margin: '0 0.5em 0 0',
            padding: '3px 12px',
            boxShadow: '0px 1px 0px -1px rgba(0,0,0,0.2), 0px 1px 5px 0px rgba(0,0,0,0.14)!important',
            '&:hover': {
                backgroundColor: 'white',
                color: theme.palette.primary.main,
                borderColor:theme.palette.primary.main
            },
        },
        '&:hover': {
            color: theme.palette.primary.main
        },
    },
    roundPillText: {
        fontSize: "16px",
        fontWeight: "initial",
        textTransform: "capitalize !important",
    },
    actionButton:{
        '&.add':{
            background: theme.palette.primary.main,
            color:theme.palette.common.white,
            width:"62%"
        },
        '&.edit':{
            color:"#F5BC00"
        },
        '&.delete':{
            color:theme.palette.error.main,
        },
        '&.MuiButtonBase-root.Mui-disabled': {
            cursor: "not-allowed",
            pointerEvents: "initial",
            filter: 'opacity(.5)'
        }
    },
    
    disabledButton : {
        '&.MuiButtonBase-root.Mui-disabled': {
            cursor: "not-allowed",
            pointerEvents: "initial",
            //filter: 'opacity(.5)'
        }
    },
    saveBtn:{
        '&.inactive':{
            pointerEvents: 'none', 
            backgroundColor:'#DCDCDC', 
            color:'rgba(0, 0, 0, 0.26)', 
            boxShadow:'none',
        }
    }
   
}))