import React from "react";
import {Link} from "react-router-dom";
// COMPONENTS MATERIAL UI
import {
    Box,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import NullMessage from '../../../common/components/error.pages/NullMessage'
// CSS
import Classes from '../../../../styles';

export default function ExchangeRateTable(props) {
    const classes = Classes();
    const rows = props.entries || [];
    const columns = props.columns || [];
    const edit = props.edit || false;

    const inactiveSecondCurrency = rows.exchangeRate === null

    return (
        <Paper className={`general-styles- ${classes.styles.shadow1}`}>
            <TableContainer style={{maxHeight: "440px"}}>
                <Table stickyHeader size="small" aria-label="sticky table"
                       className={`general-tables- ${classes.tables.table} white-table`}>
                    <TableHead>
                        <TableRow>
                            {columns.map(function (column, index) {
                                return (
                                    index === 2 ?
                                        !inactiveSecondCurrency &&
                                        <TableCell key={index + 1} align="center">{column}</TableCell>
                                        : <TableCell key={index + 1} align="center">{column}</TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows ?
                                <TableRow>
                                    <TableCell align="center" colSpan={2}>
                                        <Box display="flex">
                                            <Box flexGrow={1} width={"70%"} pr={6}>
                                                <Box style={{padding: "0.8em 0"}}>{rows.primary.code}</Box>
                                                {
                                                    !inactiveSecondCurrency &&
                                                    <Box style={{padding: "0.8em 0"}}>{rows.secondary.code}</Box>
                                                }

                                            </Box>
                                            <Box width={"30%"}>
                                                <Box
                                                    style={{padding: "0.8em 0"}}>{rows.primary.isMain && "Primary"}</Box>
                                                {
                                                    !inactiveSecondCurrency &&
                                                    <Box
                                                        style={{padding: "0.8em 0"}}>{!rows.secondary.isMain && "Secondary"}</Box>
                                                }
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    {
                                        !inactiveSecondCurrency &&
                                        <TableCell align="center">{Number(rows.exchangeRate).toFixed(2)}</TableCell>
                                    }
                                    <TableCell align="center">
                                        {props.canManage ?
                                            <Link to={`${props.origin}exchange-rate`}>
                                                <IconButton variant="contained"
                                                            className={`${classes.buttons.actionButton} edit`}
                                                            onClick={() => {
                                                                props.edit()
                                                            }}>
                                                    <EditOutlinedIcon/>
                                                </IconButton>
                                            </Link> :
                                            <IconButton variant="contained"
                                                        disabled
                                                        className={`${classes.buttons.actionButton} `}>
                                                <EditOutlinedIcon/>
                                            </IconButton>
                                        }
                                    </TableCell>
                                </TableRow> :
                                <TableRow>
                                    <TableCell colSpan={9} align="center">
                                        <NullMessage message={"There are no" + props.entity} height={"10em"}/>
                                    </TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}