import {LOAD_CATEGORIES, SET_CATEGORY} from "../redux/actionTypes";

const initialState = {
    currentCategory: 1,
    all: [],
    isLoading:true
}
export default function (state = initialState, action) {

    switch (action.type) {
        case LOAD_CATEGORIES: {
            return { ...state, all: action.categories,isLoading: false}
        }
        case SET_CATEGORY: {
            return { ...state, currentCategory: action.categoryId}
        }
        default:
            return state
    }
}

