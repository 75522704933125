import React,{useState} from 'react';
import {useDispatch} from "react-redux";
// MATERIAL UI
import {makeStyles} from '@material-ui/core/styles';
import {ListItem, ListItemText, Box} from '@material-ui/core';
// ICONS
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import {handleDynamicDialog} from "../../../common/actions/actions"
import {main} from '../../../../styles';

const useStyles = makeStyles((theme) => ({
    listItem: {
        borderRadius: 25,
        margin: '0.6em 0',
        height: 36,
        padding: '0 0 0 1em!important',
        backgroundColor: theme.palette.secondary.main,
        cursor: "hand",
        color: "#ffffff",
        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        '& span':{
            fontSize: '1em !important',
            textTransform: "capitalize",
        },
        '&.primary': {
            backgroundColor: theme.palette.primary.main,
            "&.primary:hover":{
                backgroundColor: "#1CB039",
                boxShadow: "px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
            }
        },
        '&.secondary': {
            backgroundColor: theme.palette.secondary.main,
            "&.secondary:hover":{
                backgroundColor: "#29A39F",
                boxShadow: "px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
            }
        },
        '&.outlined-primary': {
            backgroundColor: "#ffffff",
            border: "1px solid" + theme.palette.primary.main,
            color: theme.palette.primary.main,
            "&.outlined:hover": {
                border: "1px solid" + theme.palette.primary.main,
                color: theme.palette.primary.main,
                boxShadow: "px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
            }
        },
        '&.outlined-secondary': {
            backgroundColor: "#ffffff",
            border: "1px solid" + theme.palette.secondary.main,
            color: theme.palette.secondary.main,
            "&.outlined:hover": {
                border: "1px solid" + theme.palette.secondary.main,
                color: theme.palette.secondary.main,
                boxShadow: "px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
            }
        },
        '&.outlined-error': {
            backgroundColor: "#ffffff",
            border: "1px solid" + theme.palette.error.main,
            color: theme.palette.error.main,
            "&.outlined:hover": {
                border: "1px solid" + theme.palette.error.main,
                color: theme.palette.error.main,
                boxShadow: "px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
            }
        }
    },
    boxNew: {
        padding: "3px 10px", 
        borderRadius: 20, 
        marginRight: 7,
        "&.primary":{
            backgroundColor: "#ffffff",
            color: theme.palette.primary.main
        },
        "&.secondary":{
            backgroundColor: "#ffffff",
            color: theme.palette.secondary.main
        },
        "&.contained-primary":{
            backgroundColor: theme.palette.primary.main,
            color: "#ffffff"
        },
        "&.contained-secondary":{
            backgroundColor: theme.palette.secondary.main,
            color: "#ffffff"
        }
    },
    positionCategory: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0
    }
}));

const Category = (props) => {
    const classes =  { 
        main: main()
    }
    const styles = useStyles();
    const dispatch = useDispatch()
    const [ eventDraggable, setEventDraggable] = useState(false)
    const products = props.allProducts.filter(product => product.category === props.category.id)
    const isHereProducts = products[0]
    const noDragProducts = props.dropProductList === undefined || props.dropProductList.length === 0

    const handleClick = (categoryId, categoryName) => {
        props.filterSearchMultiple("")
        props.selectCategory(categoryId,categoryName)
        props.getCreatedProduct(false)
    }

    const handleEdit = (category, flag, action) => {
        props.handleShowCategoryForm(flag)
        props.toggleCreateEditCategory(action)
        props.edit(category)
    }

    const eventDrop = (e) => {
        props.onDrop(e, props.category.id, props.category.name)
        setEventDraggable(false)
    }

    return (
            props.canManage ? 
            <div className={ `${props.category.id === props.newCategoryId && styles.positionCategory} `} >
                <ListItem className={`${styles.listItem} ${ eventDraggable && ( props.selectedCategory ? 'outlined-error' : 'outlined-primary') } ${ props.selectedCategory ? 'secondary' : "primary" } ${classes.main.animations} ${props.selectedCategoryResponse === props.category.id && props.animation  + " active"}`}
                    button onClick={() => handleClick(props.category.id, props.category.name)}>
                    <ListItemText 
                        onDragOver={(e) => props.onDragOver(e)}
                        onDrop={(e)=> eventDrop(e)}
                        onDragEnter={()=>  setEventDraggable(true)}
                        onDragLeave={()=>  setEventDraggable(false)}
                        primary={props.category.name} />
                    {
                    (!props.category.products &&  (props.category.id === props.categoryMaxId)) &&
                        <Box  className={`${styles.boxNew} ${props.selectedCategory ? "secondary" : "primary" } ${ eventDraggable && ( props.selectedCategory ? 'contained-secondary' : 'contained-primary') } ` }
                        >New</Box>
                    }
                    {
                        
                        !isHereProducts ? 
                            noDragProducts ? 
                            <IconButton style={{padding: 7}} onClick={()=>  dispatch(handleDynamicDialog("category",props.category.id))}>
                                <DeleteIcon style={{color: eventDraggable ?  "#EC4262" : '#ffffff'}} />
                            </IconButton> : 
                            <IconButton style={{padding: 7, cursor: 'not-allowed'}} 
                            onMouseEnter={()=> props.activeAlertDragAndDrop(true)}
                            onMouseLeave={()=> props.activeAlertDragAndDrop(false)}>
                                    <DeleteIcon style={{color: eventDraggable ?  "#EC4262" : '#ffffff'}} />
                            </IconButton>
                        : null 
                        
                    }
                    <IconButton style={{padding: 7}} onClick={() => {handleEdit(props.category, true, 2)}}  >
                        <EditIcon style={{color: eventDraggable ? "#F5BC00" :'#ffffff'}} />
                    </IconButton>
                </ListItem>
            </div> 
            :   <ListItem className={`${styles.listItem} ${props.selectedCategory ? 'secondary' : "primary" } `} button onClick={() => handleClick(props.category.id, props.category.name)}  >
                    <ListItemText primary={props.category.name} />
                </ListItem>

    )
};

export default Category