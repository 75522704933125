import React, {Fragment, useState} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from "@material-ui/core/TablePagination";
// CSS
import Classes from '../../../../styles';
import {
    Box,
    Button,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    Hidden,
    Typography
} from "@material-ui/core";
import NullMessage from '../../../common/components/error.pages/NullMessage'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterListIcon from '@material-ui/icons/FilterList';

export default function MultiTable(props) {
    const classes = Classes();

    const rows = props.report ? props.report : [];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [changeColumnShow, setChangeColumnShow] = useState("Amt");
    const [colSpanForHideColumns, setColSpanForHideColumns] = useState(8);
    const subHeaders = {
        "guestAmount": {
            "title": "Guest Amt.",
            "display": "Amt"
        },
        "guestQuantity": {
            "title": "Guest Qty.",
            "display": "Qty"
        },
        "staffAmount": {
            "title": "Staff Amt.",
            "display": "Amt"
        },
        "staffQuantity": {
            "title": "Staff Qty.",
            "display": "Qty"
        },
        "volunteerAmount": {
            "title": "Volunteer Amt.",
            "display": "Amt"
        },
        "volunteerQuantity": {
            "title": "Volunteer Qty.",
            "display": "Qty"
        },
        "clientAmount": {
            "title": "Client Amt.",
            "display": "Amt"
        },
        "clientQuantity": {
            "title": "Client Qty.",
            "display": "Qty"
        }
    };

    const handleChangeColumnShow = (columnIdentifier) => {
        if (columnIdentifier === "") {
            setColSpanForHideColumns(8);
        } else {
            setColSpanForHideColumns(4)
        }
        setChangeColumnShow(columnIdentifier);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const commonHeaders = ["Product", "Outlet", "Total Quantity", "Total Amount"];
    const allHeaders = ["Product", "Outlet", "Total Quantity", "Total Amount", "Guest Amt.","Guest Qty.","Staff Amt.", "Staff Qty.","Volunteer Amt.","Volunteer Qty.","Client Amt.","Client Qty.",]
    
    return (
            <Paper elevation={0} className={`${classes.styles.shadow1}`}>
                <TableContainer style={{maxHeight: 440}}>
                    <Hidden smDown>
                        <Table stickyHeader size="small" aria-label="spanning table" className={`${classes.tables.table} white-table`}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="right">Product</TableCell>
                                    <TableCell align="right">Outlet</TableCell>
                                    <TableCell align="right">Total Quantity</TableCell>
                                    <TableCell align="right">Total Amount</TableCell>
                                    <TableCell align="center" colSpan={colSpanForHideColumns} >
                                        <Grid container item>
                                            <Grid item xs={4}>
                                                <Button style={changeColumnShow === "" ? {backgroundColor: 'rgba(57, 205, 200, 0.04)'}: {}}
                                                        color={changeColumnShow === "" ? "secondary" : "default"} fullWidth
                                                        onClick={() => handleChangeColumnShow("")}>
                                                        <Typography variant="body1" className={classes.buttons.roundPillText}>
                                                            All
                                                        </Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button style={changeColumnShow === "Amt" ? {backgroundColor: 'rgba(57, 205, 200, 0.04)'}: {}}
                                                        color={changeColumnShow === "Amt" ? "secondary" : "default"} fullWidth
                                                        onClick={() => handleChangeColumnShow("Amt")}>
                                                        <Typography variant="body1" className={classes.buttons.roundPillText}>
                                                            Amounts
                                                        </Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button style={changeColumnShow === "Qty" ? {backgroundColor: 'rgba(57, 205, 200, 0.04)'}: {}}
                                                        color={changeColumnShow === "Qty" ? "secondary" : "default"} fullWidth
                                                        onClick={() => handleChangeColumnShow("Qty")}>
                                                        <Typography variant="body1" className={classes.buttons.roundPillText}>
                                                            Quantities
                                                        </Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    {
                                        Object.values(subHeaders).map((value, index) => {
                                            if (changeColumnShow === "" || changeColumnShow === value.display) {
                                                return (
                                                    <TableCell key={index} align="right">{value.title}</TableCell>
                                                )
                                            } else {
                                                return (
                                                    <TableCell key={index} align="right" style={{display: "none"}}>
                                                        {value.title}
                                                    </TableCell>
                                                )
                                            }
                                        })
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    rows.length ?
                                        rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                            return (
                                            <TableRow key={index + 1} hover role="checkbox" tabIndex={-1} >
                                                {Object.values(row).map((value, index) => {
                                                        if (changeColumnShow === "" || index < 4) {
                                                            return (
                                                                index === 3 ? <TableCell key={index}
                                                                align="right">{value.toFixed(2)}</TableCell> : 
                                                                (index > 3 && index % 2 === 0 ?
                                                                <TableCell key={index}
                                                                        align="right">{value.toFixed(2)}</TableCell>
                                                                    :   <TableCell key={index}
                                                                        align="right">{value}</TableCell>)
                                                            )
                                                        } else {
                                                            if (changeColumnShow === "Qty" && !!(index % 2) === true) {
                                                                return (
                                                                    <TableCell key={index}
                                                                            align="right" >{value}</TableCell>
                                                                )
                                                            } else if (changeColumnShow === "Amt" && !!(index % 2) === false) {
                                                                return (
                                                                    <TableCell key={index}
                                                                            align="right">{value.toFixed(2)}</TableCell>
                                                                )
                                                            } else {
                                                                return (
                                                                    <TableCell key={index} align="right"
                                                                            style={{display: "none"}}>
                                                                        {value}
                                                                    </TableCell>
                                                                )
                                                            }
                                                        }
                                                    }
                                                )}
                                            </TableRow>   
                                        )}) :
                                        <TableRow tabIndex={-1}>
                                            <TableCell align="center" colSpan={12}
                                            >
                                                <NullMessage message={"There are no ranking report"} height={"10em"}/>
                                            </TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </Hidden>
                    <Hidden mdUp>
                    { rows.length ?
                        rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                        return (
                        <ExpansionPanel key={index + 1} style={{margin: 0}}>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                                <Grid container item >
                                    <Grid item xs={6} sm={4} >
                                        <Typography variant="caption" style={{fontSize: 10, textAlign:'center'}} display="block" color="textSecondary">{commonHeaders[Object.keys(commonHeaders)[0]]}</Typography>
                                        <Typography variant="body2" style={{ textAlign:'center'}} display="block">{Object.values(rows[index])[0]}</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={4} >
                                        <Typography variant="caption" style={{fontSize: 10, textAlign:'center'}} display="block" color="textSecondary"> {commonHeaders[Object.keys(commonHeaders)[3]]}</Typography>
                                        <Typography variant="body2" style={{  textAlign:'center'}} display="block">{Object.values(rows[index])[3].toFixed(2)}</Typography></Grid>
                                    <Hidden only="xs">
                                        <Grid item sm={4} >
                                            <Typography variant="caption" style={{fontSize: 10, textAlign:'center'}} display="block" color="textSecondary">{commonHeaders[Object.keys(commonHeaders)[2]]}</Typography>
                                            <Typography variant="body2" style={{  textAlign:'center'}} display="block">{Object.values(rows[index])[2]}</Typography>
                                        </Grid>
                                    </Hidden>
                                </Grid>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{borderTop: `2px solid rgba(0,0,0,0.14)`,borderBottom: `2px solid rgba(0,0,0,0.14)`}}>
                                <Grid container spacing={2} direction="row"
                                        justify="space-between" alignItems="center">
                                    { Object.values(row).map((value, index) => (
                                        index === 2 ?
                                        <Hidden smUp key={index +1}>
                                            <Grid container item  >
                                                <Grid item xs={6} sm={4} style={{textAlign: "left", color:'#9E9E9E'}}>
                                                {allHeaders[Object.keys(allHeaders)[index]]}
                                                </Grid>
                                                <Grid item xs={6} sm={8} style={{textAlign: "right"}}>
                                                {value}
                                                </Grid>
                                            </Grid> 
                                        </Hidden> : 
                                        <Grid container item key={index +1} style={index === 0 || index === 3 ? {display:'none'}: {}}>
                                            <Grid item xs={6} sm={4} style={{textAlign: "left", color:'#9E9E9E'}}>
                                            {allHeaders[Object.keys(allHeaders)[index]]}
                                            </Grid>
                                            <Grid item xs={6} sm={8} style={{textAlign: "right"}}>
                                            {index > 3 ? value.toFixed(2) : value}
                                            </Grid>
                                        </Grid> 
                                    ))}
                                  
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        ) }) : <NullMessage message={"There are no sales"} height={"10em"}/>
                    } 
                    </Hidden>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows ? rows.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
    );
}