export const englishTexts = {
    "areYouAclient": "Are you a client?",
    "login": "Login",
    "mainPresentation": "A new way of billing with precise, legal and automated information.",
    "contactUsButton": "Contact us",
    "billingPresentation": "Perfect for billing with",
    "hostels": "Hostels",
    "hotels": "Hotels",
    "boutiques": "Hotels Boutiques",
    "apartHotels": "Apart Hotels",
    "bAndB": "B&B",
    "cabins": "Cabins",
    "ourProducts": "Our Products",
    "billingText": "Lorem ipsum",
    "posText": "Lorem ipsum",
    "knowMore": "Know more",
    "testimonial": "Testimonial text",
    "howDoesItWork": "How does it work",
    "firstScreenShotHeader": "Lorem Ipsum",
    "firstScreenShotText": ["Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna."],
    "secondScreenShotHeader": "Lorem Ipsum",
    "secondScreenShotText": ["Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna."],
    "thirdScreenShotHeader": "Lorem Ipsum",
    "thirdScreenShotText": ["Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna."],
    "taxesText": "¿Puedes asegurar que tu hotel está usando todas las vías para el recupero de impuestos al final del año?",
    "letsWorkTogether": "Lets work together",
    "contactText": "Contact us",
    "copyRight": "Copyright"
};

export const spanishTexts = {
    "areYouAclient": "¿Ya eres cliente?",
    "login": "Entrar",
    "mainPresentation": "Una nueva forma de facturar con información precisa, normada y automatizada.",
    "contactUsButton": "Contáctanos",
    "billingPresentation": "Perfecto para facturar de forma electrónica con",
    "hostels": "Hostels",
    "hotels": "Hotels",
    "boutiques": "Hotels Boutiques",
    "apartHotels": "Apart Hotels",
    "bAndB": "B & B",
    "cabins": "Cabañas",
    "ourProducts": "Nuestros Productos",
    "billingText": "Detalla los consumos acumulados de tus clientes, para proceder con el cobro de alojamiento y/o alimentos y bebidas de RUMI POS, incluyendo impuestos y cargos considerando exoneraciones por ley.",
    "posText": "En cualquier punto de venta del establecimiento como bar, terraza, restaurante, patio, etc. puedes vender productos a tus clientes de forma directa o a cuenta incluyendo impuestos y cargos.",
    "knowMore": "Saber más",
    "testimonial": "“Las leyes de facturación en LATAM son complejas, cambiantes. Se vuelve difícil y caro si no se tiene una herramienta que procese cálculos precisos considerando excepciones de impuestos a extranjeros u otras condiciones especiales para cada país. ¡RUMI nos ha solucionado este problema!, ahorrando en 35% nuestros gastos de control de impuestos y evitando costosas multas.”",
    "howDoesItWork": "¿Como funciona?",
    "firstScreenShotHeader": "Configuración",
    "firstScreenShotText": [
        "Haz las configuraciones de tipos de documentos y series a generar. Los impuestos se cargan de forma automática, en función a las leyes del país.",
        "Crea diferentes tipos de cuenta para el acceso al sistema desde el usuario administrador.",
        "Con RUMI POS crea productos para la venta en diferentes categorías y decide si los impuestos son incluidos dentro o fuera del precio base.",
        "Crea precios especiales en productos de RUMI POS para staff y voluntarios."
    ],
    "secondScreenShotHeader": "Consumos",
    "secondScreenShotText": [
        "Selecciona huéspedes desde las reservas del PMS a quien cargar productos de RUMI POS a cuenta.",
        "Visualiza los consumos totales de los huéspedes con detalle impositivo para el cobro efectivo o con tarjeta (visa, mastercard) de su cuenta.",
        "Realiza venta de productos de RUMI POS a clientes externos."
    ],
    "thirdScreenShotHeader": "Ventas",
    "thirdScreenShotText": [
        "Reportes de cierre de caja. Considera doble moneda.",
        "Listado de comprobantes generados y validados, con número de series y correlativos.",
        "Ranking de los productos más vendidos.",
        "Reportes de ventas."
    ],
    "taxesText": "¿Puedes asegurar que tu hotel está usando todas las vías para el recupero de impuestos al final del año?",
    "letsWorkTogether": "Trabajemos juntos",
    "contactText": "¿Puedes asegurar que tu hotel está usando todas las vías para el recupero de IMPUESTOS al final del año?",
    "copyRight": "Copyright"
};