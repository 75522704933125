import { makeStyles } from '@material-ui/core/styles';

// BORRAR ARCHIVO
export default makeStyles((theme) => ({
    colorDisabled: {
        color: theme.palette.grey['600']
    },
    grey50:{// cambiar la clase a bgGrey50
        backgroundColor: theme.palette.grey[50]
    },
    textSecondary: {
        color: theme.palette.text.secondary
    },
    grey600:{
        color: theme.palette.grey[600]
    },
    bgPrymary50: {
        backgroundColor: theme.palette.secondary.light
    },
    /* custom classes */
    grey7a:{
        color: theme.palette.grey[600]
    },
    black:{
        color: theme.palette.common.black
    },

    bgWhite: {
        backgroundColor: theme.palette.common.white
    },
    borderSecondary:{
        border: '1px solid ' + theme.palette.secondary.main
    }
}));