import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux'
import {setClient, setPaymentType} from '../../redux/actions'
import AddClientIcon from "../../../../assets/icons/add-user.svg"
import {Link, useRouteMatch, withRouter} from 'react-router-dom';

// COMPONENTS
import ClientDetails from './ClientDetails';

// MATERIAL UI
import {Box, Typography} from '@material-ui/core/';
import {RUMI_LINK} from "../../../common/commonConstants";
import Classes from '../../../../styles/';


const steps_consumptions = [
    {
        label: 'Customers'
    },
    {
        label: 'Staff'
    },
];

const general_steps = [
    {
        label: 'Customers'
    },
    {
        label: 'External'
    }


]

const ClientPanel = ({
                         location,
                         optionClientType,
                         currentRoute,
                         customerType,
                         client,
                         isCompany,
                         selectedPaymentOption
                     }) => {
    const classes = Classes();
    const dispatch = useDispatch();
    let {path} = useRouteMatch();
    const isBillingApp = localStorage.getItem('origin') === RUMI_LINK;
    const currentPath = location.pathname;

    const [clientOptions, setClientOptions] = useState([]);
    const [route, setRoute] = useState("");
    const [count, setCount] = useState(0)

    useEffect(() => {
        if (isBillingApp) {
            setRoute("store");
            if (currentPath.includes("consumptions")) {
                setClientOptions(steps_consumptions)

            } else {
                setClientOptions(general_steps)
            }
        } else {
            setRoute("store");
            setClientOptions(general_steps)
        }
    }, [location]);


    const clientRoute = `${path}/clients`
    const redirecst = `${path}/${route}`

    return (
        optionClientType !== "" ?
            <ClientDetails optionClientType={optionClientType} clientRoute={clientRoute}
                           isBillingApp={isBillingApp}
                           setClient={setClient}
                           setPaymentType={setPaymentType}
                           customerType={customerType}
                           client={client}
                           isCompany={isCompany}
                           selectedPaymentOption={selectedPaymentOption}
            />
            :
            <Box p={2} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                <img src={AddClientIcon} style={{width: 45, height: 45, opacity: 0.5}}/>
                <Box display="flex" style={{color: '#9e9e9e'}} mt={1.5}>
                    <Typography variant="subtitle1">
                        <Link to={clientRoute} className={`${classes.nav.link}`}
                              style={currentRoute === `${path}/store` ? {color: "#5BE576"} : {
                                  pointerEvents: "none",
                                  color: '#9e9e9e'
                              }}
                        >
                            Customers
                        </Link>
                        &nbsp;&nbsp;&nbsp;or&nbsp;&nbsp;&nbsp;
                        <Link to={redirecst} className={`${classes.nav.link}`}
                              style={{color: "#5BE576"}} onClick={() => dispatch(setClient({}, "External"))}
                        >
                            External
                        </Link>
                    </Typography>
                </Box>
            </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        optionClientType: state.paymentTypeSelected.optionClientType,
        currentRoute: state.clientPanel.currentRoute,
        customerType: state.client.customerType,
        client: state.client,
        isCompany: state.paymentState.isCompany,
        selectedPaymentOption: state.paymentState.selectedPaymentOption
    }
};

export default connect(mapStateToProps)(withRouter(ClientPanel))