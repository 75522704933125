import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    /* new classes custom */
    bRadius10:{
        borderRadius:10
    },
    bRadius18: {
        borderRadius: 18
    },
    shadow1: {
        boxShadow: '0px 1px 0px -1px rgba(0,0,0,0.2), 0px 1px 5px 0px rgba(0,0,0,0.14)!important',
    },
    shadow2: {
        boxShadow: '0 1px 8px rgba(0,0,0,0.19), 0 0px 1px rgba(0,0,0,0.23)'
    },
    borderSize2:{
        borderSize:'2px'
    },
    '@global': {
        '*::-webkit-scrollbar': {
          width: '0.4em'
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.1)',
          borderRadius: '50px'
        }
    },

    animate:{
        transition: "opacity 1.5s, border 1.5s, color 1.5s",
        "&.opacity":{
            filter: 'opacity(.5)'
        },
        "&.border":{
            border: '1px solid #EC4262'  
        },
        "&.color":{
            color: "#EC4262"
        }
    },

    animations:{
        "&.delete": {
            transition: "opacity 4s",
            "::-webkit-transition": "opacity 0.5s ease-in-out",
            "::-moz-transition": "opacity 0.5s ease-in-out",
            "::-ms-transition": "opacity 0.5s ease-in-out",
            "::-o-transition": "opacity 0.5s ease-in-out",       
            "opacity": 1,
            "&.active":{
                "opacity": 0,
            }
        }
    }
    
}));