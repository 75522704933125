import {
    GET_ALERT,
    GET_ERRORS,
    CREATE_MESSAGE,
    VIEW_PAYMENT_PANEL, RESET_ALL,
    OPEN_DYNAMIC_DIALOG,
    OPEN_LOADER_PROGRESS,
    SET_MENU_ITEM,
    GET_REQUEST,
    RESET_CREDENTIALS
} from './actionTypes';

export const requestError = (error) => ({
    type: GET_ERRORS,
    msg: error.response? error.response.data : "response data fom server is undefined",
    status: error.response?error.response.status : "status undefined",
});

export const getRequest = (status) => ({
    type: GET_REQUEST,
    status
});

export const createMessage = (msg) => ({
    type: CREATE_MESSAGE,
    msg: msg
})

export const handleViewPaymentPanel = showPaymentPanel => ({
    type: VIEW_PAYMENT_PANEL,
    showPaymentPanel
})

export const logOut = () => ({
    type: RESET_ALL
});

export const handleDynamicDialog = (door, paramsFunction) => ({
    type: OPEN_DYNAMIC_DIALOG,
    door,
    paramsFunction
});

export const handleAlerts = (status, msg, open) => ({
    type: GET_ALERT,
    status,
    msg,
    open

})

export const openLoaderProgress = (open) => ({
    type: OPEN_LOADER_PROGRESS,
    open
})

export const setMenuItem = (name) => ({
    type: SET_MENU_ITEM,
    name
})

export const resetCredentials = (value) => ({
    type: RESET_CREDENTIALS,
    value
});