import {LOAD_PRODUCTS, UPDATE_VARIABLE_PRODUCT} from "../redux/actionTypes";

const initialState = {
    all: {},
    isLoading: true
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOAD_PRODUCTS: {
            return {
                ...state,
                all: action.products_list.reduce((obj, product) => {
                    obj[product.id] = product
                    return obj
                }, {}),
                isLoading: false
            }
        }
        case UPDATE_VARIABLE_PRODUCT:{
            const updatedProduct = state.all[action.payload.product];
            updatedProduct.price = action.payload.newPrice;
            return {
                ...state,
                all:{...state.all, [action.payload.product]: updatedProduct}/**/
            }
        }
        default :
            return state
    }
}

export const getProduct = (state, id) => state.all[id]