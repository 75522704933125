import {LOAD_COMMON_OPTION} from "../actions/actionsTypes";
import {transformToObject} from "../../common/reducerUtilities";

const initialState = {

    country: {},
    idType: {},
    isFetch:false
}
 function commonOptionReducer(state = initialState.country, action) {

    switch (action.type){

        case LOAD_COMMON_OPTION:
            return action.country.reduce((accumulator, country) => {
                accumulator[country.code] = country;
                return accumulator
            }, {});

        default:
            return state;
    }
}

function identityTypeReducer (state = initialState.idType, action) {

    switch (action.type) {
        case LOAD_COMMON_OPTION:
            return transformToObject(action.identityType)
        default:
            return state;
    }
}

function  isFetch (state= initialState.isFetch, action) {
    switch (action.type) {
        case  LOAD_COMMON_OPTION:
            return true
        default:
            return state
    }
}

const commonOptionsSelectReducer = (state = initialState, action ) => {

    return {
        country: commonOptionReducer(state.country, action),
        idType: identityTypeReducer(state.idType, action),
        isFetch: isFetch(state.isFetch, action)
    }
}
export default  commonOptionsSelectReducer