import { VALIDATE_EXTERNAL, GET_CURRENT_ROUTE} from '../redux/actionTypes';

const  initialState = {
    validatedExternal: false,
    currentRoute : ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CURRENT_ROUTE:
            return {
                ...state,
                currentRoute: action.route
            }
        case VALIDATE_EXTERNAL:
            return { ...state, validatedExternal: action.validated } 
        default :
            return state
    }
}