import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux'
import {filterSearchMultiple} from '../../actions/utilsActions'
import {cleanFilter} from "../../../pos/redux/actions";

// COMPONENTS MATERIAL UI
import {Box, Input, InputAdornment, Typography} from '@material-ui/core/';
// COMPONENTS
import SearchIcon from '@material-ui/icons/SearchRounded';

import Classes from '../../../../styles'
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";

const SearchMultiple = (props) => {
    const classes = Classes();
    const dispatch = useDispatch();
    const roomsById = useSelector(state => state.rooms.byId);
    const [roomMarker, setRoomMarker] = useState("");
    const [filteringClient, setFilteringClient] = useState(false);
    const [filtered, setFiltered] = useState(false);
    const [activeIcon, setActiveIcon] = useState(false);
    const [term, setTerm] = useState('');
    let search;

    const setFullSearchTerm = (value) => {
        props.filterSearchMultiple(term)
    }

    useEffect(() => {
        if (props.clients) {
            setFilteringClient(true);
        }
        if (props.roomId) {
            setRoomMarker(roomsById[props.roomId].number);
            setFiltered(true);
        } else {
            if (props.filterCriteria) {
                setRoomMarker(props.filterCriteria);
                setFiltered(true);
            } else {
                setRoomMarker("");
                setFiltered(false);
            }
        }
        const timerId = setTimeout(() => {
            setFullSearchTerm(term);
        }, 2000);

        return () => {
            clearTimeout(timerId);
        };

    }, [props.roomId, props.filterCriteria, term]);
    /**/
    const handleValue = (e) => {
        const value = e.target.value.trim();
        setTerm(value)
    };

    const handleEventIcon = (value) => {
        setTimeout(() => setActiveIcon(value), 100);
    }
    return (
        <div className={classes.forms.searchHolder}>
            <Input
                className={` general-forms- ${classes.forms.searchInput} general-colors- ${classes.colors.bgWhite}  
					general-styles- ${classes.styles.bRadius18} general-styles- ${classes.styles.shadow1}`}
                id="input-with-icon-adornment" placeholder="Search"
                fullWidth
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon style={activeIcon ? {color: "#5BE576"} : {color: "#9E9E9E"}}/>
                    </InputAdornment>
                }
                endAdornment={
                    filteringClient && filtered &&
                    <InputAdornment position="end" onClick={() => {
                        dispatch(cleanFilter());
                    }} style={{cursor: 'pointer'}}>
                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center"
                             style={{
                                 backgroundColor: '#adadad',
                                 borderRadius: '1em',
                                 width: '100%',
                                 padding: '0.1em 0.7em',
                                 marginRight: '0.2em'
                             }}
                        >
                            <CancelOutlinedIcon style={{marginRight: '5px', color: '#ffffff'}}/>
                            <Typography variant="subtitle2" style={{color: '#ffffff'}}
                            >{roomMarker}</Typography>
                        </Box>
                    </InputAdornment>
                }
                onChange={handleValue}
                value={term}
                onFocus={() => handleEventIcon(true)}
                onBlur={() => handleEventIcon(false)}
            />
        </div>
    );
};


export default connect(state => ({
    roomId: state.clientCriteria.value,
    filterCriteria: state.clientCriteria.criteria,
    searchValue: state.utilsReducer.search,
}), {filterSearchMultiple})(SearchMultiple)

