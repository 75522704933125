import {
    AUTH_ERROR,
    INVALID_SESSION,
    LOGIN_SUCCESS,
    LOGOUT_USER,
    SESSION_VERIFIED,
    SUBDOMAIN_RETRIEVED,
    USER_LOADED,
    USER_LOADING,
    BAD_CREDENTIALS,
    SERVER_ERROR, LOAD_USER_DATA,
    RESET_CREDENTIALS, POST_USER_PROFILE
} from '../actions/actionTypes'

const initialState = {
    subdomain: localStorage.getItem('subdomain'),
    isSessionValid: false,
    isAuthenticated: false,
    isLoading: false,
    badCredentials:false,
    serverError:false,
    mainApp: '',
    userData: {},
    permissionsList: {},
    authData: {},
    verifying:false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case USER_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case SESSION_VERIFIED:
            localStorage.setItem('accessToken', action.payload.access);
            return {
                ...state,
                ...action.payload
            };
        case POST_USER_PROFILE:
            return {
                ...state,
                userData: action.payload.data
            };
        case USER_LOADED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                isSessionValid: true,
                badCredentials:false
            };
        case LOGIN_SUCCESS:
            localStorage.setItem('refreshToken', action.refreshToken);
            localStorage.setItem('accessToken', action.accessToken);
            localStorage.setItem('accessExp', action.accessExp);
            localStorage.setItem('refreshExp', action.refreshExp);
            localStorage.setItem('origin', action.redirectOrigin);
            localStorage.setItem('username', action.userInformation.username);
            localStorage.setItem('subdomain', action.userInformation.subdomain);
            localStorage.setItem('color', action.userInformation.color);
            localStorage.setItem('permissions',action.permissionsList);
            localStorage.setItem('country',action.userInformation.country);
            localStorage.setItem('exchangeRate',action.userInformation.exchangeRate);
            localStorage.setItem('taxValue',action.userInformation.taxValue);
            localStorage.setItem('currencyCode',action.userInformation.currencyCode);
            localStorage.setItem('secondaryCurrencyCode',action.userInformation.secondaryCurrencyCode);
            return {
                ...state,
                // isAuthenticated: true,
                userData: action.userInformation,
                permissionsList: action.permissionsList,
                mainApp: action.redirectOrigin,
                isSessionValid: true,
                badCredentials:false
                // isLoading: false

            };
        case LOAD_USER_DATA:
            // NEVER SET THE TOKENS HERE, ONLY AT LOGIN
            localStorage.setItem('username', action.payload.userInformation.username);
            localStorage.setItem('subdomain', action.payload.userInformation.subdomain);
            localStorage.setItem('color', action.payload.userInformation.color);
            localStorage.setItem('permissions',action.payload.permissionsList);
            localStorage.setItem('country',action.payload.userInformation.country);
            localStorage.setItem('currencyCode',action.payload.userInformation.currencyCode);
            localStorage.setItem('secondaryCurrencyCode',action.payload.userInformation.secondaryCurrencyCode);
            localStorage.setItem('exchangeRate',action.payload.userInformation.exchangeRate);
            localStorage.setItem('taxValue',action.payload.userInformation.taxValue);
            return {
                ...state,
                userData: action.payload.userInformation,
                permissionsList: action.payload.permissionsList,
            };
        case AUTH_ERROR:
        case LOGOUT_USER:
        case INVALID_SESSION:
            console.log('INVALID SESSION')
            // localStorage.removeItem('refreshToken');
            // localStorage.removeItem('accessToken');
            // localStorage.removeItem('accessExp');
            // localStorage.removeItem('refreshExp');
            return {
                ...initialState,
                subdomain: '',
                isSessionValid:false
            };
        case BAD_CREDENTIALS:
            console.log('BAD CREDENTIALS')
            // localStorage.removeItem('refreshToken');
            // localStorage.removeItem('accessToken');
            // localStorage.removeItem('accessExp');
            // localStorage.removeItem('refreshExp');
            return {
                ...initialState,
                subdomain: '',
                badCredentials:true,
                isSessionValid:false
            };
        case SUBDOMAIN_RETRIEVED:
            localStorage.setItem('subdomain', action.payload);
            return {
                ...state
            };
        case RESET_CREDENTIALS:
            console.log('RESETING CREDENTIALS')
            return {...state, badCredentials: action.value};
        default:
            return state;
    }
}