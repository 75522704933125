import {STATUS_RESPONSE,DELETE_METHOD, PUT_METHOD, POST_METHOD} from "../commonConstants"

export const validateResponseApi = (response, keyName) => {
    if(response !== {}) {
        const post = response.method === POST_METHOD
        const update = response.method === PUT_METHOD
        const remove = response.method === DELETE_METHOD 
        const status =  response.status >= 200 && response.status < 300 ? true : false
        const key = keyName === response.key

        if(status && key) {
            if(post){
                return POST_METHOD
            } 
            if(update){
                return PUT_METHOD
            }
            if(remove){
                return DELETE_METHOD
            }

        }else{
            return "inactive"
        }
    }else {
        return "inactive"
    }
}

const handleFilterSearch = criteriaKeys => (searchValue, input) => {
    if (!searchValue) return input;
    let found = false;
    return input.filter(obj => {
        for (let key of criteriaKeys) {
            if (obj.hasOwnProperty(key)) {
                found = (obj[key] || '').toString().toLowerCase().includes(searchValue.toLowerCase());
                if(found) break
            }
        }
        return found
    })
};

const customerKeys = ['name', 'surname', 'fullName','roomNumber','roomName','status'];
const productsKeys = ['name', 'description'];
const supportQuestionsKeys = ['question','answer'];
const supportCategoriesKeys = ['categories'];

export const handleCustomerSearch = handleFilterSearch(customerKeys);
export const handleProductsSearch = handleFilterSearch(productsKeys);
export const handleSupportFilter = handleFilterSearch(supportCategoriesKeys);
export const handleSupportSearch = handleFilterSearch(supportQuestionsKeys);
//reports

const filterKey = ['origin'];
const balanceFlowsReportSearchKeys = ['user', 'transaction', 'cardBrand', 'paymentType'];
const guestFlowsReportSearchKeys = ['user', 'guestName', 'paymentType', 'currency', 'eCorrelative', 'documentType'];
const salesReportSearchKeys = ['user', 'type', 'customer', 'name', 'product', 'outlet'];
const RankingReportSearchCriteria = ['product', 'outlet'];
const ticketsReportSearchCriteria = ['serial', 'documentNumber', 'name', 'type', 'relatedInvoice'];
const invoicesReportSearchCriteria = ['serial', 'documentNumber', 'name', 'type', 'relatedInvoice'];
const creditNotesReportSearchCriteria = ['serial', 'documentNumber', 'name', 'relatedReceipt', 'relatedDate', 'relatedReceiptType'];

const settingsStaffVolunteerCriteria = ['name', 'surname', 'fullName', 'pmsGuestId', 'id', 'documentNumber','identityNumber', 'email', 'country'];
const settingsUsersCriteria = ['username', 'firstName', 'lastName','groupName','email'];
const settingsGroupsCriteria = ['name', 'origin'];
const settingsVolunteerModalCriteria = ['name', 'surname', 'fullName'];

export const handleFilter = handleFilterSearch(filterKey);
export const handleBalanceFlowsSearch = handleFilterSearch(balanceFlowsReportSearchKeys);
export const handleGuestFlowsSearch = handleFilterSearch(guestFlowsReportSearchKeys);
export const handleSalesSearch = handleFilterSearch(salesReportSearchKeys);
export const handleRankingSearch = handleFilterSearch(RankingReportSearchCriteria);
export const handleTicketsSearch = handleFilterSearch(ticketsReportSearchCriteria);
export const handleInvoicesSearch = handleFilterSearch(invoicesReportSearchCriteria);
export const handleCreditNotesSearch = handleFilterSearch(creditNotesReportSearchCriteria);

export const handleSearchSettingsStaff = handleFilterSearch(settingsStaffVolunteerCriteria);
export const handleSearchSettingsVolunteer = handleFilterSearch(settingsStaffVolunteerCriteria);
export const handleSearchSettingsUsers = handleFilterSearch(settingsUsersCriteria);
export const handleSearchSettingsGroups = handleFilterSearch(settingsGroupsCriteria);
export const handleSearchSettingsVolunteerModal = handleFilterSearch(settingsVolunteerModalCriteria);

