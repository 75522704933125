import {connect } from "react-redux";
import AmountDetail from "../../components/payment_panel/AmountDetail";
import {calculateCartAmount, calculateConsumptions} from "../../selector/cartAmount";
import {paymentTypeCriteria} from "../../redux/selector/payment.selector"


const mapStateToProps = (state) => {
    return {
        cart:calculateCartAmount(state),
        consumptions:calculateConsumptions(state),
        origin:  localStorage.getItem("origin"),
        chargedCartProducts: state.cart.productsIds.length > 0,
        exchangeRate:state.exchangeRateReducer,
        paymentValues: state.paymentCurrencyValues,
        selectedClient: state.paymentTypeSelected.optionClientType,
        paymentTypeSelected:  paymentTypeCriteria(state),
        activedPaySecondCurrency: state.paymentTypeSelected.activedPaySecondCurrency,
        isRrhhPayment: state.paymentState.isRrhhPayment,
        permissionsList:localStorage.getItem("permissions"),
        paymentType:state.paymentTypeSelected.selectedTransferPayment.type
    }
};

export default connect(mapStateToProps)(AmountDetail)