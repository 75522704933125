export const API_BALANCE_STATE_URL = 'api/balance/state-balance';

export const API_OPEN_BALANCE = 'api/balance/retrieve-open-balances';

// DASHBOARD API URLS
export const API_DOCUMENTS_DAILY_URL = 'api/billing/dashboard-daily';
export const API_DOCUMENTS_WEEKLY_URL = 'api/billing/dashboard-weekly';
export const API_DOCUMENTS_MONTHLY_URL = 'api/billing/dashboard-monthly';
export const API_DOCUMENTS_YEARLY_URL = 'api/billing/dashboard-yearly';
export const API_RECEIPTS_BY_BALANCE_DASHBOARD_URL = 'api/billing/dashboard-receipts';
export const API_SALES_TOTALS_BY_TYPE_URL = 'api/pos/dashboard-amount-by-sale-type';
export const API_SALES_TOTALS_BY_ORIGIN_URL = 'api/pos/sales-total-by-origin';

export const CONSUMPTION_URL =  "api/pms/client";
export const SET_CONSUMPTION_STATUS_URL = "api/pos/consumption/detail";
export const CANCEL_ACCOUNT_CONSUMPTION_URL = 'api/pos/cancel-charge-detail';

// CLIENT HISTORY AND ANULLED/
export const API_CLIENT_RECEIPTS_HISTORY_URL = 'api/billing/documents-by-client';

export const API_SUPPORT_URL = 'support/api/list-questions';