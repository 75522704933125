import {
    GET_ERRORS
} from '../actions/actionTypes'

const initialState = {
    msg:{},
    status:null
}

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_ERRORS:
            return {
                ...state,
                msg: action.msg,
                status:action.status
            }
        default:
            return state;
    }
}