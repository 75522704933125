import Room from "./Room";
import React from "react";

import {Box, Grid, Typography} from "@material-ui/core/";
import NullMessage from '../../../common/components/error.pages/NullMessage'
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const RoomList = ({rooms, setFilterClient}) => {
    const theme = useTheme();
    const matchesSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <React.Fragment>
            <Typography variant="h6"><Box color="text.secondary" mb={2}>Rooms</Box></Typography>
            {rooms.length > 0 ?
                <Grid container spacing={2} style={{height: "68vh", overflowY: "auto"}}>
                    <Grid item xs={12}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" flexWrap="wrap">
                            {rooms.map(function (room, index) {
                                return (
                                    <Box width={matchesSmDown ? "48%" : "24%"} key={index}>
                                        <Room key={room.roomId} {...room}
                                              guestFilter={() => setFilterClient("room", room.roomId)}/>
                                    </Box>
                                )
                            })
                            }

                        </Box>
                    </Grid>
                </Grid>
                :
                <Box display="flex" flexDirection="row" spacing={3} alignItems="flex-start"
                     flexWrap="wrap" justifyContent="space-between" alignContent="baseline"
                     style={{height: "68vh", overflowY: "auto", padding: "0.5em 0em"}}>
                    <NullMessage message="There are no rooms"/>
                </Box>
            }
        </React.Fragment>
    )
};

export default RoomList

