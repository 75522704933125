import {
    ADD_AREA,
    ADD_SERIAL,
    EDIT_AREA,
    LOAD_ACCOUNTS,
    LOAD_AREAS,
    LOAD_BILLING,
    LOAD_GENERAL,
    REMOVE_AREA,
} from "../actions/actionsTypes";
import {transformToObject} from "../../common/reducerUtilities"

const initialState = {
    isFetch: false,
    areas: {}

}

const areaSettings = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_GENERAL:
        case LOAD_BILLING:
        case LOAD_ACCOUNTS:
        case LOAD_AREAS:
            return {
                areas: transformToObject(action.areas),
                isFetch: true
            };
        case ADD_AREA:
        case EDIT_AREA:
            return {
                isFetch: state.isFetch,
                areas: {...state.areas, [action.payload.id]: action.payload}
            };
        case REMOVE_AREA:
            const {[action.areaId]: value, ...areas} = state.areas
            return {
                isFetch: state.isFetch,
                areas: areas
            };
        case ADD_SERIAL:
            const areaId = action.location;
            const serials = action.serial.map(serial => ({documentType: serial.document, serie: serial.serial}))
            const areasUpdated = {
                ...state.areas,
                [areaId]: {...state.areas[areaId], serials: serials}
            }
            return {
                areas: areasUpdated,
                isFetch: state.isFetch
            }

        default:
            return state
    }
}

export default areaSettings