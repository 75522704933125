import React from 'react';
import {Box, Grid, InputLabel} from "@material-ui/core";
import {connect} from "react-redux";
import ImageIcon from '@material-ui/icons/ImageOutlined';
const logoCompany = ({company}) => {
    return (
        <Grid container item>
            <Grid item xs={12} lg={8}>
                <InputLabel shrink  style={{color: "rgba(0, 0, 0, 0.38)"}}>Company logo</InputLabel>
                {
                    company[12] === null ? 
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <ImageIcon color="disabled" />
                    </Box>
                    : 
                    <Box display="flex" alignItems="center" justifyContent="center">
                         <img src={company[12]} 
                        style={{maxHeight: '80px', borderRadius: '25px'}} alt="logo_company"/>

                    </Box>
                }
            </Grid>
        </Grid>
    )
};

const mapStateToProps = (state) => ({
    company: Object.values(state.companyReducer)
})

export default connect(mapStateToProps)(logoCompany)