import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {typography} from '../../../../styles'
import {Box, Typography} from '@material-ui/core';
import PowerOffIcon from '@material-ui/icons/PowerOffOutlined';
import CloudbedsAuthorizeButton from "../modals/CloudbedsAuthorizePopupButton";

const useStyles = makeStyles(theme => ({
    powerOffIcon: {
        fontSize: 80,
        marginBottom: 10
    }
}))

const ErrorMessagePMS = () => {
    const classes = {
        local: useStyles(),
        fontPoppins: typography().familyPoppins
    };

    return (
        <React.Fragment>
            <Box height={"80vh"} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                <PowerOffIcon color="secondary" className={classes.local.powerOffIcon}/>
                <Typography variant="h5">
                    PMS error
                </Typography>
                <br/>
                <Typography variant="subtitle1" align="center" className={classes.fontPoppins}>
                    The connection with your PMS is lost.
                    <br/>
                    Please try again.
                    <CloudbedsAuthorizeButton/>
                </Typography>
            </Box>
        </React.Fragment>
    )
};

export default ErrorMessagePMS