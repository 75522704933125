import {cartProductsIds, productQuantity} from "../reducers/cart.reducer";
import * as fromProducts from "../reducers/products.reducer";

const getCartProductsIds = (state) => cartProductsIds(state.cart)
const getProductQuantity = (state, id) => productQuantity(state.cart, id)
const getProduct = (state, id) => fromProducts.getProduct(state.products, id)
const getCurrentCategory = (state) => state.category.currentCategory
const getCurrentCategorySetting = (state) => state.storeReducer.currentCategory


export const getCategoryProducts = (state) => {
    const currentCategory = getCurrentCategory(state)
   
    return Object.values(state.products.all).filter(prod => (
        prod.category === currentCategory
    ))
}

export const getCategoryProductsSettings = (state) => {
    const currentCategory = getCurrentCategorySetting(state)
    return Object.values(state.storeReducer.productList).filter(prod => (
        prod.category === currentCategory
    ))
}


export const getCartProducts = (state)  => (
    getCartProductsIds(state).map(id => ({
            ...getProduct(state, id), quantity: getProductQuantity(state, id)
        })
    )
)

