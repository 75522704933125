import React, {useEffect} from "react";
// CSS
import Classes from '../../../../styles'
// COMPONENTS MATERIAL UI
import {Box, Button, Typography} from '@material-ui/core/'

const Room = ({categoryName, number,categoryType, shortName, guestFilter, showComponent}) => {
    const classes = Classes();
    const [size, setSize] = React.useState(150);
    const [roomName, setRoomName] = React.useState(number);

    useEffect(() => {
        if(number.length > 12){
            if(number.length >= 35  ){
                let currentText= number.substring(0,35) + '...';
                setRoomName(currentText)
            }
            setSize(size - number.length)
        }
    }, []);
    
    return (
        <Button
            className={`${classes.styles.shadow2} ${classes.styles.bRadius10} ${classes.buttons.boxButton} roomBox`}
            onSubmit={showComponent} onClick={guestFilter} 
            style={{marginBottom:"1em"}} >
            <Box display="flex" flexDirection="column" justifyContent="center" className={classes.buttons.boxInfo}>
                <Typography className="mainText" 
                style={size < 100 ? {fontSize: "118%"} : {fontSize: size + "%"}}>
                    {roomName}
                </Typography>
                <Typography className="extraData">
                    {categoryName}
                </Typography>
                <Typography className="subText">{categoryType}</Typography>
            </Box>
        </Button>
    )
};

export default Room
