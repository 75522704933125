import {
    SET_CLIENT,
    INITIAL_CLIENT,
    SUCCESS_PAYMENT
} from "../redux/actionTypes"

const initialState = {
    id: null,
    customerType: '',
    name: null,
    surname: null,
    documentNumber: null,
    applyDiscount: false,
    debt: 0.0,
    chargeBlock:false,
    reservationId: null,
    groupId: null,
    customerId: null,
    isFetch:false
};

const client = (state = initialState, action) => {
    switch (action.type) {
        case SET_CLIENT:
            return {
                ...state, ...action.client
            };
        case INITIAL_CLIENT:
        case SUCCESS_PAYMENT:
            return initialState;
        default:
            return state
    }
}

export default client