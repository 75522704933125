import axios from 'axios';
import {
    baseDeleteAPIRequest,
    baseGetAPIRequest,
    basePostAPIRequest,
    basePutAPIRequest,
    cleanLocalStorage,
    getRequestInfo,
    makeAuthUrl
} from './utils';
import {
    AUTH_TOKEN_URL,
    BALANCE_TRANSFERS_URL,
    CURRENCIES_URL,
    INITIAL_BALANCE,
    OBTAIN_PMS_JSONS_URL,
    OPEN_BALANCE,
    REFRESH_TOKEN_URL,
    SEND_LOCATION,
    SEND_USER_PROFILE,
    USER_DATA_URL,
    VALIDATE_TOKEN_URL,
    PMS_AUTHORIZE_URL
} from './apiUrlsConstants';

export function authenticationRequests(credentials) {
    const {apiUrl, headers} = makeAuthUrl(AUTH_TOKEN_URL);
    return axios.post(apiUrl, credentials, {headers: headers})
}

export function getUserInformationRequests(token) {
    const {apiUrl, headers} = makeAuthUrl(USER_DATA_URL);
    headers['Authorization'] = `Bearer ${token}`;
    return axios.get(apiUrl, {headers: headers})
}

export function refreshToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    const {apiUrl, headers} = getRequestInfo(REFRESH_TOKEN_URL);
    return axios.post(apiUrl, {refresh: refreshToken}, {headers: headers})
}

export function getInitialBalance() {
    return baseGetAPIRequest(INITIAL_BALANCE)
}

export function postOpenBalance(openData) {
    return basePostAPIRequest(OPEN_BALANCE, openData)
}

export function postSendLocation(id) {
    return basePostAPIRequest(SEND_LOCATION, id)
}

export function verifyTokenRequest() {
    const token = localStorage.getItem('accessToken');
    const {apiUrl, headers} = getRequestInfo(VALIDATE_TOKEN_URL);
    return axios.post(apiUrl, {token: token}, {headers: headers})
}

export async function verifyToken() {
    try {
        const accessExp = new Date(localStorage.getItem('accessExp'))
        const currentDateTime = new Date()
        if (currentDateTime < accessExp) {
            return Promise.resolve()
        } else {
            //TODO: Improve the verification method, maybe rework the api calls to avoid constant
            // verification despite having jsut check the time
            return await verifyTokenRequest();
        }
    } catch (e) {
        try {
            const token = await refreshToken();
            localStorage.setItem('accessToken', token.data.access);
            localStorage.setItem('accessExp', token.data.accessExp);
            localStorage.setItem('refreshExp', token.data.refreshExp);
            return Promise.resolve();
        } catch (e) {
            cleanLocalStorage();
            window.location.assign("/");
        }
    }
}

export function getPmsInformation() {
    return baseGetAPIRequest(OBTAIN_PMS_JSONS_URL)
}

export function postUserProfile(data) {
    return basePostAPIRequest(SEND_USER_PROFILE, data)
}

export function getCurrencies() {
    return baseGetAPIRequest(CURRENCIES_URL)
}

export function getBalanceTransfers() {
    return baseGetAPIRequest(BALANCE_TRANSFERS_URL)
}

export function postBalanceTransfer(data) {
    return basePostAPIRequest(BALANCE_TRANSFERS_URL, data)
}

export function updateBalanceTransfer(id, data) {
    const updateUrl = `${BALANCE_TRANSFERS_URL}${id}/`;
    return basePutAPIRequest(updateUrl, data)
}

export function deleteBalanceTransfer(id) {
    const deleteUrl = `${BALANCE_TRANSFERS_URL}${id}/`;
    return baseDeleteAPIRequest(deleteUrl)
}

//PMS RE AUTHORIZATION
export function authorizeWithCode(code) {
    const URL = PMS_AUTHORIZE_URL + `?code=${code}`;
    return baseGetAPIRequest(URL)
}
