import React from "react";
import {connect, useDispatch} from "react-redux"
import {openModalView} from "../../../common/actions/utilsActions"
import {Box, Grid, Typography} from "@material-ui/core/";
import Classes from '../../../../styles'
import {groupSelector} from "../../selector/customer.selector";
import GroupList from "../../../pos/components/payment_panel/GroupList";
import ModalView from "../../../common/components/modals/ModalView"


const CustomerSection = ({client, groupMembers, selectedPaymentOption}) => {
    const classes = Classes();
    const dispatch = useDispatch();
    let customerType = client.customerType;
    customerType = customerType.charAt(0).toUpperCase() + customerType.slice(1).toLowerCase();
    const isStaff = client.customerType === "STAFF";
    const isVolunteer = parseInt(client.pmsGuestId);
    const isGroup = selectedPaymentOption === "Group"
    const clientFullName = !client.fullName ? "" : client.fullName.toLowerCase();

    return (
        <Grid container>
            <Grid item xs={1}/>
            <Grid item xs={11}>
                <Box color="textPrimary" align='center'>
                    <Typography variant="subtitle1" className={` ${classes.typography.fontBold}`}
                                style={{textTransform: 'capitalize'}}>
                        {isGroup ? (client.name + "'s group") : clientFullName}
                    </Typography>
                    {
                        isGroup &&
                        <Box bgcolor="primary.main" color="common.white" p={0.3} width={"25%"}
                             style={{borderRadius: 20, fontSize: 12, fontWeight: "bold", cursor: 'pointer'}}
                             onClick={() => dispatch(openModalView("group-details"))}>
                            {groupMembers.length + " friends"}</Box>
                    }
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box align="left">
                    <Box color="text.secondary">
                        {
                            isGroup ?
                                <Typography variant="caption">
                                    Type: Guest / group
                                </Typography> :
                                <Typography variant="caption">
                                    Type: {isStaff ?
                                    (isVolunteer ? "Volunteer" : customerType)
                                    : customerType
                                }
                                </Typography>
                        }
                    </Box>
                    <Box color="text.secondary">
                        <Typography variant="caption">
                            Country: {client.country}
                        </Typography>
                    </Box>
                    <Box color="text.secondary">
                        <Typography variant="caption">
                            Document Number: {client.documentNumber}
                        </Typography>
                    </Box>
                </Box>
                <ModalView
                    title={"Group Details"}
                    keyValue={"group-details"}
                    body={<GroupList/>}
                />

            </Grid>
        </Grid>

    )
};

const mapStateToProps = (state) => {
    return {
        client: state.client,
        groupMembers: groupSelector(state),
        selectedPaymentOption: state.paymentState.selectedPaymentOption
    }
};

export default connect(mapStateToProps)(CustomerSection)
