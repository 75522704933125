// Reusable utility functions

export function updateObject(oldObject, newValues) {
    // Encapsulate the idea of passing a new object as the first parameter
    // to Object.assign to ensure we correctly copy data instead of mutating
    return Object.assign({}, oldObject, newValues)
}

export function updateItemInArray(array, itemId, updateItemCallback) {
    const updatedItems = array.map(item => {
        if (item.id !== itemId) {
            return item
        }

        return updateItemCallback(item)
    })

    return updatedItems
}

export function createReducer(initialState, handlers) {
    return function reducer(state = initialState, action) {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](state, action)
        } else {
            return state
        }
    }
}

export function transformToObject(itemsArray, key = "id") {
    if (!itemsArray) return {}
    return itemsArray.reduce((object, item) => {
        if (item.hasOwnProperty(key)) object[item[key]] = item
        return object
    }, {})
}

export function transformToObjectWithDuplicateIds(itemsArray, key = "id") {
    if (!itemsArray) return {}
    // console.log(itemsArray);
    let finalConsumptionItems = {}
    itemsArray.forEach(item => {
        const dateIdentifier = item["id"]
        if(finalConsumptionItems.hasOwnProperty(dateIdentifier)){
            const newItem = item;
            const newSubtotal = finalConsumptionItems[dateIdentifier]["price"]["subtotal"] + item["price"]["subtotal"]
            const newTaxes = finalConsumptionItems[dateIdentifier]["price"]["taxes"] + item["price"]["taxes"]
            const newServices = finalConsumptionItems[dateIdentifier]["price"]["services"] + item["price"]["services"]
            const newTotal = finalConsumptionItems[dateIdentifier]["price"]["total"] + item["price"]["total"]
            newItem["price"]["subtotal"] = newSubtotal
            newItem["price"]["taxes"] = newTaxes
            newItem["price"]["services"] = newServices
            newItem["price"]["total"] = newTotal
            finalConsumptionItems[dateIdentifier] = newItem
        }else{
            finalConsumptionItems[dateIdentifier] = item
        }
    })
    return finalConsumptionItems;
}