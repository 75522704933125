import {LOAD_DASHBOARD, LOAD_DASHBOARD_SALES_TOTALS_BY_TYPE} from "../actionTypes";

const initialState = {
    dailyTransactions: {},
    weeklyTransactions: {},
    monthlyTransactions: {},
    yearlyTransactions: {},
    receiptsByBalance: {},
    salesTotalByType: {},
    salesTotalsByOrigin: {},
    isDashboardFetch:false
};


const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_DASHBOARD:
            return {
                ...state,
                dailyTransactions: action.payload.dailyTransactions,
                weeklyTransactions: action.payload.weeklyTransactions,
                monthlyTransactions: action.payload.monthlyTransactions,
                yearlyTransactions: action.payload.yearlyTransactions,
                receiptsByBalance: action.payload.receiptsByBalance,
                salesTotalsByOrigin: action.payload.salesTotalsByOrigin,
                isDashboardFetch: true,
            };
        case  LOAD_DASHBOARD_SALES_TOTALS_BY_TYPE:
            return {
                ...state,
                salesTotalByType: action.payload,
                isDashboardFetch: true,
            };
        default:
            return state
    }
};


export default dashboardReducer
