import {
    LOAD_CONFIG,
    ADD_TAX,
    EDIT_TAX,
    REMOVE_TAX,
    EDIT_CURRENCY,
    ADD_SERIAL,
    DISMISS_AREA,
    AREA_SELECTED,
    LOAD_SERIAL,
    LOAD_TAX,
    LOAD_BILLING,

} from "../actions/actionsTypes"

import {updateObject, transformToObject, createReducer} from "../../common/reducerUtilities"

const initialState = {
    taxesList: {},
    taxType: {},
    currency: {},
    currencyList: {},
    documentType: {},
    serialList: {},
    areaSelected: null,
    isLoading: true,
};
const taxesReducer = (state = initialState.taxesList, action) => {
    switch (action.type) {
        case LOAD_BILLING:
        case LOAD_TAX:
            return action.taxes.reduce((obj, taxes) => {
                obj[taxes.id] = taxes;
                return obj
            }, {})
        case LOAD_CONFIG :
            return updateObject(state, transformToObject(action.taxes));
        case ADD_TAX:
        case EDIT_TAX:
            return {
                ...state,
                [action.payload.id]: action.payload
            };
        case REMOVE_TAX:
            const {[action.taxId]: tax, ...taxes} = state
            return taxes
        default:
            return state
    }
}

const taxTypeReducer = (state = {}, action) => {
    switch (action.type) {
        case LOAD_CONFIG:
            return transformToObject(action.taxType)
        default:
            return state
    }
}

const currencyReducer = (state = {}, action) => {
    switch (action.type) {
        case LOAD_CONFIG:
            return {
                principalCurrency: action.principalCurrency,
                secondaryCurrency: action.secondaryCurrency,
            }
        case EDIT_CURRENCY:
            return updateObject(state, action.payload) // payload => {principalCurrency:value} / {secondaryCurrency:value}
        default:
            return state
    }
}

const currencyListReducer = (state = {}, action) => {
    switch (action.type) {
        case LOAD_CONFIG:
            return transformToObject(action.currencyList)
        default:
            return state
    }
}

function updateSerial(state, action) {
    const serials = action.serial.reduce((acc, serial) => {
        acc[serial.serie] = serial;
        return acc
    }, {})
    return updateObject(state, serials);

}

const serialReducer = (state = initialState.serialList, action) => {
    switch (action.type) {
        case LOAD_BILLING:
        case LOAD_SERIAL:
            return action.serials.reduce((obj, serial) => {
                obj[serial.serial] = serial;
                return obj
            }, {})
        case ADD_SERIAL:
            const serials = transformToObject(action.serial, 'serial')
            return updateObject(state, serials);

        default:
            return state
    }
};

const documentTypeReducer = createReducer({}, {
    [LOAD_BILLING]: (state, action) => transformToObject(action.documentType, 'code')
});

const isLoadingReducer = (state = true, action) => {
    switch (action.type) {
        case LOAD_CONFIG:
        case LOAD_BILLING:
            return false
        default:
            return state
    }
}

const areaSelectedReducer = (state = null, action) => {
    switch (action.type) {
        case AREA_SELECTED:
            return action.areaId
        case  DISMISS_AREA:
            return null
        default:
            return state
    }
};

const billingSettingReducer = (state = initialState, action) => {
    return {
        taxesList: taxesReducer(state.taxesList, action),
        taxType: taxTypeReducer(state.taxType, action),
        currency: currencyReducer(state.currency, action),
        currencyList: currencyListReducer(state.currencyList, action),
        documentType: documentTypeReducer(state.documentType, action),
        serialList: serialReducer(state.serialList, action),
        areaSelected: areaSelectedReducer(state.areaSelected || null, action),
        isLoading: isLoadingReducer(state.isLoading, action)
    }
};

export default billingSettingReducer