import React , {useEffect}from 'react';
import {Link} from 'react-router-dom';
import {Field, reduxForm, getFormInitialValues} from "redux-form";
import {connect, useDispatch} from 'react-redux';
import {getOutletsSelector} from '../../selector/store.selector'
import {store} from "../../../rumi-settings/permissionsConstants";
import {handleViewPaymentPanel} from "../../../common/actions/actions" 

// COMPONENTS
import InfoList from '../utils/InfoList';
// MATERIAL UI
import {Box, Button, FormControl, FormHelperText, Grid, InputLabel, Typography} from '@material-ui/core';
// CSS
import Classes from '../../../../styles';
import {renderTextField} from "../../../common/components/utils/ReduxFromMaterialUI";
import {submitTaxesForm, validate} from "../../forms/submit/taxes.submit";

import CheckIcon from '@material-ui/icons/CheckCircleOutline';

let TaxesForm = (props) => {
    const classes = Classes();
    const dispatch = useDispatch();
    const origin = localStorage.getItem('origin');
    const permissions = Object.values(props.permissionsList);
    const canSeeSettingsStore = permissions.includes(store) || permissions.includes("superuser");
    const {handleSubmit, pristine, reset, submitting, outletList, initialValues} = props;

    const handleCancel = () => {
        reset();
        props.history.goBack()
    };

    useEffect(() => {
        dispatch(handleViewPaymentPanel(false))
        if(initialValues === undefined || outletList.length === 0){
            handleCancel()
        }
    });

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h1" className={classes.typography.pageTitles}>
                    <Box display="inline" color="text.secondary">
                        Settings /
                    </Box>
                    <Link to={`../billing`}
                          className={`general-nav- ${classes.nav.link}`}>
                        <Box display="inline" color="text.secondary">
                            &nbsp;Billing /
                        </Box>
                    </Link>
                    &nbsp;Tax
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container item spacing={2}>
                                <Grid item xs={12} md={2}>
                                    <InfoList title={"Description"}
                                              description={"This taxes will be charged on your customers bill and they would see it."}/>
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    <Grid container item spacing={2}>
                                        <Grid item xs={12} md={5}>
                                            <Box display="flex" flexDirection="column">
                                                <FormControl className={`${classes.forms.formMargin}`}>
                                                    <Field disabled id="standard-input" label="Name *" name="name"
                                                        component={renderTextField} InputLabelProps={{shrink: true,}}
                                                        className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} `}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <Box display="flex" flexDirection="row">
                                                        <Field disabled
                                                            id="standard-number"
                                                            label="Value"
                                                            name="value"
                                                            type="number"
                                                            component={renderTextField}
                                                            className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} `}
                                                            style={{width: '10em', marginRight: '0.15em'}}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                        <FormHelperText style={{padding: '20px 0 0 5px'}}> % of the total amount (sale).</FormHelperText>
                                                    </Box>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={10}>
                                            <Box fontStyle="oblique" borderRadius={5} color="text.secondary" p={2}
                                                style={{border: "1px solid #5BE576"}} className={`${classes.forms.formMargin}`}>
                                                <Typography variant="body1">
                                                    This value added tax is INCLUDED on the purchase price you define in
                                                    {
                                                        canSeeSettingsStore ? 
                                                        <Link className={`general-nav- ${classes.nav.link}`}
                                                        to={`/${origin}/settings/store`}
                                                        style={{color: "#5BE576", margin: "0 5px"}}>settings/store</Link>
                                                        : <strong> settings/store </strong> 
                                                    }
                                                     by each product. PMS prices are NOT INCLUDED.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>   
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container item  spacing={3}>
                                <Grid item xs={12} md={2}>
                                    <InfoList title={"Tax application by outlets"}
                                              description={""}/>
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    <Grid container item spacing={2}>
                                        <Grid item xs={6} sm={3}>
                                            <InputLabel shrink component="legend" color="secondary">PMS</InputLabel>
                                            <Box mt={2}>
                                                <Typography variant="body1" color="textSecondary"
                                                            gutterBottom>{outletList.length === 0 ? "" : outletList[0].name}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={2}>
                                            <InputLabel shrink component="legend" color="secondary">Tax</InputLabel>
                                            <Box mt={2}>
                                                <CheckIcon color="primary"/>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={7} md={5}>
                                            <InputLabel shrink component="legend" color="secondary">i.e</InputLabel>
                                            <Box display="flex" style={{backgroundColor: "#e7e7e7", fontSize: 13}}
                                                 p={2}>
                                                <Box display="flex" flexDirection="column" alignItems="center">
                                                    <span>PMS PRICE</span><span>100</span></Box>&nbsp;&nbsp; +&nbsp;&nbsp;
                                                <Box display="flex" flexDirection="column" alignItems="center">
                                                    <span>RUMI IGV</span><span>18 %</span></Box>&nbsp;&nbsp;  =&nbsp;&nbsp;
                                                <Box display="flex" flexDirection="column" alignItems="center">
                                                    <span>RUMI PRICE</span><span
                                                    style={{color: "#5BE576", fontWeight: "bold"}}>118</span></Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={2} />
                                <Grid item xs={12} md={10}>
                                    <Grid container item spacing={2}>
                                        <Grid item xs={6} sm={3}>
                                            <InputLabel shrink component="legend" color="secondary">Rumi</InputLabel>
                                            <Box mt={2}>
                                                {
                                                    outletList.length === 0 ? "" :
                                                    outletList.map((outlet, index) => (
                                                        <Box key={index + 1}>
                                                            <Typography variant="body1" color="textSecondary"
                                                                        gutterBottom> {outlet.name}</Typography>
                                                        </Box>
                                                    ))
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={2}>
                                            <InputLabel shrink component="legend" color="secondary">Tax</InputLabel>
                                            <Box mt={2}>
                                                { outletList.length === 0 ? "" : 
                                                    outletList.map((outlet, index) => (
                                                        <Box key={index + 1} mb={0.1}>
                                                            <CheckIcon color="primary"/>
                                                        </Box>
                                                    ))
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={7} md={5}>
                                            <InputLabel shrink component="legend" color="secondary">i.e</InputLabel>
                                            <Box display="flex" style={{backgroundColor: "#e7e7e7", fontSize: 13}}
                                                 p={2}>
                                                <Box display="flex" flexDirection="column" alignItems="center">
                                                    <span>BASE PRICE</span><span>79, 37 </span></Box>&nbsp;&nbsp; +&nbsp;&nbsp;
                                                <Box display="flex" flexDirection="column" alignItems="center">
                                                    <span>RUMI IGV</span><span>18 %</span></Box>&nbsp;&nbsp;  + &nbsp;&nbsp;
                                                <Box display="flex" flexDirection="column" alignItems="center">
                                                    <span>RUMI CHARGE</span><span>8 %</span></Box>&nbsp;&nbsp;  =  &nbsp;&nbsp;
                                                <Box display="flex" flexDirection="column" alignItems="center">
                                                    <span>RUMI PRICE</span><span
                                                    style={{color: "#5BE576", fontWeight: "bold"}}>100</span></Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container item  spacing={3}>
                                <Grid item xs={12} md={2}>
                                    <InfoList title={"Tax exemption application"}
                                              description={"Tax exemptions are applied considering RUMI IGV = 0% based in the type of clients, arrival, and others according your country regulations. You can take this off by products in settings/store. "}/>
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    <Grid container item spacing={2}>
                                        <Grid item xs={6} sm={3}>
                                            <InputLabel shrink component="legend" color="secondary">Apps service</InputLabel>
                                            <Box display="flex" flexDirection="column" mr={16} mt={1.5}>
                                                <Box>
                                                    <Typography variant="subtitle1" color="textSecondary"
                                                                gutterBottom>PMS</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="subtitle1" color="textSecondary"
                                                                gutterBottom>Rumi</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={2}>
                                            <Box display="flex" flexDirection="column" mr={12}>
                                                <InputLabel shrink component="legend"
                                                            color="secondary">Exemption</InputLabel>
                                                <Box mt={1.9} >
                                                    <CheckIcon color="primary"/>
                                                </Box>
                                                <Box mt={0.5} >
                                                    <CheckIcon color="primary"/>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={10}>
                                            <Box fontStyle="oblique" borderRadius={5} color="text.secondary" p={2}
                                                style={{border: "1px solid #5BE576"}} className={`${classes.forms.formMargin}`}>
                                                <Typography variant="body1">
                                                    This value added tax is INCLUDED on the purchase price you define in By default, 
                                                    <strong> tax exemptions</strong> are provided by Rumi. Set up in
                                                    {   canSeeSettingsStore ?
                                                        <Link className={`general-nav- ${classes.nav.link}`}
                                                        to={`/${origin}/settings/store`}
                                                        style={{color: "#5BE576", margin: "0 5px"}}>settings/store</Link> 
                                                        : <strong> settings/store </strong>
                                                    }
                                                    by choose a product and set up without this application if you need.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container item  spacing={2}>
                                <Grid item xs={12} md={2} />
                                <Grid item xs={12} md={10} >
                                    {
                                        false &&
                                        <Button type="submit" variant="contained" color="primary"
                                                style={{margin: '0 0.8em 0 0', width: '100px'}}
                                                disabled={pristine || submitting}
                                        >Save</Button>
                                    }
                                    <Button variant="outlined" color="primary"
                                            style={{margin: '0 0.8em 0 0', width: '100px'}}
                                            onClick={handleCancel}
                                    >Back</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
};

TaxesForm = reduxForm({
    form: 'taxes',
    onSubmit: submitTaxesForm,
    validate
})(TaxesForm);

TaxesForm = connect(
    state => {
        const stateOutlets = state.storeReducer.outletList;
        return {
            initialValues: getFormInitialValues('taxes')(state),
            outletList: getOutletsSelector(stateOutlets),
            permissionsList: state.auth.permissionsList
        }
    }
)(TaxesForm);


export default TaxesForm