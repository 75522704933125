/*Auth*/
export const REFRESH_TOKEN_URL = 'api/core/token/refresh/';
export const AUTH_TOKEN_URL = 'api/core/token/';
export const USER_DATA_URL = 'api/core/current-user';
export const VALIDATE_TOKEN_URL = 'api/core/token/verify/';
export const INITIAL_BALANCE = 'api/balance/initial-balance';
export const BALANCE_TRANSFERS_URL = 'api/balance/balancetransfer/';
export const CURRENCIES_URL = 'api/core/currencies';
export const OPEN_BALANCE = 'api/balance/open-balance';
export const SEND_LOCATION = 'api/core/user_location';
export const SEND_USER_PROFILE = 'api/core/user-profile';
export const OBTAIN_PMS_JSONS_URL = 'api/pms/reservations';
export const PMS_AUTHORIZE_URL = 'api/pms/receive-authorization-code';