import React from 'react';
import {Link, withRouter} from "react-router-dom";
import {connect, useDispatch} from "react-redux";

import {handleViewPaymentPanel, setMenuItem} from "../../actions/actions";
import {cleanCustomerFilter} from "../../../pos/redux/actions";
import {filterSearchMultiple} from '../../actions/utilsActions'
import {selectPaymentOptions} from "../../../rumi/actions/sync"
import {activeAlertDragAndDrop, getCreatedProduct, handleDropProducts} from '../../../rumi-settings/actions/store/sync';

import {makeStyles} from '@material-ui/core/styles';
import {ListItem, ListItemIcon, Tooltip} from '@material-ui/core/';
import SubMenu from './SubMenu'

const useStyles = makeStyles((theme) => ({
    listItem: {
        cursor: 'pointer'
    },
    menuItemContainer: {
        borderRadius: "100%",
        padding: 10,
        width: 45,
        minWidth: '0!important'
    },
    menuItem: {
        color: theme.palette.grey['main'],
        '&:hover': {
            backgroundColor: theme.palette.common['rgba-black'],
            color: theme.palette.primary.main,
        },
    },
    menuItemActive: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
            backgroundColor: theme.palette.primary.dark
        }
    }
}));


const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize: '1em',
        fontWeight: "400"
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const MenuItem = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const origin = localStorage.getItem('origin');
    const currentLocation = props.location.pathname;
    const isClientLocation = currentLocation === `/${origin}` && props.item.name === "Clients";
    const isDisabledConsumptions = (props.optionClientType === "" || props.optionClientType === "External") && props.item.name === "Consumptions";
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const cleanEvents = () => {
        dispatch(cleanCustomerFilter());
        dispatch(filterSearchMultiple(""));
        dispatch(getCreatedProduct(""));
        dispatch(selectPaymentOptions(""))
        dispatch(handleDropProducts([]))
        dispatch(activeAlertDragAndDrop(false))
    };

    const handleMenu = (event, dispatch) => {
        setAnchorEl(event.currentTarget);
        if (props.item.hasOwnProperty('showPaymentPanel')) dispatch(handleViewPaymentPanel(props.item.showPaymentPanel))
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            {props.item.url !== undefined ?
                (isDisabledConsumptions || !props.item.enableButton ?
                        <ListItem disabled
                                  style={isDisabledConsumptions && props.item.enableButton ? {cursor: 'default'} : {cursor: 'not-allowed'}}
                                  className={classes.listItem}>
                            <BootstrapTooltip
                                title={isDisabledConsumptions && props.item.enableButton ? props.item.name : ""}
                                placement="right">
                                <ListItemIcon className={classes.menuItemContainer}>
                                    {props.item.icon}
                                </ListItemIcon>
                            </BootstrapTooltip>
                        </ListItem>
                        :
                        <Link to={props.item.url}>
                            <ListItem className={classes.listItem}
                                      onClick={(event) => {
                                          dispatch(setMenuItem(props.item.name));
                                          handleMenu(event, dispatch);
                                          cleanEvents()
                                      }}
                                      onMouseEnter={() => {
                                          if (props.dropProductList !== undefined) {
                                              if (props.dropProductList.length > 0) {
                                                  dispatch(activeAlertDragAndDrop(true))
                                              }
                                          }
                                      }}
                                      onMouseLeave={() => {
                                          if (props.dropProductList !== undefined) {
                                              if (props.dropProductList.length > 0) {
                                                  dispatch(activeAlertDragAndDrop(false))
                                              }
                                          }
                                      }}>
                                <BootstrapTooltip title={props.item.name} placement="right">
                                    <ListItemIcon
                                        className={`${classes.menuItemContainer} ${currentLocation === props.item.url || isClientLocation ? classes.menuItemActive : classes.menuItem}`}>
                                        {props.item.icon}
                                    </ListItemIcon>
                                </BootstrapTooltip>
                            </ListItem>
                        </Link>
                ) :
                <span style={!props.item.enableButton ? {cursor: 'not-allowed'} : {}}>
                    <ListItem disabled={!props.item.enableButton}
                              style={!props.item.enableButton ? {pointerEvents: 'none'} : {}}
                              className={classes.listItem}
                              onClick={(event) => {
                                  handleMenu(event, dispatch);
                                  if (props.dropProductList !== undefined) {
                                      if (props.dropProductList.length > 0) {
                                          dispatch(activeAlertDragAndDrop(true))
                                      }
                                  }
                              }}>
                        <BootstrapTooltip title={props.item.name} placement="right">
                            <ListItemIcon
                                className={`${classes.menuItemContainer} ${props.selectedMenuItem === props.item.name ? classes.menuItemActive : classes.menuItem}`}>
                                {props.item.icon}
                            </ListItemIcon>
                        </BootstrapTooltip>
                    </ListItem>
                </span>
            }
            {props.item.submenus &&
                <SubMenu
                    position={{left: '6.2em'}}
                    subItems={props.item.submenus}
                    anchorEl={anchorEl}
                    open={open}
                    menu={props.item.name}
                    setMenuItem={setMenuItem}
                    handleClose={handleClose}
                    cleanEvents={cleanEvents}
                    dropProductList={props.dropProductList}
                />
            }
        </React.Fragment>
    )
};

const mapStateToProps = state => {
    return {
        selectedMenuItem: state.utilsReducer.selectedMenuItem,
        optionClientType: state.paymentTypeSelected.optionClientType,
        dropProductList: state.storeReducer.dropProductList
    }
};


export default connect(mapStateToProps)(withRouter(MenuItem))