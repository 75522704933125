import products from "./products.reducer";
import category  from "./category";
import clientPanel from "./clientPanel.reducer"
import cart from "./cart.reducer";
import rooms from "./rooms"
import pmsEntities  from "./reservations"
import clientCriteria from "./clientCriteria"
import client from "./clients.reducer"
import paymentTypeSelected from "./paymentType.reducer"
import paymentCurrencyValues from "./paymentCurrencies.reducer";
import variablePriceProducts from "./variablePriceProducts.reducer"
// REPORTS
import reports from "./reports/reports";
import cashReport from "./reports/cashReport";
// BALANCE
import balance from "./balance/balance"
import closeShift from "./balance/closeShift"

export default {
    products,
    category,
    cart,
    clientPanel,
    rooms,
    clientCriteria,
    pmsEntities,
    client,
    reports,
    balance,
    closeShift,
    cashReport,
    paymentTypeSelected,
    paymentCurrencyValues,
    variablePriceProducts
}

