import "date-fns";
import React from "react";
// CSS
import Classes from '../../../../styles';
// ICONS
import TimeIcon from '@material-ui/icons/AccessTime';
// MATERIAL UI
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {makeStyles} from '@material-ui/core/styles';
import {KeyboardTimePicker, MuiPickersUtilsProvider,} from "@material-ui/pickers";


const useStyles = makeStyles((theme) => ({
    timepicker: {
        '& div': {
            //width: ' 7.8em'
        },
        '& input': {
            //width: '12em',
            //fontSize: '1em',
            color: theme.palette.grey[500]
        }
    },
}));

export default function TimePicker(props) {
    const classes = Classes()
    const useclasses = useStyles()

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container>
                <KeyboardTimePicker
                    color="secondary"
                    className={`timepicker- ${useclasses.timepicker} general-forms- ${classes.forms.textField}`}
                    margin="normal"
                    id="time-picker"
                    //label=" "
                    value={props.time}
                    onChange={props.handleGetDate.bind(this, props.timeClass)}
                    KeyboardButtonProps={{
                        "aria-label": "change time",
                    }}
                    keyboardIcon={<TimeIcon/>}
                />
            </Grid>
        </MuiPickersUtilsProvider>
    );
}