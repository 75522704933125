import { makeStyles } from '@material-ui/core/styles';


export default makeStyles((theme) => ({
    /* use */
    link:{
        textDecoration: 'none',
    },
    /* --- */
    tabPadding:{
        '& .MuiBox-root': {
            paddingTop: '3px!important'
        }
    },
    boxFilters:{
        '& .MuiBox-root': {
            paddingTop: '0!important'
        }
    },
    tab:{
        backgroundColor: 'transparent!important',
        boxShadow: 'none!important'
    },
    tabPaper:{// pos
        padding: '0.8em  0!important',
    },

}));