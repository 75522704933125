import * as api_settings from "../../api.calls";
import {LOAD_EXCHANGE_RATE} from "../../actions/actionsTypes";
import {verifyToken} from "../../../common/apiCalls";

export const validate = values => {
    const errors = {};
    if (!values.exchangeRate ) {
        errors.exchangeRate = 'Required'
    }

    if (parseFloat(values.exchangeRate) <=0.0000 ) {
        errors.exchangeRate = 'Required'
    }

    return errors
};



export const submitExchangeRateForm = async (values, dispatch, {reset, ...rest}) =>  {
    try {
        await verifyToken();
        if(values.activeSecondCurrency === false){
            values.exchangeRate = 0
        }

        const exchangeRequest = api_settings.postExchangeRate;

        const response = await exchangeRequest({
            exchangeData: values
        });

        if (response.status === 201) {
            dispatch({
                type: LOAD_EXCHANGE_RATE,
                payload: response.data[0],
            });

            reset()
        }
        if(response.status === 200) {
            dispatch({
                type: LOAD_EXCHANGE_RATE,
                payload: response.data[0],
            });
            rest.history.goBack()
        }

        rest.history.goBack()
    } catch (e) {
    }
};
