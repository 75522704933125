import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
	palette: {
		//type: 'dark',
		/* Emerald -malachite */
		primary: {
			light: '#83EC98',
			main: '#5BE576',
			dark: '#1CB039',
			contrastText: '#ffffff'
		},
		/* Medium Turquoise*/
		secondary: {
			light: '#5CD6D2',//'#7CDEDB',
			main: '#39CDC8',
			dark: '#29A39F',
			contrastText: '#ffffff'
		},
		/* Lilac */
		third: {
			main: '#C094C7',
			dark:''
		},
		/* Lilac */
		info: {
			light: '#F390BD',
			main: '#C094C7' ,
			dark: '#CA1667'
		},
		/* Golden Poppy*/
		warning: { 
			light: '',
			main: '#F5BC00',
			dark: '',
			contrastText: '#ffffff'
		},
		/* Radical red */
		error: {
			light: '',
			main: '#EC4262',
			dark: '',
			contrastText: '#ffffff'
		},
		/* others */
		utils: {
			linen: "#F5F0EB",
			
		},
		/* Emerald -malachite */
		success: {
			light: '',
			main: '#5BE576',
			dark: '',
			contrastText: '#ffffff'
		},
		grey:{
			// usan : #fafafa (fondo), #f6f6f6(paleta principal)
			'50': '#f9f9f9',
			'100': '#9e9e9e6e',
			'200': '#ebebeb',
			'300': '#e0e0e0',
			'400': '#999999',
			'500': '#9e9e9e',//#B8B8B8
			'600': '#7a7a7a',
			'700': '#707070',
			'800': '#424242',
			'900': '#212121',
			'A100': '#d5d5d5',
			'A200': '#aaaaaa',
			'A400': '#303030',
			'A700': '#000000de',
			'charcoal': '#494949',
			'matterhorn': '#4D4D4D',
			'main': '#7a7a7a'
		},
		common: {
			'rgba-black': 'rgba(0, 0, 0, 0.04)',
			'rgba-white': 'rgba(253, 253, 253, 0.86)',
			black: '#0a0a0a',
			white: '#ffffff'
		},
		text: {
			primary: '#4a4a49',
			secondary: '#9e9e9e',
			disabled: 'rgba(0, 0, 0, 0.38)',
			hint: '#999999'
		}
		// shadow item-menu: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
	}

	
});