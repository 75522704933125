import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {cleanCustomerFilter} from "../../pos/redux/actions";
import {filterSearchMultiple} from '../../common/actions/utilsActions'
import {getCategories} from '../actions/store/async';
import {getCustomizedCharges} from '../../rumi-settings/actions/store/sync'
// CSS
import Classes from '../../../styles'
// COMPONENTS
import Preview from '../components/store/Preview';
import CategoryListView from './store/CategoryListView';
import ProductListView from './store/ProductListView';
import SearchMultiple from '../../common/components/utils/SearchMultiple';

// MATERIAL UI
import {Box, Button, Grid,  Typography, FormLabel,FormHelperText, Divider, Hidden} from '@material-ui/core';
import {Link, useRouteMatch} from "react-router-dom";
import CategoryForm from "../components/store/CategoryForm";
import PreviewStore from "./store/PreviewStore";
import VisibilityIcon from '@material-ui/icons/Visibility';
import DragIcon from '@material-ui/icons/PanToolOutlined';
import {SET_GROUP_PAYMENT} from "../../rumi/actionTypes";
import {POS_ORIGIN, RUMI_ORIGIN} from "../../common/commonConstants";
import InfoList from "../components/utils/InfoList";
import {red} from "react-color/lib/helpers";
import AlertIcon from '@material-ui/icons/ErrorOutlineOutlined';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    gridContainer:{
        paddingLeft: 20,
        [theme.breakpoints.down('sm')]: {
            paddingLeft:0
        },
    },
    gridProductList:{
        paddingTop: 0,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 20,
        },
    },
    btnSaveDragAndDrop :{
        width:110,
        fontSize: '0.875rem!important'
    }
}));

const Store = ({selectedCategoryName, dropProductList, putAllProducts, filtersVisibleProducts, handleDropProducts, 
    searchValue, getCreatedProduct, getEventTypeProduct, activeAlertDragAndDrop, alertDragAndDrop, canManage}) => {
    const classes = Classes();
    const useclasses = useStyles();
    const dispatch = useDispatch();
    let {path} = useRouteMatch();
    const [state, setState] = React.useState({
        inputCategory: '',
        dragProduct: {},
        indexProduct: 0,
        dropProducts: [],
        select: 2,
        refresh: false
    });

    const onDragStart = (ev, obj) => {
        ev.dataTransfer.setData("productId", obj.id);
        ev.dataTransfer.setData("categoryId", obj.category);
        ev.dataTransfer.setData("productName", obj.name);

    };

    const getValueCategory = (event) => {
        setState({
            ...state,
            inputCategory: event.target.value
        })
    };

    const cleanSearch = () => {
        dispatch(cleanCustomerFilter());
        dispatch(filterSearchMultiple(""))
    }

    const selectedFilter = (value,num) => {
        filtersVisibleProducts(value)
        let auxSelectFilter = num
        setState({
            ...state,
            select: auxSelectFilter
        })
        cleanSearch()

    }

    const handleSaveMoves = () => {
        putAllProducts(dropProductList)
        activeAlertDragAndDrop(false)
        handleDropProducts([])
        getCreatedProduct("")
        let auxRefresh = !state.refresh
        setState({
            ...state,
            refresh: auxRefresh
        })
    };

    useEffect(() => {
        dispatch(getCategories())
        getEventTypeProduct("")
        dispatch(getCustomizedCharges(null))
    },[state.refresh]);

    return (
        <Grid container spacing={3} style={{paddingRight: 7}}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="h1" className={classes.typography.pageTitles}>
                    <Box display="inline" color="text.secondary">
                        Settings /
                    </Box>&nbsp;Store
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <InfoList
                    title={"List categories & products:"}
                    description={"Allows to create new categories to show on RUMIpos or RUMIbilling and group together different products."}/>
            </Grid>
            <Divider light style={{width: "100%"}} />
            <Grid item xs={12} >
                <Grid container item >
                    <Grid item xs={10} sm={10} md={6} lg={6} style={{paddingRight: 20}}>
                        <Box mt={dropProductList !== undefined && dropProductList.length > 0 ? 1 :0}>
                            <SearchMultiple />
                        </Box>
                    </Grid>
                    <Hidden smDown>
                        <Grid item  md={5} lg={5} >
                            {
                                dropProductList !== undefined &&
                                dropProductList.length > 0  ?
                                <Box display="flex" alignItems="center" p={1} borderRadius={5}  
                                style={alertDragAndDrop ? {border: "1px solid #F5BC00", boxShadow: "1px 1px 2px #F5BC00, 0 0 25px #F5BC00, 0 0 5px #F5BC00"}:{border: "1px solid #F5BC00"}}>    
                                    <AlertIcon style={{color: "#F5BC00"}}/>
                                    <Box display="flex" p={0} flexGrow={1} ml={3} color="text.secondary" fontStyle="oblique">
                                        <Typography variant="body2" >
                                            Please, save your moves &nbsp;&nbsp;
                                        </Typography><DragIcon style={{fontSize:20}} />
                                        <Typography variant="body2" >&nbsp;))</Typography>
                                    </Box>
                                    <Button className={useclasses.btnSaveDragAndDrop} size="small" variant="contained" color="primary"
                                            disabled={dropProductList !== undefined ? (dropProductList.length > 0 ? false : true) :  true}
                                            onClick={handleSaveMoves}>
                                        Save  
                                    </Button>
                                </Box> : null
                            }
                        </Grid>
                    </Hidden>
                    <Grid item xs={2}  sm={2} md={1} lg={1}>
                        <Box display="flex" justifyContent="flex-end" mt={dropProductList !== undefined && dropProductList.length > 0 ? 1 :0}>
                            <Preview
                            btnOpen={<Button  variant="contained" color="secondary" onClick={cleanSearch} ><VisibilityIcon/></Button>}
                            title={"Store Preview"}
                            tabsView={<div>
                                        <Button variant={state.select === 1 ? "contained" : "outlined"}
                                                color="primary" size="large"
                                                className={` ${classes.typography.textCapitalize} `}
                                                onClick={() => selectedFilter(RUMI_ORIGIN,1)}
                                                style={{borderBottomRightRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 20, borderTopLeftRadius: 20}}
                                        >
                                            BILLING
                                        </Button>
                                        <Button variant={state.select === 2? "contained" : "outlined"}
                                                color="primary" size="large"
                                                className={`${classes.typography.textCapitalize}`}
                                                style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0, borderBottomRightRadius: 20, borderTopRightRadius: 20}}
                                                onClick={()=>selectedFilter(POS_ORIGIN,2)}
                                        >
                                            POS
                                        </Button>
                                    </div>}
                            body={<PreviewStore/>} />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container item >
                    <Grid item xs={12} sm={12} md={4} lg={4} >
                        <Grid container item className={classes.containers.dividerVertical}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <CategoryForm />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                <CategoryListView
                                    inputCategory={state.inputCategory}
                                    getValueCategory={getValueCategory}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid  item xs={12} sm={12} md={8} lg={8} className={`${useclasses.gridContainer} ${useclasses.gridProductList}`}>
                        <Grid container item >
                            <Grid item xs={12} sm={8} md={8} lg={8}>
                                <Typography variant="h6" gutterBottom  >
                                    <Box display="inline" color="text.secondary">
                                        { !searchValue || !selectedCategoryName ?  selectedCategoryName : "Products"}
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                {
                                    canManage ? 
                                    <Link to={`${path}products`}  className={`general-nav- ${classes.nav.link}`} >
                                        <Button fullWidth variant="contained" color="secondary" onClick={()=>{ 
                                            handleDropProducts([])
                                            activeAlertDragAndDrop(false)
                                            getEventTypeProduct("CREATE")}}
                                            onMouseEnter={()=> {
                                                if(dropProductList !== undefined){
                                                    if(dropProductList.length > 0) {
                                                        activeAlertDragAndDrop(true)
                                                    }
                                                }
                                            }}
                                            onMouseLeave={()=> {
                                                if(dropProductList !== undefined){
                                                    if(dropProductList.length > 0) {
                                                        activeAlertDragAndDrop(false)
                                                    }
                                                }
                                            }}>
                                            Add Product
                                        </Button>
                                    </Link>
                                    : <span style={{cursor:'not-allowed'}}>
                                        <Button fullWidth variant="contained" color="secondary" disabled>
                                        Add Product
                                        </Button></span>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop: 10}}>
                                <ProductListView onDragStart={onDragStart} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};


export default Store