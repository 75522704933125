import React from "react";
import {connect} from "react-redux";
import {useRouteMatch} from 'react-router-dom';
import {taxes} from "../../../rumi-settings/permissionsConstants";

import {editTaxes, removeTaxes} from "../../actions/sync";
// CSS
// COMPONENTS
import TaxesTable from './TaxesTable';
// MATERIAL UI
import {Button, Grid} from '@material-ui/core/';

import {taxesList} from "../../selector/billing.selector";

const TaxesListView = ({taxesList, removeTaxes, editTaxes, permissionsList}) => {
    let {path} = useRouteMatch();
    const permissions = Object.values(permissionsList);
    const canManageTaxes = permissions.includes(taxes) || permissions.includes("superuser");
    const columns = ['Id', 'Type', 'Name', 'Value', 'Options'];

    return (
        <Grid container item>
            <Grid item xs={12} sm={10} lg={8}>
                <Button variant="contained"
                style={{
                    marginBottom: '0', pointerEvents: "none",
                    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14)",
                    borderRadius: 0, backgroundColor: "rgba(57, 205, 200, 0.1)", color: "#4a4a49"
                }}
                fullWidth
                >CHARGE </Button> 
            </Grid>
            <Grid item xs={12} sm={10} lg={8}>
                <TaxesTable
                    remove={removeTaxes}
                    edit={editTaxes}
                    canManage={canManageTaxes}
                    entries={taxesList}
                    columns={columns}
                    origin={path}
                    entity={"taxes"}

                />
            </Grid>
        </Grid>
    )
};

const mapStateToProps = state => ({
    taxesList: taxesList(state.billingSettingReducer.taxesList),
    permissionsList: state.auth.permissionsList
});

export default connect(mapStateToProps, {removeTaxes, editTaxes})(TaxesListView)