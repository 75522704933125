import React, {useEffect} from 'react'
import {connect, useDispatch, useSelector} from "react-redux"
import {withRouter} from "react-router-dom";
import {getCartProducts} from "../../selector";
import {removeNoticeCart} from "../../redux/actions"
// COMPONENTS
import ProductView from "./ProductView";
import Category from "../../components/products/category/CategoryList";
import SearchMultiple from '../../../common/components/utils/SearchMultiple'
import salesHistoryFiltered from "../../redux/selector/salesHistoryFiltered"
import StoreIcon from '@material-ui/icons/LocalGroceryStoreOutlined';

// MATERIAL UI COMPONENTS
import Classes from '../../../../styles/'
import {Box, Button, Container, Grid, Hidden, Snackbar, Typography} from "@material-ui/core/";
import Loader from "../../../common/components/utils/Loader";
import {handleViewPaymentPanel} from "../../../common/actions/actions";
import {billing_sales_history, pos_sales_history} from "../../../rumi-settings/permissionsConstants";
import {SET_GROUP_PAYMENT} from "../../../rumi/actionTypes";

const Products = ({
                      title,
                      mainApp,
                      paymentPanel,
                      permissionsList,
                      salesHistoryList,
                      chargedProduct,
                      showNoticeCart
                  }) => {
    const classes = Classes();
    const productsAreLoading = useSelector(state => state.products.isLoading);
    const categoriesAreLoading = useSelector(state => state.category.isLoading);
    const dispatch = useDispatch();
    const salesHistoryFiltered = salesHistoryList.filter(list => list.type !== "Chrg");
    const permissions = Object.values(permissionsList);
    const canSeeSaleHistory = permissions.includes(billing_sales_history) || permissions.includes(pos_sales_history) || permissions.includes("superuser");

    const handleCloseNotificationStore = () => {
        setTimeout(() => {
            dispatch(removeNoticeCart())
        }, 3000);
    };

    useEffect(() => {
        if (!paymentPanel) {
            dispatch(handleViewPaymentPanel(true));
        }
        dispatch({type: SET_GROUP_PAYMENT, payload: false});
        setTimeout(() => handleCloseNotificationStore(), 3000);

    }, [showNoticeCart]);


    if (productsAreLoading && categoriesAreLoading) {
        return (
            <Container className={classes.styles.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Loader/>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {
                    Object.values(chargedProduct).length > 0 &&
                    <Hidden mdUp>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={showNoticeCart}
                            message={chargedProduct.qty + ' ' + chargedProduct.name}
                            action={
                                <React.Fragment>
                                    <StoreIcon/>
                                </React.Fragment>
                            }
                            onClick={() => dispatch(removeNoticeCart())}
                        />
                    </Hidden>
                }
                <Typography variant="h1" className={classes.typography.pageTitles}>{title}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Box display="flex">
                    <Box width={400}>
                        <SearchMultiple/>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} md={4}>
                <Category/>
            </Grid>
            <Grid item xs={12} md={8}>
                <Box paddingRight={0}>
                    <Typography variant="h6">
                        <Box color="text.secondary" className={classes.typography.pageSubTitles}>Products</Box>
                    </Typography>
                </Box>
                <ProductView/>
            </Grid>
        </Grid>
    )
};

const mapStateToProps = state => {
    const products = getCartProducts(state);
    const chargedProductId = state.cart.chargedProduct;
    const getChargedProduct = products.filter(prod => chargedProductId === prod.id);
    const chargedProduct = getChargedProduct.length > 0 ? {
        name: getChargedProduct[0].name,
        qty: getChargedProduct[0].quantity
    } : {};

    return {
        mainApp: localStorage.getItem("origin"),
        paymentPanel: state.utilsReducer.showPaymentPanel,
        permissionsList: state.auth.permissionsList,
        salesHistoryList: salesHistoryFiltered(state.balance.salesHistory, state.utilsReducer.search),
        chargedProduct: chargedProduct,
        showNoticeCart: state.cart.showNoticeCart
    }
};

export default connect(mapStateToProps)(withRouter(Products))