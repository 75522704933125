import {FETCHING_RESERVATIONS, LOAD_RESERVATION} from "../redux/actionTypes";

const initialState = {
    reservation: {},
    guest: {},
    roomsReservation: {},
    isLoading: true,
    fetching:false
};

const pmsEntities = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_RESERVATION:
            const {reservations, customers, roomReservations} = action.payload;
            const repetitions = [];
            return {
                reservation: reservations.reduce((allReservation, reservation) => {
                    allReservation[reservation.reservationId] = reservation
                    return allReservation
                }, {}),
                guest: customers.reduce((allClient, client) => {
                    if(repetitions.includes(client.customerId)){
                        if(allClient[client.customerId].variations){
                            allClient[client.customerId].variations.push(client)
                        }else{
                            allClient[client.customerId].variations = [];
                            allClient[client.customerId].variations.push(client);
                        }
                    }else{
                        repetitions.push(client.customerId);
                        allClient[client.customerId] = client;
                    }
                    return allClient;
                }, {}),
                roomsReservation: roomReservations,
                isLoading: false,
                fetching:false
            };
        case FETCHING_RESERVATIONS:
            return {...state, fetching: true};
        default:
            return state
    }
};

export const getClient = (state, id) => state.guest[id];
export const getReservation = (state, id) => state.reservation[id];
export const getReservationsIds = (state) => Object.keys(state.reservation);
export const getClientsIds = (state) => Object.keys(state.guest);

export const getRoomsReservation = state => state.roomsReservation;
export const getPMSGuest = state => state.guest;
export const getPMSReservation = state => state.reservation;

export default pmsEntities