import React, {useState} from "react";
import {Box, Button, TextField} from "@material-ui/core/";
import {connect, useDispatch} from "react-redux"
import Classes from '../../../../styles'
import {openModalView} from "../../../common/actions/utilsActions";

import GroupInformation from "./GroupInformation";
import {SET_COMPANY, SET_COMPANY_FLAG, SET_GROUP_PAYMENT} from "../../../rumi/actionTypes";
import {loadConsumption} from "../../../rumi/actions/async";
import {selectPaymentOptions} from "../../../rumi/actions/sync"

import {withRouter} from "react-router-dom";


const CompanyForm = ({typeCompany,company, taxPayer, taxPayerNumber,address }) => {
    const classes = Classes();

    const dispatch = useDispatch();
    const [companyInfo, setCompanyInfo] = useState({
        taxPayer: taxPayer,
        taxPayerNumber: taxPayerNumber,
        address: address,
    });

    const validateCurrentInfo = company.taxPayer.length === 0 ? true : (companyInfo.taxPayer !== taxPayer || companyInfo.taxPayerNumber !== taxPayerNumber || companyInfo.address !== address)

    const validateSaveBtn = validateCurrentInfo && companyInfo.address.length > 0 && companyInfo.taxPayer.length > 0 && companyInfo.taxPayerNumber.length === 11


    const handleCompanyInfoChange = (evt) => {
        const value = evt.target.value;
        setCompanyInfo({
            ...companyInfo,
            [evt.target.name]: value,
        });
    };

    const handleCompanyInfoSave = () => {
        dispatch({type: SET_COMPANY, payload: companyInfo});
    };

    const handleCompanyPaymentChange = (value) => {
        dispatch({type: SET_COMPANY_FLAG, isCompany: value});
    };

    const handleGroupPaymentChange = (value) => {
        dispatch({type: SET_GROUP_PAYMENT, payload: value});
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" style={{width: "100%"}}>
            <TextField id="taxPayer" name="taxPayer" label="Tax Payer"
                        size="small"
                        className={`${classes.forms.textField} ${classes.forms.inputExternal}`}
                        value={companyInfo.taxPayer}
                        onChange={handleCompanyInfoChange}
            />
            <br/>
            <TextField id="taxPayerNumber" name="taxPayerNumber" label="Tax Payer Number"
                        size="small"
                        type="number"
                        value={companyInfo.taxPayerNumber}
                        className={`${classes.forms.textField} ${classes.forms.inputExternal}`}
                        onChange={handleCompanyInfoChange}
            />
            <br/>
            <TextField id="address" name="address" label="Address"
                        size="small"
                        value={companyInfo.address}
                        className={`${classes.forms.textField} ${classes.forms.inputExternal}`}
                        onChange={handleCompanyInfoChange}
            />
            <br/>
            <br/>
            {
                typeCompany === 'Company group' && 
                <GroupInformation/>
            }
            <Box display="flex" alignItems="center" justifyContent="center" mt={5} >
                <Button variant="outlined" color="primary" style={{marginRight: '1em'}} 
                onClick={()=> {dispatch(openModalView("")) }} >
                    CANCEL
                </Button>
                <Button variant="contained" color="primary" 
                style={{width: '6em'}} disabled={!validateSaveBtn}
                onClick={()=> {
                    dispatch(openModalView(""))
                    handleCompanyInfoSave()
                    dispatch(selectPaymentOptions(typeCompany))
                    if(typeCompany === 'Company group') {
                        handleCompanyPaymentChange(true)
                        handleGroupPaymentChange(true)
                    }else {
                        handleCompanyPaymentChange(true)
                    }
                    dispatch(loadConsumption())
                }} >
                   SAVE
                </Button>
            </Box>
        </Box>
    )
};

const mapStateToProps = (state) => {
    return {
        isCompany: state.paymentState.isCompany,
        taxPayer: state.paymentState.company.taxPayer,
        taxPayerNumber: state.paymentState.company.taxPayerNumber,
        address: state.paymentState.company.address,
        company: state.paymentState.company
    }
};

export default connect(mapStateToProps)(withRouter(CompanyForm))
