import {
    SEARCH_SETTINGS_USER,
    SEARCH_SETTINGS_GROUPS,
    LOAD_ACCOUNTS,
    LOAD_USERS,
    ADD_USER,
    EDIT_USER,
    REMOVE_USER,
    SEARCH_SETTINGS_VOLUNTEERS
} from "../actions/actionsTypes";


const initialState = {
    userSearch:"",
    groupSearch:"",
    volunteerSearch:""
};

const employeesAccountsSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_SETTINGS_USER:
            return {...state,userSearch:action.payload};
        case SEARCH_SETTINGS_GROUPS:
            return {...state,groupSearch:action.payload};
        case SEARCH_SETTINGS_VOLUNTEERS:
            return {...state,volunteerSearch:action.payload};
        default:
            return state
    }
};

export default employeesAccountsSearchReducer;