import React, {useState} from "react";
import {Box, Typography,Grid} from "@material-ui/core/";
import {connect, useDispatch} from "react-redux"
import Classes from '../../../../styles'

import {groupSelector} from "../../selector/customer.selector";
import {withRouter} from "react-router-dom";


const CompanySection = ({location, client,groupMembers,selectedPaymentOption, taxPayer, taxPayerNumber, address}) => {
    const classes = Classes();
    const isCompanyGroup = selectedPaymentOption === 'Company group'
    const taxPayerToLowerCase = !taxPayer? "" : taxPayer.toLowerCase();
    const clientFullNameLowerCase = !client.fullName ? "" : client.fullName.toLowerCase();

    return (
        <Grid container item>
            <Grid item xs={1} />
            <Grid item xs={11}>
                <Box color="textPrimary" align='center'>
                    <Typography variant="subtitle1" className={` ${classes.typography.fontBold}`} style={{textTransform: 'capitalize'}}>
                        {taxPayerToLowerCase}
                    </Typography>
                    {
                        isCompanyGroup && 
                        <Box bgcolor="primary.main" color="common.white" p={0.3} width={"25%"} 
                        style={{borderRadius: 20, fontSize:12, fontWeight:"bold"}}>
                            {groupMembers.length + " friends"}</Box>
                    }
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box align="left">
                    <Box color="text.secondary">
                        <Typography variant="caption">
                            Type: { isCompanyGroup ? "Group company" : "Company" }
                        </Typography>
                    </Box>
                    <Box color="text.secondary">
                        <Typography variant="caption">
                        Tax payer number: {taxPayerNumber}
                        </Typography>
                    </Box>
                    <Box color="text.secondary">
                        <Typography variant="caption">
                            Address: {address}
                        </Typography>
                    </Box>
                    <Box color="text.secondary">
                        <Typography variant="caption" style={{textTransform: 'capitalize'}}>
                            Client reference: {clientFullNameLowerCase}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
};

const mapStateToProps = (state) => {
    return {
        client: state.client,
        groupMembers: groupSelector(state),
        taxPayer: state.paymentState.company.taxPayer,
        taxPayerNumber: state.paymentState.company.taxPayerNumber,
        address: state.paymentState.company.address,
        selectedPaymentOption:  state.paymentState.selectedPaymentOption
    }
};

export default connect(mapStateToProps)(withRouter(CompanySection))
