import React, {useEffect} from "react";
import {connect, useDispatch} from "react-redux";
// COMPONENTS
import SimpleTable from "../../../common/components/utils/SimpleTable";
import Filters from "../../components/reports/Filters";
import ExportButtons from "../../../common/components/utils/ExportButtons";
import Classes from "../../../../styles";
import {handleViewPaymentPanel} from "../../../common/actions/actions";
import {loadBalanceFlowsReport} from "../../redux/asyncActions";
import {Box, Container, Grid, Typography} from "@material-ui/core";
import Loader from "../../../common/components/utils/Loader";
import ReportsSearchPanel from "../../../common/components/utils/reportsSearchPanel";
import {searchBalanceFlowsReport} from "../../utils/reports.utils";
import {setFilterBalanceFlowsReport} from "../../redux/actions";
import {reportsSelector} from "../../selector/reportsSelector";

const BalanceFlowsReport = ({report, isLoading, paymentPanel}) => {
    const classes = Classes();
    const dispatch = useDispatch();
    const currencyCode = localStorage.getItem('currencyCode')
    const secondaryCurrencyCode = localStorage.getItem('secondaryCurrencyCode')

    const columns = {
        "date": "Date",
        "transaction": "Transaction",
        "user": "User",
        "cardBrand": "Card brand",
        "paymentType": "Payment type",
        "openPrincipal": `Open ${currencyCode}`,
        "openSecondary": `Open ${secondaryCurrencyCode}`,
        "inflowPrincipal": `Inflow ${currencyCode}`,
        "inflowSecondary": `Inflow ${secondaryCurrencyCode}`,
        "outflowPrincipal": `Outflow ${currencyCode}`,
        "outflowSecondary": `Outflow ${secondaryCurrencyCode}`,
    };

    useEffect(() => {
        if (paymentPanel) {
            dispatch(handleViewPaymentPanel(false));
        }
        dispatch(loadBalanceFlowsReport());

    }, [isLoading]);

    if (isLoading) {
        return (
            <Container className={classes.styles.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Loader/>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h1" className={classes.typography.pageTitles}>
                    <Box color="text.secondary" display="inline"> Reports /</Box>
                    &nbsp;Balance Flows
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container item>
                    <Grid item xs={12} sm={12} md={5} lg={4}>
                        {/*<ReportsSimpleFilterParameterPanel panelClass={"guest-flows-report"} />*/}
                    </Grid>
                    <Grid item xs={12} sm={7} md={3} lg={4}>
                        <ReportsSearchPanel searcher={searchBalanceFlowsReport}/>
                    </Grid>
                    <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            {/*<Filters setReportFilter={setFilterBalanceFlowsReport}/>*/}
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={2} md={2} lg={2}>
                        <Box display="flex" flexDirection="row-reverse">
                            <ExportButtons report={report} name={"Balance-flows-report"}/>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <SimpleTable report={report} amountKey={8} columns={columns} indexListResponsiveTable={[0, 4, 2]}/>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    report: reportsSelector(state, "balance-flows"),
    isLoading: state.reports.balanceFlowsReport.balanceFlowsAreLoading,
    paymentPanel: state.utilsReducer.showPaymentPanel
});

export default connect(mapStateToProps, {setFilterBalanceFlowsReport})(BalanceFlowsReport)