import "date-fns";
import React from "react";
// CSS
import Classes from "../../../../styles";
// MATERIAL UI
import {makeStyles} from '@material-ui/core/styles';
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
    datepicker: {
        '& div': {
            //width: '8.8em'
        },
        '& input': {
            //width: '17em',
            //fontSize: '1em',
            color: theme.palette.grey[500]
        },
    },
}));

export default function DatePicker(props) {
    const classes = Classes()
    const useclasses = useStyles();

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container>
                <KeyboardDatePicker
                    color="secondary"
                    className={`datepicker- ${useclasses.datepicker}  general-forms- ${classes.forms.textField}`}
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label={props.label}
                    value={props.date}
                    onChange={props.handleGetDate.bind(this, props.dateClass)}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                />
            </Grid>
        </MuiPickersUtilsProvider>
    );
}