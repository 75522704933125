import React, {useEffect} from "react";
// COMPONENTS
import SimpleTable from "../../../common/components/utils/SimpleTable";
import Filters from "../../components/reports/Filters";
import ExportButtons from "../../../common/components/utils/ExportButtons";
// COMPONENTS MATERIAL UI
import {Box, Container, Grid, Typography} from "@material-ui/core";
import {connect, useDispatch} from "react-redux";
import Loader from "../../../common/components/utils/Loader";
import Classes from "../../../../styles";
import {handleViewPaymentPanel} from "../../../common/actions/actions";
import {setFilterGuestFlowsReport} from '../../redux/actions';
import {loadGuestFlowsReport} from "../../redux/asyncActions";
import {searchGuestFlowsReport,} from "../../utils/reports.utils";
import {reportsSelector} from "../../selector/reportsSelector";
import ReportsSearchPanel from "../../../common/components/utils/reportsSearchPanel";
import ReportsSimpleFilterParameterPanel from "../../../common/components/utils/reportsSimpleFilterParameterPanel";

const GuestFlowsReport = ({report, isLoading, paymentPanel}) => {
    const classes = Classes();
    const dispatch = useDispatch();


    const columns = {
        "date": "Date/Time",
        "user": "User",
        "guestName": "Guest name",
        "paymentType": "Payment type",
        "currency": "Currency",
        "total": "Total",
        "eCorrelative": "E_Correlative",
        "documentType": "Document type",
        "download":"Download"
    };

    useEffect(() => {
        if (paymentPanel) {
            dispatch(handleViewPaymentPanel(false));
        }
        dispatch(loadGuestFlowsReport());

    }, [isLoading]);

    if (isLoading) {
        return (
            <Container className={classes.styles.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Loader/>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h1" className={classes.typography.pageTitles}>
                    <Box color="text.secondary" display="inline"> Reports /</Box>
                    &nbsp;Guest Flows
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container item>
                    <Grid item xs={12} sm={12} md={5} lg={4}>
                        <ReportsSimpleFilterParameterPanel panelClass={"guest-flows-report"}/>
                    </Grid>
                    <Grid item xs={12} sm={7} md={3} lg={4}>
                        <ReportsSearchPanel searcher={searchGuestFlowsReport}/>
                    </Grid>
                    <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Filters setReportFilter={setFilterGuestFlowsReport}/>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={2} md={2} lg={2}>
                        <Box display="flex" flexDirection="row-reverse">
                            <ExportButtons report={report} name={"Guest-flows-report"}/>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <SimpleTable report={report} amountKey={8} columns={columns} indexListResponsiveTable={[0, 4, 2]}/>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    report: reportsSelector(state, "guest-flows"),
    isLoading: state.reports.guestFlowsReport.guestFlowsAreLoading,
    paymentPanel: state.utilsReducer.showPaymentPanel
});

export default connect(mapStateToProps, {setFilterGuestFlowsReport})(GuestFlowsReport)