import React, {useEffect, useState} from 'react';
import {Field, formValueSelector, reduxForm} from 'redux-form'
import {getPMSGuest} from "../../../pos/redux/asyncActions"
import {handleViewPaymentPanel} from "../../../common/actions/actions" 

// MATERIAL UI
import {Box, Button, FormControl, Grid, InputLabel} from '@material-ui/core';
// CSS
import Classes from '../../../../styles';
import {connect, useDispatch} from "react-redux";
import {renderSelectField, renderSwitch, renderTextField} from "../../../common/components/utils/ReduxFromMaterialUI";
import {submitEmployeesForm, validate} from "../../forms/submit/customer.submit";
import {customerSelected} from "../../selector/customer.selector";
import VolunteerModal from "./VolunteerModal";
import {getCommonSelection} from "../../actions/asyncAction";
import MenuItem from '@material-ui/core/MenuItem';


let FormEmployeeReduxForm = (props) => {
    const classes = Classes();
    const dispatch = useDispatch();
    const {handleSubmit, pristine, reset, submitting, countryList, identityList, searchModal, pmsId,  initialValues} = props;
    const [documentLenght, setDocumentLenght] = useState(8);

    const handleDniPassportSelectionChange = (e) => {
        if(e.target.value === 'PASSPORT'){
            setDocumentLenght(11)
        }else{
            setDocumentLenght(8)
        }
    };

    const handleCancel = () => {
        reset();
        window.history.back()
    };

    useEffect(() => {
            dispatch(handleViewPaymentPanel(false))
            dispatch(getPMSGuest());
            dispatch(getCommonSelection());
        }, [dispatch]
    );

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={8} md={8} lg={7}>
                    <Box display="flex" flexDirection="column">
                        <Box display="flex" flexDirection="row"
                             className={`general-forms- ${classes.forms.formMargin}  general-forms- ${classes.forms.fullFormWidth}`}>
                            <FormControl style={searchModal && !pmsId ? {width: "33em"} : {width: "38em"}}
                            >
                                <Field
                                    id="standard-input"
                                    component={renderTextField}
                                    label="First name *"
                                    name="name"
                                    className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} `}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                            {
                                searchModal && !pmsId &&
                                <React.Fragment>
                                    <VolunteerModal/>
                                </React.Fragment>
                            }
                        </Box>
                        <FormControl
                            className={`general-forms- ${classes.forms.formMargin} general-forms- ${classes.forms.fullFormWidth}`}>
                            <Field
                                id="standard-input"
                                label="Surname *"
                                name="surname"
                                component={renderTextField}
                                className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} `}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>
                        <FormControl
                            className={`general-forms- ${classes.forms.formMargin} general-forms- ${classes.forms.fullFormWidth}`}>
                            <Field
                                className={`${classes.forms.select}`}
                                component={renderSelectField}
                                label="Country *"
                                name="country"
                            >
                                <MenuItem value="">
                                    <em>Select a Country</em>
                                </MenuItem>
                                {countryList.map((country, index) =>
                                    <MenuItem key={index + 1} 
                                              aria-label="None" className={classes.forms.option} value={country.code}
                                    >{country.name}</MenuItem>
                                )}
                            </Field>
                        </FormControl>
                        <FormControl
                            className={`general-forms- ${classes.forms.formMargin} general-forms- ${classes.forms.fullFormWidth}`}>
                            <Field
                                className={`${classes.forms.select}  `}
                                component={renderSelectField}
                                onChange={handleDniPassportSelectionChange}
                                label="Document Type *"
                                name="document">
                                <MenuItem value="">
                                    <em>Select a document...</em>
                                </MenuItem>
                                {identityList.map((idType, index) =>
                                    <MenuItem key={index + 1} aria-label="None"
                                              className={classes.forms.option}
                                              value={idType.code}>{idType.name}</MenuItem>
                                    
                                )}
                            </Field>
                        </FormControl>
                        <FormControl
                            className={`general-forms- ${classes.forms.formMargin} general-forms- ${classes.forms.fullFormWidth}`}>
                            <Field
                                id="standard-input"
                                label="Document Number *"
                                type="text"
                                name="identityNumber"
                                component={renderTextField}
                                inputProps={{
                                    //minLength: documentLenght,
                                    minlenght:documentLenght,
                                    maxLength:documentLenght
                                }}
                                className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} `}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>
                        <FormControl
                            className={`general-forms- ${classes.forms.formMargin} general-forms- ${classes.forms.fullFormWidth}`}>
                            <Field
                                id="standard-input"
                                component={renderTextField}
                                label="Email *"
                                name="email"
                                className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} `}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>
                        <FormControl
                            className={`general-forms- ${classes.forms.formMargin} general-forms- ${classes.forms.fullFormWidth}`}>
                            <Field
                                name="active"
                                label="Active"
                                component={renderSwitch}
                                value={true}
                            />
                        </FormControl>
                        <FormControl
                            className={`${classes.forms.formMargin} general-forms- ${classes.forms.fullFormWidth}`}>
                            <Field
                                name="blocked"
                                label="Blocked"
                                component={renderSwitch}
                                value={true}
                            />

                        </FormControl>
                        <InputLabel shrink component="legend" color="secondary">Cannot put items on tap. </InputLabel>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary"
                            style={{margin: '0 0.8em 0 0', width: '100px'}}
                            disabled={pristine || submitting}
                    >Save</Button>
                    <Button type="button" variant="outlined" color="primary"
                            style={{margin: '0 0.8em 0 0', width: '100px'}}
                            onClick={handleCancel}
                    >Cancel</Button>
                </Grid>
            </Grid>
        </form>
    );
};

FormEmployeeReduxForm = reduxForm({
    form: 'employees',
    onSubmit: submitEmployeesForm,
    validate,
})(FormEmployeeReduxForm);
const selector = formValueSelector('employees');
FormEmployeeReduxForm = connect(
    state => ({
        pmsId: selector(state, 'pmsGuestId'),
        countryList: Object.values(state.commonOptions.country),
        identityList: Object.values(state.commonOptions.idType),
        initialValues: customerSelected(state)
    })
)(FormEmployeeReduxForm);

export default FormEmployeeReduxForm