import {
    ADD_STAY_QUANTITY, CLEAN_CONSUMPTION,
    LOAD_SELECTED_GUEST_CONSUMPTION,
    REDUCE_STAY_QUANTITY,
    SET_ACCOMMODATION_DISCOUNT,
    SET_ANCILLARY_DISCOUNT,
    SET_STAY_QUANTITY,
    TOGGLE_OUTLET
} from "../actionTypes"

const initialState = {
    accommodation: {
        title: "Accommodations",
        code: "ACCO",
        items: {},
        discount: 0.0,
        total: 0.0,
        discountReason: "",
    },
    ancillary: {
        title: "Ancillaries",
        code: "ANCY",
        items: {},
        total: 0.0,
        discount: 0.0,
        discountReason: "",
        itemPrices: {}
    }
};

function stay(state, action) {
    switch (action.type) {
        case ADD_STAY_QUANTITY:
            return {...state, quantity: state.quantity < state.maxQuantity ? state.quantity + 1 : state.maxQuantity};
        case REDUCE_STAY_QUANTITY:
            return {...state, quantity: state.quantity > 1 ? state.quantity - 1 : 1};
        case SET_STAY_QUANTITY:
            let {quantity} = action;
            quantity = Number(quantity)
            return {...state, quantity: Math.max(0,Math.min(state.maxQuantity, quantity))};
        default:
            return state;
    }
}

const accommodations = (state = initialState.accommodation, action) => {
    switch (action.type) {
        case SET_ACCOMMODATION_DISCOUNT:
            return {...state, discount: action.payload};
        default:
            const {stayId} = action;
            if (stayId) {
                return {
                    ...state,
                    items: {...state.items, [stayId]: stay(state.items[stayId], action)}
                }
            }
            return state
    }
};

const ancillary = (state, action) => {

    switch (action.type) {
        case TOGGLE_OUTLET :
            return {...state, selected: !state.selected, quantity: Number(!state.selected)};
        default:
            return state;
    }
};

const ancillaries = (state = initialState.ancillary, action) => {
    switch (action.type) {
        case "ADD_OUTLET":
            const {code, outletInfo} = action.payload;
            return {...state, [code]: outletInfo};

        case SET_ANCILLARY_DISCOUNT:
            return {...state, discount: action.payload};

        default:
            const {outletCode} = action;
            if (outletCode) {
                return {
                    ...state,
                    items: {...state.items, [outletCode]: ancillary(state.items[outletCode], action)}
                    //IMPROVE!
                }
            }
            return state
    }
};

const selectedConsumptionReducer = (state = initialState, action) => {

    switch (action.type) {
        case LOAD_SELECTED_GUEST_CONSUMPTION:
            return {
                accommodation: {...state.accommodation, ...action.payload.accommodation},
                ancillary: {...state.ancillary, ...action.payload.ancillary},
            };
        case "PAY_SUCCESS":
        case CLEAN_CONSUMPTION:
            return initialState;
        default:
            return {
                accommodation: accommodations(state.accommodation, action),
                ancillary: ancillaries(state.ancillary, action),
            }
    }
};

export const getStayQuantity = (state, id) => {
    return state.accommodation[id]
};
export const getConceptItems = (state, concept) => {
    return state[concept].items
};
export const getConceptDiscount = (state, concept) => {
    return state[concept].discount
};

export default selectedConsumptionReducer
