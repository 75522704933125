import React  ,{useState} from "react";
import {connect, useDispatch} from "react-redux";
import {Link, useRouteMatch} from 'react-router-dom';

import {removeUser, editUser} from "../../actions/sync";
import {validateResponseApi} from "../../../common/selectors/utilsSelector"

// CSS
import Classes from '../../../../styles';

import {Button} from '@material-ui/core/';

import {usersList} from "../../selector/accounts.selector";
import {groupsList} from "../../selector/accounts.selector";
import AccountsTable from "./AccountsTable";
import {users} from "../../permissionsConstants";
import DynamicDialog from "../../../common/components/modals/DynamicDialog"
import {Grid, Input, InputAdornment} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/SearchRounded";
import {searchSettingsUser} from "../../actions/asyncAction";
import DialogPrevent from '../../../common/components/modals/DialogPrevent';
import { handleDynamicDialog } from "../../../common/actions/actions"

const UserListView = ({userList, groups, userSearchValue, permissionsList, currentUserId, removeUser, editUser, selectedUser, animation}) => {
    const classes = Classes();
    let {path} = useRouteMatch();
    const columns = ['Id', 'Name', 'Username', 'Group', 'isStaff', 'Options'];
    const [searchIcon, setSearchIcon] = useState(false);

    const permissions = Object.values(permissionsList);
    const canManageUsers = permissions.includes(users) || permissions.includes("superuser");
    const dispatch = useDispatch();
    let search;
    const handleUserSearchChange = (e) => {
        clearTimeout(search);
        dispatch(searchSettingsUser(e.target.value.trim()));
    };
    const handleEventIcon = (value) => {
        setTimeout(() => setSearchIcon(value), 100);
    };

    return (
        <div>
            <Grid item xs={12} sm={10} md={10} lg={8}>
                <Input style={{marginBottom:'0.8em'}}
                    className={` general-forms- ${classes.forms.searchInput} general-colors- ${classes.colors.bgWhite}  
                                        general-styles- ${classes.styles.bRadius18} general-styles- ${classes.styles.shadow1}`}
                    id="staff-input-search" name="staff-input-search" placeholder="Search..."
                    fullWidth
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon  style={searchIcon ? {color: "#5BE576"} : {color: "#9E9E9E"}}/>
                        </InputAdornment>
                    }
                    onChange={handleUserSearchChange}
                    onFocus={() => handleEventIcon(true)}
                    onBlur={() => handleEventIcon(false)}
                    value={userSearchValue}
                />
                {canManageUsers?
                    (   groups.length > 1 ? 
                        <Link to={`${path}user`}
                            className={`general-nav- ${classes.nav.link}`}>
                            <Button variant="contained" style={{marginBottom: '0.8em'}}
                                    fullWidth
                                    color="secondary"
                            >Add User</Button>
                        </Link>:
                        <Button variant="contained" style={{marginBottom: '0.8em'}}
                                fullWidth
                                color="secondary" 
                                onClick={()=> dispatch(handleDynamicDialog("error-add-user", null))}
                        >Add User</Button>
                    ) :
                    <span style={{cursor:'not-allowed'}}>
                        <Button variant="contained" style={{marginBottom: '0.8em'}}
                                fullWidth
                                color="secondary"
                                disabled
                        >Add User</Button>
                    </span>
                }
                <AccountsTable
                    remove={removeUser}
                    edit={editUser}
                    canManage={canManageUsers}
                    entries={userList}
                    columns={columns}
                    link={`${path}user`}
                    entity={"users"}
                    userId={currentUserId}
                    selectedItem={selectedUser}
                    animation={animation}
                />
            </Grid>
            <DynamicDialog
            title={"Are you sure?"}
            keyValue="users"
            msg={"Do you want to delete this user?"}
            type={"delete"}
            handleFunction={removeUser}/>
            <DialogPrevent
            title={"Warning!"}
            keyValue="error-add-user"
            msg={"You can't create user because need groups"}
            type={"warning"}
            />
        </div>
    )
};

const mapStateToProps = state => ({
    userList: usersList(state),
    userSearchValue:state.employeesAccountsSearchReducer.userSearch,
    permissionsList: state.auth.permissionsList,
    currentUserId:state.auth.userData.id,
    selectedUser: state.messages.response.id,
    animation: validateResponseApi(state.messages.response,"delete-user"),
    groups: groupsList(state),
});

export default connect(mapStateToProps, {removeUser, editUser})(UserListView)