import {
    LOAD_USERS,
    ADD_USER,
    EDIT_USER,
    REMOVE_USER,
    LOAD_ACCOUNTS
} from "../actions/actionsTypes";

const initialState = {
    usersList: {},
    isLoading: true,
};
const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_ACCOUNTS:
        case LOAD_USERS:
            return action.users.reduce((obj, user) => {
                obj[user.id] = user;
                return obj
                // isLoading:false
            }, {})
        case ADD_USER:
        case EDIT_USER:
            return {
                ...state,
                [action.payload.id]: action.payload
            };
        case REMOVE_USER:
            const {[action.userId]: value, ...users} = state;
            return users;
        default:
            return state
    }
};

export default usersReducer;