import React, {Fragment} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
/* Routes */
import AuthenticateUser from './modules/common/containers/authentication';
import RumiPOS from './modules/pos';
import RumiModule from "./modules/rumi/"
import page500 from "./modules/common/components/error.pages/500";
import page404 from "./modules/common/components/error.pages/404";
import page403 from "./modules/common/components/error.pages/403";
import page400 from "./modules/common/components/error.pages/400";
import Alert from './modules/common/components/modals/Alert';
import {PrivateComponentWithWelcome} from "./modules/common/components/utils/PrivateRouter";
import Landing from "./modules/common/components/landing/landing";
import LogOutUser from "./modules/common/containers/logOut";
import PmsAuthorizationReceiver from "./modules/rumi/components/clients/PmsAuthorizationReceiver";

const App = () => {
    return (
        <Fragment>
            <Router>
                <Switch>
                    <Route exact path="/" component={Landing}/>
                    <Route exact path="/pmsAuthorizationReceiver" component={PmsAuthorizationReceiver}/>
                    <Route exact path="/login" component={AuthenticateUser}/>
                    <Route exact path="/logout" component={LogOutUser}/>
                    <PrivateComponentWithWelcome path="/billing" component={RumiModule}/>
                    <PrivateComponentWithWelcome path="/pos" component={RumiPOS}/>
                    <Route exact path="/400" component={page400}/>
                    <Route exact path="/403" component={page403}/>
                    <Route component={page404}/>
                    <Route exact path="/500" component={page500}/>
                </Switch>
            </Router>
            <Alert/>
        </Fragment>
    )
};
export default App;