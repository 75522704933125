import {FETCHING_ROOMS, LOAD_ROOMS} from '../redux/actionTypes'

const initialState = {
    byId: {},
    allIds:[],
    fetching:false
};

const room = (state=initialState, action)=>{
    switch (action.type) {
        case LOAD_ROOMS :
            const rooms = action.rooms.reduce((obj, room) => {
                obj[room.roomId] = room;
                return obj
            }, {})

            return {
                ...state,
                byId: {...rooms},
                allIds: [...Object.keys(rooms)],
                fetching: false
            };
        case FETCHING_ROOMS:
            return {...state, fetching: true};
        default:
            return state
    }
}

export const  getRoomsIds = (state) => state.allIds
export const getRoom = (state, id) => state.byId[id]
export const getRooms = state => state.byId

export default room

