import {
    GUEST,
    STAFF,
    EXTERNAL
} from '../posConstants'

export function getDefaultPaymentCustomerType(client, optionClientType) {   
    
    let paymentCustomerType = optionClientType === "External" ? EXTERNAL : "";
    const isStaff = client.customerType === STAFF 

    if(isStaff || client.reservationId) {
        paymentCustomerType =  isStaff ? STAFF : GUEST 
        
    }
    return [paymentCustomerType]
}