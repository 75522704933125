import React from 'react';
import {useDispatch, useSelector} from "react-redux";
// COMPONENTS
import VolunteerSectionModal from './VolunteerSectionModal'
import Loader from '../../../common/components/utils/Loader'
import {getPMSGuest} from "../../../pos/redux/asyncActions" 

// MATERIAL UI
import {makeStyles} from '@material-ui/core/styles';
import {
    Modal, Backdrop, Fade, Button, Card,
    CardHeader, CardContent, IconButton, Tooltip
} from '@material-ui/core';

// ICONS
import CloseIcon from '@material-ui/icons/CloseRounded';
import SearchIcon from '@material-ui/icons/Search';
import {initialize} from "redux-form";



const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        
    },
    paper: {
        maxWidth: "90vw",
        maxHeight: "90vh",
        backgroundColor: theme.palette.background.paper,
        borderRadius: 10,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        '&:hover': {
            border: 'none!important'
        },
        '& .MuiCardHeader-title' : {
            fontSize: "1.60rem",
            fontWeight:"400"
        }
    },
    btnFailed: {
        height: '3em',
        width: '8em',
        fontSize: '0.8em!important'

    }
}));


const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize: '1em',
        fontWeight: "410"

    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

export default function VolunteerModal() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const dispatch = useDispatch();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <BootstrapTooltip title="Search volunteer from PMS"  placement="top">
                <Button variant="contained" color="primary" onClick={handleOpen} size="small" style={{marginLeft: '1.5em', marginTop: "1.1em"}}>
                    <SearchIcon />
                </Button>
            </BootstrapTooltip>
            <Modal 
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Card className={classes.paper} style={{outline: '0'}}>
                    <CardHeader
                        action={
                            <IconButton style={{margin: "0 0 0 100px"}} aria-label="settings" onClick={handleClose}>
                                <CloseIcon/>
                            </IconButton>
                        }
                        title="Search Volunteer from PMS"
                    />
                    <CardContent >
                        <VolunteerSectionModal
                            closeModal={handleClose}
                            handleAction={customer => dispatch(initialize("employees", customer))}
                        />
                    </CardContent>
                </Card>
            </Modal>


        </div>
    );
}