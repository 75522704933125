import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    /* custom classes */
    paymentPanelDrawer: {
        [theme.breakpoints.up('md')]: {
            width: "340px",
            flexShrink: 0,
        },
    },
    paymentPanelDrawerHolder:{
        [theme.breakpoints.up('md')]: {
            width:"340px",
        },
        [theme.breakpoints.down('sm')]: {
            width:"50%",
        },
        [theme.breakpoints.down('xs')]: {
            width:"100%",
        },
    },
    menuDrawer: {
        [theme.breakpoints.up('md')]: {
            width: "120px",
            flexShrink: 0,
        },
    },
    menuDrawerHolder:{
        width:"120px",
    }
}))