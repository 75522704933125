const getAreas = state => state.areasSettingReducer.areas

export function areasList(areas) {
    if (!areas) return []
    const list = Object.values(areas)
    return list.map(area => {
        const {id, name, origin, groupCount} = area;
        return {id, name, origin, groupCount}
    })
}

const filterAreas = areas => areas.filter(area => !area.serials.length)

export function areasSelector(state, location, isEdit = false) {
    const areas = getAreas(state)
    return isEdit ? [].concat(areas[location]) : [].concat(filterAreas(Object.values(areas)))
}