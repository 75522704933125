import React, {useEffect, useState} from 'react';
import {Line} from 'react-chartjs-2';

const LineGraph = (props) => {
    const mainApp = localStorage.getItem("origin")
    let loadedPosDataset = props.lineGraphData.pos ? props.lineGraphData.pos :[0];
    let loadedBillingDatasets = props.lineGraphData.billing ? props.lineGraphData.billing : [0];
    let lowerLabels = props.lineGraphData.lowerLabels ? props.lineGraphData.lowerLabels : ["no data"];
   
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                display: true,
                gridLines: {
                    display: true,
                    color: "#ebebeb",
                    zeroLineColor: '#ebebeb'
                },
                ticks: {
                    fontColor: "#c2c2c2",
                }
            }],
            yAxes: [{
                display: false,
                gridLines: {
                    display: true,
                    color: "#ebebeb",
                    zeroLineColor: '#ebebeb'
                },
                ticks: {
                    fontColor: "#c2c2c2",
                }
            }]
        }, 
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    var label = 'Amount: '
                    var amount = !data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]? "0" :  data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toFixed(2) 
                    label  += amount
                    return label;
                }
            }
        },
        events: ['mousemove'],
        legend: {
            display:true,
            position:'bottom',
            labels: {
                fontSize: 15,
                usePointStyle: true
            }
        },
        layout: {
            padding: {
                bottom: 10
            }
        }
       
    };
    const dataPOS = {
        labels:lowerLabels,
        datasets: [
            {
                label: props.label,
                fill: false,
                lineTension: 0.4,
                backgroundColor: 'rgba(91,229,118)',
                borderColor: 'rgb(91,229,118)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgb(91,229,118)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgb(91,229,118)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: loadedPosDataset
            }
        ]
    }

    const dataBilling = {
        labels: lowerLabels,
        datasets: [
            {
                label: props.label,
                fill: false,
                lineTension: 0.4,
                backgroundColor: 'rgba(75,192,192)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: loadedBillingDatasets
            }
        ]
    }
    return (
        <Line data={mainApp == "pos" ? dataPOS : dataBilling} height={302} options={options}/>
    );
};

export default LineGraph;