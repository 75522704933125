import {requestError} from "../actions/actions";
import * as api from '../apiCalls';

export const sendCodeForReauthorization = (code) => {
    return async dispatch => {
        try {
            await api.authorizeWithCode(code);
        } catch (err) {
            dispatch(requestError(err))
        }
    }
};