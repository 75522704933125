import Main from './components/main';
import Accordion from './components/accordions'
import Typography from './components/typography';
import Containers from './components/containers'
import Forms from './components/forms';
import Buttons from './components/buttons';
import Tables from './components/tables';
import Lists from './components/lists';
import Nav from './components/nav';
import Styles from './components/styles';
import Loader from './components/loader';
//import Palette from './theme/colors';
import Colors from './components/colors'
import Responsive from './components/responsive'
import SideDrawers from "./components/drawer";

export const main = () =>  Styles()
export const typography = () => Typography()
export const forms = () =>  Forms()
export const buttons = () =>  Buttons()
export const tables = () =>  Tables()
export const containers = () =>  Containers()
export const nav = () =>  Nav()
export const lists = () =>  Lists()
export const colors = () =>  Colors()
export const accordion = () =>  Accordion()
export const loader = () =>  Loader()
export const responsive = () => Responsive()
export const sideDrawers = () => SideDrawers();


export const Classes = () => {
    const main = Main();
    const typography = Typography();
    const forms = Forms();
    const buttons = Buttons();
    const tables = Tables();
    const containers = Containers();
    const nav = Nav();
    const styles = Styles();
    const lists = Lists();
    const colors = Colors();
    const accordion = Accordion();
    const loader = Loader();
    const sideDrawers = SideDrawers();

    return  {main, typography,nav,lists, containers, accordion, forms, buttons, tables, styles, colors, loader,sideDrawers}
}

export default Classes