import Classes from "../../../../styles";
import {
    Box,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import {Link} from "react-router-dom";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import NullMessage from "../../../common/components/error.pages/NullMessage";
import React from "react";


export default function BlockAmountTable(props){
    const classes = Classes();
    const rows = props.entries || [];
    const columns = props.columns || [];
    const edit = props.edit || false;
    return (
        <Paper className={`general-styles- ${classes.styles.shadow1}`}>
            <TableContainer style={{maxHeight: "440px"}}>
                <Table stickyHeader size="small" aria-label="sticky table"
                       className={`general-tables- ${classes.tables.table} white-table`}>
                    <TableHead>
                        <TableRow>
                            {columns.map(function (column, index) {
                                return (
                                    <TableCell key={index + 1} align="center">{column}</TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows ?
                                <TableRow>
                                    <TableCell align="center" colSpan={2}>
                                        <Box display="flex" >
                                            <Box flexGrow={1} width={"100%"}>
                                                <Box style={{padding: "0.8em 0"}}>{rows}</Box>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell align="center">
                                        { props.canManage ?
                                            <Link to={`${props.origin}block-amount`}>
                                                <IconButton variant="contained"
                                                            className={`${classes.buttons.actionButton} edit`}
                                                            onClick={() => {
                                                                props.edit()
                                                            }}>
                                                    <EditOutlinedIcon/>
                                                </IconButton>
                                            </Link> :
                                            <IconButton variant="contained"
                                                        disabled
                                                        className={`${classes.buttons.actionButton} `} >
                                                <EditOutlinedIcon/>
                                            </IconButton>
                                        }
                                    </TableCell>
                                </TableRow> :
                                <TableRow>
                                    <TableCell colSpan={9} align="center">
                                        <NullMessage message={"There are no" + props.entity} height={"10em"} />
                                    </TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )

}