import {
    BAD_CREDENTIALS,
    CREATE_BALANCE_TRANSFERS,
    DELETE_BALANCE_TRANSFERS,
    LOAD_BALANCE,
    LOAD_BALANCE_TRANSFERS,
    LOAD_CURRENCIES,
    LOAD_USER_DATA,
    LOGIN_SUCCESS,
    POST_USER_PROFILE,
    SERVER_ERROR,
    SUBDOMAIN_RETRIEVED,
    SUCCESS_OPEN_BALANCE,
    UPDATE_BALANCE_TRANSFERS,
} from './actionTypes';

import {
    authenticationRequests,
    deleteBalanceTransfer,
    getBalanceTransfers,
    getCurrencies,
    getInitialBalance,
    getUserInformationRequests,
    postBalanceTransfer,
    postOpenBalance,
    postSendLocation,
    postUserProfile,
    updateBalanceTransfer,
    verifyToken
} from '../apiCalls';

import {POS_LINK, POS_ORIGIN, RUMI_LINK, RUMI_ORIGIN} from "../commonConstants";
import {requestError} from "../../pos/redux/actions";
import {handleAlerts, openLoaderProgress} from '../actions/actions'
import {openModalView} from "../actions/utilsActions"

/* OBTAIN SUB DOMAIN FROM WINDOW AND STORE IT IN LOCALSTORAGE */
export const obtainSubDomain = () => dispatch => {
    let host = window.location.host;
    let subdomain = host.split(".")[0];
    dispatch({type: SUBDOMAIN_RETRIEVED, payload: subdomain})
};

export const obtainRootOrigin = (origin) => {
    return origin === RUMI_ORIGIN ? RUMI_LINK : origin === POS_ORIGIN ? POS_LINK : '';
};

/* LOGIN USER WITHOUT PROVIDING LOGIN DATA*/
export const userDataRequest = () => async dispatch => {
    try {
        await verifyToken();
        const token = localStorage.getItem('accessToken');
        const user = await getUserInformationRequests(token);
        dispatch({
            type: LOAD_USER_DATA,
            payload: {
                userInformation: {
                    id: user.data.id,
                    username: user.data.username,
                    email: user.data.email,
                    origin: user.data.origin,
                    locations: user.data.location,
                    locationHasSerials: user.data.locationHasSerials,
                    subdomain: user.data.subdomain,
                    color: user.data.color,
                    authorizationUrl:user.data.authorizationUrl,
                    country:user.data.country,
                    exchangeRate:user.data.exchangeRate,
                    taxValue:user.data.taxValue,
                    currencyCode:user.data.currencyCode,
                    secondaryCurrencyCode:user.data.secondaryCurrencyCode,
                },
                permissionsList: user.data.permissionsList,
            }
        });
    } catch (e) {
        dispatch(handleAlerts('error', 'failed pass or user ', true));
        if (e.response) {
            if (e.response.status === 401) {
                dispatch({
                    type: BAD_CREDENTIALS
                });

            } else if (e.response.status >= 500) {
                dispatch({
                    type: SERVER_ERROR
                });

            } else {
                dispatch({
                    type: SERVER_ERROR
                });

            }
        } else {
            dispatch({
                type: SERVER_ERROR
            });
        }
    }
};

/* LOGIN USER PROVIDING LOGIN DATA*/
export const loginUser = (username, password, redirectCb) => async dispatch => {
    const credentials = {username, password};
    try {
        const authentication = await authenticationRequests(credentials);
        const user = await getUserInformationRequests(authentication.data.access);
        const origin = user.data.origin;
        const redirectOrigin = obtainRootOrigin(origin);

        dispatch({
            type: LOGIN_SUCCESS,
            accessToken: authentication.data.access,
            refreshToken: authentication.data.refresh,
            accessExp: authentication.data.accessExp,
            refreshExp: authentication.data.refreshExp,
            userInformation: {
                id: user.data.id,
                username: user.data.username,
                email: user.data.email,
                origin: origin,
                locations: user.data.location,
                locationHasSerials: user.data.locationHasSerials,
                subdomain: user.data.subdomain,
                color: user.data.color,
                authorizationUrl:user.data.authorizationUrl,
                country:user.data.country,
                exchangeRate:user.data.exchangeRate,
                taxValue:user.data.taxValue,
                currencyCode:user.data.currencyCode,
                secondaryCurrencyCode:user.data.secondaryCurrencyCode,
            },
            permissionsList: user.data.permissionsList,
            redirectOrigin: redirectOrigin
        });

        setTimeout(function () {
            redirectCb(`/${redirectOrigin}`);
        }, 1000);

    } catch (e) {
        if (e.response) {
            if (e.response.status === 401 || e.response.status === 400) {
                dispatch({
                    type: BAD_CREDENTIALS
                });
            } else if (e.response.status >= 500) {
                dispatch({
                    type: SERVER_ERROR
                });
                dispatch(handleAlerts('error', 'Server error, please try again', true));

            } else {
                dispatch({
                    type: SERVER_ERROR
                });
                dispatch(handleAlerts('error', 'Server error, please try again', true));

            }
        } else {
            dispatch({
                type: SERVER_ERROR
            });
            dispatch(handleAlerts('error', 'Server error, please try again', true));
        }
    }
};


export const getBalance = () => async (dispatch, getState) => {
    try {
        await verifyToken();
        const state = getState();
        if (!state.balanceStatus.isFetch) {
            const balance = await getInitialBalance();
            dispatch({
                type: LOAD_BALANCE,
                openBalance: balance.data["openBalance"],
                primaryOpenData: balance.data["primaryOpenData"],
                secondaryOpenData: balance.data["secondaryOpenData"]
            })
        }
    } catch (e) {
    }
};

export const loadBalanceTransfers = () => {
    return async (dispatch) => {
        try {
            await verifyToken();
            const balanceTransfers = await getBalanceTransfers();
            dispatch({
                type: LOAD_BALANCE_TRANSFERS,
                payload: balanceTransfers.data
            });
        } catch (err) {
            if (err.response) {
                dispatch(requestError(err.response));
            } else {
                console.log(err)
            }
        }
    }
};

export const loadCurrencies = () => {
    return async (dispatch) => {
        try {
            await verifyToken();
            const currencies = await getCurrencies();
            dispatch({
                type: LOAD_CURRENCIES,
                payload: currencies.data
            });
        } catch (err) {
            if (err.response) {
                dispatch(requestError(err.response));
            } else {
                console.log(err)
            }
        }
    }
};

export const makeBalanceTransfer = (data) => {
    return async (dispatch) => {
        try {
            await verifyToken();
            const balanceTransfers = await postBalanceTransfer(data);
            dispatch({
                type: CREATE_BALANCE_TRANSFERS,
                payload: balanceTransfers.data
            });
        } catch (err) {
            if (err.response) {
                dispatch(requestError(err.response));
            } else {
                console.log(err)
            }
        }
    }
};

export const changeStatusBalanceTransfer = (transferId) => {
    return async (dispatch) => {
        try {
            const data = {
                "id": transferId,
            };
            await verifyToken();
            const balanceTransfers = await updateBalanceTransfer(transferId, data);
            dispatch({
                type: UPDATE_BALANCE_TRANSFERS,
                payload: balanceTransfers.data[0]
            });
        } catch (err) {
            if (err.response) {
                dispatch(requestError(err.response));
            } else {
                console.log(err)
            }
        }
    }
};

export const declineBalanceTransfer = (transferId) => {
    return async (dispatch) => {
        try {
            await verifyToken();
            await deleteBalanceTransfer(transferId);
            dispatch({
                type: DELETE_BALANCE_TRANSFERS,
                payload: transferId
            });
        } catch (err) {
            if (err.response) {
                dispatch(requestError(err.response));
            } else {
                console.log(err)
            }
        }
    }
};

export const sendOpenBalance = (primaryOpen, secondaryOpen, description) => async (dispatch) => {

    try {
        const initialInfo = {
            description: description,
            currencies: [
                {
                    id: primaryOpen.id,
                    amount: primaryOpen.amount
                },
                {
                    id: secondaryOpen.id,
                    amount: secondaryOpen.amount
                }
            ]
        };
        await verifyToken();
        await postOpenBalance(initialInfo);
        dispatch({
            type: SUCCESS_OPEN_BALANCE
        })
    } catch (e) {

    }
};

export const sendLocation = (id) => async () => {
    try {
        const location = {location: id};
        await postSendLocation(location)
    } catch (e) {

    }
};

export const sendUserProfile = (password, color) => async (dispatch) => {
    try {
        await verifyToken();
        const userData = {password: password, color: color};
        const user = await postUserProfile(userData);
        dispatch({
            type: POST_USER_PROFILE,
            payload: user
        });
        dispatch(openModalView(""))
    } catch (e) {
        dispatch(openLoaderProgress(false))
    }
};