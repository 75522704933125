import {
    LOAD_EXCHANGE_RATE,
    UPDATE_EXCHANGE_RATE,
    UPDATE_FAIL,
    CONSULTING_EXCHANGE_RATE
} from "../actions/actionsTypes"


const initialState = {
    exchangeId:'',
    primary: {},
    secondary: {},
    exchangeRate: '',
    consultingExchangeRate: null
};

const exchangeRateReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_EXCHANGE_RATE:
            return {
                ...state,
                exchangeId: action.payload.id,
                primary: action.payload.primary,
                secondary: action.payload.secondary,
                exchangeRate: action.payload.exchangeRate,
                createdDate: action.payload.createdDate
            };
        case UPDATE_EXCHANGE_RATE:
            return {
                ...state,
                exchangeId: action.payload.id,
                primary: action.payload.primary,
                secondary: action.payload.secondary,
                exchangeRate: action.payload.exchangeRate,
                createdDate: action.payload.createdDate
            };
        case CONSULTING_EXCHANGE_RATE : 
            return {
                ...state,
                consultingExchangeRate: action.exchangeRate
            }
        case UPDATE_FAIL:
        default:
            return state
    }
};

export default exchangeRateReducer