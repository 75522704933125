import React, {useState} from "react";
import {connect, useDispatch} from "react-redux";
import {Link, useRouteMatch} from 'react-router-dom';

import {editGroup, removeGroup} from "../../actions/sync";
import {validateResponseApi} from "../../../common/selectors/utilsSelector"
import {areasList} from '../../selector/areas.selector';

// CSS
import Classes from '../../../../styles';
// COMPONENTS
// MATERIAL UI
import {Box, Button, Grid, Input, InputAdornment, Typography} from '@material-ui/core';

import {groupsList} from "../../selector/accounts.selector";
import {groups} from "../../permissionsConstants";
import DynamicDialog from "../../../common/components/modals/DynamicDialog"
import {searchSettingsGroup} from "../../actions/asyncAction";
import SearchIcon from "@material-ui/icons/SearchRounded";
import GroupsTable from "./GroupsTable";
import DialogPrevent from '../../../common/components/modals/DialogPrevent';
import {handleDynamicDialog} from "../../../common/actions/actions"

const GroupListView = ({
                           group,
                           locations,
                           groupSearchValue,
                           permissionsList,
                           removeGroup,
                           editGroup,
                           selectedGroup,
                           animation
                       }) => {
    const classes = Classes();
    let {path} = useRouteMatch();
    const [searchIcon, setSearchIcon] = useState(false);
    const columns = ['Id', 'Name', 'App', 'UsersCount', 'Options'];
    const permissions = Object.values(permissionsList);
    const canManageGroups = permissions.includes(groups) || permissions.includes("superuser");
    const dispatch = useDispatch();
    let search;
    const handleGroupSearchChange = (e) => {
        clearTimeout(search);
        dispatch(searchSettingsGroup(e.target.value.trim()));
    };

    const handleEventIcon = (value) => {
        setTimeout(() => setSearchIcon(value), 100);
    };

    return (
        <div>
            {
                canManageGroups &&
                <Grid item xs={12} lg={8}>
                    <Box fontStyle="oblique" borderRadius={5} color="text.secondary" p={2}
                         style={{border: "1px solid #5BE576"}} display="flex" alignItems="left">
                        <Typography variant="body1">
                            Groups that have an assigned users cannot be deleted
                        </Typography>
                    </Box>
                    <br/>
                </Grid>
            }
            <Grid item xs={12} sm={10} md={10} lg={8}>
                <Input style={{marginBottom: '0.8em'}}
                       className={` general-forms- ${classes.forms.searchInput} general-colors- ${classes.colors.bgWhite}  
                                        general-styles- ${classes.styles.bRadius18} general-styles- ${classes.styles.shadow1}`}
                       id="staff-input-search" name="staff-input-search" placeholder="Search..."
                       fullWidth
                       startAdornment={
                           <InputAdornment position="start">
                               <SearchIcon style={searchIcon ? {color: "#5BE576"} : {color: "#9E9E9E"}}/>
                           </InputAdornment>
                       }
                       onChange={handleGroupSearchChange}
                       onFocus={() => handleEventIcon(true)}
                       onBlur={() => handleEventIcon(false)}
                       value={groupSearchValue}
                />
                {canManageGroups ?
                    (
                        locations.length > 1 ?
                            <Link to={`${path}group`}
                                  className={`general-nav- ${classes.nav.link}`}>
                                <Button variant="contained" style={{marginBottom: '0.8em'}}
                                        fullWidth
                                        color="secondary"
                                >Add Group</Button>
                            </Link> :
                            <Button variant="contained" style={{marginBottom: '0.8em'}}
                                    fullWidth onClick={() => dispatch(handleDynamicDialog("error-add-group", null))}
                                    color="secondary"
                            >Add Group</Button>
                    ) :
                    <span style={{cursor: 'not-allowed'}}>
                        <Button variant="contained" style={{marginBottom: '0.8em'}}
                                fullWidth
                                color="secondary"
                                disabled
                        >Add Group</Button>
                    </span>
                }
                <GroupsTable
                    remove={removeGroup}
                    edit={editGroup}
                    canManage={canManageGroups}
                    entries={group}
                    columns={columns}
                    link={`${path}group`}
                    entity={"groups"}
                    selectedItem={selectedGroup}
                    animation={animation}
                />
            </Grid>
            <DynamicDialog
                title={"Are you sure?"}
                keyValue="groups"
                msg={"Do you want to delete this group?"}
                type={"delete"}
                handleFunction={removeGroup}/>
            <DialogPrevent
                title={"Warning!"}
                keyValue="error-add-group"
                msg={"You can't create group because need locations "}
                type={"warning"}
            />
        </div>
    )
};

const mapStateToProps = state => ({
    group: groupsList(state),
    groupSearchValue: state.employeesAccountsSearchReducer.groupSearch,
    permissionsList: state.auth.permissionsList,
    selectedGroup: state.messages.response.id,
    animation: validateResponseApi(state.messages.response, "delete-group"),
    locations: areasList(state.areasSettingReducer.areas),
});

export default connect(mapStateToProps, {removeGroup, editGroup})(GroupListView)