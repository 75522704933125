import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux"
import {Link, useRouteMatch, withRouter} from 'react-router-dom';
// CSS
import Classes from '../../../../styles/'
// COMPONENTS
import OrderListView from '../../containers/payment-panel/OrderListView'
// MATERIAL UI
import {Box, Button, Typography} from '@material-ui/core/';
import {hideConsumptionsPanel, showConsumptionsPanel} from "../../../rumi/actions/async";

import {togglePaymentSection} from "../../redux/actions"
import AddStoreIcon from "../../../../assets/icons/shopping-cart.svg"
import PaymentIcon from "@material-ui/icons/MonetizationOnOutlined";
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCartOutlined';

import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paymentIcon: {
        marginRight: 10,
        color: theme.palette.common.white
    },

}));

const CartPanel = ({location, origin, chargedCartProducts, isConsumptions, optionClientType, client}) => {
    const classes = Classes();
    const useclasses = useStyles();
    let {path} = useRouteMatch();
    const dispatch = useDispatch();
    const [route, setRoute] = useState("");
    const [currentLocation, setCurrentLocation] = useState("");

    useEffect(() => {
        const currentRoute = "store";
        setRoute(currentRoute);
        const currentPath = location.pathname;
        if (currentPath.includes("consumptions")) {
            setCurrentLocation("consumptions");
            showConsumptionsPanel()
        } else if (currentPath.includes("store")) {
            setCurrentLocation("store");
        } else {
            setCurrentLocation("");
            hideConsumptionsPanel()
        }
    }, [location, origin, chargedCartProducts, isConsumptions]);

    return (
        client.active === false ?
            <Box display="flex"
                 alignItems="center"
                 justifyContent="center"
                 flexDirection="column">
                <Box p={13} pb={0} style={{filter: 'opacity(.3)'}}>
                    <RemoveShoppingCartIcon style={{fontSize: 50}}/>
                </Box>
                <Box p={5} style={{color: '#9e9e9e'}} mt={2}>
                    <Typography variant="subtitle1" align="center">
                        You cannot sale, because client's account inactive
                    </Typography>
                </Box>
            </Box>
            : <Box p={0.1}>
                {chargedCartProducts ?
                    <OrderListView/> :
                    <Box p={13} display="flex"
                         alignItems="center"
                         justifyContent="center"
                         flexDirection="column">

                        <img src={AddStoreIcon} style={{width: 45, height: 45, opacity: 0.5}}/>
                        <Box style={{color: '#9e9e9e'}} mt={2}>
                            {
                                currentLocation === "" ?
                                    <Typography variant="subtitle1">
                                        <Link to={`${path}/${route}`} className={`${classes.nav.link}`}
                                              style={{color: "#5BE576"}}>
                                            Products
                                        </Link>
                                    </Typography> :
                                    <Typography variant="subtitle1">
                                        Products
                                    </Typography>
                            }
                        </Box>
                    </Box>
                }
                <Box display="flex" style={!chargedCartProducts || !optionClientType ? {cursor: 'not-allowed'} : {}}
                     alignItems="center"
                     justifyContent="center" mt={2}>
                    <Button variant="contained" color="primary" disabled={!chargedCartProducts || !optionClientType}
                            onClick={() => dispatch(togglePaymentSection(true))}>
                        <PaymentIcon color="primary" className={useclasses.paymentIcon}/>GO TO PAY</Button>
                </Box>
            </Box>
    )
};


const mapStateToProps = state => {
    const auxGuestConsumptions = state.guestConsumption.guestConsumptions;

    return {
        chargedCartProducts: state.cart.productsIds.length > 0,
        isConsumptions: Object.values(auxGuestConsumptions).length > 0,
        origin: localStorage.getItem('origin'),
        optionClientType: state.paymentTypeSelected.optionClientType,
        client: state.client
    }
};


export default connect(mapStateToProps)(withRouter(CartPanel));