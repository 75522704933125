import React, {Fragment, useState} from "react";
import {connect, useDispatch} from "react-redux";
// CSS
import Classes from "../../../../styles"

// MATERIAL UI
import {Box, Button, InputAdornment, InputLabel, TextField} from "@material-ui/core";
import {sendOpenBalance} from "../../actions/asyncActions";

const FormOpeningBalance = (props) => {
    const classes = Classes();
    const dispatch = useDispatch();
    const [primaryOpenBalance, setPrimaryOpenBalance] = useState(
        {
            amount: props.primaryOpenData.amount,
            id: props.primaryOpenData.currency
        }
    );
    const [secondaryOpenBalance, setSecondaryOpenBalance] = useState(
        {
            amount: props.secondaryOpenData.amount,
            id: props.secondaryOpenData.currency
        }
    );
    const handleChangePrimaryBalance = (event) => {
        setPrimaryOpenBalance({...primaryOpenBalance, amount: event.target.value});
    };
    const handleChangeSecondaryBalance = (event) => {
        setSecondaryOpenBalance({...secondaryOpenBalance, amount: event.target.value});
    };
    const [description, setDescription] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(sendOpenBalance(primaryOpenBalance, secondaryOpenBalance, description));
    };

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                <Box display="flex" flexDirection="row">
                    <InputLabel shrink component="legend">Opening balance:</InputLabel>
                    <Box width={150} ml={1.5}>
                        <TextField
                            id="outlined-number"
                            value={primaryOpenBalance.amount}
                            onChange={handleChangePrimaryBalance}
                            fullWidth
                            className={`general-forms- ${classes.forms.textFieldOutlined}`}
                            type="number"
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="end"
                                                    style={{paddingLeft: 1}}>
                                        {props.currencyCode}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    {
                        props.exchangeRate > 0 ?
                            <Box width={150} ml={1.5}>
                                <TextField
                                    id="outlined-number"
                                    value={secondaryOpenBalance.amount}
                                    onChange={handleChangeSecondaryBalance}
                                    fullWidth
                                    className={`general-forms- ${classes.forms.textFieldOutlined}`}
                                    type="number"
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="end"
                                                            style={{paddingLeft: 1}}>
                                                {props.secondaryCurrencyCode}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                            :
                            ""
                    }
                </Box>
                <br/>
                {
                    !props.isOpen ?
                        <Fragment>
                            {props.primaryOpenData.amount !== primaryOpenBalance.amount ||
                            props.secondaryOpenData.amount !== secondaryOpenBalance.amount ?
                                <div>
                                    <InputLabel shrink htmlFor="age-native-simple">Why was it changed?</InputLabel>
                                    <TextField
                                        id="standard-multiline-static"
                                        multiline
                                        fullWidth
                                        className={`general-forms- ${classes.forms.textFieldInput} ${classes.forms.textField}`}
                                        onChange={e => setDescription(e.target.value)}
                                    />
                                </div>
                                :
                                ""}
                        </Fragment>
                        :
                        ""
                }
                <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
                    {
                        <Button variant="contained" color="primary" style={{width: 100}} type="submit">
                            Save
                        </Button>
                    }
                </Box>
            </form>

        </Box>
    );
};

const mapStateToProps = (state) => {
    const currencyCode = localStorage.getItem('currencyCode')
    const secondaryCurrencyCode = localStorage.getItem('secondaryCurrencyCode')
    return {
        isOpened: state.balanceStatus.openBalance,
        primaryOpenData: state.balanceStatus.primaryOpenData,
        secondaryOpenData: state.balanceStatus.secondaryOpenData,
        exchangeRate: state.exchangeRateReducer.exchangeRate,
        currencyCode:currencyCode,
        secondaryCurrencyCode:secondaryCurrencyCode
    }
};

export default connect(mapStateToProps)(FormOpeningBalance)
