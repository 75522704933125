import React from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';

// CSS
import Classes from '../../../../styles';

// COMPONENTS
import FormProductView from './FormProductView';

// MATERIAL UI
import {Grid, Typography, Box }from '@material-ui/core';

const  Products = ({origin}) => {
    const classes = Classes()
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="h1" className={classes.typography.pageTitles}>
                    <Box display="inline" color="text.secondary" >
                        Settings /
                    </Box>
                    <Link to={`../store`}
                          className={`general-nav- ${classes.nav.link}`}>
                        <Box display="inline" color="text.secondary">
                            &nbsp;Store /
                        </Box>
                    </Link>
                    &nbsp;Products
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormProductView/>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state)=> {
    return {
        origin: state.auth.userData.origin
    }
}

export default connect(mapStateToProps)(Products)