import * as api_settings from "../../api.calls";
import {ADD_AREA, EDIT_AREA} from "../../actions/actionsTypes";
import {verifyToken} from "../../../common/apiCalls";

export const validate = values => {
    const errors = {}
    if (!values.name) {
        errors.name = 'Required'
    }
    if (!values.origin) {
        errors.origin = 'Required'
    }
    return errors
}

export const submitAreasForm = async (values, dispatch, {reset, ...rest},) => {
    try {
        await verifyToken();
        const areaRequest = !values.id ? api_settings.postAreas : api_settings.putAreas
        const response = await areaRequest({
            areaData: values,
        })
        if (response.status === 201) {
            dispatch({
                type: ADD_AREA,
                payload: response.data,
            })
            reset()
        }
        if (response.status === 200) {
            dispatch({
                type: EDIT_AREA,
                payload: response.data
            })
        }
        rest.history.goBack()
    } catch (e) {
    }
}