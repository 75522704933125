import React, {useEffect} from 'react';
// STYLES
import "../../../styles/font.css"

// MATERIAL UI
import {Box, Grid, Typography} from '@material-ui/core';
import {useHistory} from "react-router-dom";
import {logOut} from "../actions/actions";
import {useDispatch} from "react-redux";

const LogOutUser = (props) => {
    const dispatch = useDispatch();
    let history = useHistory();
    const [state, setState] = React.useState({
        transition: true,
        opacity: ''
    });

    const dispelText = (opa, sec) => {
        setTimeout(function () {
            setState({...state, opacity: opa});
        }, sec);
    }

    useEffect(() => {
        localStorage.clear();
        setState({...state, transition: true});
        let sec = 0;
        for (let i = 1; i < 9; i++) {
            const text = 'opacity(.' + i + ')';
            dispelText(text, sec)
            sec = sec + 200;
        }
        sec = sec + 600
        for (let i = 9; i > 1; i--) {
            const text = 'opacity(.' + i + ')';
            sec = sec + 200;
            dispelText(text, sec)
        }
        setTimeout(() => history.push("/"), 4000);
        dispatch(logOut());
    }, []);

    return (
        <Grid container
              style={{
                  filter: state.transition ? state.opacity : 'opacity(0)',
              }}>
            <Grid item xs={12}>
                <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center"
                     style={{height: '100vh'}}>
                    <Box display="flex" alignItems="center" justifyContent="center" style={{width: '100%'}}>
                        <Typography variant="h3" style={{
                            fontFamily: 'Poppins, sans-serif',
                            fontWeight: 100,
                            textAlign: "center"
                        }}>
                            Thank you, come back soon
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
};

export default LogOutUser;

