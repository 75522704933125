import React from 'react';
import {containers} from "../../../../styles";
import {Box} from '@material-ui/core/';
import UserItem from "./UserItem";
import ListMenu from "./ListMenu";
import LogoItem from "./LogoItem";

const Menu = (props) => {
    const classes = {
        innerMenu: containers().innerMenu
    };

    return (
        <Box display="flex" alignItems="center" flexDirection="column" bgcolor="common.white"
             className={classes.innerMenu} boxShadow={3}>
            <Box mt={2} mb={1.2}>
                <LogoItem logoImage={props.logo}/>
            </Box>
            <Box mt={2} mb={1.2}>
                <UserItem/>
            </Box>
            <Box>
                <ListMenu menuItems={props.menuItems} position={props.positionDivider}/>
            </Box>
        </Box>
    )
}

export default Menu