import React, {Fragment} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
// COMPONENTS
import Client from './Client'
import NullMessage from '../../../common/components/error.pages/NullMessage'
import {handleDynamicDialog} from "../../../common/actions/actions"
// CSS5
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Classes from '../../../../styles'
// COMPONENTS MATERIAL UI
import {Box, Grid, Hidden, Tooltip, Typography} from "@material-ui/core";
import StoreIcon from '@material-ui/icons/LocalGroceryStoreOutlined';
import Fab from '@material-ui/core/Fab';
import ClientDynamicDialog from "../../../common/components/modals/ClientDynamicDialog";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {billing_store, consumptions, pos_store,} from "../../../rumi-settings/permissionsConstants";
import AlertIcon from '@material-ui/icons/ErrorOutlineOutlined';

const useStyles = makeStyles((theme) => ({
    containerLink: {
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)!important',
        color: '#ffffff',
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: '#ffffff',
            border: '1px solid ' + theme.palette.primary.main,
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)!important'
        }
    },
    missingInfo: {
        backgroundColor: '#000000',
        color: '#FFFFFF',
        padding: '4px'
    }
}));

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: 'transparent'
    },
    tooltip: {
        marginBottom: '0.3em!important',
        margin: '0!important',
        backgroundColor: "transparent"

    }
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip classes={classes} {...props} interactive/>;
}


function ClientList({origin, RUMI_LINK, route, title, clients, setClient, chargedCartProducts, permissionsList}) {
    let history = useHistory();
    const useclasses = useStyles();
    const classes = Classes();
    const [selectedClient, setSelectedClient] = React.useState({});
    const dispatch = useDispatch();
    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
    const permissions = Object.values(permissionsList);
    const canSalePosStore = permissions.includes(pos_store) || permissions.includes("superuser");
    const canSaleBillingStore = permissions.includes(billing_store) || permissions.includes("superuser");
    const canPayConsumptions = permissions.includes(consumptions) || permissions.includes("superuser");
    const clientDocumentError = !clients.length ? null : clients.find(client => client.documentNumber == "")

    const goConsumptions = () => {
        history.push(`/${origin}/consumptions`);
        setClient(selectedClient, 'Clients')
    };

    const goStore = () => {
        history.push(`/${origin}/store`);
        setClient(selectedClient, 'Clients')
    };

    const preventSetClient = (client) => {
        setSelectedClient(client);
        dispatch(handleDynamicDialog("store", null))
    };


    return (
        <React.Fragment><Box mb={2}><Box flexGrow={1}><Typography variant="h6" style={{textTransform: 'capitalize'}}>
            <Box color="text.secondary">
                {
                    title === null ? "Results" : title
                }
                 - {clients.length}
            </Box>
        </Typography>
        </Box>
            {
                clientDocumentError &&
                <Box display="flex" justifyContent="center" alignItems="center">
                    <AlertIcon style={{color: "#F5BC00"}}/>
                    <Box p={0} ml={2} color="text.secondary" fontStyle="oblique">
                        <Typography variant="body2">
                            Please, complete document information from clients highlighted in yellow
                        </Typography>
                    </Box>
                </Box>
            }
        </Box>
            {clients.length > 0 ?
                <Grid container spacing={2} style={{height: "68vh", overflowY: "auto"}}><Grid item xs={12}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" flexWrap="wrap">
                        {clients.map((client, index) => {
                                if (client.customerType === 'STAFF' || (client.hasOwnProperty('customerId') && client.hasOwnProperty('name'))) {
                                    return (
                                        <Box width={matchesSm ? "100%" : "48%"} key={index + 1}>
                                            {origin === RUMI_LINK ?
                                                <Fragment><Hidden smDown>{client.active === false ?
                                                    <Link to={route} className={classes.nav.link}>
                                                        <Client client={client} enable={true}
                                                                setClient={() => setClient(client, 'Clients')}/>
                                                    </Link>
                                                    : (canPayConsumptions ?
                                                            (canSaleBillingStore ?
                                                                    <BootstrapTooltip
                                                                        title={
                                                                            <Link to={`/${origin}/store`}
                                                                                  className={` ${classes.nav.link} `}
                                                                                  onClick={() => setClient(client, 'Clients')}>
                                                                                {(!client.documentNumber || !client.documentType || !client.country || !client.arrivalCountry) &&
                                                                                    <span
                                                                                        className={`${useclasses.missingInfo} `}>
                                                                                                {!client.documentNumber &&
                                                                                                    <span>No document N°<br/></span>}
                                                                                        {!client.documentType &&
                                                                                            <span>No document type<br/></span>}
                                                                                        {!client.country &&
                                                                                            <span>No country<br/></span>}
                                                                                        {!client.arrivalCountry &&
                                                                                            <span>No arrival date<br/></span>}
                                                                                            </span>
                                                                                }
                                                                                <Fab size="small"
                                                                                     className={`${useclasses.containerLink} `}
                                                                                     color="secondary"
                                                                                     style={{
                                                                                         margin: '0!important',
                                                                                         borderRadius: 18,
                                                                                         height: 1,
                                                                                         width: 38
                                                                                     }}
                                                                                >
                                                                                    <StoreIcon
                                                                                        style={{fontSize: 18}}/>
                                                                                </Fab>
                                                                            </Link>
                                                                        }
                                                                        placement="top">
                                                                        {chargedCartProducts ?
                                                                            <Box mt={1}>
                                                                                <Client client={client}
                                                                                        enable={true}
                                                                                        setClient={() => preventSetClient(client)}/>
                                                                            </Box>
                                                                            :
                                                                            <Link to={route}
                                                                                  className={classes.nav.link}>
                                                                                <Client client={client}
                                                                                        enable={true}
                                                                                        setClient={() => setClient(client, 'Clients')}/>
                                                                            </Link>
                                                                        }
                                                                    </BootstrapTooltip>
                                                                    : <Link to={route}
                                                                            className={classes.nav.link}>
                                                                        <Client client={client} enable={true}
                                                                                setClient={() => setClient(client, 'Clients')}/>
                                                                    </Link>
                                                            ) : canSaleBillingStore ?
                                                                <Link to={`/${origin}/store`}
                                                                      className={classes.nav.link}>
                                                                    <Client client={client} enable={true}
                                                                            setClient={() => setClient(client, 'Clients')}/>
                                                                </Link> :
                                                                <Box style={{cursor: 'not-allowed'}}>
                                                                    <Client client={client} enable={false}
                                                                            setClient={() => setClient(client, 'Clients')}/>
                                                                </Box>
                                                    )
                                                }
                                                </Hidden>
                                                    <Hidden mdUp>
                                                        {client.active === false ?
                                                            <Link to={route} className={classes.nav.link}>
                                                                <Client client={client} enable={true}
                                                                        setClient={() => setClient(client, 'Clients')}/>
                                                            </Link>
                                                            : (canPayConsumptions ?
                                                                    (canSaleBillingStore ?
                                                                            <Box mt={1} display="flex">
                                                                                <Box flexGrow={1}>
                                                                                    {chargedCartProducts ?
                                                                                        <Client client={client}
                                                                                                enable={true}
                                                                                                setClient={() => preventSetClient(client)}
                                                                                        />
                                                                                        : <Link to={route}
                                                                                                className={classes.nav.link}>
                                                                                            <Client client={client}
                                                                                                    enable={true}
                                                                                                    setClient={() => setClient(client, 'Clients')}
                                                                                            />
                                                                                        </Link>
                                                                                    }
                                                                                </Box>
                                                                                <Link to={`/${origin}/store`}
                                                                                      style={{marginLeft: 7}}
                                                                                      className={` ${classes.nav.link} `}
                                                                                      onClick={() => setClient(client, 'Clients')}>
                                                                                    <Fab size="small"
                                                                                         className={`${useclasses.containerLink} `}
                                                                                         color="secondary"
                                                                                         style={{
                                                                                             margin: '0!important',
                                                                                             borderRadius: 18,
                                                                                             height: 1,
                                                                                             width: 36
                                                                                         }}
                                                                                    >
                                                                                        <StoreIcon
                                                                                            style={{fontSize: 18}}/>
                                                                                    </Fab>
                                                                                </Link>
                                                                            </Box>
                                                                            : <Box display="flex">
                                                                                <Box flexGrow={1}>
                                                                                    <Client client={client}
                                                                                            enable={true}
                                                                                            setClient={() => setClient(client, 'Clients')}/>

                                                                                </Box>
                                                                            </Box>
                                                                    ) : canSaleBillingStore ?
                                                                        <Link to={`/${origin}/store`}
                                                                              className={classes.nav.link}>
                                                                            <Client client={client} enable={true}
                                                                                    setClient={() => setClient(client, 'Clients')}/>
                                                                        </Link> :
                                                                        <Box style={{cursor: 'not-allowed'}}>
                                                                            <Client client={client} enable={false}
                                                                                    setClient={() => setClient(client, 'Clients')}/>
                                                                        </Box>
                                                            )
                                                        }
                                                    </Hidden>
                                                </Fragment>
                                                : client.active === false ?
                                                    <Box style={{cursor: 'not-allowed', filter: 'opacity(.5)'}}>
                                                        <Client client={client} enable={false}
                                                                setClient={() => setClient(client, 'Clients')}/>
                                                    </Box>
                                                    : (canSalePosStore ?
                                                            <Link key={index + 1} to={route}
                                                                  className={classes.nav.link}>
                                                                <Client client={client} enable={true}
                                                                        setClient={() => setClient(client, 'Clients')}/>
                                                            </Link> :
                                                            <Box style={{cursor: 'not-allowed'}}>
                                                                <Client client={client} enable={false}
                                                                        setClient={() => setClient(client, 'Clients')}/>
                                                            </Box>
                                                    )
                                            }
                                        </Box>
                                    )
                                }
                            }
                        )}
                    </Box>
                </Grid></Grid>
                :
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{padding: "0"}}>
                        <NullMessage message="There are no clients" height={"20em"}/>
                    </Grid>
                </Grid>
            }
            <ClientDynamicDialog
                title={"Are you sure?"}
                msg={"Going to consumptions will clean the products cart, are you sure you want to see the clients consumptions?"}
                type={"warning"}
                keyValue="store"
                handleConsumptionFunction={goConsumptions}
                handleStoreFunction={goStore}
            />
        </React.Fragment>
    );
}

export default ClientList;