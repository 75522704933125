export const GROUPS_URLS = 'api/core/groups';
export const GROUPS_DELETE_URLS = 'api/core/group-delete/';
export const USERS_URL = 'api/core/users';
export const USERS_DELETE_URL = 'api/core/user-delete/';
export const LOCATIONS_LIST_URL = 'api/core/locations';
export const GROUP_URL = 'api/core/group/';
export const CATEGORIES_URL = 'api/pos/categories';
export const PRODUCTS_SETTINGS_URL = 'api/pos/products-settings';
export const ALL_CATEGORIES_URL = 'api/pos/category/';
export const POST_PRODUCTS_URL = 'api/pos/create-product';
export const PUT_PRODUCTS_URL = 'api/pos/update-product/';
export const UPDATE_ALL_PRODUCTS_URL = 'api/';
export const PUT_ALL_PRODUCTS_URL = 'api/pos/change-products-category';
export const DELETE_PRODUCTS_URL = 'api/pos/delete-product/';
export const GET_OUTLETS_URL = 'api/core/outlets';
export const AREA_LIST_URL = 'api/core/locations';
export const POST_AREA_URL = 'api/core/locations';
export const AREA_URL = 'api/core/locations/';
export const COMPANY_URL = 'api/core/company';
export const POST_SERIAL_URL = 'api/core/serial_location';
export const POST_TAXES_URL = 'api/core/taxes_charges';
export const SERIALS_URL = 'api/core/serial-list';
export const TAXES_URL = 'api/core/taxes_charges';
export const TAX_URL = 'api/core/tax_charge/';
export const LOCATION_WITHOUT_SERIALS_URL = 'api/core/locations-without-serial';
export const CUSTOMER_URL = 'api/pos/customer/';
export const CUSTOMER_LIST_URL = 'api/pos/customer-list';
export const COUNTRY_URL = 'api/core/country/';
export const IDTYPE_URL = 'api/core/idtype/';
export const BILLING_DOCUMENTS_URL = 'api/core/documents/';
export const USER_LOOKUP_URL = email => `api/core/user/${email}/`;
export const CONSULTING_EXCHANGE_RATE_URL = 'api/core/consulting-exchange-rate';
export const EXCHANGE_RATE_URL = 'api/core/exchanges';
export const BLOCK_AMOUNT_UPDATE_URL = 'api/core/block-amount-update';
