import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {handleViewPaymentPanel, setMenuItem} from "../../actions/actions";

import {typography} from "../../../../styles";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Button, Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    container: {
        height: "96vh"
    },
    containerTitle: {
        width: "30%"
    },
    title: {
        marginBottom: "1em",
        fontSize: "3em"
    },
    containerImage: {
        width: "70%"
    },
    image: {
        margin: 'auto',
        width: "70%"
    }
}))

const ErrorPageDisplay = (props) => {
    const classes = {
        local: useStyles(),
        fontPoppins: typography().familyPoppins
    };

    const dispatch = useDispatch();

    const handleBack = () => {
        window.history.back()
        dispatch(setMenuItem(""))
    };

    useEffect(() => {
        dispatch(handleViewPaymentPanel(false));
    }, []);

    return (
        <Box display="flex" flexDirection="row" alignContent="center" className={classes.local.container}>
            <Box display="flex" justifyContent="center" alignItems="flex-start" flexDirection="column"
                 className={classes.local.containerTitle}>
                <Typography variant="h1"
                            className={`${classes.fontPoppins} ${classes.local.title}`}>{props.errorName}</Typography>
                <Button variant="contained" color="secondary" onClick={handleBack}>
                    Go Back
                </Button>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" className={classes.local.containerImage}>
                <img src={props.errorDisplay} alt="error-image" className={classes.local.image}/>
            </Box>
        </Box>
    )
};

export default ErrorPageDisplay