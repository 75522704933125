import React from 'react';
import {useDispatch} from "react-redux";
import {setFilterClient} from "../../redux/actions";
// CSS
import Classes from '../../../../styles'
// MATERIAL UI
import {Box, Typography, Button} from "@material-ui/core";

const ClientFilter = (props) => {
    const classes = Classes();
    const dispatch = useDispatch()
    return (
        <div>
            {
                props.filters.map((filter, index) =>
                    <Button variant="outlined" color="secondary" className={`${classes.buttons.roundPillButton} filter`}
                            key={index + 1} onClick={() => dispatch(setFilterClient(filter))}>
                        <Box lineHeight={1}>
                            <Typography variant="body1" className={classes.buttons.roundPillText}>
                                {filter}
                            </Typography>
                        </Box>
                    </Button>
                )
            }
        </div>
    );
};

export default ClientFilter;
