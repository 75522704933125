import React from "react";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {DEFAULT} from "../../../common/commonConstants"
// COMPONENTS MATERIAL UI
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Grid
} from "@material-ui/core";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DefaultIcon from '@material-ui/icons/HttpsOutlined';
import NullIcon from '@material-ui/icons/NotInterested';
// CSS
import {Classes, main, tables, buttons} from '../../../../styles';
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import {handleDynamicDialog} from "../../../common/actions/actions"
import NullMessage from '../../../common/components/error.pages/NullMessage'

export default function AreasTable(props) {
    const classes = {
        main: main(),
        tables: tables(),
        buttons: buttons()
    };

    const dispatch = useDispatch();

    const rows = props.entries || [];
    const columns = props.columns || [];
    const edit = props.edit || false;
    const remove = props.remove || false;

    return (
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper className={`general-styles- ${classes.main.shadow1}`}>
                <TableContainer style={{maxHeight: "25em"}}>
                    <Table stickyHeader size="small" aria-label="sticky table"
                           className={`general-tables- ${classes.tables.table} white-table`}>
                        <TableHead>
                            <TableRow>
                                {columns.map(function (column, index) {
                                    if (index === 0 || index === 3) {
                                        return (
                                            <TableCell key={index + 1} align="center"
                                                       className={classes.tables.hiddenColumn}>{column}</TableCell>
                                        )
                                    }
                                    return (
                                        <TableCell key={index + 1} align="center">{column}</TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                rows.length ?
                                    rows.map((row, index) => (
                                            <TableRow key={index + 1} hover role="checkbox" tabIndex={-1}
                                                      className={`${classes.main.animations} ${props.selectedItem == row.id && props.animation + " active"}`}>
                                                {
                                                    Object.values(row).map(function (value, index) {
                                                        if (index === 0 || index === 3) {
                                                            return (
                                                                <TableCell key={index} align="center"
                                                                           className={classes.tables.hiddenColumn}>{value} </TableCell>
                                                            )
                                                        }
                                                        return (
                                                            <TableCell key={index} align="center">{value}</TableCell>
                                                        )
                                                    })
                                                }
                                                <TableCell align="center">
                                                    {edit && props.canManage ?
                                                        (row.id === null ?
                                                            <IconButton variant="contained" disabled
                                                                        className={`${classes.buttons.actionButton} edit`}>
                                                                <EditOutlinedIcon/>
                                                            </IconButton>
                                                            :
                                                            <Link to={props.link}>
                                                                <IconButton variant="contained"
                                                                            className={`${classes.buttons.actionButton} edit`}
                                                                            onClick={() => {
                                                                                props.edit(row.id)
                                                                            }}>
                                                                    <EditOutlinedIcon/>
                                                                </IconButton>
                                                            </Link>)
                                                        : 
                                                        <IconButton variant="contained"
                                                                    disabled
                                                                    className={`${classes.buttons.actionButton} `}
                                                        >
                                                            <EditOutlinedIcon/>
                                                        </IconButton>
                                                    }
                                                    {
                                                        row.id == DEFAULT ? 
                                                        <IconButton variant="contained" style={{pointerEvents:'none'}} className={`${classes.buttons.actionButton}`}> 
                                                            <DefaultIcon color="secondary"/>
                                                        </IconButton>
                                                        :
                                                        remove && props.canManage  ?
                                                            (row.groupCount === 0 ?
                                                                <IconButton variant="contained"
                                                                            className={`${classes.buttons.actionButton} delete`}
                                                                            onClick={() => dispatch(handleDynamicDialog(props.entity, row.id))}
                                                                >
                                                                    <DeleteIcon color="error"/>
                                                                </IconButton> :
                                                                <IconButton variant="contained"
                                                                    disabled 
                                                                    className={`${classes.buttons.actionButton} delete`}>
                                                                    <DeleteIcon color="error"/>
                                                                </IconButton>)

                                                        : <IconButton variant="contained" disabled
                                                                        className={`${classes.buttons.actionButton} `}>
                                                                <DeleteIcon />
                                                            </IconButton> 
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )
                                    ) :
                                    <TableRow>
                                        <TableCell colSpan={9} align="center">
                                            {props.entity === "volunteer" ?
                                                <NullMessage message={"There are no volunteers"} height={"10em"}/>
                                                :
                                                <NullMessage message={"There are no" + props.entity} height={"10em"}/>
                                            }
                                        </TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Grid>

    );
}