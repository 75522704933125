import React, {Fragment, useEffect} from 'react';
import {connect} from "react-redux"
import {categoriesToUpperCase, orderCategories} from "../../../../rumi-settings/selector/store.selector"
import {setFilterCategory} from "../../../redux/actions";
import {filterSearchMultiple} from '../../../../common/actions/utilsActions'
// COMPONENTS
import Category from './Category';

// MATERIAL UI
import {Box, Hidden, Typography} from '@material-ui/core/';
import List from '@material-ui/core/List';
import NullMessage from '../../../../common/components/error.pages/NullMessage'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


// CSS
import Classes from '../../../../../styles'


const CategoryList = ({
                          cartProducts,
                          categories,
                          selectedCategory,
                          setFilterCategory,
                          searchValue,
                          filterSearchMultiple
                      }) => {
    const classes = Classes();

    useEffect(() => {
        const categoryId = categories.length > 0 ? categories[0].id : null;

        if (!cartProducts) {
            setFilterCategory(categoryId)
        }

    }, [])

    const responsiveHandleSelectedCategory = (event) => {
        filterSearchMultiple("");
        setFilterCategory(event.target.value)
    };

    const handleSelectedCategory = (categoryId) => {
        if (!searchValue) {
            return categoryId === selectedCategory ? true : false
        } else {
            return false
        }

    };

    return (
        <Fragment>
            <Hidden smDown>
                <Box className={classes.containers.dividerVertical}>
                    <Box mb={0.6}>
                        <Typography variant="h6">
                            <Box color="text.secondary" className={classes.typography.pageSubTitles}>Categories</Box>
                        </Typography>
                    </Box>
                    {
                        categories.length > 0 ?
                            <List component="nav" style={{paddingRight: '2em', height: '72vh', overflowY: 'auto'}}
                                  className="scroll">
                                {
                                    categories.map((category, index) => (
                                        <Category key={index}
                                                  name={category.name}
                                                  selected={handleSelectedCategory(category.id)}
                                                  setCategory={setFilterCategory}
                                                  idCategory={category.id}
                                                  filterSearchMultiple={filterSearchMultiple}
                                        />
                                    ))
                                }
                            </List>
                            : <NullMessage message="There are no categories" height={"20em"}/>
                    }
                </Box>
            </Hidden>
            <Hidden mdUp>
                <Box display="flex" flexDirection="column" maxWidth="300px">
                    <Box mb={0.6}>
                        <Typography variant="h6">
                            <Box color="text.secondary" className={classes.typography.pageSubTitles}>Categories</Box>
                        </Typography>
                    </Box>
                    {
                        categories.length > 0 ?
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedCategory}
                                    onChange={responsiveHandleSelectedCategory}
                                >
                                    {
                                        categories.map((category, index) => (
                                            <MenuItem key={index + 1} value={category.id}>{category.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            :
                            <NullMessage message="There are no categories" height={"20em"}/>
                    }
                </Box>
            </Hidden>
        </Fragment>
    )
};

const mapStateTProps = (state) => {
    const capitalCaseCategories = categoriesToUpperCase(state.category.all);

    return {
        categories: orderCategories(capitalCaseCategories),
        selectedCategory: state.category.currentCategory,
        searchValue: state.utilsReducer.search,
        cartProducts: state.cart.productsIds.length,
    }
};

export default connect(mapStateTProps, {setFilterCategory, filterSearchMultiple})(CategoryList)
