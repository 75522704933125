import guestConsumption from "./guestConsumption.reducer";
import selectedConsumption from "./selectedConsumption.reducer";
import paymentDocuments from "./documents.reducer";
import dashboardReducer from './dashboard.reducer';
import paymentState from './payment.reducer';
import clientHistoryReducer from "./history.reducer";
import supportReducer from "./support.reducer";
export default {
    guestConsumption,
    selectedConsumption,
    paymentDocuments,
    dashboardReducer,
    paymentState,
    clientHistoryReducer,
    supportReducer
}