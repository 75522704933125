import React, {useEffect, useState} from 'react';
import {Box} from '@material-ui/core/';
import { Pie } from 'react-chartjs-2';

const PolarGraph = (props) => {
    const currency = props.currency;
    let loadedDatasets;
    let loadedLabels = props.polarData["labels"] ? props.polarData["labels"] : ["No data", "No data", "No data", "No data","No data"];
    if(Object.keys(props.polarData).length >0){
        if(currency){
            if(currency.isMain){
                loadedDatasets = [props.polarData["CASH"]["primaryCurrency"],props.polarData["CHRG"]["primaryCurrency"], props.polarData["CARD"]["primaryCurrency"],props.polarData["BANK"]["primaryCurrency"], props.polarData["E-WALLET"]["primaryCurrency"]]
            }else{
                loadedDatasets = [props.polarData["CASH"]["secondaryCurrency"],props.polarData["CHRG"]["secondaryCurrency"], props.polarData["CARD"]["secondaryCurrency"],props.polarData["BANK"]["secondaryCurrency"], props.polarData["E-WALLET"]["secondaryCurrency"]]
            }
        }else{
            loadedDatasets = [props.polarData["CASH"]["primaryCurrency"],props.polarData["CHRG"]["primaryCurrency"], props.polarData["CARD"]["primaryCurrency"], props.polarData["E-WALLET"]["primaryCurrency"],props.polarData["BANK"]["primaryCurrency"]]
        }
    }else{
        loadedDatasets = [0,0,0,0,0];
    }

    const options={
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                display: false,
                ticks: {
                    beginAtZero: true,
                    min: 0
                }
            }],
            xAxes: [{
                display: false,
                ticks: {
                    beginAtZero: true,
                    min: 0
                }
            }]
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    var label = data.datasets[tooltipItem.datasetIndex].label || '';
                    var amount = !data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] ? "0" : data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toFixed(2)
                    if (label) {
                        label += ': ';
                    }
                    label += amount
                    return label;
                }
            }
        },
        legend: {
            display:true,
            position:'bottom',
            labels: {
                fontSize: 12,
                usePointStyle: true

            }
        },
        layout: {
            padding: {
                bottom: 10
            }
        }

    };

    const data = {
        datasets: [{
            data: loadedDatasets,
            backgroundColor: [
                '#5be576',
                '#4BC0C0',
                '#c094c7',
                '#F5BC00',
                '#EC4262',
            ],
            label: 'Amount'
        }],
        labels: loadedLabels,
    };

    return (
        <Pie data={data}  height={350}  options={options} />
    );
};

export default PolarGraph;