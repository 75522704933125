import React, {useEffect} from 'react';
// CSS
import Classes from '../../../styles'
// COMPONENTS
import InfoList from '../components/utils/InfoList';

// MATERIAL UI
import {Grid, Typography, Box, Container} from '@material-ui/core';
import TaxesListView from "../components/billing/TaxesList";
import SerialsListView from "../components/billing/SeriesAndCorrelativeList";
import {handleViewPaymentPanel} from "../../common/actions/actions";
import {connect, useDispatch} from "react-redux";
import {loadBillings, loadExchangeRate, consultingExchangeRate} from "../actions/asyncAction";
import {getOutlets} from "../actions/store/async";

import Loader from "../../common/components/utils/Loader";
import ExchangeRateList from "../components/billing/ExchangeRateList";

const Billing = ({paymentPanel,billingLoading,areasFetch}) => {
    const classes = Classes();
    const dispatch = useDispatch();

    useEffect(() => {
        if(paymentPanel){
            dispatch(handleViewPaymentPanel(false));
        }
        dispatch(getOutlets());
        dispatch(loadBillings());
        dispatch(loadExchangeRate());
        dispatch(consultingExchangeRate())
    },[billingLoading,areasFetch]);

    if (billingLoading && !areasFetch) {
        return (
            <Container className={classes.styles.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Loader/>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h1" className={classes.typography.pageTitles}>
                    <Box display="inline" color="text.secondary">
                        Settings /
                    </Box>
                    &nbsp;Billing
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={2}>
                        <InfoList title={"Series & correlatives"}
                                  description={"Generate receipts with different " +
                                  "serial numbers according to your location. " +
                                  "Set up if you need an initial number for correlatives."}/>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <SerialsListView/>
                    </Grid>
                </Grid>
                <br/>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={2} >
                        <InfoList title={"Taxes"}
                                  description={"In accordance with the legal requirements in your " +
                                  "country you can easily set taxes, and fees or other charges. " +
                                  "Contact support."}/>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <TaxesListView/>
                    </Grid>
                </Grid>
                <br/>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={2} className={classes.containers.infoList}>
                        <InfoList title={"Currency and Exchange rate"} description={"Here are described the currencies and exchange rates values"}/>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <ExchangeRateList/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

const mapStateToProps = (state) => {
    return {
        paymentPanel:state.utilsReducer.showPaymentPanel,
        billingLoading:state.billingSettingReducer.isLoading,
        areasFetch:state.areasSettingReducer.isFetch,
    }
};

export default connect(mapStateToProps)(Billing)