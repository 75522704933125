import { connect } from "react-redux"

import RoomList from "../../components/clients/RoomList";
import {getRooms} from "../../redux/selector";
import { setFilterClient } from "../../redux/actions"

const mapStateToProps = (state) => ({
    rooms: getRooms(state),
});

export default connect(mapStateToProps, {setFilterClient})(RoomList)