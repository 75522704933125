export const formatDate = (date) => {
    let year = date.getFullYear()
    let month = date.getMonth() + 1 < 10 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1;
    let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    return year + "-" + month + "-" + day
};

export const formatTime = (date) => {
    let hour = date.getHours() > 9 ? date.getHours() : '0' + date.getHours();
    let minute = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes();
    return hour + ":" + minute;
};

export const formatedReverseDate = (date) => {
    let auxDate = date;
    let formatedDate = auxDate.split("-").reverse().join("-");
    return formatedDate;
};

export const handleCurrentDate = () => {
    const date = new Date();
    const auxDate = formatDate(date);
    const formatAuxDate = formatedReverseDate(auxDate);
    const auxTime = formatTime(date);
    const stringTime = auxTime.toString();
    const stringDate = formatAuxDate.toString();
    return stringDate + "  hrs: " + stringTime;
};