import {
    FILTER_SEARCH_MULTIPLE,
    GET_DATE_RANGE, 
    GET_CURRENT_DATE, OPEN_MODAL_VIEW
} from './actionTypes';

export const filterSearchMultiple = value => ({
    type: FILTER_SEARCH_MULTIPLE,
    value
});

export const getDateRange = (initialDate, initialTime, endDate, endTime) => ({
    type: GET_DATE_RANGE,
    initialDate,
    initialTime,
    endDate,
    endTime
});

export const getCurrentDate = (currentDate) => ({
    type: GET_CURRENT_DATE,
    currentDate
});

export const openModalView = (value) => ({
    type: OPEN_MODAL_VIEW,
    value
});