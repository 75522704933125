import {
    CLEAR_COMPANY_FLAG,
    CLEAR_COMPANY_INFO,
    CLEAR_RRHH_PAYMENT_FLAG,
    SELECT_PAYMENT_OPTIONS,
    SET_COMPANY,
    SET_COMPANY_FLAG,
    SET_EXCHANGE_RATE,
    SET_GROUP_PAYMENT,
    SET_PAYMENT_CURRENCY,
    SET_PAYMENT_TYPE,
    SET_RRHH_PAYMENT_FLAG
} from "../actionTypes"

const initialState = {
    isCompany: false,
    company: {
        taxPayer: "",
        taxPayerNumber: "",
        address: "",
    },
    exchangeRate: 1,
    paymentType: "CASH",
    description: "",
    currencies: {},
    groupPayment: false,
    selectedPaymentOption: "",
    isRrhhPayment: false
};

const setgroupPayment = (state = initialState.groupPayment, action) => {
    switch (action.type) {
        case SET_GROUP_PAYMENT :
            return action.payload
        default:
            return state
    }
};

const isRrhhPayment = (state = initialState.isRrhhPayment, action) => {
    switch (action.type) {
        case SET_RRHH_PAYMENT_FLAG :
            return action.value
        case CLEAR_RRHH_PAYMENT_FLAG:
            return false
        default:
            return state
    }
};

const isCompany = (state = initialState.isCompany, action) => {
    switch (action.type) {
        case SET_COMPANY_FLAG :
            return action.isCompany
        case CLEAR_COMPANY_FLAG:
            return false
        default:
            return state
    }
};

const companyInfo = (state = initialState.company, action) => {
    switch (action.type) {
        case SET_COMPANY:
            return {...action.payload}
        case CLEAR_COMPANY_INFO:
        case CLEAR_COMPANY_FLAG:
            return {
                taxPayer: "",
                taxPayerNumber: "",
                address: "",
            }
        default :
            return state
    }
};

const setPaymentType = (state = initialState.paymentType, action) => {
    switch (action.type) {
        case SET_PAYMENT_TYPE:
            return action.paymentType
        default:
            return state
    }
}

const setCurrenciesPayment = (state = initialState.currencies, action) => {
    switch (action.type) {
        case  SET_PAYMENT_CURRENCY:
            return {...state, ...action.payload}
        default:
            return state
    }
}

const setExchangeRate = (state = initialState.exchangeRate, action) => {
    switch (action.type) {
        case SET_EXCHANGE_RATE:
            return action.payload
        default:
            return state
    }
}

const setSelectedPaymentOption = (state = initialState.selectedPaymentOption, action) => {
    switch (action.type) {
        case SELECT_PAYMENT_OPTIONS:
            return action.value
        default:
            return state
    }
}

const paymentStateReducer = (state = initialState, action) => {
    return {
        isCompany: isCompany(state.isCompany, action),
        company: companyInfo(state.company, action),
        paymentType: setPaymentType(state.paymentType, action),
        exchangeRate: setCurrenciesPayment(state.exchangeRate, action),
        currencies: setExchangeRate(state.currencies, action),
        groupPayment: setgroupPayment(state.groupPayment, action),
        selectedPaymentOption: setSelectedPaymentOption(state.selectedPaymentOption, action),
        isRrhhPayment: isRrhhPayment(state.isRrhhPayment, action)
    }
};

export default paymentStateReducer