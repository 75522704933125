import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Field, getFormInitialValues, reduxForm} from "redux-form";
import {connect, useDispatch} from 'react-redux';
import {submitExchangeRateForm, validate} from "../../forms/submit/exchangerate.submit";
// COMPONENTS
import InfoList from '../utils/InfoList';
// MATERIAL UI
import {Box, Button, FormControl, FormHelperText, Grid, TextField, Typography} from '@material-ui/core';
import {handleViewPaymentPanel} from "../../../common/actions/actions";
// CSS
import Classes from '../../../../styles';
import {
    fieldValueSecondCurrency,
    switchActiveSecondCurrency
} from "../../../common/components/utils/ReduxFromMaterialUI";

let ExchangeRateForm = (props) => {
    const classes = Classes();
    const origin = localStorage.getItem('origin');
    const [eventActive, setEventActive] = useState(false);
    const [resetValue, setResetValue] = useState(false);
    const dispatch = useDispatch();
    const {handleSubmit, pristine, reset, submitting, exchangeRate, initialValues} = props;

    const handleCancel = () => {
        reset();
        props.history.goBack();
    };

    const activeSecondaryCurrency = (e) => {
        const value = e.target.checked;
        setTimeout(() => setEventActive(value), 100);
        setResetValue(true)

    };

    const cancelResetValue = () => {
        setResetValue(false)
    };

    useEffect(() => {
        if (initialValues !== undefined) {
            if (initialValues.exchangeRate > 0) {
                setEventActive(true)
            } else {
                setEventActive(false)
            }
        }

        if(initialValues === undefined){
            handleCancel()
        }
        dispatch(handleViewPaymentPanel(false));
    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h1" className={classes.typography.pageTitles}>
                    <Box display="inline" color="text.secondary">Settings /</Box>
                    <Link to={`../billing`}
                          className={`general-nav- ${classes.nav.link}`}>
                        <Box display="inline" color="text.secondary">
                            &nbsp;Billing /
                        </Box>
                    </Link>
                    &nbsp;Currency and Exchange Rate
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Grid container item  spacing={2}>
                                <Grid item xs={12} sm={2}>
                                    <InfoList title={"Currency"}
                                              description={""}/>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Box display="flex" flexDirection="column">
                                        <FormControl
                                            className={`general-forms- ${classes.forms.formMargin} general-forms-`}>
                                            <TextField className={`general-forms- ${classes.forms.textFieldBox} `}
                                                       id="standard-required"
                                                       label="Primary currency"
                                                       value={props.exchangeRate.primary.code || ""}
                                                       disabled
                                                       InputLabelProps={{
                                                           shrink: true
                                                       }}
                                            />
                                        </FormControl>
                                        <Box className={`general-forms-`}>
                                            <FormHelperText>Use secondary currency?</FormHelperText>
                                            <Field
                                                name="activeSecondCurrency"
                                                label=""
                                                component={switchActiveSecondCurrency}
                                                active={eventActive}
                                                setActive={activeSecondaryCurrency}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            eventActive &&
                                <Grid item xs={12}>
                                    <Grid container item  spacing={3}>
                                        <Grid item xs={12} sm={2}>
                                            <InfoList title={"Exchange rate"}
                                                      description={"Set the global exchange rate for the project."}/>
                                        </Grid>
                                        <Grid item xs={12} sm={10}>
                                            <Grid container item  spacing={2}>
                                                <Grid item xs={12} >
                                                    <FormControl >
                                                        <TextField className={`general-forms- ${classes.forms.textFieldBox} `}
                                                                id="standard-required"
                                                                label="Secondary currency"
                                                                value={props.exchangeRate.secondary.code || ""}
                                                                disabled
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={9} md={6}>
                                                    <Box fontStyle="oblique" borderRadius={5}  color="text.secondary" p={2}
                                                        style={{border: "1px solid #5BE576"}}>
                                                        <Typography variant="body1">Exchange rate
                                                            suggested {props.exchangeRate.secondary.code} :
                                                            <span style={{color: "#5BE576", marginLeft: 10}}>
                                                                {props.consultingExchangeRate}
                                                            </span>
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} >
                                                    <Box display="flex" flexDirection="row" >
                                                        <Field
                                                            id="exchange-rate"
                                                            label="Value"
                                                            name="exchangeRate"
                                                            type="number"
                                                            component={fieldValueSecondCurrency}
                                                            resetValue={resetValue}
                                                            cancelResetValue={cancelResetValue}
                                                            className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} `}
                                                            style={{width: '10em', marginRight: '0.15em'}}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                        <FormHelperText style={{padding: '15px 0 0 5px'}}> * se sugiera usar el valor del
                                                            Exchange rate {props.exchangeRate.secondary.code} </FormHelperText>
                                                    </Box>           
                                                </Grid>
                                           </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                        }
                        <Grid item xs={12}>
                            <Grid container item  spacing={3}>
                                <Grid item xs={12} sm={2}>
                                    <InfoList title={"Last edition"} description={""}/>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <TextField  className={`general-forms- ${classes.forms.textFieldBox} `}
                                                id="standard-required"
                                                label="Date"
                                                value={props.exchangeRate.createdDate || ""}
                                                disabled
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container item  spacing={3}>
                                <Grid item xs={12} sm={2}/>
                                <Grid item xs={12} sm={10}>
                                    <Button type="submit" variant="contained" color="primary"
                                            style={{margin: '0 0.8em 0 0', width: '100px'}}
                                            disabled={pristine || submitting}
                                    >Save</Button>
                                    <Button variant="outlined" color="primary"
                                            style={{margin: '0 0.8em 0 0', width: '100px'}}
                                            onClick={handleCancel}
                                    >Cancel</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
};

ExchangeRateForm = reduxForm({
    form: 'rate',
    onSubmit: submitExchangeRateForm,
    validate
})(ExchangeRateForm);

ExchangeRateForm = connect(
    state => {
        return {
            exchangeRate: state.exchangeRateReducer,
            initialValues: getFormInitialValues('rate')(state),
            consultingExchangeRate: state.exchangeRateReducer.consultingExchangeRate
        }
    }
)(ExchangeRateForm);


export default ExchangeRateForm