import React from 'react';
import errorPage400 from "../../../../assets/errorPage400.svg";
import ErrorPageDisplay from "./errorPageDisplay";

const page400 = () => {
    return (
        <ErrorPageDisplay errorName="Bad request" errorDisplay={errorPage400}/>
    )
};

export default page400