import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {handleDynamicDialog} from "../../actions/actions"
import {connect, useDispatch} from "react-redux"

import {Box, Button, IconButton, Typography} from '@material-ui/core/'

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import AlertIcon from '@material-ui/icons/ErrorOutlineOutlined';
import StoreIcon from '@material-ui/icons/LocalGroceryStoreOutlined';
import RoomServiceIcon from '@material-ui/icons/RoomServiceOutlined';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutline';

const styles = (theme, props) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    dialog: {
        '& > div> div': {
            maxWidth: 500,
            padding: '10px 20px 25px 20px'
        }

    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },

});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h4" style={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 200
            }}
            >{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        justifyContent: "center",

        '& .btn-delete': {
            backgroundColor: theme.palette.error.main,
        },
        '& .btn-warning': {
            backgroundColor: theme.palette.warning.main,
        },
        '& .btn-success': {
            backgroundColor: theme.palette.primary.main,
        },
        '&  .btn-action >span': {
            padding: '0px 8px',
            color: '#ffffff'
        }
    }
}))(MuiDialogActions);

const ClientDynamicDialog = withStyles(styles)(({opened, params, ...props}) => {
    const classes = props.classes;
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(handleDynamicDialog("", null))
    };

    const handleConsumptionAction = () => {
        props.handleConsumptionFunction(params);
        dispatch(handleDynamicDialog("", null))
    };

    const handleStoreAction = () => {
        props.handleStoreFunction(params);
        dispatch(handleDynamicDialog("", null))
    };

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.keyValue === props.door}
                    className={classes.dialog}>
                <DialogContent>
                    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                        {
                            props.type === 'delete' && <DeleteIcon color="error" style={{fontSize: 85}}/> ||
                            props.type === 'warning' && <AlertIcon style={{fontSize: 85, color: "#F5BC00"}}/>
                        }

                        <DialogTitle id="customized-dialog-title">
                            {props.title}
                        </DialogTitle>
                        <Typography align="center" color="textPrimary" style={{fontWeight: 150}}>
                            {props.msg}
                        </Typography>
                    </Box>
                </DialogContent>
                <br/>
                <DialogActions>
                    <Button autoFocus
                            onClick={handleConsumptionAction}
                            className={`btn-${props.type} btn-action`}
                    >
                        <RoomServiceIcon/> Go to consumptions
                    </Button>
                    <Button autoFocus
                            onClick={handleStoreAction}
                            className={`btn-${props.type} btn-action`}
                    >
                        <StoreIcon/> Go to store
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
});

const mapStateToProps = (state) => {
    return {
        door: state.utilsReducer.handleDynamicDialog.door,
        params: state.utilsReducer.handleDynamicDialog.params
    }
};

export default connect(mapStateToProps)(ClientDynamicDialog)
