import {
    FILTER_SEARCH_MULTIPLE,
    VIEW_PAYMENT_PANEL,
    GET_DATE_RANGE,
    OPEN_DYNAMIC_DIALOG,
    OPEN_LOADER_PROGRESS,
    GET_CURRENT_DATE,
    SET_MENU_ITEM,
    OPEN_MODAL_VIEW
} from '../actions/actionTypes';

import {
    SET_CLIENT_CRITERIA,
    CLEAN_FILTER
} from '../../pos/redux/actionTypes';
import {combineReducers} from "redux";
import {SET_SUPPORT_FILTER_VALUE} from "../../rumi/actionTypes";

const  initialState = ''
 function searchReducer (state = initialState, action) {
    switch (action.type) {
        case FILTER_SEARCH_MULTIPLE:
            return  action.value
        case SET_CLIENT_CRITERIA:
        case SET_SUPPORT_FILTER_VALUE:
        case CLEAN_FILTER:
            return initialState
        default :
            return state
    }
}

const showPaymentPanelReducer = (state= true , action) => {
    switch (action.type) {
        case VIEW_PAYMENT_PANEL:
            return  action.showPaymentPanel
        default :
            return state
    }
}

const getDateRangeReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_DATE_RANGE:
            return {
                initDate: action.initialDate,
                 initTime: action.initialTime, 
                 endDate: action.endDate, 
                 endTime: action.endTime
                } 
        default :
            return state
    }
}

const handleDynamicDialogReducer = (state = {door: "",  params: null }, action) => {
    switch (action.type) {
        case OPEN_DYNAMIC_DIALOG:
            return {door: action.door, params: action.paramsFunction }
        default :
            return state
    }
}

const openLoaderProgressReducer = (state = false, action) => {
    switch (action.type) {
        case OPEN_LOADER_PROGRESS:
            return action.open
        default :
            return state
    }
}

const getCurrentDateReducer = (state = "", action) => {
    switch (action.type) {
        case GET_CURRENT_DATE:
            return action.currentDate
        default :
            return state
    }
}

const setMenuItemReducer = (state = "", action) => {
    switch (action.type) {
        case  SET_MENU_ITEM:
            return action.name
        default :
            return state
    }
}

const modalViewReducer = (state = {door: ""}, action) => {
    switch (action.type) {
        case   OPEN_MODAL_VIEW:
            return {
                door: action.value
            }
        default :
            return state
    }
}


export default combineReducers({showPaymentPanel:showPaymentPanelReducer, 
    search:searchReducer, getDateRange:getDateRangeReducer, handleDynamicDialog: handleDynamicDialogReducer,
    openLoaderProgress:  openLoaderProgressReducer, currentDate: getCurrentDateReducer,
    selectedMenuItem: setMenuItemReducer , modalViewReducer: modalViewReducer
})