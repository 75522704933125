import React, {useEffect, useState} from 'react';
import {Bar} from 'react-chartjs-2';

const BarGraph = (props) => {
    let loadedAmounts = props.barData["amountsDatasets"] ? props.barData["amountsDatasets"] : [0,0,0]
    let loadedLabels = props.barData["labels"] ? props.barData["labels"] : ["No data", "No data", "No data"];
    let loadedDatasets = props.barData["datasets"]? props.barData["datasets"] :[0,0,0];
  
    const options={
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                display: false,
                ticks: {
                    beginAtZero: true,
                    min: 0
                }
            }],
            xAxes: [{
                display: false,
                ticks: {
                    beginAtZero: true,
                    min: 0
                }
            }]
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    var labelQty = "Quantity: "
                    var labelAmount = "Amount: "
                    var amount = !loadedAmounts[tooltipItem.datasetIndex] ? "0" : loadedAmounts[tooltipItem.datasetIndex].toFixed(2) 
                    
                    labelQty += data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] 
                    labelAmount +=  amount
                   
                    return labelQty + ' / ' +  labelAmount;
                }
            }
        },
        legend: {
            display:true,
            position:'bottom',
            labels: {
                fontSize: 12,
                usePointStyle: true
            }
        },
        layout: {
            padding: {
                bottom: 10
            }
        }
    }

    const data = {
        labels: [""],
        datasets: [
            {
                label: [loadedLabels[0]],
                data: [loadedDatasets[0]],
                backgroundColor: 'rgba(91,229,118)',
                borderColor: 'rgba(91,229,118)',
                hoverBackgroundColor:'rgba(91,229,118,0.4)',
                hoverBorderColor:'rgba(91,229,118)',
                borderWidth: 1,
            },
            {
                label: [loadedLabels[1]],
                data: [loadedDatasets[1]],
                backgroundColor: 'rgba(57,205,200)',
                borderColor: 'rgb(57,205,200)',
                hoverBackgroundColor:'rgba(57,205,200,0.4)',
                hoverBorderColor:'rgb(57,205,200)',
                borderWidth: 1,
            },
            {
                label: [loadedLabels[2]],
                data: [loadedDatasets[2]],
                backgroundColor: 'rgba(192,148,199)',
                borderColor: 'rgb(192,148,199)',
                hoverBackgroundColor:'rgba(192,148,199,0.4)',
                hoverBorderColor:'rgb(192,148,199)',
                borderWidth: 1,
            },
        ],
    };

    return (
        <Bar data={data} height={350} options={options} />
    );
};

export default BarGraph;