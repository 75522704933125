import {
    ADD_TO_CART,
    CLEAN_FILTER,
    CREATE_MESSAGE,
    DELETE_FAILED_SALE,
    DISABLE_CLOSE_SHIFT,
    EMPTY_CART,
    FILTER_BALANCE_FLOWS_REPORT,
    FILTER_CASH_REPORT,
    FILTER_CREDIT_NOTES_REPORT,
    FILTER_GUEST_FLOWS_REPORT,
    FILTER_INVOICES_REPORT,
    FILTER_RANKING_REPORT,
    FILTER_SALES_REPORT,
    FILTER_TICKETS_REPORT,
    GET_CURRENT_ROUTE,
    GET_ERRORS,
    OPEN_PAYMENT_SECTION,
    REFRESH_STATE,
    REMOVE_NOTICE_CART,
    REMOVE_TO_CART,
    SET_CATEGORY,
    SET_CLIENT,
    SET_CLIENT_CRITERIA,
    SET_PAYMENT_TYPE,
    SET_TRANSFER_PAYMENT,
    SET_VARIABLE_PRODUCT,
    SUBTRACT_TO_CART,
    TOGGLE_TRANSFER_PAYMENT_SECTION,
    UPDATE_VARIABLE_PRODUCT,
    VALIDATE_EXTERNAL,
} from './actionTypes'
import {handleAlerts} from '../../common/actions/actions'
import {getDefaultPaymentCustomerType} from "../utils/payment.utils";
import {CLEAN_CONSUMPTION, CONSUMPTION_FETCHING} from "../../rumi/actionTypes";
import {processCancelSale} from "./asyncActions";

export const requestError = (error) => ({
    type: GET_ERRORS,
    msg: error.response.data,// error clients
    status: error.response.status
});

export const createMessage = (msg) => ({
    type: CREATE_MESSAGE,
    msg: msg
});

export const setFilterCategory = (categoryId) => ({
    type: SET_CATEGORY,
    categoryId
});

export const setFilterBalanceFlowsReport = (filterClass) => ({
    type: FILTER_BALANCE_FLOWS_REPORT,
    payload: filterClass
});

export const setFilterGuestFlowsReport = (filterClass) => ({
    type: FILTER_GUEST_FLOWS_REPORT,
    payload: filterClass
});

export const setFilterSalesReport = (filterClass) => ({
    type: FILTER_SALES_REPORT,
    payload: filterClass
});

export const setFilterTicketsReport = (filterClass) => ({
    type: FILTER_TICKETS_REPORT,
    payload: filterClass
});

export const setFilterInvoicesReport = (filterClass) => ({
    type: FILTER_INVOICES_REPORT,
    payload: filterClass
});

export const setFilterCreditNotesReport = (filterClass) => ({
    type: FILTER_CREDIT_NOTES_REPORT,
    payload: filterClass
});

export const setFilterRankingReport = (filterClass) => ({
    type: FILTER_RANKING_REPORT,
    payload: filterClass
});

// BALANCE


export const deleteSalesHistory = (saleHistory) => {
    return async (dispatch) => {
        try {
            dispatch(processCancelSale(saleHistory));
            dispatch(cleanCustomerFilter());
        } catch (e) {
            dispatch(handleAlerts('error', 'Failed remove ', true))
        }
    }
};

export const deleteFailedSale = (saleID) => {
    return async (dispatch) => {
        dispatch({
            type: DELETE_FAILED_SALE,
        })
    }
};

// CLOSE SHIFT
export const changeDisableCloseShift = (disable) => ({
    type: DISABLE_CLOSE_SHIFT,
    payload: disable
});

export const filterCashReport = (dateSelected) => ({
    type: FILTER_CASH_REPORT,
    dateSelected
});

// ALL
export const refreshState = () => ({
    type: REFRESH_STATE
});

// PRODUCTS
export const addProductCart = (productId) => ({
    type: ADD_TO_CART,
    productId
});
export const addVariableProduct = (product, newPrice) => ({
    type: SET_VARIABLE_PRODUCT,
    payload: {
        product: product,
        newPrice: newPrice
    }
})

export const editVariableProduct = (product, newPrice) => ({
    type: UPDATE_VARIABLE_PRODUCT,
    payload: {
        product: product,
        newPrice: newPrice
    }
})

export const subtractProductCart = productId => ({
    type: SUBTRACT_TO_CART,
    productId
});
export const removeProductCart = productId => ({
    type: REMOVE_TO_CART,
    productId
});

export const cleanCart = () => ({
    type: EMPTY_CART
});

export const removeNoticeCart = () => ({
    type: REMOVE_NOTICE_CART,
});

export const cleanConsumption = () => ({
    type: CLEAN_CONSUMPTION
});

export const consumptionFetching = () => ({
    type: CONSUMPTION_FETCHING
});

export const setFilterClient = (criteria, value = null) => {
    return {
        type: SET_CLIENT_CRITERIA,
        payload: {
            criteria: criteria,
            value: value
        }
    }
};
export const cleanCustomerFilter = () => ({type: CLEAN_FILTER});

export const cleanFilter = () => ({type: CLEAN_FILTER});

export const setExternal = (client) => {
    let {name, documentNumber} = client;
    return {
        type: SET_CLIENT,
        client: {
            client
        },
        optionClientType: "External",
        paymentCustomerType: "EXTERNAL",
        paymentType: "CASH",
    }
};

export const setClient = (client, optionClientType) => {
    let {
        id,
        name,
        surname,
        applyDiscount,
        reservationId,
        groupId,
        customerId,
        documentNumber,
        documentType,
        arrivalCountry,
        pmsGuestId,
        blocked,
        country,
        debt,
        chargeBlock,
        fullName,
        active
    } = client;
    let isFetch = true;
    const [paymentCustomerType] = getDefaultPaymentCustomerType(client, optionClientType);
    return {
        type: SET_CLIENT,
        client: {
            id,
            name,
            surname,
            fullName,
            applyDiscount,
            reservationId,
            groupId,
            customerType: paymentCustomerType,
            customerId,
            documentNumber,
            isFetch,
            pmsGuestId,
            blocked,
            country,
            debt,
            chargeBlock,
            active,
            documentType,
            arrivalCountry
        },
        paymentCustomerType: paymentCustomerType,
        optionClientType
    }
};

export const setPaymentType = (paymentType, value) => ({
    type: SET_PAYMENT_TYPE,
    paymentType: paymentType,
    value
});

export const setTransferPayment = (selectedTransferPayment) => ({
    type: SET_TRANSFER_PAYMENT,
    selectedTransferPayment
});

export const toggleTransferPaymentSection = (status, key) => ({
    type: TOGGLE_TRANSFER_PAYMENT_SECTION,
    status,
    key
});

export const togglePaymentSection = (value) => ({
    type: OPEN_PAYMENT_SECTION,
    value
})

/* CLIENT PANEL ACTIONS */

export const setValidateExternal = (validated) => ({
    type: VALIDATE_EXTERNAL,
    validated
});

export const getCurrentRoute = (route) => ({
    type: GET_CURRENT_ROUTE,
    route
});

