import {
    ADD_TO_CART,
    SUBTRACT_TO_CART,
    EMPTY_CART,
    REMOVE_TO_CART,
    SUCCESS_PAYMENT,
    REMOVE_NOTICE_CART
}
from "../redux/actionTypes";

const initialState = {
    productsIds: [],
    quantityById: {},
    showCardProducts: false,
    chargedProduct: null,
    showNoticeCart: false
};

const addProductsId = (state=initialState.productsIds, action) => {
    switch (action.type) {
        case ADD_TO_CART:
            if (state.indexOf(action.productId) !== -1) {
                return state
            }
            return [...state, action.productId]

        case REMOVE_TO_CART:
            return  state.filter(product => product !== action.productId)
        
        default:
            return state
    }
}

const quantityByProduct = (state=initialState.quantityById, action)=>{
    switch (action.type) {
        case ADD_TO_CART:
            return { ...state, [action.productId]: (state[ action.productId] || 0) + 1}
        case SUBTRACT_TO_CART:
            return {...state, [action.productId]: validateSubtract(state[action.productId])}
        case REMOVE_TO_CART:
            const  {[action.productId]: value, ...products} = state
            return products
        default:
            return state
    }
}

const validateSubtract = (quantity) =>  quantity - 1 || 1
export const cartProductsIds = (state) => state.productsIds
export const  productQuantity = (state, id) => state.quantityById[id]
 
const chargedProductReducer = (state=initialState.chargedProduct, action) => {
    switch (action.type) {
        case ADD_TO_CART:
            return action.productId
        default:
            return state
    }
}

const noticeCartReducer = (state=initialState.chargedProduct, action) => {
    switch (action.type) {
        case ADD_TO_CART:
            return true
        case REMOVE_NOTICE_CART:
            return false
        default:
            return state
    }
}

const  cart = (state = initialState, action) => {
    switch (action.type) {
        case EMPTY_CART:
        case SUCCESS_PAYMENT:
            return initialState  

        default:
            return {
                productsIds: addProductsId(state.productsIds, action),
                quantityById:  quantityByProduct(state.quantityById, action),
                chargedProduct: chargedProductReducer(state.chargedProduct, action),
                showNoticeCart: noticeCartReducer(state.showNoticeCart,action)
            }
    }
}

export default cart