const handleDataFiltered = (obj, searchValue) => {
    
    if (!searchValue) {
        return obj

    } else {
        return obj.filter(auxObj => {
           return findArray(auxObj.products,searchValue) || Object.values(auxObj).toString().toLowerCase().includes(searchValue.toLowerCase())
        })
    }
}

const findArray = (array,search)=>{
    let flag = false;
    for(let i=0;i<array.length;i++){
        if(Object.values(array[i]).toString().toLowerCase().includes(search.toLowerCase())){
            flag=true;
            break;
        }
    }
    return flag;
}


export default handleDataFiltered
