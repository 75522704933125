import {makeStyles} from '@material-ui/core/styles';


export default makeStyles((theme) => ({
    /* custom class*/
    textFieldOutlined: {
        '& .MuiOutlinedInput-input': {
            padding: 10,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.main
        },
        '& .MuiOutlinedInput-adornedStart': {
            paddingLeft: 1
        }
    },
    textField: {
        '& .MuiInput-underline:after': {
            borderBottom: '2px solid' + theme.palette.secondary.main
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: '2px solid' + theme.palette.text.secondary
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: theme.palette.secondary.main
        },
        '& div > div > button': {
            color: '#757575c7' // grey-
        }
    },
    searchHolder: {
        '& div > div > svg:hover': {
            color: theme.palette.primary.main
        }
    },
    searchInput: {
        '& div': {
            marginLeft: '0.5em',
        },
        borderBottom: 'none!important',
        '&:before': {
            borderBottom: 'none!important'
        },
        '&:after': {
            borderBottom: 'none!important'
        },
    },
    formMargin: {
        marginBottom: '1.5em',
    },
    formWidth: {
        [theme.breakpoints.down('sm')]: {
            width: '22.5em'
        },
        [theme.breakpoints.up('md')]: {
            width: '35em'
        }
    },
    fullFormWidth: {
        width: "100%"
    },
    textFieldBox: {
        "& .MuiInputBase-root.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.28)"
        },
        '& .MuiInputBase-root': {
            backgroundColor: theme.palette.common.white
        },
        '& .MuiInput-underline:before': {
            borderBottom: 'none!important',

        },
        '& .MuiInput-underline:after': {
            borderBottom: 'none!important'
        },
        '& div > input': {
            borderRadius: '0.3em',
            paddingLeft: '0.8em'
        },
        '& div': {
            boxShadow: '0px 1px 0px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14)',
            borderRadius: '0.3em'
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: theme.palette.secondary.main
        }
    },
    textarea: {
        paddingLeft: '0.8em',
        paddingTop: '0.5em',
        border: 'none',
        borderRadius: '0.3em',
    },
    select: {
        borderRadius: '0.3em',
        '&:before': {
            borderBottom: 'none!important'
        },
        '&:after': {
            borderBottom: 'none!important'
        },
        paddingLeft: '0.8em',
        backgroundColor: theme.palette.common.white,
        '& .MuiSelect-select:focus': {
            borderRadius: '0.3em',
            backgroundColor: theme.palette.common.white,
        },
        boxShadow: '0px 1px 0px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14)',
    },
    inputExternal: {
        '& .MuiInputBase-root': {
            fontSize: '1em!important'
        }
    },
    inputUploadFile:{
        '&::file-selector-button':{
            fontSize: '14px',
            fontWeight: '500',
            margin: '0.5em',
            padding: '0.8em',
            borderRadius: '20px',
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
            boxShadow: '0px 1px 0px -1px rgba(0,0,0,0.2), 0px 1px 5px 0px rgba(0,0,0,0.14)!important',
            border:'none',
            cursor: 'pointer',
        }
    }
}));