import {filterClient} from "../redux/selector";
import {handleCustomerSearch, handleProductsSearch, handleSupportSearch} from "../../common/selectors/utilsSelector";
import {getCategoryProducts, getCategoryProductsSettings} from "./index";
import {customersSelector} from "./customer.selector";
import {getCustomers,getVolunteers} from "../redux/selector/index";


export const showClientList = state => {
    return state.clientCriteria.showClientList || state.utilsReducer.search
};

export const questionsList = state => {
    const searchValue = state.utilsReducer.search;
    return !searchValue ?
        filterClient(state) :
        handleSupportSearch(searchValue, questionsList(state))
};


export const customerList = state => {
    const searchValue = state.utilsReducer.search;
    let filteredCLients = null;
    let clientsSearch = null;
    if(!searchValue){
        filteredCLients = filterClient(state);
        return filteredCLients;
    }else{
        const allCustomers = customersSelector(state).concat(getCustomers(state)).concat(getVolunteers(state));
        clientsSearch = handleCustomerSearch(searchValue, allCustomers);
        return clientsSearch;
    }
};

function customersInput(state) {
    const guestList = Object.values(state.pmsEntities.guest);
    const staffList = Object.values(state.customerSettings.customers);
    return guestList.concat(staffList)
}

function productInput(state) {
    return Object.values(state.products.all)
   
}

export const productListGenerator = productSelector => state => {
    const searchValue = state.utilsReducer.search;
    return !searchValue ?
        productSelector(state) :
        handleProductsSearch(searchValue, productInput(state))
};

export const productsSettingsGenerator = productSelector => state => {
    const allProducts = Object.values(state.storeReducer.productList)
    const searchValue = state.utilsReducer.search;
    return !searchValue ?
        productSelector(state) :
        handleProductsSearch(searchValue, allProducts)
};


export const productList = productListGenerator(getCategoryProducts)
export const productSettingList = productsSettingsGenerator(getCategoryProductsSettings)
