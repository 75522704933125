import {LOAD_HISTORY, LOAD_SELECTED_DOCUMENTS, SELECT_RECEIPT, TOGGLE_RECEIPT_DETAILS} from "../actionTypes";

const initialState = {
    history:{
        customerName:"",
        customerTaxIdType:"",
        customerTaxIdNumber:"",
        billingDetails:[]
    },
    selectedDocuments:{},
    isHistoryLoaded:false,
    selectedReceipt: null,
    openReceiptDetails: false
};

const clientHistory = (state = initialState.history, action) => {
    switch (action.type) {
        case LOAD_HISTORY : return {
            ...state,
            customerName:action.payload.customerName,
            customerTaxIdNumber: action.payload.customerTaxIdNumber,
            customerTaxIdType: action.payload.customerTaxIdType,
            billingDetails: action.payload.billingDetails,
            isHistoryLoaded:true
        };
        default: return state
    }
};

const selectedDocuments = (state = initialState.selectedDocuments, action) => {
    switch (action.type) {
        case LOAD_SELECTED_DOCUMENTS : return {...state, selectedDocuments:action.payload, isHistoryLoaded:true};
        default: return state
    }
};

const selectedReceipt = (state = initialState.selectedReceipt, action) => {
    switch (action.type) {
        case SELECT_RECEIPT : return action.value ;
        default: return state
    }
};

const toggleReceiptDetails = (state = initialState.openReceiptDetails, action) => {
    switch (action.type) {
        case TOGGLE_RECEIPT_DETAILS : return action.value ;
        default: return state
    }
}

const clientHistoryReducer = (state = initialState, action) => {
    return {
        clientHistory: clientHistory(state.history, action),
        selectedDocuments: selectedDocuments(state.selectedDocuments, action),
        selectedReceipt : selectedReceipt(state.selectedReceipt, action),
        openReceiptDetails: toggleReceiptDetails(state.openReceiptDetails, action)
    }
};

export default  clientHistoryReducer