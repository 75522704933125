import React from 'react';
// COMPONENTS
import DatePicker from '../../../common/components/utils/DatePicker';
// COMPONENTS MATERIAL UI
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {Box, Checkbox, InputLabel} from '@material-ui/core/';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ReportDownloadIcon from '@material-ui/icons/AssignmentReturnedOutlined';
import NullMessage from '../../../common/components/error.pages/NullMessage'


// CSS
import Classes from '../../../../styles'
import {loadCashReport} from "../../redux/asyncActions";
import {useDispatch} from "react-redux";

const useStyles = makeStyles((theme) => ({
    listCashBalance: {
        '& .MuiTypography-root': {
            paddingTop: '1.2em'
        }
    }

}));


export default function CashReport({report, generateCashReport}) {
    const useclasses = useStyles();
    const classes = Classes();
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = React.useState("");
    const [date, setDate] = React.useState(new Date());


    const formatDate = (status, date) => {
        let year = date.getFullYear()
        let month = date.getMonth() + 1 < 10 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1
        let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()

        if (status === "reverse") {
            return year + "-" + month + "-" + day
        } else {
            return day + "-" + month + "-" + year
        }

    };

    const handleGetDate = (className, value) => {
        let auxDate = formatDate('normal', value)
        let auxDateReverse = formatDate('reverse', value)
        setDate(value)
        setSelectedDate(auxDate)

        dispatch(loadCashReport(auxDateReverse))
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Box width={200}>
                    <DatePicker
                        label={"Search Date"}
                        date={date}
                        handleGetDate={handleGetDate}
                        //dateClass={"date"}
                    />
                </Box>
                <br/>
                <Box className={classes.forms.formMargin}>
                    <InputLabel component="legend" color="secondary">Flows: {selectedDate}</InputLabel>
                    <br/>

                    {
                        report.length ?
                            <List dense component="div" role="list">
                                {report.map((cash, index) => {

                                    return (
                                        <ListItem key={cash.id} role="listitem" button
                                                  onClick={() => generateCashReport(cash.id)}
                                        >
                                            <ListItemIcon>
                                                <ReportDownloadIcon color="secondary"/>
                                            </ListItemIcon>
                                            <ListItemText id={cash.id}>
                                                {'open: ' + cash.startDate}&nbsp;&nbsp;-&nbsp;&nbsp; {'close: ' + cash.endDate}
                                            </ListItemText>
                                        </ListItem>
                                    );
                                })}
                                <ListItem/>
                            </List>
                            : <NullMessage message="There are no balances" height={"10em"}/>

                    }

                </Box>
            </Grid>

        </Grid>
    );
}
