import React from 'react';
import {Link} from 'react-router-dom';

// COMPONENTS
import FormEmployeeReduxForm from '../../components/employees/FormEmployeeReduxForm';
import InfoList from '../../components/utils/InfoList';

// MATERIAL UI
import {
    Grid,
    Typography,
    Box,
} from '@material-ui/core';
import Classes from '../../../../styles';

const Staff = () => {
    const classes = Classes();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h1" className={classes.typography.pageTitles}>
                    <Box display="inline" color="text.secondary">
                        Settings /
                    </Box>
                    <Link to={`../employees`}
                          className={`general-nav- ${classes.nav.link}`}>
                        <Box display="inline" color="text.secondary">
                            &nbsp;Employees /
                        </Box>
                    </Link>
                    &nbsp;Staff
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={2}>
                            <InfoList title={"Description"} description={"Data from your staff members"}/>
                        </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={10}>
                        <FormEmployeeReduxForm/>
                    </Grid>
                 </Grid>
                
            </Grid>
        </Grid>
    );
}

export default Staff
