import React from 'react';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {openModalView} from "../../actions/utilsActions"
import {activeAlertDragAndDrop} from '../../../rumi-settings/actions/store/sync';

import {nav} from '../../../../styles';
import {Box, Menu} from '@material-ui/core';
import ModalView from "../../components/modals/ModalView"
import SubmenuItem from "./SubmenuItem"

const UserItem = ((props) => {
    const classes = {
        link: nav().link
    };
    const dispatch = useDispatch();

    return (
        <Menu id="menu-appbar" anchorEl={props.anchorEl}
              anchorOrigin={{vertical: 'top', horizontal: 'right',}}
              keepMounted open={props.open} style={props.position}
              transformOrigin={{vertical: 'top', horizontal: 'right'}}
              onClick={props.handleClose}
              onMouseLeave={() => {
                  if (props.dropProductList !== undefined) {
                      if (props.dropProductList.length > 0) {
                          dispatch(activeAlertDragAndDrop(false))
                      }
                  }
              }}>
            {props.subItems.map((submenu, index) =>
                submenu.enableButton ?
                    (submenu.url === 'modal' ?
                            <Box key={index + 1}>
                                <Box display="flex" flexDirection="row" px={'0.8em'} py={'0'}
                                     onClick={() => {
                                         dispatch(openModalView(submenu.name))
                                         props.cleanEvents()
                                         if (submenu.handleAction !== undefined) {
                                             submenu.handleAction()
                                         }
                                     }}>
                                    <SubmenuItem
                                        submenu={submenu}
                                        setMenuItem={props.setMenuItem}
                                        menu={props.menu}
                                    />
                                </Box>
                                <ModalView
                                    title={submenu.name}
                                    keyValue={submenu.name}
                                    body={submenu.body}
                                />
                            </Box>
                            : <Link key={submenu.name} to={submenu.url} className={classes.link}>
                                <Box display="flex" flexDirection="row" px={'0.8em'} py={'0'}
                                     onClick={() => {
                                         props.cleanEvents()
                                         if (submenu.handleAction !== undefined) {
                                             submenu.handleAction()
                                         }
                                         if (props.setMenuItem !== undefined) {
                                             dispatch(props.setMenuItem(props.menu))
                                         }
                                     }}>
                                    <SubmenuItem submenu={submenu}
                                                 setMenuItem={props.setMenuItem}
                                                 menu={props.menu}
                                    />
                                </Box>
                            </Link>
                    ) :
                    <Box key={submenu.name} display="flex" flexDirection="row" px={'0.8em'} py={'0'}>
                        <SubmenuItem
                            submenu={submenu}
                            setMenuItem={props.setMenuItem}
                            menu={props.menu}
                        />
                    </Box>
            )}
        </Menu>
    )
});

export default UserItem