import React from 'react';
import {withRouter} from "react-router-dom";

// MATERIAL UI
import {Box, Button, Grid, Typography} from '@material-ui/core';

// CSS
import Classes from '../../../../styles'
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    pLTypePayment: {
        paddingLeft: '1em!important'
    },
    defaultShadowBtn: {
        boxShadow: "0px 0px 0px -0px rgba(0,0,0,0.0)",
    },
    shadowBtn: {
        boxShadow: ' 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
    },
    button: {
        height: '3.5em'
    },
    gridTypePayment: {
        padding: '5px 0'
    }
}));

const PaymentTypeList = ({
                             location, paymentTypes, paymentTypeSelected, setPaymentType, paymentCustomerType,
                             emptyCart, consumptions, validatedExternal, chargeBlock, paymentTypeBlocked,
                             selectedTransferPayment, keyTransfer, toggleTransferPaymentSection
                         }) => {

    const useclasses = useStyles();
    const classes = Classes();
    let auxDisabled = true;
    const disabledPaymentTypesOptions = (payType) => {
        if (location.pathname === "/billing/consumptions") {
            auxDisabled = (!(payType.enable(paymentCustomerType)) || !(parseInt(consumptions.total) > 0));
            if (payType.code === "CHRG" && (paymentTypeBlocked || chargeBlock)) {
                auxDisabled = paymentTypeBlocked || chargeBlock
            }
            return auxDisabled
        } else {
            auxDisabled = (!(payType.enable(paymentCustomerType)) || emptyCart) || validatedExternal;
            if (payType.code === "CHRG" && (paymentTypeBlocked || chargeBlock)) {
                auxDisabled = paymentTypeBlocked || chargeBlock
            }
            return auxDisabled
        }
    };

    return (
        <Grid container item spacing={1}>
            <Grid item xs={12} style={{paddingTop: 7}}>
                <Typography variant="caption" gutterBottom color="textSecondary">
                    Payments method
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container item>
                    {
                        paymentTypes.map((payType, index) => (
                            /* hide account type payment in consumptions view */
                            (location.pathname === "/billing/consumptions" && payType.code === "CHRG")
                                ? null :
                                Object.values(selectedTransferPayment).length > 1 ?
                                    keyTransfer === payType.code ?
                                        <Grid item xs={4} key={index} className={useclasses.gridTypePayment}>
                                            <Box display="flex" flexDirection="column" alignItems="center"
                                                 justifyContent="center">
                                                <Button
                                                    className={`${useclasses.defaultShadowBtn} ${useclasses.button}`}
                                                    variant={selectedTransferPayment.code === paymentTypeSelected ? "outlined" : "text"}
                                                    color={selectedTransferPayment.code === paymentTypeSelected ? "secondary" : "default"}
                                                    style={selectedTransferPayment.code === paymentTypeSelected ? {boxShadow: "1px 1px 1px #39CDC8, 0 0 10px #39CDC8, 0 0 1px #39CDC8"} : {}}
                                                    onClick={() => {
                                                        setPaymentType(selectedTransferPayment.code, true)
                                                        toggleTransferPaymentSection(false, null)
                                                    }}>
                                                    <img src={selectedTransferPayment.icon}
                                                         style={{
                                                             color: '#7a7a7a',
                                                             maxWidth: '3.5em',
                                                             maxHeight: '2.5em',
                                                         }}/>
                                                </Button>
                                                <Typography style={{fontWeight: 'bold'}} variant="caption"
                                                            color={selectedTransferPayment.code === paymentTypeSelected ? "secondary" : "textSecondary"}>
                                                    {selectedTransferPayment.name}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        : <Grid item xs={4} key={index} className={useclasses.gridTypePayment}>
                                            <Box display="flex" flexDirection="column" alignItems="center"
                                                 justifyContent="center"
                                                 style={disabledPaymentTypesOptions(payType) ? {cursor: 'not-allowed'} : {}}>
                                                <Button className={`${useclasses.defaultShadowBtn} ${useclasses.button}`}
                                                        variant={!(disabledPaymentTypesOptions(payType)) ? (payType.code === paymentTypeSelected ? "outlined" : "text") : "text"}
                                                        color={!(disabledPaymentTypesOptions(payType)) ? (payType.code === paymentTypeSelected ? "secondary" : "default") : "default"}
                                                        disabled={disabledPaymentTypesOptions(payType)}
                                                        style={!(disabledPaymentTypesOptions(payType)) ? (payType.code === paymentTypeSelected ? {boxShadow: "1px 1px 1px #39CDC8, 0 0 10px #39CDC8, 0 0 1px #39CDC8"} : {}) : {}}
                                                        onClick={() => {
                                                            if (!payType.isGroup) {
                                                                setPaymentType(payType.code, true)
                                                                toggleTransferPaymentSection(false, null)
                                                            } else {
                                                                toggleTransferPaymentSection(true, payType.code)
                                                            }
                                                        }}>
                                                    <img src={payType.icon}
                                                         style={{
                                                             color: '#7a7a7a',
                                                             maxWidth: '3.5em',
                                                             maxHeight: '2.5em',
                                                             filter: disabledPaymentTypesOptions(payType) ? 'opacity(.4)' : 'opacity(.9)',
                                                         }}/>
                                                </Button>
                                                <Typography style={{
                                                    fontWeight: 'bold',
                                                    filter: disabledPaymentTypesOptions(payType) ? 'opacity(.4)' : 'opacity(.9)',
                                                }} variant="caption"
                                                            color={!(disabledPaymentTypesOptions(payType)) ? (payType.code === paymentTypeSelected ? "secondary" : "textSecondary") : "textSecondary"}>
                                                    {payType.name}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    : <Grid item xs={4} key={index} className={useclasses.gridTypePayment}>
                                        <Box display="flex" flexDirection="column" alignItems="center"
                                             justifyContent="center"
                                             style={disabledPaymentTypesOptions(payType) ? {cursor: 'not-allowed'} : {}}>
                                            <Button className={`${useclasses.defaultShadowBtn} ${useclasses.button}`}
                                                    variant={!(disabledPaymentTypesOptions(payType)) ? (payType.code === paymentTypeSelected ? "outlined" : "text") : "text"}
                                                    color={!(disabledPaymentTypesOptions(payType)) ? (payType.code === paymentTypeSelected ? "secondary" : "default") : "default"}
                                                    disabled={disabledPaymentTypesOptions(payType)}
                                                    style={!(disabledPaymentTypesOptions(payType)) ? (payType.code === paymentTypeSelected ? {boxShadow: "1px 1px 1px #39CDC8, 0 0 10px #39CDC8, 0 0 1px #39CDC8"} : {}) : {}}
                                                    onClick={() => {
                                                        if (!payType.isGroup) {
                                                            setPaymentType(payType.code, true)
                                                            toggleTransferPaymentSection(false, null)
                                                        } else {
                                                            toggleTransferPaymentSection(true, payType.code)
                                                        }
                                                    }}>
                                                <img src={payType.icon}
                                                     style={{
                                                         color: '#7a7a7a',
                                                         maxWidth: '3.5em',
                                                         maxHeight: '2.5em',
                                                         filter: disabledPaymentTypesOptions(payType) ? 'opacity(.4)' : 'opacity(.9)',
                                                     }}/>
                                            </Button>
                                            <Typography variant="caption" style={{
                                                fontWeight: 'bold',
                                                filter: disabledPaymentTypesOptions(payType) ? 'opacity(.4)' : 'opacity(.9)',
                                            }}
                                                        color={!(disabledPaymentTypesOptions(payType)) ? (payType.code === paymentTypeSelected ? "secondary" : "textSecondary") : "textSecondary"}>
                                                {payType.name}
                                            </Typography>
                                        </Box>
                                    </Grid>
                        ))}
                </Grid>
            </Grid>
        </Grid>
    );
};


export default withRouter(PaymentTypeList);
