export const POS_ORIGIN = 'POS';
export const RUMI_ORIGIN = 'Billing';
export const POS_LINK = 'pos';
export const RUMI_LINK = 'billing';
// USER
export const DEFAULT = 1;
export const DEFAULT_NAME = " "+"(default)"

// COMMON MESSAGES
export const USER_RETRIEVED_SUCCESSFULY = 'User retrieved successfuly'
export const USER_LOGGED_IN_SUCCESSFULY = 'User logged in successfuly'

// RESPONSE CRUD
export const POST_METHOD = "post"
export const PUT_METHOD = "put"
export const DELETE_METHOD = "delete"
export const STATUS_RESPONSE = 200

