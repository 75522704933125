import React, {useState} from "react";
import {Link, useRouteMatch} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {handleDynamicDialog} from "../../../common/actions/actions"
import {getEventTypeProduct, activeAlertDragAndDrop, handleDropProducts} from '../../actions/store/sync';
// MATERIAL UI
import {makeStyles} from '@material-ui/core/styles';
import {Box, IconButton, Tooltip, Hidden} from '@material-ui/core';
import {main, typography} from "../../../../styles";
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import HandIcon from '@material-ui/icons/PanToolOutlined';

const useStyles = makeStyles((theme) => ({
    dragCard: {
        backgroundColor: theme.palette.common.white,
        border: '1px solid ' + theme.palette.primary.main,
    },
    buttonFloat: {
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)!important',
        color: '#ffffff',
        marginTop: 5,
        padding: 7,
        '&:hover': {
            backgroundColor: '#ffffff',
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)!important'
        },
        '& .icon':{
            fontSize: 20,
        }
    },
    btnProduct:{
        width: "100%", 
        padding: "10px 5px", 
        margin: "5px",
        cursor:'pointer',
        "&.dragColor": {
            backgroundColor: "#29A39F"
        },
        "&.hover-color": {
            "&:hover": {
                backgroundColor: "rgba(57, 205, 200, 0.04)"
            },
        },
        border: '1px solid' + theme.palette.secondary.main,
        filter: 'opacity(.9)'
    },
    
    btnEdit: {
        backgroundColor: '#f5bc00',
        border: '1px solid ' + '#f5bc00',
        '&:hover': {
            color: '#f5bc00',
            border: '1px solid ' + '#f5bc00'
        }
    },
    btnDelete: {
        backgroundColor: theme.palette.error.main,
        border: '1px solid ' + theme.palette.error.main,
        '&:hover': {
            color: theme.palette.error.main,
            border: '1px solid ' + theme.palette.error.main,
        },
        marginLeft: 5
    },
    boxNew: {
        padding: "0.1px 13px",
        borderRadius: 20,
        marginRight: 7,
        marginTop: 1.6,
        lineHeight: 2.3,
        height:32,
        "&.primary": {
            backgroundColor: "#ffffff",
            color: theme.palette.primary.main
        },
        "&.secondary": {
            backgroundColor: "#ffffff",
            color: theme.palette.secondary.main
        },
        "&.contained-primary": {
            backgroundColor: theme.palette.primary.main,
            color: "#ffffff"
        },
        "&.contained-secondary": {
            backgroundColor: theme.palette.secondary.main,
            color: "#ffffff"
        }
    },
    linearLoader: {
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#ea3788"
        }
    },
    titleProduct:{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
        fontWeight: "bold",
        filter: 'opacity(.9)',
    },
    handIcon:{
        color: "rgb(158, 158, 158)", 
        marginRight:10, 
        marginTop: 5,
        "&.draggable":{
            color: "#ffffff"
        }
    }
}));

const useStylesBootstrap = makeStyles((theme) => ({
    tooltip: {
        marginBottom: '0!important',
        margin: '0!important',
        backgroundColor: "transparent"

    }
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip classes={classes} {...props} interactive/>;
}

const Product = ({canManage, product, onDragStart, editProduct, categories, createdProduct,  productList,selectedProduct, animation, dropProductList}) => {
    const classes = { 
        main: main(),
        typography : typography()
    }
    const styles = useStyles();
    let {path} = useRouteMatch();
    const dispatch = useDispatch();
    const [eventDrag, setEventDrag] = useState(false);
    const [eventDragEnd, setEventDragEnd] = useState(false);
    const categoryList = categories.filter(cat => product.category === cat.id);
    const productIds = productList.map(prod => prod.id);
    const newProductId = Math.max(...productIds);
    const isNewProduct = newProductId === product.id;

    const dragStartEvent = (e) => {
        onDragStart(e, product);
        setEventDrag(true)
    };

    const dragEndEvent = (productId) => {
        setEventDrag(false)
    };

    return (
        <React.Fragment>
            <Hidden mdUp>
                <Box  style={canManage ? {} : {pointerEvents:'none'}} 
                className={`${classes.main.shadow2} ${classes.main.bRadius10} ${classes.typography.textCapitalize} ${styles.btnProduct} ${'hover-color'} ${classes.main.animations} ${selectedProduct == product.id && animation  + " active" }`}>
                    <Box  display="flex">
                            <Box ml={2} color={"grey.700"} fontSize={'0.9em'} lineHeight={'1.3em'} mr={1}
                                className={`${styles.titleProduct} `}>
                                {product.nameCapitalize}
                                <Box m={0} mt={1} fontSize={'0.8em'} lineHeight={'1em'} 
                                    color={"text.secondary"} >
                                    {categoryList[0].name}
                                </Box>
                            </Box>
                            
                    </Box>
                    {   canManage && 
                        <Box display="flex" >
                            <Box flexGrow={1} >
                            {
                                (createdProduct === "CREATE" && isNewProduct) &&
                                <Box p={1.5}   style={{color: '#39CDC8', fontWeight:'bold'}} 
                                >New</Box>
                                        
                            }
                            </Box>
                            <Box display="flex" p={1}>
                                <Link to={{pathname: `${path}products`}} style={{color: '#F5BC00'}}>
                                    <EditIcon className={"icon"} onClick={() => {
                                                dispatch(getEventTypeProduct("EDIT"));
                                                editProduct(product.id)
                                                dispatch(handleDropProducts([]))
                                                dispatch(activeAlertDragAndDrop(false))
                                    }}/>
                                </Link>
                                <DeleteIcon className={"icon"}  style={{color: '#EC4262', marginLeft:2}} onClick={() => dispatch(handleDynamicDialog("product", product.id))}/>
                            </Box>
                        </Box>
                    }
                </Box>
            </Hidden>
            <Hidden smDown>
            {   canManage ? 
                <BootstrapTooltip
                    title={!eventDrag ?
                        <Box display="flex">
                            <Link to={{pathname: `${path}products`}}>
                                <IconButton className={`${styles.buttonFloat} ${styles.btnEdit} `}
                                            onClick={() => {
                                                dispatch(getEventTypeProduct("EDIT"));
                                                editProduct(product.id)
                                                dispatch(handleDropProducts([]))
                                                dispatch(activeAlertDragAndDrop(false))
                                            }}
                                            onMouseEnter={()=> {
                                                if(dropProductList !== undefined){
                                                    if(dropProductList.length > 0) {
                                                        dispatch(activeAlertDragAndDrop(true))
                                                    }
                                                }
                                            }}
                                            onMouseLeave={()=> {
                                                if(dropProductList !== undefined){
                                                    if(dropProductList.length > 0) {
                                                        dispatch(activeAlertDragAndDrop(false))
                                                    }
                                                }
                                            }}>
                                    <EditIcon className={"icon"} />
                                </IconButton>
                            </Link>
                            <IconButton className={`${styles.buttonFloat} ${styles.btnDelete} `}
                                        onClick={() => dispatch(handleDynamicDialog("product", product.id))}>
                                <DeleteIcon className={"icon"} />
                            </IconButton>
                        </Box> : ""
                        }
                    placement="top">
                    <Box  display="flex"
                    className={`${classes.main.shadow2} ${classes.main.bRadius10} ${classes.typography.textCapitalize} ${styles.btnProduct} ${eventDrag ? 'dragColor': 'hover-color'} ${classes.main.animations} ${selectedProduct == product.id && animation  + " active" }`}
                    onDragStart={(e) => dragStartEvent(e)} onDragEnd={() => dragEndEvent(product.id)} draggable>
                        <Box ml={2} color={eventDrag ? "common.white" : "grey.700"} fontSize={'0.9em'} lineHeight={'1.3em'} mr={1}
                            className={`${styles.titleProduct} `}>
                            {product.nameCapitalize}
                            <Box m={0} mt={1} fontSize={'0.8em'} lineHeight={'1em'} 
                                color={eventDrag ? "common.white" : "text.secondary"} >
                                {categoryList[0].name}
                            </Box>
                        </Box>
                        {
                            (createdProduct === "CREATE" && isNewProduct) &&
                            <Box className={`${styles.boxNew} ${eventDrag ? "secondary" : "contained-secondary"} `}
                            >New</Box>
                        }
                        {
                            eventDrag && <HandIcon className={`${styles.handIcon} ${eventDrag && "draggable"}`}/>
                        }
                    </Box>
                </BootstrapTooltip> 
                :   <Box style={{cursor:'not-allowed'}} display="flex" className={`${classes.main.shadow2} ${classes.main.bRadius10} ${classes.typography.textCapitalize} ${styles.btnProduct}  `}>
                        <Box ml={2} color={"grey.700"} fontSize={'0.9em'} lineHeight={'1.3em'} mr={1}
                            className={`${styles.titleProduct} `}>
                            {product.nameCapitalize}
                            <Box m={0} mt={1} fontSize={'0.8em'} lineHeight={'1em'} 
                                color={"text.secondary"} >
                                {categoryList[0].name}
                            </Box>
                        </Box>
                    </Box>
            }
            </Hidden>
        </React.Fragment>
    )
};

export default Product