import {combineReducers} from "redux";
/*common*/
import commonReducers from "../modules/common/reducers";
/*pos*/
import posReducers from "../modules/pos/reducers"
/*rumi*/
import rumiReducers from "../modules/rumi/reducers"
/*settings*/
import settingsReducers from "../modules/rumi-settings/reducers"
import {reducer as formReducer} from 'redux-form'
// import areas from  "../modules/rumi-settings/reducers/areas"
/*
CUIDADO CON EL NOMBRE DE LOS REDUCERS SE PUEDEN CHANCHAR SI DIJERENTES OBJETOS TIENEN LS MISMA LLAVE
SOLO SE HARA REFERENCIA DEL  OBJETO QUE ESTE MAS A LA DERECHA
*/
const mainReducer = combineReducers({
    ...posReducers,
    ...commonReducers,
    ...rumiReducers,
    ...settingsReducers,
    form: formReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'RESET_ALL') {
        state = undefined
    }

    return mainReducer(state, action)
};

export default rootReducer;

