import {LOAD_SUPPORT_QUESTIONS, SET_SUPPORT_FILTER_VALUE} from "../actionTypes";

const initialState = {
    supportQuestions: [],
    supportCategories: [],
    filterValue: "",
    isLoading: true
};

const supportQuestions = (state = initialState.questions, action) => {
    switch (action.type) {
        case LOAD_SUPPORT_QUESTIONS :
            return action.payload.questions;
        default:
            return state
    }
};

const supportCategories = (state = initialState.categories, action) => {
    switch (action.type) {
        case LOAD_SUPPORT_QUESTIONS :
            return action.payload.categories;
        default:
            return state
    }
};

const isLoading = (state = initialState.isLoading, action) => {
    switch (action.type) {
        case LOAD_SUPPORT_QUESTIONS :
            return false;
        default:
            return state
    }
};

const filterValue = (state = initialState.filterValue, action) => {
    switch (action.type) {
        case SET_SUPPORT_FILTER_VALUE:
            return action.payload;
        default:
            return state
    }
};

const supportReducer = (state = initialState, action) => {
    return {
        supportQuestions: supportQuestions(state.supportQuestions, action),
        supportCategories: supportCategories(state.supportCategories, action),
        filterValue: filterValue(state.filterValue, action),
        isLoading: isLoading(state.isLoading, action),
    }
};

export default supportReducer