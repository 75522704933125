import * as fromPmsEntities from '../reducers/reservations'
import * as fromRooms from "../reducers/rooms";

import {createSelector} from "reselect";
import {setClient} from "../redux/actions";

const getPMSCustomer = state => fromPmsEntities.getPMSGuest(state.pmsEntities);
const getReservations = state => fromPmsEntities.getPMSReservation(state.pmsEntities);
const getRoomsReservation = state => fromPmsEntities.getRoomsReservation(state.pmsEntities);

const getRooms = state => fromRooms.getRooms(state.rooms);

export const customersSelector = createSelector(
    [getRoomsReservation, getPMSCustomer, getReservations, getRooms],
    (roomReservations, guest, reservations, rooms) => {
        let clientValidations = [];
       
        return roomReservations.reduce((accu, roomReservation) => {
            let registeredVariations = 0;
            const reservation = reservations[roomReservation.reservationId];
            
            const room = rooms[roomReservation.roomId.split('-')[0]];

            const customers = reservation.customers.map(customerId => {
                if (clientValidations.includes(customerId)) {
                    if ('variations' in guest[customerId]) {
                        const tempCustomer = Object.assign(
                            {},
                            guest[customerId].variations[registeredVariations],
                            room,
                            {
                                reservationId: roomReservation.reservationId,
                            groupId: reservation.groupId});
                        guest[customerId].variations[registeredVariations] = tempCustomer;
                        registeredVariations += 1;
                        return tempCustomer
                    } else{
                        guest[customerId]['variations'] = [{registeredVariations: {}}]
                    
                        const tempCustomer = Object.assign(
                            {},
                            guest[customerId].variations[registeredVariations],
                            room,
                            {
                                reservationId: roomReservation.reservationId,
                            groupId: reservation.groupId});

                        guest[customerId]['variations'] = [{registeredVariations: tempCustomer}]
                       
                        registeredVariations += 1;
                        return tempCustomer
                    }
                } else {
                   clientValidations.push(customerId);
                    const tempCustomer = Object.assign(
                        {},
                        guest[customerId],
                        room,
                        {reservationId: roomReservation.reservationId, groupId: reservation.groupId}
                    );
                    return tempCustomer
                }
            });
            return accu.concat(customers)
        }, []);
    }
);

export const groupSelector = (state) => {
    const guests = getPMSCustomer(state);
    const groupId = state.client.groupId 

    if(!groupId) {
        return null
    }else {
        const group_members = Object.values(guests).filter(guest => guest.groupId === groupId).map((guest) => {
            const formatted_member = setClient(guest, guest.customerId);
            return formatted_member.client
        });
        return group_members
    }
};