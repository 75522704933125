import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {handleDynamicDialog} from "../../actions/actions"
import {connect, useDispatch} from "react-redux"

import {Box, Button, IconButton, Typography} from '@material-ui/core/'
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import AlertIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CloseIcon from '@material-ui/icons/Close';
import NotDeleteIcon from '@material-ui/icons/DeleteForeverOutlined';


const styles = (theme, props) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    dialog: {
        '& > div> div': {
            maxWidth: 500,
            padding: '10px 20px 25px 20px'
        },
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    tittle: {
        textAlign: 'center',
        fontWeight: 200
    },
    message: {
        fontWeight: 150
    },
    icon: {
        fontSize: 85,
        '&.warning': {
            color: theme.palette.warning.main
        }
    }

});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={`${classes.root} ${classes.tittle}`} {...other}>
            <Typography variant="h4"
                        style={{
                            fontFamily: 'Poppins, sans-serif',
                        }}
            >{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        justifyContent: "center",

        '& .btn-delete': {
            backgroundColor: theme.palette.error.main,
        },
        '& .btn-warning': {
            backgroundColor: theme.palette.warning.main,
        },
        '& .btn-success': {
            backgroundColor: theme.palette.primary.main,
        },
        '&  .btn-action >span': {
            padding: '0px 8px',
            color: '#ffffff'
        }
    }
}))(MuiDialogActions);

const DialogPrevent = withStyles(styles)(({opened, params, ...props}) => {
    const styles = props.classes
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(handleDynamicDialog("", null))
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.keyValue === props.door}
                className={styles.dialog}>
            <DialogContent>
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                    {
                        props.type === 'delete' && <NotDeleteIcon color="error" className={`${styles.icon}`}/> ||
                        props.type === 'warning' && <AlertIcon className={`${styles.icon} warning`}/>
                    }
                    <DialogTitle id="customized-dialog-title">
                        {props.title}
                    </DialogTitle>
                    <Typography align="center" color="textPrimary" className={styles.message}>
                        {props.msg}
                    </Typography>
                </Box>
            </DialogContent>
            <br/>
            <DialogActions>
                <Button autoFocus onClick={handleClose} className={`btn-${props.type} btn-action`}
                        color="default">
                    OK
                </Button>

            </DialogActions>
        </Dialog>
    );
});

const mapStateToProps = (state) => {
    return {
        door: state.utilsReducer.handleDynamicDialog.door
    }
}

export default connect(mapStateToProps)(DialogPrevent)
