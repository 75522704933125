import React, {useEffect} from "react";
// COMPONENTS MATERIAL UI
import {Button} from "@material-ui/core/";

import Classes from '../../../../styles';
import {Box, Typography} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {POS_ORIGIN, RUMI_ORIGIN} from "../../../common/commonConstants";
import {setFilterClient} from "../../redux/actions";

export default function Filters(props) {
    const classes = Classes();
    const dispatch = useDispatch();

    const handleFilter = (value) => {
        dispatch(props.setReportFilter(value));
        dispatch(setFilterClient(value));
    }

    return (
        <div style={{marginTop: 12}}>
            <Button variant="outlined" color="secondary" className={`${classes.buttons.roundPillButton} filter`}
                    onClick={() => handleFilter(POS_ORIGIN)}>
                <Box lineHeight={1}>
                    <Typography variant="body1" className={classes.buttons.roundPillText}>
                        POS
                    </Typography>
                </Box>
            </Button>
            <Button  style={{marginRight:'0'}} variant="outlined" color="secondary" className={`${classes.buttons.roundPillButton} filter`}
                    onClick={() => handleFilter(RUMI_ORIGIN)}>
                <Box lineHeight={1}>
                    <Typography variant="body1" className={classes.buttons.roundPillText}>
                        Billing
                    </Typography>
                </Box>
            </Button>
        </div>
    );
}