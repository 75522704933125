import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {loginUser} from "../actions/asyncActions";
import {resetCredentials} from "../actions/actions";

import {connect, useDispatch} from 'react-redux';
// COMPONENTS
// MATERIAL UI
import {Box, Button, FormHelperText, Grid, Link, TextField} from "@material-ui/core";
import Logo from "../../../assets/images/Main/Main/LogoSoloColores.svg"
// CSS
import Classes from '../../../styles'
import Loader from "../components/utils/Loader";
import {USER_LOADING} from "../actions/actionTypes";

const AuthenticateUser = ({badCredentials, serverError, isLoading, validSession}) => {
    const classes = Classes();
    const dispatch = useDispatch();
    let history = useHistory();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [serverErrorAlert, setServerErrorAlert] = useState(false);
    const redirectCB = origin => history.push(origin);
    const handleSubmit = (event) => {
        if (serverError) {
            setServerErrorAlert(true);
        }
        event.preventDefault();
        dispatch({type: USER_LOADING});
        setTimeout(function () {
            dispatch(loginUser(username, password, redirectCB));
        }, 2000);
    };

    if (isLoading || validSession) {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{width: 9000}}>
                    <Loader/>
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column"
                 pb={'2em'} height={'100vh'} className={`loginFormHolder ${classes}`}>
                <Box bgcolor="common.white" px={5} pt={2} pb={5}>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <img src={Logo} alt="logoPOS" style={{width: '170px', height: '100px'}}/>
                    </Box>
                    <form style={{width: '200px', margin: "auto"}}
                          className='loginform' noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <Box mb={2}>
                            <TextField
                                id="standard-basic"
                                type="text"
                                fullWidth
                                label="Username"
                                className={`general-forms- ${classes.forms.textField}`}
                                name="username"
                                value={username}
                                onChange={e => {
                                    setUsername(e.target.value)
                                    dispatch(resetCredentials(false))
                                    setServerErrorAlert(false)
                                }}
                                error={badCredentials}
                            />
                        </Box>
                        <Box mb={2}>
                            <TextField style={{marginBottom: "0.5em"}}
                                       className={`general-forms- ${classes.forms.textField}`}
                                       id="standard-password-input"
                                       label="Password"
                                       fullWidth
                                       type="password"
                                       name="password"
                                       value={password}
                                       onChange={e => {
                                           setPassword(e.target.value)
                                           dispatch(resetCredentials(false))
                                           setServerErrorAlert(false)
                                       }}
                                       error={badCredentials}

                            />
                            {
                                badCredentials &&
                                <FormHelperText id="standard-weight-helper-text" style={{color: "#EA3788"}}>Incorrect
                                    credentials</FormHelperText>
                            }
                        </Box>
                        <Button className={`general-typography- ${classes.typography.textCapitalize} 
                            general-styles- ${classes.styles.bRadius18}`} fullWidth
                                type="submit" variant="contained" color="primary" style={{color: '#ffffff'}}>
                            Login
                        </Button>
                        <br/><br/>
                        {
                            serverErrorAlert &&

                            <FormHelperText id="standard-weight-helper-text" style={{color: "#EA3788"}}>
                                * Server error.&nbsp;Please, if problem persist, send mail to
                                <Link color="primary" href="mailto:example@support@rumifact.com" target="_blank"
                                      style={{fontWeight: "bold"}}>
                                    &nbsp; support@rumibilling.com
                                </Link>
                            </FormHelperText>

                        }
                    </form>
                </Box>
            </Box>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        badCredentials: state.auth.badCredentials,
        serverError: state.auth.serverError,
        isLoading: state.auth.isLoading,
        validSession: state.auth.isSessionValid
    }
};
export default connect(mapStateToProps)(AuthenticateUser);