import {createSelector, createStructuredSelector} from "reselect";
import {POS_ORIGIN} from "../../common/commonConstants";

const getCurrentCategory = state => state.storeReducer.currentCategory
const getProducts = state => state.storeReducer.productPreviewList 
const getCategories = state => state.storeReducer.categoryList
const getPreviewApp = state => state.storeReducer.previewAppSelected

export const getPreviewCategorySelected = state => state.storeReducer.previewCategorySelected

export const productsToUpperCase = (products) => {
    return products.map(product =>({ nameCapitalize: (product.name[0].toUpperCase() + product.name.toLowerCase().slice(1)) , ...product })) 
}

export const categoriesToUpperCase = (categories) => {
    return categories.map(category =>({id: category.id , name: (category.name[0].toUpperCase() + category.name.toLowerCase().slice(1)) })) 
};

export const orderCategories = (categories) => {
    return categories.sort((asc, desc) => asc.name > desc.name ? 1 : -1)                         
};

export const categoriesSettingsToUpperCase = (categories) => {
    return categories.map(category =>({id: category.id , name: (category.name[0].toUpperCase() + category.name.toLowerCase().slice(1)) , products: category.products})) 
};

export const categoriesSelector = createSelector(
    [getCategories],
    categories => categoriesSettingsToUpperCase(Object.values(categories))
);

export const productsSelector = createSelector(
    [getProducts],
    products => Object.values(products)
);


const filterPosProduct = products => {
    let auxProducts = products.filter(product => product["displayPos"] || false )
    return auxProducts
}

const filterRumiProduct = products => {
    let auxProducts = products.filter(product => product["displayBilling"] || false)
    return auxProducts
}

const makeProductSelector = (filterProductCriteria) => createSelector(
    [productsSelector],
    filterProductCriteria
);


const POSProductSelector = makeProductSelector(filterPosProduct)
const RUMIProductSelector = makeProductSelector(filterRumiProduct)


const getPreviewCategories = (products, categories) => {
    const productCategories = new Set(products.map(p => p.category))
    return [...productCategories].map(cat => categories[cat])
}


export const POSCategoriesSelector = createSelector(
    [POSProductSelector, getCategories],
    getPreviewCategories
);

export const RUMICategoriesSelector = createSelector(
    [RUMIProductSelector, getCategories],
    getPreviewCategories
);

const POSPreview = createStructuredSelector({
        productList: POSProductSelector,
        categoryList: POSCategoriesSelector
    }
);

const RUMIPreview = createStructuredSelector({
        productList: RUMIProductSelector,
        categoryList: RUMICategoriesSelector
    }
);

export const storePreviewSelector = state => {
    const previewApp = getPreviewApp(state)
    return previewApp === POS_ORIGIN ? POSPreview(state) : RUMIPreview(state)
};

const handleRemoveDragProduct = (productList, dragProduct, newCatId, indexProduct) => {
    if (Object.keys(dragProduct).length !== 0) {
        const newProductList = Object.values(productList)

        for (let i = 0; i < newProductList.length; i++) {
            if (newProductList[i].id === dragProduct.id) {
                newProductList[i].categoryId = newCatId
            }
        }
        return newProductList


    } else {
        return Object.values(productList)
    }
};

export const getOutletsSelector = (outlets)=> {
    return Object.values(outlets).map(outlet => ({label: " ", name: outlet.name, value: outlet.id, code: outlet.code }))

}

