import React, {useEffect} from "react";
import {connect, useDispatch} from "react-redux";
import {Grid} from "@material-ui/core";
import CategoryPreview from "../../components/store/CategoryPreview";
import ProductListPreview from "../../components/store/ProductListPreview";
import SearchMultiple from '../../../common/components/utils/SearchMultiple'
import {getProducts} from '../../actions/store/async';

const StorePreview = ({dropProductList}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (dropProductList === undefined || dropProductList.length == 0) {
            dispatch(getProducts())
        }
    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={8} md={6} lg={6}>
                <SearchMultiple/>
            </Grid>
            <Grid item xs={12}>
                <Grid container item>
                    <Grid item xs={12} sm={8} md={3} lg={3}>
                        <CategoryPreview/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} lg={9}>
                        <ProductListPreview/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        dropProductList: state.storeReducer.dropProductList
    }
};

export default connect(mapStateToProps)(StorePreview) 
