import * as api_settings from "../../api.calls";
import {ADD_TAX, EDIT_TAX} from "../../actions/actionsTypes";
import {verifyToken} from "../../../common/apiCalls";

export const validate = values => {
    const errors = {}
    if (!values.name) {
        errors.name = 'Required'
    }
    if (!values.type) {
        errors.type = 'Required'
    }
    return errors

}



export const submitTaxesForm = async (values, dispatch, {reset, ...rest}) =>  {
    
    try {
        await verifyToken();
        const taxRequest = !values.id ? api_settings.postTaxes : api_settings.putTaxes

        if(values.value === 0){
            values.apply =[]
        }

        const response = await taxRequest({
            taxesData: values
        })
        
        if (response.status === 201) {
            dispatch({
                type: ADD_TAX,
                payload: response.data,
            })
            reset()
        }
        if (response.status === 200) {
            dispatch({
                type: EDIT_TAX,
                payload: response.data
            })
        }
        rest.history.goBack()
    } catch (e) {
    }
}
