import React from 'react';
import errorPage401 from "../../../../assets/errorPage401.svg";
import ErrorPageDisplay from "./errorPageDisplay";

const page401 = () => {
    return (
        <ErrorPageDisplay errorName="You are not authorized" errorDisplay={errorPage401}/>
    )
};

export default page401