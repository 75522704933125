import React, {useEffect} from 'react';
import {connect, useDispatch} from "react-redux";
import {useHistory} from 'react-router-dom';
import {handleAlerts, openLoaderProgress} from '../../common/actions/actions'
// STYLES
import "../../../styles/font.css"
// COMPONENTS
import Location from "../components/welcome/Location"
import BillingLogo from "../../../assets/images/Colores/BillingLogo.svg"
import POSLogo from "../../../assets/images/Colores/PosLogo.svg"
// MATERIAL UI
import {Box, Grid, Typography} from '@material-ui/core';
import {sendLocation} from "../actions/asyncActions";
import {POS_LINK} from "../commonConstants";
import LoaderProgress from "../components/modals/LoaderProgress"

const Locations = ({locations, isLoading}) => {
    let history = useHistory();
    const dispatch = useDispatch();
    const origin = localStorage.getItem('origin');
    const [state, setState] = React.useState({
        transition: false,
        opacity: '',
        redirect: false
    });

    const handleRedirectToApp = (id) => {
        localStorage.setItem('skipWelcome', JSON.stringify(true));
        setState({...state, transition: true});
        let sec = 0;
        for (let i = 1; i < 9; i++) {
            const text = 'opacity(.' + i + ')';
            dispelText(text, sec)
            sec = sec + 200;
        }
        sec = sec + 400
        for (let i = 9; i > 1; i--) {
            const text = 'opacity(.' + i + ')';
            sec = sec + 200;
            dispelText(text, sec)
        }
        
        setTimeout(() =>{
            history.push(`/${origin}/clients`)
            dispatch(openLoaderProgress(false));
        }, 2000);
        dispatch(sendLocation(id))
    };

    const dispelText = (opa, sec) => {
        setTimeout(function () { setState({...state, opacity: opa});}, sec);
    }

    useEffect(() => {
        localStorage.setItem('renderWelcome', JSON.stringify(true));
        if (locations.length <= 1) {
            handleRedirectToApp()
        }
    }, [locations]);

    return (
        <Grid container
            style={{
                filter: (locations.length < 2) || state.transition ? state.opacity : 'opacity(.9)',
            }}>
            <Grid item xs={12}>
                <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center"
                    style={{height: '100vh'}}>
                    <Box display="flex" alignItems="center" justifyContent="center" style={{width: '100%'}}>
                        <Typography variant="h3" style={{
                            fontFamily: 'Poppins, sans-serif',
                            fontWeight: 100,
                            textAlign: "center"
                        }}>
                            Welcome to {origin === POS_LINK ? 'RUMI POS' : 'RUMI Billing'}
                        </Typography>
                    </Box>
                    {
                        locations.length > 1 &&
                        <Box display="flex" alignItems="center" justifyContent="center" flexWrap="wrap"
                            mt={5} style={{width: '80vw'}}>
                            {
                                locations.map((location, index) =>
                                    <Location key={index} location={location}
                                            handleRedirectToApp={handleRedirectToApp}
                                            activeLoader={()=>  dispatch(openLoaderProgress(true)) }/>
                                )
                            }
                        </Box>
                    }
                </Box>
            </Grid>
            <LoaderProgress/>
        </Grid>
    )
}


const mapStateToProps = (state) => {
    return {
        locations: state.auth.userData.locations,
        isLoading: state.auth.isLoading,
    }
};
export default connect(mapStateToProps)(Locations)
