export const ACCOUNT = "CHRG";
export const CASH = "CASH";
export const CARD = "CARD";
export const WALLET = "WALLET";
export const BANK = "BANK";
/* WALLET LIST */
export const YAPE = "YAPE"
export const PLIN = "PLIN"
/* BANK LIST */
export const BCP = "BCP"
export const BBVA = "BBVA"
/* CARD LIST */
export const VISA = "VISA";
export const MASTERCARD = "MASTERCARD"

// PAYMENTS CUSTOMER
export const STAFF = "STAFF";
export const GUEST = "GUEST";
export const EXTERNAL = "EXTERNAL" // ANONYMOUS / CUSTOMER WITH DNI AND NAME