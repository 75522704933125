import axios from "axios";

import {
    getRequestInfo, baseGetAPIRequest, basePostAPIRequest, basePutAPIRequest, baseDeleteAPIRequest
} from '../common/utils';

import {
    GROUPS_URLS,
    LOCATIONS_LIST_URL,
    USERS_URL,
    CUSTOMER_URL,
    IDTYPE_URL,
    COUNTRY_URL,
    AREA_LIST_URL,
    COMPANY_URL,
    POST_AREA_URL,
    AREA_URL,
    POST_PRODUCTS_URL,
    POST_SERIAL_URL,
    SERIALS_URL,
    TAXES_URL,
    POST_TAXES_URL,
    TAX_URL,
    LOCATION_WITHOUT_SERIALS_URL,
    ALL_CATEGORIES_URL,
    USERS_DELETE_URL,
    GROUPS_DELETE_URLS,
    PUT_PRODUCTS_URL,
    PUT_ALL_PRODUCTS_URL,
    BILLING_DOCUMENTS_URL,
    USER_LOOKUP_URL,
    DELETE_PRODUCTS_URL,
    CUSTOMER_LIST_URL,
    EXCHANGE_RATE_URL,
    CONSULTING_EXCHANGE_RATE_URL, BLOCK_AMOUNT_UPDATE_URL

} from "./constants.api.urls";


export function requestBillingConfig() {
    const token = localStorage.getItem('accessToken');
    const {apiUrl, headers} = getRequestInfo(GROUPS_URLS);
    headers['Authorization'] = `Bearer ${token}`;
    return axios.get(apiUrl, {headers: headers})
}
/* Staff and Customer */

export function requestGroups() {
    return baseGetAPIRequest(GROUPS_URLS)
}

export function requestUsers() {
    return baseGetAPIRequest(USERS_URL)
}

export function  requestsCountries() {
    return baseGetAPIRequest(COUNTRY_URL);
}

export function requestIdentityType() {
    return baseGetAPIRequest(IDTYPE_URL);
}

export function requestCustomers() {
    return baseGetAPIRequest(CUSTOMER_URL);
}

export function requestCustomersList() {
    return baseGetAPIRequest(CUSTOMER_LIST_URL);
}

export function postCustomer({customerData}) {
    return basePostAPIRequest(CUSTOMER_URL, customerData);
}

export function putCustomer({customerData}){
    const URL = `${CUSTOMER_URL}${customerData.id}/`;
    return basePutAPIRequest(URL, customerData)
}

export function getAreas(){
    return baseGetAPIRequest(AREA_LIST_URL)
}

export function getLocationWithOutSerials(){
    return baseGetAPIRequest(LOCATION_WITHOUT_SERIALS_URL)
}

export function postAreas(areaData){
    return basePostAPIRequest(POST_AREA_URL, areaData.areaData)
}

export function putAreas(areaData){
    const URL = `${AREA_URL}${areaData.areaData.id}`;
    return basePutAPIRequest(URL, areaData.areaData)
}

export function removeArea(areaId) {
    const URL = `${AREA_URL}${areaId}`;
    return baseDeleteAPIRequest(URL)
}

export function postProducts(productData){
    return basePostAPIRequest(POST_PRODUCTS_URL, productData.productData)
}

export function putProducts(productData){
    const URL = `${PUT_PRODUCTS_URL}${productData.productData.id}`;
    return basePutAPIRequest(URL, productData.productData)
}

export function putAllProducts(allProducts){
    const URL = `${PUT_ALL_PRODUCTS_URL}`;
    return basePutAPIRequest(URL, allProducts)
}


export function requestCompany() {
    return baseGetAPIRequest(COMPANY_URL)
}

export function postCategories(categoryData){
    return basePostAPIRequest(ALL_CATEGORIES_URL, categoryData.categoryData)
}

export function putCategories(categoryData){
    const URL = `${ALL_CATEGORIES_URL}${categoryData.categoryData.id}/`;
    return basePutAPIRequest(URL, categoryData.categoryData)
}

export function removeCategory(categoryId) {
    const URL = `${ALL_CATEGORIES_URL}${categoryId}`;
    return baseDeleteAPIRequest(URL)
}

export function removeProduct(productId) {
    const URL = `${DELETE_PRODUCTS_URL}${productId}`;
    return baseDeleteAPIRequest(URL)
}

export function postSerial(serialData){
    return basePostAPIRequest(POST_SERIAL_URL, serialData.serialData)
}

export function postTaxes(taxesData){
    return basePostAPIRequest(POST_TAXES_URL, taxesData.taxesData)
}

export function putTaxes(taxesData){
    const URL = `${TAX_URL}${taxesData.taxesData.id}`;
    return basePutAPIRequest(URL, taxesData.taxesData)
}

export function removeTaxes(taxId) {
    const URL = `${TAX_URL}${taxId}`;
    return baseDeleteAPIRequest(URL)
}

export function postUser(userData){
    return basePostAPIRequest(USERS_URL, userData.userData)
}

export function putUser(userData){
    const URL = `${USERS_URL}/${userData.userData.id}`;
    return basePutAPIRequest(URL, userData.userData)
}

export function postGroup(groupData){
    return basePostAPIRequest(GROUPS_URLS, groupData.groupData)
}

export function putGroup(groupData){
    const URL = `${GROUPS_URLS}/${groupData.groupData.id}`;
    return basePutAPIRequest(URL, groupData.groupData)
}

export function getLocations(){
    return baseGetAPIRequest(LOCATIONS_LIST_URL)
}

export function getSerials(){
    return baseGetAPIRequest(SERIALS_URL)
}

export function getTaxes(){
    return baseGetAPIRequest(TAXES_URL)
}

export function getExchangeRate(){
    return baseGetAPIRequest(EXCHANGE_RATE_URL)
}

export function postExchangeRate(exchangeData){
    return basePostAPIRequest(EXCHANGE_RATE_URL, exchangeData.exchangeData)
}

export function postBlockAmount(blockAmountData){
    return basePostAPIRequest(BLOCK_AMOUNT_UPDATE_URL, blockAmountData.blockAmountData)
}

export function removeUser(UserId) {
    const URL = `${USERS_DELETE_URL}${UserId}`;
    return baseDeleteAPIRequest(URL)
}

export function removeCustomer(CustomerId) {
    const URL = `${CUSTOMER_URL}${CustomerId}`;
    return baseDeleteAPIRequest(URL)
}

export function removeGroup(GroupId) {
    const URL = `${GROUPS_DELETE_URLS}${GroupId}`;
    return baseDeleteAPIRequest(URL)
}

export function getBillingDocumentTypeAPI() {
    return baseGetAPIRequest(BILLING_DOCUMENTS_URL)
}


export function userLookupAPI( email) {
    return baseGetAPIRequest(USER_LOOKUP_URL(email))
}

export function consultingExchangeRate() {
    return baseGetAPIRequest(CONSULTING_EXCHANGE_RATE_URL)
}



