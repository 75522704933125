import {
    LOAD_CUSTOMERS,
    ADD_CUSTOMER,
    EDIT_CUSTOMER,
    REMOVE_CUSTOMER,
    FILL_CUSTOMER,
    SET_CUSTOMER_TYPE,
    LOAD_COMMON_OPTION,
    EMPTY_CUSTOMER,
    LIST_CUSTOMERS, SEARCH_SETTINGS_STAFF, SEARCH_SETTINGS_VOLUNTEER
} from "../actions/actionsTypes";

const initialState = {
    customers: {},
    selectedCustomer: {active:true},
    customerType: "STAFF",
    volunteerSearch:"",
    staffSearch:""
};

function customerReducer(state = initialState.customers, action) {
    switch (action.type) {
        case LOAD_COMMON_OPTION:
        case LIST_CUSTOMERS:
        case LOAD_CUSTOMERS: {
            return action.customers.reduce((accumulator, customer) => {
                accumulator[customer.id] = customer;
                return accumulator
            }, {});
        }
        case ADD_CUSTOMER:
        case EDIT_CUSTOMER:
            return {...state, [action.payload.id]: action.payload};
        case REMOVE_CUSTOMER: {
            const {[action.customerId]: customer, ...customers} = state
            return customers;
        }
        default:
            return state
    }
}

function selectedCustomer(state = initialState.selectedCustomer, action) {
    switch (action.type) {
        case FILL_CUSTOMER:
            return action.customer
        case EMPTY_CUSTOMER:
            return  initialState.selectedCustomer
        default:
            return state
    }
}

function customerType(state=initialState.customerType, action){
    switch (action.type) {
        case SET_CUSTOMER_TYPE:
            return action.customerType
        default:
            return  state
    }
}

function searchVolunteer(state=initialState.volunteerSearch, action){
    switch (action.type) {
        case SEARCH_SETTINGS_VOLUNTEER:
            return action.payload;
        default:
            return state
    }
}

function searchStaff(state=initialState.staffSearch, action){
    switch (action.type) {
        case SEARCH_SETTINGS_STAFF:
            return action.payload;
        default:
            return state
    }
}

const customerSettings = (state = initialState, action) => {
    return {
        customers: customerReducer(state.customers, action),
        selectedCustomer: selectedCustomer(state.selectedCustomer, action),
        customerType: customerType(state.customerType, action),
        searchVolunteer: searchVolunteer(state.volunteerSearch, action),
        searchStaff: searchStaff(state.staffSearch, action)
    }
};

export default customerSettings