import React, {useEffect, useState} from 'react';
import {SliderPicker} from 'react-color';

import {connect, useDispatch} from 'react-redux'
import {sendUserProfile} from '../../actions/asyncActions'
import {openModalView} from "../../actions/utilsActions"

import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Box, Button, TextField, Typography} from "@material-ui/core";
import Classes from "../../../../styles"
import LoaderProgress from "../modals/LoaderProgress"
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/EditOutlined';

const useStyles = makeStyles({
    table: {
        minWidth: 300,
    },
    iconClose: {
        color: "#616161",
        margin: "16px 0 0 7px",
        cursor: "pointer",
        fontSize: 15
    }
});

const CurrentUserProfile = ({currentUserInformation}) => {
    const useclasses = useStyles();
    const classes = Classes();
    const dispatch = useDispatch();
    const [newPassword, setNewPassword] = useState("");
    const [newColorProfile, setNewColorProfile] = useState("");
    const [showLabel, setShowLabel] = useState(false);
    const [EditColor, setEditColor] = useState(false);
    const [EditPassword, setEditPassword] = useState(false);
    const validateSaveBtn = (newColorProfile && !showLabel && !EditColor) || newPassword ? false : true

    const handleGetPass = (e) => {
        setNewPassword(e.target.value)
    }

    const handleChangeColor = (color) => {
        setNewColorProfile(color.hex)

        if (color.hex == currentUserInformation.color || !color.hex) {
            setShowLabel(true)
            setEditColor(true)
        } else {
            setShowLabel(false)
            setEditColor(false)
        }

    }

    const saveProfile = () => {
        dispatch(sendUserProfile(newPassword, newColorProfile))
    }

    const resetProfile = () => {
        setNewPassword("")
        setNewColorProfile("")
        dispatch(openModalView(""))
    }

    useEffect(() => {
        if (currentUserInformation.color !== undefined || currentUserInformation.color !== "#616161") {
            setNewColorProfile(currentUserInformation.color)
            setEditColor(true)
        }
    }, []);

    return (
        <React.Fragment>
            <TableContainer component={Box} className={`${classes.styles.shadow1} ${classes.tables.greyTable}`}>
                <Table className={useclasses.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell align="right">Information</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Username
                            </TableCell>
                            <TableCell align="right">{currentUserInformation.username}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Password
                            </TableCell>
                            <TableCell align="right">
                                {
                                    EditPassword ?
                                        <div>
                                            <TextField id="standard-basic" label=""
                                                       onChange={handleGetPass}
                                                       style={{width: '100px'}}/>
                                            <CloseIcon className={useclasses.iconClose}
                                                       onClick={() => {
                                                           setEditPassword(false)
                                                           setNewPassword("")
                                                       }}/>
                                        </div> :

                                        <EditIcon style={{color: "#F5BC00", cursor: "pointer"}}
                                                  onClick={() => setEditPassword(true)}/>

                                }
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Email
                            </TableCell>
                            <TableCell align="right">{currentUserInformation.email}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Origin
                            </TableCell>
                            <TableCell align="right">{currentUserInformation.origin}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Color
                            </TableCell>
                            <TableCell align="right">
                                <SliderPicker
                                    color={newColorProfile}
                                    onChangeComplete={handleChangeColor}
                                />
                                {
                                    showLabel &&
                                    <Typography variant="caption" component="div" style={{marginTop: 10}}>Choose the one
                                        you like</Typography>
                                }
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Box mt={3} display="flex" justifyContent="center">
                <Button variant="outlined" color="primary" style={{marginRight: 5}}
                        onClick={resetProfile}>Cancel</Button>
                <Button variant="contained" color="primary" style={{width: 85}}
                        onClick={saveProfile} disabled={validateSaveBtn}>save</Button>
            </Box>
            <LoaderProgress/>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    currentUserInformation: state.auth.userData
});

export default connect(mapStateToProps)(CurrentUserProfile)