import {Button, FormControl, Grid} from "@material-ui/core";
import React, {useState} from "react";
import Classes from "../../../../styles";
import axios from "axios";
import {UPLOAD_PRODUCT_PRICES_FILE} from "../../apiUrlsConstants";

export default function UpdateProductPrices({retrieveProductPricesFile}) {
    const classes = Classes();
    const [file, setFile] = useState(null);
    const [status, setStatus] = useState("");
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        const token = localStorage.getItem('accessToken');
        formData.append("file", file);
        // const {url,headers} = getRequestInfo(UPLOAD_PRODUCT_PRICES_FILE)
        const subdomain = localStorage.getItem('subdomain');
        const host = process.env.REACT_APP_API_SERVER;
        const protocol = process.env.REACT_APP_PROTOCOL;
        const apiUrl = `${protocol}://${subdomain}.${host}/${UPLOAD_PRODUCT_PRICES_FILE}/${file.name}/`;
        const resp = await axios.post(apiUrl, formData, {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            },
        }).then(
            setStatus("Thank you, products uploaded correctly.")
        ).catch(error => {
            setStatus("There was an error, review the file and try again or contact support.")
        });
    }

    const setTheCsvFileHandler = (event) => {
        console.log(event.target.files[0])
        setFile(event.target.files[0])
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {status ? <h4>{status}</h4> : null}
                <div>
                    <Button onClick={() => retrieveProductPricesFile()}
                            className={`general-typography- ${classes.typography.textCapitalize} 
                            general-styles- ${classes.styles.bRadius18}`} fullWidth
                            type="button" variant="contained" color="secondary" style={{color: '#ffffff'}}>
                        DOWNLOAD CURRENT PRICES
                    </Button>
                </div>
                <br/>
                <div>
                    <form action="" encType="multipart/form-data" onSubmit={handleSubmit}>
                        <FormControl
                            className={`general-forms- ${classes.forms.formMargin} general-forms- ${classes.forms.fullFormWidth}`}>
                            <input
                                className={classes.forms.inputUploadFile}
                                id="productPricesFile"
                                accept="text/csv"
                                type="file"
                                onChange={(e) => {
                                    setTheCsvFileHandler(e)
                                }}
                            />
                        </FormControl>
                        <Button className={`general-typography- ${classes.typography.textCapitalize} 
                            general-styles- ${classes.styles.bRadius18}`}
                                type="submit" variant="contained" color="primary" style={{color: '#ffffff'}}>
                            Send file
                        </Button>
                    </form>
                </div>
            </Grid>
        </Grid>
    )
}
