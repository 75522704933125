import {
    CLEAR_VARIABLE_PRODUCTS, EMPTY_CART, REMOVE_TO_CART,
    REMOVE_VARIABLE_PRODUCT,
    SET_VARIABLE_PRODUCT,
    UPDATE_VARIABLE_PRODUCT
} from "../redux/actionTypes";

export default function (state = {}, action) {
    switch (action.type) {
        case SET_VARIABLE_PRODUCT:
        case UPDATE_VARIABLE_PRODUCT:
            return {
                ...state,
                [action.payload.product]: {product:action.payload.product,newPrice:action.payload.newPrice}
            };
        case REMOVE_VARIABLE_PRODUCT:
            const {[action.payload.product]: variableProduct, ...variableProducts} = state;
            return variableProducts;
        case REMOVE_TO_CART:
            const {[action.productId]: removedProduct, ...otherProducts} = state;
            return otherProducts;
        case EMPTY_CART:
        case CLEAR_VARIABLE_PRODUCTS:
            return {}
        default:
            return state;
    }
}

