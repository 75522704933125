import React, {useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom';
import {cleanCustomerFilter} from "./redux/actions";
import {getCategories, getProducts} from "./redux/asyncActions"
import {getBalance, loadCurrencies} from "../common/actions/asyncActions";

import {
    getCommonSelection,
    getCustomersList,
    loadBillings,
    loadExchangeRate
} from "../rumi-settings/actions/asyncAction";
//CSS
import Classes from '../../styles';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// MATERIAL UI
import {Badge, Box, Drawer, Fab, Grid, Hidden} from '@material-ui/core';
// COMPONENTS
import OpeningBalanceModal from '../common/containers/OpeningBalance'
import Menu from "../common/components/menu/Menu"

import SalesHistory from './containers/balance/SalesHistory';
import Clients from './containers/clients/Clients';
import Products from './containers/products/Products';
import PaymentPanel from './containers/payment-panel/PaymentPanel';
import CashReportView from "./containers/reports/CashReportView"
import SupportView from "../rumi/containers/SupportView";
import ClientsIcon from '@material-ui/icons/PersonOutlined';
import StoreIcon from '@material-ui/icons/LocalGroceryStoreOutlined';
import ReportsIcon from '@material-ui/icons/PollOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import SupportIcon from '@material-ui/icons/HelpOutlineOutlined';
import ReceipIcon from '@material-ui/icons/ReceiptOutlined';
import EmployeeIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import AccountsIcon from '@material-ui/icons/PeopleAltOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import FavoriteIcon from '@material-ui/icons/GradeOutlined';
import SaleReportIcon from '@material-ui/icons/LoyaltyOutlined';
import CashIcon from '@material-ui/icons/LocalAtmOutlined';
import TimelineIcon from '@material-ui/icons/TrendingUp';
import StatisticsIcon from '@material-ui/icons/PieChartOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import page404 from "../common/components/error.pages/404";
import SalesReport from "./components/reports/SalesReport";
import InvoicingReport from "./components/reports/InvoicingReport";
import SalesRankingReport from "./components/reports/SalesRankingReport";
import StoreView from "../rumi-settings/containers/StoreView";
import SettingsProduct from "../rumi-settings/containers/store/Products";
import General from "../rumi-settings/containers/General";
import Areas from "../rumi-settings/components/general/AreasForm";
import Billing from "../rumi-settings/containers/Billing";
import SeriesAndCorrelatives from "../rumi-settings/components/billing/SeriesAndCorrelativesForm";
import Taxes from "../rumi-settings/components/billing/TaxesForm";
import ExchangeRate from "../rumi-settings/components/billing/ExchangeRateForm";
import Charges from "../rumi-settings/components/billing/ChargesForm";
import Employees from "../rumi-settings/containers/Employees";
import Staff from "../rumi-settings/containers/employees/Staff";
import Volunteer from "../rumi-settings/containers/employees/Volunteers";
import Accounts from "../rumi-settings/containers/Accounts";
import GroupView from "../rumi-settings/components/accounts/GroupForm";
import UserView from "../rumi-settings/components/accounts/UserForm";
import page401 from "../common/components/error.pages/401";
import DashboardView from "../rumi/containers/Dashboard";
import {
    accounts,
    billing,
    cash_balance_report,
    employees,
    general,
    groups,
    locations,
    pos_sales_history,
    pos_store,
    ranking_report,
    receipts_reports,
    reports,
    sales_report,
    serials,
    settings,
    staff,
    statistics_report,
    store,
    store_actions,
    support,
    taxes,
    exchange_rate,
    users,
    volunteer
} from "../rumi-settings/permissionsConstants";
import Logo from "../../assets/images/Colores/pos_small.png";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PrivateRouteWithValidation from "../common/components/utils/PrivateRouterWithValidation";
import BalanceFlowsReport from "./components/reports/BalanceFlowsReport";
import BlockAmount from "../rumi-settings/components/general/BlockAmountForm";
import PmsAuthorizationReceiver from "../rumi/components/clients/PmsAuthorizationReceiver";


const useStyles = makeStyles((theme) => ({
    fabIcon: {
        color: theme.palette.common.white,
        '&.menu': {
            backgroundColor: theme.palette.secondary.main,
        },
        '&.store': {
            backgroundColor: theme.palette.warning.main,
        }
    }
}));


const RumiPOS = ({location, showPaymentPanel, balanceStatus, permissionsList, qtyProducts}) => {
    const classes = Classes();
    const useclasses = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const dispatch = useDispatch();
    const currentPath = location.pathname;
    const totalProducts = !Object.values(qtyProducts).length ? [] : Object.values(qtyProducts).reduce((total, num) => total + num);

    const permissions = Object.values(permissionsList);
    const canSaleStore = permissions.includes(pos_store) || permissions.includes("superuser");

    const [menuMobileOpen, setMenuMobileOpen] = React.useState(false);
    const [paymentPanelMobileOpen, setPaymentPanelMobileOpen] = React.useState(false);
    const handlePaymentPanelDrawerToggle = () => {
        setPaymentPanelMobileOpen(!paymentPanelMobileOpen);
    };
    const handleMenuDrawerToggle = () => {
        setMenuMobileOpen(!menuMobileOpen);
    };
    let {path, url} = useRouteMatch();

    useEffect(() => {
            dispatch(getBalance());
            dispatch(getProducts());
            dispatch(getCategories());
            dispatch(getCustomersList());
            dispatch(loadExchangeRate());
            dispatch(loadCurrencies());
        }, [dispatch, location]
    );

    const menuItems = [
        {
            name: 'Clients',
            url: `${url}/clients`,
            icon: <ClientsIcon onClick={() => {
                dispatch(cleanCustomerFilter());
            }}/>,
            showPaymentPanel: true,
            enableButton: true
        },
        {
            name: 'Store',
            url: `${url}/store`,
            icon: <StoreIcon onClick={() => {
                dispatch(cleanCustomerFilter());
            }}/>,
            showPaymentPanel: true,
            value: false,
            enableButton: permissions.includes(pos_store) || permissions.includes("superuser")
        },
        {
            name: 'Sales History',
            url: `${url}/sales-history`,
            icon: <TimelineIcon/>,
            showPaymentPanel: false,
            value: false,
            enableButton: permissions.includes(pos_sales_history) || permissions.includes("superuser")
        },
        {
            name: 'Reports',
            icon: <ReportsIcon/>,
            value: true,
            enableButton: permissions.includes(reports) || permissions.includes("superuser"),
            submenus: [
                {
                    url: `${path}/balance-history`,
                    name: 'Balance Flows',
                    icon: <SaleReportIcon/>,
                    showPaymentPanel: false,
                    enableButton: permissions.includes(sales_report) || permissions.includes("superuser")
                },
                {
                    url: `${url}/reports-sales`,
                    name: 'Sales',
                    icon: <SaleReportIcon/>,
                    enableButton: permissions.includes(sales_report) || permissions.includes("superuser")
                },
                {
                    url: `${url}/reports-ranking`,
                    name: 'Ranking',
                    icon: <FavoriteIcon/>,
                    enableButton: permissions.includes(ranking_report) || permissions.includes("superuser")
                },
                {
                    url: `${url}/reports-receipts`,
                    name: 'Receipts',
                    icon: <ReceipIcon/>,
                    enableButton: permissions.includes(receipts_reports) || permissions.includes("superuser")
                },
                {
                    url: `${url}/statistics`,
                    name: 'Statistics',
                    icon: <StatisticsIcon/>,
                    enableButton: permissions.includes(statistics_report) || permissions.includes("superuser")
                },
                {
                    url: 'modal',
                    name: 'Cash Balance',
                    icon: <CashIcon/>,
                    body: <CashReportView/>,
                    enableButton: permissions.includes(cash_balance_report) || permissions.includes("superuser")
                }
            ],

        },
        {
            name: 'Settings',
            icon: <SettingsIcon/>,
            value: true,
            enableButton: permissions.includes(settings) || permissions.includes("superuser"),
            submenus: [
                {
                    url: `${url}/settings/general/`,
                    name: 'General',
                    icon: <HomeIcon/>,
                    enableButton: permissions.includes(general) || permissions.includes("superuser")
                },
                {
                    url: `${url}/settings/billing/`,
                    name: 'Billing',
                    icon: <ReceipIcon/>,
                    handleAction: () => dispatch(loadBillings()),
                    enableButton: permissions.includes(billing) || permissions.includes("superuser")
                },
                {
                    url: `${url}/settings/store/`,
                    name: 'Store',
                    icon: <StoreIcon/>,
                    showPaymentPanel: false,
                    enableButton: permissions.includes(store) || permissions.includes("superuser")
                },
                {
                    url: `${url}/settings/employees/`,
                    name: 'Employees',
                    icon: <EmployeeIcon/>,
                    handleAction: () => dispatch(getCommonSelection()),
                    enableButton: permissions.includes(employees) || permissions.includes("superuser")
                },
                {
                    url: `${url}/settings/accounts/`,
                    name: 'Accounts',
                    icon: <AccountsIcon/>,
                    enableButton: permissions.includes(accounts) || permissions.includes("superuser")
                }
            ],
        },
        // { NO MVP
        //     name: 'Support',
        //     url: `${url}/support`,
        //     icon: <SupportIcon/>,
        //     showPaymentPanel: false,
        //     value: false,
        //     enableButton: permissions.includes(support) || permissions.includes("superuser")
        // }
    ];

    const paymentPanelDrawer = (
        <div>
            <Hidden smUp>
                <ArrowBackIcon style={{position: 'absolute', right: 10, top: 10}}
                               onClick={() => setPaymentPanelMobileOpen(false)}/>
            </Hidden>
            <PaymentPanel/>
        </div>
    );

    const menuDrawer = (
        <Menu
            menuItems={menuItems}
            logo={Logo}
            positionDivider={2}
        />
    );

    return (

        <Grid container spacing={3} className={classes.containers.root}>
            <Grid item xs className={classes.containers.middlePanel}>
                <Hidden mdUp implementation="css">
                    <Drawer variant="temporary" anchor='left' open={menuMobileOpen} onClose={handleMenuDrawerToggle}
                            classes={{
                                paper: classes.sideDrawers.menuDrawerHolder,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}>
                        {menuDrawer}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer variant="permanent" open anchor='left'
                            classes={{
                                paper: classes.sideDrawers.menuDrawerHolder,
                            }}>
                        {menuDrawer}
                    </Drawer>
                </Hidden>
                <Box id='box1' flexGrow={1} style={showPaymentPanel ? {
                    marginRight: matches ? "350px" : 0,
                    marginLeft: matches ? "130px" : 0
                } : {marginLeft: matches ? "130px" : 0}}>
                    <Hidden mdUp>
                        <Box display="flex" flexDirection="row" justifyContent="right" alignItems="center"
                             style={{marginBottom: "-61px"}}>
                            <Fab className={`${useclasses.fabIcon} menu`} size="medium" aria-label="open drawer"
                                 onClick={handleMenuDrawerToggle}
                            >
                                <MenuIcon/>
                            </Fab>
                            {
                                showPaymentPanel &&
                                (canSaleStore ?
                                        <Fab className={`${useclasses.fabIcon} store`} style={{
                                            right: 0,
                                            position: 'absolute',
                                            marginRight: 10
                                        }}

                                             size="medium"
                                             aria-label="open drawer"
                                             onClick={handlePaymentPanelDrawerToggle}
                                        >
                                            <Badge color="error" badgeContent={totalProducts}
                                                   invisible={totalProducts > 0 ? false : true}
                                            >
                                                <StoreIcon/>
                                            </Badge>
                                        </Fab>
                                        : null
                                )
                            }

                        </Box>
                    </Hidden>
                    <Box mt={{xs: 7, md: 0}}>
                        <Switch>
                            <PrivateRouteWithValidation viewPermission="noneed" exact
                                                        path={[`${path}`, `${path}/clients`]}
                                                        component={props => <Clients/>}/>
                            <PrivateRouteWithValidation viewPermission={statistics_report} exact
                                                        path={[`${path}`, `${path}/statistics`]}
                                                        component={props => <DashboardView/>}/>
                            <PrivateRouteWithValidation viewPermission={pos_store}
                                                        component={props => <Products title={"Store"} {...props}/>}
                                                        path={`${path}/store`} exact/>
                            <PrivateRouteWithValidation viewPermission={pos_sales_history} component={SalesHistory}
                                                        path={`${path}/sales-history`} exact/>
                            <PrivateRouteWithValidation viewPermission={sales_report}
                                                        component={props => <SalesReport {...props}/>}
                                                        path={`${path}/reports-sales`}
                                                        exact/>
                            <PrivateRouteWithValidation viewPermission={sales_report}
                                                        component={props => <BalanceFlowsReport {...props}/>}
                                                        path={`${path}/balance-history`}
                                                        exact/>
                            <PrivateRouteWithValidation viewPermission={ranking_report}
                                                        component={props => <SalesRankingReport {...props}/>}
                                                        path={`${path}/reports-ranking`} exact/>
                            <PrivateRouteWithValidation viewPermission={receipts_reports}
                                                        component={props => <InvoicingReport {...props}/>}
                                                        path={`${path}/reports-receipts`} exact/>
                            <PrivateRouteWithValidation viewPermission={store} component={StoreView}
                                                        path={`${path}/settings/store/`} exact/>
                            <PrivateRouteWithValidation viewPermission={store_actions} component={SettingsProduct}
                                                        path={`${path}/settings/store/products/`}
                                                        exact/>
                            <PrivateRouteWithValidation viewPermission={general} component={General}
                                                        path={`${path}/settings/general/`} exact/>
                            <PrivateRouteWithValidation viewPermission={locations} component={Areas}
                                                        path={`${path}/settings/general/areas/`} exact/>
                            <PrivateRouteWithValidation viewPermission={locations} component={BlockAmount}
                                                        path={`${path}/settings/general/block-amount/`} exact/>
                            <PrivateRouteWithValidation viewPermission={billing} component={Billing}
                                                        path={`${path}/settings/billing/`} exact/>
                            <PrivateRouteWithValidation viewPermission={serials} component={SeriesAndCorrelatives}
                                                        path={`${path}/settings/billing/series_correlatives/`}
                                                        exact/>
                            <PrivateRouteWithValidation viewPermission={taxes} component={Taxes}
                                                        path={`${path}/settings/billing/taxes`} exact/>
                            <PrivateRouteWithValidation viewPermission={exchange_rate} component={ExchangeRate}
                                                        path={`${path}/settings/billing/exchange-rate`} exact/>
                            <PrivateRouteWithValidation viewPermission={taxes} component={Charges}
                                                        path={`${path}/settings/billing/charges`} exact/>
                            <PrivateRouteWithValidation viewPermission={employees} component={Employees}
                                                        path={`${path}/settings/employees/`} exact/>
                            <PrivateRouteWithValidation viewPermission={staff} component={Staff}
                                                        path={`${path}/settings/employees/staff/`} exact/>
                            <PrivateRouteWithValidation viewPermission={volunteer} component={Volunteer}
                                                        path={`${path}/settings/employees/volunteer/`}
                                                        exact/>
                            <PrivateRouteWithValidation viewPermission={accounts} component={Accounts}
                                                        path={`${path}/settings/accounts/`} exact/>
                            <PrivateRouteWithValidation viewPermission={groups} component={GroupView}
                                                        path={`${path}/settings/accounts/group/`} exact/>
                            <PrivateRouteWithValidation viewPermission={users} component={UserView}
                                                        path={`${path}/settings/accounts/user/`} exact/>
                            <PrivateRouteWithValidation viewPermission={support}
                                                        component={props => <SupportView {...props}/>} 
                                                        path={`${path}/support`} exact/>
                            <Route exact path={`${path}/logout`} component={props => <Redirect to="/logout"/>}/>
                            <Route exact path={`${path}/401`} component={page401}/>
                            <Route component={page404}/>
                        </Switch>
                    </Box>
                </Box>
                {
                    showPaymentPanel &&
                    <Box>
                        <Hidden mdUp implementation="css">
                            <Drawer variant="temporary" anchor='right' open={paymentPanelMobileOpen}
                                    onClose={handlePaymentPanelDrawerToggle}
                                    classes={{
                                        paper: classes.sideDrawers.paymentPanelDrawerHolder,
                                    }}
                                    ModalProps={{
                                        keepMounted: true, // Better open performance on mobile.
                                    }}>
                                {paymentPanelDrawer}
                            </Drawer>
                        </Hidden>
                        <Hidden smDown implementation="css">
                            <Drawer variant="permanent" open anchor='right'
                                    classes={{
                                        paper: classes.sideDrawers.paymentPanelDrawerHolder,
                                    }}>
                                {paymentPanelDrawer}
                            </Drawer>
                        </Hidden>
                    </Box>
                }
                {!balanceStatus && <OpeningBalanceModal/>}
            </Grid>
        </Grid>

    )
};


const mapStateToProps = (state) => ({
    showPaymentPanel: state.utilsReducer.showPaymentPanel,
    balanceStatus: state.balanceStatus.openBalance,
    permissionsList: state.auth.permissionsList,
    qtyProducts: state.cart.quantityById
});
export default connect(mapStateToProps)(RumiPOS)

