import React from "react";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";

// COMPONENTS MATERIAL UI
import {IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {DEFAULT} from "../../../common/commonConstants"

// CSS
import Classes from '../../../../styles';
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import {handleDynamicDialog} from "../../../common/actions/actions"
import NullMessage from '../../../common/components/error.pages/NullMessage'

export default function GroupsTable(props) {
    const classes = Classes();
    const dispatch = useDispatch();

    const rows = props.entries || [];
    const columns = props.columns || [];
    const edit = props.edit || false;
    const remove = props.remove || false;

    return (
        <Paper className={`general-styles- ${classes.styles.shadow1}`}>
            <TableContainer style={{maxHeight: "25em"}}>
                <Table stickyHeader size="small" aria-label="sticky table"
                       className={`general-tables- ${classes.tables.table} white-table`}>
                    <TableHead>
                        <TableRow>
                            {columns.map(function (column, index) {
                                if (index === 0 || index === 3) {
                                    return (
                                        <TableCell key={index + 1} align="center"
                                                   className={classes.tables.hiddenColumn}>{column}</TableCell>
                                    )
                                }
                                return (
                                    <TableCell key={index + 1} align="center">{column}</TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows.length > 0 ?
                                rows.length === 1 && rows[0].id === DEFAULT ?
                                    <TableRow>
                                        <TableCell colSpan={9} align="center">
                                            {props.entity === "volunteer" ?
                                                <NullMessage message={"There are no volunteers"} height={"10em"}/>
                                                :
                                                <NullMessage message={"There are no" + props.entity} height={"10em"}/>
                                            }
                                        </TableCell>
                                    </TableRow> :
                                    rows.map((row, index) => (
                                            <TableRow key={index + 1} hover role="checkbox" tabIndex={-1}>
                                                {
                                                    Object.values(row).map(function (value, index) {
                                                        if (index === 0 || index === 3) {
                                                            return (
                                                                <TableCell key={index} align="center"
                                                                           style={row.id === DEFAULT ? {display: 'none'} : {}}
                                                                           className={classes.tables.hiddenColumn}>{value}</TableCell>
                                                            )
                                                        }
                                                        return (
                                                            <TableCell style={row.id === DEFAULT ? {display: 'none'} : {}}
                                                                       key={index} align="center">{value}</TableCell>
                                                        )
                                                    })
                                                }
                                                <TableCell align="center"
                                                           style={row.id === DEFAULT ? {display: 'none'} : {}}>
                                                    {edit && props.canManage ?
                                                        (row.id === null ?
                                                                <IconButton variant="contained" disabled
                                                                            className={`${classes.buttons.actionButton} edit`}>
                                                                    <EditOutlinedIcon/>
                                                                </IconButton>
                                                                :
                                                                <Link to={props.link}>
                                                                    <IconButton variant="contained"
                                                                                className={`${classes.buttons.actionButton} edit`}
                                                                                onClick={() => {
                                                                                    props.edit(row.id)
                                                                                }}>
                                                                        <EditOutlinedIcon/>
                                                                    </IconButton>
                                                                </Link>
                                                        ) :
                                                        <IconButton variant="contained" disabled
                                                                    className={`${classes.buttons.actionButton} `}>
                                                            <EditOutlinedIcon/>
                                                        </IconButton>
                                                    }
                                                    {remove && props.canManage ?
                                                        (row.usersCount === 0 ?
                                                            <IconButton variant="contained"
                                                                        className={`${classes.buttons.actionButton} delete`}
                                                                        onClick={() => dispatch(handleDynamicDialog(props.entity, row.id))}
                                                            >
                                                                <DeleteIcon color="error"/>
                                                            </IconButton> :
                                                            <IconButton variant="contained"
                                                                        disabled
                                                                        className={`${classes.buttons.actionButton} delete`}>
                                                                <DeleteIcon color="error"/>
                                                            </IconButton>)
                                                        :
                                                        <IconButton variant="contained"
                                                                    disabled
                                                                    className={`${classes.buttons.actionButton} `}>
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )
                                    ) :
                                <TableRow>
                                    <TableCell colSpan={9} align="center">
                                        {props.entity === "volunteer" ?
                                            <NullMessage message={"There are no volunteers"} height={"10em"}/>
                                            :
                                            <NullMessage message={"There are no" + props.entity} height={"10em"}/>
                                        }
                                    </TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}