import {Grid, IconButton} from "@material-ui/core";
import DetailsIcon from "../../../../assets/icons/view-details.svg";
import React from "react";
import Classes from "../../../../styles";
import {useDispatch} from "react-redux";


export default function ReservationsAllTime({retrieveReservationsAllTime}) {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <IconButton onClick={() => retrieveReservationsAllTime()}>
                    <img src={DetailsIcon} style={{width: '32px', height: '32px'}} alt=""/>
                </IconButton>
            </Grid>
        </Grid>
    )
}

