import {connect} from "react-redux"
import {setClient} from "../../redux/actions"
import ClientList from "../../components/clients/ClientList";
import {customerList} from "../../selector/utils.selector";
import {RUMI_LINK} from "../../../common/commonConstants";

const mapStateToProps = (state) => {
    const origin = localStorage.getItem('origin');
    const some = origin === RUMI_LINK ? "consumptions" : "store";

    return {
        chargedCartProducts: state.cart.productsIds.length > 0,
        clients: customerList(state),
        route: `/${origin}/${some}`,
        origin,
        RUMI_LINK,
        title: state.clientCriteria.criteria,
        permissionsList: state.auth.permissionsList,
    }
};

export default connect(mapStateToProps, {setClient})(ClientList)