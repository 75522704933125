export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const BAD_CREDENTIALS = 'BAD_CREDENTIALS';
export const SERVER_ERROR = 'SERVER_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SESSION_VERIFIED = 'SESSION_VERIFIED';
export const INVALID_SESSION = 'INVALID_SESSION';
export const SUBDOMAIN_RETRIEVED = 'SUBDOMAIN_RETRIEVED';
export const RESET_ALL = 'RESET_ALL';
export const LOAD_USER_DATA = 'LOAD_USER_DATA';

export const API_AUTH_LOADING = 'API_AUTH_LOADING';
export const API_REQUEST_AUTH_SUCCESS = 'API_REQUEST_AUTH_SUCCESS';
export const API_REQUEST_USER_DATA_SUCCESS = 'API_REQUEST_USER_DATA_SUCCESS';
export const GET_ERRORS = 'GET_ERRORS';
export const GET_MESSAGES = 'GET_MESSAGES';
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const GET_REQUEST = "GET_REQUEST";
export const POST_USER_PROFILE = "POST_USER_PROFILE";

/* UTILS */
export const FILTER_SEARCH_MULTIPLE = 'FILTER_SEARCH_MULTIPLE';
export const VIEW_PAYMENT_PANEL = 'VIEW_PAYMENT_PANEL';
export const GET_DATE_RANGE = "GET_DATE_RANGE";
export const GET_CURRENT_DATE = "GET_CURRENT_DATE";
export const SET_MENU_ITEM = "SET_MENU_ITEM";
export const OPEN_MODAL_VIEW = "OPEN_MODAL_VIEW";
export const VERIFYING_TOKEN = "VERIFYING_TOKEN";

/* Country */
export const LOAD_COUNTRY = "LOAD_COUNTRY";

/* Balance */
export const LOAD_BALANCE = "LOAD_BALANCE";
export const SUCCESS_OPEN_BALANCE = "SUCCESS_OPEN_BALANCE";
export const LOAD_BALANCE_TRANSFERS = "LOAD_BALANCE_TRANSFERS";
export const CREATE_BALANCE_TRANSFERS = "CREATE_BALANCE_TRANSFERS";
export const UPDATE_BALANCE_TRANSFERS = "UPDATE_BALANCE_TRANSFERS";
export const DELETE_BALANCE_TRANSFERS = "DELETE_BALANCE_TRANSFERS";
/* alerts & modals*/
export const GET_ALERT = "GET_ALERT";
export const OPEN_DYNAMIC_DIALOG = "OPEN_DYNAMIC_DIALOG";
export const OPEN_LOADER_PROGRESS = "OPEN_LOADER_PROGRESS";
/* others */
export const RESET_CREDENTIALS = "RESET_CREDENTIALS";
export const GET_RESPONSE = "GET_RESPONSE";
export const LOAD_CURRENCIES = "LOAD_CURRENCIES";
