import React from 'react';
import {connect, useDispatch} from "react-redux";
import {store_actions} from "../../permissionsConstants";
import {Field, reduxForm, submit} from "redux-form";
import {renderTextField} from "../../../common/components/utils/ReduxFromMaterialUI";
import {Button, Grid} from "@material-ui/core";
import Classes from "../../../../styles";
import {submitCategoriesForm, validate} from "../../forms/submit/category.submit";
import {handleShowCategoryForm, handleShowCreatedCategory, toggleCreateEditCategory} from '../../actions/store/sync';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        paddingRight: '0.4em',
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0
        },
    }
}));

let CategoryForm = (props) => {
    const useclasses = useStyles()
    const classes = Classes()
    const dispatch = useDispatch()
    const permissions = Object.values(props.permissionsList);
    const canManageSettingsStore = permissions.includes(store_actions) || permissions.includes("superuser");

    const {pristine, destroy, submitting, showCategoryForm, actionCategory} = props

    const handleAddCancelCategory = (action) => {
        destroy()
        dispatch(toggleCreateEditCategory(action))
        dispatch(handleShowCategoryForm(!showCategoryForm))
    }

    const handleSave = () => {
        dispatch(submit('categories'))
        dispatch(handleShowCategoryForm(false))
        dispatch(handleShowCreatedCategory(actionCategory === 1 ? true : false))
    }

    return (
        <form>
            <Grid container item className={useclasses.gridContainer}>
                <Grid item xs={12} sm={12} md={11} lg={11} style={{paddingBottom: 10}}>
                    {
                        canManageSettingsStore ?
                            (!showCategoryForm ?
                                    <Button variant="contained" color="secondary" fullWidth
                                            onClick={() => handleAddCancelCategory(1)}>
                                        Add Category
                                    </Button>
                                    :
                                    <Button variant="outlined" color="primary" fullWidth
                                            onClick={() => handleAddCancelCategory(null)}>
                                        Cancel
                                    </Button>
                            )
                            : <span style={{cursor: 'not-allowed'}}>
                        <Button variant="contained" disabled color="secondary" fullWidth>
                            Add Category
                        </Button></span>
                    }
                </Grid>
                {
                    showCategoryForm &&
                    <Grid item xs={8} sm={8} md={8} lg={8} style={{paddingBottom: '0.6em'}}>
                        <Field fullWidth
                               id="standard-input"
                               name="name"
                               component={renderTextField}
                               className={`${classes.forms.textFieldBox} ${classes.forms.inputColor}`}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               placeholder="Category name"
                        />
                    </Grid>
                }
                {
                    showCategoryForm &&
                    <Grid item xs={4} sm={4} md={3} lg={3} style={{paddingLeft: 10, paddingBottom: '0.6em'}}>
                        <Button variant="contained" color="primary" fullWidth
                                onClick={handleSave}
                                disabled={pristine || submitting}
                        >save</Button>
                    </Grid>
                }
            </Grid>
        </form>
    )
}

CategoryForm = reduxForm({
    form: 'categories',
    onSubmit: submitCategoriesForm,
    validate
})(CategoryForm)

CategoryForm = connect(
    state => ({
        showCategoryForm: state.storeReducer.showCategoryForm,
        actionCategory: state.storeReducer.actionCategory,
        permissionsList: state.auth.permissionsList
    })
)(CategoryForm)

export default CategoryForm