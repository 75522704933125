import React from 'react';
import {connect, useDispatch} from 'react-redux'

import Loader from "../utils/Loader";


import {makeStyles} from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';

import {Box} from '@material-ui/core';
// CSS


const useStyles = makeStyles((theme) => ({
    root: {
        width: '50%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        backgroundColor: "rgba(253, 253, 253, 0.86)"
    },
}));

const LoaderProgress = ({statusPayment, open}) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    return (
        <Box>
            <Backdrop className={classes.backdrop}
                      open={statusPayment === "close" && open ? true : false}
            >
                <div className={classes.root}>
                    <Loader/>
                </div>
            </Backdrop>

        </Box>
    )
};

const mapStateToProps = state => {
    return {
        statusPayment: state.messages.status,
        open: state.utilsReducer.openLoaderProgress
    }
};

export default connect(mapStateToProps)(LoaderProgress)





