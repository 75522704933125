import React, {useEffect} from "react";
import {useDispatch} from 'react-redux'
import {handleFilterSearchPanel} from "../../../pos/redux/asyncActions"
import {getCurrentDate, getDateRange} from "../../../common/actions/utilsActions"

// COMPONENTS MATERIAL UI
import {makeStyles} from '@material-ui/core/styles';
import DatePicker from "./DatePicker";
import TimePicker from "./TimePicker";
import {Box, Button, Grid, InputLabel} from "@material-ui/core";
import LoaderProgress from "../../../common/components/modals/LoaderProgress"
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import DateRangeIcon from '@material-ui/icons/DateRange';

import Classes from '../../../../styles/';
import {formatDate, formatedReverseDate, formatTime, handleCurrentDate} from "./handleCurrentDate";

const useStyles = makeStyles((theme) => ({
    dateInput: {
        pointerEvents: 'none!important',
        marginLeft: 15,
        '& input': {
            color: theme.palette.grey[500]
        },

    },
    popover: {
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
        [theme.breakpoints.up('sm')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: '85%'
        },
        [theme.breakpoints.up('lg')]: {
            width: '65%'
        },
    },
    marginGrid: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 15
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 30
        }
    },
    containerCurrentDate: {
        marginTop: 12,
        width: '85%',
        [theme.breakpoints.down('sm')]: {
            width: '54%',
            marginTop: 0
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop: 0
        }
    }
}));

const ReportsParametersPanel = ({panelClass, searcher, data}) => {
    const classes = Classes();
    const useclasses = useStyles();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [state, setState] = React.useState({
        initDate: new Date(),
        endDate: new Date(),
        initTime: new Date("2020-12-15T00:00:00"),
        endTime: new Date(),
        initDateFormat: "",
        endDateFormat: "",
        initTimeFormat: "",
        endTimeFormat: "",
    });

    const handleGetDate = (className, value) => {
        let auxInitDate = state.initDate;
        let auxEndDate = state.endDate;
        let auxInitTime = state.initTime;
        let auxEndTime = state.endTime;

        setState({
            ...state,
            initDate: className === 'init_date' ? value : auxInitDate,
            endDate: className === 'end_date' ? value : auxEndDate,
            initTime: className === 'init_time' ? value : auxInitTime,
            endTime: className === 'end_time' ? value : auxEndTime,
            initDateFormat: className === 'init_date' ? formatDate(value) : formatDate(auxInitDate),
            endDateFormat: className === 'end_date' ? formatDate(value) : formatDate(auxEndDate),
            initTimeFormat: className === 'init_time' ? formatTime(value) : formatTime(auxInitTime),
            endTimeFormat: className === 'end_time' ? formatTime(value) : formatTime(auxEndTime),
        })

    };


    const generateCurrentDate = () => {
        let formatCurrentDate = handleCurrentDate();
        dispatch(getCurrentDate(formatCurrentDate));
    };

    const generateReport = () => {
        dispatch(handleFilterSearchPanel(
            panelClass,
            state.initDateFormat,
            state.endDateFormat,
            state.initTimeFormat,
            state.endTimeFormat,
        ));

        let formatedInitDate = formatedReverseDate(state.initDateFormat);
        let formatedEndDate = formatedReverseDate(state.endDateFormat);
        dispatch(getDateRange(
            formatedInitDate,
            state.initTimeFormat,
            formatedEndDate,
            state.endTimeFormat
        ));
        generateCurrentDate();
        handleClose()
    };

    useEffect(() => {
        setInterval(generateCurrentDate, 300000);
        setState({
            ...state,
            initDate: state.initDate,
            endDate: state.endDate,
            initTime: state.initTime,
            endTime: state.endTime,
            initDateFormat: formatDate(state.initDate),
            endDateFormat: formatDate(state.endDate)
        })
    }, []);

    return (
        <div>
            <Box display="flex" alignItems="flex-end" style={{cursor: 'pointer'}}
                 onClick={handleClick}>
                <DateRangeIcon color="primary" style={{marginBottom: 7}}/>
                <Box display="flex" className={useclasses.containerCurrentDate}
                     style={{borderBottom: "1px solid #5BE576", paddingBottom: 7}}>
                    <Box display="flex" flexGrow={1} ml={2}>
                        <Typography color="textSecondary" style={{marginRight: 15}}>From:</Typography>
                        <Typography>{formatedReverseDate(state.initDateFormat)}</Typography>
                    </Box>
                    <Box display="flex" mr={2}>
                        <Typography color="textSecondary" style={{marginRight: 15}}>To: </Typography>
                        <Typography>{formatedReverseDate(state.endDateFormat)}</Typography>
                    </Box>
                </Box>
            </Box>
            <Popover className={useclasses.popover}
                     id={id}
                     open={open}
                     anchorEl={anchorEl}
                     onClose={handleClose}
                     anchorOrigin={{
                         vertical: 'top',
                         horizontal: 'left',
                     }}
                     transformOrigin={{
                         vertical: 'top',
                         horizontal: 'left',
                     }}>
                <Grid container style={{padding: 20}}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Range Date/Time</Typography>
                        <br/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel htmlFor="input-with-icon-adornment">From: </InputLabel>
                        <Box display="flex">
                            <Box width={"55%"}>
                                <DatePicker date={state.initDate} handleGetDate={handleGetDate}
                                            dateClass={"init_date"}/>
                            </Box>
                            <Box ml={2} width={"45%"}>
                                <TimePicker handleGetDate={handleGetDate} timeClass={"init_time"}
                                            time={state.initTime}/>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} className={useclasses.marginGrid}>
                        <InputLabel htmlFor="input-with-icon-adornment">To: </InputLabel>
                        <Box display="flex">
                            <Box width={"55%"}>
                                <DatePicker handleGetDate={handleGetDate} date={state.endDate} dateClass={"end_date"}/>
                            </Box>
                            <Box ml={2} width={"45%"}>
                                <TimePicker handleGetDate={handleGetDate} time={state.endTime} timeClass={"end_time"}/>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
                            <Button variant="contained" color="primary" onClick={generateReport} style={{width: 200}}>
                                Apply
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Popover>
            <LoaderProgress/>
        </div>
    );
};

export default ReportsParametersPanel;