import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    /* custom classes */
    loaderContainer:{
        height:"100vh"
    },
    loaderHolder:{
        height:"4px",
        width:"60%",
    }
}))