export function formatConsumption(guestConsumption) {
    let consumptions = []
    // console.log(guestConsumption);
    const result = guestConsumption.map( guest => {
            const {outlets:o, ...rest} = guest
            const outlets = Object.entries(o).reduce((acc,entries) => {
                const [outletCode, value] = entries
                const items = value.items
                const consumptionKeys = items.map( consumption => consumption.id)
                consumptions = consumptions.concat(items)
                // console.log(consumptions);
                acc[outletCode] = {
                    ...value,
                    items: consumptionKeys
                }
                return acc
            }, {})

            return {...rest, outlets }
        }
    )
    // console.log(result)
    // console.log(consumptions)
    return {guestConsumption: result, consumptions}
}

export function validateNumericalInput(value) {
    return (value <= 0 ||isNaN(value || typeof value === 'number'))? 0: value
}