import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {withRouter} from "react-router-dom";

import {makeStyles} from '@material-ui/core/styles';
import {Box, ListItemIcon, ListItemText, MenuItem} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    menuItem: {
        borderRadius: 50,
        color: theme.palette.grey['main'],
        width: 200,
        '&:hover': {
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.primary.main
            },
        },
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white + "!important"
            },
        },
        '&.selected': {
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.primary.main,
            }
        }
    }
}));

const SubmenuItem = (({location, submenu, setMenuItem, menu}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const currentLocation = location.pathname;

    useEffect(() => {
        if (setMenuItem !== undefined) {
            if (submenu.url === currentLocation) {
                dispatch(setMenuItem(menu))
            }
        }
    }, [location]);

    return (
        <Box display="flex" style={submenu.enableButton ? {} : {cursor: 'not-allowed'}}>
            <MenuItem style={!submenu.enableButton ? {pointerEvents: "none"} : {}} disabled={!submenu.enableButton}
                      className={`${classes.menuItem} ${currentLocation === submenu.url && 'selected'}`}>
                <ListItemIcon>
                    {submenu.icon}
                </ListItemIcon>
                <ListItemText primary={submenu.name}/>
            </MenuItem>
        </Box>
    )
});

export default (withRouter(SubmenuItem))