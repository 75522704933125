import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import {Box} from "@material-ui/core";
import Classes from "../../../../styles";

export default function Loader() {
    const classes = Classes();
    const [progress, setProgress] = React.useState(0);
    const [buffer, setBuffer] = React.useState(10);

    const progressRef = React.useRef(() => {
    });
    React.useEffect(() => {
        progressRef.current = () => {
            if (progress > 100) {
                setProgress(0);
                setBuffer(10);
            } else {
                const diff = Math.random() * 10;
                const diff2 = Math.random() * 10;
                setProgress(progress + diff);
                setBuffer(progress + diff + diff2);
            }
        };
    });

    React.useEffect(() => {
        const timer = setInterval(() => {
            progressRef.current();
        }, 50);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Box display="flex" alignItems="center" justifyContent="center"
             className={classes.loader.loaderContainer}
        >
            <Box className={classes.loader.loaderHolder}>
                <LinearProgress variant="buffer" value={progress} valueBuffer={buffer}/>
            </Box>
        </Box>
    );
}