import React from 'react';
import {containers} from '../../../../styles/'

const LogoItem = (props) => {
    const classes = {
        logo: containers().logo
    };

    return (
        <img className={classes.logo} src={props.logoImage} alt="logo"/>
    )
};


export default LogoItem
