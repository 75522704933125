import React, {useEffect} from 'react';
import {connect, useDispatch} from "react-redux";
import {userDataRequest} from "../../actions/asyncActions";
import {loadExchangeRate} from "../../../rumi-settings/actions/asyncAction";
import {Redirect, Route} from "react-router-dom";

export const PrivateRouteValidated = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (
        <Component {...props} />
    )}/>
);

const PrivateRouteWithValidation = ({userPermissions, viewPermission, component, path, exact}) => {
    const dispatch = useDispatch();
    const origin = localStorage.getItem('origin');
    const permissionsStr = localStorage.getItem('permissions')
    const permissionsList = permissionsStr.split(",")
    const validatePermissions = Object.values(permissionsList).includes(viewPermission) || Object.values(permissionsList).includes("superuser")

    useEffect(() => {
            if (Object.keys(userPermissions).length === 0) {
                dispatch(userDataRequest());
                dispatch(loadExchangeRate()); //This is necesary for the shopping cart to work
            }
        }, [dispatch]
    );
    if (viewPermission === "noneed" || validatePermissions) {
        return (
            <PrivateRouteValidated component={component} path={path} exact={exact}/>
        )
    } else {
        return (
            <Redirect to={`/${origin}/401`}/>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        userPermissions: state.auth.permissionsList
    }
};
export default connect(mapStateToProps)(PrivateRouteWithValidation);