import React from "react";
import {deleteSalesHistory} from "../../../pos/redux/actions";
import {connect, useDispatch} from "react-redux";
import {loadSelectedDocuments} from "../../actions/async";
import {selectReceipt, toggleReceiptDetails} from "../../actions/sync";
import {validateResponseApi} from "../../../common/selectors/utilsSelector"

// COMPONENTS MATERIAL UI
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {
    Box,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    Hidden,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@material-ui/core/";
// ICONS
import DeleteIcon from '@material-ui/icons/DeleteOutline';
// CSS
import {buttons, main, responsive, tables} from '../../../../styles'
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {IconButton} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import {cancel_sales} from "../../../rumi-settings/permissionsConstants";
import {POS_LINK, RUMI_LINK} from "../../../common/commonConstants";

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 325,
    },
    cellHeader: {
        borderBottom: 'none!important'
    },
    td: {
        padding: '0em!important'
    },
    tdBorder: {
        borderBottom: 'none!important'
    },
    thPadding: {
        '& .MuiTableCell-root': {
            padding: '0.8em 1.2em!important'
        }
    },
    tdSize: {
        '& .MuiTableCell-root': {
            fontSize: '12px!important'
        }
    },
    dialog: {
        '& > div> div': {
            maxWidth: 500,
            padding: '10px 20px 25px 20px'
        }

    },
    blockedEvent: {
        [theme.breakpoints.down('sm')]: {
            pointerEvents: 'none!important'
        }
    }

}));


const HistoryTable = ({permissionsList, billingDetails, selectedReceipt, selectedReceiptResponse, animation}) => {
    const useclasses = useStyles();
    const classes = {
        main: main(),
        tables: tables(),
        buttons: buttons(),
        responsive: responsive()
    }

    const dispatch = useDispatch();
    const theme = useTheme();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(4);
    const rows = billingDetails ? billingDetails : {};
    const [open, setOpen] = React.useState(false);
    const [saleId, setSalelId] = React.useState(null);
    const [toggleAccordion, setToggleAccordion] = React.useState(false);
    // const [keyAccordion, setKeyAccordion] = React.useState(null);
    const canBillingDeleteSales = permissionsList.includes(cancel_sales) || permissionsList.includes("superuser");
    const canPosDeleteSales = permissionsList.includes(cancel_sales) || permissionsList.includes("superuser");
    const handleClickOpen = (id) => {
        setOpen(true);
        setSalelId(id);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const deleteSales = (saleId) => {
        dispatch(deleteSalesHistory(saleId))
    };

    const documentsColumns = [
        "Payment type",
        "User",
        "Date/Time",
        "Total",
        "Document type",
        "Status"
    ];

    const handleClose = () => {
        setOpen(false);
    };

    const changeSelectedBilling = (billingId) => {
        const billing = billingDetails.filter(billing => billing.id === billingId);
        dispatch(selectReceipt(billingId))
        dispatch(loadSelectedDocuments(billing[0].documents))
    };

    // const handleToggleAccordion = (id, value) => {
    //     setKeyAccordion(id)
    //     setToggleAccordion(value)
    // }
    const enableDeleteSales = () => {
        const origin = localStorage.getItem('origin');
        if (origin === RUMI_LINK) {
            return canBillingDeleteSales
        } else if (origin === POS_LINK) {
            return canPosDeleteSales
        } else {
            return true
        }
    };

    return (
        <div>
            <Paper elevation={0} style={{backgroundColor: '#fafafa'}} className={`${classes.main.shadow1}`}>
                <TableContainer className={`${useclasses.tableContainer} `}>
                    <Hidden smDown>
                        <Table stickyHeader aria-label="sticky table" className={`${classes.tables.table} grey-table`}>
                            <TableHead>
                                <TableRow>
                                    {Object.values(documentsColumns).map((column, index) => (
                                        <TableCell align="center" key={index + 1}>
                                            {column}
                                        </TableCell>
                                    ))}
                                    <TableCell align="center">Options</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={useclasses.hovered}>
                                {
                                    rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        return (
                                            <TableRow key={index + 1} role="checkbox" tabIndex={-1}
                                                      style={{
                                                          cursor: "pointer",
                                                          backgroundColor: row.documents[0].notes.length !== 0 ? 'rgba(236, 66, 98, 0.08)' : (selectedReceipt === row.id ? "rgba(57, 205, 200, 0.08)" : 'none')
                                                      }}
                                                      className={`${useclasses.thPadding} ${useclasses.tdSize} ${classes.main.animations} ${selectedReceiptResponse === row.id && animation + " active"}`}
                                                      hover={true}>
                                                <TableCell
                                                    className={`${useclasses.blockedEvent} ${classes.tables.cell + ' sm-noneBorder'} `}
                                                    onClick={() => changeSelectedBilling(row.id)}>
                                                    {row.paymentType}
                                                </TableCell>
                                                <TableCell
                                                    className={`${useclasses.blockedEvent} ${classes.tables.cell + ' sm-noneBorder'}  `}
                                                    onClick={() => changeSelectedBilling(row.id)}>
                                                    {row.user}
                                                </TableCell>
                                                <TableCell
                                                    className={`${useclasses.blockedEvent} ${classes.tables.cell + ' sm-noneBorder'}  `}
                                                    onClick={() => changeSelectedBilling(row.id)}>
                                                    {row.saleDate}
                                                </TableCell>
                                                <TableCell
                                                    className={`${useclasses.blockedEvent} ${classes.tables.cell + ' sm-noneBorder'} `}
                                                    onClick={() => changeSelectedBilling(row.id)}>
                                                    {row.documents[0].total}
                                                </TableCell>
                                                <TableCell scope="row" onClick={() => changeSelectedBilling(row.id)}>
                                                    {row.documents.map((document) =>
                                                        document.notes.length === 0 ?
                                                            document.documentType :
                                                            document.notes.map((note, index) =>
                                                                note.documentType)
                                                    )}
                                                </TableCell>
                                                <TableCell onClick={() => changeSelectedBilling(row.id)}
                                                           className={`${useclasses.blockedEvent} ${classes.tables.cell + ' sm-noneBorder'}  `}>
                                                    {row.documents.map((document, index) =>
                                                        document.notes.length === 0 ?
                                                            document.status :
                                                            document.notes.map((note, index) =>
                                                                note.status)
                                                    )}
                                                </TableCell>
                                                <TableCell className={`${classes.tables.cell}  `}>
                                                    {
                                                        enableDeleteSales() && row.documents[0].notes.length === 0 ?
                                                            <IconButton variant="contained"
                                                                        className={`${classes.buttons.actionButton} delete`}
                                                                        onClick={() => handleClickOpen(row.id)}>
                                                                <DeleteIcon color="error"/>
                                                            </IconButton>
                                                            : ""
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Hidden>
                    <Hidden mdUp>
                        {
                            rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(
                                (row, index) => {
                                    return (
                                        <ExpansionPanel key={index + 1} style={{backgroundColor: '#fafafa', margin: 0}}>
                                            <ExpansionPanelSummary
                                                expandIcon={<ExpandMoreIcon/>}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header">
                                                <Grid container item>
                                                    <Grid item xs={6} sm={4}>
                                                        <Typography variant="caption"
                                                                    style={{fontSize: 10, textAlign: 'center'}}
                                                                    display="block" color="textSecondary">Document
                                                            type</Typography>
                                                        <Typography variant="body2" style={{textAlign: 'center'}}
                                                                    display="block">
                                                            {row.documents.map((document) =>
                                                                document.notes.length === 0 ?
                                                                    document.documentType :
                                                                    document.notes.map((note) =>
                                                                        note.documentType)
                                                            )}</Typography></Grid>
                                                    <Hidden only="xs">
                                                        <Grid item xs={6} sm={4}>
                                                            <Typography variant="caption"
                                                                        style={{fontSize: 10, textAlign: 'center'}}
                                                                        display="block"
                                                                        color="textSecondary">Date/Time</Typography>
                                                            <Typography variant="body2" style={{textAlign: 'center'}}
                                                                        display="block">
                                                                {row.saleDate}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sm={4}>
                                                            <Typography variant="caption"
                                                                        style={{fontSize: 10, textAlign: 'center'}}
                                                                        display="block" color="textSecondary">Total
                                                                Amount</Typography>
                                                            <Typography variant="body2" style={{textAlign: 'center'}}
                                                                        display="block">{row.saleTotal.toFixed(2)}</Typography>
                                                        </Grid>
                                                    </Hidden>
                                                </Grid>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails style={{
                                                borderBottom: `2px solid rgba(0,0,0,0.14)`,
                                                borderTop: `2px solid rgba(0,0,0,0.14)`
                                            }}>
                                                <Grid container spacing={2}
                                                      direction="row" key={index + 1}
                                                      justify="space-between"
                                                      alignItems="center">
                                                    <Grid container item>
                                                        <Grid item xs={6} sm={4}
                                                              style={{textAlign: "left", color: '#9E9E9E'}}>
                                                            Payment type
                                                        </Grid>
                                                        <Grid item xs={6} sm={8} style={{textAlign: "right"}}>
                                                            {row.paymentType}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item>
                                                        <Grid item xs={6} sm={4}
                                                              style={{textAlign: "left", color: '#9E9E9E'}}>
                                                            User
                                                        </Grid>
                                                        <Grid item xs={6} sm={8} style={{textAlign: "right"}}>
                                                            {row.user}
                                                        </Grid>
                                                    </Grid>
                                                    <Hidden smUp>
                                                        <Grid container item>
                                                            <Grid item xs={6} sm={4}
                                                                  style={{textAlign: "left", color: '#9E9E9E'}}>
                                                                Date/Time
                                                            </Grid>
                                                            <Grid item xs={6} sm={8} style={{textAlign: "right"}}>
                                                                {row.saleDate}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container item>
                                                            <Grid item xs={6} sm={4}
                                                                  style={{textAlign: "left", color: '#9E9E9E'}}>
                                                                Total
                                                            </Grid>
                                                            <Grid item xs={6} sm={8} style={{textAlign: "right"}}>
                                                                {row.saleTotal.toFixed(2)}
                                                            </Grid>
                                                        </Grid>
                                                    </Hidden>
                                                    <Grid container item>
                                                        <Grid item xs={6} sm={4}
                                                              style={{textAlign: "left", color: '#9E9E9E'}}>
                                                            Status
                                                        </Grid>
                                                        <Grid item xs={6} sm={8} style={{textAlign: "right"}}>
                                                            {row.documents.map((document, index) =>
                                                                document.notes.length === 0 ?
                                                                    document.status :
                                                                    document.notes.map((note, index) =>
                                                                        note.status)
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item>
                                                        <Grid item xs={6} sm={4}
                                                              style={{textAlign: "left", color: '#9E9E9E'}}>
                                                            Document Type details
                                                        </Grid>
                                                        <Grid item xs={6} sm={8} style={{textAlign: "right"}}>
                                                            <IconButton variant="contained" style={{padding: 0}}
                                                                        onClick={() => {
                                                                            dispatch(toggleReceiptDetails(true))
                                                                            changeSelectedBilling(row.id)
                                                                        }}>
                                                                <VisibilityIcon color="secondary"/>
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                    {row.documents[0].notes.length === 0 &&
                                                        <Grid container item>
                                                            <Grid item xs={6} sm={4}
                                                                  style={{textAlign: "left", color: '#9E9E9E'}}>
                                                                Delete
                                                            </Grid>
                                                            <Grid item xs={6} sm={8} style={{textAlign: "right"}}>
                                                                {enableDeleteSales() ?
                                                                    <IconButton variant="contained" style={{padding: 0}}
                                                                                className={`${classes.buttons.actionButton} delete`}
                                                                                onClick={() => handleClickOpen(row.id)}>
                                                                        <DeleteIcon color="error"/>
                                                                    </IconButton>
                                                                    : ""
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    )
                                })
                        }
                    </Hidden>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[4, 10, 25, 100]}
                    component="div" count={rows.length} rowsPerPage={rowsPerPage} page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}/>
            </Paper>

            <Dialog
                open={open} onClose={handleClose} aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" className={useclasses.dialog}>
                <DialogContent>
                    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                        <DeleteIcon color="error" style={{fontSize: 85}}/>
                        <Typography variant="h4" style={{
                            fontFamily: 'Poppins, sans-serif',
                            fontWeight: 200,
                            margin: 16
                        }}
                        > Are you sure?</Typography>
                        <Typography align="center" color="textPrimary" style={{fontWeight: 150}}>
                            Are you sure that you want to delete this receipt?
                        </Typography>
                    </Box>
                </DialogContent>
                <br/>
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="row">
                    <DialogActions>
                        <Button autoFocus onClick={handleClose} variant="outlined"
                                color="default">
                            CANCEL
                        </Button>
                        <Button autoFocus
                                onClick={() => {
                                    handleClose();
                                    deleteSales({id: saleId, key: "Receipts_history"});
                                }}
                                style={{backgroundColor: "#EA3788", color: "#ffffff", padding: "5px 15px"}}>
                            Delete
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        permissionsList: state.auth.permissionsList,
        billingDetails: state.clientHistoryReducer.clientHistory.billingDetails,
        selectedReceipt: state.clientHistoryReducer.selectedReceipt,
        selectedReceiptResponse: state.messages.response.id,
        animation: validateResponseApi(state.messages.response, "delete-sale")
    }
};

export default connect(mapStateToProps)(HistoryTable);