import {
    SEARCH_BALANCE_FLOWS_REPORT,
    SEARCH_CREDIT_NOTES_REPORT, SEARCH_GUEST_FLOWS_REPORT,
    SEARCH_INVOICES_REPORT,
    SEARCH_RANKING_REPORT,
    SEARCH_SALES_REPORT,
    SEARCH_TICKETS_REPORT
} from "../redux/actionTypes";

export const searchBalanceFlowsReport = value => ({
    type: SEARCH_BALANCE_FLOWS_REPORT,
    valueInput: value
});

export const searchGuestFlowsReport = value => ({
    type: SEARCH_GUEST_FLOWS_REPORT,
    valueInput: value
});

export const searchSalesReport = value => ({
    type: SEARCH_SALES_REPORT,
    valueInput: value
});

export const searchRankingReport = value => ({
    type: SEARCH_RANKING_REPORT,
    valueInput: value
});
export const searchTicketsReport = value => ({
    type: SEARCH_TICKETS_REPORT,
    valueInput: value
});
export const searchInvoicesReport = value => ({
    type: SEARCH_INVOICES_REPORT,
    valueInput: value
});
export const searchCreditNotesReport = value => ({
    type: SEARCH_CREDIT_NOTES_REPORT,
    valueInput: value
});