import React from 'react';

// MATERIAL UI
import {
    TextField,
    FormControl,
    Box, Grid
} from '@material-ui/core';

// CSS
import Classes from '../../../../styles'
import {connect} from "react-redux";

const FormGeneral = ({company}) => {
    const classes = Classes();
    return (
        <Grid container item xs={12}>
            <Grid item xs={12} sm={12} md={12} lg={8}>
                <Box display="flex" flexDirection="column" >
                    <FormControl className={`general-forms- ${classes.forms.formMargin} general-forms- ${classes.forms.fullFormWidth}`}>
                        <TextField className={`general-forms- ${classes.forms.textFieldBox} `}
                                   id="standard-required"
                                   label="Legal Name"
                                   value={company[1] || ""}
                                   disabled
                                   InputLabelProps={{
                                       shrink: true
                                   }}
                        />
                    </FormControl>
                    <FormControl className={`general-forms- ${classes.forms.formMargin} general-forms- ${classes.forms.fullFormWidth}`}>
                        <TextField className={`general-forms- ${classes.forms.textFieldBox} `}
                                   id="standard-required" label="Tax Payer Number"
                                   value={company[5] || ""} disabled
                                   InputLabelProps={{
                                       shrink: true
                                   }}
                        />
                    </FormControl>
                    <FormControl className={`general-forms- ${classes.forms.formMargin} general-forms- ${classes.forms.fullFormWidth}`}>
                        <TextField className={`general-forms- ${classes.forms.textFieldBox} `}
                                   id="standard-required" label="Name"
                                   value={company[2] || ""} disabled
                                   InputLabelProps={{
                                       shrink: true
                                   }}
                        />
                    </FormControl>
                    <FormControl className={`general-forms- ${classes.forms.formMargin} general-forms- ${classes.forms.fullFormWidth}`}>
                        <TextField className={`general-forms- ${classes.forms.textFieldBox} `}
                                   id="standard-required" label="Address"
                                   value={company[3] || ""} disabled
                                   InputLabelProps={{
                                       shrink: true
                                   }}
                        />
                    </FormControl>
                    <FormControl className={`general-forms- ${classes.forms.formMargin} general-forms- ${classes.forms.fullFormWidth}`}>
                        <TextField className={`general-forms- ${classes.forms.textFieldBox} `}
                                   id="standard-required" label="Legal Location"
                                   value={company[4] || ""} disabled
                                   InputLabelProps={{
                                       shrink: true
                                   }}
                        />
                    </FormControl>
                    <FormControl className={`general-forms- ${classes.forms.formMargin} general-forms- ${classes.forms.fullFormWidth}`}>
                        <TextField className={`general-forms- ${classes.forms.textFieldBox} `}
                                   id="standard-required" label="Country"
                                   value={company[0] || ""} disabled
                                   InputLabelProps={{
                                       shrink: true
                                   }}
                        />
                    </FormControl>
                    <FormControl className={`general-forms- ${classes.forms.formMargin} general-forms- ${classes.forms.fullFormWidth}`}>
                        <TextField className={`general-forms- ${classes.forms.textFieldBox} `}
                                   id="standard-required" label="Account block amount"
                                   value={company[13] || ""} disabled
                                   InputLabelProps={{
                                       shrink: true
                                   }}
                        />
                    </FormControl>
                </Box>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    company: Object.values(state.companyReducer)
})

export default connect(mapStateToProps)(FormGeneral)
