import React from 'react';
import {containers} from "../../../../styles";
import {Box, Divider, List} from '@material-ui/core/';
import MenuItem from './MenuItem';

const ListMenu = (props) => {
    const classes = {
        dividerHorizontalMenus: containers().dividerHorizontalMenus
    }

    return (
        <List component="nav">
            {props.menuItems.map((item, index) =>
                <Box display="flex" alignItems="center" flexDirection="column" key={`menu-${index}`}>
                    {
                        index === 0 && <Divider className={classes.dividerHorizontalMenus}/>
                    }
                    <Box pt={1}>
                        <MenuItem index={index} key={index} item={item} handleView={props.handleView}/>
                    </Box>
                    {
                        index === props.position && <Divider className={classes.dividerHorizontalMenus}/>
                    }
                </Box>
            )
            }
        </List>
    )
}

export default ListMenu