import {
    HIDE_CONSUMPTION_PANEL,
    LOAD_CONSUMPTION,
    LOAD_DASHBOARD,
    LOAD_DASHBOARD_SALES_TOTALS_BY_TYPE,
    LOAD_HISTORY,
    LOAD_SELECTED_DOCUMENTS,
    LOAD_SUPPORT_QUESTIONS,
    SET_SUPPORT_FILTER_VALUE,
    SHOW_CONSUMPTION_PANEL
} from "../actionTypes"
import {verifyToken} from "../../common/apiCalls";
import * as api from "../apiCalls";
import {
    requestClientReceiptsHistory,
    requestDailyTransactions,
    requestMonthlyTransactions,
    requestReceiptsByBalance,
    requestSalesTotalsByOrigin,
    requestSalesTotalsByType,
    requestSupportInformation,
    requestWeeklyTransactions,
    requestYearlyTransactions
} from "../apiCalls";
import {requestError} from "../../pos/redux/actions";
import {selectedAll} from "./sync";
import {formatConsumption} from "../utils/formatConsumptions";
import {getCustomerIdentifier} from "../selector/customer.selector";
import {groupSelector} from "../../pos/selector/customer.selector";
import {GET_RESPONSE} from "../../common/actions/actionTypes"
import {handleAlerts} from '../../common/actions/actions'
import {STAFF} from "../../pos/posConstants";

export const loadClientHistory = (customerId, groupId, clientId) => {
    return async (dispatch) => {
        try {
            await verifyToken();
            const clientHistory = await requestClientReceiptsHistory(customerId, groupId, clientId);
            dispatch({
                type: LOAD_HISTORY,
                payload: clientHistory.data
            });
        } catch (err) {
            if (err.response) {
                dispatch(requestError(err.response));
            } else {
                console.log(err)
            }
        }
    }
};

export const loadSelectedDocuments = (documents) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: LOAD_SELECTED_DOCUMENTS,
                payload: documents
            });
        } catch (err) {
            if (err.response) {
                dispatch(requestError(err.response));
            } else {
                console.log(err)
            }
        }
    }
};

export const loadConsumption = () => {
    return async (dispatch, getState) => {
        try {
            await verifyToken();
            const customer = getCustomerIdentifier(getState());
            const groupPayment = getState().paymentState.groupPayment;

            let data = {};

            if (groupPayment) {
                const groupGuests = groupSelector(getState());
                const customersIds = Object.values(groupGuests).map((guest) => {
                    return guest.customerId
                });
                data = {
                    customer: customer.customerType,
                    groupId: customer.group,
                    customersIds: customersIds,
                    identifier: '',
                };
            } else {
                if (customer.customerType === STAFF) {
                    data = {
                        customer: customer.customerType,
                        groupId: '',
                        customersIds: [],
                        identifier: customer.identifier
                    }
                } else {
                    data = {
                        customer: customer.customerType,
                        groupId: '',
                        customersIds: [customer.identifier],
                        identifier: '',
                        reservationId: customer.reservationId
                    }
                }
            }

            const result = await api.getGuestConsumption(data);
            if (result.status === 200) {
                const format = formatConsumption(result.data);
                dispatch({
                    type: LOAD_CONSUMPTION,
                    guestConsumptions: format.guestConsumption,
                    consumptionItems: format.consumptions,
                    guestsSelected: format.guestConsumption.map(guest => guest.customerId),
                    isFetch: true
                });
                dispatch(selectedAll())
            }
        } catch (err) {
            if (err.response.data) {
                dispatch(requestError(err.response));// error consumptions
            } else {
                console.log(err)
            }
        }
    }
};

export const removeConsumption = (detailId) => {
    return async (dispatch) => {
        try {
            await verifyToken();
            const response = await api.cancelAccountItem(detailId);
            setTimeout(() => {
                if (response.status === 200) {
                    dispatch(loadConsumption())
                }
            }, 3000);
            dispatch(handleAlerts('success', 'Ancillary removed ', true));
            setTimeout(() => {
                dispatch(handleAlerts('close', '', false));
            }, 800);
            dispatch({
                type: GET_RESPONSE,
                response: {
                    status: response.status,
                    method: response.config.method,
                    id: detailId,
                    key: "delete-ancillary"
                }
            })
        } catch (err) {
            dispatch({
                type: GET_RESPONSE,
                response: {}
            })
            dispatch(handleAlerts('error', 'Failed remove', true));
            if (err.response.data) {
                dispatch(requestError(err.response));
            } else {
                console.log(err)
            }
        }
    }
}


export const toggleConsumptionStatus = (outlet, consumptionId) => {
    return async (dispatch, getState) => {
        const customer = getCustomerIdentifier(getState());
        const payload = {
            customer: customer.customerType,
            identifier: customer.identifier,
            consumptionId
        };
        const result = await api.putConsumptionStatus(consumptionId, payload);
        if (result.status === 200) {
            dispatch({
                type: "SET_CONSUMPTION_STATUS",
                outlet: outlet,
                consumptionId: consumptionId
            });
            dispatch(selectedAll());
        }
    }
};

export const showConsumptionsPanel = () => {
    return async (dispatch) => {
        dispatch({
            type: SHOW_CONSUMPTION_PANEL
        });
    }
};

export const hideConsumptionsPanel = () => {
    return async (dispatch) => {
        dispatch({
            type: HIDE_CONSUMPTION_PANEL
        });
    }
};

export const requestDashboard = () => {
    return async (dispatch) => {
        try {
            await verifyToken();
            const dailyTransactions = await requestDailyTransactions();
            const weeklyTransactions = await requestWeeklyTransactions();
            const monthlyTransactions = await requestMonthlyTransactions();
            const yearlyTransactions = await requestYearlyTransactions();
            const receiptsByBalance = await requestReceiptsByBalance();
            const salesTotalByType = await requestSalesTotalsByType();
            const salesTotalsByOrigin = await requestSalesTotalsByOrigin();
            const dashboardData = {
                "dailyTransactions": dailyTransactions.data[0],
                "weeklyTransactions": weeklyTransactions.data[0],
                "monthlyTransactions": monthlyTransactions.data[0],
                "yearlyTransactions": yearlyTransactions.data[0],
                "receiptsByBalance": receiptsByBalance.data[0],
                "salesTotalByType": salesTotalByType.data,
                "salesTotalsByOrigin": salesTotalsByOrigin.data[0]
            };
            dispatch({
                type: LOAD_DASHBOARD,
                payload: dashboardData
            })
        } catch (err) {
            if (err.response) {
                dispatch(requestError(err.response));
            } else {
                console.log(err)
            }
        }
    }
};

export const requestDashboardSaleTotalsByType = () => {
    return async (dispatch) => {
        try {
            await verifyToken();
            const salesTotalByType = await requestSalesTotalsByType();
            dispatch({
                type: LOAD_DASHBOARD_SALES_TOTALS_BY_TYPE,
                payload: salesTotalByType.data
            })
        } catch (err) {
            if (err.response) {
                dispatch(requestError(err.response));
            } else {
                console.log(err)
            }
        }
    }
};

// SUPPORT
export const requestSupportList = () => {
    return async (dispatch) => {
        try {
            const supportList = await requestSupportInformation();
            dispatch({
                type: LOAD_SUPPORT_QUESTIONS,
                payload: supportList.data[0]
            })
        } catch (err) {
            if (err.response) {
                dispatch(requestError(err.response));
            } else {
                console.log(err)
            }
        }
    }
};


export const setSupportFilterValue = (value = null) => {
    return {
        type: SET_SUPPORT_FILTER_VALUE,
        payload: value
    }
};