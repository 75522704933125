import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    /* custom classes */
    root: {
        flexGrow: 1,
        padding: "0 !important",
        margin: "0 !important",
        display: "flex",
        maxWidth: "100%",
        bgcolor: theme.palette.grey[50]
    },
    menu: {
        paddingLeft: "0px !important",
        paddingTop: "0px !important",
        paddingBottom: "0px !important",
    },
    innerMenu: {
        height: "100vh",
        overflowY:"auto"
    },
    logo: {
        width: "63.59px",//"2.65em",
        height:"63.59px" //"2.65em"
    },
    middlePanel: {
        height: "100%",
    },
    paymentPanelHolder: {
        paddingTop: "0px !important",
        paddingRight: "0px !important",
        paddingBottom: "0px !important",
        paddingLeft: "0px !important",
    },
    paymentPanel: {
        height: "100vh"
    },
    mainHolder: {
        padding: "12px",
        height: "100vh",
        overflowY: "auto",
        overflowX:"hidden"
    },
    displayFlex: {
        display: "flex"
    },
    alignCenter: {
        alignItems: "center",
        justifyContent: "center",
    },
    directionColumn: {
        flexDirection: "column",
    },
    directionRow: {
        flexDirection: "row",
    },
    containerPadding: {
        padding: "2.5em 1.2em 0 3.5em!important"
    },
    dividerVertical: {
        borderRight: "1px solid " + theme.palette.grey[200],
        [theme.breakpoints.down('sm')]: {
            border:'none'
        },
        
    },
    dividerHorizontal: {
        borderBottom: "1px solid " + theme.palette.grey[200]
    },
    dividerHorizontalMenus: {
        margin: "0!important",
        width: "5em",
        "&.longer": {
            margin: "auto !important",
            width: "80%"
        }
    },
    infoList: {
        paddingRight: "5em"
    },
    pPaperSale: {
        padding: "0.7em 0.9em 0.8em 0.9em!important",
    },
    dashboardBoxes: {
        backgroundColor: theme.palette.common.white,
        padding: "10px",
        textAlign: "center",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        minHeight:"6.1em",
        "&.balance": {
            width: "48%",
            height: "auto",
            marginBottom: "1em"
        },
        "& > div > .boxTitle": {
            fontSize: "1.2em",
            fontWeight: "400",
            lineHeight: "1em",
            marginBottom: "0.5em",
            marginLeft: '16px',
            textAlign: "left",
            color: theme.palette.grey[600]
        },
        "& > div > .boxInfo": {
            fontSize: "1.4em",
            fontWeight: "bold",
            lineHeight: "1.4em",
            marginLeft: '16px',
            textAlign: "left",
            color: theme.palette.secondary.main,
            '&.list': {
                fontSize: "1em",
                lineHeight: "1.4em",
                marginRight: "4.5em",
                textAlign: "right",
                fontWeight: "normal",
                color: theme.palette.grey[600],
            },
            "&.unrelated": {
                fontSize: "1em",
                lineHeight: "1em",
                color: theme.palette.grey[400],
            }
        },
    },
    productsButtonBlock: {
        minHeight: "8em",
        width: "100% !important",
        padding: "0.1em",

    },
    reportTab:{
        padding: "0em",
    }
}));