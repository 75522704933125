import {
    LOAD_SELECTED_GUEST_CONSUMPTION,
    SET_ACCOMMODATION_DISCOUNT,
    SET_STAY_QUANTITY,
    SET_ANCILLARY_DISCOUNT,
    TOGGLE_OUTLET,
    SET_PAYMENT_TYPE,
    SELECT_RECEIPT,
    SELECT_PAYMENT_OPTIONS,
    TOGGLE_RECEIPT_DETAILS
} from "../actionTypes"

import {joinConsumption} from "../selector/index"

export const selectedAll = () => {
    return (dispatch, getState) => {
        const state = getState();
        const data = joinConsumption(state);
        if (data) {
            dispatch({
                type: LOAD_SELECTED_GUEST_CONSUMPTION,
                payload: data
            })
        }
    }
};

/*Accommodation  Action*/
export const setDiscount = (discount) => {
    return {
        type: SET_ACCOMMODATION_DISCOUNT,
        payload: discount
    }
}

export const setQuantity = (stayId, qty) => {
    return {
        type: SET_STAY_QUANTITY,
        quantity: qty,
        stayId
    }
}
/*Ancillary  Action*/

export const toggleOutlet = (outletCode) => {
    return {
        type: TOGGLE_OUTLET,
        outletCode
    }
}

export const setAncillaryDiscount = (discount) => {
    return {
        type: SET_ANCILLARY_DISCOUNT,
        payload: discount
    }
}



/*Payment state Action*/

export const selectPaymentType = paymentType => {
    return {
        type: SET_PAYMENT_TYPE,
        paymentType: paymentType
    }
}

export const setCurrenciesPayment = currencies => {
    return {
        type: SET_PAYMENT_TYPE,
        payload: currencies
    }
}

export const selectReceipt = value => {
    return {
        type: SELECT_RECEIPT,
        value: value
    }
}

export const selectPaymentOptions = (value) => ({
    type: SELECT_PAYMENT_OPTIONS,
    value
});

export const toggleReceiptDetails = value => {
    return {
        type: TOGGLE_RECEIPT_DETAILS,
        value: value
    }
}

