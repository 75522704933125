import React from "react";
import {connect} from "react-redux";
import {useRouteMatch} from 'react-router-dom';
import {exchange_rate} from "../../permissionsConstants";

import {editExchangeRate} from "../../actions/sync";
// CSS
// COMPONENTS
import ExchangeRateTable from './ExchangeRateTable';
// MATERIAL UI
import {Button, Grid} from '@material-ui/core/';

const ExchangeRateView = ({exchangeRate, editExchangeRate, permissionsList}) => {
    let {path} = useRouteMatch();
    const columns = ['Currency', 'Type', 'Exchange Rate', 'Options'];
    const inactiveSecondCurrency = exchangeRate.exchangeRate === null
    const permissions = Object.values(permissionsList);
    const canManageExchangeRate = permissions.includes(exchange_rate) || permissions.includes("superuser");

    return (
        <Grid container item>
            <Grid item xs={12} sm={10} lg={8}>
                <Button variant="contained"
                        style={{
                            marginBottom: '0', pointerEvents: "none",
                            boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14)",
                            borderRadius: 0, backgroundColor: "rgba(57, 205, 200, 0.1)", color: "#4a4a49"
                        }}
                        fullWidth>
                    {!inactiveSecondCurrency ? "Currency and Exchange rate" : "Currency"}
                </Button>
            </Grid>
            <Grid item xs={12} sm={10} lg={8}>
                <ExchangeRateTable
                    edit={editExchangeRate}
                    entries={exchangeRate}
                    columns={columns}
                    origin={path}
                    entity={"exchange-rate"}
                    canManage={canManageExchangeRate}
                />
            </Grid>
        </Grid>
    )
};

const mapStateToProps = state => ({
    exchangeRate: state.exchangeRateReducer,
    permissionsList: state.auth.permissionsList

});

export default connect(mapStateToProps, {editExchangeRate})(ExchangeRateView)