import storeReducer from './store.reducer';
import billingSettingReducer from './billingSettings.reducer';
import areasSettingReducer from './areas.reducer';
import usersReducer from './users.reducer';
import groupsReducer from './groups.reducer';
import commonOptions from './commonOption.reducer';
import customerSettings from './customerSettings.reducer';
import companyReducer from "./company.reducer";
import employeesAccountsSearchReducer from "./accountsSearchReducer";
import exchangeRateReducer from "./exchange.rate.reducer";


export default {
    storeReducer,
    billingSettingReducer,
    areasSettingReducer,
    groupsReducer,
    usersReducer,
    commonOptions,
    customerSettings,
    companyReducer,
    employeesAccountsSearchReducer,
    exchangeRateReducer
}
