import React from 'react';

import {makeStyles} from "@material-ui/core/styles";
import {Box, Typography} from '@material-ui/core';
import searchIcon from "../../../../assets/icons/search1.svg";

const useStyles = makeStyles(theme => ({
    searchIcon: {
        marginBottom: '1em',
        opacity: 0.1,
        width: 50,
        height: 50
    }
}))

const NullMessage = ({message, height}) => {
    const classes = useStyles();
    return (
        <Box height={height} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <img src={searchIcon} className={classes.searchIcon} alt="search-icon"></img>
            <Typography variant="subtitle1" color="textSecondary">
                {message}
            </Typography>
        </Box>
    )
};

export default NullMessage