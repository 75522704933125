import React, {useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import {connect, useDispatch} from 'react-redux';
import {Field, formValueSelector, reduxForm, getFormInitialValues } from 'redux-form';
import {handleViewPaymentPanel} from "../../../common/actions/actions" 

// CSS
import Classes from '../../../../styles';
// COMPONENTS
import InfoList from '../utils/InfoList';
import {DEFAULT} from "../../../common/commonConstants"

// MATERIAL UI
import {
    Grid,
    FormControl,
    Box,
    Button,
    Typography,
    MenuItem,
    InputLabel
} from '@material-ui/core';
import {renderSelectField, renderSwitch, renderTextField} from "../../../common/components/utils/ReduxFromMaterialUI";
import {submitUserForm, validate, asyncValidate} from "../../forms/submit/user.submit";
import {loadAccounts} from "../../actions/asyncAction";


let UserForm = (props) => {
    const classes = Classes();
    const dispatch = useDispatch();
    const {handleSubmit, pristine, reset, submitting, groupList, isEdit, valid, initialValues} = props
    const userData = initialValues !== undefined ? initialValues : null
    const [activePass, setActivePass] = useState("")
    const [confirmPass, setConfirmPass] = useState("")
    const handleCancel = () => {
        props.history.goBack()
        reset()
    }

    useEffect(() => {
            dispatch(handleViewPaymentPanel(false))
            dispatch(loadAccounts());
        }, [dispatch]
    );


    return (
        <Grid container item xs={12} spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h1" className={classes.typography.pageTitles}>
                    <Box display="inline" color="text.secondary"
                    >
                        Settings /
                    </Box>
                    <Link to={`../accounts`}
                          className={`general-nav- ${classes.nav.link}`}>
                        <Box display="inline" color="text.secondary">
                            &nbsp;Accounts /
                        </Box>
                    </Link>
                    &nbsp;User
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={2}>
                            <InfoList title={"Description"} description={"Data from your user."}/>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={6}>
                            <FormControl
                                className={`general-forms- ${classes.forms.formMargin} general-forms- ${classes.forms.fullFormWidth}`}>
                                <Field
                                    id="standard-input"
                                    label="E-mail *"
                                    name="email"
                                    component={renderTextField}
                                    className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} `}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={isEdit}
                                />
                            </FormControl>
                            <FormControl
                                className={`general-forms- ${classes.forms.formMargin} general-forms- ${classes.forms.fullFormWidth}`}
                            >
                                <Field
                                    id="standard-input"
                                    label="Name *"
                                    name="firstName"
                                    component={renderTextField}
                                    className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} `}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={userData.id == DEFAULT && isEdit}
                                />
                            </FormControl>
                            <FormControl
                                className={`general-forms- ${classes.forms.formMargin} general-forms- ${classes.forms.fullFormWidth}`}
                            >
                                <Field
                                    id="standard-input"
                                    label="Surname *"
                                    name="lastName"
                                    component={renderTextField}
                                    className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} `}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={userData.id == DEFAULT && isEdit}
                                />
                            </FormControl>
                            <FormControl
                                className={`general-forms- ${classes.forms.formMargin} general-forms- ${classes.forms.fullFormWidth}`}>
                                <Field
                                    className={`${classes.forms.select}  `}
                                    classNameFormControl={` ${classes.forms.fullFormWidth}`}
                                    component={renderSelectField}
                                    label="Group *"
                                    name="groups"
                                    disabled={userData.id == DEFAULT && isEdit}
                                >
                                    <MenuItem value="">
                                        <em>Select a Group...</em>
                                    </MenuItem>
                                    {groupList.map(groups => 
                                        groups.id == 1? null :  
                                        <MenuItem aria-label="None" className={classes.forms.option} value={groups.id}
                                        key={groups.id}>{groups.name}</MenuItem>
                                    )}
                                </Field>
                            </FormControl>
                            <FormControl  className={`general-forms- ${classes.forms.formMargin} general-forms- ${classes.forms.fullFormWidth}`}>
                                <Field
                                    id="standard-input"
                                    label="Username *"
                                    name="username"
                                    component={renderTextField}
                                    className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} `}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={userData.id == DEFAULT && isEdit}
                                />
                            </FormControl>
                            <Box display="flex" flexDirection="row"
                                 className={`${classes.forms.formMargin} ${classes.forms.fullFormWidth}`}>
                                <Field onChange={(e)=> setActivePass(e.target.value)}
                                    id="standard-input"
                                    label="Password *"
                                    name="password"
                                    type="password"
                                    component={renderTextField}
                                    className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} `}
                                    style={{width: '20em', marginRight: '0.8em'}}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={userData.id == DEFAULT && isEdit}
                                />
                                <Field onChange={(e)=> setConfirmPass(e.target.value)}
                                    id="standard-input"
                                    label="Confirm password *"
                                    name="password2"
                                    type="password"
                                    component={renderTextField}
                                    className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} `}
                                    style={{width: '20em', marginRight: '0.8em'}}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={userData.id == DEFAULT && isEdit}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={2} />
                        <Grid item xs={12} lg={10} >
                            <Button className={`${classes.buttons.saveBtn} ${!activePass || !confirmPass ? 'inactive': 'active'}`}
                                    style={{margin: '0 0.8em 0 0', width: '100px'}}
                                    type="submit" variant="contained" color="primary"
                                    disabled={submitting || !valid}
                            >Save</Button>
                            <Button type="button" variant="outlined" color="primary"
                                    style={{margin: '0 0.8em 0 0', width: '100px'}}
                                    onClick={handleCancel}
                            >Cancel</Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>

        </Grid>
    );
}

UserForm = reduxForm({
    form: 'user',
    onSubmit: submitUserForm,
    validate,
    asyncValidate,
    asyncBlurFields: ['email'],
    initialValues: {isActive: true}

})(UserForm)

const selector = formValueSelector('user')
const mapStateToProps = state => ({
    isEdit: selector(state, 'isEdit'),
    groupList: Object.values(state.groupsReducer),
    initialValues: getFormInitialValues('user')(state),
})

export default connect(mapStateToProps)(UserForm)
