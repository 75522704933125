import React from "react";
import {connect, useDispatch} from "react-redux";
import {calculateAncillaryTotals} from "../../selector";
import {setAncillaryDiscount, toggleOutlet} from "../../actions/sync"
import {validateResponseApi} from "../../../common/selectors/utilsSelector"

import Classes from '../../../../styles'

import AncillaryDetails from '../../components/payments/AncillaryDetails'
import {Box, Button, Grid, TextField, Typography} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AncillaryTable from '../../components/payments/AncillaryTable'

import {makeStyles} from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import CancelAccount from "../../../../assets/icons/money.svg";
import ModalView from "../../../common/components/modals/ModalView"
import {openModalView} from "../../../common/actions/utilsActions"


const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize: '1em',
        fontWeight: "410"

    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const Ancillary = ({
                       ancillary,
                       ancieDiscount,
                       clientType,
                       taxes,
                       services,
                       total,
                       subTotal,
                       display,
                       toggleOutlet,
                       setAncillaryDiscount,
                       selectedAncillaryResponse,
                       animation
                   }) => {
    const classes = Classes();
    const dispatch = useDispatch();
    const existingAncillaries = Object.keys(ancillary.items).length === 0;
    const columns = {
        "Outlet": {
            "name": "Outlet",
            "align": "center",
            "colSpan": "2"
        },
        "Amount": {
            "name": "Amount",
            "align": "right",
            "colSpan": "1"
        }
    };


    if (!display) {
        return (
            <p>No Ancillaries</p>
        )
    }

    return (
        <Grid container spacing={2} direction="row" justify="space-between" alignItems="center">
            <Grid item xs={12}>
                <Box display="flex">
                    <Box flexGrow={1}>
                        <Typography variant="h6" color="textSecondary" className={classes.typography.pageSubTitles}>
                            Ancillaries
                        </Typography>
                    </Box>
                    <Box>
                        {!existingAncillaries ?
                            <BootstrapTooltip title={"View details"} placement="top">
                                <Button variant="contained" color="secondary" size="small"
                                        className={`${classes.tables.btnView} ${classes.styles.shadow1}`}
                                        onClick={() => dispatch(openModalView("ancillaries"))}>
                                    <VisibilityIcon/>
                                </Button>
                            </BootstrapTooltip> :
                            <Button variant="contained" color="secondary" size="small"
                                    className={`${classes.tables.btnView} ${classes.styles.shadow1} ${classes.buttons.disabledButton}`}
                                    disabled={existingAncillaries}>
                                <VisibilityIcon/>
                            </Button>
                        }
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                {!existingAncillaries ?
                    <AncillaryTable
                        columns={columns}
                        data={ancillary.items}
                        total={total}
                        subTotal={subTotal}
                        taxes={taxes}
                        services={services}
                        selectableRows={true}
                        discount={ancieDiscount}
                        setDiscount={setAncillaryDiscount}
                        toggleOutlet={toggleOutlet}
                        enableDiscount={existingAncillaries}

                    /> :
                    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column"
                         css={{height: 295, padding: 10, border: "1px solid #e0e0e0"}}>
                        <img src={CancelAccount} style={{marginBottom: '1em', width: 70, height: 70, opacity: 0.4}}/>
                        <Typography variant="subtitle1" style={{color: '#9e9e9e'}}>
                            There are no ancillaries to pay
                        </Typography>
                    </Box>
                }
            </Grid>
            {clientType === 'STAFF' ?
                <Grid item xs={12}>
                    <Box height="100%" display="flex" flexDirection="column" alignItems="center"
                         justifyContent="center" className={`${classes.styles.shadow1}`} bgcolor="common.white"
                         style={{padding: "0.5em"}}>
                        <Typography variant="span">
                            Bonus
                        </Typography>
                        <TextField type="number" min="0" id="standard-basic"
                                   className={`${classes.forms.textField}`}/>
                    </Box>
                </Grid>
                : ""
            }
            <ModalView
                title={"Detail Consumptions by outlet"}
                keyValue={"ancillaries"}
                body={<AncillaryDetails selectedAncillaryResponse={selectedAncillaryResponse} animation={animation}/>}
            />
        </Grid>
    )
};


const mapStateToProps = (state) => {
    const ancillary = state.selectedConsumption.ancillary;
    const ancillaryTotalValues = calculateAncillaryTotals(state);

    return {
        ancillary: ancillary,
        total: ancillaryTotalValues["total"],
        taxes: ancillaryTotalValues["taxes"],
        services: ancillaryTotalValues["services"],
        subTotal: ancillaryTotalValues["subTotal"],
        ancieDiscount: ancillary.discount,
        display: ancillary.items,
        clientType: state.client.clientType,
        selectedAncillaryResponse: state.messages.response.id,
        animation: validateResponseApi(state.messages.response, "delete-ancillary")

    }
};
export default connect(mapStateToProps, {toggleOutlet, setAncillaryDiscount})(Ancillary)