import React, {useState} from "react";
import {connect} from "react-redux"

// CSS
import Classes from '../../../../../styles'
// MATERIAL UI
import {Box, Button, Typography} from '@material-ui/core/';
import {FormControl, TextField} from "@material-ui/core";

const Product = ({name, price, id, variablePrice, category, categories, addProduct, editVariablePrice, client}) => {
    const [newPrice, setNewPrice] = useState(price)
    const classes = Classes();
    const categoryList = categories.filter(cat => category === cat.id)
    const currencyCode = localStorage.getItem('currencyCode')
    const handleNewPriceChange = (event) => {
        setNewPrice(event.target.value);
        editVariablePrice(id, variablePrice, event.target.value)
    }

    return (
        <div>
            <Box style={client.active === false ? {cursor: 'not-allowed', filter: 'opacity(.5)'} : {}}>
                <Box
                    className={`${classes.styles.shadow2} ${classes.styles.bRadius10} ${classes.buttons.boxButton} productBox`}
                >
                    <Button disabled={client.active === false ? true : false}
                            onClick={() => addProduct(id, variablePrice, newPrice)}>
                        <Box display="flex" flexDirection="column" justifyContent="center"
                             className={classes.buttons.boxInfo}>
                            <Typography className="mainText products">
                                {name}
                            </Typography>
                            <Typography className="extraData" style={{marginTop: 10}}>
                                {currencyCode} {price}
                            </Typography>
                            <Typography className="subText" style={{marginTop: 5}}>
                                {categories.length > 0 ? categoryList[0].name : "undefined"}
                            </Typography>
                        </Box>
                    </Button>
                    {variablePrice ?
                        <form>
                            <FormControl
                                className={`general-forms- ${classes.forms.formMargin}`}>
                                <TextField
                                    id="standard-input" name="variablePrice"
                                    type='number'
                                    className={`${classes.forms.textField} ${classes.forms.inputExternal}`}
                                    value={newPrice}
                                    onChange={handleNewPriceChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </form> : ""
                    }
                </Box>
            </Box>
        </div>
    )
};

const mapStateToProps = state => ({
    categories: state.category.all,
    client: state.client
})
export default connect(mapStateToProps)(Product)
