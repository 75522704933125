import React from "react";
// COMPONENTS
import DetailsConsumptionsTable from './DetailsConsumptionsTable'
// COMPONENTS MATERIAL UI
import {Grid, Paper} from "@material-ui/core/";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
// CSS
import Classes from '../../../../styles'
import {ANCILLARY_ORDER} from "../../constants";
import {useDispatch, useSelector} from "react-redux";
import {getConsumptionDetail} from "../../selector";
import {toggleConsumptionStatus} from "../../actions/async";

const TabPanel = (props) => {
    const {children, value, index, ...other} = props;
    return (
        <Typography component="div" role="tabpanel" hidden={value !== index}
                    id={`scrollable-auto-tabpanel-${index}`}
                    aria-labelledby={`scrollable-auto-tab-${index}`}
                    {...other}>
            {value === index && <Box p={0}>{children}</Box>}
        </Typography>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
};

const AncillaryDetails = ({selectedAncillaryResponse, animation}) => {
    const classes = Classes();
    const [state, setState] = React.useState({
        value: 0,
    });

    const consumptions = useSelector(getConsumptionDetail);
    const dispatch = useDispatch();

    const handleChangeTabs = (event, newValue) => {
        setState({
            ...state,
            value: newValue,
        })
    };

    const toggleConsumptionStatusAction = outlet => consumptionId => {
        dispatch(toggleConsumptionStatus(outlet, consumptionId))
    };

    return (
        <div>
            <Paper position="static" elevation={3} className={`${classes.nav.tabPaper} ${classes.nav.tab}`}>
                <Tabs value={state.value} indicatorColor="primary" textColor="primary" onChange={handleChangeTabs}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example">
                    <Tab label="Accommodation" {...a11yProps(0)} />
                    <Tab label="Bar" {...a11yProps(1)} />
                    <Tab label="Kitchen" {...a11yProps(2)} />
                    <Tab label="Souvenirs" {...a11yProps(3)} />
                    <Tab label="Services" {...a11yProps(4)} />
                </Tabs>
            </Paper>
            {
                ANCILLARY_ORDER.map((outlet, i) => (
                    <TabPanel key={i + 1} value={state.value} index={i} className={classes.nav.tabPadding}>
                        <DetailsConsumptionsTable
                            data={consumptions[outlet] || []}
                            action={toggleConsumptionStatusAction(outlet)}
                            allowQuantityChange={false}
                            checkboxAllowed={false}
                            allowDelete={true}
                            selectedAncillaryResponse={selectedAncillaryResponse}
                            animation={animation}
                        />
                    </TabPanel>
                ))
            }
        </div>
    );
};

export default AncillaryDetails 