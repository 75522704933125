import React, {useEffect} from "react";
import salesHistoryFiltered from "../../redux/selector/salesHistoryFiltered"
import SearchMultiple from "../../../common/components/utils/SearchMultiple";
// COMPONENTS MATERIAL UI
import { Box, Grid, Typography} from "@material-ui/core/";
// CSS
import Classes from '../../../../styles'
import {connect, useDispatch} from "react-redux";
import {deleteSalesHistory} from "../../redux/actions"
import HistoryTable from "../../components/balance/HistoryTable";
import {Container} from "@material-ui/core";
import {handleViewPaymentPanel} from "../../../common/actions/actions";
import {loadSalesHistory} from "../../redux/asyncActions";
import Loader from "../../../common/components/utils/Loader";


const SalesHistory = ({salesHistoryList, isSalesHistoryFetch, isDeleted}) => {
    const classes = Classes();
    const dispatch = useDispatch();
    const columns = {
        "product": "Product",
        "quantity": "Qty",
        "amount": "Amount"
    };

    useEffect(() => {
        dispatch(handleViewPaymentPanel(false));
        dispatch(loadSalesHistory());
    }, [isDeleted]);

    if (!isSalesHistoryFetch) {
        return (
            <Container
                className={classes.styles.root}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Loader/>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    return (
        <Grid container spacing={3} style={{paddingRight: "12px"}}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="h1" className={classes.typography.pageTitles}>Sales history</Typography>
            </Grid>
            <Grid item xs={12} sm={7} md={7} lg={4}>
                <Box>
                    <SearchMultiple initial={true} />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <HistoryTable
                    columns={columns}
                    data={salesHistoryList}
                    entity="sales-history"
                />
            </Grid>
        </Grid>

    );
};

const mapStateToProps = (state) => {
    return {
        searchInputHistory: state.balance.searchInputHistory,
        salesHistoryList: salesHistoryFiltered(state.balance.salesHistory, state.utilsReducer.search),
        failedSalesList: state.balance.failedSales,
        isSalesHistoryFetch: state.balance.isSalesHistoryFetch,
        permissionsList: state.auth.permissionsList,
        isDeleted : state.messages.response
    }
};
export default connect(mapStateToProps, {deleteSalesHistory})(SalesHistory)
