import React, {useEffect} from "react";
import {useDispatch} from 'react-redux'
import {handleFilterSearchSimplePanel} from "../../../pos/redux/asyncActions"
import {Box, Button, Grid, InputLabel, TextField} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import LoaderProgress from "../modals/LoaderProgress";

import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    popover: {
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
        [theme.breakpoints.up('sm')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: '85%'
        },
        [theme.breakpoints.up('lg')]: {
            width: '65%'
        },
    },
    marginGrid: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 15
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 30
        }
    },
    containerCurrentFilter: {
        marginTop: 12,
        width: '85%',
        [theme.breakpoints.down('sm')]: {
            width: '54%',
            marginTop: 0
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop: 0
        }
    }
}));

const ReportsSimpleFilterParameterPanel = ({panelClass}) => {
    const useClasses = useStyles();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [filtering, setFiltering] = React.useState("")

    const generateReport = () => {
        dispatch(handleFilterSearchSimplePanel(
            panelClass,
            filtering
        ));
        handleClose()
    };

    useEffect(() => {
        if (panelClass == "guest-flows-report") {
            dispatch(handleFilterSearchSimplePanel(
                panelClass,
                filtering
            ));
        }
    }, []);

    return (
        <div>
            <Box display="flex" alignItems="flex-end" style={{cursor: 'pointer'}}
                 onClick={handleClick}>
                <Box display="flex" className={useClasses.containerCurrentFilter}
                     style={{borderBottom: "1px solid #5BE576", paddingBottom: 7}}>
                    <Box display="flex" flexGrow={1} ml={2}>
                        <Typography color="textSecondary" style={{marginRight: 15}}>Filtering text:</Typography>
                        <Typography>{filtering}</Typography>
                    </Box>
                </Box>
            </Box>
            <Popover className={useClasses.popover}
                     id={id}
                     open={open}
                     anchorEl={anchorEl}
                     onClose={handleClose}
                     anchorOrigin={{
                         vertical: 'top',
                         horizontal: 'left',
                     }}
                     transformOrigin={{
                         vertical: 'top',
                         horizontal: 'left',
                     }}>
                <Grid container style={{padding: 20}}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Filtering Text</Typography>
                        <br/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel htmlFor="input-with-icon-adornment">Filter by: </InputLabel>
                        <Box display="flex">
                            <Box width={"55%"}>
                                <TextField
                                    id="filter-text"
                                    label="Last Name"
                                    value={filtering}
                                    onChange={e => setFiltering(e.target.value)}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
                            <Button variant="contained" color="primary" onClick={generateReport} style={{width: 200}}>
                                Apply
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Popover>
            <LoaderProgress/>
        </div>
    );
}

export default ReportsSimpleFilterParameterPanel;