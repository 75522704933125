import React from "react";
// COMPONENTS
import SimpleTable from "../../../common/components/utils/SimpleTable"
import ExportButtons from "../../../common/components/utils/ExportButtons"
import Filters from "../../components/reports/Filters";
// COMPONENTS MATERIAL UI
import {Box, Grid} from "@material-ui/core";
import ReportsParametersPanel from "../../../common/components/utils/reportsParametersPanel";
import ReportsSearchPanel from "../../../common/components/utils/reportsSearchPanel";
import Classes from "../../../../styles";


export default function SimpleReport(props) {
    const classes = Classes();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container item>
                    <Grid item xs={12} sm={12} md={5} lg={4}>
                        <ReportsParametersPanel panelClass={props.panelClass}/>
                    </Grid>
                    <Grid item xs={12} sm={7} md={3} lg={4} >
                        <ReportsSearchPanel searcher={props.searcher}/>
                    </Grid>
                    <Grid item xs={6} sm={3} md={2} lg={2} >
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Filters setReportFilter={props.filtering}/>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={2} md={2} lg={2}>
                        <Box display="flex" flexDirection="row-reverse">
                            <ExportButtons report={props.report} name={props.title} />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <SimpleTable columns={props.columns} amountKey={props.amountKey} indexListResponsiveTable={props.indexListReport} report={props.report} />
            </Grid>
        </Grid>
    );
}