import React from "react";
// COMPONENTS MATERIAL UI
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    Hidden,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NullMessage from '../../../common/components/error.pages/NullMessage'
// CSS
import {buttons, main, responsive, tables} from '../../../../styles'
import PdfIcon from "../../../../assets/icons/pdf-file.svg";
import {downloadDocumentPDF} from "../../../pos/redux/asyncActions";
import {useDispatch} from "react-redux";

export default function SimpleTable(props) {
    const classes = {
        main: main(),
        tables: tables(),
        buttons: buttons(),
        responsive: responsive()
    };
    const dispatch = useDispatch();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const rows = props.report;
    console.log(rows);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <Paper elevation={0} className={`${classes.main.shadow1} `}>
            <TableContainer style={{maxHeight: 440}}>
                <Hidden smDown>
                    <Table size="small" stickyHeader aria-label="sticky table"
                           className={`${classes.tables.table} white-table`}>
                        <TableHead>
                            <TableRow>
                                {Object.values(props.columns).map((column, index) => (
                                    <TableCell key={index + 1} align="center">
                                        {column}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                rows.length ?
                                    rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        return (
                                            <TableRow key={index + 1} hover role="checkbox" tabIndex={-1}>
                                                {Object.values(row).map((value, index) => (
                                                    index === props.amountKey ?
                                                        <TableCell key={index}
                                                                   align="center">{value.toFixed(2)}</TableCell> :
                                                        <TableCell key={index} align="center">{value}</TableCell>
                                                ))}
                                                {
                                                    props.columns.hasOwnProperty('download') &&
                                                    <TableCell key={index} align="center">
                                                        <IconButton onClick={
                                                            (event) => {
                                                                dispatch(downloadDocumentPDF(row.serial ? row.serial : row.eCorrelative))
                                                            }
                                                        }>
                                                            <img src={PdfIcon} style={{width: '32px', height: '32px'}}
                                                                 alt=""/>
                                                        </IconButton>
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        )
                                    })
                                    :
                                    <TableRow tabIndex={-1}>
                                        <TableCell align="center" colSpan={Object.values(props.columns).length}>
                                            <NullMessage message={"There are no sales"} height={"10em"}/>
                                        </TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </Hidden>
                <Hidden mdUp>
                    {rows.length ?
                        rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            return (
                                <ExpansionPanel key={index + 1} style={{margin: 0}}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header">
                                        <Grid container item>
                                            <Grid item xs={6} sm={4}>
                                                <Typography variant="caption"
                                                            style={{fontSize: 10, textAlign: 'center'}} display="block"
                                                            color="textSecondary">{props.columns[Object.keys(props.columns)[props.indexListResponsiveTable[0]]]}</Typography>
                                                <Typography variant="body2" style={{textAlign: 'center'}}
                                                            display="block">{Object.values(rows[index])[props.indexListResponsiveTable[0]]}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={4}>
                                                <Typography variant="caption"
                                                            style={{fontSize: 10, textAlign: 'center'}} display="block"
                                                            color="textSecondary">{props.columns[Object.keys(props.columns)[props.indexListResponsiveTable[1]]]}</Typography>
                                                <Typography variant="body2" style={{textAlign: 'center'}}
                                                            display="block">{Object.values(rows[index])[props.indexListResponsiveTable[1]]}</Typography></Grid>
                                            <Hidden only="xs">
                                                <Grid item sm={4}>
                                                    <Typography variant="caption"
                                                                style={{fontSize: 10, textAlign: 'center'}}
                                                                display="block"
                                                                color="textSecondary">{props.columns[Object.keys(props.columns)[props.indexListResponsiveTable[2]]]}</Typography>
                                                    <Typography variant="body2" style={{textAlign: 'center'}}
                                                                display="block">{Object.values(rows[index])[props.indexListResponsiveTable[2]]}</Typography>
                                                </Grid>
                                            </Hidden>
                                        </Grid>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails style={{
                                        borderTop: `2px solid rgba(0,0,0,0.14)`,
                                        borderBottom: `2px solid rgba(0,0,0,0.14)`
                                    }}>
                                        <Grid container spacing={2}
                                              direction="row"
                                              justify="space-between"
                                              alignItems="center">
                                            {Object.values(row).map((value, index) => (
                                                index === props.indexListResponsiveTable[2] ?
                                                    <Hidden smUp key={index + 1}>
                                                        <Grid container item spacing={2}>
                                                            <Grid item xs={6} sm={4}
                                                                  style={{textAlign: "left", color: '#9E9E9E'}}>
                                                                {props.columns[Object.keys(props.columns)[index]]}
                                                            </Grid>
                                                            <Grid item xs={6} sm={8}
                                                                  style={{textAlign: "right"}}>
                                                                {index === props.amountKey ? value.toFixed(2) : value}
                                                            </Grid>
                                                        </Grid>
                                                    </Hidden> :
                                                    <Grid container item spacing={2} key={index + 1}
                                                          style={index == props.indexListResponsiveTable[0] || index == props.indexListResponsiveTable[1] ? {display: 'none'} : {}}>
                                                        <Grid item xs={6} sm={4}
                                                              style={{textAlign: "left", color: '#9E9E9E'}}>
                                                            {props.columns[Object.keys(props.columns)[index]]}
                                                        </Grid>
                                                        <Grid item xs={6} sm={8}
                                                              style={{textAlign: "right"}}>
                                                            {index === props.amountKey ? value.toFixed(2) : value}
                                                        </Grid>
                                                    </Grid>
                                            ))}
                                        </Grid>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            )
                        }) : <NullMessage message={"There are no items"} height={"10em"}/>
                    }
                </Hidden>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows ? rows.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
}