import {connect} from 'react-redux';
import {selectCategory, updateProductCategory, 
        editCategory, removeCategory,
        handleShowCategoryForm, handleDropProducts,
        handleShowCreatedCategory, toggleCreateEditCategory,
        getCreatedProduct, activeAlertDragAndDrop
} from '../../actions/store/sync';

import CategoryList from '../../components/store/CategoryList';
import {categoriesSelector} from "../../selector/store.selector";
import {filterSearchMultiple} from '../../../common/actions/utilsActions'
import {validateResponseApi} from "../../../common/selectors/utilsSelector"

const mapStateToProps = (state) => {
    return {
        categoryList: categoriesSelector(state),
        dropProductList: state.storeReducer.dropProductList,
        showCreatedCategory: state.storeReducer.showCreatedCategory,
        currentCategory: state.storeReducer.currentCategory,
        showDeleteIcon: state.storeReducer.showDeleteIcon, 
        searchValue:  state.utilsReducer.search,
        createdProduct: state.storeReducer.createProduct,
        allProducts: Object.values(state.storeReducer.productList),
        selectedCategoryResponse: state.messages.response.id,
        animation: validateResponseApi(state.messages.response,"delete-category"),
        keyDeleteResponse: state.messages.response.key,
        permissionsList: state.auth.permissionsList
}}

export default connect(mapStateToProps, {selectCategory, updateProductCategory, editCategory,removeCategory,
                 handleDropProducts, handleShowCategoryForm, handleShowCreatedCategory,
                toggleCreateEditCategory, filterSearchMultiple, getCreatedProduct, activeAlertDragAndDrop})(CategoryList)