import {
    handleSearchSettingsGroups,
    handleSearchSettingsUsers
} from "../../common/selectors/utilsSelector";

const getGroup = state => state.groupsReducer;
const getUser = state => state.usersReducer;
const getSettingsUserSearchValue = state => state.employeesAccountsSearchReducer.userSearch;
const getSettingsGroupsSearchValue = state => state.employeesAccountsSearchReducer.groupSearch;

export function usersList(state) {
    const userSearchValue = getSettingsUserSearchValue(state);
    let searchedUsers = null;
    const users = getUser(state);
    const sourceGroup = getGroup(state);
    if (!users) return []
    if(userSearchValue !== ""){
        searchedUsers =  handleSearchSettingsUsers(userSearchValue, Object.values(users));
    }
    const list = searchedUsers? Object.values(searchedUsers) : Object.values(users)
    return list.map(user => {
        const {id, firstName, username, groupName, isStaff} = user;
        return {id, firstName, username, groupName, isStaff}
    })
}

export function groupsList(state) {
    const groupSearchValue = getSettingsGroupsSearchValue(state);
    let searchedGroups = null;
    const groups = getGroup(state)
    if (!groups) return []
    if(groupSearchValue !== ""){
        searchedGroups =  handleSearchSettingsGroups(groupSearchValue, Object.values(groups));
    }
    const list = searchedGroups? Object.values(searchedGroups) : Object.values(groups);
    const p = list.map(group => {
        const {id, name, profile: {origin}, usersCount} = group;
        return {id, name, origin, usersCount}
    });
    return p;
}


export function areasByOrigin(state, origin) {
    if (!origin) return []
    const areasList = Object.values(state.areasSettingReducer.areas)
    return areasList
        .filter(area => area.origin === origin)
        .map(area => ({label: area.name, value: area.id}))
}