import {connect} from 'react-redux'
import PaymentTypeList from "../../components/payment_panel/PaymentTypeList";
import {getPaymentTypeConfig, paymentTypeCriteria} from "../../redux/selector/payment.selector"
import {setPaymentType, toggleTransferPaymentSection} from "../../redux/actions"
import { calculateConsumptions} from "../../selector/cartAmount";

const mapStateToProps = state => {
        return {
                paymentTypes: getPaymentTypeConfig(state),
                paymentTypeSelected:  paymentTypeCriteria(state),
                paymentCustomerType : state.paymentTypeSelected.paymentCustomerType,
                emptyCart : !state.cart.productsIds.length,
                consumptions: calculateConsumptions(state),
                validatedExternal: state.clientCriteria.validatedExternal,
                chargeBlock:state.client.chargeBlock,
                optionClientType: state.paymentTypeSelected.optionClientType,
                paymentTypeBlocked : state.client.blocked,
                selectedTransferPayment: state.paymentTypeSelected.selectedTransferPayment,
                keyTransfer: state.paymentTypeSelected.transferPaymentSection.key
        }
}
export default connect(mapStateToProps, {setPaymentType, toggleTransferPaymentSection})(PaymentTypeList)