import React, {useEffect} from 'react';
import {useHistory, withRouter} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import {loadConsumption, loadSelectedDocuments} from "../actions/async"
import {cleanCart, cleanConsumption} from "../../pos/redux/actions";
import {openModalView} from "../../common/actions/utilsActions"
import {selectReceipt} from "../actions/sync";
import {receipts} from "../../rumi-settings/permissionsConstants";
import {CLEAR_COMPANY_FLAG, CLEAR_COMPANY_INFO, SET_GROUP_PAYMENT} from "../actionTypes";
import {RUMI_LINK} from "../../common/commonConstants";
import {makeStyles} from "@material-ui/core/styles";
import AccommodationView from "./payments/AccommodationView";
import AncillaryView from "./payments/AncillaryView";
import ClientHistory from "../components/payments/ClientHistory.jsx";
import Loader from "../../common/components/utils/Loader";
import ErrorMessage from '../../common/components/error.pages/ErrorMessage'
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import NullMessage from "../../common/components/error.pages/NullMessage"
import ModalView from "../../common/components/modals/ModalView"
import {Box, Divider, Grid, Hidden, IconButton, Typography} from '@material-ui/core';
import AlertIcon from '@material-ui/icons/ErrorOutlineOutlined';
import {typography} from "../../../styles";


const useStyles = makeStyles((theme) => ({
    notification: {
        '& .title': {
            paddingTop: 5,
            //fontSize:'1em',
            fontWeight: '2em',
            color: theme.palette.common.black
        },
        '& .message': {
            paddingTop: 2,
            color: theme.palette.grey['700']
        },
        '& .iconButton': {
            padding: 7
        },

    },
    divider: {
        width: '100%'
    },
    missinInfoNotification: {
        backgroundColor: theme.palette.grey['700'],
        color: theme.palette.common.black
    }
}));


const Payments = ({
                      location, loadConsumption, isFetch, customerId, optionClientType, permissionsList, isGroup,
                      customerSelected, clientDocumentType, clientArrivalDate, clientCountry, clientDocumentNumber
                  }) => {
    const styles = useStyles()
    const classes = {
        typography: typography()
    }
    const dispatch = useDispatch();
    const errorResponse = useSelector(state => (state.errors));
    const isClientPanel = optionClientType === "Clients";
    const permissions = Object.values(permissionsList);
    const canSeeReceipts = permissions.includes(receipts) || permissions.includes("superuser");
    let history = useHistory();

    useEffect(() => {
        dispatch(cleanCart());
        dispatch({type: SET_GROUP_PAYMENT, payload: false});
        dispatch({type: CLEAR_COMPANY_INFO});
        dispatch({type: CLEAR_COMPANY_FLAG});
        dispatch(cleanConsumption());
        if (isClientPanel) {
            loadConsumption();
        } else {
            history.push(`/${RUMI_LINK}/clients`)
        }
        dispatch(selectReceipt(null));
        dispatch(loadSelectedDocuments({}))
    }, [customerId]);

    if (!isClientPanel) {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <NullMessage message={"There is no client selected"} height={"90vh"}/>
                </Grid>
            </Grid>
        )
    } else {
        if (!isFetch) {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {
                            errorResponse.status !== null ? <ErrorMessage/> : <Loader/>
                        }
                    </Grid>
                </Grid>
            )
        }
    }

    return (
        <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="h1" gutterBottom
                            className={classes.typography.pageTitles}>Consumptions</Typography>
                <Box ml={2} display="flex" flexWrap="wrap" alignItems="center" className={styles.notification}>
                    <Box flexGrow={1}>
                        {(!clientDocumentNumber || !clientDocumentType || !clientCountry || !clientArrivalDate) &&
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <AlertIcon style={{color: "#F5BC00"}}/>
                                <Box p={0} ml={2} color="text.secondary" fontStyle="oblique">
                                    <Typography variant="body2">
                                        {!clientDocumentNumber && <span>No document number</span>}
                                        {!clientDocumentType && <span>, No document type</span>}
                                        {!clientCountry && <span>, No country</span>}
                                        {!clientArrivalDate && <span>, No arrival date</span>}
                                    </Typography>
                                </Box>
                            </Box>
                        }
                        <Hidden mdUp>
                            <Typography component="legend" className="title"
                            >{customerSelected} &nbsp;</Typography>
                        </Hidden>
                    </Box>
                    <Typography component="legend" color="textSecondary" className={classes.typography.familyPoppins}
                    >receipts&nbsp;</Typography>
                    {
                        canSeeReceipts ?
                            <IconButton className="iconButton" onClick={() => {
                                dispatch(selectReceipt(null));
                                dispatch(loadSelectedDocuments({}));
                                dispatch(openModalView("receipts"))
                            }}>
                                <ReceiptIcon color="primary"/>
                            </IconButton>
                            : <Box style={{cursor: 'not-allowed', padding: 5}}>
                                <ReceiptIcon style={{filter: 'opacity(.2)'}}/>
                            </Box>
                    }
                </Box>
                <Divider light className={styles.divider}/>
            </Grid>
            <Grid item xs={12} sm={7} md={12} lg={7}>
                <AccommodationView/>
            </Grid>
            <Grid item xs={12} sm={5} md={12} lg={5}>
                <AncillaryView/>
            </Grid>
            <ModalView
                title={"Receipts"}
                keyValue={"receipts"}
                body={<ClientHistory/>}
            />
        </Grid>
    )
};

const mapStateToProps = (state) => {
    return {
        isFetch: state.guestConsumption.isFetch,
        customerId: state.client.customerId,
        optionClientType: state.paymentTypeSelected.optionClientType,
        permissionsList: state.auth.permissionsList,
        isGroup: state.paymentState.groupPayment,
        customerSelected: state.client.name,
        clientDocumentType: state.client.documentType,
        clientArrivalDate: state.client.arrivalCountry,
        clientCountry: state.client.country,
        clientDocumentNumber: state.client.documentNumber,
    }
};
export default connect(mapStateToProps, {loadConsumption})(withRouter(Payments))