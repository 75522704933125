import {
    LOAD_CATEGORY_LIST, CREATE_CATEGORY, UPDATE_CATEGORY, REMOVE_CATEGORY,
    LOAD_PRODUCT_LIST, CREATE_PRODUCT, UPDATE_PRODUCT, REMOVE_PRODUCT, PUT_ALL_PRODUCTS,
    LOAD_OUTLET_LIST, UPDATE_TOTAL_PRODUCTS, LOAD_TAX, LOAD_BILLING

} from '../actionsTypes';

import * as api from '../../store.api.calls';
import {requestError} from "../../../pos/redux/actions";
import {verifyToken} from "../../../common/apiCalls";
import * as api_settings from "../../api.calls";

/* CATEGORIES */
export const getCategories = () => async (dispatch) => {
    try {
        await verifyToken();
        const storeSettingCategories = await api.requestCategories();
        dispatch({
            type: LOAD_CATEGORY_LIST,
            categoryList: storeSettingCategories.data
        })
    } catch {
        console.log("throws error");
    }
}


export const postCategory = (newCategory) => {
    return (dispatch) => {
        api.postCategory(newCategory).then(response => {

                dispatch({
                    type: CREATE_CATEGORY,
                    name: response.data
                })
            },
            error => {
                console.log(error)
                //     if (error.response) {dispatch(requestError(error))} else {console.log('Not getting error')}
            }
        )
    }
}

export const updateCategory = (categoryId, categoryName) => {
    return (dispatch) => {
        api.updateCategory(categoryId, categoryName).then(response => {
                dispatch({
                    type: UPDATE_CATEGORY,
                    category: response.data
                })
            },
            error => {
                console.log(error)
                //     if (error.response) {dispatch(requestError(error))} else {console.log('Not getting error')}
            }
        )
    }
}

export const removeCategory = (categoryId) => {
    return (dispatch) => {
        api.deleteCategory(categoryId).then(response => {
                dispatch({
                    type: REMOVE_CATEGORY,
                    category: response.data
                })

            },
            error => {
                console.log(error)
                //     if (error.response) {dispatch(requestError(error))} else {console.log('Not getting error')}
            }
        )
    }
}

/* PRODUCTS */
export const getProducts = () => async (dispatch) => {
    try {
        await verifyToken()
        const storeSettingProducts = await api.requestProducts()
        dispatch({
            type: LOAD_PRODUCT_LIST,
            productList: storeSettingProducts.data
        })
    } catch (e) {
        console.log("throws error")
    }
}

export const postProducts = (obj) => {
    return (dispatch) => {
        api.postProducts(obj).then(response => {
                dispatch({
                    type: CREATE_PRODUCT,
                    productList: response.data
                })
            },
            error => {
                console.log(error)
                //     if (error.response) {dispatch(requestError(error))} else {console.log('Not getting error')}
            }
        )
    }
}

export const updateProducts = (productId, objProduct) => {
    return (dispatch) => {
        api.updateProducts(productId, objProduct).then(response => {
                dispatch({
                    type: UPDATE_PRODUCT,
                    productList: response.data
                })
            },
            error => {
                console.log(error)
                //     if (error.response) {dispatch(requestError(error))} else {console.log('Not getting error')}
            }
        )
    }
}

/* productos arrastrados en conjunto */
export const updateTotalProducts = (objProduct) => {
    return (dispatch) => {
        api.updateTotalProducts(objProduct).then(response => {
                dispatch({
                    type: UPDATE_TOTAL_PRODUCTS,
                    dragProducts: response.data
                })
            },
            error => {
                console.log(error)
            }
        )
    }
}


export const removeProducts = (productId) => {
    return (dispatch) => {
        api.deleteProducts(productId).then(response => {
                dispatch({
                    type: REMOVE_PRODUCT,
                    productList: response.data
                })
            },
            error => {
                console.log(error)
            }
        )
    }
}

export const getOutlets = () => async dispatch => {
    try {
        await verifyToken()
        const response = await api.requestOutlets();
        dispatch({
            type: LOAD_OUTLET_LIST,
            outletList: response.data
        });
    } catch (e) {
        dispatch(requestError(e.response));

    }
}

export const getTaxes = () => async dispatch => {
    try {
        await verifyToken()
        const response = await api_settings.getTaxes();
        dispatch({
            type: LOAD_TAX,
            taxes: response.data
        });
    } catch (e) {
        dispatch(requestError(e.response));

    }
}

export const putAllProducts = (products) => async dispatch => {
    try {
        await verifyToken()
        const response = await api_settings.putAllProducts(products);
        dispatch({
            type: PUT_ALL_PRODUCTS
        });
    } catch (e) {
        //dispatch(requestError(e.response));

    }
}