import React from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {Redirect, Route} from 'react-router-dom';
import Locations from "../../containers/Welcome";
import {cleanLocalStorage} from "../../utils";
import {
    accounts,
    billing,
    billing_sales_history,
    billing_store,
    consumptions,
    employees,
    general,
    groups,
    locations,
    pos_sales_history,
    pos_store,
    ranking_report,
    receipts_reports,
    sales_report,
    serials,
    staff,
    statistics_report,
    store,
    taxes,
    users,
    volunteer,
} from "../../../rumi-settings/permissionsConstants";
import {userDataRequest} from "../../actions/asyncActions";
import {loadExchangeRate} from "../../../rumi-settings/actions/asyncAction";

export const verifyPermissionAndToken = async permission => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    let userPermissions = useSelector(state => state.auth.permissionsList);
    if (Object.keys(userPermissions).length === 0) {
        userPermissions = await dispatch(userDataRequest());
        dispatch(loadExchangeRate());
    }
    return Object.values(userPermissions).includes(permission) || Object.values(userPermissions).includes("superuser");
};

export const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (
        <Component {...props} />
    )}/>
);

export const PrivatePermissionRoute = permission => ({component, path, exact}) => {
    const hasPermission = verifyPermissionAndToken(permission);
    const origin = localStorage.getItem('origin');
    if (permission === "noneed") {
        return (
            <PrivateRoute component={component} path={path} exact={exact}/>
        )
    } else if (hasPermission) {
        return (
            <PrivateRoute component={component} path={path} exact={exact}/>
        )
    } else {
        return (
            <Redirect to={`/${origin}/401`}/>
        )
    }
};

// No permissions needed
export const ClientsRoute = PrivatePermissionRoute("noneed");
export const SettingsExchangeRateRoute = PrivatePermissionRoute(billing);
//REPORTS PRIVATE ROUTES
export const RankingReportRoute = PrivatePermissionRoute(ranking_report);
export const SalesReportRoute = PrivatePermissionRoute(sales_report);
export const ReceiptsReportRoute = PrivatePermissionRoute(receipts_reports);
export const DashboardRoute = PrivatePermissionRoute(statistics_report);
// SETTINGS PRIVATE ROUTES
export const SettingsStoreRoute = PrivatePermissionRoute(store);
export const SettingsProductsRoute = PrivatePermissionRoute(store);
export const SettingsGeneralRoute = PrivatePermissionRoute(general);
export const SettingsAreasRoute = PrivatePermissionRoute(locations);
export const SettingsBillingRoute = PrivatePermissionRoute(billing);
export const SettingsSeriesAndCorrelativesRoute = PrivatePermissionRoute(serials);
export const SettingsTaxesRoute = PrivatePermissionRoute(taxes);
export const SettingsChargesRoute = PrivatePermissionRoute(general);
export const SettingsEmployeesRoute = PrivatePermissionRoute(employees);
export const SettingsStaffRoute = PrivatePermissionRoute(staff);
export const SettingsVolunteersRoute = PrivatePermissionRoute(volunteer);
export const SettingsAccountsRoute = PrivatePermissionRoute(accounts);
export const SettingsGroupsRoute = PrivatePermissionRoute(groups);
export const SettingsUsersRoute = PrivatePermissionRoute(users);
// POS PRIVATE ROUTES
export const PosStoreRoute = PrivatePermissionRoute(pos_store);
export const PosSalesHistory = PrivatePermissionRoute(pos_sales_history);
// BILLING PRIVATE ROUTES
export const BillingPaymentsRoute = PrivatePermissionRoute(consumptions);
export const BillingStoreRoute = PrivatePermissionRoute(billing_store);
export const BillingSalesHistoryRoute = PrivatePermissionRoute(billing_sales_history);


export const PrivateComponentWithWelcome = ({component: Component, ...rest}) => (
    <Route  {...rest} render={props => {
        const renderWelcome = JSON.parse(localStorage.getItem('renderWelcome'));
        const skipWelcome = JSON.parse(localStorage.getItem('skipWelcome'));
        const login = localStorage.getItem('accessToken');
        if (!login || ((renderWelcome && !skipWelcome) || (!renderWelcome && skipWelcome))) {
            console.log('private_error_component')
            cleanLocalStorage();
            return <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
        } else {
            return renderWelcome ? <Component {...props} /> : <Locations/>
        }
    }}/>
);
