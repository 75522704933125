export const groupAccosByRoomName = (accosItems) =>{

    const group = Object.values(accosItems).reduce(function (r, a) {
        if(a.quantity > 0){
            if(r[a.description + a.price.total.toString()] && r[a.description + a.price.total.toString()].price.total === a.price.total){
                r[a.description + a.price.total.toString()].quantity += a.quantity;
            }else{
                if(a.hasOwnProperty('maxQuantity')){
                    r[a.description + a.price.total.toString()] = {
                        "id":"",
                        "description":a.description,
                        "price":{
                            "total": a.price.total
                        },
                        "quantity":a.quantity,
                        "maxQuantity":a.maxQuantity
                    };
                }else{
                    r[a.description + a.price.total.toString()] = {
                        "id":"",
                        "description":a.description,
                        "price":{
                            "total": a.price.total
                        },
                        "quantity":a.quantity,
                    };
                }
            }
        }
        return r;
    }, Object.create(null));
    return group;
};