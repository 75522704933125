import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    /* custom classes */
    listContainer:{
        padding:'0',
        '&.MuiListItem-gutters': {
            paddingLeft: '0!important',
            paddingRight: '0!important'
        }
    },
    listTextItem:{
        '& span' :{
            fontSize: '0.9em!important'
        }
    },
}))