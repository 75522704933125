import React, {useEffect} from "react";
import {connect, useDispatch} from 'react-redux'
import {
    refreshState,
    setFilterCreditNotesReport,
    setFilterInvoicesReport,
    setFilterTicketsReport
} from "../../../pos/redux/actions"
// COMPONENTS
import SimpleReport from './SimpleReport'
// COMPONENTS MATERIAL UI
import {Box, Container, Grid, Paper, Tab, Tabs} from "@material-ui/core/";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
// CSS
import Classes from '../../../../styles'
import Loader from "../../../common/components/utils/Loader";
import {
    handleCreditNotesReportFilterAndSearch,
    handleInvoicesReportFilterAndSearch,
    handleTicketsReportFilterAndSearch
} from "../../redux/selector/handleDataFiltered";
import {handleViewPaymentPanel} from "../../../common/actions/actions";
import {loadReceiptsReport} from "../../redux/asyncActions";
import {searchCreditNotesReport, searchInvoicesReport, searchTicketsReport} from "../../utils/reports.utils";
import {reportsSelector} from "../../selector/reportsSelector";


const TabPanel = (props) => {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
};

const InvoicingReport = ({
                             invoiceReport, ticketReport, creditNoteReport,
                             invoicesAreLoading, ticketsAreLoading, creditNotesAreLoading,paymentPanel
                         }) => {
    const classes = Classes();
    const dispatch = useDispatch();
    const [state, setState] = React.useState({value: 0,});
    const handleChangeTabs = (event, newValue) => {
        setState({
            ...state,
            value: newValue,
        });
        dispatch(refreshState())
    };

    useEffect(() => {
        if(paymentPanel){
            dispatch(handleViewPaymentPanel(false));
        }
        dispatch(loadReceiptsReport());
        
    }, [invoicesAreLoading, ticketsAreLoading, creditNotesAreLoading]);

    if (invoicesAreLoading && ticketsAreLoading && creditNotesAreLoading) {
        return (
            <Container className={classes.styles.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Loader/>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    const columnInvoice = {
        "date": "Date/time",
        'serial': "Receipt Number",
        "name": "Name",
        "documentNumber": "Document Number",
        "nationality": "Nationality",
        "netPrice": "Net Price",
        "tax": "Tax",
        "fees": "Fees",
        "amount": "Amount",
        "type": "Type",
        "origin": "Origin",
        "relatedTicket": "Related Ticket",
        "download":"Download"
    };
    const columnTicket = {
        "date": "Date/time",
        'serial': "Receipt Number",
        "name": "Name",
        "documentNumber": "Document Number",
        "netPrice": "Net Price",
        "tax": "Tax",
        "fees": "Fees",
        "amount": "Amount",
        "type": "Type",
        "origin": "Origin",
        "relatedInvoice": "Related Invoice",
        "download":"Download"
    };

    const columnCNote = {
        "date": "Date/time",
        'serial': "Receipt Number",
        "name": "Name",
        "documentNumber": "Document Number",
        "netPrice": "Net Price",
        "tax": "Tax",
        "fees": "Fees",
        "amount": "Amount",
        "origin": "Origin",
        "relatedReceipt": "Related Receipt",
        "relatedDate": "Related Receipt Date",
        "relatedReceiptType": "Related Receipt Type",
        "download":"Download"
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h1" className={classes.typography.pageTitles}>
                    <Box
                        color="text.secondary" display="inline">
                        Reports /
                    </Box>
                    &nbsp;Receipts
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper position="static" style={{margin: 0}} className={`${classes.nav.tabPaper} ${classes.nav.tab}`}>
                    <Tabs value={state.value}
                          indicatorColor="secondary"
                          textColor="secondary"
                          onChange={handleChangeTabs}
                          aria-label="simple tabs example">
                        <Tab label="Ticket" {...a11yProps(0)} />
                        <Tab label="Invoice" {...a11yProps(1)} />
                        <Tab label="Credit Note" {...a11yProps(2)} />
                    </Tabs>
                </Paper>
                <TabPanel value={state.value} index={0} className={classes.containers.reportTab}>
                    <SimpleReport
                        title={"Ticket"}
                        columns={columnTicket}
                        report={ticketReport}
                        panelClass={"tickets-report"}
                        searcher={searchTicketsReport}
                        filtering={setFilterTicketsReport}
                        amountKey={7}
                        indexListReport={[0,2,1]}
                    />
                </TabPanel>
                <TabPanel value={state.value} index={1} className={classes.containers.reportTab}>
                    <SimpleReport
                        title={"Invoice"}
                        columns={columnInvoice}
                        report={invoiceReport}
                        panelClass={"invoices-report"}
                        searcher={searchInvoicesReport}
                        filtering={setFilterInvoicesReport}
                        amountKey={8}
                        indexListReport={[0,2,1]}
                    />
                </TabPanel>
                <TabPanel value={state.value} index={2} className={classes.containers.reportTab}>
                    <SimpleReport
                        title={"Credit Note"}
                        columns={columnCNote}
                        report={creditNoteReport}
                        panelClass={"credit-notes-report"}
                        searcher={searchCreditNotesReport}
                        filtering={setFilterCreditNotesReport}
                        amountKey={7}
                        indexListReport={[0,2,1]}
                    />
                </TabPanel>
            </Grid>
        </Grid>

    );
};

const mapStateToProps = (state) => ({
    invoiceReport: reportsSelector(state,"invoices"),
    ticketReport: reportsSelector(state,"tickets"),
    creditNoteReport: reportsSelector(state,"creditNotes"),
    invoicesAreLoading: state.reports.invoiceReport.invoicesAreLoading,
    ticketsAreLoading: state.reports.ticketReport.ticketsAreLoading,
    creditNotesAreLoading: state.reports.creditNoteReport.creditNotesAreLoading,
    paymentPanel:state.utilsReducer.showPaymentPanel
});

export default connect(mapStateToProps)(InvoicingReport)