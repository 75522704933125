import React from 'react';
import {connect} from 'react-redux';
// CSS
import {makeStyles} from '@material-ui/core/styles';
import Classes from '../../../styles/'
// ICONS
// COMPONENTS
import FormOpeningBalance from '../components/opening_balance/FormOpeningBalance'
// MATERIAL UI
import {Backdrop, Card, CardContent, Modal, Typography} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 10,
        width: 500,
        // border: 'none!important',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        '&:hover': {
            border: 'none!important'
        }
    },
}));

const OpeningBalance = ({username}) => {
    const useclasses = useStyles();
    const classes = Classes();
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={useclasses.modal}
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Card className={useclasses.paper} style={{outline: '0'}}>
                <CardContent>
                    <Typography
                        variant="h5">Hello, {username}</Typography>
                    <br/>
                    <Typography variant="caption" color="textSecondary"
                                className={classes.typography.familyPoppins}
                    >You have opened balance in your shift. The amount displayed is what you should have
                        in cash dropped from the last turn.
                    </Typography>
                    <br/>
                    <br/>
                    <br/>
                    <FormOpeningBalance handleClose={handleClose}/>
                </CardContent>
            </Card>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        username: state.auth.userData.username
    }
}

export default connect(mapStateToProps)(OpeningBalance)