import React, {useEffect} from 'react';
import {connect, useDispatch} from "react-redux";
import {loadClientHistory} from "../../actions/async.js";
import {toggleReceiptDetails} from "../../actions/sync.js"


import ClientHistoryTabs from "../clientHistory/clientHistoryTabs";
import Loader from "../../../common/components/utils/Loader";
import ClientHistoryTable from "../payments/ClientHistoryTable"
import NullMessage from "../../../common/components/error.pages/NullMessage"
import {Box,Hidden, Grid, Typography, Divider} from '@material-ui/core';
import {responsive} from '../../../../styles'

const ClientHistory = ({
                           customerId, billingDetails, customerName, customerTaxIdType, pmsGuestId,
                           customerTaxIdNumber, groupId, isHistoryLoaded, customerType, clientId, address,openReceiptDetails
                       }) => {
    const classes = responsive()
    const dispatch = useDispatch();                    
    let clientType = customerType
    clientType = clientType.charAt(0).toUpperCase() + customerType.slice(1).toLowerCase()
    const isStaff = customerType === "STAFF"
    const isVolunteer =  parseInt(pmsGuestId)  

    useEffect(() => {
        if (!isHistoryLoaded) {
            if( customerType === "STAFF" && clientId){
                dispatch(loadClientHistory("", "", clientId));
            }
            else{
                dispatch(loadClientHistory(customerId, groupId,""));
            }
        }
       
    }, [isHistoryLoaded, groupId, customerId]);

    if (!isHistoryLoaded) {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{width: '80vw'}}>
                    <Loader/>
                </Grid>
            </Grid>
        )
    }
    if (billingDetails !== undefined && billingDetails.length > 0) {
        return (
                <Grid container spacing={3} className={classes.sm}   style={{width: '80vw'}}>
                    <Grid item xs={12} md={8} className={`${openReceiptDetails ? 'sm-hide' : 'sm-show'}` }>
                        <Grid container style={{border: "1px solid #5BE576", borderRadius: 5,padding: 12}}>
                            <Grid item xs={12} md={6} >
                                <Grid container >
                                    <Grid item xs={6} md={4}>
                                        <Typography variant="subtitle2" color="textSecondary" >Name: </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={8}>
                                        <Typography variant="subtitle2" >{customerName}</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Typography variant="subtitle2" color="textSecondary" >Address:</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={8}>
                                        <Typography variant="subtitle2" >{address}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                            <Grid item xs={12} md={6}>
                                <Grid container >
                                    <Grid item xs={6} md={8}>
                                        <Typography variant="subtitle2" color="textSecondary">Client type: </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Typography variant="subtitle2" >
                                        { isStaff ?
                                            ( isVolunteer ? "Volunteer" : clientType )
                                        : clientType }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={8}>
                                        <Typography variant="subtitle2" color="textSecondary" >Document number:</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Typography variant="subtitle2" >{customerTaxIdNumber}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <br/>
                        <ClientHistoryTable />
                    </Grid>
                    <Grid item xs={12} md={4} className={`${openReceiptDetails ? 'sm-show' : 'sm-hide'}` }>
                        <ClientHistoryTabs/>
                    </Grid> 
                </Grid>


        );
    } else {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} style={{width: 9500}}>
                    <NullMessage message={"There are no receipts" } height={"20em"} />
                </Grid>
            </Grid>
        );
    }

};

const mapStateToProps = (state) => {
    return {
        billingDetails: state.clientHistoryReducer.clientHistory.billingDetails,
        customerName: state.clientHistoryReducer.clientHistory.customerName,
        customerTaxIdType: state.clientHistoryReducer.clientHistory.customerTaxIdType,
        customerTaxIdNumber: state.clientHistoryReducer.clientHistory.customerTaxIdNumber,
        isHistoryLoaded: state.clientHistoryReducer.clientHistory.isHistoryLoaded,
        customerId: state.client.customerId,
        customerType: state.client.customerType,
        clientId: state.client.id,
        groupId: state.client.groupId,
        address: state.client.country,
        pmsGuestId: state.client.pmsGuestId,
        openReceiptDetails: state.clientHistoryReducer.openReceiptDetails
    }
};

export default connect(mapStateToProps)(ClientHistory);
