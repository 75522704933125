import React, {Fragment} from "react";
import {connect, useDispatch} from 'react-redux';
import {withRouter} from "react-router-dom";
import {deleteSalesHistory} from "../../redux/actions";
import {removeConsumption} from "../../../rumi/actions/async";
import {cancel_sales} from "../../../rumi-settings/permissionsConstants";
import {handleDynamicDialog} from "../../../common/actions/actions"
import {POS_LINK, RUMI_LINK} from "../../../common/commonConstants";
import {validateResponseApi} from "../../../common/selectors/utilsSelector"

import {makeStyles} from "@material-ui/core/styles";
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, Hidden,Typography
} from "@material-ui/core/";
import NullMessage from '../../../common/components/error.pages/NullMessage'
import DynamicDialog from "../../../common/components/modals/DynamicDialog"
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import {buttons, colors, main, responsive, tables} from '../../../../styles'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        [theme.breakpoints.up('lg')]: {
            maxHeight: 450,
        },
        [theme.breakpoints.down('md')]: {
            maxHeight: 400,
        },
        [theme.breakpoints.down('xs')]: {
            maxHeight: 380,
        },
        [theme.breakpoints.down('sm')]: {
            maxHeight: 380,
        },
    },
    cellHeader: {
        padding: '0.5em 0!important',
        borderBottom: 'none!important',
    },
    bodyTableRow: {
        '& .MuiTableCell-root': {
            padding: '0.5em 0 0.5em 1.4em!important',
            fontSize: '12px!important'
        },
    },
    cellProducts: {
        '& .justify-flex': {
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.up('md')]: {
                justifyContent: 'center',
            },

        },
        '& .align-flex': {
            display: 'flex',
            alignItems: 'center'
        },
        '& .Y-margin': {
            marginTop: 10, marginBottom: 10
        },
        '& .col-qty-product': {
            textAlign: 'right',
            marginLeft: '3%'
        },
        '& .col-amount-product': {
            textAlign: 'right',
            marginLeft: '6%'
        },
        '& .cell-sub-header': {
            color: theme.palette.grey['600'],
            '&.align-center': {
                [theme.breakpoints.down('sm')]: {
                    textAlign: 'center',
                },
            }

        }
    }

}));


const HistoryTable = (props) => {
    const styles = useStyles();
    const classes = {
        main: main(),
        tables: tables(),
        buttons: buttons(),
        colors: colors(),
        responsive: responsive()
    };
    const dispatch = useDispatch();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [toggleAccordion, setToggleAccordion] = React.useState(false);
    const [keyAccordion, setKeyAccordion] = React.useState(null);

    const rows = props.data === undefined || props.data.length > 0 ? props.data : [];
    const permissions = Object.values(props.permissionsList);
    const canBillingDeleteSales = permissions.includes(cancel_sales) || permissions.includes("superuser");
    const canPosDeleteSales = permissions.includes(cancel_sales) || permissions.includes("superuser");

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const enableDeleteSales = () => {
        const origin = localStorage.getItem('origin');
        if (origin === RUMI_LINK) {
            return canBillingDeleteSales
        } else if (origin === POS_LINK) {
            return canPosDeleteSales
        } else {
            return true
        }
    };

    const handleToggleAccordion = (id,value) => {
        setKeyAccordion(id)
        setToggleAccordion(value)
    }

    return (
        <div>
            <Paper elevation={0} className={`${classes.main.shadow1} `} >
                <TableContainer className={styles.tableContainer}>
                    <Hidden smDown>
                        <Table stickyHeader className={`${classes.tables.table} white-table`}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Type</TableCell>
                                    <TableCell align="left"> User</TableCell>
                                    <TableCell align="left"> Date/time</TableCell>
                                    <TableCell align="left"> Customer</TableCell>
                                    <TableCell align="left"> Name</TableCell>
                                    <TableCell component="th" scope="row" colSpan={4} style={{width: 450}}>
                                        <Grid container>
                                            {Object.values(props.columns).map((column, index) => (
                                                <Grid item md={index < 1 ? 5 : (index === 1 ? 2 : 3)} key={index + 1}
                                                    className={`${styles.cellHeader} ${classes.tables.cell} ${index === 0 ? 'align-left' : ' align-center'}`}>
                                                    {column}
                                                </Grid>
                                            ))}
                                            <Grid item md={2}
                                                className={`${styles.cellHeader} ${classes.tables.cell + ' align-center'}`}>Delete</Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                        <TableBody>
                            {
                                rows.length > 0 ?
                                    (rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(
                                        (row, index) => {
                                            return (
                                                row.products.length === 0 ? null :
                                                    <TableRow key={index + 1} hover role="checkbox" tabIndex={-1}
                                                        className={`${styles.bodyTableRow} ${classes.main.animations} 
                                                        ${props.selectedSaleHistoryResponse === row.saleId && (row.type !== "Chrg" ? props.animation : props.animationAccount) + " active"}`}>
                                                        <TableCell className={`${classes.tables.cell + ' sm-noneBorder'}`}>
                                                            {row.type}
                                                        </TableCell>
                                                        <TableCell className={`${classes.tables.cell + ' sm-noneBorder'}`}>
                                                            {row.user}
                                                        </TableCell>
                                                        <TableCell className={`${classes.tables.cell + ' sm-noneBorder'}`}>
                                                            {row.date}
                                                        </TableCell>
                                                        <TableCell className={`${classes.tables.cell + ' sm-noneBorder'}`}>
                                                            {row.customer}
                                                        </TableCell>
                                                        <TableCell className={`${classes.tables.cell + ' sm-noneBorder'}`}>
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell scope="row" className={`${styles.cellProducts} `}>
                                                            <Grid container item className={row.type !== "Chrg" ? `justify-flex` : ''}>
                                                                <Grid item md={row.type !== "Chrg" ? 10 : 12} >
                                                                    {row.products.map((product, index) => (
                                                                        <Grid container item key={index + 1} className={`align-flex`}>
                                                                            {
                                                                                Object.values(product).map((value, index) =>
                                                                                    index === 0 ? null :
                                                                                    <Grid item  md={row.type !== "Chrg" ? (index < 2 ? 6 : 2) : (index < 2 ? 5 : (index == 2? 2: 3))}
                                                                                        key={index + 1}
                                                                                        className={`Y-margin ${row.type !== "Chrg" ? (index < 2 ? '' : (index > 2 ? 'col-amount-product' : 'col-qty-product')) : ""}`}
                                                                                        style={row.type !== "Chrg"  ? {} : (index < 2 ? {} : (index === 3 ? {textAlign:'right',paddingRight: '6%'} : {textAlign:'right'}))}
                                                                                    >{value}</Grid>
                                                                                )
                                                                            }
                                                                            {row.type === "Chrg" &&
                                                                                (enableDeleteSales() ?
                                                                                <Grid item md={2} className={`${classes.tables.cell} align-center`} >
                                                                                    <IconButton variant="contained" className={`${classes.buttons.actionButton} delete`}
                                                                                                onClick={() => dispatch(handleDynamicDialog(props.entity + "account", product.id))}>
                                                                                        <DeleteIcon/>
                                                                                    </IconButton>
                                                                                </Grid> :
                                                                                 <Grid item md={2} className={`${classes.tables.cell} align-center`}>
                                                                                    <IconButton variant="contained" disabled
                                                                                                className={`${classes.buttons.actionButton} delete`}>
                                                                                        <DeleteIcon className={classes.colors.colorDisabled}/>
                                                                                    </IconButton>
                                                                                </Grid>)

                                                                            }
                                                                        </Grid>
                                                                    ))}
                                                                </Grid>
                                                                {
                                                                    row.type !== "Chrg" &&
                                                                        (enableDeleteSales() ?
                                                                            <Grid item md={2} className={`${classes.tables.cell} align-center `}>
                                                                                <IconButton variant="contained"
                                                                                            className={`${classes.buttons.actionButton} delete`}
                                                                                            onClick={() => dispatch(handleDynamicDialog(props.entity, {
                                                                                                id: row.saleId,
                                                                                                key: props.entity
                                                                                            }))} >
                                                                                    <DeleteIcon/>
                                                                                </IconButton>
                                                                            </Grid> :
                                                                            <Grid item md={2} className={`${classes.tables.cell} align-center`}>
                                                                                <IconButton variant="contained" disabled
                                                                                            className={`${classes.buttons.actionButton} delete`}>
                                                                                    <DeleteIcon className={classes.colors.colorDisabled}/>
                                                                                </IconButton>
                                                                            </Grid> )
                                                                }
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                            )
                                        })
                                ) : <TableRow tabIndex={-1}>
                                        <TableCell align="center" colSpan={9}>
                                            <NullMessage message={"There are no sales"} height={"10em"}/>
                                        </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                    </Hidden>
                    <Hidden mdUp>
                    {
                        rows.length > 0 ?
                            (rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(
                                (row, index) => {
                                    return (
                                        row.products.length === 0 ? null :
                                        <ExpansionPanel key={index + 1} style={{margin: 0}}>
                                            <ExpansionPanelSummary 
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header">
                                                <Grid container item >
                                                    <Grid item xs={6} sm={4} >
                                                        <Typography variant="caption" style={{fontSize: 10, textAlign:'center'}} display="block" color="textSecondary">Date/Time</Typography>
                                                        <Typography variant="body2" style={{ textAlign:'center'}} display="block">{row.date}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sm={4} >
                                                        <Typography variant="caption" style={{fontSize: 10, textAlign:'center'}} display="block" color="textSecondary">Name</Typography>
                                                        <Typography variant="body2" style={{  textAlign:'center'}} display="block">{row.name}</Typography></Grid>
                                                    <Hidden only="xs">
                                                        <Grid item sm={4} >
                                                            <Typography variant="caption" style={{fontSize: 10, textAlign:'center'}} display="block" color="textSecondary">Total Amount</Typography>
                                                            <Typography variant="body2" style={{  textAlign:'center'}} display="block">{row.amount}</Typography>
                                                        </Grid>
                                                    </Hidden>
                                                </Grid>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails style={{borderBottom: `2px solid rgba(0,0,0,0.14)`,borderTop: `2px solid rgba(0,0,0,0.14)` }}>
                                                <Grid container spacing={2}
                                                direction="row" key={index +1}
                                                justify="space-between"
                                                alignItems="center">
                                                        <Grid container item  >
                                                            <Grid item xs={6} sm={4} style={{textAlign: "left", color:'#9E9E9E'}}>
                                                            Type
                                                            </Grid>
                                                            <Grid item xs={6} sm={8} style={{textAlign: "right"}}>
                                                            {row.type}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container item >
                                                            <Grid item xs={6} sm={4} style={{textAlign: "left", color:'#9E9E9E'}}>
                                                            User
                                                            </Grid>
                                                            <Grid item xs={6} sm={8} style={{textAlign: "right"}}>
                                                            {row.user}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container item >
                                                            <Grid item xs={6} sm={4} style={{textAlign: "left", color:'#9E9E9E'}}>
                                                            Customer
                                                            </Grid>
                                                            <Grid item xs={6} sm={8} style={{textAlign: "right"}}>
                                                            {row.customer}
                                                            </Grid>
                                                        </Grid>
                                                        <Hidden smUp>
                                                            <Grid container item  >
                                                                <Grid item xs={6} sm={4} style={{textAlign: "left", color:'#9E9E9E'}}>
                                                                Total amount
                                                                </Grid>
                                                                <Grid item xs={6} sm={8} style={{textAlign: "right"}}>
                                                                {row.amount}
                                                                </Grid>
                                                            </Grid>
                                                        </Hidden>
                                                        <Grid container item >
                                                            <Grid item xs={6}  style={{textAlign: "left", color:'#9E9E9E'}}>
                                                                Product
                                                            </Grid>
                                                            <Grid item xs={2} style={{textAlign: "center", color:'#9E9E9E'}}>
                                                                Qty
                                                            </Grid>
                                                            <Grid item xs={2} style={{textAlign: "center", color:'#9E9E9E'}}>
                                                                Amount
                                                            </Grid>
                                                            <Grid item xs={2} style={{textAlign: "right", color:'#9E9E9E'}}>***</Grid>
                                                        </Grid>
                                                        <Grid container item  style={row.type !== "Chrg" ? {display:'flex', justifyContent:'center'}: {}}>
                                                            <Grid item xs={row.type !== "Chrg" ? 10 : 12} >
                                                            {row.products.map((product, index) => (
                                                                <Grid  container key={index + 1} style={{display:'flex', justifyContent:'center',alignItems:'center'}} spacing={2} >
                                                                    { Object.values(product).map((value, index) =>
                                                                            index === 0 ? null :
                                                                            <Grid  item xs={row.type !== "Chrg" ? (index > 1 ? 3: 6) : (index > 1? 2 : 6)} key={index + 1}
                                                                                style={index === 1 ? {textAlign: "left"} : {textAlign: "right"}} >
                                                                                {value} 
                                                                            </Grid>
                                                                    )}
                                                                    {row.type === "Chrg" &&
                                                                        (enableDeleteSales() ? 
                                                                        <Grid item xs={2} className={`${classes.tables.cell} align-right`}>
                                                                            <IconButton variant="contained" style={{padding: 0}}
                                                                                        className={`${classes.buttons.actionButton} delete`}
                                                                                        onClick={() => dispatch(handleDynamicDialog(props.entity + "account", product.id))}>
                                                                                <DeleteIcon/>
                                                                            </IconButton>
                                                                        </Grid> : 
                                                                        <Grid item xs={2} className={`${classes.tables.cell} align-center`}
                                                                            style={{display:'flex', justifyContent:'flex-end',alignItems:'center'}} >
                                                                            <IconButton variant="contained" disabled style={{padding: 0}}
                                                                                        className={`${classes.buttons.actionButton} delete`}>
                                                                                <DeleteIcon
                                                                                    className={classes.colors.colorDisabled}/>
                                                                            </IconButton>
                                                                        </Grid> )
                                                                    }
                                                                </Grid>
                                                            ))}
                                                            </Grid>
                                                            {
                                                                row.type !== "Chrg" &&
                                                                ( enableDeleteSales() ?
                                                                    <Grid item xs={2} style={{display:'flex', justifyContent:'flex-end',alignItems:'center'}}
                                                                        className={`${classes.tables.cell} align-center `} >
                                                                        <IconButton variant="contained" style={{padding: 0}}
                                                                                    className={`${classes.buttons.actionButton} delete`}
                                                                                    onClick={() => dispatch(handleDynamicDialog(props.entity, {
                                                                                        id: row.saleId,
                                                                                        key: props.entity
                                                                                    }))}>
                                                                            <DeleteIcon/>
                                                                        </IconButton>
                                                                    </Grid>
                                                                    : <Grid item xs={2} className={`${classes.tables.cell} align-center`}
                                                                        style={{display:'flex', justifyContent:'flex-end',alignItems:'center'}} >
                                                                        <IconButton variant="contained" disabled style={{padding: 0}}
                                                                                    className={`${classes.buttons.actionButton} delete`}>
                                                                            <DeleteIcon
                                                                                className={classes.colors.colorDisabled}/>
                                                                        </IconButton>
                                                                    </Grid> )
                                                            }
                                                        </Grid>
                                                </Grid>                                            
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    )})) :  <NullMessage message={"There are no sales"} height={"10em"}/>
                                }
                    </Hidden>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[0, 10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            <DynamicDialog
                title={"Are you sure?"}
                keyValue={props.entity + "account"}
                msg={"Do you want to delete this sale?"}
                type={"delete"}
                handleFunction={props.removeConsumption}
            />
            <DynamicDialog
                title={"Are you sure?"}
                keyValue={props.entity}
                msg={"Do you want to delete this sale?. If you doit, generate a credit note"}
                type={"delete"}
                handleFunction={props.deleteSalesHistory}/>
        </div>
    );
};
const mapStateToProps = (state) => ({
    permissionsList: state.auth.permissionsList,
    selectedSaleHistoryResponse: state.messages.response.id,
    animation: validateResponseApi(state.messages.response, "delete-sale"),
    animationAccount: validateResponseApi(state.messages.response, "delete-account-sale"),

});

export default connect(mapStateToProps, {deleteSalesHistory, removeConsumption})(withRouter(HistoryTable))