import * as api_settings from "../../api.calls";
import {CREATE_CATEGORY, UPDATE_CATEGORY} from "../../actions/actionsTypes";
import {verifyToken} from "../../../common/apiCalls";

export const validate = values => {
    const errors = {}
    if (!values.name) {
        errors.name = 'Required'
    }
    return errors
}

export const submitCategoriesForm = async (values, dispatch, {reset, ...rest}) => {
    try {
        await verifyToken();
        const categoryRequest = !values.id ? api_settings.postCategories : api_settings.putCategories
        const response = await categoryRequest({
            categoryData: values,
        })
        if (response.status === 201) {
            dispatch({
                type: CREATE_CATEGORY,
                payload: response.data,
            })
            reset()
        }
        if (response.status === 200) {
            dispatch({
                type: UPDATE_CATEGORY,
                payload: response.data
            })
        }
    } catch (e) {
    }
}