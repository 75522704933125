import React from "react";
import {connect} from "react-redux";
import {Link, useRouteMatch} from 'react-router-dom';

import {editSerial} from "../../actions/sync";
// CSS
import Classes from '../../../../styles';
// COMPONENTS
import TableConfig from '../utils/TableConfig';
// MATERIAL UI
import {Button, Grid} from '@material-ui/core';
import {serialList} from "../../selector/billing.selector";
import {serials} from "../../permissionsConstants";

const SerialsListView = ({serialList, permissionsList, editSerial}) => {
    const classes = Classes();
    let {path} = useRouteMatch();
    const columns = ['Id', 'Location', 'Document', 'Serials', 'Correlative', 'Options'];
    const permissions = Object.values(permissionsList);
    const canManageSeriesAndCorrelatives = permissions.includes(serials) || permissions.includes("superuser");

    return (
        <Grid container item>
            <Grid item xs={12} sm={10} lg={8}>
                {canManageSeriesAndCorrelatives ?
                    <Link to={`${path}series_correlatives`}
                          className={`general-nav- ${classes.nav.link}`}>
                        <Button variant="contained" style={{marginBottom: '0.8em '}}
                                fullWidth
                                color="secondary"
                        >Add serial number</Button>
                    </Link>
                    :
                    <span style={{cursor:'not-allowed'}}>
                    <Button variant="contained" style={{marginBottom: '0.8em ',pointerEvents: !canManageSeriesAndCorrelatives ? "none" : "auto"}}
                            fullWidth
                            color="secondary"
                            disabled
                    >Add serial number</Button></span>
                }
            </Grid>
            <Grid item xs={12} sm={10} lg={8}>
                <TableConfig
                    edit={editSerial}
                    entries={serialList}
                    canManage={canManageSeriesAndCorrelatives}
                    columns={columns}
                    link={`${path}series_correlatives`}
                    entity={"serial & correlative numbers"}
                />
            </Grid>
        </Grid>
    )
};

const mapStateToProps = state => ({
    serialList: serialList(state),
    permissionsList: state.auth.permissionsList
});

export default connect(mapStateToProps, {editSerial})(SerialsListView)

