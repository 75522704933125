import React from 'react';
import errorPage404 from "../../../../assets/errorPage404.svg";
import ErrorPageDisplay from "./errorPageDisplay";

const page404 = () => {
    return (
        <ErrorPageDisplay errorName="Page not found" errorDisplay={errorPage404}/>
    )
};

export default page404