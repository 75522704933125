import axios from 'axios';
import {
    getRequestInfo,
    baseGetAPIRequest,
} from '../common/utils';
import {
    CATEGORIES_URL,
    PRODUCTS_SETTINGS_URL,
    GET_OUTLETS_URL,
    UPDATE_ALL_PRODUCTS_URL
} from './constants.api.urls';
import {PRODUCTS_URL} from "../pos/apiUrlsConstants";

/* CATEGORIES */

export function requestCategories() {
    return baseGetAPIRequest(CATEGORIES_URL)
}

export const postCategory = (newCategory) => {
    const token = localStorage.getItem('JWT-access')
    const {apiUrl, headers} = getRequestInfo(CATEGORIES_URL)
    headers['Authorization'] = 'Bearer ' + token
    // pasar objeto category que debe enviar con la forma que recibirá el api
    return axios.post(apiUrl, {name: newCategory}, headers)
}

export function updateCategory(categoryId, categoryName) {
    const updateCategoryUrlWithId = CATEGORIES_URL + categoryId
    const token = localStorage.getItem('JWT-access')
    const {apiUrl, headers} = getRequestInfo(updateCategoryUrlWithId)
    headers['Authorization'] = 'Bearer ' + token
    return axios.put(apiUrl, {categoryName}, headers)
}

export function deleteCategory(categoryId) {
    const updateCategoryUrlWithId = CATEGORIES_URL + categoryId
    const token = localStorage.getItem('JWT-access')
    const {apiUrl, headers} = getRequestInfo(updateCategoryUrlWithId)
    headers['Authorization'] = 'Bearer ' + token
    return axios.delete(apiUrl, headers)
}

/* PRODUCTS */

export function requestProducts() {
    return baseGetAPIRequest(PRODUCTS_SETTINGS_URL);
}

export const postProducts = (objProduct) => {
    const token = localStorage.getItem('JWT-access')
    const {apiUrl, headers} = getRequestInfo(PRODUCTS_URL)
    headers['Authorization'] = 'Bearer ' + token
    return axios.post(apiUrl, {objProduct}, headers)
}

export function updateProducts(productId, objProduct) {
    const updateProductUrlWithId = PRODUCTS_URL + productId
    const token = localStorage.getItem('JWT-access')
    const {apiUrl, headers} = getRequestInfo(updateProductUrlWithId)
    headers['Authorization'] = 'Bearer ' + token
    return axios.put(apiUrl, {objProduct}, headers)
}

export function updateTotalProducts(objProduct) {
    const updateProductUrlWithId = UPDATE_ALL_PRODUCTS_URL
    const token = localStorage.getItem('JWT-access')
    const {apiUrl, headers} = getRequestInfo(updateProductUrlWithId)
    headers['Authorization'] = 'Bearer ' + token
    return axios.put(apiUrl, {objProduct}, headers)
}

export function deleteProducts(productId) {
    const updateProductUrlWithId = PRODUCTS_URL + productId
    const token = localStorage.getItem('JWT-access')
    const {apiUrl, headers} = getRequestInfo(updateProductUrlWithId)
    headers['Authorization'] = 'Bearer ' + token
    return axios.delete(apiUrl, headers)
}

export const requestOutlets = () => {
    return baseGetAPIRequest(GET_OUTLETS_URL)
}
