import React, {useEffect, useState} from 'react';
// CSS
import Classes from '../../../../styles/'
// MATERIAL UI
import {makeStyles} from '@material-ui/core/styles';
import {
    Button,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@material-ui/core';

import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {changeDisableCloseShift} from "../../redux/actions";
import {loadCloseBalance} from "../../redux/asyncActions";
import {loadBalanceTransfers} from "../../../common/actions/asyncActions";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 100,
    },
    btnCloseshift: {
        backgroundColor: '#ea3788',
        color: theme.palette.common.white,
        '& .MuiButton-contained:hover': {
            backgroundColor: '#ea3788'
        },
        '&.MuiButton-root:hover': {
            backgroundColor: '#ea3788'
        },

    },
    thPadding: {
        '& .MuiTableCell-root': {
            padding: '0.5em!important'

        }
    },
    borderLogout: {
        borderColor: theme.palette.info.main,
        color: theme.palette.info.main
    }
}));

export default function CloseShift({
                                       closeShift, transfers, sendAndCloseShift, exchangeRate
                                   }) {
    const useclasses = useStyles();
    const history = useHistory();
    const classes = Classes();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadCloseBalance());
        dispatch(loadBalanceTransfers());
    }, []);

    const pendingTransfers = Object.values(transfers).filter(transfer => transfer.status === "PENDING");
    const acceptedTransfers = Object.values(transfers).filter(transfer => transfer.status === "ACCEPTED");

    let acceptedTransferAmount = 0;
    let pendingTransferAmount = 0;
    if (pendingTransfers.length > 0) {
        pendingTransferAmount = pendingTransfers.reduce(function (acc, val) {
            return acc + val.amount
        }, 0)
    }
    if (acceptedTransfers.length > 0) {
        acceptedTransferAmount = acceptedTransfers.reduce(function (acc, val) {
            return acc + val.amount
        }, 0)
    }

    const [primaryActualShiftDrop, setPrimaryActualShiftDrop] = useState(closeShift.primary.actualShiftDrop);
    const [primaryExpectedShiftDrop, setPrimaryExpectedShiftDrop] = useState(closeShift.primary.expectedShiftDrop);
    const [primaryCashCardDeposit, setPrimaryCashCardDeposit] = useState(closeShift.primary.cashCardDeposit);
    const [primaryAdjustment, setPrimaryAdjustment] = useState(closeShift.primary.adjustment);
    const [primaryCashDrop, setPrimaryCashDrop] = useState(closeShift.primary.cashDrop);

    const [secondaryActualShiftDrop, setSecondaryActualShiftDrop] = useState(closeShift.secondary.actualShiftDrop);
    const [secondaryExpectedShiftDrop, setSecondaryExpectedShiftDrop] = useState(closeShift.secondary.expectedShiftDrop);
    const [secondaryCashCardDeposit, setSecondaryCashCardDeposit] = useState(closeShift.secondary.cashCardDeposit);
    const [secondaryAdjustment, setSecondaryAdjustment] = useState(closeShift.secondary.adjustment);
    const [secondaryCashDrop, setSecondaryCashDrop] = useState(closeShift.secondary.cashDrop);

    const validateBtnDisable = () => {
        const validPrimaryActualShiftDrop = primaryActualShiftDrop >= 0;
        const validSecondaryActualShiftDrop = secondaryActualShiftDrop >= 0;
        const validPrimaryCashCardDeposit = primaryCashCardDeposit >= 0;
        const validSecondaryCashCardDeposit = secondaryCashCardDeposit >= 0;
        if (validPrimaryActualShiftDrop && validSecondaryActualShiftDrop && validPrimaryCashCardDeposit && validSecondaryCashCardDeposit) {
            dispatch(changeDisableCloseShift(false));
        } else {
            dispatch(changeDisableCloseShift(true));
        }

    };

    const handleChangePrimaryAdjustment = (event) => {
        const newPrimaryActualShiftDrop = event.target.value;
        setPrimaryActualShiftDrop(newPrimaryActualShiftDrop);
        setPrimaryAdjustment(newPrimaryActualShiftDrop - primaryExpectedShiftDrop);
        setPrimaryCashCardDeposit(newPrimaryActualShiftDrop - primaryCashDrop);
        if (newPrimaryActualShiftDrop - primaryCashDrop >= 0 && newPrimaryActualShiftDrop >= 0 && secondaryAdjustment >= 0) {
            validateBtnDisable()
        } else {
            dispatch(changeDisableCloseShift(true));
        }
    };

    const handleChangeSecondaryAdjustment = (event) => {
        const newSecondaryActualShiftDrop = event.target.value;
        setSecondaryActualShiftDrop(newSecondaryActualShiftDrop);
        setSecondaryAdjustment(newSecondaryActualShiftDrop - secondaryExpectedShiftDrop);
        setSecondaryCashCardDeposit(newSecondaryActualShiftDrop - secondaryCashDrop);
        if (newSecondaryActualShiftDrop - secondaryCashDrop >= 0 && newSecondaryActualShiftDrop >= 0 && primaryAdjustment >= 0) {
            validateBtnDisable()
        } else {
            dispatch(changeDisableCloseShift(true));
        }
    };

    const handleChangePrimaryCashCardDeposit = (event) => {
        const newPrimaryCashDrop = event.target.value;
        setPrimaryCashDrop(newPrimaryCashDrop);
        setPrimaryCashCardDeposit(primaryActualShiftDrop - newPrimaryCashDrop);
        if (primaryActualShiftDrop - newPrimaryCashDrop >= 0 && primaryActualShiftDrop >= 0) {
            validateBtnDisable()
        } else {
            dispatch(changeDisableCloseShift(true))
        }
    };

    const handleChangeSecondaryCashCardDeposit = (event) => {
        const newSecondaryCashDrop = event.target.value;
        setSecondaryCashDrop(newSecondaryCashDrop);
        setSecondaryCashCardDeposit(secondaryActualShiftDrop - newSecondaryCashDrop);
        if (secondaryActualShiftDrop - newSecondaryCashDrop >= 0 && secondaryActualShiftDrop >= 0) {
            dispatch(changeDisableCloseShift(false))
        } else {
            dispatch(changeDisableCloseShift(true))
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TableContainer component={Paper} className={`${classes.styles.shadow1} ${classes.tables.greyTable}`}>
                    <Table className={useclasses.table} aria-label="simple table">
                        <TableHead>
                            <TableRow className={useclasses.thPadding}>
                                <TableCell>Description</TableCell>
                                <TableCell align="right" colSpan={exchangeRate ? 2 : 1}>Information</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow className={useclasses.thPadding}>
                                <TableCell component="th" scope="row">
                                    Currency
                                </TableCell>
                                <TableCell align="right">{closeShift.primary.currency}</TableCell>
                                <TableCell align="right"
                                           style={!exchangeRate ? {display: "none"} : {}}>{closeShift.secondary.currency}</TableCell>
                            </TableRow>
                            <TableRow className={useclasses.thPadding}>
                                <TableCell component="th" scope="row">
                                    Opening Balance
                                </TableCell>
                                <TableCell align="right">{closeShift.primary.openingBalance.toFixed(2)}</TableCell>
                                <TableCell align="right"
                                           style={!exchangeRate ? {display: "none"} : {}}>{closeShift.secondary.openingBalance.toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow className={useclasses.thPadding}>
                                <TableCell component="th" scope="row">
                                    Opening adjustment
                                </TableCell>
                                <TableCell align="right">{closeShift.primary.openingAdjustment.toFixed(2)}</TableCell>
                                <TableCell align="right"
                                           style={!exchangeRate ? {display: "none"} : {}}>{closeShift.secondary.openingAdjustment.toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow className={useclasses.thPadding}>
                                <TableCell component="th" scope="row">
                                    Total incomes
                                </TableCell>
                                <TableCell align="right">{closeShift.primary.incomeTotal.toFixed(2)}</TableCell>
                                <TableCell align="right"
                                           style={!exchangeRate ? {display: "none"} : {}}>{closeShift.secondary.incomeTotal.toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow className={useclasses.thPadding}>
                                <TableCell component="th" scope="row">
                                    Cash incomes
                                </TableCell>
                                <TableCell align="right">{closeShift.primary.incomeCash.toFixed(2)}</TableCell>
                                <TableCell align="right"
                                           style={!exchangeRate ? {display: "none"} : {}}>{closeShift.secondary.incomeCash.toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow className={useclasses.thPadding}>
                                <TableCell component="th" scope="row">
                                    Card incomes
                                </TableCell>
                                <TableCell align="right">{closeShift.primary.incomeCard.toFixed(2)}</TableCell>
                                <TableCell align="right"
                                           style={!exchangeRate ? {display: "none"} : {}}>{closeShift.secondary.incomeCard.toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow className={useclasses.thPadding}>
                                <TableCell component="th" scope="row">
                                    E-wallet incomes
                                </TableCell>
                                <TableCell align="right">{closeShift.primary.incomeEWallet.toFixed(2)}</TableCell>
                                <TableCell align="right"
                                           style={!exchangeRate ? {display: "none"} : {}}>{closeShift.secondary.incomeEWallet.toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow className={useclasses.thPadding}>
                                <TableCell component="th" scope="row">
                                    Bank incomes
                                </TableCell>
                                <TableCell align="right">{closeShift.primary.incomeBank.toFixed(2)}</TableCell>
                                <TableCell align="right"
                                           style={!exchangeRate ? {display: "none"} : {}}>{closeShift.secondary.incomeBank.toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow className={useclasses.thPadding}>
                                <TableCell component="th" scope="row">
                                    Total balance expected
                                </TableCell>
                                <TableCell align="right">{primaryExpectedShiftDrop.toFixed(2)}</TableCell>
                                <TableCell align="right"
                                           style={!exchangeRate ? {display: "none"} : {}}>{secondaryExpectedShiftDrop.toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow className={useclasses.thPadding}>
                                <TableCell component="th" scope="row">
                                    Total pending transfer
                                </TableCell>
                                <TableCell align="right">{pendingTransferAmount}</TableCell>
                                <TableCell align="right"
                                           style={!exchangeRate ? {display: "none"} : {}}>{pendingTransferAmount}</TableCell>

                            </TableRow>
                            <TableRow className={useclasses.thPadding}>
                                <TableCell component="th" scope="row">
                                    Total accepted transfer
                                </TableCell>
                                <TableCell align="right">{acceptedTransferAmount}</TableCell>
                                <TableCell align="right"
                                           style={!exchangeRate ? {display: "none"} : {}}>{acceptedTransferAmount}</TableCell>
                            </TableRow>
                            <TableRow className={useclasses.thPadding}>
                                <TableCell component="th" scope="row">
                                    Total cash balance
                                </TableCell>
                                <TableCell align="right">
                                    <TextField style={{width: '6em'}}
                                               id="outlined-basic"
                                               value={primaryActualShiftDrop}
                                               type="number"
                                               inputProps={{min: 0, step: 1}}
                                               className={classes.forms.textField}
                                               onChange={handleChangePrimaryAdjustment}
                                    />
                                </TableCell>
                                <TableCell align="right" style={!exchangeRate ? {display: "none"} : {}}>
                                    <TextField style={{width: '6em'}}
                                               id="outlined-basic"
                                               value={secondaryActualShiftDrop}
                                               type="number"
                                               inputProps={{min: 0, step: 1}}
                                               className={classes.forms.textField}
                                               onChange={handleChangeSecondaryAdjustment}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow className={useclasses.thPadding}>
                                <TableCell component="th" scope="row">
                                    Balance adjustment
                                </TableCell>
                                <TableCell align="right"
                                           style={{color: primaryAdjustment < 0 ? "red" : null}}>
                                    {primaryAdjustment.toFixed(2)}</TableCell>
                                <TableCell align="right"
                                           style={{
                                               color: secondaryAdjustment < 0 ? "red" : null,
                                               display: exchangeRate ? "" : "none"
                                           }}>
                                    {secondaryAdjustment.toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow className={useclasses.thPadding}>
                                <TableCell component="th" scope="row">
                                    Cash drop (next shift)
                                </TableCell>
                                <TableCell align="right">
                                    <TextField style={{width: '6em'}}
                                               id="outlined-basic"
                                               value={primaryCashDrop}
                                               type="number"
                                               inputProps={{min: 0, step: 1}}
                                               className={classes.forms.textField}
                                               onChange={handleChangePrimaryCashCardDeposit}
                                    />
                                </TableCell>
                                <TableCell align="right" style={!exchangeRate ? {display: "none"} : {}}>
                                    <TextField style={{width: '6em'}}
                                               id="outlined-basic"
                                               value={secondaryCashDrop}
                                               type="number"
                                               inputProps={{min: 0, step: 1}}
                                               className={classes.forms.textField}
                                               onChange={handleChangeSecondaryCashCardDeposit}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow className={useclasses.thPadding}>
                                <TableCell component="th" scope="row">
                                    Total withdrawal
                                </TableCell>
                                <TableCell align="right" style={{color: primaryCashCardDeposit < 0 ? "red" : null}}>
                                    {primaryCashCardDeposit.toFixed(2)}</TableCell>
                                <TableCell align="right" style={{
                                    color: secondaryCashCardDeposit < 0 ? "red" : null,
                                    display: exchangeRate ? "" : "none"
                                }}
                                >
                                    {secondaryCashCardDeposit.toFixed(2)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12}>
                {/*Missing transfer validation only temporary*/}
                <Button variant="contained" disabled={closeShift.btnSendCloseShift}
                        className={useclasses.btnCloseshift}
                        onClick={() => sendAndCloseShift(primaryCashDrop, primaryCashCardDeposit, secondaryCashDrop, secondaryCashCardDeposit, history)}
                        style={{marginRight: '1em'}}
                >
                    CLOSE SHIFT & Logout
                </Button>
            </Grid>
        </Grid>

    );
}