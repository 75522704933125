import React, {useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {Field, getFormInitialValues, reduxForm} from 'redux-form';
import {handleViewPaymentPanel} from "../../../common/actions/actions"
import {DEFAULT, POS_ORIGIN, RUMI_ORIGIN} from "../../../common/commonConstants"
import {accounts} from "../../../rumi-settings/permissionsConstants";
// CSS
import Classes from '../../../../styles';
// COMPONENTS
import InfoList from '../utils/InfoList';
// MATERIAL UI
import {Box, Button, FormControl, FormControlLabel, Grid, Radio, Typography} from '@material-ui/core';
import {radioButton, renderTextField} from "../../../common/components/utils/ReduxFromMaterialUI";
import {submitAreasForm, validate} from "../../forms/submit/area.submit";
import LockIcon from '@material-ui/icons/LockOutlined';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';

let AreaForm = (props) => {
    const classes = Classes();
    const dispatch = useDispatch();
    const origin = localStorage.getItem('origin');
    const {handleSubmit, pristine, reset, submitting, initialValues} = props;
    const [radioBtn, setActiveRadioBtn] = React.useState(false);
    const permissions = Object.values(props.permissionsList);
    const canSeeSettingsAccounts = permissions.includes(accounts) || permissions.includes("superuser");

    const handleCancel = () => {
        reset();
        props.history.goBack()
    };

    const activeRadioBtn = (value) => {
        setActiveRadioBtn(value)
    }

    useEffect(() => {
        dispatch(handleViewPaymentPanel(false))
    }, []);

    return (
        <Grid container item xs={12} spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h1" className={classes.typography.pageTitles}>
                    <Box display="inline" color="text.secondary">
                        Settings /
                    </Box>
                    <Link to={`../general`} className={`general-nav- ${classes.nav.link}`}>
                        <Box display="inline" color="text.secondary">&nbsp;General /</Box>
                    </Link>
                    &nbsp;Locations
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={2}>
                            <InfoList title={"Description"}
                                      description={"This business location will appear when the users log on an associated app to select a corresponding location according to their position."}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={6}>
                            <FormControl
                                className={`general-forms- ${classes.forms.formMargin} general-forms- ${classes.forms.fullFormWidth}`}>
                                <Field
                                    id="standard-input" label="Location *" name="name" component={renderTextField}
                                    className={`${classes.forms.textFieldBox} ${classes.forms.inputColor} `}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                            {
                                initialValues !== undefined ?
                                    initialValues.id === DEFAULT &&
                                    <Box fontStyle="oblique" borderRadius={5} color="text.secondary" p={2}
                                         style={{border: "1px solid #5BE576"}}>
                                        <Typography variant="body1">
                                            You cannot edit the App module because it is a default location
                                        </Typography>
                                    </Box>
                                    : null
                            }
                            {
                                initialValues !== undefined && initialValues.groupCount > 0 && initialValues.id !== DEFAULT &&
                                <Box fontStyle="oblique" borderRadius={5} color="text.secondary" p={2}
                                     style={{border: "1px solid #F5BC00"}}>

                                    <Typography variant="body1">
                                        <Button variant="contained" onClick={activeRadioBtn}
                                                style={{marginRight: 10, background: "#F5BC00", color: "#ffffff"}}>Change
                                            App </Button>
                                        If you want to change app, all group users from this location will move to the
                                        new app assigned.
                                        To check this go to
                                        {
                                            canSeeSettingsAccounts ?
                                                <Link style={{color: "#5BE576", fontWeight: 'bold'}}
                                                      to={`/${origin}/settings/accounts`}
                                                      className={`general-nav- ${classes.nav.link}`}> settings/accounts/groups </Link>
                                                : <strong> settings/accounts/groups </strong>
                                        }
                                        and add the missing permissions.
                                    </Typography>
                                </Box>
                            }
                            <br/>
                            <FormControl>
                                {
                                    initialValues !== undefined &&
                                    initialValues.groupCount > 0 ?
                                        (initialValues.id === DEFAULT ?
                                                <Field label={"App *"}
                                                       name="origin" component={radioButton}
                                                       style={{cursor: 'not-allowed'}}>
                                                    <FormControlLabel
                                                        style={{pointerEvents: 'none', filter: 'opacity(0.5)'}}
                                                        value={RUMI_ORIGIN} control={<Radio color="secondary"/>}
                                                        label="Rumi Billing"/>
                                                    <FormControlLabel
                                                        style={{pointerEvents: 'none', filter: 'opacity(0.5)'}}
                                                        value={POS_ORIGIN} control={<Radio color="secondary"/>}
                                                        label="Rumi POS"/>
                                                </Field>
                                                : (radioBtn ?
                                                    <Field label={<Box display="flex" justifyContent="center"
                                                                       alignItems="center"><span>App * </span><LockOpenIcon
                                                        style={{fontSize: 27, margin: "0 10px"}}
                                                        color="secondary"/></Box>}
                                                           name="origin" component={radioButton}>
                                                        <FormControlLabel style={{marginTop: 10}} value={RUMI_ORIGIN}
                                                                          control={<Radio color="secondary"/>}
                                                                          label="Rumi Billing"/>
                                                        <FormControlLabel value={POS_ORIGIN}
                                                                          control={<Radio color="secondary"/>}
                                                                          label="Rumi POS"/>
                                                    </Field> :
                                                    <Field label={<Box display="flex" justifyContent="center"
                                                                       alignItems="center"><span>App * </span> <LockIcon
                                                        style={{fontSize: 27, margin: "0 10px"}}/></Box>}
                                                           name="origin" component={radioButton}
                                                           style={{cursor: 'not-allowed'}}
                                                    >
                                                        <FormControlLabel style={{
                                                            pointerEvents: 'none',
                                                            filter: 'opacity(0.5)',
                                                            marginTop: 10
                                                        }} value={RUMI_ORIGIN} control={<Radio color="secondary"/>}
                                                                          label="Rumi Billing"/>
                                                        <FormControlLabel
                                                            style={{pointerEvents: 'none', filter: 'opacity(0.5)'}}
                                                            value={POS_ORIGIN} control={<Radio color="secondary"/>}
                                                            label="Rumi POS"/>
                                                    </Field>)
                                        )
                                        :
                                        <Field label={"App *"}
                                               name="origin" component={radioButton}>
                                            <FormControlLabel value={RUMI_ORIGIN} control={<Radio color="secondary"/>}
                                                              label="Rumi Billing"/>
                                            <FormControlLabel value={POS_ORIGIN} control={<Radio color="secondary"/>}
                                                              label="Rumi POS"/>
                                        </Field>

                                }
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={2}/>
                        <Grid item xs={12} lg={10}>
                            <Button type="submit" variant="contained" color="primary"
                                    style={{margin: '0 0.8em 0 0', width: '100px'}}
                                    disabled={pristine || submitting}>
                                Save
                            </Button>
                            <Button type="button" variant="outlined" color="primary"
                                    style={{margin: '0 0.8em 0 0', width: '100px'}}
                                    onClick={handleCancel}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </form>

            </Grid>
        </Grid>
    );
};

AreaForm = reduxForm({
    form: 'area',
    onSubmit: submitAreasForm,
    validate
})(AreaForm);

const mapStateToProps = state => {
    return {
        initialValues: getFormInitialValues('area')(state),
        permissionsList: state.auth.permissionsList,
    }
};

export default connect(mapStateToProps)(AreaForm)