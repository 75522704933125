import * as api_settings from "../../api.calls";

import {CREATE_PRODUCT, UPDATE_PRODUCT} from "../../actions/actionsTypes";
import {verifyToken} from "../../../common/apiCalls";
import {getCreatedProduct} from "../../actions/store/sync";

export const validate = values => {
    const errors = {}
    if (!values.name) {
        errors.name = 'Required'
    }
    if (!values.outlet) {
        errors.outlet = 'Required'
    }
    if (!values.category) {
        errors.category = 'Required'
    }
    if (!values.price) {
        errors.price = 'Required'
    }
   
    return errors
}



export const submitProductsForm = async (values, dispatch, {reset, ...rest}) =>  {
    try {
        await verifyToken();
        const productRequest = !values.id ? api_settings.postProducts : api_settings.putProducts
        const  isActivedOutlet = values.selectOutlet[values.selectOutlet.length -1]
        
        if(isActivedOutlet !== null){
            values.taxes = []
            values.taxes = values.taxesByOutlets
        }

        const response = await productRequest({
            productData: values,
        })

        if (response.status === 201) {
            dispatch({
                type: CREATE_PRODUCT,
                payload: response.data,
            })
            dispatch(getCreatedProduct("PRE-CREATE"))
            setTimeout(function () {
                dispatch(getCreatedProduct("CREATE"))
            }, 2000);
            reset()
        }
        if (response.status === 200) {
            dispatch({
                type: UPDATE_PRODUCT,
                payload: response.data
            })
            dispatch(getCreatedProduct("UPDATE"))
        }
        getCreatedProduct()
        window.history.back()
    } catch (e) {
        dispatch(getCreatedProduct(""))
    }
}