import {connect} from 'react-redux'
import OrderList from "../../components/payment_panel/OrderList";
import { getCartProducts } from "../../selector";
import { addProductCart , subtractProductCart, removeProductCart , setPaymentType} from "../../redux/actions"

const mapStateToProps = (state) => {
    const products = getCartProducts(state)
    return {
      productsCart:  products,
      optionClientType: state.paymentTypeSelected.optionClientType,
    }
}
export default connect(mapStateToProps, {addProductCart, subtractProductCart, removeProductCart, setPaymentType})(OrderList)