import {SET_CLIENT_CRITERIA, CLEAN_FILTER,SET_INCLUDE_FUTURE_RESERVATIONS
} from "../redux/actionTypes"
import {FILTER_SEARCH_MULTIPLE} from "../../common/actions/actionTypes"

const initialState = {
    criteria: null,
    value: null,
    futureReservations:false,
    showClientList: false
}

const clientCriteria = (state= initialState, action)=>{
    switch (action.type) {
        case SET_CLIENT_CRITERIA:
            return { ...state, ...action.payload, showClientList: true }
        case CLEAN_FILTER:
            return  initialState
        case SET_INCLUDE_FUTURE_RESERVATIONS:
            return {...state,futureReservations: action.value}
        case FILTER_SEARCH_MULTIPLE:
            if(!!action.value === state.showClientList){
                return state
            }else{
                if(!action.value && (state.criteria || state.value)){
                    return state
                }else{
                    return { ...state, showClientList: !!action.value  }
                }
            }
        default:
            return state
    }
}

export default clientCriteria