import React from 'react';
import errorPage500 from "../../../../assets/errorPage500.svg";
import ErrorPageDisplay from "./errorPageDisplay";

const page500 = () => {
    return (
        <ErrorPageDisplay errorName="Server error" errorDisplay={errorPage500}/>
    )
};

export default page500