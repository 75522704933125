import React from "react";
import {Typography} from "@material-ui/core/";
import {connect} from "react-redux"
import {groupSelector} from "../../selector/customer.selector";
import CheckIcon from '@material-ui/icons/CheckCircleOutlined';

import {List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import NullMessage from "../../../common/components/error.pages/NullMessage";

const GroupInformation = ({groupMembers}) => {

    return (
        <div>
            <Typography variant="subtitle1" style={{marginLeft: "1em"}} gutterBottom>
                Members:
            </Typography>
            {
                groupMembers.length ?
                    <List dense component="div" role="list">

                        {groupMembers.map((row, index) => {
                            return (
                                <ListItem key={index + 1} role="listitem">
                                    <ListItemIcon>
                                        <CheckIcon color="primary"/>
                                    </ListItemIcon>
                                    <ListItemText>
                                        {row.name} {row.surname}
                                    </ListItemText>
                                </ListItem>
                            );
                        })}
                        <ListItem/>
                    </List>
                    : <NullMessage message={"There are no members in the group"} height={"10em"}/>
            }
        </div>

    )
};

const mapStateToProps = (state) => {
    return {
        groupMembers: groupSelector(state)
    }
};
export default connect(mapStateToProps)(GroupInformation)
