import React from "react";
import {connect} from "react-redux";
import {Link, useRouteMatch} from 'react-router-dom';

import {editArea, removeArea} from "../../actions/sync";
// CSS
import Classes from '../../../../styles';
// COMPONENTS
// MATERIAL UI
import {Box, Button, Typography} from '@material-ui/core/';

import {areasList} from '../../selector/areas.selector';
import {locations} from "../../permissionsConstants";
import DynamicDialog from "../../../common/components/modals/DynamicDialog"
import AreasTable from "./AreasTable";
import {validateResponseApi} from "../../../common/selectors/utilsSelector"
import {Grid} from "@material-ui/core";

const AreasListView = ({areasList, permissionsList, removeArea, editArea, selectedArea, animation}) => {
    const classes = Classes();
    let {path} = useRouteMatch();
    const columns = ['Id', 'Location', 'App', 'GroupCount', 'Options'];
    const permissions = Object.values(permissionsList);
    const canManageLocations = permissions.includes(locations) || permissions.includes("superuser");

    return (
        <div>
            {
                canManageLocations &&
                <Grid item xs={12} sm={10} md={10} lg={8}>
                    <Box fontStyle="oblique" borderRadius={5} color="text.secondary" p={2} mt={'0.8em'}
                         style={{border: "1px solid #5BE576"}} display="flex" alignItems="left">
                        <Typography variant="body1">
                            Locations that have an assigned group cannot be deleted
                        </Typography>
                    </Box>
                    <br/>
                </Grid>
            }
            <Grid item xs={12} sm={10} md={10} lg={8}>
                {canManageLocations ?
                    <Link to={`${path}areas`} className={`general-nav- ${classes.nav.link}`}>
                        <Button variant="contained" style={{marginBottom: '0.8em'}}
                                fullWidth
                                color="secondary"
                        >
                            Add Location
                        </Button>
                    </Link>
                    :
                    <span style={{cursor: 'not-allowed'}}>
                        <Button variant="contained" style={{marginBottom: '0.8em'}}
                                fullWidth
                                color="secondary"
                                disabled
                        >
                            Add Location
                        </Button>
                    </span>
                }
            </Grid>
            <Grid item xs={12} sm={10} md={10} lg={8}>
                <AreasTable
                    remove={removeArea}
                    edit={editArea}
                    canManage={canManageLocations}
                    entries={areasList}
                    columns={columns}
                    link={`${path}areas`}
                    entity={"location"}
                    selectedItem={selectedArea}
                    animation={animation}
                />
            </Grid>
            <DynamicDialog
                title={"Are you sure?"}
                keyValue="location"
                msg={"Do you want to delete this location?"}
                type={"delete"}
                handleFunction={removeArea}/>

        </div>
    )
};

const mapStateToProps = state => ({
    areasList: areasList(state.areasSettingReducer.areas),
    permissionsList: state.auth.permissionsList,
    selectedArea: state.messages.response.id,
    animation: validateResponseApi(state.messages.response, "delete-area")
});

export default connect(mapStateToProps, {removeArea, editArea})(AreasListView)