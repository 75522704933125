import { connect } from "react-redux";

import FormProduct from '../../components/store/FormProduct';

const mapStateToProps = (state)=> {
    return {
        productToEdit: state.storeReducer.productToEdit,
        outletList: state.storeReducer.outletList,
    }
}

export default  connect(mapStateToProps)(FormProduct)