import React, {useEffect, useState} from 'react';
// MATERIAL UI
import {Box, Container, Grid, Hidden, Select, Tab, Tabs, Tooltip, Typography} from '@material-ui/core/';
import Classes from "../../../styles";
import PropTypes from 'prop-types';
import LineGraph from "../components/dashboardGraphs/lineGraph";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Loader from "../../common/components/utils/Loader";
import PolarGraph from "../components/dashboardGraphs/polarGraph";
import {handleViewPaymentPanel} from "../../common/actions/actions";
import {connect, useDispatch} from "react-redux";
import BarGraph from "../components/dashboardGraphs/barGraph";
import {loadCloseBalance} from "../../pos/redux/asyncActions";
import {requestDashboard, requestDashboardSaleTotalsByType} from "../actions/async";
import {MenuItem, TextField} from "@material-ui/core";
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import CardIcon from '@material-ui/icons/PaymentOutlined';
import StoreIcon from '@material-ui/icons/LocalGroceryStoreOutlined';
import RoomServiceIcon from '@material-ui/icons/RoomServiceOutlined';
import HistoryIcon from '@material-ui/icons/TrendingUp';
import CoinIcon from '../../../assets/icons/coin.svg'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {makeStyles} from "@material-ui/core/styles";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    textConsumption: {
        [theme.breakpoints.down('xs')]: {
            width: 75,
            wordWrap: "break-word"
        }
    }
}));

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize: '1em',
        fontWeight: "410",
    }
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}


const DashboardView = ({
                           daily, weekly, monthly, yearly, receiptsByBalance, salesTotalByType, paymentPanel,
                           primaryOpeningBalance, primaryBalance, primaryTotal,
                           secondaryOpeningBalance, secondaryBalance, secondaryTotal,
                           isBalanceFetch, currencies, isDashboardFetch, exchangeRate
                       }) => {
    const mainApp = localStorage.getItem("origin")
    const classes = Classes();
    const styles = useStyles();
    const tooltipStyles = useStylesBootstrap();
    const [loading, setLoading] = useState(true);
    const [tabValue, setTabValue] = useState(0);
    const mainCurrency = Object.values(currencies).filter(currency => currency.isMain === true);
    const [currencySelected, setCurrencySelected] = useState(mainCurrency[0]);
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };
    const handleTooltipOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (paymentPanel) {
            dispatch(handleViewPaymentPanel(false));
        }
        dispatch(loadCloseBalance());
        dispatch(requestDashboard());
        dispatch(requestDashboardSaleTotalsByType());
        setCurrencySelected(mainCurrency[0])
    }, [isDashboardFetch]);

    if (isDashboardFetch) {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Loader/>
                </Grid>
            </Grid>
        )
    }

    const handleTabChange = (event, newTabValue) => {
        setTabValue(newTabValue)
    };

    const handleChangeCurrency = (event) => {
        const currencyCode = event.target.value;
        const selected = Object.values(currencies).filter(item => item.code === currencyCode);

        setCurrencySelected(selected[0])
    };

    return (
        <Grid container spacing={3} style={{paddingRight: "12px"}}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="h1" className={classes.typography.pageTitles}>
                    Statistics
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={3} direction="row" justify="space-between" alignItems="center">
                    <Grid item xs={6} sm={6} md={(mainApp == "billing" && false) ? 3 : 6}
                          lg={mainApp == "billing" && false ? 3 : 6}>
                        <Box display="flex" flexDirection="row"
                             className={`${classes.containers.dashboardBoxes} ${classes.styles.shadow1}`}>
                            <Box width="30%">
                                <MonetizationOnIcon fontSize="large" color="primary" style={{marginLeft: "6px"}}/>
                            </Box>
                            {
                                exchangeRate ?
                                    <Box width="70%">
                                        <Typography className="boxTitle">Currency</Typography>
                                        <TextField fullWidth select id="statistics-currency"
                                                   value={currencySelected ? currencySelected.code : ""}
                                                   onChange={handleChangeCurrency}>
                                            {
                                                Object.values(currencies).map((currency, index) =>
                                                    <MenuItem value={currency.code} key={index}>
                                                        {currency.name}
                                                    </MenuItem>
                                                )
                                            }
                                        </TextField>
                                    </Box>
                                    :
                                    <Box width="70%">
                                        <Typography className="boxTitle">Currency</Typography>
                                        <Typography className="boxInfo">PEN</Typography>
                                    </Box>
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={mainApp == "billing" || false ? 3 : 6}
                          lg={mainApp == "billing" && false ? 3 : 6}>
                        <Box display="flex" flexDirection="row" style={{cursor: 'pointer'}}
                             className={`${classes.containers.dashboardBoxes} ${classes.styles.shadow1}`}>
                            <Box width="30%">
                                <img src={CoinIcon} alt="" style={{marginLeft: "6px", width: 40, height: 40}}/>
                            </Box>
                            <Hidden mdUp>
                                <ClickAwayListener onClickAway={handleTooltipClose}>
                                    {
                                        exchangeRate ?
                                            <Tooltip arrow classes={tooltipStyles}
                                                     PopperProps={{
                                                         disablePortal: true,
                                                     }}
                                                     onClose={handleTooltipClose}
                                                     open={open}
                                                     disableFocusListener
                                                     disableHoverListener
                                                     disableTouchListener
                                                     title={currencySelected ?
                                                         <Box style={{padding: '1em', width: '10em'}}>
                                                             <Box display="flex">
                                                                 <Box flexGrow={1}
                                                                      color="text.secondary">Opening: </Box>
                                                                 <Box
                                                                     style={{color: 'white'}}>{currencySelected.isMain ? primaryOpeningBalance.toFixed(2) : secondaryOpeningBalance.toFixed(2)}</Box>
                                                             </Box>
                                                             <Box display="flex">
                                                                 <Box flexGrow={1}
                                                                      color="text.secondary">Balance: </Box>
                                                                 <Box
                                                                     style={{color: 'white'}}>{currencySelected.isMain ? primaryBalance.toFixed(2) : secondaryBalance.toFixed(2)}</Box>
                                                             </Box>
                                                         </Box>
                                                         : <Box style={{padding: '1em', width: '10em'}}>
                                                             <Box display="flex">
                                                                 <Box flexGrow={1}
                                                                      color="text.secondary">Opening: </Box>
                                                                 <Box style={{color: 'white'}}>0.00</Box>
                                                             </Box>
                                                             <Box display="flex">
                                                                 <Box flexGrow={1}
                                                                      color="text.secondary">Balance: </Box>
                                                                 <Box style={{color: 'white'}}>0.00</Box>
                                                             </Box>
                                                         </Box>}
                                                     placement="top"
                                            >
                                                <Box width="70%" onClick={handleTooltipOpen}>
                                                    <Typography className="boxTitle">Total</Typography>
                                                    <React.Fragment>
                                                        {
                                                            currencySelected ?
                                                                <Typography className="boxInfo">
                                                                    {
                                                                        currencySelected.isMain ?
                                                                            primaryTotal.toFixed(2)
                                                                            :
                                                                            secondaryTotal.toFixed(2)
                                                                    }
                                                                </Typography>
                                                                :
                                                                <Typography className="boxInfo">0.00</Typography>
                                                        }</React.Fragment>

                                                </Box>
                                            </Tooltip>
                                            :
                                            <Tooltip arrow classes={tooltipStyles}
                                                     PopperProps={{
                                                         disablePortal: true,
                                                     }}
                                                     onClose={handleTooltipClose}
                                                     open={open}
                                                     disableFocusListener
                                                     disableHoverListener
                                                     disableTouchListener
                                                     title={<Box style={{padding: '1em', width: '10em'}}>
                                                         <Box display="flex">
                                                             <Box flexGrow={1} color="text.secondary">Opening: </Box>
                                                             <Box
                                                                 style={{color: 'white'}}>{primaryOpeningBalance.toFixed(2)}</Box>
                                                         </Box>
                                                         <Box display="flex">
                                                             <Box flexGrow={1} color="text.secondary">Balance: </Box>
                                                             <Box
                                                                 style={{color: 'white'}}>{primaryBalance.toFixed(2)}</Box>
                                                         </Box>
                                                     </Box>}
                                                     placement="top">
                                                <Box width="70%" onClick={handleTooltipOpen}>
                                                    <Typography className="boxTitle">Total</Typography>
                                                    <Typography
                                                        className="boxInfo">{primaryTotal.toFixed(2)}</Typography>
                                                </Box>
                                            </Tooltip>
                                    }</ClickAwayListener>
                            </Hidden>
                            <Hidden smDown>
                                {
                                    exchangeRate ?
                                        <BootstrapTooltip
                                            title={currencySelected ?
                                                <Box style={{padding: '1em', width: '10em'}}>
                                                    <Box display="flex">
                                                        <Box flexGrow={1} color="text.secondary">Opening: </Box>
                                                        <Box
                                                            style={{color: 'white'}}>{currencySelected.isMain ? primaryOpeningBalance.toFixed(2) : secondaryOpeningBalance.toFixed(2)}</Box>
                                                    </Box>
                                                    <Box display="flex">
                                                        <Box flexGrow={1} color="text.secondary">Balance: </Box>
                                                        <Box
                                                            style={{color: 'white'}}>{currencySelected.isMain ? primaryBalance.toFixed(2) : secondaryBalance.toFixed(2)}</Box>
                                                    </Box>
                                                </Box>
                                                : <Box style={{padding: '1em', width: '10em'}}>
                                                    <Box display="flex">
                                                        <Box flexGrow={1} color="text.secondary">Opening: </Box>
                                                        <Box style={{color: 'white'}}>0.00</Box>
                                                    </Box>
                                                    <Box display="flex">
                                                        <Box flexGrow={1} color="text.secondary">Balance: </Box>
                                                        <Box style={{color: 'white'}}>0.00</Box>
                                                    </Box>
                                                </Box>}
                                            placement="top">
                                            <Box width="70%">
                                                <Typography className="boxTitle">Total</Typography>
                                                <React.Fragment>
                                                    {
                                                        currencySelected ?
                                                            <Typography className="boxInfo">
                                                                {
                                                                    currencySelected.isMain ?
                                                                        primaryTotal.toFixed(2)
                                                                        :
                                                                        secondaryTotal.toFixed(2)
                                                                }
                                                            </Typography>
                                                            :
                                                            <Typography className="boxInfo">0.00</Typography>
                                                    }</React.Fragment>

                                            </Box>
                                        </BootstrapTooltip>
                                        :
                                        <BootstrapTooltip
                                            title={<Box style={{padding: '1em', width: '10em'}}>
                                                <Box display="flex">
                                                    <Box flexGrow={1} color="text.secondary">Opening: </Box>
                                                    <Box
                                                        style={{color: 'white'}}>{primaryOpeningBalance.toFixed(2)}</Box>
                                                </Box>
                                                <Box display="flex">
                                                    <Box flexGrow={1} color="text.secondary">Balance: </Box>
                                                    <Box style={{color: 'white'}}>{primaryBalance.toFixed(2)}</Box>
                                                </Box>
                                            </Box>}
                                            placement="top">
                                            <Box width="70%">
                                                <Typography className="boxTitle">Total</Typography>
                                                <Typography className="boxInfo">{primaryTotal.toFixed(2)}</Typography>
                                            </Box>
                                        </BootstrapTooltip>
                                }
                            </Hidden>
                        </Box>
                    </Grid>
                    {
                        mainApp == "billing" && false &&
                        <Grid item xs={6} sm={6} md={3} lg={3}>
                            <Box display="flex" flexDirection="row"
                                 className={`${classes.containers.dashboardBoxes} ${classes.styles.shadow1}`}>
                                <Box width="30%">
                                    <RoomServiceIcon fontSize="large" color="disabled" style={{marginLeft: "6px"}}/>
                                </Box>
                                {
                                    exchangeRate ?
                                        <Box width="70%">
                                            <Typography className={`boxTitle  ${styles.textConsumption}`}>Total
                                                consumptions</Typography>
                                            {
                                                currencySelected ?
                                                    <Typography className="boxInfo">
                                                        {
                                                            currencySelected.isMain ? '0.00' : '0.00'
                                                            // primaryOpeningBalance
                                                            // :
                                                            // secondaryOpeningBalance
                                                        }
                                                    </Typography>
                                                    :
                                                    <Typography className="boxInfo">0.00</Typography>
                                            }
                                        </Box>
                                        :
                                        <Box width="70%">
                                            <Typography className={`boxTitle  ${styles.textConsumption}`}>Total
                                                consumptions</Typography>
                                            <Typography className="boxInfo">0.00</Typography>
                                            {/* <Typography className="boxTitle">{primaryOpeningBalance}</Typography> */}
                                        </Box>
                                }
                            </Box>
                        </Grid>
                    }
                    {
                        mainApp == "billing" && false &&
                        <Grid item xs={6} sm={6} md={3} lg={3}>
                            <Box display="flex" flexDirection="row"
                                 className={`${classes.containers.dashboardBoxes} ${classes.styles.shadow1}`}>
                                <Box width="30%">
                                    <StoreIcon fontSize="large" color="disabled" style={{marginLeft: "6px"}}/>
                                </Box>
                                {
                                    exchangeRate ?
                                        <Box width="70%">
                                            <Typography className="boxTitle">Total store</Typography>
                                            {
                                                currencySelected ?
                                                    <Typography className="boxInfo">
                                                        {
                                                            currencySelected.isMain ? "0.00" : "0.00"
                                                            // primaryBalance
                                                            // :
                                                            // secondaryBalance
                                                        }
                                                    </Typography>
                                                    :
                                                    <Typography className="boxInfo">0.00</Typography>
                                            }
                                        </Box>
                                        :
                                        <Box width="70%">
                                            <Typography className="boxTitle">Total store</Typography>
                                            <Typography className="boxInfo">0.00</Typography>
                                            {/* <Typography className="boxTitle">{primaryBalance}</Typography> */}
                                        </Box>
                                }
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems="stretch" spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Box bgcolor="common.white" className={classes.styles.shadow2} display="flex"
                             flexDirection="column"
                             flexGrow="1">
                            <Box style={{padding: "1em"}}><Typography variant="h5"
                                                                      className={classes.typography.dashboardBlockTitle}><HistoryIcon
                                color="primary" style={{marginRight: '1em'}}/>Sales </Typography></Box>
                            <Box display="flex"
                                 style={{alignItems: "center"}}>
                                <Tabs value={tabValue} orientation="horizontal"
                                      variant="fullWidth"
                                      aria-label="Vertical tabs example"
                                      onChange={handleTabChange} aria-label="simple tabs example"
                                >
                                    <Tab label="Daily" {...a11yProps(0)} />
                                    <Tab label="Weekly" {...a11yProps(1)} />
                                    <Tab label="Monthly" {...a11yProps(2)} />
                                    <Tab label="Yearly" {...a11yProps(3)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={tabValue} index={0} className="tabPanelHolder">
                                <LineGraph lineGraphData={daily} label="The information is displayed in hours per day"/>
                            </TabPanel>
                            <TabPanel value={tabValue} index={1} className="tabPanelHolder">
                                <LineGraph lineGraphData={weekly}
                                           label="The information is displayed in days per week"/>
                            </TabPanel>
                            <TabPanel value={tabValue} index={2} className="tabPanelHolder">
                                <LineGraph lineGraphData={monthly}
                                           label="The information is displayed in days per month"/>
                            </TabPanel>
                            <TabPanel value={tabValue} index={3} className="tabPanelHolder">
                                <LineGraph lineGraphData={yearly}
                                           label="The information is displayed in months per year"/>
                            </TabPanel>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Box bgcolor="common.white" className={classes.styles.shadow2} display="flex"
                             flexDirection="column">
                            <Box style={{padding: "1em"}}>
                                <Typography variant="h5" className={classes.typography.dashboardBlockTitle}>
                                    <ReceiptIcon color="primary" style={{marginRight: '1em'}}/>Receipts</Typography>
                            </Box>
                            <Box>
                                <BarGraph barData={receiptsByBalance}/>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Box bgcolor="common.white" className={classes.styles.shadow2}>
                            <Box style={{padding: "1em"}}>
                                <Typography variant="h5" className={classes.typography.dashboardBlockTitle}>
                                    <CardIcon color="primary" style={{marginRight: '1em'}}/>By type</Typography>
                            </Box>
                            <Box pl={1} pr={1}>
                                <PolarGraph polarData={salesTotalByType} currency={currencySelected}/>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        daily: state.dashboardReducer.dailyTransactions,
        weekly: state.dashboardReducer.weeklyTransactions,
        monthly: state.dashboardReducer.monthlyTransactions,
        yearly: state.dashboardReducer.yearlyTransactions,
        receiptsByBalance: state.dashboardReducer.receiptsByBalance,
        salesTotalByType: state.dashboardReducer.salesTotalByType,
        paymentPanel: state.utilsReducer.showPaymentPanel,
        primaryOpeningBalance: state.closeShift.primary.openingBalance,
        primaryBalance: state.closeShift.primary.cashCardDeposit,
        primaryTotal: state.closeShift.primary.actualShiftDrop,
        secondaryOpeningBalance: state.closeShift.secondary.openingBalance,
        secondaryBalance: state.closeShift.secondary.cashCardDeposit,
        secondaryTotal: state.closeShift.secondary.actualShiftDrop,
        isBalanceFetch: state.balance.isBalanceFetch,
        currencies: state.currencies.currencies,
        exchangeRate: state.exchangeRateReducer.exchangeRate
    }
};

export default connect(mapStateToProps)(DashboardView)