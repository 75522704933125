import {DISABLE_CLOSE_SHIFT, LOAD_CLOSE_BALANCE} from "../../redux/actionTypes";

const initialState = {
    primary: {
        currency: null,
        openingBalance: 0,
        openingAdjustment: 0,
        incomeTotal: 0,
        incomeCard: 0,
        incomeCash: 0,
        incomeEWallet: 0,
        incomeBank: 0,
        expectedShiftDrop: 0,
        actualShiftDrop: 0,
        adjustment: 0,
        cashDrop: 0,
        cashCardDeposit: 0,
    },
    secondary: {
        currency: null,
        openingBalance: 0,
        openingAdjustment: 0,
        incomeTotal: 0,
        incomeCard: 0,
        incomeCash: 0,
        incomeEWallet: 0,
        incomeBank: 0,
        expectedShiftDrop: 0,
        actualShiftDrop: 0,
        adjustment: 0,
        cashDrop: 0,
        cashCardDeposit: 0,
    },
    btnSendCloseShift: false,
};

export default function (state = initialState, action) {

    switch (action.type) {
        case DISABLE_CLOSE_SHIFT: {
            return {
                ...state,
                btnSendCloseShift: action.payload,
            }
        }
        case LOAD_CLOSE_BALANCE: {
            const primary = {
                currency: action.value.data.Currency.principalCurrency,
                openingBalance: action.value.data.ClosingBalance.principalCurrency,
                openingAdjustment: action.value.data.OpeningAdjustments.principalCurrency,
                incomeTotal: (action.value.data.IncomeCard.principalCurrency +
                    action.value.data.IncomeCash.principalCurrency +
                    action.value.data.IncomeEWallet.principalCurrency +
                    action.value.data.IncomeBank.principalCurrency),
                incomeCard: action.value.data.IncomeCard.principalCurrency,
                incomeCash: action.value.data.IncomeCash.principalCurrency,
                incomeEWallet: action.value.data.IncomeEWallet.principalCurrency,
                incomeBank: action.value.data.IncomeBank.principalCurrency,
                actualShiftDrop: action.value.data.Expected.principalCurrency,
                cashDrop: action.value.data.ClosingBalance.principalCurrency,
                cashCardDeposit: (action.value.data.Expected.principalCurrency -
                    action.value.data.ClosingBalance.principalCurrency),
                expectedShiftDrop: action.value.data.Expected.principalCurrency,
                adjustment: 0
            };
            const secondary = {
                currency: action.value.data.Currency.secondaryCurrency,
                openingBalance: action.value.data.ClosingBalance.secondaryCurrency,
                openingAdjustment: action.value.data.OpeningAdjustments.secondaryCurrency,
                incomeTotal: (action.value.data.IncomeCard.secondaryCurrency +
                    action.value.data.IncomeCash.secondaryCurrency +
                    action.value.data.IncomeEWallet.secondaryCurrency +
                    action.value.data.IncomeBank.secondaryCurrency),
                incomeCard: action.value.data.IncomeCard.secondaryCurrency,
                incomeCash: action.value.data.IncomeCash.secondaryCurrency,
                incomeEWallet: action.value.data.IncomeEWallet.secondaryCurrency,
                incomeBank: action.value.data.IncomeBank.secondaryCurrency,
                actualShiftDrop: action.value.data.Expected.secondaryCurrency,
                cashDrop: action.value.data.ClosingBalance.secondaryCurrency,
                cashCardDeposit: (action.value.data.Expected.secondaryCurrency -
                    action.value.data.ClosingBalance.secondaryCurrency),
                expectedShiftDrop: action.value.data.Expected.secondaryCurrency,
                adjustment: 0
            };
            return {
                ...state,
                primary: primary,
                secondary: secondary
            }
        }

        default : {
            return {...state}
        }

    }

}