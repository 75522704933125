import {
    LOAD_CASH_REPORT,
    FILTER_CASH_REPORT
} from "../../redux/actionTypes";

const  initialState = {
    cashReport: [],
    dateSelected: new Date()
}

export default  function (state = initialState, action) {

    switch (action.type) {

        case LOAD_CASH_REPORT: {
            return {
                ...state,
                cashReport: action.value
            }
        }

        case FILTER_CASH_REPORT: {
            return {
                ...state,
                dateSelected: action.dateSelected
            }
        }


        default : {
            return {...state}
        }

    }

}