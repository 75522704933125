import React from "react";
// CSS
import Classes from '../../../../styles'
// MATERIAL UI
import {Box, Button, Typography, Grid} from '@material-ui/core';

const ProductPreview = ({product, categories}) => {
    const classes = Classes();
    const category = categories.filter(list => product.category === list.id)
    const currencyCode = localStorage.getItem('currencyCode')
    return (
        <Button variant="outlined"  color="secondary" className={`${classes.styles.shadow2} ${classes.styles.bRadius10} ${classes.typography.textCapitalize} ${classes.containers.productsButtonBlock}`} 
        style={{pointerEvents: "none"}}
        >
            <Box display="flex" flexDirection="column" justifyContent="center">
                <Box display="flex" flexDirection="column">
                    <Typography style={{
                        fontSize: '1em',
                        lineHeight: '1.4em',
                        textTransform: 'capitalize',
                        marginBotton: '4px'
                    }}>{product.name}</Typography>
                </Box>
                <Box color="grey.600" mt={0.5} fontSize={'0.9em'} lineHeight={1.1}>
                    {currencyCode}{product.price}
                </Box>
                <Box color="text.hint" mt={0.2}  fontSize={'0.9em'} className={classes.typography.textCapitalize} >
                    {category[0].name}
                </Box>
            </Box>
        </Button>
    )
};


export default ProductPreview
