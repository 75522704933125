export const GET_ERRORS = 'GET_ERRORS'
export const GET_MESSAGES = 'GET_MESSAGES'
export const CREATE_MESSAGE = 'CREATE_MESSAGE'


/* products */
export const LOAD_PRODUCTS = "LOAD_PRODUCTS"
export const LOAD_PRODUCTS_FAILED = "LOAD_PRODUCTS_FAILED"

/* categories */
export const LOAD_CATEGORIES = "LOAD_CATEGORIES"
export const SET_CATEGORY = "SET_CATEGORY"
export const LOAD_CATEGORIES_FAILED = "LOAD_CATEGORIES_FAILED"
export const FILTER_CATEGORY = "FILTER_CATEGORY"
/* cart */
export const ADD_TO_CART = "ADD_TO_CART"
export const SUBTRACT_TO_CART = "SUBTRACT_TO_CART"
export const REMOVE_TO_CART = "REMOVE_TO_CART"
export const EMPTY_CART = "EMPTY_CART";
export const REMOVE_NOTICE_CART = "REMOVE_NOTICE_CART"

/* ROOMS */
export const LOAD_ROOMS = "LOAD_ROOMS";
export const FETCHING_ROOMS = "FETCHING_ROOMS";

/* CLIENTS */

export const SET_CLIENT_CRITERIA = "SET_CRITERIA"
export const SET_INCLUDE_FUTURE_RESERVATIONS = "SET_INCLUDE_FUTURE_RESERVATIONS"
export const CLEAN_FILTER = "CLEAN_FILTER"
export const SET_CLIENT = "SET_CLIENT"
export const INITIAL_CLIENT = "INITIAL_CLIENT"

/* CONSUMPTIONS */
export const GET_CONSUMPTIONS = "GET_CONSUMPTIONS"

/* GUESTS */
export const GET_GUESTS = "GET_GUESTS"

/* PAYMENT TYPE */
export const SET_PAYMENT_TYPE = "SET_PAYMENT_TYPE"

/* PAYMENT */
export const GET_PAYMENT_TYPES = "GET_PAYMENT_TYPES"
export const SET_PAYMENT = "SET_PAYMENT"

export const GET_PAYMENTS = "SET_PAYMENT"

export const POST_PAYMENT = "GET_PAYMENT"
export const SUCCESS_PAYMENT = "SUCCESS_PAYMENT"
export const VALIDATE_EXTERNAL = "VALIDATE_EXTERNAL"
export const SET_CURRENCY_VALUES = "SET_CURRENCY_VALUES";
export const CLEAR_CURRENCY_VALUES = "CLEAR_CURRENCY_VALUES";
export const SET_TRANSFER_PAYMENT = "SET_TRANSFER_PAYMENT"
export const TOGGLE_TRANSFER_PAYMENT_SECTION = "TOGGLE_TRANSFER_PAYMENT_SECTION"
export const OPEN_PAYMENT_SECTION = "OPEN_PAYMENT_SECTION"
export const ACTIVE_PAY_SECOND_CURRENCY = "ACTIVE_PAY_SECOND_CURRENCY"

/* RESERVATION */
export const LOAD_RESERVATION = "LOAD_RESERVATION";
export const FETCHING_RESERVATIONS = "FETCHING_RESERVATIONS";


/* SYSTEM CLIENTS (staff, Special Client, etc) */

export const LOAD_SYSTEM_CLIENTS = "LOAD_SYSTEM_CLIENTS"

/* BALANCE */
export const OPEN_BALANCE = "OPEN_BALANCE"
export const OPEN_BALANCE_FAILED = "OPEN_BALANCE_FAILED"
export const REQUEST_OPEN_BALANCE = "REQUEST_OPEN_BALANCE"
export const REQUEST_OPEN_BALANCE_FAILED = "REQUEST_OPEN_BALANCE_FAILED"
export const CLOSE_BALANCE = "CLOSE_BALANCE"
export const CLOSE_BALANCE_FAILED = "CLOSE_BALANCE_FAILED"

// REPORTS
export const LOAD_BALANCE_FLOWS_REPORT = "LOAD_BALANCE_FLOWS_REPORT"
export const FILTER_BALANCE_FLOWS_REPORT = "FILTER_BALANCE_FLOWS_REPORT";
export const SEARCH_BALANCE_FLOWS_REPORT = "SEARCH_BALANCE_FLOWS_REPORT";
export const CLEAN_BALANCE_FLOWS_REPORT = "CLEAN_BALANCE_FLOWS_REPORT";

export const LOAD_GUEST_FLOWS_REPORT = "LOAD_GUEST_FLOWS_REPORT"
export const FILTER_GUEST_FLOWS_REPORT = "FILTER_GUEST_FLOWS_REPORT";
export const SEARCH_GUEST_FLOWS_REPORT = "SEARCH_GUEST_FLOWS_REPORT";
export const CLEAN_GUEST_FLOWS_REPORT = "CLEAN_GUEST_FLOWS_REPORT";

export const LOAD_SALES_REPORT = "LOAD_SALES_REPORT"
export const FILTER_SALES_REPORT = "FILTER_SALES_REPORT";
export const SEARCH_SALES_REPORT = "SEARCH_SALES_REPORT";
export const CLEAN_SALES_REPORT = "CLEAN_SALES_REPORT";

export const LOAD_RANKING_REPORT = "LOAD_RANKING_REPORT";
export const FILTER_RANKING_REPORT = "FILTER_RANKING_REPORT";
export const SEARCH_RANKING_REPORT = "SEARCH_RANKING_REPORT";
export const CLEAN_RANKING_REPORT = "CLEAN_RANKING_REPORT";

export const LOAD_INVOICES_REPORT = "LOAD_INVOICES_REPORT";
export const FILTER_INVOICES_REPORT = "FILTER_INVOICES_REPORT";
export const SEARCH_INVOICES_REPORT = "SEARCH_INVOICES_REPORT";
export const CLEAN_INVOICES_REPORT = "CLEAN_INVOICES_REPORT";

export const LOAD_TICKETS_REPORT = "LOAD_TICKETS_REPORT";
export const FILTER_TICKETS_REPORT = "FILTER_TICKETS_REPORT";
export const SEARCH_TICKETS_REPORT = "SEARCH_TICKETS_REPORT";
export const CLEAN_TICKETS_REPORT = "CLEAN_TICKETS_REPORT";

export const LOAD_CREDIT_NOTES_REPORT = "LOAD_CREDIT_NOTES_REPORT";
export const FILTER_CREDIT_NOTES_REPORT = "FILTER_CREDIT_NOTES_REPORT";
export const SEARCH_CREDIT_NOTES_REPORT = "SEARCH_CREDIT_NOTES_REPORT";
export const CLEAN_CREDIT_NOTES_REPORT = "CLEAN_CREDIT_NOTES_REPORT";

export const FILTER_SEARCH_PANEL = "FILTER_SEARCH_PANEL"

// BALANCE
export const LOAD_SALES_BALANCE = "LOAD_SALES_BALANCE"
export const LOAD_BALANCE_STATUS = "LOAD_BALANCE_STATUS"
export const LOAD_SALES_HISTORY = "LOAD_SALES_HISTORY"
export const LOAD_CONSUMPTIONS_HISTORY = "LOAD_CONSUMPTIONS_HISTORY"
export const DELETE_SALES_HISTORY = "DELETE_SALES_HISTORY"
export const LOAD_FAILED_SALES = "LOAD_FAILED_SALES"
export const DELETE_FAILED_SALE = "DELETE_FAILED_SALE"

// CLOSE SHIFT
export const DISABLE_CLOSE_SHIFT = "DISABLE_CLOSE_SHIFT";
export const LOAD_CLOSE_BALANCE = "LOAD_CLOSE_BALANCE";
export const CALC_ADJUSTMENT_PRIMARY = "CALC_ADJUSTMENT_PRIMARY";
export const CALC_ADJUSTMENT_SECONDARY = "CALC_ADJUSTMENT_SECONDARY";
export const CALC_CASH_CARD_DEPOSIT = "CALC_CASH_CARD_DEPOSIT";
export const SEND_CLOSE_SHIFT = "SEND_CLOSE_SHIFT";
// CASH REPORT
export const LOAD_CASH_REPORT = "LOAD_CASH_REPORT"
export const FILTER_CASH_REPORT = "FILTER_CASH_REPORT"
export const GENERATE_CASH_REPORT = "GENERATE_CASH_REPORT"

//VARIABLE PRICE PRODUCTS
export const SET_VARIABLE_PRODUCT = "SET_VARIABLE_PRODUCT";
export const REMOVE_VARIABLE_PRODUCT = "REMOVE_VARIABLE_PRODUCT";
export const UPDATE_VARIABLE_PRODUCT = "UPDATE_VARIABLE_PRODUCT";
export const CLEAR_VARIABLE_PRODUCTS = "CLEAR_VARIABLE_PRODUCTS";

// ALL
export const REFRESH_STATE = "REFRESH_STATE";
export const GET_CURRENT_ROUTE = "GET_CURRENT_ROUTE"