import {useRouteMatch} from "react-router-dom";
import React from "react";
import {locations} from "../../permissionsConstants";
import {connect} from "react-redux";
import {editBlockAmount} from "../../actions/sync";
import BlockAmountTable from "./BlockAmountTable";
import {Button, Grid} from '@material-ui/core/';


const BlockAmountView = ({blockAmount, editBlockAmount, permissionsList}) => {
    let {path} = useRouteMatch();
    const columns = ['Block amount', 'Options'];
    const permissions = Object.values(permissionsList);
    const canManageBlockAmount = permissions.includes(locations) || permissions.includes("superuser");
    return (
        <Grid container item>
            <Grid item xs={12} sm={10} lg={8}>
                <Button variant="contained"
                        style={{
                            marginBottom: '0', pointerEvents: "none",
                            boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14)",
                            borderRadius: 0, backgroundColor: "rgba(57, 205, 200, 0.1)", color: "#4a4a49"
                        }}
                        fullWidth>
                    Block amount
                </Button>
            </Grid>
            <Grid item xs={12} sm={10} lg={8}>
                <BlockAmountTable
                    edit={editBlockAmount}
                    entries={blockAmount}
                    columns={columns}
                    origin={path}
                    entity={"block-amount"}
                    canManage={canManageBlockAmount}
                />
            </Grid>
        </Grid>
    )

}

const mapStateToProps = state => ({
    blockAmount: Object.values(state.companyReducer)[13],
    permissionsList: state.auth.permissionsList

});

export default connect(mapStateToProps, {editBlockAmount})(BlockAmountView)