import {
    SET_CLIENT,
    SET_PAYMENT_TYPE,
    SUCCESS_PAYMENT,
    SET_TRANSFER_PAYMENT,
    TOGGLE_TRANSFER_PAYMENT_SECTION,
    OPEN_PAYMENT_SECTION,
    EMPTY_CART,
    ADD_TO_CART,
    ACTIVE_PAY_SECOND_CURRENCY
} from '../redux/actionTypes'

const initialState = {
    paymentType: null,
    paymentCustomerType: null,
    optionClientType: '',
    optionPaymentType: '',
    transferPaymentSection: {},
    selectedTransferPayment: {},
    openPaymentSection: false,
    activedPaySecondCurrency: false
}

const paymentTypeReducer = (state = initialState.paymentType, action = {}) => {
    switch (action.type) {
        case SET_PAYMENT_TYPE:
            return state === action.paymentType ? initialState.paymentType : action.paymentType
        case SUCCESS_PAYMENT:
            return initialState.paymentType;
        case  EMPTY_CART:
            return initialState.paymentType;
        default:
            return state;
    }
}

const paymentCustomerTypeReducer = (state = initialState.paymentCustomerType, action) => {
    switch (action.type) {
        case SET_CLIENT:
            return action.paymentCustomerType
        case SUCCESS_PAYMENT:
            return initialState.paymentCustomerType
        default:
            return state;
    }
}

const optionClientType = (state = initialState.optionClientType, action) => {
    switch (action.type) {
        case SET_CLIENT:
            return action.optionClientType

        default:
            return state;
    }
}

const togglePaymentType = (state = initialState.optionPaymentType, action) => {
    switch (action.type) {
        case SET_PAYMENT_TYPE:
            return action.id
        default:
            return state;
    }

}

const transferPaymentSectionReducer = (state = initialState.transferPaymentSection, action) => {
    switch (action.type) {
        case TOGGLE_TRANSFER_PAYMENT_SECTION:
            return {status: action.status, key: action.key}
        default:
            return state;
    }
}

const selectedTransferPaymentReducer = (state = initialState.selectedTransferPayment, action) => {
    switch (action.type) {
        case SET_TRANSFER_PAYMENT:
            return action.selectedTransferPayment
        case  EMPTY_CART:
            return initialState.selectedTransferPayment
        default:
            return state;
    }
}

const openPaymentSectionReducer = (state = initialState.openPaymentSection, action) => {
    switch (action.type) {
        case OPEN_PAYMENT_SECTION:
            return action.value
        case   ADD_TO_CART: 
            return initialState.openPaymentSection
        default:
            return state;
    }
}

const paySecondCurrencyReducer = (state = initialState.activedPaySecondCurrency, action) => {
    switch (action.type) {
        case ACTIVE_PAY_SECOND_CURRENCY:
            return action.value
        default:
            return state;
    }
}


//STATUS REDUCER
const paymentTypeSelected = (state = initialState, action) => {
    return {
        paymentType: paymentTypeReducer(state.paymentType, action),
        paymentCustomerType: paymentCustomerTypeReducer(state.paymentCustomerType, action),
        optionClientType: optionClientType(state.optionClientType, action),
        optionPaymentType: togglePaymentType(state.optionPaymentType, action),
        transferPaymentSection: transferPaymentSectionReducer(state.transferPaymentSection, action),
        selectedTransferPayment: selectedTransferPaymentReducer(state.selectedTransferPayment, action),
        openPaymentSection: openPaymentSectionReducer(state.openPaymentSection, action),
        activedPaySecondCurrency: paySecondCurrencyReducer(state.activedPaySecondCurrency, action)
    }
}

export default paymentTypeSelected