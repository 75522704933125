import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import {Link, useRouteMatch} from 'react-router-dom';
import Classes from '../../../styles';
// COMPONENTS
import TableConfig from '../components/utils/TableConfig';
import InfoList from '../components/utils/InfoList';
import {handleViewPaymentPanel} from "../../common/actions/actions";
import DynamicDialog from "../../common/components/modals/DynamicDialog"
import {validateResponseApi} from "../../common/selectors/utilsSelector"

// MATERIAL UI
import {Box, Button, Grid, Input, InputAdornment, Typography} from '@material-ui/core';

import {customerList} from "../selector/customer.selector";
import {editCustomer, emptyCustomer, removeCustomer} from "../actions/sync";
import {getCommonSelection, searchSettingsStaff, searchSettingsVolunteer} from "../actions/asyncAction";
import {staff as staff_permission, volunteer as volunteer_permission} from "../permissionsConstants";
import SearchIcon from "@material-ui/icons/SearchRounded";

const Employees = ({
                       customersList, permissionsList, removeCustomer, editCustomer, searchVolunteerValue,
                       searchStaffValue, animation, selectedCustomer
                   }) => {
    const classes = Classes();
    const {volunteer, staff, isFetch} = customersList;
    const permissions = Object.values(permissionsList);
    const canManageVolunteers = permissions.includes(volunteer_permission) || permissions.includes("superuser");
    const canManageStaff = permissions.includes(staff_permission) || permissions.includes("superuser");
    const dispatch = useDispatch();
    const [staffIcon, setStaffIcon] = useState(false);
    const [volunteerIcon, setVolunteerIcon] = useState(false);

    let search;
    useEffect(() => {
            dispatch(getCommonSelection());
            dispatch(handleViewPaymentPanel(false));
        }, [dispatch]
    );
    let {path} = useRouteMatch();
    const columns = ['Id', 'Name', 'Surname', 'Document number', 'Options'];

    const handleStaffSearchChange = (e) => {
        clearTimeout(search)
        dispatch(searchSettingsStaff(e.target.value.trim()));
    };

    const handleVolunteerSearchChange = (e) => {
        clearTimeout(search)
        dispatch(searchSettingsVolunteer(e.target.value.trim()));
    };

    const handleEventIcon = (keyValue, value) => {
        if (keyValue == "staff") {
            setTimeout(() => setStaffIcon(value), 100);
        } else {
            setTimeout(() => setVolunteerIcon(value), 100);
        }

    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h1" className={classes.typography.pageTitles}>
                    <Box display="inline" color="text.secondary">Settings /</Box>
                    &nbsp;Employees
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container item spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={2}>
                        <InfoList title={"Staff"}
                                  description={"Create new staff members so they can have some benefits as special prices and open accounts on RUMIpos."}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={10}>
                        <div>
                            <Grid item xs={12} sm={10} md={10} lg={8}>
                                <Input
                                    className={` general-forms- ${classes.forms.searchInput} general-colors- ${classes.colors.bgWhite}  
					                general-styles- ${classes.styles.bRadius18} general-styles- ${classes.styles.shadow1}`}
                                    id="staff-input-search" name="staff-input-search" placeholder="Search"
                                    fullWidth
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchIcon style={staffIcon ? {color: "#5BE576"} : {color: "#9E9E9E"}}/>
                                        </InputAdornment>
                                    }
                                    onChange={handleStaffSearchChange}
                                    value={searchStaffValue}
                                    onFocus={() => handleEventIcon("staff", true)}
                                    onBlur={() => handleEventIcon("staff", false)}
                                />
                                {
                                    canManageStaff ?
                                        <Link to={`${path}staff`}
                                              className={`general-nav- ${classes.nav.link}`}>
                                            <Button
                                                variant="contained"
                                                color="secondary" fullWidth
                                                onClick={() => dispatch(emptyCustomer())}
                                                style={{margin: '0.8em 0'}}
                                            >Add Staff</Button>
                                        </Link>
                                        :
                                        <span style={{cursor: 'not-allowed'}}>
                                            <Button
                                                variant="contained"
                                                color="secondary" fullWidth
                                                style={{margin: '0.8em 0'}}
                                                disabled

                                            >Add Staff</Button>
                                        </span>
                                }
                                {
                                    <TableConfig
                                        columns={columns}
                                        entries={staff}
                                        entity="staff"
                                        edit={editCustomer}
                                        remove={removeCustomer}
                                        canManage={canManageStaff}
                                        link={`${path}staff`}
                                        animation={animation}
                                        selectedItem={selectedCustomer}
                                    />
                                }
                            </Grid>
                            <DynamicDialog
                                title={"Are you sure?"}
                                msg={"Are you sure that you want to delete this staff?"}
                                type={"delete"}
                                keyValue={"staff"}
                                handleFunction={removeCustomer}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container item spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={2}>
                        <InfoList title={"Volunteer"}
                                  description={"Create new voluntary staff  so they can have some benefits as special prices and open accounts on RUMIpos."}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={10}>
                        <div>
                            <Grid item xs={12} sm={10} md={10} lg={8}>
                                <Input
                                    className={` general-forms- ${classes.forms.searchInput} general-colors- ${classes.colors.bgWhite}  
                                        general-styles- ${classes.styles.bRadius18} general-styles- ${classes.styles.shadow1}`}
                                    id="volunteer-input-search" name="volunteer-input-search" placeholder="Search"
                                    fullWidth
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchIcon
                                                style={volunteerIcon ? {color: "#5BE576"} : {color: "#9E9E9E"}}/>
                                        </InputAdornment>
                                    }
                                    onChange={handleVolunteerSearchChange}
                                    value={searchVolunteerValue}
                                    onFocus={() => handleEventIcon("volunteer", true)}
                                    onBlur={() => handleEventIcon("volunteer", false)}
                                />
                                {canManageVolunteers ?
                                    <Link
                                        to={`${path}volunteer`}
                                        className={classes.nav.link}
                                        onClick={() => dispatch(emptyCustomer())}
                                    >
                                        <Button
                                            variant="contained"
                                            color="secondary" fullWidth
                                            style={{margin: '0.8em 0'}}
                                        >Add Volunteer</Button>
                                    </Link>
                                    : <span style={{cursor: 'not-allowed'}}>
                                        <Button
                                            variant="contained"
                                            color="secondary" fullWidth
                                            style={{margin: '0.8em 0'}}
                                            disabled

                                        >Add Volunteer</Button>
                                    </span>
                                }
                                {
                                    <TableConfig
                                        columns={columns}
                                        entries={volunteer}
                                        entity="volunteer"
                                        edit={editCustomer}
                                        remove={removeCustomer}
                                        canManage={canManageVolunteers}
                                        link={`${path}volunteer`}
                                        selectedItem={selectedCustomer}
                                        animation={animation}
                                    />
                                }
                            </Grid>
                            <DynamicDialog
                                title={"Are you sure?"}
                                msg={"Are you sure that you want to delete this volunteer?"}
                                type={"delete"}
                                keyValue={"volunteer"}
                                handleFunction={removeCustomer}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = state => ({
    customersList: customerList(state),
    searchVolunteerValue: state.customerSettings.searchVolunteer,
    searchStaffValue: state.customerSettings.staffSearch,
    permissionsList: state.auth.permissionsList,
    selectedCustomer: state.messages.response.id,
    animation: validateResponseApi(state.messages.response, "delete-customer")
});

export default connect(mapStateToProps, {removeCustomer, editCustomer})(Employees)