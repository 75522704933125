export function formatReservation(reservationData) {
    let customers = [];
    let roomReservations = [];
    reservationData.forEach(reservation => {
        const {rooms: r, customers: c} = reservation;
        const roomsIds = r.map(room => room.roomId);
        reservation.rooms = roomsIds;
        reservation.customers = c.map(customer => customer.customerId);
        roomReservations.push(
            ...roomsIds.map(id => ({
                reservationId: reservation.reservationId,
                roomId: id,
            }))
        );
        if (r.length > 1) {
            for (let i = 0; i < r.length; i++) {
                const tempArray = Object.assign({}, c[0]);
                tempArray.debt = reservation.debt;
                tempArray.roomNumber = r[i].roomNumber;
                tempArray.roomName = r[i].roomName;
                customers.push(tempArray)
            }
        } else {
            c[0].debt = reservation.debt;
            c[0].roomNumber = r[0].roomNumber;
            c[0].roomName = r[0].roomName;
            customers.push(...c);
        }

    });
    return {
        reservations: reservationData,
        customers,
        roomReservations
    }
}

function formatReservationRefactor(reservationData) {

    return reservationData
        .map(reservation => {
            const {rooms, customers} = reservation
            const roomsIds = getRoomIds(rooms);
            const roomReservations = mapRoomsAndReservation(roomsIds, reservation.reservationId)
            const customerIds = getCustomerIds(customers)
            const _reservation = {...reservation, customers: customerIds, rooms: roomsIds}
            return {
                reservation: _reservation,
                customers,
                roomReservations
            }
        })
        .reduce((accu, resInfo) => {
            accu.reservations.push(resInfo.reservation);
            accu.customers.push(...resInfo.customers);
            accu.roomReservations.push(...resInfo.roomReservations)
            return accu;
        }, {reservations: [], customers: [], roomReservations: []});

}

const getItemsByProperty = (key = "id") => (items) => items.map(item => item[key]);
const getRoomIds = getItemsByProperty("roomId");
const getCustomerIds = getItemsByProperty("customerId");
const mapRoomsAndReservation = (rooms, reservationId) => rooms.map(roomId => ({reservationId, roomId}))
