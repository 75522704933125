const getAllAreas = state => state.areasSettingReducer.areas
const getArea = (state, id) => state.areasSettingReducer.areas[id]
export const getSerial = state => state.billingSettingReducer.serialList;
const getDocumentType = state => state.billingSettingReducer.documentType;
const getSelectedArea = state => state.billingSettingReducer.areaSelected;

function getAreasSerial(state) {
    return new Set(simpleFlatArray(
        Object.values(getAllAreas(state)).map(area => {
            return Object.values(area.serial);
        })
    ));
}

function simpleFlatArray(arr) {
    return arr.reduce((acc, val) => acc.concat(val), []);
}

function missingSerial(allSerial, serial) {
    const difference = new Set(allSerial);
    for (let elem of serial) {
        difference.delete(elem);
    }
    return difference;
}

export const serialList = state => {
    const documentTypes = getDocumentType(state);
    const serials = getSerial(state);
    const areas = getAllAreas(state);

    const areasSerial = Object.values(areas).reduce((acc, area) => {
        return acc.concat(area.serials.map(entry => {
            const {documentType, serie} = entry;
            const serial = serials[serie];
            return {
                id: area.id,
                location: area.name,
                document: documentTypes[documentType].name,
                serial: serial.serial,
                correlative: serial.correlative
            }
        }));
    }, []);

    const areaSerialCodes = new Set(areasSerial.map(area => area.serial));
    const allSerialCodes = Object.keys(serials);
    const unusedSerial = Array.from(missingSerial(allSerialCodes, areaSerialCodes)).map(serial => {
        const serialInfo = serials[serial];
        return {
            id: null,
            location: null,
            document: documentTypes[serialInfo.document].name,
            serial: serialInfo.serial,
            correlative: serialInfo.correlative
        }
    });
    return areasSerial.concat(unusedSerial)
}

export const selectedAreaInfo = state => {
    const areaId = getSelectedArea(state);
    if (!areaId) return {}
    return getArea(state, areaId)
}

export const serialsCombos = state => {
    const serials = getSerial(state);

    return Object.values(serials).reduce((acc, serial) => {
        acc[serial.documentType] = (acc[serial.documentType] || []).concat(serial)
        return acc
    }, {})
}

export function serialsList(serials) {
    if (!serials) return []
    const list = Object.values(serials)
    return list.map(serie => {
        const {location, document, serial, correlative, locationId} = serie;
        return {id: locationId, location, document, serial, correlative}
    })
}

export function taxesList(taxes) {
    if (!taxes) return []
    const list = Object.values(taxes)
    return list.map(tax => {
        const {id, type, name, value} = tax;
        return {id, type, name, value}
    })
}

export function taxesByProducts(taxes) {
    if (!taxes) return []
    const list = Object.values(taxes)
    return list.map(tax => {
        const {name, id, type} = tax;
        return {label:name, value:id, type: type}
    })
}

export function taxesByOutletsSelector(taxes) {
    if (!taxes) return []
    return taxes.map(excise => {
        const  {id} = excise;
        return id
    })
}



