import React, {useEffect} from 'react';
import {Redirect, Route, Switch, useRouteMatch, withRouter} from 'react-router-dom';

import {connect, useDispatch} from 'react-redux';
//CSS
import Classes from '../../styles/';
// MATERIAL UI
import {Badge, Box, Drawer, Fab, Grid, Hidden} from '@material-ui/core';
// COMPONENTS
import Logo from '../../assets/images/Colores/billing_small.png';
import Menu from '../common/components/menu/Menu';
import Clients from '../pos/containers/clients/Clients';
import Payments from './containers/Payments';
import Store from '../pos/containers/products/Products';
import PaymentPanel from '../pos/containers/payment-panel/PaymentPanel';
/* reports */
import CashReportView from "../pos/containers/reports/CashReportView"
// ICONS
import ClientsIcon from '@material-ui/icons/PersonOutlined';
import StoreIcon from '@material-ui/icons/LocalGroceryStoreOutlined';
import ReportsIcon from '@material-ui/icons/PollOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
// ICONS SUBMENU
import ReceipIcon from '@material-ui/icons/ReceiptOutlined';
import EmployeeIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import AccountsIcon from '@material-ui/icons/PeopleAltOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import FavoriteIcon from '@material-ui/icons/GradeOutlined';
import SaleReportIcon from '@material-ui/icons/LoyaltyOutlined';
import CashIcon from '@material-ui/icons/LocalAtmOutlined';
import StatisticsIcon from '@material-ui/icons/PieChartOutlined';
import RoomServiceIcon from '@material-ui/icons/RoomServiceOutlined';
import PaymentIcon from "@material-ui/icons/MonetizationOnOutlined";
import FileCopyIcon from "@material-ui/icons/FileCopy";
//import {getCustomersList} from "../rumi-settings/actions/asyncAction";
import {getCategories, getProducts} from "../pos/redux/asyncActions";
import {getCustomersList, loadExchangeRate} from "../rumi-settings/actions/asyncAction";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {getBalance, loadCurrencies} from "../common/actions/asyncActions";

import SupportView from "./containers/SupportView";
import DashboardView from "./containers/Dashboard";
// import {routes as settingsRoute} from "../rumi-settings/settings-route";
import OpeningBalanceModal from "../common/containers/OpeningBalance";
import page404 from "../common/components/error.pages/404";
import SalesReport from "../pos/components/reports/SalesReport";
import InvoicingReport from "../pos/components/reports/InvoicingReport";
import SalesRankingReport from "../pos/components/reports/SalesRankingReport";
import StoreView from "../rumi-settings/containers/StoreView";
import SettingsProduct from "../rumi-settings/containers/store/Products";
import General from "../rumi-settings/containers/General";
import Areas from "../rumi-settings/components/general/AreasForm";
import Billing from "../rumi-settings/containers/Billing";
import SeriesAndCorrelatives from "../rumi-settings/components/billing/SeriesAndCorrelativesForm";
import Taxes from "../rumi-settings/components/billing/TaxesForm";
import ExchangeRate from "../rumi-settings/components/billing/ExchangeRateForm";
import BlockAmount from "../rumi-settings/components/general/BlockAmountForm";
import Charges from "../rumi-settings/components/billing/ChargesForm";
import Employees from "../rumi-settings/containers/Employees";
import Staff from "../rumi-settings/containers/employees/Staff";
import Volunteer from "../rumi-settings/containers/employees/Volunteers";
import Accounts from "../rumi-settings/containers/Accounts";
import GroupView from "../rumi-settings/components/accounts/GroupForm";
import UserView from "../rumi-settings/components/accounts/UserForm";
import page401 from "../common/components/error.pages/401";

import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import {
    accounts,
    billing,
    billing_sales_history,
    billing_store,
    cash_balance_report,
    consumptions,
    employees,
    exchange_rate,
    general,
    groups,
    locations,
    ranking_report,
    receipts_reports,
    reports,
    sales_report,
    serials,
    settings,
    staff,
    statistics_report,
    store,
    store_actions,
    support,
    taxes,
    users,
    volunteer
} from "../rumi-settings/permissionsConstants";
import TimelineIcon from "@material-ui/icons/TrendingUp";
import SalesHistory from "../pos/containers/balance/SalesHistory";
import PrivateRouteWithValidation from "../common/components/utils/PrivateRouterWithValidation";
import GuestFlowsReport from "../pos/components/reports/GuestFlowsReport";
import BalanceFlowsReport from "../pos/components/reports/BalanceFlowsReport";
import ReservationsAllTimeView from "../pos/containers/reports/ReservationsAllTimeView";
import ReservationsAllTimeDetailedRates from "../pos/containers/reports/AllReservationsDetailedRates";
import UpdateProductPricesView from "../pos/containers/reports/UpdateProductPricesView";


const useStyles = makeStyles((theme) => ({
    fabIcon: {
        color: theme.palette.common.white,
        '&.menu': {
            backgroundColor: theme.palette.secondary.main,
        },
        '&.store': {
            backgroundColor: theme.palette.warning.main,
        },
        '&.payment': {
            backgroundColor: theme.palette.primary.main,
        }
    }
}));

const RumiBilling = ({location, showPaymentPanel, balanceStatus, permissionsList, qtyProducts}) => {
    const classes = Classes();
    const useclasses = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const dispatch = useDispatch();
    const permissions = Object.values(permissionsList);
    const canSaleStore = permissions.includes(billing_store) || permissions.includes("superuser");
    const currentPath = location.pathname;
    const [isConsumptionsView, setConsumptionsView] = React.useState(false);
    const totalProducts = !Object.values(qtyProducts).length ? [] : Object.values(qtyProducts).reduce((total, num) => total + num);

    const [menuMobileOpen, setMenuMobileOpen] = React.useState(false);
    const [paymentPanelMobileOpen, setPaymentPanelMobileOpen] = React.useState(false);

    const handlePaymentPanelDrawerToggle = () => {
        setPaymentPanelMobileOpen(!paymentPanelMobileOpen);
    };
    const handleMenuDrawerToggle = () => {
        setMenuMobileOpen(!menuMobileOpen);
    };
    let {path, url} = useRouteMatch();

    useEffect(() => {
        dispatch(getBalance());
        dispatch(getCustomersList());
        dispatch(getProducts());
        dispatch(getCategories());
        dispatch(loadExchangeRate());
        dispatch(loadCurrencies());
        if (currentPath.includes("consumptions")) {
            setConsumptionsView(true)
        } else {
            setConsumptionsView(false)
        }
    }, [dispatch, location]);

    const menuItems = [
        {
            name: 'Clients',
            url: `${path}/clients`,
            icon: <ClientsIcon/>,
            showPaymentPanel: true,
            value: false,
            enableButton: true,
        },
        {
            name: 'Consumptions',
            url: `${path}/consumptions`,
            icon: <RoomServiceIcon/>,
            showPaymentPanel: true,
            value: false,
            enableButton: permissions.includes(consumptions) || permissions.includes("superuser"),
        },
        {
            name: 'Store',
            url: `${path}/store`,
            icon: <StoreIcon/>,
            showPaymentPanel: true,
            value: false,
            enableButton: permissions.includes(billing_store) || permissions.includes("superuser"),
        },
        {
            name: 'Sales History',
            url: `${url}/sales-history`,
            icon: <TimelineIcon/>,
            showPaymentPanel: false,
            value: false,
            enableButton: permissions.includes(billing_sales_history) || permissions.includes("superuser")
        },
        {
            name: 'Reports',
            icon: <ReportsIcon/>,
            value: true,
            enableButton: permissions.includes(reports) || permissions.includes("superuser"),
            submenus: [
                {
                    url: `${path}/balance-history`,
                    name: 'Balance Flows',
                    icon: <SaleReportIcon/>,
                    showPaymentPanel: false,
                    enableButton: permissions.includes(sales_report) || permissions.includes("superuser")
                },
                {
                    url: `${path}/guest-flows`,
                    name: 'Guest Flows',
                    icon: <SaleReportIcon/>,
                    showPaymentPanel: false,
                    enableButton: permissions.includes(sales_report) || permissions.includes("superuser")
                },
                {
                    url: `${path}/reports-sales`,
                    name: 'Sales',
                    icon: <SaleReportIcon/>,
                    showPaymentPanel: false,
                    enableButton: permissions.includes(sales_report) || permissions.includes("superuser")
                },
                {
                    url: `${path}/reports-ranking`,
                    name: 'Ranking',
                    icon: <FavoriteIcon/>,
                    showPaymentPanel: false,
                    enableButton: permissions.includes(ranking_report) || permissions.includes("superuser")
                },
                {
                    url: `${path}/reports-receipts`,
                    name: 'Receipts',
                    icon: <ReceipIcon/>,
                    showPaymentPanel: false,
                    enableButton: permissions.includes(receipts_reports) || permissions.includes("superuser")
                },
                {
                    url: `${url}/statistics`,
                    name: 'Statistics',
                    icon: <StatisticsIcon/>,
                    enableButton: permissions.includes(statistics_report) || permissions.includes("superuser")
                },
                {
                    url: 'modal',
                    name: 'Cash Balance',
                    icon: <CashIcon/>,
                    body: <CashReportView/>,
                    enableButton: permissions.includes(cash_balance_report) || permissions.includes("superuser")
                },
                {
                    url: `modal`,
                    name: 'All reservations',
                    icon: <StatisticsIcon/>,
                    body: <ReservationsAllTimeView/>,
                    showPaymentPanel: false,
                    enableButton: permissions.includes(reports) || permissions.includes("superuser")
                },
                {
                    url: `modal`,
                    name: 'Detailed rates',
                    icon: <StatisticsIcon/>,
                    body: <ReservationsAllTimeDetailedRates/>,
                    showPaymentPanel: false,
                    enableButton: permissions.includes(reports) || permissions.includes("superuser")
                },
                {
                    url: `modal`,
                    name: 'Update product prices',
                    icon: <FileCopyIcon/>,
                    body: <UpdateProductPricesView/>,
                    showPaymentPanel: false,
                    enableButton: permissions.includes(settings) || permissions.includes("superuser")
                },
            ],
        },
        {
            name: 'Settings',
            icon: <SettingsIcon/>,
            value: true,
            enableButton: permissions.includes(settings) || permissions.includes("superuser"),
            submenus: [
                {
                    url: `${path}/settings/general`,
                    name: 'General',
                    icon: <HomeIcon/>,
                    showPaymentPanel: false,
                    enableButton: permissions.includes(general) || permissions.includes("superuser")
                },
                {
                    url: `${path}/settings/billing`,
                    name: 'Billing',
                    icon: <ReceipIcon/>,
                    showPaymentPanel: false,
                    enableButton: permissions.includes(billing) || permissions.includes("superuser")
                },
                {
                    url: `${path}/settings/store`,
                    name: 'Store',
                    icon: <StoreIcon/>,
                    showPaymentPanel: false,
                    enableButton: permissions.includes(store) || permissions.includes("superuser")
                },
                {
                    url: `${path}/settings/employees`,
                    name: 'Employees',
                    icon: <EmployeeIcon/>,
                    showPaymentPanel: false,
                    enableButton: permissions.includes(employees) || permissions.includes("superuser")
                },
                {
                    url: `${path}/settings/accounts`,
                    name: 'Accounts',
                    icon: <AccountsIcon/>,
                    showPaymentPanel: false,
                    enableButton: permissions.includes(accounts) || permissions.includes("superuser")
                }
            ],
        },
        // NO MVP 
        // { 
        //     name: 'Support',
        //     url: `${url}/support`,
        //     icon: <SupportIcon/>,
        //     showPaymentPanel: false,
        //     value: false,
        //     enableButton: permissions.includes(support) || permissions.includes("superuser")
        // }
    ];

    const paymentPanelDrawer = (
        <div>
            <Hidden smUp>
                <ArrowBackIcon style={{position: 'absolute', right: 10, top: 10}}
                               onClick={() => setPaymentPanelMobileOpen(false)}/>
            </Hidden>
            <PaymentPanel/>
        </div>
    );

    const menuDrawer = (
        <Menu
            menuItems={menuItems}
            logo={Logo}
            positionDivider={3}
        />
    );

    return (
        <Grid container spacing={3} className={classes.containers.root}>
            <Grid item xs className={classes.containers.middlePanel}>
                <Hidden mdUp implementation="css">
                    <Drawer variant="temporary" anchor='left' open={menuMobileOpen} onClose={handleMenuDrawerToggle}
                            classes={{paper: classes.sideDrawers.menuDrawerHolder,}}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}>
                        {menuDrawer}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer variant="permanent" open anchor='left'
                            classes={{
                                paper: classes.sideDrawers.menuDrawerHolder,
                            }}>
                        {menuDrawer}
                    </Drawer>
                </Hidden>
                <Box flexGrow={1} style={showPaymentPanel ? {
                    marginRight: matches ? "350px" : 0,
                    marginLeft: matches ? "130px" : 0
                } : {marginLeft: matches ? "130px" : 0}}>
                    <Hidden mdUp>
                        <Box display="flex" flexDirection="row" justifyContent="right" alignItems="center"
                             style={{marginBottom: "-61px"}}>
                            <Fab color="inherit" size="medium" aria-label="open drawer"
                                 onClick={handleMenuDrawerToggle}
                                 className={`${useclasses.fabIcon} menu`}>
                                <MenuIcon/>
                            </Fab>
                            {
                                showPaymentPanel &&
                                (!canSaleStore && !isConsumptionsView ?
                                        null :
                                        <Fab style={{
                                            right: 0,
                                            position: 'absolute',
                                            marginRight: 10
                                        }}
                                             className={`${useclasses.fabIcon} ${isConsumptionsView ? 'payment' : 'store'}`}
                                             size="medium"
                                             aria-label="open drawer"
                                             onClick={handlePaymentPanelDrawerToggle}>
                                            {
                                                isConsumptionsView ? <PaymentIcon/> :
                                                    <Badge color="error" badgeContent={totalProducts}
                                                           invisible={totalProducts > 0 ? false : true}>
                                                        <StoreIcon/>
                                                    </Badge>
                                            }
                                        </Fab>
                                )
                            }
                        </Box>
                    </Hidden>
                    <Box mt={{xs: 7, md: 0}}>
                        <Switch>
                            <PrivateRouteWithValidation viewPermission="noneed" exact
                                                        path={[`${path}`, `${path}/clients`]}
                                                        component={props => <Clients/>}/>
                            <PrivateRouteWithValidation viewPermission={statistics_report} exact
                                                        path={[`${path}`, `${path}/statistics`]}
                                                        component={props => <DashboardView/>}/>
                            <PrivateRouteWithValidation viewPermission={consumptions} exact
                                                        path={[`${path}`, `${path}/consumptions`]}
                                                        component={props => <Payments/>}/>
                            <PrivateRouteWithValidation viewPermission={billing_store}
                                                        component={props => <Store title={"Store"} {...props}/>}
                                                        path={`${path}/store`} exact/>
                            <PrivateRouteWithValidation viewPermission={billing_sales_history} component={SalesHistory}
                                                        path={`${path}/sales-history`} exact/>
                            <PrivateRouteWithValidation viewPermission={sales_report}
                                                        component={props => <BalanceFlowsReport {...props}/>}
                                                        path={`${path}/balance-history`}
                                                        exact/>
                            <PrivateRouteWithValidation viewPermission={sales_report}
                                                        component={props => <GuestFlowsReport {...props}/>}
                                                        path={`${path}/guest-flows`}
                                                        exact/>
                            <PrivateRouteWithValidation viewPermission={sales_report}
                                                        component={props => <SalesReport {...props}/>}
                                                        path={`${path}/reports-sales`}
                                                        exact/>
                            <PrivateRouteWithValidation viewPermission={ranking_report}
                                                        component={props => <SalesRankingReport {...props}/>}
                                                        path={`${path}/reports-ranking`} exact/>
                            <PrivateRouteWithValidation viewPermission={receipts_reports}
                                                        component={props => <InvoicingReport {...props}/>}
                                                        path={`${path}/reports-receipts`} exact/>
                            <PrivateRouteWithValidation viewPermission={store} component={StoreView}
                                                        path={`${path}/settings/store/`} exact/>
                            <PrivateRouteWithValidation viewPermission={store_actions} component={SettingsProduct}
                                                        path={`${path}/settings/store/products/`}
                                                        exact/>
                            <PrivateRouteWithValidation viewPermission={general} component={General}
                                                        path={`${path}/settings/general/`} exact/>
                            <PrivateRouteWithValidation viewPermission={locations} component={Areas}
                                                        path={`${path}/settings/general/areas/`} exact/>
                            <PrivateRouteWithValidation viewPermission={locations} component={BlockAmount}
                                                        path={`${path}/settings/general/block-amount/`} exact/>
                            <PrivateRouteWithValidation viewPermission={billing} component={Billing}
                                                        path={`${path}/settings/billing/`} exact/>
                            <PrivateRouteWithValidation viewPermission={serials} component={SeriesAndCorrelatives}
                                                        path={`${path}/settings/billing/series_correlatives/`}
                                                        exact/>
                            <PrivateRouteWithValidation viewPermission={taxes} component={Taxes}
                                                        path={`${path}/settings/billing/taxes`} exact/>
                            <PrivateRouteWithValidation viewPermission={exchange_rate} component={ExchangeRate}
                                                        path={`${path}/settings/billing/exchange-rate`} exact/>
                            <PrivateRouteWithValidation viewPermission={taxes} component={Charges}
                                                        path={`${path}/settings/billing/charges`} exact/>
                            <PrivateRouteWithValidation viewPermission={employees} component={Employees}
                                                        path={`${path}/settings/employees/`} exact/>
                            <PrivateRouteWithValidation viewPermission={staff} component={Staff}
                                                        path={`${path}/settings/employees/staff/`} exact/>
                            <PrivateRouteWithValidation viewPermission={volunteer} component={Volunteer}
                                                        path={`${path}/settings/employees/volunteer/`}
                                                        exact/>
                            <PrivateRouteWithValidation viewPermission={accounts} component={Accounts}
                                                        path={`${path}/settings/accounts/`} exact/>
                            <PrivateRouteWithValidation viewPermission={groups} component={GroupView}
                                                        path={`${path}/settings/accounts/group/`} exact/>
                            <PrivateRouteWithValidation viewPermission={users} component={UserView}
                                                        path={`${path}/settings/accounts/user/`} exact/>
                            <PrivateRouteWithValidation viewPermission={support}
                                                        component={props => <SupportView {...props}/>}
                                                        path={`${path}/support`} exact/>
                            <Route exact path={`${path}/logout`} component={props => <Redirect to="/logout"/>}/>
                            <Route exact path={`${path}/401`} component={page401}/>
                            <Route component={page404}/>
                        </Switch>
                    </Box>
                </Box>
                {
                    showPaymentPanel &&
                    <Box>
                        <Hidden mdUp implementation="css">
                            <Drawer variant="temporary" anchor='right' open={paymentPanelMobileOpen}
                                    onClose={handlePaymentPanelDrawerToggle}
                                    classes={{
                                        paper: classes.sideDrawers.paymentPanelDrawerHolder,
                                    }}
                                    ModalProps={{
                                        keepMounted: true, // Better open performance on mobile.
                                    }}>
                                {paymentPanelDrawer}
                            </Drawer>
                        </Hidden>
                        <Hidden smDown implementation="css">
                            <Drawer variant="permanent" open anchor='right'
                                    classes={{
                                        paper: classes.sideDrawers.paymentPanelDrawerHolder,
                                    }}>
                                {paymentPanelDrawer}
                            </Drawer>
                        </Hidden>
                    </Box>
                }
                {!balanceStatus && <OpeningBalanceModal/>}
            </Grid>
        </Grid>
    )
};

const mapStateToProps = state => ({
    showPaymentPanel: state.utilsReducer.showPaymentPanel,
    balanceStatus: state.balanceStatus.openBalance,
    userData: state.auth.userData,
    permissionsList: state.auth.permissionsList,
    qtyProducts: state.cart.quantityById
});

export default connect(mapStateToProps)(withRouter(RumiBilling));
