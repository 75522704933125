import {LOAD_CURRENCIES} from "../actions/actionTypes";

const initialState = {
    currencies:{},
    loaded: false
};

const currencies = (state = initialState, action) => {

    switch (action.type) {
        case LOAD_CURRENCIES:
            return {...state, currencies: action.payload,loaded: true};
        default :
            return state;
    }
};

export default currencies