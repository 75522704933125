import React from 'react';
import {useDispatch} from "react-redux"
// CSS
import Classes from '../../../../styles'
import {cleanCart} from "../../redux/actions";
// MATERIAL UI
import IconButton from '@material-ui/core/IconButton';
import {Grid, Typography} from '@material-ui/core';
// ICONS
import AddIcon from '@material-ui/icons/AddRounded';
import RemoveIcon from '@material-ui/icons/RemoveRounded';
import DeleteIcon from '@material-ui/icons/DeleteOutlineRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '12px 7px 12px 15px', background: theme.palette.grey['50']
    }, sectionCart: {
        marginTop: 15, height: '20em', [theme.breakpoints.down('md')]: {
            height: '17em'
        }, overflow: 'scroll'
    }

}));

const OrderList = ({productsCart, addProductCart, subtractProductCart, removeProductCart}) => {
    const classes = Classes();
    const useclasses = useStyles();
    const dispatch = useDispatch();

    const cancelOrderList = () => {
        dispatch(cleanCart())
    }

    return (<Grid container item className={useclasses.container}>
            <Grid item xs={11}>
                <Typography variant="subtitle1" color="textSecondary">
                    Total added products
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <IconButton aria-label="settings"
                            style={{padding: '5px'}}
                            className={classes.buttons.btnCloseCardPanel}
                            onClick={cancelOrderList}>
                    <CloseIcon/>
                </IconButton>
            </Grid>
            <Grid item xs={12} className={useclasses.sectionCart}>
                {productsCart.length && productsCart.map((product, index) => <Grid container key={index + 1}
                                                                                   style={{margin: '0.5em 0'}}>
                    <Grid item xs={6} style={{fontSize: '0.9em'}}>{product.name}</Grid>
                    <Grid item xs={1}><RemoveIcon style={{color: '#5BE576', fontSize: '1.4em'}}
                                                  onClick={() => subtractProductCart(product.id)}/></Grid>
                    <Grid item xs={1} style={{fontSize: '0.9em'}}>{product.quantity}</Grid>
                    <Grid item xs={1}><AddIcon style={{color: '#5BE576', fontSize: '1.4em'}}
                                               onClick={() => (addProductCart(product.id))}/></Grid>
                    <Grid item xs={2} style={{fontSize: '0.9em'}}>{product.price} </Grid>
                    <Grid item xs={1}> <DeleteIcon style={{color: '#EA3788', fontSize: '1.4em'}}
                                                   onClick={() => removeProductCart(product.id)}/> </Grid>
                </Grid>)}
            </Grid>
        </Grid>);
};

export default OrderList;
