import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {connect} from 'react-redux'
// CSS
import Classes from '../../../styles'
// COMPONENTS
import InfoList from '../components/utils/InfoList';
// MATERIAL UI
import {Box, Grid, Typography} from '@material-ui/core';
import GroupListView from "../components/accounts/GroupList";
import UserListView from "../components/accounts/UserList";
import {handleViewPaymentPanel} from "../../common/actions/actions";
import {loadAccounts, loadBillings} from "../actions/asyncAction";


const Accounts = ({handleViewPaymentPanel, loadAccounts }) => {
    const classes = Classes();
    const dispatch = useDispatch();

    useEffect(() => {
        handleViewPaymentPanel(false);
        dispatch(loadBillings());
        loadAccounts()
    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h1" className={classes.typography.pageTitles}>
                    <Box display="inline" color="text.secondary">
                        Settings /
                    </Box>
                    &nbsp;Accounts
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={2}>
                        <InfoList title={"Groups"}
                                  description={"Create groups to classify users with exclusive permissions by group."}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={10}>
                        <GroupListView/>
                    </Grid>
                </Grid>
                <br/>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={2}>
                        <InfoList title={"Users"}
                                  description={"Create new users  for access to RUMI and assign a group for permissions."}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={10}>
                        <UserListView/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

const mapStateToProps = state => ({
    isLoading: state
});
export default connect(mapStateToProps, {handleViewPaymentPanel, loadAccounts })( Accounts)
